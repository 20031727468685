import React from 'react'
import PropTypes from 'prop-types'
import styles from './USMap.module.scss'
import classNames from 'classnames'

class USMap extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      hoverState: null
    }
  }

  handleMouseEnter = e => {
    const { data, isStatic } = this.props
    if (isStatic) return
    const id = e.target.parentNode.id.replace('_', ' ')
    const state = data && data.find(d => d.state === id)
    this.setState({
      hoverState: state.state,
      hoverCompliance: state.status === 'Y' ? 'In Compliance' : 'Out of Compliance'
    })
  }

  handleMouseMove = e => {
    const parentEl = document.getElementById('Map_Container').getBoundingClientRect()
    this.setState({
      posX: e.clientX - parentEl.x - 60,
      posY: e.clientY - parentEl.y - 55
    })
  }

  handleMouseLeave = () => {
    this.setState({
      hoverState: null,
      hoverCompliance: null
    })
  }

  isCompliance = state => {
    const { data } = this.props
    return data && data.find(d => d.state === state) && data.find(d => d.state === state).status === 'Y'
  }

  render () {
    const { accent } = this.props
    const tooltipStyle = {
      transform: `translateY(${this.state.posY}px) translateX(${this.state.posX}px)`
    }

    return <div id='Map_Container' className={styles.container}>
      {this.state.hoverState && <div style={tooltipStyle} className={classNames(styles.tooltip, {
        [styles.green]: accent === 'green',
        [styles.orange]: accent === 'orange',
        [styles.pink]: accent === 'pink',
        [styles.gray]: accent === 'gray',
        [styles.black]: accent === 'black'
      })}>
        <div className={styles.title}>
          {this.state.hoverState}
        </div>
        <div className={styles.text}>
          {this.state.hoverCompliance}
        </div>
        <div className={styles.triangle} />
      </div>}
      <svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 729.5 493.7'>
        <g id='Wyoming'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Wyoming')
          })} d='M186.8,99.3c3.4,0.8,6.5,1.8,11,1.4c0.8,0.9,3.4,0.1,4.1,1.1c4.7,0.2,8.2,1.6,13.2,1.4
            c0.8-0.2,0.5,0.6,1.1,0.5c3.2-0.4,4.8,0.9,8,0.5c2.4,1.1,5.8,0.3,7.8,1.4c1.8-0.2,3.7,0.2,5.3,1.1c5.6,0.5,11.6,0.7,17.4,0.9
            c0.9,0.8,3,0.5,4.6,0.5c0.6-0.1,0.4,0.5,0.7,0.5c4.3-0.2,7.1,1.1,11.4,0.9c0.4,1.8,0.1,4.3,0.2,6.4c0.2,0.6-0.5,0.4-0.5,0.7
            c-0.2,2.4,0.5,5.7-0.5,7.3c-0.2,1.7,0.5,4.3-0.5,5.2c0.3,5.8-0.5,12-0.9,17.8c0.2,0.8-0.6,0.5-0.5,1.1v2.5c0.1,0.7-0.5,0.5-0.5,1.1
            c0.2,5.5-0.4,9.7-0.4,14.6c-0.8,0.8-0.1,2.9-1.1,3.6v5.5c-0.6,0.7-0.6,2.1-0.5,3.6c-5.4,0.2-9.4-1-14.6-1.1
            c-0.7,0.2-0.6-0.4-0.9-0.5c-4,0.3-6.9-0.6-10.9-0.4c-0.7-0.7-2.5-0.2-3-1.1c-6.5,0.5-10.1-1.5-15.9-1.4c-0.8,0.3-0.5-0.6-1.1-0.5
            c-2.8-0.2-4.4-0.1-7.3-0.5c-0.4,0-0.8-0.4-1.2-0.5c-1.9-0.4-4,0-5.7-0.4c-0.3-0.1-0.6-0.5-0.9-0.5c-1-0.1-2,0.1-3,0
            c-0.4-0.1-0.7-0.4-1.1-0.5c-1.2-0.3-2.6,0.2-3.9,0c-0.4-0.1-0.8-0.4-1.2-0.5c-0.4-0.1-1.1,0.1-1.8,0c-1-0.1-2.1-0.8-3-0.9
            c-1.4-0.2-2.9,0.2-4.3,0c-0.4-0.1-0.7-0.4-1.2-0.5c-0.5-0.1-1.4,0.1-2,0c-0.3-0.1-0.6-0.5-0.9-0.5c-2.3-0.3-4.4-0.2-6.4-0.5
            c0-2.8,1.4-5.2,0.9-9.1c1.9-8.3,3.4-16.8,4.4-25.3c-0.2-0.7,0.5-0.4,0.5-0.9c-0.1-5.6,1.3-9,1.4-13.5c1-0.5,0-2.8,1.1-3.2
            c0.1-2.9,0.6-5.8,1.4-8.5v-2.5C186.4,103.4,186.7,101.4,186.8,99.3z' />
        </g>
        <g id='Wisconsin'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Wisconsin')
          })} d='M436.4,78.7c-1.4,1.3-2.3,3.1-2.5,5c1.1,1.1,2.5-0.2,3.7-0.2c1.6,0.3,2.2,1.6,4.1,1.6c1,1.1,1.9,2.2,2.7,3.4h2
            c0.4,0.6,1.4,0.6,2.5,0.5c0.7,1,2.4,0,2.8,1.1c0.6,0.1,0.6-0.3,1.2-0.2c2.1,0.8,5.2,0.6,6.2,2.5c3.7,0.2,6.6,1.1,10.1,1.4
            c0.5,0.5-0.6,0.5-0.4,1.2c1.1,1.4,2.7,1.3,4.5,2c-0.4,3.5,1,5.2,1.1,8.4c0.5,0.9,1.6,1.2,2,2.3c-2.1,2.5-4.4,6.2-3.9,9.8
            c0.6,0.2,1.2,0.2,1.8,0.2c0.6-0.8,0.6-2.1,2.1-2c0.2-0.5,0.2-1.1,0.2-1.6c1.7-2.1,2.8-4.1,4.8-6.1c0.5-1.7,1.5-3.1,2.8-4.3
            c-0.9,6.7-6.8,11.6-4.8,19.2c-3,1.1-2.2,5.9-1.6,9.1c-0.4,1-1.5,2.2-0.9,3.7c-1.2,2.3-1.2,5.1,0,7.5c-0.1,0.7-0.2,1.3-0.2,2
            c-0.2,0.6,0.5,0.4,0.5,0.7c-0.2,2.8,1.5,3.6,1.1,6.6c-1.1,0.3-4.4-0.6-5.2,0.5c-2.1,0.3-5.2-0.6-6.4,0.5h-8.7
            c-0.6-0.2-0.4,0.5-0.7,0.5c-2.2,0.2-5.4-0.5-6.8,0.5c-2.7-0.3-5.3-0.3-8,0c-0.7-2.2-3-2.6-5.5-3c-0.3-1.2-0.9-2.2-1.6-3.2
            c-0.5-2.9,0.8-4,1.1-6.1c-2.4-1.9-2.7-6-3-10c-0.8-1.3-2-2.4-3.4-3c-0.4-0.1-0.4-0.9-0.7-1.1c-0.3-0.2-0.7,0.1-0.9,0
            c-0.2-0.1-0.3-0.8-0.5-0.9c-0.7-0.4-1.4-0.3-1.8-0.5c-0.6-0.4-1.2-1-1.6-1.6c-0.1-0.2,0.1-0.6,0-0.7c-0.4-0.4-1.1-0.7-1.4-1.2
            s-0.1-0.9-0.7-1.4h-1.9c-0.3-1.1-1.5-1.3-1.8-2.5h-2.3c-1.1-0.7-2.1-1.6-2.8-2.7c0.5-4.1-1.8-8,1.1-10c0.3-2.8-0.8-4.3-3-4.6
            c1-2.3,1.5-5.8,4.6-6.2c0.5-0.8,1.4-1.5,2.3-1.8v-9.8c0.6-0.4,1-1,1.1-1.8c0.7-0.4,0.5,0.3,1.1,0.5h3.4c3.4-2,8.1-2.7,10.7-5.5
            C435.7,77.7,435.8,78.5,436.4,78.7z' />
        </g>
        <g id='West_Virginia'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('West Virginia')
          })} d='M577.3,178.5h0.5c-0.2,2.3,0.3,3.8,0.4,5.9c1.2,1.1,1.2,3.3,1.1,5.7c3.3-0.1,8-0.4,10.7-1.8
            c1.2,0.1,2.8-0.2,3.7,0.2c-0.4,2.6,1,4.7,0.9,7.5c0.2,0.4,1.2,0.1,1.8,0.2c1.7-1.6,1.9-4.7,5.5-4.4c0.2-1.3,1.6-1.5,1.8-2.9h4.5
            c0.8-0.3,0.8-1.4,1.4-2c1.8,0.5,1.3-1.3,2.3-1.6c2.2,2.1,7.9,3.2,5.3,7.8c-2.5-1.5-4.5-3.5-8.2-3.9c-0.1,1.4,0,4.3,0,5.7
            c-1.2,0.9-1.8,2.3-1.6,3.7c-1.2,0.4-1.8,1.4-2.8,2v2h-2.8c-0.3,2.4-0.9,4.7-1.8,6.9c-2.4,0.5-2.4-1.5-3.4-2.5
            c-0.9-0.2-1.8,0-2.5,0.7c0.5,3.4-0.9,4-0.9,6.8c-0.8,0.8-0.3,3-2,3c0.2,3.5-3.3,5.2-2.1,8.5c-0.3,0.5-0.4,1.2-0.9,1.4
            c-0.1,0.5,0.4,0.3,0.5,0.5c-1.7,1-4.4,0.9-5.2,2.9c-0.8,0-1-0.8-2.1-0.5c-0.8,2.1-1.4,3.2-3.6,3.9c-1.5,0.2-1.2-1.3-2.8-0.9
            c-0.8,0.8-1.6,1.5-2.3,2.3c-1.5,0.3-1.8-0.5-3.2-0.4c-1.1-0.4-1.5-1.6-2.5-2.1v-0.5c-2.7-2.5-6-4.4-8-7.6v-1.4
            c-1-0.7-1.9-1.4-2.8-2.1v-3.9c1.7,0.4,1.8-0.8,3.4-0.5v-2.9c0.7-0.3,1,0.2,1.6-0.4c-0.6-2.4,0.1-4.8,0.4-6.9
            c0.5-0.2,0.5-0.9,1.4-0.7v1.4c1,0.1,2.1-0.1,3-0.5c0.3-2.2-0.9-2.9-0.5-5.2c0.6-0.6,1.6-0.9,1.6-2.1c0.6-0.5,1.5-0.8,1.4-2
            c0.7,0.2,1,0.8,2.1,0.5c1.3-1.2,3.4-2.2,3.4-4.1c1.5-0.4,1.5-2.3,2.5-3.2C576.1,186.7,577.4,183.4,577.3,178.5z' />
        </g>
        <g id='Washington_DC'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Washington DC')
          })} d='M631.1,196.7c-1.6,0-4-1.5-4.5-2.8c0.6-0.6,2.7-0.6,3.7-0.7C631.5,194.1,631.1,196.7,631.1,196.7z' />
        </g>
        <g id='Washington'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Washington')
          })} d='M127.9,16.9c-0.3,3.1-2.2,7-2.5,11.2c-0.9,1.2-0.6,3.5-1.6,4.6c-0.1,1,0,2,0.2,3c-0.2,0.2-0.7,0.2-0.7,0.5
            c0.6,1.4-0.7,1.6-0.4,3.2c0.1,0.4-0.2,0.3-0.5,0.3c-0.6,4.7-2,7.6-2.5,12.5c-0.6,0.4-0.6,1.4-0.5,2.5c-1.2,3.8-1.7,7.8-1.4,11.8
            c-0.6-0.1-0.4,0.5-0.7,0.5c-4.7-1-8.1-2.4-13.2-3c-1.8-1.1-4.9-0.8-6.6-2c-1.7,0.1-4.4-1.4-5.7,0c-3-1.7-7.4,0.2-10.9-1.1
            C79,61.9,77,62.3,75,62.1c-0.1-0.7-0.9-0.7-1.1-1.4c-2.5,0.2-3.1,0.5-5.5,0.5c-2-0.7-2.8-2.7-5.7-2.5c-0.2-0.2-0.2-0.6-0.2-1.1
            c-0.9-0.3-0.9,0.4-1.4,0.5c-1.5,0.1-1.6-1.2-3-1.1c-0.7,0.3-1.3,0.9-1.6,1.6c-4.7,0.5-6.8-1.5-8.7-3.4c1-1.4,0.3-4.4,0.5-6.6
            c-1-1.6-1.4-2.8-2.8-3.9H43c-0.1-0.4-0.4-0.6-0.7-0.7c0.2-0.5,0.2-1.1,0.2-1.6c-1.9,0.4-3.2-2.3-4.6-0.7c-2-0.3-3.1-4.4,0.3-3.9
            c0.5-1.3,1.6-2.1,1.2-4.3c-0.6-0.3-1.7-0.1-2-0.7c-0.2-1.3,0.6-1.7,0.5-2.3h2.7c0.2-0.3,0.2-0.7,0.2-1.1c-1.3-1.6-2.8-2.9-2.5-6.1
            c0.2-0.6-0.6-0.2-0.5-0.7c0.5-3.2,0.7-6.4,0.5-9.6c-2.6-1.9-0.4-5.8-0.5-8.5c0.4-0.1,0.5-0.6,0.9-0.7c1.3,0.4,1.9,1.6,2.5,2.7
            c1.7,0.6,2.5,2.1,3.7,3.2c2,0.5,3.8,1.4,5.5,2.5c2.1,0.1,4.1,0.8,5.9,2H60c-0.2-1.2,0.3-2.6,0.9-2.3c-1,1.2,0.3,2.7,0.2,3.6
            c1.8,0.6,1.1,3.8,3.6,3.7c0.8-0.7,0.4-3-0.5-3.2c-0.5-2.3,1.5-4.3,0-5.9c2.8-2.3,0.4-6.9-1.1-9.1c-0.2-0.7,0.4-0.6,0.5-1.1
            c1.8,0.6,3.6,1.1,5.5,1.4c1.9,0.7,3.5,1.7,5.9,2c0.4,0,0.4,0.4,0.7,0.5h1.8c0.6-0.1,0.5,0.5,0.9,0.5c7.2,1,12.6,3.8,19.8,4.8
            c1.3,0.8,3.6,0.7,4.8,1.6c2.4,0.3,3.7,0.5,5.3,1.4c4.2,0.5,7.4,2.1,11.8,2.5C122.2,16,125.7,15.7,127.9,16.9z' />
        </g>
        <g id='Virginia'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Virginia')
          })} d='M655.5,206.7c-0.9,1.7-2.2,3-3,4.8v6c-0.6,1.3-0.9,2.7-1.1,4.1c-0.1,0.8-1.2,0.6-0.9,1.8
            c-1.1-0.3-1.1-1.8-2-2.3v-3.2c-0.2-0.6,0.6-0.2,0.5-0.7c-0.3-4,2-4.4,2-8.2c0.3-0.4,0.6-0.9,0.7-1.4
            C653.4,207.6,654,206.8,655.5,206.7z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Virginia')
          })} d='M610.1,190.3c2.3,1.5,4.7,2.2,6.9,3.9h1.2c0-1.5,0.6-2.2,0.7-3.4c1-0.2,0.8,0.8,1.8,0.5
            c-0.3,1.6,0.5,2.1,1.1,2.9c2.8-0.1,3.9,1.6,6.2,2c1.1,1.4,0.8,3.4-0.6,4.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,1.8-0.7,3.1,0,5.2
            c1.4,0,2.1,0.8,3.6,0.7c1.3,1.2,3.2,1.7,5.9,1.4c1.7,0.8,2.3,2.7,5,2.5c1.8,0.2,1.7,3.5,0.9,4.8c0.1,0.5,0.6,0.8,1.1,0.7
            c-0.4,2.2,1.2,2.3,1.1,4.3c-1-0.3-1.7-0.7-2.5-0.4c-0.5,1.8,1.2,1.5,0.9,3c1,1.1,2.7,1.4,2.5,3.7h-1.4c-0.2-2.7-3.2-2.5-4.1-4.5
            c-0.8,0-1.7-0.1-2,0.4c0.2,0.8-0.1,1,0.2,1.8h1.4c0.8,1.7,2.3,2.7,3,4.5h1.8c0.4,0.4-0.1,0.7,0,1.2c0.3,0.3,1,0.1,1.6,0.2v-1.8
            c0.9-0.7,2.7-0.5,3.6-1.2c1,1.8,2,3.4,2.8,5.3c-3.8,1.5-9.9,1.5-13.5,3.6c-3.5-0.4-5.2,1-8.4,1.1c-1,0.9-4.1,0-5.5,1.4
            c-1.9,0-3,0.9-5.3,0.5c-2.9,1-5.9,1.6-8.9,2c-0.6-0.1-0.2,0.6-0.7,0.5c-3.9,0.3-7.7,0.7-11.6,1.4c-0.6-0.1-0.4,0.5-0.7,0.5h-2.2
            c-0.6-0.2-0.2,0.6-0.7,0.5c-6.2,0.2-11.3,1.4-17.1,2c-2.5,1.3-7.8-1.5-9.1,1.4c-7.7,0-13.3,2-20.5,2.5c-0.1-0.7,0.7-0.5,1.2-0.5
            c2.1-1.3,3.8-3,6.2-3.9v-2c2.5-4.4,7.2-6.6,10-10.9c1.8,2.3,2.9,4.4,7.3,3.9c1.2-0.5,1.7-1.7,2.7-2.3c1.8,1.7,3.6,0.2,5.7-0.2
            c-0.1-1.4,0.6-2,1.1-2.9c0.8,0.2,0.7,0.6,1.4,0.5c0.9-0.5,1.6-1.3,2.1-2.1c2.1,0.4,2.6-0.7,3.9-1.1c-0.5-1.8,1.2-2.4-0.2-3.7
            c1.3-4,4.3-7.1,4.5-11.8c1.5-0.7,0.8-3.4,1.2-5.2c1.4,0.2,1.7,1.5,2.3,2.5h3.2c0.7-2.3,1.3-4.6,1.8-6.9h2.7
            c0.5-0.5,0.2-1.3,0.2-2.3c1-0.7,2-1.5,3-2.1c-0.1-1.7,0.3-2.9,1.6-3.2C609.8,194.1,609.8,192.2,610.1,190.3z' />
        </g>
        <g id='Vermont'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Vermont')
          })} d='M673.8,75.3c-0.3,1-0.1,2.5-0.2,3.7c1.3,1.2,1.9,3,1.6,4.8c-1.7,1.4-3.2,3-5,4.3c1,2.8,1.4,5.8,1.1,8.7
            c0.2,0.6-0.5,0.4-0.5,0.7c0,3.8-1.7,9.7,0.5,12.5c-0.1,1,0.3,2.6-0.5,2.8c0.2,0.9-0.5,2.8,0.5,2.9c-1.2,0.7-2.7,1-4.1,0.9
            c-1.3,0.8-2.8,1.2-4.3,0.9c0-1.5-0.1-3-0.5-4.4c-0.1-0.3-0.4-0.4-0.5-0.7s0.1-0.8,0-1.2s-0.5-0.6-0.5-0.7c-0.1-0.5,0.2-1.2,0.2-1.6
            s-0.6-0.5-0.7-0.9s0.3-0.6,0.2-0.9s-0.9-0.8-0.5-2c-0.1-0.4-0.7-0.4-0.7-0.9c-1.1,0.1-2,0-2.3-0.7c0.5-0.9,0.7-1.9,0.5-2.9
            c-1.5-1.3-1.3-2.7-2.5-4.4c0.8-2.7,0.4-5.2,0-8c-0.5-0.4-0.8-1-1.4-1.2c0.1-2.7-1.4-3.8-1.1-6.8c0.4,0,0.6-0.1,0.5-0.5
            C660.7,78.6,666.4,75.7,673.8,75.3z' />
        </g>
        <g id='Utah'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Utah')
          })} d='M139.8,144.9c0.6-0.1,0.4,0.5,0.7,0.5c5.5,0.3,11.8,2.6,18.5,2.9c0.7,0.9,2.8,0.4,4.3,0.5
            c0.2,0.2,0.1,0.8,0.4,1.1c4.7-0.4,9,1.1,13.5,1.4c-0.2,4.1-1.8,6.8-1.4,12.3c-0.5,1.6-1.6,4.2-0.7,6.1c3.8-0.3,5.9,0.8,9.6,0.9
            c0.6-0.2,0.4,0.5,0.7,0.5c1.8,0.2,3.3-0.2,4.8,0c0.4,0,0.4,0.4,0.7,0.5s0.8-0.1,1.2,0s0.8,0.5,1.1,0.5c0.8,0.1,1.7-0.3,2.7-0.2
            c0.1,0,0.2,0.5,0.4,0.5c1.1,0.4,2.6-0.2,4.1,0c0.7,3.3-1.7,5.9-0.7,9.4c-1.1,0.9-0.6,2-0.5,3.4c-0.8,0.6-0.5,2.2-0.5,3.6
            c-0.7,2.1-1,4.2-1.1,6.4c0.1,0.6-0.6,0.2-0.5,0.7c0.3,3.6-0.5,5-0.4,8.5c0.1,0.6-0.5,0.4-0.5,0.7c0,2.5-0.5,4.9-1.6,7.1
            c0.2,5.2-1.1,11.7-1.4,17.6c-1.1,2-0.1,5-1.4,6.9c0.3,2.1-0.6,3.1-0.7,4.8c-3.1,0.3-4.5-0.9-7.6-0.5c-0.6,0.2-0.4-0.5-0.7-0.5
            c-4.2,0.4-6.3-1.4-10.1-1.4c-0.6,0.1-0.4-0.5-0.7-0.5h-2.9c-0.6,0.2-0.2-0.6-0.7-0.5c-4.8,0-7.3-1.3-12.1-1.4
            c-0.5-0.7-1.8-0.5-3-0.5c-0.6,0.2-0.2-0.6-0.7-0.5c-1.6-0.2-3.3-0.2-5-0.4c-0.4,0-0.7-0.5-1.1-0.5c-0.6-0.1-1.4,0.1-2,0
            c-0.3-0.1-0.5-0.5-0.9-0.5c-1.3-0.3-2.9-0.2-4.1-0.5c-0.4-0.1-0.6-0.5-0.9-0.5c-1.6-0.2-3.1-0.1-4.6-0.4c-0.3,0-0.4-0.4-0.7-0.5
            c-0.6-0.2-1.3,0.1-1.8,0c-0.2,0-0.5-0.5-0.7-0.5c-1.7-0.3-3.3-0.2-4.8-0.5c-0.8-0.2-1.4-0.8-2.3-0.5c0.1-1-0.3-2.3,0.5-2.5v-2
            c0.8-2.4,1.3-4.8,1.4-7.3c0.6-0.4,0.6-1.4,0.5-2.5c0.8-0.6,0.4-1.7,0.5-2.5c0.1-0.3,0.5-0.4,0.5-0.7c0.3-1.1,0.1-2.3,0.4-3.6
            c0.2-0.9,1-1.6,0.5-2.7c0.9-1.3,0.5-4,1.6-5.2c-0.2-0.7-0.2-1.4-0.2-2.1c0.7-1.9,1-4,1.1-6c0.7-0.5,0.5-1.8,0.5-3
            c-0.1-0.6,0.6-0.2,0.5-0.7c-0.4-4.6,2.1-7.3,2-12.1c0-0.5,0.5-0.4,0.5-0.9c0-0.7,0-1.3-0.2-2c1-1.5,0.6-4.3,1.6-5.7
            c0-2.9,0.9-3.9,0.9-6.6c-0.1-0.6,0.5-0.4,0.5-0.7v-2c0.7-2.1,1.6-5.4,1.4-7.3c1-1.5,0.6-4.3,1.6-5.7
            C139.9,146.2,139.6,145.2,139.8,144.9z' />
        </g>
        <g id='Texas'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Texas')
          })} d='M274.4,261.1c4.9-0.4,8.6,1.1,13.2,0.4c0.6-0.2,0.2,0.6,0.7,0.5c9.2,0.5,18.6,0.7,28,1.1
            c0.3,5.5-0.9,9.3-0.5,14.8c0,0.5-0.5,0.4-0.5,0.9c0.6,6.9-1.5,13.2,0,19.2c3.1,0,2.6,3.7,6.2,3.2c0.5,0,0.4-0.6,0.4-1.1
            c0.9,0.3,1,1.4,2.5,1.1c0.5-0.1,0.6-0.4,0.5-1.1c1.2,1,0.9,2.1,1.2,3.7c0.7,0.4,2,0.1,3,0.2c1.7,1.4,3.9,1.9,6,1.6
            c1.1,0.2,1,1.6,2.5,1.4c0.8,0,0.5-1.2,1.1-1.4c0.5,0.8,2,0.4,2.7,0.5c0,1.4,1.1,2.5,2.4,2.5c0,0,0,0,0.1,0c0.5,0.6-0.4,0.8-0.5,1.2
            c0.8,2.2,4.4,0.8,5.2-0.4c0.7,0.5,1.5,0.7,2.3,0.5c-0.3,1.2,0.4,1.4,0.7,2c2.5-0.1,2.5,1.7,5.5,1.4c1-0.6,1.2-1.9,1.8-2.8
            c1.3,0.8,2.9,1.1,4.5,0.9c1,0.2,1.8,1.5,3,0.9c0.5,0,0.2,0.8,0.5,1.1h3c0.8-0.4,1.4-1.2,1.6-2.1c1.2-0.7,3.6-0.2,5.3-0.4
            c0.6-0.4,1.2-0.7,1.8-0.9c0.7,1.2,2.9,0.9,4.8,0.9c0.4-0.4,0.8-0.9,0.9-1.4c1.8,1.1,3.4,2.4,4.8,3.9c2.2-0.2,2.8,1.1,4.8,1.1
            c0.7,1.7,3.9,2.8,5.2,0.9c0.2,0.6,1.2,0.5,1.8,0.7c-0.5,8.1,1.1,15.1,0.3,23c0.8,0.7,0.5,2.5,0.5,3.9c1.5,1.9,3,3.6,3,6.9
            c1.1,1.8,2.5,3.4,3.6,5.2c-0.5,0.7,0.1,1.9-0.2,2.5c0,0.5,0.6,0.4,1.1,0.4c0.5,3.9-0.5,7.9-3,11c0.3,0.4,0.6,0.7,1.1,0.9
            c-1.3,1.7,0,5.5-0.5,7.8c-0.2,1-1.2,1.5-1.4,2.1c-0.1,1.2,0.2,2.8-0.2,3.7h-1.8c-3.7,1.6-7.5,3.1-10.9,5c-0.2,0.6,0,0.6,0,1.2
            c-2.4,1.4-4.3,3.3-6.8,4.6c-4.5,5.5-12,7.8-18.3,11.4c-0.6,0.9-1.5,1.5-2.1,2.3c-2.3,1.1-4.1,2.7-6.4,3.7v1.2c-1.1,0.8-1.9,2-3,2.8
            v1.2c-2.6,2.7-3.8,6.8-5.8,10.1v1.6c0.1,0.6-0.5,0.5-0.5,0.9v1.8c0.1,0.6-0.5,0.5-0.5,0.9v2c1.4,2.4,0.9,5.9,2.5,7.8v2.6
            c0.6,1.2,0.8,2.8,1.6,3.7c-0.7,0.4-1.3,1-1.8,1.6c-2.8,0.1-3.5-1.8-5-3c-2.1,0.2-4.6-1.5-6.2,0c-1.8-0.8-4.4-1.3-5.3-3.4
            c-1.5,0.1-1.8-0.9-3.6-0.5c-3-2.5-8.5-1.6-8.2-6.8c-0.5-0.6-0.4-1.9-1.1-2.3c-0.1-0.7,0.2-1.7-0.2-2.1c-1-0.8-2.1-1.4-2.7-2.5
            c0.5-1,0.3-2.8,0.3-4.3c-0.1-0.4-0.8-0.3-1.1-0.5c0.2-2,1.2-3.7,0.2-5.5c-5.1-1.4-6.9-6.1-9.1-10.3c-1-0.4-2-1-2.8-1.8
            c-1-4.9-3-8.7-5-12.6v-2.1c-2.3-3.9-6.4-5.9-8.4-10.1c-0.9-0.7-1.6-1.6-2.5-2.3c-4.3,0.5-6.3-1.2-10.5-0.9c-1.4-0.5-2-1.7-3.7-2
            c-2,1.5-5.4,1.7-7.1,3.6c-1.4,4.5-3.9,8.5-7.3,11.8c-2.3-2.2-6.3-2.7-7.8-5.7c-1.1-0.2-1.7-1.2-3.4-1.2c-3.5-3.1-8.1-6.1-9.6-11.8
            v-6.1c-1.1-2-1.9-4.3-2.9-6.4c-0.6-1.1-2-1.4-2.1-2.9c-1.4-0.4-2.3-1.3-3.7-1.6c-2.7-3.4-5.9-6.2-8-10.1c-0.5-0.3-0.9-0.8-1.2-1.2
            h-1.2c-0.4-0.5-0.9-1-1.4-1.4c-0.5-2.8-1.6-4.9-4.1-5.7c-0.3-0.9-1.1-1.3-0.9-2.7c0.3,0.1,0.3-0.1,0.4-0.4c2.4-0.5,4.7,0.8,8,0.4
            c0.6-0.1,0.2,0.6,0.7,0.5c1.1,0.2,2.2-0.2,3.2,0c0.4,0.1,0.5,0.5,0.9,0.5c1,0.1,2-0.1,3,0c0.5,0.1,0.8,0.5,1.2,0.5
            c2.4,0.4,5.2,0,7.6,0.4c0.4,0,0.5,0.4,0.9,0.5c1.5,0.4,3.5-0.4,5.3,0c0.3,0,0.8,0.5,1.1,0.5c1.8,0.3,3.5-0.2,5.2,0
            c0.3,0,0.5,0.5,0.9,0.5c1,0.3,2.2-0.1,3.4,0c3.6,0.4,7.2,0.5,10.9,0.4c1.5-2.1,0-7.2,1.2-9.4v-7c-0.1-0.5,0.5-0.5,0.5-0.9
            c-0.1-3.4,0.7-5.6,0.4-9.4c-0.2-0.6,0.5-0.4,0.5-0.7c-0.4-3.3,0.9-5,0.5-8.2c1.3-4.5,0.8-10,0.9-15.8c-0.2-0.6,0.6-0.2,0.5-0.7
            c0.5-4.4,0.6-8.8,0.5-13.2c0-0.5,0.5-0.4,0.5-0.9C273.7,269.1,274.8,265.1,274.4,261.1z' />
        </g>
        <g id='Tennessee'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Tennessee')
          })} d='M572.4,247.2v3.2c-2.4,0.8-2.8,3.6-4.1,5.5c-2.4-1.4-4.4,1.6-5.7,3c-0.5-0.4-0.5-1.3-0.7-2
            c-2.3,0.5-2.5,3-4.8,3.6v2.7c-1.6,0.4-2.3,1.7-4.3,1.6c-0.3,0.3-0.2,0.9-0.2,1.4c-1.3,0.9-2.4,2-4.5,2.1c-1.7,1-3.3,2-5,3v2.9
            c-1.3,0.4-2.7,1-3.9,1.6v4.3c-3.7-0.1-7.2,0-9.8,1.1c-3-0.3-4.5,0.9-7.7,0.5c-0.6-0.1-0.2,0.6-0.7,0.5c-3.2,0.4-6.3,0-9.3,0.4
            c-0.5,0.1-0.8,0.4-1.2,0.5s-1.2-0.1-1.8,0c-0.4,0.1-0.9,0.5-1.2,0.5c-2.8,0.4-5.6-0.3-8.7,0c-0.8-0.2-0.6,0.6-1.2,0.5
            c-7.7-0.1-13.6,1.5-21.2,1.4c-0.6-0.2-0.4,0.5-0.7,0.5c-2,0.2-5-0.6-6.1,0.5c-5-0.3-8.6,0.6-13,0.4c0.4-0.6,1.5-0.4,1.6-1.2
            c0-1.9-0.3-3.8-1.1-5.5c0.4-1.3,2.1-1.3,3-2.1c0-3.5,0.4-5.3,2-7.1c0.1-3.9,2.7-5.2,2-10c6.8,0.2,13.4-1.1,20.7-1.1
            c0.5-0.8-0.1-2.7,0.3-3.6c4.5,1.1,10.4-0.8,16.6-0.9c0.6,0.2,0.4-0.5,0.7-0.5h4.1c0.8,0.2,0.6-0.6,1.2-0.5
            c5.2-0.3,11.8,0.8,15.7-0.9h2.5c0.6,0.2,0.4-0.5,0.7-0.5h9.8c0.6,0.2,0.2-0.6,0.7-0.5h4.3c3.1-1.2,6.5-1.7,9.8-1.4
            c0.6,0.2,0.4-0.5,0.7-0.5h3.7c2.3-1.3,4.7-1.2,8.4-1.4c0.6,0.2,0.4-0.5,0.7-0.5h4.8C570.5,248.2,570.6,246.9,572.4,247.2z' />
        </g>
        <g id='South_Dakota'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('South Dakota')
          })} d='M365.2,97.4v2c-1.2,0.8-2.2,1.9-3.6,2.7c1,2.6,2.5,4.7,5,5.7v31.5c-0.7-0.1-1.5,0.2-2,0.7
            c-0.1,1.7,0.1,3.5,0.5,5.2c0.2,0.5,1.5-0.2,1.4,0.5c0.2,0.8,0,1.5-0.5,2.1c0.7,2.6-1.6,4.3-1.4,6c0.1,1.6,1.6,1.6,2,3.4
            c-1.5,0.1-2.2-1.1-2.3-2.9c-2.4-1.7-6-2.2-8.2-4.1c-3.3,0.4-7.2,0.2-9.1,2c-1.8-1.8-4.6-2.5-6.2-4.5c-3-0.3-6.1,0.4-9.1,0
            c-0.4,0-0.7-0.4-1.1-0.5c-3.2-0.8-7.1,0.5-10.7,0c-0.4-0.1-0.8-0.4-1.2-0.5c-2.7-0.6-5.4,0.3-7.8,0c-0.4-0.1-0.7-0.4-1.1-0.5
            c-2.7-0.7-6.2-0.1-9.4-0.4c-3.2-0.3-6.4,0.6-9.1,0c-1.6-0.5-3.3-0.7-5-0.5c-2-1.1-5.7-0.3-8.5-0.5c-1.5-1-4.6-0.4-7-0.5v-7.1
            c-0.2-0.8,0.6-0.5,0.5-1.1c-0.2-7.3,1.2-11,0.9-18.2c-0.3-0.8,0.6-0.5,0.5-1.1v-6.4c0.5-0.6,0.3-1.1,0.5-2.3
            c0.1-0.3,0.4-0.4,0.5-0.7s-0.1-0.8,0-1.2c0.1-0.3,0.3-0.5,0.5-0.7c0.4-3.4-0.6-7.5-0.2-11.4c0.6,0.2,0.4-0.5,0.7-0.5
            c12.5,0.3,23.2,1.9,36.3,2c0.8-0.2,0.4,0.7,1.1,0.5c11.7,0.4,23,1.2,35.4,0.9c0.8-0.2,0.4,0.7,1.1,0.5L365.2,97.4z' />
        </g>
        <g id='South_Carolina'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('South Carolina')
          })} d='M589.6,274.5v1.6c4.3,1.2,7.7-1.4,13-0.7c0.6,0.2,0.4-0.5,0.7-0.5h3c0,0.6,0.8,0.4,0.7,1.1
            c2.9,0.7,4.2,2.9,6.8,3.9c2.6,2.6,5.6,3.7,8,6.4c1.4-0.1,1.4,1.4,2.5,1.1c-3.9,2.9-7,6.6-6.4,14.1c-0.6,0.1-0.6,1-1.6,0.7
            c-0.1,0.9-0.5,1.8-1.1,2.5h-1.6c-1,0.7-1.6,1.7-1.8,2.9c-2.9,2.9-5.5,6-8.7,8.5c-0.2-0.2-0.8-0.2-1.2-0.2c-0.7,0.4-0.4,2.2,0.2,2.5
            c-0.8,1.2-1.8,2.2-2.8,3.2c-1.3,1.3-2.3,3.2-5,2.9c-0.2-0.5-0.6-0.9-1.1-1.1c0.4-2.5-1.5-2.9-1.4-5.2c-0.5-0.7-1.8-0.6-1.8-1.8
            h-1.8c0-0.7,0.2-1.6-0.5-1.6c0.2-2.2-2.2-4-2.3-5.9c-0.3-0.9-1.4-0.8-2-1.4h-1.2c-1.2-1-3.5-1.9-3.2-3.9c-1-2.2-4.1-2.3-5-4.6
            c-0.6-0.6-0.7-0.9-1.6-0.9c0.3-1-0.5-0.8-0.5-1.4h-0.8c-0.7,0-0.7-0.7-1.2-0.9c-4.1-0.5-3.7-4.6-6.2-6.6c0.3-1-0.8-1-0.3-1.6
            c-0.5-0.4-0.9-0.9-1.4-1.2h-2.5c-1-1.5-3-1.8-4.6-2.7c0.2-1.8,0.9-3,2.5-3.4v-1.8c1.4-0.2,3-0.2,3.2-1.6h1.7
            c2.1-1.7,4.8-2.6,7.3-3.9h4.8c0.6,0.2,0.2-0.6,0.7-0.5c4.5,0.5,9.4-1,12.8,0C587.5,273.7,588.8,273.9,589.6,274.5z' />
        </g>
        <g id='Rhode_Island'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Rhode Island')
          })} d='M689.5,125.5c0.1,1.7,1.8,1.8,1.6,3.7c0.7,0.1,0.9,0.8,1.8,0.7c0.3,1.3,1.3,1.8,1.6,3
            c-0.6,0.7-1.6,1.1-2.5,0.9c-0.8,2.1-2.7,3.1-4.8,3.9c0.4-4.9-1.5-7.6-2-11.2C686.6,126.3,688.1,126,689.5,125.5z' />
        </g>
        <g id='Pennsylvania'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Pennsylvania')
          })} d='M639.5,138c0.5,0.6,0.7,1.5,1.2,2h1.8c0.3,1.7,1.8,2.3,2,4.1c0.5,0.7,1.2,1,1.6,1.8c1.3,0,2.8-0.2,3.4,0.5
            c-0.4,0.5-0.7,1.2-1.4,1.4c0.1,2,0,3.8-1.6,4.1c0.4,1.6-0.4,1.5-0.3,3c0.2,0.7,1.1,0.6,0.9,1.8c-0.3,0.5-1.1,0.6-1.4,1.1
            c-0.3,1.9,0.9,2.4,0.5,4.5h2.3c0.7,3.1,4.1,3.5,5.5,5.9c-2.2,1.1-3.4,2.3-3.9,4.8h-0.9c-0.2,1.8-1.8,2.1-3.9,2
            c-1.4,0.7-2.2,2.1-3.4,3c-3.3,0.6-7.3,0.5-9.8,2c-2.8-0.2-4.3,0.9-6.2,1.6c-5.4,0.1-11.3,2.3-18,2.3c-0.5,0-0.4,0.6-0.4,1.1
            c-2.1,0.1-3.5,0.9-6,0.5c-2,0.5-3.9,1.5-7.3,1.4c-0.6-0.1-0.2,0.6-0.7,0.5c-2.4-0.5-4.2,0.7-5.9,0.9c-0.8,0.1-1.6-0.1-2.3,0
            c-0.2,0.1-0.5,0.5-0.7,0.5c-1.3,0.2-2.9-0.2-4.1,0.5c0-2.1,0.4-4.7-1.1-5.3c0.5-1.5-0.8-3-0.4-5.5c0.2-0.6-0.5-0.4-0.5-0.7
            c0.4-5.6-2.1-6.7-1.4-12.1c0.2-0.6-0.5-0.4-0.5-0.7c-0.1-1.1,0.4-2.7-0.5-3c-0.1-1.2,0.4-3.1-0.5-3.6c-0.1-1.1,0.3-2.7-0.5-3
            c0.6-2-0.7-2.8-0.4-5c2.8-1.6,5.4-3.6,7.7-5.9c0.1,0.9-0.1,2.9,0,4.3c2.3,0.8,3.7-0.8,6.2-0.4c0.6,0.1,0.2-0.6,0.7-0.5
            c2.5,0.1,4.2,0.1,5.9-0.9h3.2c2.3-1.7,7.1-1,9.6-2.5c2.3-0.6,5.5-0.3,7.3-1.4h1.4c0.6,0.1,0.2-0.6,0.7-0.5c1.7,0.1,3.5-0.2,5-1.1
            c1.8-0.1,3.7-0.1,4.8-0.9c4.2,0,7-1.6,10.9-1.9C638.3,138.1,638.9,137.9,639.5,138z' />
        </g>
        <g id='Oregon'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Oregon')
          })} d='M41.2,43.4c0,0.9,0.3,1.7,0.7,2.5c6-1.5,6.3,6.6,4.3,9.8c1.6,1.9,3.8,2.4,5.3,4.1h5.4c0.6-0.4,1.1-1,1.4-1.6
            c0.9,0.9,2.1,1.3,3.4,1.1c2.2,0.9,4.8,1.4,6.2,3c1.7-0.2,4.1,0.4,5-0.5c0.1,0.7,1,0.7,1.1,1.4c2.6,0,5.6,0.2,7.1-0.9
            c3.3,1,7.5,0,10.9,0.9c4.1-1.4,8.2,2,13.2,2c0.6-0.2,0.4,0.5,0.7,0.5c3.3-0.3,5,2,8,1.4c0.1,0.1,0.1,0.5,0.4,0.5
            c1.7-0.3,2,0.9,3.7,0.5c-0.2,1.2,0.9,1.1,0.5,2.5c1.4,1.8,3.7,2.2,2.9,5.9c-0.9-0.2-0.9,0.4-1.4,0.5c0.3,1.3-1.2,1-0.9,2.3
            c-2,0.7-1.7,3.6-3.9,4.1c-0.1,0.5-0.3,0.9-0.7,1.2c-0.1,0.5,0,1.1,0.2,1.6c-0.2,0.8-1,1-1.4,1.6c-0.3,0-0.7,0.1-0.7-0.2
            c-1.9,1.4-2.8,3.8-4.4,5.3c0.6,2.4-1.2,3.3,0.2,4.8h1.7c0.9,2.1,0.3,4.4-1.4,5.9c-1.2,7.8-3.8,14-4.8,21.7c-0.5,0.7-0.7,0.7-0.5,2
            c-0.9,2.6-1.6,5.2-2.1,7.8c-2.3-0.1-3.8-1-6.2-0.9c-0.6,0.2-0.4-0.5-0.7-0.5c-8.3-0.8-14-3.6-21.5-4.5c0.1-0.4,0-0.7-0.2-1.1
            c-5.6-0.1-8.7-2.7-14.2-2.8c-3.9-1.7-8.8-2.4-12.6-4.1c-2.9,0.3-5.4-2.1-8.4-1.8c-0.1-1-0.7-0.3-1.4-0.5c-0.3-0.1-0.9-0.9-1.4-1.1
            c-0.3-0.1-0.6,0.1-0.9,0c-1.2-0.3-2.4-0.5-3.6-0.5c-0.4-0.1-0.2-0.4-0.5-0.5c-0.9-0.3-2.2,0.1-2.5-0.9H25c-2.1-1.5-4.9-1.2-7.1-2.5
            c-2.5,0-3.5-1.5-6-1.4c-0.6-1.2-1.3-1.8-1.6-3.6c0.9-2.1,1.2-4.5,0.9-6.8c0.8-0.7,0.5-2.6,0.5-4.1c0.6-0.8,0.8-2,2-2.3
            c0.1-0.3,0.1-0.6,0-0.9c2.2-2.2,3.2-5.6,5.5-7.7c1-3.5,3.2-5.4,3.9-9.2c0.9-0.9,1.5-2.1,1.4-3.4c1.2-0.6,0.9-2.4,1.6-4.1
            c0.2-0.6,1-1.7,1.4-2.7c0.1-0.3-0.1-0.8,0-1.1c0.2-0.6,1-1.1,1.1-1.4c0.4-1.2,0.2-2.3,1.4-2.8v-1.2c1.4-1.5,1.8-4,2.5-6.2
            c0.8-1.5,1.2-3.2,1.3-4.9c0.6-1.8,1.7-3.2,2-5.3C37.9,44.4,38.9,43.3,41.2,43.4z' />
        </g>
        <g id='Oklahoma'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Oklahoma')
          })} d='M276,252c3,1.2,8.1,0.2,11.9,0.5c1.5,1,4.6,0.3,7,0.5c3.5,1.5,10.4-0.4,13.9,1.1c2.5-0.1,5.5,0.2,7.6-0.2
            c0.5,0,0.2,0.9,0.9,0.7c4.2,0.6,8.8-0.7,13.2-0.2c0.4,0,0.7,0.5,1.1,0.5c3.7,0.7,8.3,0,12.3,0c4.2,0,8.8-0.7,12.3,0
            c0.3,0.1,0.7,0.5,1.1,0.5c4.2,0.6,9.2,0,14.1,0c4.8,0,9.9,0.6,14.1,0c0.5-0.1,0.8-0.4,1.2-0.5c1.5-0.3,3.1,0.2,4.6,0
            c0.4,2.3,0.1,5.3,0.2,7.8c1,2.3,0.5,6.8,2,8.2c-0.1,3.3-0.1,6.6,1.1,8.7v34c-1.7-0.4-2.4-1.5-4.8-1.4c-1.7-1.4-3.2-3.1-5.3-4.5
            c-0.6,0.6-1.3,1-1.4,2h-2.7c-0.2-0.5-0.6-0.8-0.7-1.4c-1.5,0-1.9,1-2.9,1.4c-2.1-0.7-2.6,0.1-5.2,0c-1.1,0.5-1.2,1.9-2.1,2.5
            c-1.4,0.3-1.8-0.5-2.3-1.1c-2.6,0.3-3.1-1.5-6.1-0.9c-0.3-0.9-1.1-1.3-1.1-2.5c-1.9,0.7-2.1,3.1-3.4,4.5c-1.7-0.2-2.7-1.3-4.1-1.8
            c-0.6-0.5-0.4-1.2-1.2-1.8c-1.8,0.2-2.2,0-3-0.9c-0.9,0.6-1.2,1.9-2.7,2c-0.9-0.3,0.2-2.6-0.9-2.7c-0.3-0.9-0.9,0.8-1.6,0
            c-0.5-0.3-0.3-1.3-0.4-2.1c-1.4-0.3-3.3,0-3.9-1.1c-0.5,0.4-1,0.9-1.4,1.4c-0.9,0-0.9-0.9-1.6-1.1c-2.4,0-4.9,0.1-5.7-1.4h-2.7
            c0-0.5,0-1-0.5-0.9c-0.2-0.8,0.8-0.5,0.5-1.4c-0.6-1.2-1.9-1.8-3.2-1.6c-0.8-0.3,0,0.9-0.5,0.9c-0.7-0.1-0.6-1.1-1.6-0.9
            c-1-0.1-1,0.8-1.6,1.1c-1.5-1.3-3-2.6-4.6-3.7c0.4-1.8,0.1-4.4,0.2-6.6c-0.2-0.8,0.7-0.4,0.5-1.1c-0.3-6.5,0.5-10.8,0.4-17.1
            c-0.3-0.8,0.6-0.5,0.5-1.1V262c-9-0.5-18.5-0.5-28.1-1.1c-0.8,0.2-0.4-0.7-1.1-0.5c-4.7,0.4-7.9-0.7-12.3-0.5
            C275.9,257.3,275.6,254.3,276,252z' />
        </g>
        <g id='Ohio'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Ohio')
          })} d='M573.2,151.1c0.8,2.6,0.5,5.4,1.6,7.7c0.1,1.2-0.4,3.1,0.5,3.6v2.3c-0.2,0.6,0.6,0.2,0.5,0.7
            c-0.6,5.2,1.6,6.7,1.4,11.6c-0.4-0.1-0.7,0-1.1,0.2c0.5,2.4-0.5,4.8-0.4,8.7c0.2,0.6-0.5,0.4-0.5,0.7v4.3c-0.5,1.2-1.4,2.3-2.5,3
            c-0.2,1.5-1.2,2.7-2.5,3.4c-1.1,0.3-0.9-0.7-2-0.5c-1.6,1.1-2.2,3.5-3.9,4.8v3.2c0.4,0.3,1.1,2.1-0.2,2c-0.5-0.3-0.5-1.3-1.1-1.6
            h-1.8c-1.4,1.6-1.5,4.4-1.4,7.8c-0.6,0.1-1.2,0.4-1.6,0.9c0.2,0.6,0.2,1.3,0.2,2c-2.4,2.2-4.2-1.3-6.6-1.4c0.3-1.4-0.5-1.6-0.5-2.7
            c-0.7-0.3-1.7,0.3-2.5-0.2c-0.8,0.8-1.7,1.6-2.5,2.5c-1.6-0.4-5.3-1.1-6,0.5c-1.8,0.4-1.7-1.2-2.3-2h-5.3c0-1.8-1-2.7-1.6-3.9
            c-0.8,0.1-1.4,0-1.6-0.5c-2.2-0.3-2.9,0.9-5.2,0.5c-0.2-1.2,0.5-3.3-0.5-3.7c-0.2-1.5,0.5-3.8-0.5-4.5v-2.3
            c-1.3-0.9,0.3-4.8-1.1-5.7c0.8-5.5-1.4-9.6-0.9-15.5c-0.1-0.7-0.8-0.8-1.1-1.4c0.3-4-0.6-4.9-0.4-8.9c-0.6-0.6-0.6-1.8-0.5-3
            c3.5-0.5,7.2-1.5,10.7-1.4c2.4-1,5.1-1.4,7.7-1.1c1.4,1.4,4,1.5,6.9,1.4c1.9,0.7,2.4,2.8,5.3,2.5c1.7-1.3,4.2-1.7,6.1-2.9
            c1.6,0.3,2.1-0.5,3.4-0.5c3.8-3.8,7-8.2,12.8-10C572.3,151.1,572.7,151.1,573.2,151.1z' />
        </g>
        <g id='North_Dakota'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('North Dakota')
          })} d='M357.7,43.4c0.5,1.9,1.2,3.6,1.6,5.5c-1.1,6.1-0.8,13.8,2.5,16.9c-0.2,1.1-0.6,3.7,0.5,4.1
            c-0.8,5,0.7,9.2,0.4,14.4c1,3.5,3.2,6.7,2.3,11.9c-12.8,0.3-24.1-0.9-37-0.5c-1,0.3-0.6-0.7-1.4-0.5c-8.2,0.4-14.5-1.3-22.8-0.9
            c-2.3-1-6.3-0.3-9.4-0.5c-0.9,0.3-0.6-0.8-1.4-0.5c-6.5,0.1-12-1.2-17.6-1.1c0.2-4.1-0.7-9.2,1.1-11.7c-0.3-4.6,0.6-7.2,0.4-11.9
            c-0.3-0.9,0.7-0.6,0.5-1.4c0.1-1.4-0.2-2.7,0-4.1c0.1-0.4,0.5-1,0.5-1.4c0.2-1-0.2-2.1,0-3c0.1-0.5,0.5-0.9,0.5-1.4
            c0.3-2-0.2-4,0-6.1c0-0.5,0.5-0.8,0.5-1.2c0.2-0.9-0.3-2.1-0.2-3c0.1-0.5,0.4-0.9,0.5-1.4c0.3-1.5-0.2-3.2,0-4.8v-0.5
            c5.7,0.2,10.7,1.1,16.9,0.9c1-0.3,0.6,0.7,1.4,0.5c12.3,0.2,23.1,2,36.1,1.4c1-0.3,0.6,0.7,1.4,0.5L357.7,43.4z' />
        </g>
        <g id='North_Carolina'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('North Carolina')
          })} d='M653.4,233.9c0.2,2.4,2.7,4.9,2.8,6.9c-0.7-0.1-1-0.7-1.4-1.1h-3c-2.2,1.8-5,3.1-7.7,4.5v2
            c2.5,0.5,3.1-1.3,5-0.5c0.9-1,3-2.1,4.6-0.9c1,0.1,0.9-1,1.8-1.1c1.4,0.2,1,2,2.5,2.1c0.2,0.9,0.3,1.9,0.5,2.9c-0.7,1-2,1.3-3,2
            v2.3c-1,0.3-1,1.6-1.8,2.1c-1,0-2.2-0.2-2.5,0.5c-1.1-0.5-2.3-0.6-3.6-0.5c-0.2,0.2-0.2,0.8-0.2,1.2c0.6,0.4,1.9,0,2,0.9
            c-0.5,1.5-0.9,3.1-1.4,5c0.5,0.8,2.3,0.2,3.4,0.4c0.3,0.3-0.2,0.5,0.2,0.9h1.8c-0.7,1.7-1.3,3.5-2.7,4.5c-0.8-0.2-1.4-0.8-2.7-0.5
            c-0.6-0.1-0.4,0.5-0.7,0.5c-1.3-0.3-1.2,0.8-2.5,0.5c-4.2,2.7-8.4,6.1-10.7,10.9c0.2,0.7,0,1.4-0.5,2c0.4,1.4-0.6,1.3-0.4,2.9
            c-0.5,0.2-0.7,0.8-1.2,1.1c-3.1-0.2-4.6,1.1-6.6,2c-6.4-4.5-12.4-9.3-19.2-13.4c-4.4,0.2-8.3,0.8-12.6,1.1
            c-0.5,0.6-1.5,0.6-2.7,0.5c-0.2-0.2-0.2-0.8-0.2-1.2c-1.3-1-2-2.8-3.7-3.2c-3.7-0.8-8.4,1-12.5,0.4c-1,0.9-3.4,0.4-5.2,0.5
            c-1.7,0.6-3.4,1.3-5,2c-0.5,0-0.2,0.8-0.5,1.1c-1.9-0.2-2.4,1.5-3.7,0.9c-0.8,0.5-1.6,1-2.3,1.6c-5.1,0.6-9.2,2.2-15.1,2
            c-0.6,0.5-1.4,0.7-2.1,0.5c-0.4-0.8-0.1-2.3-0.2-3.4c1.2-0.5,2.8-0.6,3.9-1.2V272c0.6,0.1,0.7-0.3,1.2-0.2c1.7-3,7.3-2,8.2-5.9
            c1.8-0.1,2.3-1.5,4.3-1.4c0.4-0.6,0.1-1.8,0.2-2.7c0.9-1,1.8-2,2.8-2.9c1,0.2,1.3,1.2,2.7,1.1c0.8-1.4,1.5-3,3.2-3.6
            c0.7,0.5,1.6,0.7,2.5,0.5c0.4-1.8,1.2-3.5,2.3-5h1.6c0.5-1.1-0.2-3.4,0.4-4.5c5.5,0.3,9-1.5,14.2-1.4c0.6,0.2,0.2-0.6,0.7-0.5h4.3
            c1.5-1,3.5,0,5.5-1.4c1,0,2.3,0.3,2.5-0.5c10-0.3,16.6-3.5,26.2-3.9c0.5,0,0.2-0.8,0.5-1.1c2.2-0.2,4.9,0,6.4-0.9
            c1.1-0.1,2.7,0.3,3-0.5c4,0.2,3.8-1.8,7.7-1.4c0.6,0.2,0.2-0.6,0.7-0.5C649,235.8,650.1,233.8,653.4,233.9z' />
        </g>
        <g id='New_York'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('New York')
          })} d='M651.9,80.6c-0.5,3,1.4,3.8,1.1,6.6c0.6,0.3,0.7,1.1,1.4,1.2c-0.3,2.2,0.9,2.8,0.5,5c0.2,0.6-0.6,0.2-0.5,0.7
            c0,3.7,1.6,5,2.5,7.5c-0.5,0.9-0.7,1.9-0.5,2.9c0.8,0.6,1.8,0.8,2.8,0.7c0.5,1.2,0.5,2.9,1.2,3.9c0,0.4-0.3,0.5-0.2,1.1
            c1,1.3,0.7,3.9,1.6,5.2c0.5,5-0.6,10.8,0,15.1c0,0.2,0.5,0.5,0.5,0.9s-0.2,0.6-0.2,0.7c0.1,0.1,0.7,0.4,0.7,0.5
            c0.1,0.6-0.7,1.7,0.3,2c0.2,1.2-0.4,3.2,0.5,3.6c0,2.9,0.9,4.9,0.5,8.2c-0.2,0.6-0.8,0.7-1.1,1.2c-0.2,1.5,1,1.7,1.1,3
            c-0.6,1-0.7,2.1-0.3,3.2c2.6-0.3,3.1-2.7,4.6-4.1c4.3,1.6,7.4-2.1,10.9-3c0.7-1.9,2.6-3.1,4.6-1.8c-4.5,4.8-10,8.6-16,11.2
            c-2.5-0.1-3.4,1.3-6,1.1c-0.8,0.4-1,1.4-1.6,1.9c-0.2-1.1,0.4-2.1,1.4-2.5c0-2.5,0.9-4,0.5-6.8c-2.3-0.4-3.6-1.8-6.4-1.6
            c-1.8-0.6-3.2-1.7-5.3-2c-0.2-0.7-1-0.9-1.2-1.6h-2.8c-1-1.9-2.6-3-3-5.9h-2.3c-0.2-0.3-0.2-0.8,0-1.1c0-0.6-1.2-0.1-1.1-0.9h-2.1
            c-1.8,0.9-4.7,0.8-6.6,2c-2.7-0.1-4.2,1.1-7.1,0.9c-0.6-0.1-0.4,0.5-0.7,0.5c-3,0.2-6.1,1.4-8.2,1.4c-0.6-0.1-0.4,0.5-0.7,0.5
            c-1.8,0.5-3.5,0.3-5.2,0.5c-0.4,0-0.5,0.4-0.9,0.5s-0.8-0.1-1.2,0c-1.6,0.4-3.3,1.3-5.3,0.9c-1,0.9-3.2,0.7-4.3,1.6
            c-2.5-0.6-4.3,0.6-5.9,0.9c-0.9,0.1-1.8-0.1-2.7,0c-0.3,0-0.6,0.5-0.9,0.5c-1,0.3-2.2-0.2-3.2,0c-0.5,0.1-1,0.9-1.8,0.5
            c-0.4-0.8-0.1-2.3-0.2-3.4c2.1-2.4,3.8-5,6.6-6.8c-0.1-1.8,1.1-3.3,2.8-3.7c-0.2-2.3-0.3-4.5-2.8-4.4c0-2-1.3-2.6-1.1-4.8
            c2.8-0.6,4.2-2.7,7.8-2.5c0.6,0.1,0.5-0.5,0.9-0.5c2.6,0.1,4.2-0.9,7.1-0.5c0.6,0.3,1.2,0.7,1.8,1.1c3.6,0.1,4.2-2.8,8.5-2
            c2.5-0.5,2.6-3.4,4.8-4.1c0.2-0.8,0.7-1.5,1.4-2c1,0.1,1.8,0,2.1-0.5c0.5-2.6-0.9-3.1-0.9-5.5c0.6-0.5,0.6-1.6,0.5-2.7
            c-1.5-0.1-2.2-1-3.6-1.2v-1.4c1.5-1.8,3.2-3.5,5-5c0.6-1.9,1.6-3.7,3-5.2c1.3-3.2,3.9-5.2,6-7.6c0.8,0,1.5-0.4,2-0.9
            c1-0.1,2.3,0.3,2.5-0.5c1-0.1,2.3,0.3,2.5-0.5C645.1,83.2,648.2,80.8,651.9,80.6z' />
        </g>
        <g id='New_Mexico'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('New Mexico')
          })} d='M274.7,251.9c0.1,2.7-0.1,5.6,0,7.8c-0.8,0.1-1.1,0.6-1.6,0.9c-0.2,2.3,0.3,4.5,0,6.8
            c-0.1,0.4-0.4,0.5-0.5,0.9c-0.4,1.3,0.4,3,0.2,4.6c0,0.2-0.5,0.7-0.5,0.9c-0.7,2.9,0.5,6.3,0,8.7c-0.5,1.3-0.7,2.7-0.5,4.1
            c-1.1,2.8-0.2,7.7-0.5,11.4c-0.8,0.9-0.7,3.6-0.4,5c0,0.7-0.8,0.5-0.7,1.2c-0.1,1,0.3,1.5,0.2,2.5c0.2,0.8-0.6,0.5-0.5,1.1v3.6
            c-1,1.5-0.4,4.6-0.5,6.9c0.2,0.8-0.7,0.4-0.5,1.1c0,1.4,0.1,2.9-0.5,3.6c0.3,5.6-0.6,9-0.3,14.6c-0.5,0.1-0.6,0.4-0.5,1.1
            c-2.7-0.4-5.5,0.5-8.2,0c-0.4-0.1-0.8-0.5-1.2-0.5c-3.2-0.5-6.7,0.3-9.6-0.4c-0.3-0.1-0.7-0.5-1.1-0.5c-3.1-0.5-6.6,0.1-9.8-0.5
            c-0.4-0.1-0.8-0.5-1.2-0.5c-1-0.1-1.9,0.1-2.8,0c-0.4,0-0.5-0.4-0.9-0.5c-1.5-0.5-4.6,0-7-0.4c-0.4,0-0.7-0.4-1.1-0.5
            c-1.4-0.3-3,0.2-4.4,0c-0.8-0.1-2.6-1-4.3-0.5c-0.5,0.3-0.6,0.9-1.2,1.1c-0.3,1.2,0,2.5,0.9,3.4c-2.1-0.8-4.9-0.8-7.7-0.9
            c-0.5,0-0.6-0.3-0.9-0.5c-5.9-0.3-10.1-1.7-15.7-2c-0.2,2.6-0.5,5.2-1.1,7.8c-3.4-0.9-8.1-0.4-11.2-1.6c0.2-1.1-0.5-3.1,0.5-3.4
            v-3.2c-0.2-0.8,0.7-0.4,0.5-1.1c0.2-6.3,1.4-10.7,2-16.7c-0.2-0.8,0.7-0.4,0.5-1.1s0.2-2-0.2-2.5c-0.1-0.7,0.9-0.3,0.7-1.1
            c-0.1-2.3,0.2-4.6,0.9-6.8c0-2.2,0.3-4.5,1.1-6.6c-0.4-3.1,0.6-3.4,0.4-6.2c0.7-2.3,1-4.6,1.1-7c-0.2-0.8,0.7-0.4,0.5-1.1
            c-0.4-4.8,0.8-7.2,0.9-11.2c-0.3-0.8,0.6-0.5,0.5-1.1V270c0.5-0.9,0.7-2,0.5-3c-0.2-0.7,0.5-0.4,0.5-0.9c-0.3-3.4,1.2-5.5,0.9-9.4
            c0.8-0.9,0.5-2.8,0.5-4.5c1.2-1-0.1-4.5,1.1-5.5c0.1-1.1,0-2.3-0.2-3.4c4.7-0.3,7.7,1.6,12.8,1.4c0.8-0.2,0.4,0.7,1.1,0.5
            c4.4,0.1,6.2,0.8,10.7,0.9c0.8-0.2,0.5,0.6,1.1,0.5c2.3,0,4.6,0.2,6.9,0.5c0.4,0.1,0.5,0.4,0.9,0.5c2.2,0.7,5-0.1,7.3,0.4
            c1.5,0.5,3.1,0.7,4.6,0.5c1.5,1.3,6-0.3,7.5,1.1c4.1-0.3,7.2,0.5,11.2,0.4c0.8-0.2,0.5,0.6,1.1,0.5
            C263,250.8,268.5,251.7,274.7,251.9z' />
        </g>
        <g id='New_Jersey'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('New Jersey')
          })} d='M661.4,150.8c0.3,2.5-0.9,3.4-0.5,5.9h-1.4v4.3c1.2,0.3,2.4,0.3,3.6-0.2c1.8,2.3,0.1,8.1,1.4,10.9
            c-0.3,1.7-1.2,2.8-1.1,4.8c-2.1,2.2-2.6,5.1-4.5,7.3c-0.2,2.3-0.4,4.4-2.1,5.2c-0.8-1,0.6-1.9,0.2-3.9c-0.7-0.4-2-0.1-3-0.2
            c-1.2-0.5-2.2-0.9-3.7-1.4c-1.4-1.2-2.1-3-4.1-3.6c-0.3-0.8,0.2-1.7,1-2c0,0,0,0,0,0v-1.2c1.8-1.5,3.8-2.9,5-5
            c0.2-0.5-0.2-0.5-0.2-0.9c1-0.7,1.7-1.7,3-2.1v-1.1c-1.8-2.4-5-3.4-6-6.4h-1.8c0.2-0.9,0-1.8-0.5-2.5c0.3-0.5,1.1-0.6,1.4-1.1
            c0.1-1.4,0.1-2.7-0.9-3c0.4-1,0-1.9,1.4-1.8c-0.1-1.6,0.9-2.2,0.5-4.1c1-2.6,4.3-0.4,5.7,0.7C657.2,149,659.2,150.3,661.4,150.8z'
          />
        </g>
        <g id='New_Hampshire'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('New Hampshire')
          })} d='M678.6,69.6c0.3,3.3,2.2,4.9,2,7.8c1.9,5.3,3.7,9.8,5,15.5c0.8,1,0.8,2.8,1.6,3.7c-0.7,1.4,0.8,1.9,0.3,3.6
            c0.7,1.4,2.1,2.2,3.6,2.1v2.1c0.8,0.2,1.7,0.4,2,1.1c-0.3,0.7-0.6,1.4-0.7,2.1h-1.1c-1.5,1.6-3.9,3-4.5,5c-5,0.5-8.8,2.8-14.4,2.8
            c-0.5-0.5-0.7-1.3-0.5-1.9c0-0.3,0.5-0.5,0.5-0.9c0.1-1.3-0.6-2.5,0-3.6c-0.2-0.4-0.6-0.7-1.1-0.5c0.3-2.5-0.6-6.1,0.5-7.6
            c0.3-3.5,0.3-6.7,0.5-10.5c-0.5-0.7-0.7-1.6-0.5-2.5c1.4-0.5,3.1-2.2,4.3-3.4c0.6-0.5,0-2.7,0.2-4.3c-0.4-0.6-1-1-1.6-1.4
            c0-3.5-0.2-7.1,1.1-9.2L678.6,69.6z' />
        </g>
        <g id='Nevada'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Nevada')
          })} d='M64.2,128c3,0.1,4.7,1.6,7.8,1.6v0.9c8.7,1,14.9,4,23.9,5c2.2,0.9,4.6,1.3,6.9,0.9c0.3,0.4,0.7,0.6,0.9,1.1
            c3.7,0.8,8,1,11,2.5c4,0.2,5.6,1.3,9.3,1.4c4.2,1.9,10.3,1.9,14.8,3.6c-1.7,9.3-3.7,17.4-5,26.7c0.1,0.4-0.3,0.4-0.5,0.4
            c0.2,3.1-1.6,5-0.9,8c-0.7,0.3-0.6,1.3-0.5,2.3c-1.2,3.2-1.9,6.6-2,10c0.1,0.6-0.5,0.4-0.5,0.7v2.3c0.1,0.6-0.6,0.2-0.5,0.7
            c0.2,2.9-1.2,5.1-0.9,7.5c-1.2,0.1,0.1,2.7-1.1,2.8v1.8c-0.6,0.4-0.6,1.4-0.5,2.5c0.1,0.6-0.6,0.2-0.5,0.7c-0.1,0.7,0,1.4,0.2,2
            c-0.1,0.1-0.5,0.1-0.5,0.4c0.4,2-0.9,2.5-0.5,4.5c-0.6,1.4-1.4,3.3-0.9,5.2c-0.2,0.2-0.7,0.2-0.7,0.5c0,0.6-0.1,1.3,0.2,1.6
            c-0.7,0.6-0.3,1.6-0.5,2.5c-0.1,0.3-0.5,0.4-0.5,0.7c-0.3,1.6-0.5,3.2-0.5,4.8c0.1,0.6-0.6,0.2-0.5,0.7c0,1.8-0.1,3.7-0.4,5.5
            c-0.1,0.3-0.4,0.5-0.5,0.9s0.1,0.9,0,1.1s-0.5,0.1-0.5,0.4c-0.2,1,0.1,2,0,3.2c-1.4,0.5-0.5,2.3-2.9,2c-0.4-0.9-1-1.7-1.6-2.5H114
            c-1.5-1.6-3.8-0.5-5,0.5v6.6c-1.4,2.8,0.2,9.5-1.1,11.7c-3.2-3.4-4.5-8.1-7.8-11c-0.1-0.3,0.2-0.3,0.2-0.5c-2.1-2.6-4-5.5-5.5-8.5
            c-1.9-1.1-1.9-3.4-3.9-4.6c-0.7-2.2-1.9-4.3-3.6-6c0.1-1.2-0.9-1.4-1.4-2c-0.7-1.6-1-2.5-2.5-3.4c-0.4-2.1-1.8-3.3-3-4.6
            c-0.2-0.5,0.2-0.5,0.2-0.9c-1.1-0.8-1.5-2.3-2.7-3c0-0.3,0.2-0.3,0.2-0.5c-0.3-0.4-0.6-0.7-1.1-0.9c-0.9-2.5-2.8-3.9-3.9-6.6
            c-1.8-2.4-3.7-4.6-5-7.5c-1.6-1-2-3.2-3.6-4.3c-0.6-3-3.5-4.9-4.3-7.5c-2-1.5-2.8-4.2-4.6-5.9c0-0.3,0-0.5,0.2-0.5
            c-1.4-1.2-1.8-3.4-3.6-4.3c0.2-0.8-0.3-1-0.5-1.4v-3.9c0.9-1.1,0.7-3.2,1.6-4.3c-0.3-2.3,1.1-2.4,0.9-5.2c1.1-2.6,1.4-5.2,2.5-7.7
            c0.3-4,1.9-5.7,1.9-9.2c-0.1-0.4,0.3-0.3,0.5-0.4c0-1.2,0.2-2.4,0.5-3.6c0.1-0.3,0.5-0.5,0.5-0.7s-0.2-0.8-0.2-1.1s0.4-0.4,0.5-0.9
            c0.1-0.5-0.1-0.8,0-1.1c0.1-0.3,0.5-0.1,0.5-0.4c0.3-1,0.2-2.3,0.5-3.4c0.1-0.3,0.4-0.4,0.5-0.7c0.1-0.3-0.1-0.8,0-1.2
            s0.5-0.4,0.5-0.7c0.2-1,0.1-2.1,0.3-3.2C63.4,130.6,64.2,129.4,64.2,128z' />
        </g>
        <g id='Nebraska'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Nebraska')
          })} d='M347,153.3c2.1-0.8,3.9-1.8,7.1-1.4c0.8,0.2,0.5-0.6,1.1-0.5c1.1,0.6,2.6,0.8,3.2,2c2.3,0.2,2.9,1,4.8,1.6
            c-0.1,1.8,1.1,2.2,1.6,3.4h2.3v3c1.5,2.4,1.6,5.8,3.6,8c-0.2,0.9-0.3,1.8-0.2,2.7c1.8,2.1,3,3.8,2.5,8.2c0.8,1,1,2.5,1.6,3.7
            c-0.1,2.4,0,4.9,0.4,7.3c0.7,1.4,2.7,2.4,2,4.6c1.5,2,2.6,3.6,4.1,5.3c-19.3,0.4-37.1-0.8-56.2-0.5c-1.5-1-4.6-0.3-6.9-0.5
            c-0.8,0.2-0.4-0.7-1.1-0.5c-8.4,0.1-16.3-0.2-24.2-0.5c-0.4-6.7,1.2-11.5,1.1-18c-9.1,0-18-2.1-26.4-1.8c0.8-3.4,0.1-9,1.6-12.1
            v-4.4c-0.2-0.8,0.7-0.4,0.5-1.1c-0.3-4.9,0.4-8.3,0.4-12.6c0.5-1,0.7-2.1,0.5-3.2c0-0.3,0.2-0.3,0.2-0.5c5,0.1,8.9,0.7,14.4,0.5
            c0.8-0.2,0.5,0.6,1.1,0.5c18.2,1,35.3,2,54.1,2.5C342.3,150.5,344.6,152,347,153.3z' />
        </g>
        <g id='Montana'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Montana')
          })} d='M140.3,19.2c0.5-0.1,0.3,0.4,0.5,0.5c5.9,0.6,11.2,2.8,17.1,2.8c2.2,1,5.3,1,7.3,2.1c5.7-0.2,10,2.2,16,2.3
            c1.5,1,4.4,0.5,5.7,1.6h2.8c0.6-0.3,0.1,0.6,0.5,0.5c8,0.6,14.7,2.6,22.6,2.8c0.6-0.2,0.2,0.6,0.7,0.5c5.9-0.3,8.1,1.9,14.1,1.4
            c0.6-0.2,0.2,0.6,0.7,0.5c8.9,0.8,14.5,1.9,23.1,2.5c0.6-0.2,0.2,0.6,0.7,0.5c4.4-0.2,7.2,1.1,11.6,0.9c0.6-0.2,0.2,0.6,0.7,0.5
            c4.1-0.3,6.6,0.9,10.7,0.5c0.5,0.7,1.7,0.6,2.8,0.5c-0.2,1.8,0.6,4.6-0.5,5.5c-0.2,5.8-0.9,10.4-0.9,16.2c-1.3,0.9,0.3,4.7-1.1,5.5
            V74c0.1,0.6-0.5,0.5-0.5,0.9v5.2c-1.5,2.2-0.7,7.7-0.9,11.8c-0.3,0.6-0.7,1.2-1.1,1.8c-0.1,5.4,0.4,11.5-1.1,15
            c-3.6-0.5-7.3-0.4-10.7-0.9c-0.3,0-0.4-0.4-0.7-0.5c-1.5-0.5-3.4,0.2-4.8,0c-0.3,0-0.4-0.4-0.7-0.5c-1.1-0.4-2.5,0.2-3.7,0
            c-0.3,0-0.4-0.4-0.7-0.5c-2.6-0.8-5.8,0.6-8.7,0c-0.6,0.2-0.2-0.6-0.7-0.5c-1.1-0.1-1.7,0.3-2.9,0.2c-0.7-0.6-2.5-0.3-3-1.1
            c-2.2,0.2-4.5-0.2-6.6-1.1c-3,0.3-3.9-0.6-7-0.4c-1-1.3-4.8,0.3-5.7-1.1h-3.4c-2.2-0.8-4.7-0.8-7.3-0.9c-1.9-0.7-4-1-6.1-1.1
            c-0.6,0.2-0.2-0.6-0.7-0.5c-5.7,0.6-7.9-1.2-12.8-1.4c-0.4,2.6-1,5.2-1.2,8.4c-1.5-1.1-1.3-3.4-2.5-4.4c-1.6-0.3-2.3,0.3-3.4,0.5
            v1.4c-1.3,1.1-4,0.5-4.8-0.5c-4.4,0.1-7.2,0.9-10.9-0.4c-0.7,0.4-1.1,1.3-1.6,2c-0.1-1.5-1.2-2-1.1-3.4c0.6-0.6,0.6-1.6,0.5-2.9
            c-1-0.9-1.2-2.7-3-2.9c-0.5-1.6-2.2-2.7-1.8-4.6c-0.9-1.1-0.5-3.5-1.6-4.8c0.2-2.3,0-4.6-0.5-6.8c-3.3,0.1-3.4,2.2-5.9,3
            c-1.2-0.7-1.3-1.9-2.5-2.9c0.7-0.6,0.9-2.6,1.1-3.2c1.6-3,2.3-7.1,4.4-10c-0.1-1.9,0.8-2.1,0.5-3.9c-0.4-1-1.6-1-3-0.9
            c0.2-1.5-0.5-2.1-1.9-2c-0.2-1.1-0.8-2.1-1.6-2.8c-0.3-3.3-2.3-5.2-2.8-7.8c-0.9-0.1-1.2-0.9-2.5-0.7c-0.6-0.8-0.7-2-1.6-2.5
            c0.9-1.7,1.2-3.6,1.1-5.5c-1.1-1.5-1.3-3.1-2.5-4.6c0-1.2,0.3-2.4,1.1-3.4v-2.3c0.9-2.4,1-6.8,2.5-9.6
            C140.5,20.1,140,19.6,140.3,19.2z' />
        </g>
        <g id='Missouri'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Missouri')
          })} d='M429.8,190.3c0.8,1.5,1.9,2.7,3.2,3.7c-0.5,0.6-0.6,1.5-1.2,2c0.6,1.7,1,3.4,1.1,5.2c0.7,1.5,1.7,2.7,2,4.6
            c0.7,0.6,2,0.7,2,2.1c3.1,1.8,4.8,4.6,7.5,6.4c-0.2,1.6,0,3.3,0.7,4.8h5.3c0.7,0.4,0.6,1.7,2,1.4c-0.8,0.5-0.5,2.1-0.5,3.4
            c0.2,0.7-0.9,0.1-1.1,0.5v2c-1.2,0.9-1.5,2.5-1.4,4.6c1.8,2.7,5.1,3.8,6.6,6.8c2-0.4,2.4,0.8,4.3,0.5c0.6,2.3,1.6,2.7,2,5
            c0.1,0.5,1,0.3,1.1,0.9c-1,2.9,0.5,6.1,2.1,8h3.2c-0.2,2.1-0.7,3.3-0.5,5.2h-2.1c-0.7,0.5,0.1,1.4-0.4,2.1
            c-0.3,0.5-1.3,0.3-2.1,0.4c-0.5,0.9,0,3-0.2,4.6c-0.7,1.6-1.5,3.1-2.1,4.8c-2.6-0.2-4.5,1.2-7.1,0.4c0.7-1.6,1.2-3.4,3-3.9
            c0.4-2.5-0.6-3.5-1.2-4.8c-7-0.2-11.8,0.6-18.7,0.4c-0.6-0.1-0.4,0.5-0.7,0.5c-14.7,0.2-28.5,1.3-43.6,1.1c0-12,0-25.6,0-38.3
            c0.2-0.8-0.6-0.6-0.5-1.2v-7.3c-3.5-0.3-3.9-3.8-6.1-5.5c-0.2-1.6,0.7-3.1,2.1-3.7c0-1.6,0.1-3.2-1.6-3.7c-0.8-0.2-1,0.2-1.4,0.4
            c-3.7-1.5-4-5.4-6.9-7.8c0.4-1.8-0.5-2.3-0.5-3.7c16.1,0.3,31.2-0.3,46.3-1.1C424.7,189.8,428.2,191,429.8,190.3z' />
        </g>
        <g id='Mississippi'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Mississippi')
          })} d='M484.2,286.2c0.4,0.5,0.5,1.4,1.1,1.8v13.5c0.2,0.6-0.5,0.4-0.5,0.7v20.1c0.2,0.6-0.6,0.2-0.5,0.7v17.6
            c-0.2,0.6,0.5,0.4,0.5,0.7c0.1,1.2-0.4,3,0.5,3.4c0.1,1.4-0.2,2.7,0,3.9c0,0.3,0.5,0.5,0.5,0.7c0.4,1.3-0.2,3.4,0,5.2
            c0.1,0.8,0.8,1.4,0.9,2.1c0.2,1.4-0.2,2.9,0,4.3c0,0.3,0.4,0.4,0.5,0.7c0.6,1.9-0.4,4.3,0,6.6h-3.9c-2.3-2.3-5.7-0.1-8.2,0.5
            c-0.8,0.1-0.7-0.9-1.8-0.5c-0.8,0.9-1.4,2.1-2.1,3c-0.8,0.2-0.6-0.7-1.2-0.7c0.3-1.7,0-3.6-2-3.9c0.1-1.2-0.8-1.4-1.4-2
            c-0.3-2.7,0.9-4,1.1-6.2c-0.1-0.6-0.5-0.8-0.9-1.1c-9.2,0.5-18.2,1-27.4,1.4V356c-0.1-0.6,0.5-0.4,0.5-0.7
            c0.4-7.9,4.2-10.4,6.9-15.7c0.3-0.9-0.4-0.9-0.5-1.4c0.4,0.1,0.7,0,1.1-0.2v-3.6c-0.4-0.5-1.3-0.5-1.6-1.1c0-3.5-2.2-4.7-2-8.4
            c0.4-0.4,1-0.8,1.6-0.9c0.2-3,0-5.7-1.6-6.9c0.9-0.4,0.7-2.8,0-3.2c0.5-0.1,0.6-0.4,0.5-1.1c-1.3-1.6,0.3-3.1,0-5.3
            c1.3-0.1,1.8-1,3-1.2c0.1-1.1,0.1-2.2-0.5-2.7c1.1-1.3,1.2-2.8,2.5-4.1c2.6,0.9,3.1-2.2,2-3.4c0.4-2.3,1.5-2.7,2-5h1.8
            c0.4-0.6,0.1-1.9,0.2-2.8C465.1,287.9,474.8,287.4,484.2,286.2z' />
        </g>
        <g id='Minnesota'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Minnesota')
          })} d='M393.5,49.8c1.5,0.2,2.6-0.1,4.6,0c0.3,0.4,0.1,1.3,0.2,2c1,0.3,2.6,0.1,3.9,0.2c1.2-0.4,1-1.2,1.6-2
            c0.5-0.2,1.4,0,1.6-0.5c0.5,0.7,1.4,0.3,2.7,0.4c3.8,1.6,6.6,5.2,11.6,4.1c0.1,0.4-0.3,0.4-0.2,0.9c0.5,1.4,2.6,1.2,3.2,2.5
            c0.5,0.1,0.3-0.4,0.5-0.5c0.7,0,0.1,1.3,0.5,1.6c1.9,0,3.2,0.6,5.3,0.4c1.5-1.3,3.1-2.4,4.8-3.4c0.8,0.5,0.3,2.3,1.4,2.5
            c2.3-0.7,5.4-0.6,8.4-0.5c0.1,0.9,0.7,1.7,1.6,2c1.2,0,2.2-1,3.6-0.3c-2.1,0.7-3.1,2.5-5.7,2.7c-0.3,0.6-1.2,0.4-1.4,1.1
            c-3.6,0.4-5,2.9-8,3.9c-0.5,1.4-1.8,2-2.8,2.9c-1.4,4.2-5.4,5.8-7.8,8.9c-0.3,0.2-0.6,0.2-0.9,0.2c-0.9,1.7-3,2.2-3.6,4.3
            c-0.2,0.2-0.8,0.1-1.1,0.4v10.7c-1.8,0.4-1.8,1.9-3.6,2c-0.6,0.5-1.1,1.1-1.8,1.6c-0.1,2.3-1.7,3-1.6,5.5c1.5,0.6,3.3,0.9,3,3.4
            c-2.6,2.3-0.5,7.5-1.1,11.4c1.8,1.5,3.2,3.4,6.4,3.4c0.7,0.8,0.6,1.4,1.4,2.1h1.6c2.1,4.9,7.7,5.8,10.5,9.6c-0.3,2.4,0.8,2.4,0.4,5
            c-1.9,0.3-5-0.6-6,0.5H417c-0.6-0.2-0.2,0.6-0.7,0.5c-7.6,0-16.2,0-22.8-0.2c-0.4,0-1.1,0.7-1.4,0.7c-3.8,0.4-8.1-0.2-12.1-0.2
            h-12.1c0-9.6,0-20.8,0-31c-1-2.6-4.2-3.1-5-5.9c1.1-0.4,1.6-1.4,2.8-1.6c-0.2-0.8,0.4-0.8,0.5-1.2c0.5-7.1-0.5-11.4-2.5-15.7
            c0.2-4.8-0.6-8.6-0.4-13.5c0-0.5-0.3-0.6-0.5-0.9v-3.7c-2.7-3.3-3.3-8.7-3-15c1.4-2.2-0.5-5.2-0.9-7.3c7.4,0.7,15.8,0.5,24,0.5
            c0.3-2.2,0.3-4.5,0-6.8c0.4-0.6,0.3,0.2,0.7,0.4c1.2,0.3,2-0.2,2.5,0.7s0.5,2.4,0.7,3.9c0.1,0.4,0.4,0.8,0.5,1.2
            c0.6,2.6-0.1,4.6,2,5.5h3.5C393.6,48.8,393.1,49.7,393.5,49.8z' />
        </g>
        <g id='Michigan'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Michigan')
          })} d='M514.3,93.8c1.3,0.2,1.3-0.9,2.7-0.5c3,2.5,6.8,4.1,10.7,4.4c0.8,1.4,1.8,2.8,2.7,4.1h-1.8
            c-0.6,1.1,0.2,2.3,0.4,3.2c3.3,0.6,2.4,5.3,2.5,9.1c-0.3,0.9-1.1,1.5-2,1.6c-0.4,1.3-0.5,4.2-1.6,5.3h-1.6
            c-1.9,1.4-0.7,5.9,0.4,6.9c1.4-0.3,1.3,0.9,2.8,0.5c1-0.7,1.2-2.1,2.3-2.7c0.5-3.7,2.9-5.5,5.7-7c1,0.1,1.3,0.7,2.3,0.7
            c0.6,1.8,1.8,2.9,2.5,4.6c0.7,4.7,1.8,9,3.9,12.3v6.5c-0.4,0.5-0.7,1.2-1.4,1.4c-0.2-0.2,0-0.7-0.5-0.5c-0.2-0.8,0.7-0.4,0.5-1.1
            c-2.3-1.5-2,2.5-3,3.4c0.2,1.4-0.5,1.8-0.7,2.7c-2.7-0.4-1.5,3-1.8,5c-1.4,1.7-3.2,3.7-3,6.4c-6.3,0.2-11.7,2-17.3,2.5
            c-3-1.4-7.2,0.1-10.9-0.2c-0.6-0.2-0.2,0.6-0.7,0.5c-2.5,0-4.6-0.2-6.4,0.5c-0.2,0.1-0.3,0.5-0.5,0.5c-1.9,0.4-3.6-0.1-5.7-0.2
            c0.2-1.5,1.7-1.7,0.9-3.4c0.9-2.1,2.2-3.7,2.5-6.4c-0.2-0.6,0.5-0.4,0.5-0.7c-0.3-3.1,0.9-4.6,0.5-7.7c0.2-0.6-0.5-0.4-0.5-0.7
            c-0.4-6.2-4.5-8.6-5.3-14.2c0-0.6,0.6-0.7,0.9-1.1c-0.1-1.2,0.4-3.1-0.5-3.6c-0.5-5.2,4.2-9.1,1.1-13.5c1.1-0.1,1.6-0.8,2.5-1.1
            c-0.1-0.8,0.3-2-0.5-2c0.8-1.4,2.2-2.5,3.7-3c0.8-0.9,0.5-2.9,1.8-3.4c0.6,1.6-1.3,4.5-0.4,6.9c1.7,0.1,2-1.2,3.2-1.6
            c-0.2-2,0.5-5.1-0.5-6.2c0.8-2.4,3.1-2.6,5.5-3.2v-1.1c-0.8-0.2-1.7-0.5-2.5-0.9c0.1-0.8-0.1-1.4-0.5-1.6
            C508.4,93.5,512,90.9,514.3,93.8z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Michigan')
          })} d='M471.4,66.4v0.4c-0.8,0.5-1.7,0.8-2.7,0.9c-0.4,2.8-3.9,2.6-3.7,5.9c-0.8,0.1-0.4,1.3-1.1,1.4
            c0.3,1-0.8,1.9-0.4,3.6c2.6,0.1,2.6-2.2,4.5-2.9c2.1,0.9,5.2,0.7,6.4,2.5c-0.6,1.7,1.2,1.6,2,3c0.3,0.5,0.3,1.2,0.9,1.4
            c1.2,0.5,4.4,0,4.8-0.5c0.3,0.4,1,0.4,1.2,0.9h2.7c0.4,0.1,0.2-0.3,0.5-0.4h1.2c0.1,0,0.2-0.2,0.4-0.2c1.8-2.3,4.2-3.9,6.9-4.8h5.2
            c1.7-1,3.5-1.8,5.3-2.5v4.6c4,2.4,7.7-1,11.4-1.6v6c0.9,1,1.8,1.8,2.8,2.7c-1.6-0.1-3.2-0.1-4.1,0.5c-1.8-1.2-4.2-0.1-5.3,0.9
            c-3.1-0.6-5.7-1.6-9.6-1.4c-0.2,1.2-1.3,1.5-1.2,2.5c-2.8,0-5.7,0.4-8.4,1.2c0,1.2,0.3,2.8-0.5,3.2c-0.7-0.4-1.3-0.9-1.8-1.6
            c-1.7,0.6-2.7,1.9-4.8,2.1c-0.2-0.3-0.2-0.7-0.2-1.1h-1.2c-1.9,4.2-3.2,8.7-5.3,13c-0.8-0.2-1.2-0.8-1.8-1.2c0.4-2-0.8-2.5-0.5-4.4
            c-1.2-1,0.4-3.8-1.1-4.8c-1.5,0.2-1.7-0.9-3.4-0.5c0.3-1.4-0.4-1.8-0.5-2.9c-2.8,0.1-5.5,0.1-7.1-1.1c-3,0.4-4.4-0.8-5.9-1.9
            c-5-0.1-7.9-1.8-12.1-2c-1-1.3-2-2.6-3-3.9c2.5-0.7,5.6-1.7,6-3.9c0.8,0.2,0.8-0.4,1.2-0.5h2c0.5,0,0.6-0.5,1.1-0.5h1.1
            c3.5-1.4,5.6-4.1,7.8-6.8c2.7-1.3,3.5-4.8,6.8-5c0.1-0.2,0.2-0.3,0.2-0.5C469.1,66.3,470.7,66,471.4,66.4z' />
        </g>
        <g id='Massachusetts'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Massachusetts')
          })} d='M692.5,108.8c0.2,1.2,0.8,2.3,1.6,3.2c-0.5,2-1.5,3.4-1.8,5.5c0.4,0.7,0.8,1.4,1.2,2.1c1,0.1,2,0,2.3-0.5
            c1.8,1.1,3.7,4.2,5,6.1c0.5,0.7,0.1,1.1,0.9,1.4c0.9,0.4,2-0.2,3.4,0c1.3-0.4-2.5,1.2-4.1,3.7c-0.1-0.9-0.4-1.7-0.9-2.5
            c-1.7,0-1.7,1.6-3.2,1.8v2.1c-0.4,0.2-1,0.2-1.1,0.7c-0.9-0.6-0.9-1.1-1.2-2.3c-2.4-1-3.3-3.1-4.1-5.2c-1,0.3-0.8-0.5-1.4-0.5
            c-3,0.6-4.5,1.5-8,1.4c-0.8,1-2.7,0.8-3.7,1.6c-3.7-0.1-4.8,1.5-9.1,1.4c-0.7,0.3-1.4,0.6-2,1.1c-1.3-0.5-1.7,0.8-3.4,0.4v-11.6
            c1.6-0.2,3.4,0.4,4.6-1.1c5.3-0.3,7.6-1.5,12.6-2c0.5-0.4,1.3-0.6,1.8-1.1c1.8-0.2,2.7-0.2,3.9-0.9c1-0.1,2.6,0.3,2.8-0.5
            c-0.1-0.4-0.4-0.5-0.5-0.7c0.1-0.8,1.2-0.6,0.9-1.8C691,110.8,690.7,108.7,692.5,108.8z' />
        </g>
        <g id='Maryland'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Maryland')
          })} d='M642,178.9c1.1,2.1,1.2,5.6,2.7,7.8c-0.5,1.6,1,2.4,0.9,4.6c0.8,1.1,0.6,3.3,1.6,4.3c-0.6,1.5,0.8,2.1-0.2,3.2
          c0.1,0.4,0.7,0.4,0.7,0.9c3.1-0.1,6.1-0.4,9.1-1.1c0.6,2.5-0.5,4.9-0.9,6.9c-2.6,0.9-4.6,0.3-5.7,2.5c-1.8-1-2-3.3-3.2-4.5
          c-1.9-0.2-2.5,0.9-4.6,0.5c-0.7-1.6-2.3-2.5-2.1-5c0.6-0.1,0.6-0.7,1.4-0.5c0.5-2.2-0.9-2.6-2.5-2.7c0-1,0.9-1.2,1.6-1.4
          c0.4-2-0.7-2.7-0.5-4.4c-0.4-0.5-1.2-0.5-1.6-1.1c0-1,0.8-1.4,0.5-3c0.7-0.6,2.1-1.2,1.4-2.7c-1.3,0.3-2.4,1.2-3,2.5h-1.2
          c-0.4,0.5-0.1,1.7-0.2,2.5c-2,0.9,0.2,2.7,0.5,3.9c-1,1-0.5,3.6-1.1,5c0.5,1.5,1.4,2.5,1.6,4.3c0.7,0.6,1.4,1.3,2,2
          c-0.2,1.5,1.4,2.8-0.2,3.6c-2.7-1.6-9.8,0.1-11.6-3c0.6-1.2,0.6-3.2,2.5-3.6c0.1-0.8,0-1.7-0.2-2.5c0.4-0.4,1-0.8,1.6-0.9
          c1.4-3.3-0.6-5.3-3.3-4.7c-0.5,0-2,1-2,1.5c-1.8-0.8-2.1-0.5-4-1.1c-0.4-0.5,0.7-0.7,0.3-1.8c-1.5-0.5-2.5-1.5-4.1-2
          c-0.6-2.6-3.8-2.5-5.2-4.3c-1,0.1-2.3-0.2-3,0.2c-0.4,0-0.1,0.6-0.2,0.9c-1-0.1-1.9,0.7-2,1.7c0,0.1,0,0.2,0,0.3
          c-1.2-0.3-3.5,0.6-3.9-0.5c-1.2,1-2.1,2.2-2.7,3.6h-2.1c-0.8,1.7-2.9,2.8-3.2,4.4c-0.7-2.1-1.5-5-1.2-7.5c4.5-0.5,8.3-1.7,13-2
          c0.4,0,0.1-0.6,0.2-0.9c3-0.9,7.6-0.2,10.2-1.6c3.1,0.1,5-1,8.2-0.9c3.9-1.7,8.7-2.5,13.7-3C640.1,178.7,641.1,178.9,642,178.9z' />
        </g>
        <g id='Maine'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Maine')
          })} d='M701,24.2c3,0.4,5.1,1.8,7.3,3c2.1,8.2,4.7,15.6,7.1,23.7h4.6c1.5,2-0.1,7.8,4.5,7.3c0.3-0.3,0.2-0.9,0.2-1.4
            c2.6,0.8,3.8,3.5,4.8,5.3c-0.5,1-1.3,1.7-1.4,3c-3.9,1.8-6,5.4-9.3,7.8c-0.1-0.5-0.4-1-0.7-1.4h-2c-0.5,0.3-0.5,1-0.9,1.4h-1.1
            c-0.5,0.9-1.7,1.4-1.4,3.6h-2.3c-0.2-0.7-0.5-1.3-0.7-2c-1.3-0.1-2.3,0-2.5,1.1s0.9,1,1.1,1.8c-1.4,2.1,1.1,8.2-3.7,6.9
            c-0.2,1-0.4,2-0.7,3c-1.9,0.1-2.4,1.8-4.3,2c-0.7-0.1-1.6-1-2.5-0.4c-0.7,1.3-2.5,3.2-1.1,5c-0.3,0.4-0.7,0.8-1.1,1.1
            c-0.1,3.5-1.4,5.8-1.6,9.1c-0.8,0.1-1-0.4-1.4-0.7c0.2-0.6,0-0.6,0.2-1.2c-1.2-1.5-4.5-0.9-3.6-4.4c-1.5-3.5-2.3-7.7-3.9-11
            c-0.6-3.3-1.6-6.4-3-9.4c-0.1-3.4-2.2-5.9-2.3-9.1c1-0.2,0.8,0.8,2,0.5c0.6-2.3,0.9-4.6,0.9-6.9c1.9-1.9,3.7-4.8,3.6-7.7
            c0-0.4-0.6-0.3-1.1-0.3c-0.6-1.3-0.6-3.3-0.5-5.3c3.3-2.1-0.5-6.8,2-10c0.8-3.7,2.4-5.4,3-9.3c1.2-1,0.8-2.3,1.6-3.6h1.2
            c0,2.5,1.5,3.7,4.3,3.4C697.2,26.8,700,26.4,701,24.2z' />
        </g>
        <g id='Louisiana'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Louisiana')
          })} d='M442.8,324.5c0.2,3.4,0.7,5.9,2,7.8c0.1,0.6-0.7,0.2-0.5,0.9c0.5,0.6,1,1.8,2,1.4c0.2,0.5-0.2,1.6,0.2,2.3
            c-0.3,0.4-1.3,0-1.6,0.4c-0.2,1.3,0.5,1.6,1.1,2.1c-1.9,2.4-2.9,5.4-5,7.1c0.1,2.5-2,2.9-1.4,5.3c-0.9,2.5-1.3,5.2-1.1,7.8
            c5.5,0.8,10.7-1.1,16.4-0.2c2.8-1.5,9,0.4,11.8-1.1c-0.1,1.5-1.2,3.2-0.9,6c0.9,1.8,2.5,3.2,3.4,4.5c0,1.2-0.2,2.7,0.5,3.2
            c-0.7,0-0.5,0.5-0.4,0.9c-1.1,0.7-2.2,1.6-3.2,2.5c-0.1,1.9,3.1,3.5,5.2,2.1c-0.1-1.6,0-2.8,1.8-2.5c0.3,0.9,0.4,2.1,1.6,2.1
            c-0.9,0.8-0.9,1.4-1.6,2.3h-2.7c-0.4,1.3-0.8,2.5-1.2,3.7c0.4,1.6,2.1,1.9,3.4,2.7c3.4-0.4,5.2,1.8,7.7,3.2
            c-0.5,2.1-1.6,2.1-3.9,1.8c-0.8-0.4-1.5-0.8-1.6-2c-2.1-0.3-3.1-1.9-5.9-1.6c-0.8-0.7-0.2-2.8-1.4-3c-1.4,0-1.3,1.4-3,1.1
            c-0.1,0.7-0.3,1.3,0.4,1.8c-0.4,0.4-1.7,1.9-0.4,2.3c-0.5,0.7-1.1,1.1-1.6,1.8c-0.4-1.3-3.2-4.8-3.9-1.4h-2.5
            c-0.7,0.5-1.1,1.4-0.9,2.3c-0.8,0.1-1.6,0.4-2.4,0.7c-2.5-1.2-5.3-2.1-7.7-3.6c0.4-0.5,1.2-0.5,1.6-1.1c0.7-3.9-3.9-2.4-5.5-3.9
            c0.3-1.5-0.5-1.8-0.5-2.9c-2.3-1.1-4.5,0.8-6-1.1c-1.3,0.2-2.5,0.8-3.4,1.8c0,1.6,1,2.1,2.1,2.5c-0.5,1-1.5,0.6-2.3,0.2
            c-0.7-0.1-0.7,0.4-1.1,0.5c-1.1,0.3-1.7-0.4-2.5-0.5c-0.8-0.1-1.5,0.1-2.1,0c-0.4-0.1-1-0.7-1.6-0.9c-0.5-0.1-1,0.1-1.2,0
            s-1-1-2-0.5c-2.7-3.2-9.5-1.5-14.2-0.9c-0.6-2.2,0.8-3.8,1.4-4.8c0.1-2.2,0-4.4-0.2-6.6c-0.2-0.6,0.5-0.4,0.5-0.7
            c0.2-0.6,0-1.3-0.5-1.8c0.9-1.5,1.6-3.1,2.5-4.6V357c-0.3-0.3-0.6-0.6-0.9-0.9v-2c-1.4-1.4-2.6-2.9-3.6-4.6c0-1,0.4-1.5-0.5-1.8
            c0.2-0.6,0.2-1.2,0.2-1.8c-0.9-0.9-1.5-2.2-2.5-3c0.4-1.5-0.3-2.9-0.5-3.7c-1-3.6,0.5-8.2,0-11.6c-0.1-0.8-1-1.5-0.5-2.3
            C415.8,325.5,430.5,325.5,442.8,324.5z' />
        </g>
        <g id='Kentucky'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Kentucky')
          })} d='M530.9,209.7c0.7,1.2,1,2.6,1.6,3.9h5.2c0.6,1.1,1,2.4,3.2,2c0.5,0,0.2-0.8,0.5-1.1c1.7-0.4,1.8,0,3.7,0
            c-0.1,0.5,0.2,0.6,0.2,1.1c2.3,0,2.6-2.1,4.1-3c1.6-0.2,1.9,0.8,1.8,2.3c1.5,0.4,2.2,1.6,3.9,1.8v5.5c1,0.7,2.1,1.2,3,2
            c0,0.6-0.3,0.8-0.2,1.4c0.6,0.8,1.3,1.4,2.1,2c0.3,1.4,1.4,2.5,2.8,2.9c0.5,0.7,0.8,1.4,2,1.4c-1.6,3.7-5.2,5.3-7.8,8
            c0.1,1.5-1.3,1.4-1.6,2.5h-0.9v2.9c-5.3,2.6-9,6.8-17.3,6.4c-0.6-0.1-0.4,0.5-0.7,0.5c-4.6-0.2-9.2,0-13.7,0.5
            c-0.6-0.2-0.2,0.6-0.7,0.5c-1,0-2.2-0.2-2.5,0.5c-7.5-0.8-14.7,0.4-21.5,0.9c-2.7,1.1-6.8,0.8-10,1.4c-1.4-0.4-2.8-0.5-4.3-0.4v3.2
            c-6.2-0.1-11.6,1.7-17.8,1.1c0-0.6,0.8-0.4,0.5-1.2c0.9,0.1,1.8-0.2,2.5-0.7c-0.3-3.5,1.5-5.5-0.5-7.3c0.3-1.4,2.1-1.2,2.5-2.5
            c0.6,0.3,0.9,0.8,2,0.5c1.6,1,3.3,1.6,5.2,2c1.3-2-0.8-4-0.7-6.4c1.5-1.3,4.7-0.8,5.5-2.7c-0.4-0.9-0.9-1.7-1.6-2.3
            c1.3-1.3,1.9-3.2,3.2-4.5c3.1,0.2,4.3-1.4,7.7-0.9c0.3,1.4,1.8,1.5,3,2c1.1-1.5,1.7-2.8,3.2-4.1c1.7,0.4,1.3,1.8,2.5,2.5
            c1,0.1,1.4-0.4,2.1-0.5c-0.3-2.5,0.1-4.1,2.1-4.3c1,1.4,4,0.8,6.2,0.9c0.4-0.7,0.1-2,0.2-3c1.5-2.6,3.1-5.1,5.9-6.4v-3.4
            c0.4,0,0.6-0.1,0.5-0.5c4,0.2,4.4-2.1,7.3-3c0.4-2.1-1-2.4-0.9-4.3C526.7,209.5,529.1,208.9,530.9,209.7z' />
        </g>
        <g id='Kansas'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Kansas')
          })} d='M292.3,200.2c10.5,0.2,20.8,0.8,31,0.9c0.9-0.3,0.5,0.8,1.4,0.5h22.8c0.9-0.3,0.6,0.8,1.4,0.5h32
            c0.9,1.2,2.4,0.9,3,2.5c0.9,0.1,2.2-0.2,2.8,0.2c0.3,2.1-1.6,2-2,3.4c-0.3,1.9,0.4,2.7,0.5,4.1c1.4,0.8,2.5,2.2,3,3.7
            c0.8,0.7,1.6,0.2,2.3,0.9c0.6,11.2,0.6,24.5,0.5,37h-4.8c-1.1-0.3-0.9,0.7-2,0.5c-18,0.4-34.3-0.8-52.2-0.5c-1,0.3-0.6-0.7-1.4-0.5
            c-12.4,0.4-21.7-1.3-34.2-0.9c-0.9,0.3-0.6-0.8-1.4-0.5h-5.7c-0.2-2.7,0.3-7.3-0.2-10.3c0.5-0.6,0.5-1.8,1.1-2.3
            c-0.3-7.9,0.3-14.8,0.5-22.2c0.6-1,0.2-1.9,1.1-2.5c-0.4-4.3-0.1-9.3-0.2-13.9C291.8,200.5,292,200.3,292.3,200.2
            C292.3,200.2,292.3,200.2,292.3,200.2z' />
        </g>
        <g id='Iowa'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Iowa')
          })} d='M433.3,138.8c-0.6,2.6,2.1,2.8,2.1,4.3c0.1,0.9-0.9,0.8-1.1,1.4v4.3c1.4,1.1,1,3.5,2.8,4.5
            c1.5-0.2,2.9,0.1,4.1,1.1v2.1c1.5,0.1,1.1,2.2,3,2c-0.1,0.6,0,0.6-0.2,1.4c1.7,1.7,4.5,2.3,4.1,6.2c-0.5,1.6-2.1,2.5-2,4.3
            c-0.5,0.8-1.1,1.6-1.6,2.5c-0.4,0.1-0.8-0.1-1.1,0.5c-2.2-0.4-3.1,0.5-5,0.5c-1.3,0.9-2,2.4-1.8,4.8c0.7,0.4,0.7,1.4,2,1.2
            c0.3,2.7-0.1,4.8-2,5.3v2.9c-2.7-0.2-3.4,1.7-3,4.6c-2.1-0.3-2.2-2.7-3.7-3.6h-5.9c-1.9,1.3-6.8-0.3-8.7,1.1c-3,0.3-6.4-0.4-9.6,0
            c-0.6,0.1-1.1,0.6-1.6,0.5c-8-0.7-19.1,0-28,0c-0.2-0.5-0.2-1.1-0.7-1.2c0.1-3,0-6-0.5-8.9c0-0.5-0.9-0.2-1.1-0.5
            c0.4-1.2,0-3.9,0.2-4.8c-1.3-1.9-3-3-2.5-6.4c-1.9-3.4-3.3-6.2-3.6-11.4c-0.6-1.4-1.5-2.7-2.5-3.9c0.8-0.8,0.9-2.3,1.6-3.2
            c-0.3-2.7,0.7-2.7,0.4-5.5c-0.1-0.4-1-0.1-1.4-0.2c-0.5-1.4-0.7-2.8-0.5-4.3C388.8,140.5,411.4,140,433.3,138.8z' />
        </g>
        <g id='Indiana'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Indiana')
          })} d='M518.4,163.2c0.1,4.5,1.2,7.6,0.9,12.1c0.1,0.8,0.8,0.8,1.1,1.4c0.3,2-0.6,5.2,0.5,6.4
            c-0.4,3.4,1.2,5.8,0.4,9.1c1.1,0.8,0.3,3,0.5,5c-0.2,0.6,0.5,0.4,0.5,0.7c-0.3,2.8,0.9,4,0.5,6.8c-0.2,0.6,0.5,0.4,0.5,0.7
            c0,3.1-0.3,6.5,1.1,7.8c-2.4,0.9-3.4,3.3-7.3,2.7c-0.6,1.4-0.8,2.9-0.7,4.5c-0.8,0.1-0.9,1.4-1.8,0.9c-1.7,1.3-2.3,3.7-4.3,4.8
            c-0.1,1.1,0.2,2.6,0,3.2h-3.7c0-0.7,0-1.3-0.4-1.6c-1.5,0.7-2.9,1.4-4.3,2.3c-0.1,1.1,0.2,2.6-0.3,3.2c-1.3-0.3-1.3-1.9-2.3-2.5
            c-2.6,0.4-3.7,2.3-4.8,4.1c-0.8-0.5-1.3-1.3-1.4-2.1c-2.7-0.1-5,0.1-6.6,1.1c-1.1-0.1-2.2,0-3.2,0.4c0.2-1.9,0.3-3.8,0.2-5.7
            c3-1.5,3-6.1,5.3-8.2c0.5-2.3-0.8-3.5-0.4-5.2c-0.7-1.1-2.7-4.2-1.1-5.7v-4.8c0.2-0.6-0.6-0.2-0.5-0.7c0-5.2-0.9-9.5-0.5-15.1
            c0.2-0.6-0.5-0.4-0.5-0.7c0.1-1.2,0.7-4-0.5-4.5c0.8-3.4-0.8-5.9-0.4-9.6c0.2-0.6-0.5-0.4-0.5-0.7v-5.5c1.6-0.4,1.8,0.6,3.2,0.5
            c1.9-0.9,3.5-2.1,5.3-3h7.3c3-1.5,8.6-1.4,12.3-1.6C513.4,162.6,516.5,163.5,518.4,163.2z' />
        </g>
        <g id='Illinois'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Illinois')
          })} d='M478.4,153.8v3.7c0.4,1.5,1.3,2.8,2.5,3.7c0.7,2.2,1.1,4.6,2.5,6c-0.3,4.6,0.6,7.4,0.4,11.6
            c0,0.4,0.3,0.5,0.5,0.7c0.2,1.4-0.5,3.8,0.5,4.4c0.4,6.4,0.8,13.5,0.9,19.6c0,0.5,0.6,0.5,0.7,0.9c-0.1,2.6-0.3,5.2-0.7,7.8
            c0.4,0.9,0.9,1.8,1.6,2.5c0.4,3.2,1,6-1.6,7.3c0.3,2.3-1.7,3.6-2.3,5.5c-0.2,0.2-0.8,0.1-1.1,0.4c0.4,3.5-0.5,5.8-0.5,8.9
            c-0.3,0.8-1.3,0.9-1.4,1.8c0,1.5,1,2,1.4,3c-1.9-0.7-2.2,1-4.1,0.9c-0.4,0.4-0.7,0.9-1.2,1.1c0.2,1.3-0.3,1.8-0.5,2.7
            c0.6,0.3,1.1,0.8,1.4,1.4c-0.2,0.4-0.4,0.8-0.5,1.2c-2.3-0.2-3.2-1.8-5.9-2c-1.1,1.4-3.5,1.6-3.6,4.1c-0.3,0-1-0.5-1.2,0
            c-1.5-0.9-1.3-3.5-2.5-4.8c0.3-0.2,0.7-0.3,1.1-0.2c-0.2-4-3-5.5-3.9-8.9c-1.6,0-2-0.6-3.9-0.5c-2.1-2.3-5.6-4.2-6.6-7.1
            c1.9-2.9,2.6-5.9,3-10.3c0-0.5-0.9-0.3-1.4-0.4V218c-1.8-0.6-5.6,0.6-6.6-0.9c0.4-0.7,0.1-2.2,0.2-3.2c-0.8-0.7-1.1-1.9-2.5-2
            c-2.8-3.7-7.6-6.3-8.9-11c-0.2-1.6-0.3-3.3-1.1-4.5c0.8-0.9,0.6-2.8,2.1-3c-0.3-1.1-0.4-3.5,0.4-4.1h2.3c0.4-0.7,0.1-2.2,0.2-3.2
            c2.3-0.8,3.1-7.4,0-8c0.3-1.5,0.3-2.4,1.1-3.2c2,0.1,2.2-0.7,4.3-0.4c1.9-0.6,3-2,4.1-3.4v-1.6c0.3,0.1,0.6,0,0.9-0.2
            c0.2-2.3,1.5-3.6,1.1-6.6c-1.1-1.3-2.4-2.5-3.9-3.4c0.3-1.5-0.5-1.9-0.7-2.9c-1,0.1-1.6-0.1-1.8-0.9h8.4c0.6,0.2,0.4-0.5,0.7-0.5
            c2.2-0.2,5.4,0.5,6.8-0.5c5.6,0.8,9.3-0.6,15-0.4C474.4,153.3,476.9,154,478.4,153.8z' />
        </g>
        <g id='Idaho'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Idaho')
          })} d='M128.9,16.7c2.8,1.4,7.2,1.2,10.1,2.5c0.1,3.4-1.8,6-2,10c-1,1.8-0.4,4.6-2,6c0.8,2.1,1.9,4.1,3,6.1
            c0.4,2.6-1.6,2.7-1.1,5.3c1.2,0.6,1.3,2.3,2.1,3.2h1.2c2,2.5,3.2,5.4,3.6,8.5c1.1,0.5,1.9,1.6,2.1,2.8c0.5,0,1.2-0.1,1.4,0.2
            c0.1,0.5,0,1.1-0.2,1.6c0.7,0.4,2,0.1,3,0.2c0.3,1.5-1,1.9-0.4,3.2c-2.5,3.4-2.9,7.8-5,11.2V79c-0.3,0.5-0.7,0.8-0.5,1.8
            c1.2,0.8,1.7,2.3,2.9,3.2c2.6-0.5,3.7-1.6,5.5-3c1.3,2-0.3,6.8,1.6,8.2c-0.2,0.8-0.4,1.6,0.5,2c-0.3,4.5,3.1,5.9,4.8,8.4
            c0.2,0.6,0,1.2-0.5,1.6c-0.5,2.8,1.2,3.3,1.6,5.2h2c-0.1-0.7,0.1-1.4,0.5-2c0.5,0.3,1.7,0.9,2.5,0.4c1.9,1.7,4.2-0.3,7.5,0.2
            c0.6,0.2,0.7,0.8,1.4,0.4c0.6,0.8,2.4,0.5,3.7,0.5c1-0.8,0.9-2.8,3-2.5c0.6,1.9,1.2,3.7,2.5,4.8c0.4,2.2-1.1,3.3-0.4,5.3
            c-1.2,0.1,0.1,2.7-1.1,2.9v2.3c-0.6,0.6-0.6,1.8-0.5,3c-1.5,3.8-1.2,9.1-2,13.9c-0.9,2.8-1.3,5.8-1.4,8.7c0.1,0.6-0.5,0.4-0.5,0.7
            v2.3c0.2,0.6-0.5,0.4-0.5,0.7v2.1c-1.8,0.7-2.7-0.8-4.8-0.4c-0.7-0.8-2.6-0.5-4.1-0.5c-1.1-1.2-4.8,0.1-5.5-1.4
            c-2.5,0.4-3.5-0.8-5.9-0.5c-0.6-0.9-2.5-0.4-3.9-0.5c-1.5-0.6-3.2-0.9-4.8-0.9c-0.6,0.2-0.4-0.5-0.7-0.5c-2,0.4-2.7-0.6-4.4-0.5
            c-0.4,0.1-0.3-0.3-0.4-0.5c-3.9,0.1-5.1-1.4-8.9-1.4c-0.6-0.6-1.8-0.6-3-0.5c-2.1-0.8-4.2-1.3-6.4-1.4c-0.4-0.6-1.4-0.6-2.5-0.5
            c-1.4-0.5-3.4-1.3-5.2-0.9c-0.2-0.1-0.1-0.5-0.4-0.5c-1.7,0.3-2.1-0.9-3.9-0.5c-2.3-1-4.8-1.5-7.3-1.4c-1-0.6-2.2-1-3.4-1.1
            c-0.5-1.9,0.9-2.1,0.4-3.4c0.6-1.8,1.6-4.1,1.4-6c0-0.4,0.7-0.2,0.7-0.7c-0.3-2.1,1.1-3.2,0.9-5.7c-0.1-0.4,0.2-0.3,0.5-0.4V118
            c0.3-0.3,0.9-1.3,0.4-2c0.6-0.4,0.9-1.3,0.5-2c0.9-0.4,0.1-2.4,1.1-2.7c-0.3-2.2,1.8-2.9,0.9-5.2c1.4-2.4,2.5-5,3-7.7
            c-0.2-0.7-1.1-0.6-0.9-1.8c-0.8-0.1-1.7-0.1-2.5,0c-0.2-0.7,0.5-0.4,0.5-0.9c-0.4-3.9,2.1-4.6,3.4-6.9h1.6c0.6-1.2,1.8-1.7,1.4-3.9
            h0.7c1-2.2,2.4-4.3,4.1-6.1c0.3-0.3-0.1-0.3,0-0.9c0.9-0.2,1-1.2,2.1-1.2v-3.4c0.1-0.7-0.8-0.4-0.5-1.2c-1.5-0.6-2.2-2.1-3-3.4
            c0-0.7,0.1-1.4-0.5-1.4c1.1-1.3,0.3-4.6,0.5-6.8c0.9-0.4,0.1-2.4,1.1-2.7c0.1-3.2,0.8-3.7,0.9-6.8c1.8-4.8,2.2-11.1,4.1-15.8
            c0.1-0.7-0.3-1-0.2-1.8c1.1-3.3,2-6.6,2.5-10C128.2,21.4,128.8,19.1,128.9,16.7z' />
        </g>
        <g id='Hawaii'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M213.2,422.2v2.5c-1.1-0.4-0.8,0.6-1.1,1.1c-2.3,0.1-3.3,1.5-6.2,1.1c-1.8-2-4.1-4.1-5.2-6.2
            c0.4-1.4,0.2-2.5,2.1-2.5c1.8,0.9,3.1,2.3,6.2,2C210.4,421.1,211.7,421.8,213.2,422.2z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M229.6,442.1c1.2,1.2,3.5,1.3,3.4,3.9c-2.5,0.7-3,3.3-6,3.4c-0.4-0.1-0.2,0.5-0.5,0.5
            c-0.6,0.1-0.3-0.8-1.1-0.5c-1.6,0.9-3.2,1.9-4.6,3c0.1,1.9-1,2.7-2,3.6c-1.7-0.7-3.2-1.6-4.6-2.7c0.1-1.6-0.3-3.8,0.5-4.6
            c-1.2-2-0.9-5.7-3-6.8v-2c1.9-0.7,2.6-2.6,3.9-3.9c-1.5-0.4-2.6-5.2,0.5-4.6c1.3,1.4,3.8,1.6,5.2,3c2.7,0.7,5.3,1.5,6.4,3.7
            c0,0.4,0.8,0,0.9,0.4C228.5,440.2,229.4,440.8,229.6,442.1z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M185.6,413.5c0.2,1-0.6,0.8-0.7,1.4h-3.4c-0.1-0.6-0.6-0.7-0.9-1.1c-1.2,0-1.5,0.9-3,0.5c-1-1.7-2-3.6-3.2-5.2
            c1.9-1,4.1-2.7,6.2-2.9c0.6,2.3,2.3,3.1,3,5.7C184.6,412.3,185,413,185.6,413.5z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M145.9,401.4c0,0.2,0,0.3,0.2,0.4c-0.4,1.8-1.8,2.6-2.9,3.7c-0.9,0.1-1-0.5-1.4-0.7
            C142.8,403.2,143.1,401,145.9,401.4z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M199.9,421.8c0.2,1-0.7,1.1-0.7,2c-1.4-0.3-1.6,1.1-2.9,0.4c-0.3-1.2-0.8-2.3-1.6-3.2
            c-0.1-1.6,2.3-0.6,3.6-0.9C198.6,420.8,199.4,421.2,199.9,421.8z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M201.5,416.3c-0.3,0.7-0.8,1.4-1.2,2c-3,1-6.4-0.2-9.4-0.7c0.2-2.6,2.3-2.9,4.6-2c0.4-0.1,0.6-0.5,1.1-0.5
            C197.2,415.9,200.6,415.1,201.5,416.3z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M158.4,398v2.5c-1,1.4-0.6,4.2-3.6,3.6c-1.8-0.8-3.8-1.3-5-2.7c-0.1-1.4,0.9-1.8,1.1-3c1.9-0.9,4-1.3,6.1-1.1
            C157.3,397.7,157.9,398,158.4,398z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M228.5,438.6c-0.1,1.6,0.9,2.2,1.1,3.6c1.2,1.2,3.5,1.3,3.4,3.9c-2.5,0.7-3,3.3-6,3.4
            c-0.4-0.1-0.2,0.5-0.5,0.5c-0.6,0.1-0.3-0.8-1.1-0.5c-1.6,0.9-3.2,1.9-4.6,3c0.1,1.9-1,2.7-2,3.6c-1.7-0.7-3.2-1.6-4.6-2.7
            c0.1-1.6-0.3-3.8,0.5-4.6c-1.2-2-0.9-5.7-3-6.8v-2c1.9-0.7,2.6-2.6,3.9-3.9c-1.5-0.4-2.6-5.2,0.5-4.6c1.3,1.4,3.8,1.6,5.2,3
            c2.7,0.7,5.3,1.5,6.4,3.7C227.7,438.6,228.4,438.2,228.5,438.6z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M198.3,420c0.4,0.8,1.1,1.2,1.6,1.8c0.2,1-0.7,1.1-0.7,2c-1.4-0.3-1.6,1.1-2.9,0.4c-0.3-1.2-0.8-2.3-1.6-3.2
            C194.6,419.4,197,420.3,198.3,420z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M213.2,422.2v2.5c-1.1-0.4-0.8,0.6-1.1,1.1c-2.3,0.1-3.3,1.5-6.2,1.1c-1.8-2-4.1-4.1-5.2-6.2
            c0.4-1.4,0.2-2.5,2.1-2.5c1.8,0.9,3.1,2.3,6.2,2C210.4,421.1,211.7,421.8,213.2,422.2z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M201.5,416.3c-0.3,0.7-0.8,1.4-1.2,2c-3,1-6.4-0.2-9.4-0.7c0.2-2.6,2.3-2.9,4.6-2c0.4-0.1,0.6-0.5,1.1-0.5
            C197.2,415.9,200.6,415.1,201.5,416.3z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M180.7,406.3c0.6,2.3,2.3,3.1,3,5.7c0.9,0.2,1.3,0.9,2,1.4c0.2,1-0.6,0.8-0.7,1.4h-3.4
            c-0.1-0.6-0.6-0.7-0.9-1.1c-1.2,0-1.5,0.9-3,0.5c-1-1.7-2-3.6-3.2-5.2C176.4,408.2,178.5,406.5,180.7,406.3z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M145.9,401.4c0,0.2,0,0.3,0.2,0.4c-0.4,1.8-1.8,2.6-2.9,3.7c-0.9,0.1-1-0.5-1.4-0.7
            C142.8,403.2,143.1,401,145.9,401.4z' />
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Hawaii')
          })} d='M158.4,398v2.5c-1,1.4-0.6,4.2-3.6,3.6c-1.8-0.8-3.8-1.3-5-2.7c-0.1-1.4,0.9-1.8,1.1-3c1.9-0.9,4-1.3,6.1-1.1
            C157.3,397.7,157.9,398,158.4,398z' />
        </g>
        <g id='Georgia'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Georgia')
          })} d='M556.2,279.1c0.1,1-1.2,0.6-1.4,1.2c-0.2,0.8-0.8,1.2-1.2,1.8c-0.1,1.2,0.4,1.3,0.5,2.5
            c1.5,0.6,3.4,0.9,3.7,2.7c1.1,0,2.9-0.1,3.4,0c1.4,1,1.9,2,2.3,4.1c2.4,2.6,3.9,5.3,7.8,6.2c0.8,2.7,4.4,4.1,6.6,6
            c-0.2,2.4,1.6,2.9,2.5,4.3c0.4,0.1,0.8-0.1,1.2,0c0.2,0.1,0.8,0.9,1.2,1.1c1.1,0.4,1.4,0.6,1.8,1.6c0.2,0.7,1.9,2.8,2.1,3.6
            c0.1,0.2-0.2,0.6-0.2,0.9s1,1,0.5,2.1c4,0.2,4.7,3.1,5,6.8c1,1.1,3.1,1.6,5,1.4c-1.2,1.9-2.1,4.1-3.6,5.7c-0.3,1,0.6,0.9,0.5,1.8
            c0,0.6-0.8,0.5-0.9,1.1c-0.2,5.4-2.9,9.3-1.6,15.1c-3.8,0.5-6.8-2.4-9.3,0c-0.5,3.2,1.5,3.7,0.9,7.3h-1.5v-2.7
            c-0.1-0.3-0.7-0.1-0.5-0.7c-3.1-0.4-4.6,0.7-7.5,0.5c-0.7-0.2-0.3,0.7-0.9,0.5c-2.7-0.1-7.5-0.7-9.6,0.4c-7.4,0.2-14,1-21.5,1.1
            c-2.2-1.3-3.2-3.8-3.9-6.6c-0.2-0.6-0.8-0.8-1.4-1.1c0.8-3.5,0.4-7.2-1.1-10.5c0.8-1,0.5-2.9,0.5-4.6c0.6-1.2,1.7-2,1.4-4.1
            c-0.2-0.6-0.8-0.8-1.4-1.1v-2.1c-2.1-1.7-2.3-4.4-4.1-6.4c0.5-1.7-0.6-2-0.4-4.1c-1.1-1.9-1.4-4.7-2.5-6.6V306
            c-0.4-0.4-0.3-0.7-0.5-1.6c-0.4-0.9-0.7-1.9-0.9-2.9c0-0.3-0.5-0.7-0.5-0.7c-0.1-0.5,0.1-1.2,0-1.8c-0.1-0.3-0.4-0.4-0.5-0.7
            c-0.2-0.6,0.3-1.7-0.5-2c-0.3-3.4-1.2-5-2.5-7.5v-2c0-0.4-0.6-0.1-0.9-0.2v-3.9c1.3-0.2,3.6,0.5,4.1-0.5c2.6,0.4,3.6-0.9,6.2-0.5
            c0.6,0.2,0.4-0.5,0.7-0.5c2.3-0.3,4.3,0.5,6.2,0c0.3-0.1,0.6-0.5,0.9-0.5c2.2-0.4,4.7,0.2,6.9-0.4c0.7-0.2,1.5-0.9,3-0.5
            c0.6,0.2,0.4-0.5,0.7-0.5h3.2C554.4,278.5,556.1,278.6,556.2,279.1z' />
        </g>
        <g id='Florida'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Florida')
          })} d='M591.9,350.5c0.1,1.1,0.4,2.2,1.1,3.2v1.3c0.4,1,1,1.8,1.1,3.2c0.6,0.6,0.9,1.5,0.9,2.3
            c1.5,3.6,3.2,7.1,5,10.5c0.4,0.2,0.4,0.9,1.1,0.9c2.2,5.7,7,8.9,10.3,13.5c0.2,1-0.3,1.2-0.5,1.8c0.2,2.8,1.1,5.6,2.5,8
            c0.5,0.8,1.5,1.2,2,2c0.2,0.4,0.3,1,0.5,1.4c0.5,0.8,0.9,1.6,1.4,2.5c1,1.7,2.3,2.7,2.5,5c2.8,3.7,5.3,6.6,5,13.2
            c-0.1,0.6,0.5,0.5,0.5,0.9c-0.1,3.8,0,7.6,0.5,11.4h-1.1c-1.5,2.1-1.8,4.9-0.9,7.3c-1.8,2.6-3.2,4.4-7.8,4.1
            c-1.3,0.9-1.8,2-3.9,1.4c0.2-0.7-0.3-0.8-0.5-1.1V440c-1-2.1-2.1-4.2-3.9-5.5v-0.9c-0.7-0.2-1.5-0.3-1.6-1.1
            c-1.6,0.1-3.8-0.3-4.8,0.4c-1-1.7-2.4-3-2.5-5.7c-1.2-2.9-4.4-3.8-5.5-6.8v-3.9c-0.3-0.7-1-1.1-2.1-0.9c-1.1,0.4,0,3.1-1.1,3.6
            c-2.6-3.8-5-7.8-8.2-11c1.7-2.4,3.1-4.1,3.6-7.8c-1.1-1.2-4.1-1.1-5.7-0.7c-0.2,1.6,1,1.9,2.1,2.1c-0.5,0.6-0.5,1.7-0.7,2.5
            c-1-1.1-1.7-2.5-2.1-3.9c-0.3-1.4,0.5-3.1-0.5-4.1c1.5-2.9,1-7.8,1.1-12.1c-1.6-1.3-2.7-3-3.2-5c-2.1-0.4-4.2-0.9-6.2-1.6
            c-0.3-2.6-2-2.5-3.9-3.4c0.2-1-0.7-1.2-0.5-2.7c-0.7-0.3-1.5-1.1-2.3-0.7c-1.1-3.2-4.8-3.9-7.5-5.5c-2.1-0.2-4.3,0.2-6.2,1.1v1.8
            c-4.5,1.1-5.1,6.2-11.4,5.5c-0.6,0.2-0.8,0.8-1.8,0.5v-1.8c-0.1-0.8-1-0.8-1.1-1.6c-4.8-1.4-7.2-5.1-13-5.5
            c-0.5-0.8-2.4-0.2-2.8-1.1h-3.9c-0.6-0.1-0.5,0.5-0.9,0.5c-2-0.2-4.1,0.1-5.9,1.1c-1.9,0.3-3.7,0.8-5.5,1.4v-6.6
            c-1.5-1.3-4.3-1.4-3.4-4.6c2.2-0.2,5.3,0.5,6.8-0.5c2.3,0.3,5.2,0.3,6.6-0.9c6.2,0,11.4-1.5,17.1-1.4c0.6,0.2,0.4-0.5,0.7-0.5h7.1
            c0.5,1.8,1.5,2.1,2.1,3.9c2.8-0.3,6.8,0.6,8.7-0.5c3-0.3,7.3,0.6,9.4-0.5c4.8,0.5,9.3-0.8,14.8-0.4c0.5,0,0.6-0.3,0.9-0.5
            c2.6,0.4,4.2-1.1,6.6-0.4v2.5c0.4,1,1.9,0.9,3.4,0.9c0.5-1.3-0.2-3.8,0.4-5.2c-0.9-0.7-1.3-1.8-1.1-2.9
            C585.6,348.9,589,350.3,591.9,350.5z' />
        </g>
        <g id='Delaware'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Delaware')
          })} d='M646,176c0.3,0.8-0.2,1.7-1,2c0,0,0,0,0,0c-0.4,2.9,1,4,1.6,5.9c1.3,0,1.7,0.8,2.5,1.2c0.2,1.8,2.3,4,2.5,5.9
            c0.7,0.5,1.7,0.8,2,1.8h1.8c0.4,2.1,0.6,3.3,1.6,4.8c-1.9,0.2-5.3,0.8-8.2,1.1c-1.2-2.3-0.7-5.3-2.1-7.1c0.3-1.6-0.8-1.8-0.5-3.4
            c0.2-0.6-0.6-0.2-0.5-0.7c0.3-1-0.1-2.1-0.9-2.9v-1.8c-0.5-0.4-0.2-0.7-0.5-1.8c0,0-1.1-2-1.1-2C643,177.6,644.5,176.4,646,176z' />
        </g>
        <g id='Connecticut'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Connecticut')
          })} d='M684.1,127.1c0.8,3.5,2.3,6.4,2,11c-1.8,0.8-3.2,2.1-5.9,2.1c-0.6,0.4-1.4,0.5-1.8,1.1c-2.9-0.2-3,1-5.2,0.9
            c-1,0.6-1.8,1.6-2.3,2.7c-2.5,0.9-3.8,3.8-6.6,3.7c-0.2-1.1,0.8-1.1,1.1-1.8c-0.2-3.4,0.6-7.8-1.1-9.8c0.3-2.3-0.9-3.1-0.5-5.5
            c5.6-1.3,10.7-3,17.1-3.6C680.9,126.6,682.9,127.2,684.1,127.1z' />
        </g>
        <g id='Colorado'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Colorado')
          })} d='M201.6,173c6,0.5,11.4,1.7,17.6,2c0.8-0.2,0.4,0.7,1.1,0.5c6,0.1,9.1,2,15.7,1.4c2,1,4.8,1.2,8,1.1
            c0.8-0.2,0.4,0.7,1.1,0.5c5.7-0.3,9.6,1.1,15.1,0.9c0.8-0.3,0.5,0.6,1.1,0.5c8,0.2,15.3,1.1,23.1,1.4c1.8,1.2,5.8,0.1,8.2,0.7
            c-0.2,1-0.2,2,0,3c-0.9,1.1-0.4,3.6-0.5,5.5c0.2,0.8-0.7,0.4-0.5,1.1c-0.1,7.5-0.9,14.3-0.5,22.2c-1.2,1.8-0.8,5.8-0.9,9.3
            c0.2,0.8-0.6,0.5-0.5,1.1v14.2c-1.6,3.1-0.9,8.4-1.1,13h-10.7c-4.5-1.6-11.6-0.6-16.4-2h-4.3c-0.8,0.3-0.5-0.6-1.1-0.5
            c-7.3-0.4-14.5-1-21.7-1.4c-0.8,0.3-0.5-0.6-1.1-0.5c-8.8,0.1-15.1-2.4-23.7-2.5c-0.8,0.2-0.6-0.6-1.2-0.5
            c-2.9,0.3-3.7-0.5-6.6-0.4c-0.8,0.2-0.5-0.6-1.1-0.5c-3.4,0.2-5.3-1.1-8.5-1.1c0.1-1.5,0.2-2.7,0.5-4.6c0.1-0.5,0.4-0.5,0.5-0.9
            s-0.1-1,0-1.6c0.1-0.4,0.5-0.9,0.5-1.2c0.2-1.9,0-4,0.4-5.7c0.5-1.6,0.7-3.3,0.5-5c1.3-1.4-0.2-5.6,1.1-6.9
            c-0.3-4.3,1.4-5.7,1.4-9.8c-0.3-0.8,0.6-0.5,0.5-1.1v-3.4c0.9-0.9,0.3-3.2,0.5-5c0.1-1.1,0.7-2.1,0.4-3.4c0.9-2.1,0.6-5.6,1.6-7.7
            C200.2,181,201.2,178.2,201.6,173z' />
        </g>
        <g id='California'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('California')
          })} d='M63.1,127.6c0,1.9-1.3,3.2-0.9,5c-0.8,1.9-1.4,3.9-1.6,5.9c-0.7,1.9-1.2,3.9-1.4,5.9c-0.9,1.9-1.4,3.9-1.4,6
            c-1,1.6-1,4.1-2.1,5.5c0.3,4.8-2.6,7.2-2.3,11.8c-1.8,3.5-2.5,7.5-2.7,12.3c0.8,1,1.9,1.6,2.1,3.2c2,1.4,2.1,3.8,3.9,5.2
            c-0.2,1.5,1.4,1.3,1.4,2.7c1.7,1.7,3,3.7,3.9,5.9c3.4,3.9,5.6,9.3,9.4,13.2v0.7c2.9,3.5,5,7.9,8,11.4c0.1,0.6-0.3,0.8,0,1.1
            c2.3,2.2,3.4,5.6,5.9,7.6v0.7c2.1,1.5,2.6,4.6,4.4,6.2c1,2.4,2.5,4.6,4.4,6.4c0.7,2.5,2.6,3.7,3.4,6c0.4,0.3,0.5,0.8,1.1,0.9
            c0.9,2.5,2.9,3.8,3.9,6.6c1.8,1.5,1.7,4,3.9,5.2c-0.2,0.5,0.1,1,0.5,1.2c0,0.9,0.1,1.9-0.5,2.1c0.5,1.3,1.2,2.5,2.1,3.6
            c-0.2,2.2,1.3,3.8,1.4,6.2c0.4,0.3,0.5,0.9,1.4,0.7v2c-2.2,0.9-5.1,1.1-5.3,3.9c-0.6,0-0.5,0.7-1.1,0.7c-0.2,2.1-1.1,3.6-0.9,5.5
            c-1.2,1.1-2.2,2.4-4.1,2.9c0.9,3.8-2.7,8,2.5,7.3c0.4,1.7-0.6,2-0.5,3.4c-3.6,2-7.2,0-11.6,0.2c-0.2-0.2-0.3-0.4-0.5-0.5
            c-3.4,0.4-5.7-0.5-8.7-0.5c-0.6,0.2-0.2-0.6-0.7-0.5c-5.7,0.4-9.2-1.5-14.6-1.4c-0.6-0.5-1.5-0.7-2.3-0.5
            c-0.9-2.7-0.4-6.8-0.5-10.3c-1-1.8-1.1-4.6-3-5.5v-1.1c-1.9-2.2-3.8-4.4-5.5-6.8c-1.6,0.3-3.2-0.2-4.4-1.2c1.4-1.2,0.2-4.7-0.9-5.7
            c-1.8,0.3-2.7-1.2-3.9,0c-1.1-1.2-2.9-1.7-3.9-3c-0.9-2.7-2.4-5-4.5-6.9c-5,0.5-6.1-4.1-11.9-3.4c-0.9-0.4-1.3-1.1-2-1.8
            c0.2-4.1,1.3-7.1,2-10.7c-0.7-0.9-1.6-1.5-1.4-3.2c-1.2-2.8-2.9-5-4.1-7.8c-0.2-0.3-0.5-0.6-0.9-0.7c-0.3-1.9-0.6-3.7-1.6-4.8
            c0.6-4-2.4-5.9-3.9-8.4c-0.2-1.8,0.9-2.2,0.5-4.1c3.8,0.4,5.6-8.2,0.2-7.5c-1.4-1.4-1.8-3.9-3.2-5.3c-0.4-3.9,0.5-6.5,1.1-9.4
            c0.5-0.1,0.5-0.7,1.2-0.5c-0.3,2.3-0.6,6.5,3,5.9c0.8-3.5-0.8-6-1.4-8.7c0.9-0.2,2.1-0.2,2.1-1.2c0.2-1.3-1.3-0.9-1.1-2.3
            c-2.7-0.1-3,2.1-2.8,4.8c-2.3-1.1-3.4-3.4-5-5.2c1.6-4.7-1-9.5-3.6-12.6c0.1-2.9-2.6-3-2.5-5.9c-0.2-0.6,0.5-0.4,0.5-0.7
            c-0.1-3.9,0.6-7.1,2.5-9.1v-6c-1.3-3-2.7-6.2-4.5-9.3c0.7-2,1-4.6,3.2-5.2c0.7-1.9,3-2.4,3.2-4.8c0.7,0.1,0.5-0.8,1.1-0.9
            c0.4-2.9,1.8-5,2.8-7.3v-6.4c0.7-0.2,0.5-1.2,0.5-2.1c0.3-0.2,0.6-0.4,0.7-0.7c3,0.9,5.5,2.3,9.3,2.5c1,0.7,2.6,0.8,3.4,1.6
            c3.2-0.3,4.2,1.7,7.5,1.4c5.5,2.1,11.2,3.7,16.9,5c0.6-0.1,0.2,0.6,0.7,0.5c2.1,0.1,4.2,0.8,6,2c2.4-0.1,3.9,0.7,6,0.9
            C62,127.3,62.5,127.5,63.1,127.6z' />
        </g>
        <g id='Arkansas'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Arkansas')
          })} d='M455,262c0.4,1.1,0.6,2.2,0.5,3.4c-2.3,0.6-2.8,3-3.4,5.3c2.9,0.6,6.1-0.1,8.7-0.4c-1.4,2.2-2,4.7-1.8,7.3
            c-1.5,0.4-2.2,1.4-3.6,2c0.4,2.1,1.6,3.4,1.6,5.9c-0.8,0.7-1.9,1.1-3,1.2v3.2c-2.8,0.2-2.7,3.7-3.9,5c-0.1,1.7,0.7,2,0.5,3.4
            c-0.3,0.3-1.2,0.1-1.8,0.2c-1.7,1.7-2.4,3.8-3.7,5.5c-0.3,1,1.1,0.2,1.1,0.9c-0.4,1.2-1.8,1.3-3,1.6v2.3c-0.5,0.7-0.8,3.8-0.5,5.7
            c0.3,0.9,0.3,1.8,0,2.7c0.7,1.8,1.8,3.1,1.4,6h-4.4c-0.8-0.2-0.4,0.7-1.1,0.5c-11.9,0.4-24.8,0.9-37,0.4v-8.7
            c-0.9-0.4-1.8-0.8-2.7-1.1c-1.3-0.2-1,1.1-2.1,1.1c-0.5-0.3-1-0.6-1.6-0.7v-35.3c-0.4-0.8-0.4-1.9-1.1-2.5c0.1-2-0.2-4.4,0.2-6
            c-0.5-0.4-0.4-1.5-1.1-1.8c0.4-2.2-0.8-2.9-0.5-5c6-0.4,12,0.1,18.2-0.5c5-0.5,10.3,0.8,15.3-0.2c2.4-0.5,6.8,0.2,9.8-0.4
            c0.5-0.1,0.8-0.5,1.2-0.5c2.9-0.4,5.9,0.3,8.7,0c0.1-0.2,0.3-0.4,0.5-0.5L455,262z' />
        </g>
        <g id='Arizona'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Arizona')
          })} d='M190.5,242.8c-0.2,1,0.2,2,0,3.2c-0.1,0.3-0.5,0.7-0.5,1.1c-0.1,1.1,0.5,2.5,0.2,3.6c-0.1,0.2-0.5,0.5-0.5,0.7
            c-0.2,1.2,0.2,2.5,0,3.7c0,0.3-0.4,0.4-0.5,0.7c-0.4,1.1,0.3,2.7,0,3.7c0,0.1-0.5,0.5-0.5,0.7c-0.1,0.9,0.2,2,0,2.9
            c-0.2,0.5-1.1,1.6-0.4,2.9c-0.7,1.9-1,4-1.1,6c-0.7,0.5-0.5,1.8-0.5,3c-1.3,3.6-0.8,8.6-1.4,13.2c0.2,0.6-0.5,0.4-0.5,0.7v2.9
            c0.1,0.6-0.6,0.2-0.5,0.7c0.5,2.5-1.1,3.9-0.4,6c-0.8,0.6-0.5,2.2-0.5,3.6c-0.8,0.6-0.5,2.3-0.5,3.6c-1.3,1.9-0.4,4.8-0.9,6.8
            c0,0.2-0.7,0.5-0.7,0.7c-0.1,0.7,0.4,2.1,0.2,2.9c-0.8,2-1.1,4.2-1.1,6.4c-0.8,0.6-0.5,2.3-0.5,3.6c-0.8,0.5-0.7,2.8-0.4,3.7
            c-0.8,0.7-0.5,2.6-0.5,4.1c-0.9,0.6-0.4,2.5-0.5,3.9c-0.9,0.6-0.4,2.5-0.5,3.9c-6.6-1.5-14.2-2.1-21.4-3c-1.3-1-4.3-0.3-5.5-1.4
            h-2.7c-8.4-4.8-16.5-10-25.3-14.4c0.2-0.7-0.7-0.3-0.5-0.9c-1.9-0.3-2.8-1.6-4.6-2c-0.3-0.6-1-0.7-1.2-1.4c-3.8-1.4-6-4.2-9.8-5.5
            c-1.7-2-4.8-2.5-6.6-4.5c-1.2-0.3-2.3-0.9-3.4-1.6c0.3-1.4,1.9-1.4,2.1-2.9c2.3,0.1,3.3-0.2,4.3-1.1c0-1.5,0.9-2.2,0.5-4.1
            c-0.7-0.8-1.8-1-2.9-0.7c0.5-0.9,0.4-3.9,0.4-5.9c2.3,0,2.6-1.9,3.9-2.9c-0.2-1.7,0.9-1.9,0.5-3.7c0.5-1.6,1.6-2.6,1.9-4.3
            c1.9-0.5,3.2-1.5,5-2.1v-2.7c-0.4-0.3-0.5-1-1.4-0.9c-0.9-3.2-1.4-6.5-3-8.5c-0.2-2.5,0.4-3.4,1.4-4.5c-0.4-6.2,0.7-10.9,0.5-16.9
            c0.3-0.8,1.9-0.2,2.1-1.1c0.6,0.1,0.5,0.8,1.1,0.9c2-0.3,2.4,1.1,2.7,2.5h2.9c0.8-0.7,1-2.1,2.1-2.5c-0.6-3.6,1.5-4.5,1.1-8
            c-0.1-0.6,0.6-0.2,0.5-0.7c-0.4-2.2,0.5-2.7,0.4-5c3.7-0.3,6.1,1.5,10,1.4c0.4,0.6,1.4,0.6,2.5,0.5c0.6-0.2,0.2,0.6,0.7,0.5
            c4.3,0,8.6,2.2,13.4,1.8c0.1,1,1.8,0.4,2.7,0.5c0.6-0.1,0.4,0.5,0.7,0.5h2.3c0.5,0.7,1.8,0.5,3,0.5c2,0.7,4.1,1,6.2,0.9
            c2.1,0.7,4.3,1.1,6.6,1.1c0.4,0.6,1.4,0.6,2.5,0.5c0.6-0.1,0.2,0.6,0.7,0.5s1.5,0.2,1.8-0.2c1.5,0.9,3.7,1.2,6.2,1.1
            c0.9,0.9,3,0.5,4.6,0.5C187.4,243.1,189.2,242.7,190.5,242.8z' />
        </g>
        <g id='Alaska'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Alaska')
          })} d='M147.4,481.3c0.3,3.2,1.5,5.1,0.5,8.2c-1.1,0.5-1.3,2-2.3,2.7c-1.3-0.6-2.2-1.9-2.3-3.4
            c-1.9,0.2-2.4-1-3.6-1.6c0.2,1.3,1.1,3.4,0.7,5.9c-0.3,0.3-0.9,0.2-1.4,0.2c-0.2-0.2-0.2-0.6-0.2-1.1c-0.6,0.1-1,0.7-0.9,1.4
            c0,0,0,0,0,0c-0.6-0.1-0.8,0.1-1.2,0.2c-1.5-1.7-3.3-3.1-2.9-6.8c-0.5-0.6-1.3-0.8-2-1.2c0.1-1.2,0-2.1-0.7-2.5
            c-0.5,0.3-0.7,0.9-0.7,1.4c-3.6-0.5-4.9-3.3-6.1-6.2c-0.3-0.6-1.3-0.5-2-0.7c0.3-1.5-0.9-1.6-0.5-3.2c-0.9-1.5-4-3.2-2.5-5.5
            c-2.8-0.7-5-3.4-6.9-5.5c-0.8,0-1.5-0.4-2-1.1c-2-0.3-3.1-1.6-4.4-2.7c0.5-0.3,0.4-1.2,1.1-1.4c-0.7-1-1.8,0.2-2.1,0.7
            c-0.6,0.2-1.2,0-1.6-0.5c-2.9,0.3-3-2.1-5.7-2c-1-1-3.6-0.3-5.3-0.5c-0.6-0.2-0.2,0.6-0.7,0.5c-2-1.3-3.6-3.1-6.6-3.4
            c-0.9-1.6-2.2-2.9-2.7-5c-2.6-0.3-3.5,1.1-5.3,1.6c0.3,1.9,0.3,3.8,0,5.7c-0.5,0.1-1.1,0.1-1.6,0v-0.9c-0.6,0.1-0.6,0.8-1.1,1.1
            c-0.5-0.8-1.9-0.7-2.3-1.6c-0.7-0.1-0.4,0.8-1.2,0.5c-1.4,2.1-3.3,3.8-5.5,5h-3.4c-2-1.9,0.8-2.9,2.3-3.4c-0.7-0.2-1.7-0.2-2-0.9
            c0.6-2.2,1.9-3.7,3-5.3c0.3-1-0.8-0.6-0.5-1.6c1.5-1.1,3.2-1.9,5-2.3c0.2,0.3,0.2,0.6,0.2,0.9h1.2c-0.3-0.6-1-0.9-1.1-1.8
            c-5.6-0.2-6.9,3.8-9.6,6.4c0.3,2.2-1.6,2.2-2.1,3.5c-1.9-0.1-3.7,0.8-4.8,2.3c0.8,0.6,1.6,1.1,2.6,1.5v2.5c-3.3,3.6-8.1,6.3-12.6,8
            c-0.6,0.9-1.8,1.2-2.5,2c-0.8,0-1.8-0.1-2,0.5c-2.7-0.1-4.7,1.2-6.2,1.6c0.3,0.4,0.9,0.5,1.1,1.1c-1,0.8-0.9,1.5-1.8,2.3
            c-0.7,0.2-0.8-0.3-1.1-0.5c-3.6-0.3-6.5,0.1-8.4,1.6c-1.4-0.1-3.2,0.2-4.3-0.2v-0.9c-1.4-0.1-1.2,1.4-2,2c-1.1,0-2.5,0.3-2.8-0.5
            c-1.1,0.4-2,1.2-2.7,2.1c-1.7-0.1-3.1-1.2-5.5-1.1c-0.3,0.3-0.6,0.6-0.9,0.9c-1,0.1-1.1-0.5-2-0.5v-2c1.9-1.4,4.3-2,6.6-1.6
            c1.1,1.2,1.9-0.2,3.6,0c2.1-1.9,4.3-3.7,8.7-3.4c0.3,0.7,1,1.1,1.8,0.9c0.6-1,1.7-1.5,2.3-2.5c1.7-0.2,3.4-0.7,5-1.4
            c0.7,0,1.4-0.1,1.4,0.5c0.7-2.3,2.6-3.5,5.2-4.1c0.5-2.7,2-4.3,2.5-6.6c1.1-0.2,1.6-1,2-2c-1.1,0-2.6,0.9-4.3,0.9
            c-0.3-0.8,0-2.2-0.7-2.5c-0.9,0.3-0.7,1.7-0.7,2.8c-0.7,0.3-1.4,0.3-2.1,0c0.3-1.8-0.9-2-0.5-3.7c-0.9-0.2-0.8,0.5-1.4,0.5
            c-0.6-0.5-1.3-0.8-1.1-2.1c-2.3-0.5-3.5,0.9-6.2,1.1c0-0.6-0.8-0.4-0.7-1.1c0.2-0.2,0.6-0.2,1.1-0.2c-0.2-0.6,0-1.3,0.5-1.8
            c-0.5-0.7-0.7-1.6-0.5-2.5c0.8-0.2,0.8-1.3,2-1.1c-0.9-0.9-0.4-3.1-0.5-4.8c-0.4,0.3-0.7,0.7-1.1,1.1c-2.7,0.1-5.4,0.2-6.4-1.4
            c0.6-1.2-0.6-1.6-0.3-3.4c-1.1-1-2.4-2.4-2-3.9c0.6-0.1,0.5,0.5,0.9,0.5c1.1-0.7,1-2.6,2.1-3.2c-0.6,0-0.7,0.4-1.1,0.5
            c-2.2-1-1.5-4.1-1.1-6.6c2-1.3,3.5-3.1,5.9-4.1c1.4-1.6,1.7-4.3,5.2-3.9c0.6,0.8,1.1,1.7,1.8,2.5c1.9-0.1,2.4-1.6,4.1-2
            c1.4,0.9,3.5,1.2,4.8,0c0-2.3,0.9-3.7,1.1-5.9c0.4-0.2,0.8-0.5,1.1-0.9c-1.2-2.4-4.3,0.4-6.8,0c-0.7-2.1-3.5-2-6.4-2
            c-1.1-1.2-2.6-2-3.7-3.2c-0.1-1.7,0.3-3.3,1.1-4.8c-1.2-1.4-3.8-2.2-2.8-5.2c3-0.1,4-0.7,6.9-0.9c0.5-0.1,0.3,0.4,0.5,0.5
            c0.4,0,0.3-0.6,0.3-1.1c1.5-0.7,3.7-0.6,5.9-0.5c0.2,0.8,1,0.9,1.2,1.6c-0.5,0.7-1,1.5-1.6,2.1c1.4,1.7,4.7,3.3,7.3,2.1
            c-0.9-1.8-3.3-3.7-2.1-6.4c-1.1-0.4-2-1.1-2.8-1.9c1.4-4.7-2.8-8-4.4-11.4c0.8-1,1.7-1.9,2.8-2.5c2.1,0.4,4.3,0.6,6.4,0.5
            c1.8-0.8,3.1-2.4,3.6-4.3c1.5-0.8,2.6-2.1,4.3-2.7c0.6-0.1,0.8,0.3,1.1,0.5c1.8-0.6,3.4-1.5,5-2.5c2.8,1.3,6.1,0.4,8-2
            c2,0.8,3.8,2.1,5.2,3.7c0.1,0.6-0.7,0.2-0.5,0.9c0,0.5,0.5,0.4,0.5,0.9c0.5,0,0.2-0.8,0.5-1.1c1.3-0.2,2.7,0.1,3.9,0.7v2.7
            c0.5,0.3,1.4,0.3,1.4,1.1h5.7c1.6,1.4,3.4,2.6,5.3,3.6c0.3-0.2,0.2-0.7,0.9-0.5c1.2,0.8,3.1,0.9,4.1,2c1.1,0.1,2.2-0.3,3-1.1
            c3.4,0.3,4.9,2.6,7.6,3.6v68.5c0.9,0.8,4.1,1.3,4.4-0.4c0.8,0.1,2-0.2,2.5,0.2v2.1c2.1,2.6,5.3,4,6.4,7.7c0.9-0.6,1.5-1.5,2.9-1.6
            c-0.2-1.4,0.2-2.9,1.2-3.9c1.4,0.2,1.8-0.7,2.3-1.4c1.8,0.9,2.7,2.7,3.6,4.5c1.3-0.2,1.1,1,2,1.2v1.1c5.3,2.2,6.1,8.8,10.3,12.1
            v1.2c0.3,0.6,1.3,0.5,1.1,1.6c2.3-0.1,2.6,1.9,4.8,1.4C144.9,480.1,146.5,480.4,147.4,481.3z' />
        </g>
        <g id='Alabama'>
          <path onMouseOver={this.handleMouseEnter} onMouseMove={this.handleMouseMove} onMouseOut={this.handleMouseLeave} className={classNames(styles.state, {
            [styles.green]: accent === 'green',
            [styles.orange]: accent === 'orange',
            [styles.pink]: accent === 'pink',
            [styles.gray]: accent === 'gray',
            [styles.static]: accent === 'static',
            [styles.compliance]: this.isCompliance('Alabama')
          })} d='M485.9,340.9c0,0.1-0.5-0.6-0.5-0.5c-0.2-2.3,0.2-5.6,0.2-8.7c0-2.8-0.5-6-0.2-8.5c0-0.1,0.5-0.2,0.5-0.4
            c0.8-2.5,0-6.8,0-9.6c0-3.3-0.4-6.9,0-9.6c0-0.3,0.4-0.4,0.5-0.7c0.6-2,0-5.2,0-7.6v-7.5c0.1-0.9-1.4-0.8-0.9-1.4
            c7.3-0.6,14.2-1.6,22.2-1.4c0.6,0.1,0.2-0.6,0.7-0.5c4.5-0.2,8.4-0.9,13-0.9v3c0,0.6,0.8,0.5,0.9,1.1c0,0.9-0.1,1.8,0.5,2v1.2
            c1.4,1.9,1.2,4.3,2.5,6.2c0,0.9-0.1,1.8,0.5,2c0.1,2.6,0.8,5.2,2,7.5v2.3c1.2,1.9,1.8,4.1,2,6.4c0.6,1.1,0.9,2.4,0.9,3.7
            c0.6,1.4,2,3.1,2.1,4.8c0.8,0.7,1.5,1.6,2,2.5v2.5c0.1,0.4,1,0.1,1.4,0.2c0.3,1.9-0.8,2.5-1.4,3.6v3.4c-0.6,0.5-0.6,1.4-0.5,2.5
            c1.9,2.1,1.5,6.7,0.9,10c0.8,1,2.3,1.2,2.1,3.2c-9-0.3-15.4,2.1-24.2,2c-0.5,0.6-1.9,0.4-2.3,1.1h-4.8c-0.6-0.2-0.2,0.6-0.7,0.5
            h-5.9c-0.3,0.1-0.1,0.7-0.7,0.5c-0.8,4,1.8,4.6,3.4,6.2v5.5c-1.8,0.5-3.2,1.5-5.2,2c0.4-0.6,1.4-0.5,1.4-1.4
            c-1.9-2-1.3-6.5-5.3-6.4c-0.2,2.2-1.2,3.6-1.8,5.5c-1,0-1.9,0-2.8-0.2c0.3-4-0.6-6.2-0.4-9.8c-1.8-1.2-0.3-6.5-1.6-8.7
            c-0.2-1.3,0.5-3.4-0.5-3.9C485.6,343.2,486.1,342.1,485.9,340.9z' />
        </g>
      </svg>
    </div>
  }
}

USMap.propTypes = {
  accent: PropTypes.string,
  data: PropTypes.array,
  isStatic: PropTypes.bool
}

USMap.defaultProps = {
  accent: 'green'
}

export default USMap
