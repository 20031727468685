import _ from 'lodash'

export function request (params) {
  let data = [
    {
      id: '1',
      serviceProvider: 'Abbasi & Associates, LLC',
      phone: '702-749-4594',
      website: 'www.kimmoabbasi.com',
      country: 'United States',
      activeCompaniesCount: 5
    },
    {
      id: '2',
      serviceProvider: 'Abbasi & Associates, LLC',
      phone: '702-749-4594',
      website: 'www.kimmoabbasi.com',
      country: 'United States',
      activeCompaniesCount: 5
    },
    {
      id: '3',
      serviceProvider: 'Abbasi & Associates, LLC',
      phone: '702-749-4594',
      website: 'www.kimmoabbasi.com',
      country: 'United States',
      activeCompaniesCount: 5
    }
  ]

  const length = data.length

  // Sort On
  if (params.sortOn) {
    data = _.orderBy(data, params.sortOn, params.sortDir || 'desc')
  }

  // Handle Page
  let page = 1
  if (params.page) page = params.page
  // Page Size
  if (params.pageSize) {
    data = data.slice(page === 1 ? 0 : (page - 1) * params.pageSize, page === 1 ? params.pageSize : ((page - 1) * params.pageSize) + params.pageSize)
  }

  return {
    totalRecords: length,
    pages: Math.ceil(length / params.pageSize),
    pageSize: params.pageSize,
    records: data,
    sortOn: params.sortOn,
    isRealTime: false,
    bySymbol: true
  }
}
