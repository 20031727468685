import React, { Component } from 'react'
import PropTypes from 'prop-types'

const externalSymbols = {
  '.OTCDR': '.DFCDR',
  '.OTCQX30': '.DGCQX',
  '.INDU': '.INDU',
  '.SPX': '.SPX',
  '.NCOMP': '.NCOMP',
  '.OTCQX': 'SLA0TC',
  '.OTCQXINT': 'SLA0TD',
  '.OTCQXUS': 'SLA0TE',
  '.OTCQXBK': 'SLA4TE',
  '.OTCQB': 'SLA6QC',
  '.OTCQXBIL': 'SLA0D0',
  '.OTCQXDIV': 'SLA168',
  '.OTCQXCAN': 'SLA2HT',
  '.OTCQXMJ': 'SLA7X7'
}

const defaultConfig = {
  // action, initialize a chart
  action: 'initialize',

  feed: '//charting.edgar-online.com/data',

  // initial symbol, set using initialize()
  symbol: 'IBM',

  // source file for charts
  src: '//charting.edgar-online.com/dynamic/chart.html',

  // set to true to configure as an investor relations site
  investorRelations: false,

  // set to true for thumbnail configuration
  thumbnail: false,

  // set to a local storage field name, leave out to default
  layoutDBField: 'OTCCharts',

  // custom styles
  css: 'https://s3.amazonaws.com/content.otcmarkets.com/edgar_online/chart/webapps/style.css',

  // todo: update for new site
  linkTemplates: {
    majorSECForms: 'http://www.otcmarkets.com/edgar/GetFilingHtml?FilingID=<filingID>',
    earnings: 'http://www.otcmarkets.com/stock/<symbol>/financials',
    dividends: 'http://www.otcmarkets.com/stock/<symbol>/profile'
  }
}

class Chart extends Component {
  render () {
    if (process.env.NODE_ENV === 'test') return null
    const config = Object.assign({}, defaultConfig)
    if (this.props.symbol) {
      config.symbol = this.props.symbol
      // use external symbol if it exists
      const externalSymbol = externalSymbols[config.symbol.toUpperCase()]
      if (externalSymbol) config.symbol = externalSymbol
    }
    if (this.props.range) config.range = this.props.range

    const onload = (e) => {
      if (!this.iframe) return
      if (this.iframe) this.iframe.contentWindow.postMessage(JSON.stringify(config), '*')
    }

    return (<iframe src={config.src}
      ref={iframe => { this.iframe = iframe }}
      title='chart'
      onLoad={onload}
      style={{ border: '1px solid #ddd' }}
      width='100%' height='600'
      scrolling='no' frameBorder='0' marginHeight='0' marginWidth='0'
    />)
  }
}

Chart.propTypes = {
  symbol: PropTypes.string,
  range: PropTypes.string
}

export default Chart
