export function request (params, pagination) {
  switch (params.symbol) {
    case 'LEAT':
      return {
        isShell: true,
        is12g32b: false,
        hasControlDispute: true,
        isPennyStockExempt: true,
        isBankrupt: true,
        unableToContact: true,
        transferAgentVerified: true,
        isCaveatEmptor: true,
        isDark: true,
        isDelinquent: true,
        hasPromotion: true,
        isShellRisk: true,
        isLinkedToProhibitedSP: true,
        verifiedProfile: true,
        verifiedDate: 1551934800000,
        hasTwoIndDir: true,
        isHotSector: true
      }

    case 'OTCM':
      return {
        isShell: true,
        is12g32b: true,
        hasControlDispute: false,
        isPennyStockExempt: true,
        isBankrupt: true,
        unableToContact: false,
        transferAgentVerified: false,
        isCaveatEmptor: false,
        isDark: false,
        isDelinquent: false,
        hasPromotion: false,
        isShellRisk: false,
        isLinkedToProhibitedSP: false,
        verifiedProfile: false,
        verifiedDate: 1551243600000,
        hasTwoIndDir: false,
        isHotSector: false
      }

    case 'GOOG':
      return {
        isShell: false,
        is12g32b: false,
        hasControlDispute: false,
        isPennyStockExempt: false,
        isBankrupt: false,
        unableToContact: false,
        transferAgentVerified: false,
        isCaveatEmptor: false,
        isDark: false,
        isDelinquent: false,
        hasPromotion: false,
        isShellRisk: false,
        isLinkedToProhibitedSP: false,
        verifiedProfile: false,
        hasTwoIndDir: false,
        isHotSector: false
      }
  }
}
