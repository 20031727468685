import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getSnapshot (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    mockData: 'market-activity/current-market/snapshot',
    url: '/market-data/snapshot/current',
    params
  })
  return response.data
}

export async function getMostActive (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'market-activity/current-market/most-active',
    url: '/market-data/active/current',
    params
  })
  return response.data
}

export async function downloadMostActive (params) {
  const url = '/otcapi/download/internal/market-data/active/current/download'
  const queryString = Object.keys(params)
    .filter(key => params[key])
    .map(key => params[key] && `${key}=${params[key]}`).join('&')
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
  window.open(`${REACT_APP_API_BASE_URI}${url}?${queryString}&file=market-data-current-active&token=${jwtToken}`)
}

export async function getMostActiveHome (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'market-activity/current-market/most-active-home',
    url: '/market-data/active/current/homepage',
    params
  })
  return response.data
}

export async function getAdvancers (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'market-activity/current-market/advancers',
    url: '/market-data/advancers/current',
    params
  })
  return response.data
}

export async function downloadAdvancers (params) {
  const url = '/otcapi/download/internal/market-data/advancers/current/download'
  const queryString = Object.keys(params)
    .filter(key => params[key])
    .map(key => params[key] && `${key}=${params[key]}`).join('&')
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
  window.open(`${REACT_APP_API_BASE_URI}${url}?${queryString}&file=market-data-current-advancers&token=${jwtToken}`)
}

export async function getDecliners (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'market-activity/current-market/decliners',
    url: '/market-data/decliners/current',
    params
  })
  return response.data
}

export async function downloadDecliners (params) {
  const url = '/otcapi/download/internal/market-data/decliners/current/download'
  const queryString = Object.keys(params)
    .filter(key => params[key])
    .map(key => params[key] && `${key}=${params[key]}`).join('&')
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
  window.open(`${REACT_APP_API_BASE_URI}${url}?${queryString}&file=market-data-current-decliners&token=${jwtToken}`)
}
