import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from '../ExternalLink'
import styles from './ContentImage.module.scss'
import getConfig from '../../config'

const { REACT_APP_CMS_S3_BASE_URI } = getConfig()

const ContentImage = ({ data }) => {
  let { src, href } = data

  // Remove S3 base URL
  if (REACT_APP_CMS_S3_BASE_URI && src && src.indexOf(REACT_APP_CMS_S3_BASE_URI) === 0) {
    src = src.substr(REACT_APP_CMS_S3_BASE_URI.length)
  }
  if (REACT_APP_CMS_S3_BASE_URI && href && href.indexOf(REACT_APP_CMS_S3_BASE_URI) === 0) {
    href = href.substr(REACT_APP_CMS_S3_BASE_URI.length)
  }

  const style = {}
  if (data.maxWidth) style.maxWidth = data.maxWidth + 'px'
  const image = <img className={styles.ContentImage} src={src} alt={data.title} title={data.title} style={style} />
  if (href) {
    return <ExternalLink href={href}>{image}</ExternalLink>
  }
  return image
}

ContentImage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ContentImage
