import { getCurrentSession, getCurrentUser, getCurrentDevice, signout } from '../api/auth/auth'

export async function getToken () {
  const response = getCurrentUser()
    .then(user => user.signInUserSession.accessToken.jwtToken)
    .catch(() => 'invalid')

  return response
}

export async function checkSession () {
  const response = await getCurrentSession()
    .then(async () => {
      return getCurrentUser()
        .then(async user => {
          return getCurrentDevice({ username: user.attributes.email })
            .then(async device => {
              if (device === 'AUTHORIZE_LOGIN') {
                return signout()
                  .then(() => 'invalid')
                  .catch(() => 'invalid')
              }
              return 'valid'
            })
            .catch(() => 'invalid')
        })
        .catch(() => 'invalid')
    })
    .catch(() => 'invalid')

  return response
}
