import api from '../../api'

export async function getHistoryRisk (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: `/compliance/risk-score-stats/${params.symbol && params.symbol}`,
    params
  })
  return response.data
}

export async function getNewlyPromoted (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: '/compliance/newly-promoted-securities',
    params
  })

  return response.data
}

export async function getCurrentRiskStats (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: '/compliance/risk-items',
    params
  })
  return response.data
}
