import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './FilterPick.module.scss'
import Loading from '../Loading'
import classNames from 'classnames'
import { FaChevronDown } from 'react-icons/fa'

export class FilterPick extends Component {
  state = {
    isOpen: this.props.isOpen
  }

  handleSelect = option => () => {
    this.props.onChange(option)
  }

  handleHeaderClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  get header () {
    const { header } = this.props

    return (
      <div className={classNames(styles.header, {
        [styles.isOpen]: this.state.isOpen
      })} onClick={this.handleHeaderClick}>
        {header}
        <div className={styles.headerIcon}>
          <FaChevronDown />
        </div>
      </div>
    )
  }

  get content () {
    const { value, isLoading, options } = this.props
    const { isOpen } = this.state

    if (isLoading) {
      return <Loading green />
    }

    return (isOpen
      ? <div>
      {options.map(option => (
        <div
          key={option.id}
          onClick={this.handleSelect(option)}
          className={classNames(styles.option, {
            [styles.selectedOption]: value && option.id === value.id
          })}>
          {option.label}
        </div>
      ))}
    </div>
      : null)
  }

  render () {
    const { isOpen } = this.state

    return (
      <div
        className={classNames(styles.component, {
          [styles.isOpen]: isOpen
        })}>
        {this.header}
        {this.content}
      </div>
    )
  }
}

FilterPick.propTypes = {
  /** Selected value (format: { id: 'option-id', label: 'Option Label' }) */
  value: PropTypes.object,
  /** Component title */
  header: PropTypes.string,
  /** OnChange event */
  onChange: PropTypes.func.isRequired,
  /** Display loading spinner? */
  isLoading: PropTypes.bool.isRequired,
  /** An array of options, each option has the same format as the value property */
  options: PropTypes.array,
  /** Leave the list opened initially? */
  isOpen: PropTypes.bool
}

export default FilterPick
