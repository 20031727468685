import React from 'react'
import PropTypes from 'prop-types'
import styles from './StockScreener.module.scss'
import ExternalLink from '../ExternalLink'
import { FaChartLine } from 'react-icons/fa'
import getConfig from '../../config'
const { REACT_APP_STOCK_SCREENER_URL } = getConfig()

const StockScreener = props =>
  <ExternalLink href={REACT_APP_STOCK_SCREENER_URL} ga={props.ga} className={styles.stockScreener}>
    <FaChartLine />&nbsp;&nbsp;&nbsp;Stock Screener
  </ExternalLink>

StockScreener.propTypes = {
  ga: PropTypes.object
}

export default StockScreener
