import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function withRouter (Component) {
  function ComponentWithRouterProp (props) {
    const match = {}
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    match.params = params

    return (
      <Component
        {...props}
        match={match}
        location={location}
        params={params}
        navigate={navigate}
      />
    )
  }

  return ComponentWithRouterProp
}

export default withRouter
