import React from 'react'
import styles from './ContactPage.module.scss'
import { Flex, Box } from 'grid-styled'
import { contact } from '../../api/contact'
import Input from '../../components/Input'
import HeadTitle from '../../components/HeadTitle'
import TextArea from '../../components/TextArea'
import Select from '../../components/Select'
import Modal from '../../components/Modal'
import { Button } from '@otcmarketsgroup/otcm-ui'
import ExternalLink from '../../components/ExternalLink'

const topicOptions = [
  {
    title: 'OTC Risk Scoring',
    value: 'OTC Risk Scoring'
  },
  {
    title: 'Small Cap Risk Scoring',
    value: 'Small Cap Risk Scoring'
  },
  {
    title: 'Blue Sky Data',
    value: 'Blue Sky Data'
  },
  {
    title: 'Other',
    value: 'Other'
  }
]

const formFields = [
  { title: 'First Name', name: 'firstName', required: true, type: 'input' },
  { title: 'Last Name', name: 'lastName', required: true, type: 'input' },
  { title: 'Email', name: 'email', required: true, type: 'input', inputType: 'email' },
  { title: 'Phone Number', name: 'phone', required: true, type: 'input', inputType: 'tel' },
  { title: 'Topic', name: 'topic', required: true, type: 'select', options: topicOptions },
  { title: 'Message', required: false, name: 'message', type: 'textarea' }
]

class ContactPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
      },
      errors: {},
      displaySuccess: false,
      displayError: false
    }
  }

  onValidate = (field) => {
    let value = this.state.form[field.name]
    if (typeof value === 'string') value = value.trim()
    // display required field errors
    if (field.required && (!value || value === '')) {
      return 'Required field.'
    }

    switch (field.name) {
      case 'firstName':
      case 'lastName':
        // names cannot contain spaces
        if (value.indexOf(' ') !== -1) {
          return 'Names should not contain spaces.'
        }
        break

      case 'email':
        // validate email address
        const atIndex = value.indexOf('@')
        if (atIndex <= 0 || atIndex >= value.length - 1) {
          return 'Please enter a valid email address.'
        }
        break
    }
  }

  onFieldChange = (field) => {
    return (value) => {
      const obj = Object.assign({}, this.state.form)
      obj[field] = value
      this.setState({ form: obj })
    }
  }

  handleSubmit = (event) => {
    let formValid = true

    event.preventDefault()

    for (const field of formFields) {
      const validationResult = this.onValidate(field)
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          [field.name]: validationResult
        }
      }))

      if (validationResult) {
        formValid = false
      }
    }

    if (formValid) {
      contact('contact', this.state.form)
        .then(() => {
          this.setState({ displaySuccess: true })
        })
        .catch(() => {
          this.setState({ displayError: true })
        })
    }

    this.forceUpdate()
  }

  render () {
    return <div className={styles.contact}>
      <HeadTitle title='Contact OTC Markets To Get Current Market Activities' />
      <Flex wrap justify='space-between'>
        <Box w={[1, 1, 5 / 12]} className={styles.form}>
          {!this.state.displaySuccess && <div>
            <div className={styles.greeting}>
              <h3 className={styles.formTitle}>Contact us</h3>
              <p className={styles.formDesc}>
                For questions regarding Canari, risk scoring or Blue Sky data, please fill out the form below or contact us directly.
                <p>
                  Phone: +1 212.220.2166
                </p>
                <p>
                  Email: <ExternalLink href='mailto:marketdata@otcmarkets.com'>marketdata@otcmarkets.com</ExternalLink>
                </p>
              </p>
            </div>
            <form onSubmit={this.handleSubmit}>
              {formFields.map(field => {
                switch (field.type) {
                  case 'input':
                    return <Input title={field.title}
                      required={field.required}
                      onChange={this.onFieldChange(field.name)}
                      key={field.title}
                      error={this.state.errors[field.name]}
                      type={field.inputType}
                    />
                  case 'select':
                    return <Select title={field.title}
                      required={field.required}
                      onSelect={this.onFieldChange(field.name)}
                      error={this.state.errors[field.name]}
                      options={field.options}
                      key={field.title}
                    />
                  case 'textarea':
                    return <TextArea title={field.title}
                      required={field.required}
                      onChange={this.onFieldChange(field.name)}
                      key={field.title}
                      error={this.state.errors[field.name]}
                      type={field.inputType}
                    />
                }
              })}
              <Button title='Submit' className={styles.submit} fullWidth type='submit' />
            </form>
          </div>}
          {this.state.displaySuccess && <div className={styles.success}>
            <h3>Thank You!</h3>
            <p>Your message was successfully sent</p>
          </div>}
        </Box>
      </Flex>
      <Modal closeButton isOpen={this.state.displayError} width={320}>
        <div className={styles.modalBody}>
          <h3>Unable to send your message</h3>
          <p>Please try again.</p>
        </div>
      </Modal>
    </div>
  }
}

export default ContactPage
