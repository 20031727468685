import React from 'react'
import { getDNSTierNews } from '../../api/otc/company/news'
import { getSECFilingsByTier } from '../../api/otc/company/filings'
import { getFinancialReportsByTier } from '../../api/otc/company/financials'
import { getDNSNewsUrl } from '../../utils/news-urls'
import { template, fetch, expand, sort } from '../../api/helper'
import api from '../../api/api'
import styles from './MANewsPage.module.scss'

import HeadTitle from '../../components/HeadTitle'
import Flexgrid from '../../components/Flexgrid'
import Outline from '../../components/Outline'
import Table from '../../components/Table'
import More from '../../components/More'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import Sticky from '../../components/Sticky'
import JumpLinkNav from '../../components/JumpLinkNav'

const hostname = window.location.origin

const jumpLinks = [
  {
    label: 'News Releases',
    hash: 'news-releases'
  }, {
    label: 'Financial Reports',
    hash: 'financial-reports'
  }, {
    label: 'SEC Filings',
    hash: 'sec-filings'
  }
]

/** Headers for Table */
const columns = {
  news: [
    {
      name: 'releaseDate',
      header: 'Release Date',
      type: 'date',
      sortingEnabled: true
    },
    {
      name: 'symbol',
      header: 'Symbol',
      type: 'symbol'
    },
    {
      name: 'title',
      header: 'Title',
      linkTo: (item) => getDNSNewsUrl(item.symbol, item)
    }
  ],
  secFilings: [
    {
      name: 'receivedDate',
      header: 'Received Date',
      type: 'date',
      sortingEnabled: true,
      color: (item) => item.valid === false && '#bbb'
    },
    {
      name: 'symbol',
      header: 'Symbol',
      type: 'symbol',
      color: (item) => item.valid === false && '#bbb'
    },
    {
      name: 'companyName',
      header: 'Company Name',
      hideOnMobile: true,
      color: (item) => item.valid === false && '#bbb'
    },
    {
      name: 'formType',
      header: 'FormType',
      target: 'blank',
      linkTo: (item) => `${hostname}/otc-content/filing/html?id=${item.id}&guid=${api.getGUID(item.guid)}`, // set back to item.guid once OTCM-429 is ready
      color: (item) => item.valid === false && '#bbb'
    },
    {
      name: 'periodEndDate',
      header: 'Period End Date',
      type: 'date',
      hideOnTablet: true,
      hideOnMobile: true,
      color: (item) => item.valid === false && '#bbb'
    }
  ],
  financialReports: [
    {
      name: 'releaseDate',
      header: 'Release Date',
      type: 'date',
      sortingEnabled: true
    },
    {
      name: 'symbol',
      header: 'Symbol',
      type: 'symbol'
    },
    {
      name: 'reportType',
      header: 'Report Type',
      hideOnMobile: true
    },
    {
      name: 'title',
      header: 'Title',
      linkTo: (item) => {
        return api.link({
          otcAPI: true,
          url: `/backend-content/company/financial-report/${item.id}/content`
        })
      }
    },
    {
      name: 'periodDate',
      header: 'Period End Date',
      type: 'date',
      sortingEnabled: true,
      hideOnTablet: true,
      hideOnMobile: true
    }
  ]
}

const tierList = [
  { title: 'All Markets', value: 'ALL' },
  { title: 'OTCQX', value: 'QX' },
  { title: 'OTCQB', value: 'DQ' },
  { title: 'Pink', value: 'PS' }
]

class MANewsPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      news: template('records'),
      secFilings: template('records'),
      financialReports: template('records'),
      tierSelection: {
        news: 'ALL',
        secFilings: 'ALL',
        financialReports: 'ALL'
      }
    }
  }

  /**
   * Get table data
   */
  componentDidMount () {
    this.loadList('news')()
    this.loadList('secFilings')()
    this.loadList('financialReports')()

    // If anchor is supplied in hash, scroll into view
    const hash = window.location.hash
    if (hash && hash.length > 1) {
      const el = document.getElementById(hash.substr(1))
      if (el) {
        const elOffSet = el.offsetTop
        window.scrollTo({ top: elOffSet - 100 })
      }
    }
  }

  /**
   * Load the data
   */
  loadList = (tableName) => {
    return () => {
      let func = null

      switch (tableName) {
        case 'news':
          func = getDNSTierNews
          break

        case 'secFilings':
          func = getSECFilingsByTier
          break

        case 'financialReports':
          func = getFinancialReportsByTier
          break
      }

      fetch(this, func, tableName, {
        [tableName === 'news' ? 'tierGroups' : 'tierGroup']: this.state.tierSelection[tableName],
        page: 1,
        pageSize: 10
      })
    }
  }

  onTierSelect = (tableName) => {
    return (tierName) => {
      this.setState(state => ({
        tierSelection: {
          ...state.tierSelection,
          [tableName]: tierName
        }
      }), () => {
        this.loadList(tableName)()
      })
    }
  }

  render () {
    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['info', 'news', 'financial-reports',
            'sec-filings']
        }
      ],
      md: [
        {
          type: 'flex',
          column: true,
          subtree: ['info', 'news', 'financial-reports',
            'sec-filings', 'footer-note']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['info', 'links']
            },
            {
              type: 'main',
              subtree: ['news', 'financial-reports', 'sec-filings', 'footer-note']
            }
          ]
        }
      ]
    }

    return (
      <div className={styles.MANewsPage}>
        <HeadTitle title='Get OTC Disclosure And News Services' />
        <PageTitle>Company News &amp; Financial Reports</PageTitle>
        <Flexgrid layouts={layouts}>
          <div key='info' className={styles.info}>
            <p>
              Access new releases and financial reports on
              companies that use the OTC Disclosure &amp;
              News Service to distribute their information.
            </p>
          </div>
          <div key='news' id='news-releases' className={styles.table}>
            <Outline mode='heading' hideFilterToggle filters={[{
              type: 'select',
              required: true,
              options: tierList,
              defaultValue: tierList[0].value,
              onSelect: this.onTierSelect('news')
            }]}>
              News Releases
            </Outline>

            <Loading
              type='table'
              loaded={this.state.news.loaded}
              reloading={this.state.news.reloading}
              error={this.state.news.error}
              data={this.state.news.data.records}>
              <Table
                columns={columns.news}
                data={this.state.news.data.records}
                sortColumn={this.state.news.sortOn}
                sortDirection={this.state.news.sortDir}
                onSorting={sort(this, getDNSTierNews, 'news')}
                showHeader
              />
              <TableFooter>
                <More onClick={expand(this, getDNSTierNews, 'news')}
                  disabled={this.state.news.expandEmpty} />
                {this.state.news.data.records && this.state.news.data.totalRecords &&
                  <DisplayResults show={this.state.news.data.records.length} total={this.state.news.data.totalRecords} text={'Releases'} />
                }
              </TableFooter>
            </Loading>
          </div>
          <div key='financial-reports' id='financial-reports' className={styles.table}>
            <Outline mode='heading' hideFilterToggle filters={[{
              type: 'select',
              required: true,
              options: tierList,
              defaultValue: tierList[0].value,
              onSelect: this.onTierSelect('financialReports')
            }]}>
              Financial Reports
            </Outline>
            <Loading
              type='table'
              loaded={this.state.financialReports.loaded}
              reloading={this.state.financialReports.reloading}
              error={this.state.financialReports.error}
              data={this.state.financialReports.data.records}>
              <Table
                columns={columns.financialReports}
                data={this.state.financialReports.data.records}
                sortColumn={this.state.financialReports.sortOn}
                sortDirection={this.state.financialReports.sortDir}
                onSorting={sort(this, getFinancialReportsByTier, 'financialReports')}
                showHeader
              />
              <TableFooter>
                <More onClick={expand(this, getFinancialReportsByTier, 'financialReports')}
                  disabled={this.state.financialReports.expandEmpty} />
                {this.state.financialReports.data.records && this.state.financialReports.data.totalRecords &&
                  <DisplayResults show={this.state.financialReports.data.records.length} total={this.state.financialReports.data.totalRecords} text={'Reports'} />
                }
              </TableFooter>
            </Loading>
          </div>
          <div key='sec-filings' id='sec-filings' className={styles.table}>
            <Outline mode='heading' hideFilterToggle filters={[{
              type: 'select',
              required: true,
              options: tierList,
              defaultValue: tierList[0].value,
              onSelect: this.onTierSelect('secFilings')
            }]}>
              SEC Filings
            </Outline>
            <Loading
              type='table'
              loaded={this.state.secFilings.loaded}
              reloading={this.state.secFilings.reloading}
              error={this.state.secFilings.error}
              data={this.state.secFilings.data.records}>
              <Table
                columns={columns.secFilings}
                data={this.state.secFilings.data.records}
                sortColumn={this.state.secFilings.sortOn}
                sortDirection={this.state.secFilings.sortDir}
                onSorting={sort(this, getSECFilingsByTier, 'secFilings')}
                showHeader
              />
              <TableFooter>
                <More onClick={expand(this, getSECFilingsByTier, 'secFilings')}
                  disabled={this.state.secFilings.expandEmpty} />
                {this.state.secFilings.data.records && this.state.secFilings.data.totalRecords &&
                  <DisplayResults show={this.state.secFilings.data.records.length} total={this.state.secFilings.data.totalRecords} text={'Filings'} />
                }
              </TableFooter>
            </Loading>
          </div>
          <Sticky key='links'>
            <JumpLinkNav links={jumpLinks} />
          </Sticky>
        </Flexgrid>
      </div>
    )
  }
}

export default MANewsPage
