export function request (params, pagination) {
  switch (params.duration) {
    case 'annual':
      switch (params.symbol) {
        case 'GOOG':
          return []

        case 'OTCM':
          return [
            {
              periodEndDate: 'Dec 31, 2016',
              cashAndCashEquivalents: '25,034',
              shortTermInvestments: '-',
              netReceivables: '6,262',
              inventory: '-',
              otherCurrentAssets: '1,681',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '2,279',
              goodwill: '251',
              intangibleAssets: '40',
              accumulatedAmortization: '-',
              otherAssets: '210',
              deferredLongTermAssetCharges: '886',
              totalAssets: '36,643',
              accountsPayable: '6,286',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '14,664',
              deferredLongTermLiabilityCharges: '187',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '21,137',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '117',
              retainedEarnings: '6,385',
              treasuryStock: '(3,984)',
              capitalSurplus: '12,988',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '15,215'
            },
            {
              periodEndDate: 'Dec 31, 2015',
              cashAndCashEquivalents: '23,925',
              shortTermInvestments: '-',
              netReceivables: '6,082',
              inventory: '-',
              otherCurrentAssets: '1,542',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '3,507',
              goodwill: '251',
              intangibleAssets: '40',
              accumulatedAmortization: '-',
              otherAssets: '210',
              deferredLongTermAssetCharges: '657',
              totalAssets: '36,214',
              accountsPayable: '5,791',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '12,737',
              deferredLongTermLiabilityCharges: '139',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '18,667',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '115',
              retainedEarnings: '8,916',
              treasuryStock: '(2,270)',
              capitalSurplus: '10,786',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '17,256'
            },
            {
              periodEndDate: 'Dec 31, 2014',
              cashAndCashEquivalents: '20,272',
              shortTermInvestments: '-',
              netReceivables: '5,674',
              inventory: '-',
              otherCurrentAssets: '1,918',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '4,357',
              goodwill: '251',
              intangibleAssets: '40',
              accumulatedAmortization: '-',
              otherAssets: '210',
              deferredLongTermAssetCharges: '454',
              totalAssets: '33,176',
              accountsPayable: '5,013',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '9,521',
              deferredLongTermLiabilityCharges: '391',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '14,925',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '114',
              retainedEarnings: '10,752',
              treasuryStock: '(1,453)',
              capitalSurplus: '8,838',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '17,960'
            },
            {
              periodEndDate: 'Dec 31, 2013',
              cashAndCashEquivalents: '18,936',
              shortTermInvestments: '-',
              netReceivables: '4,980',
              inventory: '-',
              otherCurrentAssets: '890',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '4,184',
              goodwill: '251',
              intangibleAssets: '40',
              accumulatedAmortization: '-',
              otherAssets: '210',
              deferredLongTermAssetCharges: '487',
              totalAssets: '29,978',
              accountsPayable: '4,275',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '6,391',
              deferredLongTermLiabilityCharges: '608',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '11,274',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '111',
              retainedEarnings: '11,971',
              treasuryStock: '(621)',
              capitalSurplus: '7,243',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '18,413'
            }
          ]

        case 'VLKAY':
          return [
            {
              periodEndDate: 'Dec 31, 2014',
              cashAndCashEquivalents: '23,140,000',
              shortTermInvestments: '13,142,000',
              netReceivables: '74,975,000',
              inventory: '38,076,000',
              otherCurrentAssets: '9,309,000',
              totalCurrentAssets: '158,642,000',
              longTermInvestments: '16,992,000',
              propertyPlantAndEquipment: '55,867,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '113,844,000',
              deferredLongTermAssetCharges: '7,113,000',
              totalAssets: '424,982,000',
              accountsPayable: '23,942,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '134,218,000',
              totalCurrentLiabilities: '158,160,000',
              longTermDebt: '-',
              otherLiabilities: '115,842,000',
              deferredLongTermLiabilityCharges: '41,844,000',
              minorityInterest: '240,000',
              negativeGoodwill: '-',
              totalLiabilities: '316,085,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '1,474,000',
              retainedEarnings: '86,152,000',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '21,268,000',
              totalStockholderEquity: '-',
              netTangibleAssets: '108,897,000'
            },
            {
              periodEndDate: 'Dec 31, 2013',
              cashAndCashEquivalents: '31,938,000',
              shortTermInvestments: '11,702,000',
              netReceivables: '76,171,000',
              inventory: '39,483,000',
              otherCurrentAssets: '9,082,000',
              totalCurrentAssets: '168,376,000',
              longTermInvestments: '16,952,000',
              propertyPlantAndEquipment: '58,410,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '113,800,000',
              deferredLongTermAssetCharges: '7,747,000',
              totalAssets: '446,920,000',
              accountsPayable: '25,137,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '138,325,000',
              totalCurrentLiabilities: '163,462,000',
              longTermDebt: '-',
              otherLiabilities: '118,510,000',
              deferredLongTermLiabilityCharges: '40,882,000',
              minorityInterest: '3,175,000',
              negativeGoodwill: '-',
              totalLiabilities: '326,028,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '1,641,000',
              retainedEarnings: '99,683,000',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '19,571,000',
              totalStockholderEquity: '-',
              netTangibleAssets: '120,892,000'
            },
            {
              periodEndDate: 'Dec 31, 2012',
              cashAndCashEquivalents: '24,375,000',
              shortTermInvestments: '9,800,000',
              netReceivables: '69,340,000',
              inventory: '37,804,000',
              otherCurrentAssets: '7,742,000',
              totalCurrentAssets: '149,061,000',
              longTermInvestments: '15,309,000',
              propertyPlantAndEquipment: '51,977,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '103,460,000',
              deferredLongTermAssetCharges: '10,331,000',
              totalAssets: '408,071,000',
              accountsPayable: '23,080,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '116,045,000',
              totalCurrentLiabilities: '139,125,000',
              longTermDebt: '-',
              otherLiabilities: '117,349,000',
              deferredLongTermLiabilityCharges: '43,493,000',
              minorityInterest: '5,686,000',
              negativeGoodwill: '-',
              totalLiabilities: '305,653,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '1,570,000',
              retainedEarnings: '85,164,000',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '15,682,000',
              totalStockholderEquity: '-',
              netTangibleAssets: '102,418,000'
            },
            {
              periodEndDate: 'Jan 01, 2012',
              cashAndCashEquivalents: '23,745,000',
              shortTermInvestments: '7,979,000',
              netReceivables: '64,128,000',
              inventory: '35,766,000',
              otherCurrentAssets: '5,521,000',
              totalCurrentAssets: '137,138,000',
              longTermInvestments: '17,704,000',
              propertyPlantAndEquipment: '41,380,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '96,204,000',
              deferredLongTermAssetCharges: '8,143,000',
              totalAssets: '329,358,000',
              accountsPayable: '22,288,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '109,092,000',
              totalCurrentLiabilities: '131,380,000',
              longTermDebt: '-',
              otherLiabilities: '88,489,000',
              deferredLongTermLiabilityCharges: '27,016,000',
              minorityInterest: '7,551,000',
              negativeGoodwill: '-',
              totalLiabilities: '254,437,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '1,546,000',
              retainedEarnings: '63,700,000',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '9,671,000',
              totalStockholderEquity: '-',
              netTangibleAssets: '74,921,000'
            }
          ]
      }
      break

    case 'semi-annual':
      switch (params.symbol) {
        case 'GOOG':
          return []

        case 'OTCM':
          return [
            {
              periodEndDate: 'Jun 30, 2017',
              cashAndCashEquivalents: '22,595',
              shortTermInvestments: '-',
              netReceivables: '5,196',
              inventory: '-',
              otherCurrentAssets: '3,039',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '1,876',
              goodwill: '251',
              intangibleAssets: '136',
              accumulatedAmortization: '-',
              otherAssets: '192',
              deferredLongTermAssetCharges: '1,103',
              totalAssets: '34,388',
              accountsPayable: '4,394',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '12,263',
              deferredLongTermLiabilityCharges: '303',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '16,960',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '118',
              retainedEarnings: '9,343',
              treasuryStock: '(5,676)',
              capitalSurplus: '13,643',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '17,041'
            },
            {
              periodEndDate: 'Jun 30, 2016',
              cashAndCashEquivalents: '23,271',
              shortTermInvestments: '-',
              netReceivables: '5,104',
              inventory: '-',
              otherCurrentAssets: '2,310',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '2,853',
              goodwill: '251',
              intangibleAssets: '40',
              accumulatedAmortization: '-',
              otherAssets: '210',
              deferredLongTermAssetCharges: '860',
              totalAssets: '34,899',
              accountsPayable: '4,065',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '10,829',
              deferredLongTermLiabilityCharges: '303',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '15,197',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '117',
              retainedEarnings: '10,591',
              treasuryStock: '(3,072)',
              capitalSurplus: '12,066',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '19,411'
            },
            {
              periodEndDate: 'Jun 30, 2015',
              cashAndCashEquivalents: '23,964',
              shortTermInvestments: '-',
              netReceivables: '5,681',
              inventory: '-',
              otherCurrentAssets: '2,404',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '4,168',
              goodwill: '251',
              intangibleAssets: '40',
              accumulatedAmortization: '-',
              otherAssets: '210',
              deferredLongTermAssetCharges: '649',
              totalAssets: '37,367',
              accountsPayable: '4,521',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '11,323',
              deferredLongTermLiabilityCharges: '272',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '16,116',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '115',
              retainedEarnings: '12,756',
              treasuryStock: '(1,620)',
              capitalSurplus: '10,000',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '20,960'
            },
            {
              periodEndDate: 'Jun 30, 2014',
              cashAndCashEquivalents: '17,518',
              shortTermInvestments: '-',
              netReceivables: '5,260',
              inventory: '-',
              otherCurrentAssets: '1,922',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '4,441',
              goodwill: '251',
              intangibleAssets: '40',
              accumulatedAmortization: '-',
              otherAssets: '210',
              deferredLongTermAssetCharges: '688',
              totalAssets: '30,330',
              accountsPayable: '3,696',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '4,926',
              deferredLongTermLiabilityCharges: '508',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '9,130',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '113',
              retainedEarnings: '13,931',
              treasuryStock: '(704)',
              capitalSurplus: '7,860',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '20,909'
            }
          ]

        case 'VLKAY':
          return [
            {
              periodEndDate: 'Jun 30, 2015',
              cashAndCashEquivalents: '19,608,000',
              shortTermInvestments: '16,819,000',
              netReceivables: '153,140,000',
              inventory: '39,289,000',
              otherCurrentAssets: '-',
              totalCurrentAssets: '228,856,000',
              longTermInvestments: '33,486,000',
              propertyPlantAndEquipment: '52,882,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '34,254,000',
              deferredLongTermAssetCharges: '-',
              totalAssets: '416,735,000',
              accountsPayable: '22,853,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '133,162,000',
              totalCurrentLiabilities: '156,016,000',
              longTermDebt: '-',
              otherLiabilities: '153,574,000',
              deferredLongTermLiabilityCharges: '-',
              minorityInterest: '231,000',
              negativeGoodwill: '-',
              totalLiabilities: '309,821,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '-',
              retainedEarnings: '-',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '106,914,000',
              totalStockholderEquity: '-',
              netTangibleAssets: '106,914,000'
            },
            {
              periodEndDate: 'Jun 30, 2014',
              cashAndCashEquivalents: '27,386,000',
              shortTermInvestments: '11,716,000',
              netReceivables: '93,618,000',
              inventory: '43,306,000',
              otherCurrentAssets: '-',
              totalCurrentAssets: '176,026,000',
              longTermInvestments: '36,949,000',
              propertyPlantAndEquipment: '58,133,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '107,271,000',
              deferredLongTermAssetCharges: '-',
              totalAssets: '460,203,000',
              accountsPayable: '26,259,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '141,222,000',
              totalCurrentLiabilities: '167,481,000',
              longTermDebt: '-',
              otherLiabilities: '169,892,000',
              deferredLongTermLiabilityCharges: '-',
              minorityInterest: '260,000',
              negativeGoodwill: '-',
              totalLiabilities: '337,634,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '-',
              retainedEarnings: '-',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '122,570,000',
              totalStockholderEquity: '-',
              netTangibleAssets: '122,569,000'
            },
            {
              periodEndDate: 'Jun 30, 2013',
              cashAndCashEquivalents: '28,127,000',
              shortTermInvestments: '9,479,000',
              netReceivables: '81,916,000',
              inventory: '39,077,000',
              otherCurrentAssets: '-',
              totalCurrentAssets: '158,600,000',
              longTermInvestments: '35,119,000',
              propertyPlantAndEquipment: '51,561,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '93,067,000',
              deferredLongTermAssetCharges: '-',
              totalAssets: '414,773,000',
              accountsPayable: '23,716,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '129,746,000',
              totalCurrentLiabilities: '153,461,000',
              longTermDebt: '-',
              otherLiabilities: '152,653,000',
              deferredLongTermLiabilityCharges: '-',
              minorityInterest: '2,867,000',
              negativeGoodwill: '-',
              totalLiabilities: '308,982,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '-',
              retainedEarnings: '-',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '105,791,000'
            },
            {
              periodEndDate: 'Jun 30, 2012',
              cashAndCashEquivalents: '25,604,000',
              shortTermInvestments: '8,631,000',
              netReceivables: '74,802,000',
              inventory: '38,016,000',
              otherCurrentAssets: '-',
              totalCurrentAssets: '147,053,000',
              longTermInvestments: '50,450,000',
              propertyPlantAndEquipment: '41,499,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '81,860,000',
              deferredLongTermAssetCharges: '-',
              totalAssets: '348,937,000',
              accountsPayable: '22,018,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '111,586,000',
              totalCurrentLiabilities: '133,604,000',
              longTermDebt: '-',
              otherLiabilities: '130,900,000',
              deferredLongTermLiabilityCharges: '-',
              minorityInterest: '5,378,000',
              negativeGoodwill: '-',
              totalLiabilities: '269,883,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '-',
              retainedEarnings: '-',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '79,054,000'
            }
          ]
      }
      break

    case 'quarterly':
      switch (params.symbol) {
        case 'GOOG':
          return []

        case 'OTCM':
          return [
            {
              periodEndDate: 'Jun 30, 2017',
              cashAndCashEquivalents: '22,595',
              shortTermInvestments: '-',
              netReceivables: '5,196',
              inventory: '-',
              otherCurrentAssets: '3,039',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '1,876',
              goodwill: '251',
              intangibleAssets: '136',
              accumulatedAmortization: '-',
              otherAssets: '192',
              deferredLongTermAssetCharges: '1,103',
              totalAssets: '34,388',
              accountsPayable: '4,394',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '12,263',
              deferredLongTermLiabilityCharges: '303',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '16,960',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '118',
              retainedEarnings: '9,343',
              treasuryStock: '(5,676)',
              capitalSurplus: '13,643',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '17,041'
            },
            {
              periodEndDate: 'Mar 31, 2017',
              cashAndCashEquivalents: '23,501',
              shortTermInvestments: '-',
              netReceivables: '5,853',
              inventory: '-',
              otherCurrentAssets: '2,326',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '2,010',
              goodwill: '251',
              intangibleAssets: '40',
              accumulatedAmortization: '-',
              otherAssets: '192',
              deferredLongTermAssetCharges: '997',
              totalAssets: '35,170',
              accountsPayable: '4,541',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '14,572',
              deferredLongTermLiabilityCharges: '129',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '19,242',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '118',
              retainedEarnings: '7,836',
              treasuryStock: '(5,392)',
              capitalSurplus: '13,366',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '15,637'
            },
            {
              periodEndDate: 'Dec 31, 2016',
              cashAndCashEquivalents: '25,034',
              shortTermInvestments: '-',
              netReceivables: '6,262',
              inventory: '-',
              otherCurrentAssets: '1,681',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '2,279',
              goodwill: '251',
              intangibleAssets: '40',
              accumulatedAmortization: '-',
              otherAssets: '210',
              deferredLongTermAssetCharges: '886',
              totalAssets: '36,643',
              accountsPayable: '6,286',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '14,664',
              deferredLongTermLiabilityCharges: '187',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '21,137',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '117',
              retainedEarnings: '6,385',
              treasuryStock: '(3,984)',
              capitalSurplus: '12,988',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '15,215'
            },
            {
              periodEndDate: 'Sep 30, 2016',
              cashAndCashEquivalents: '25,114',
              shortTermInvestments: '-',
              netReceivables: '4,930',
              inventory: '-',
              otherCurrentAssets: '1,989',
              totalCurrentAssets: '-',
              longTermInvestments: '-',
              propertyPlantAndEquipment: '2,490',
              goodwill: '251',
              intangibleAssets: '40',
              accumulatedAmortization: '-',
              otherAssets: '210',
              deferredLongTermAssetCharges: '953',
              totalAssets: '35,977',
              accountsPayable: '5,903',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '-',
              totalCurrentLiabilities: '-',
              longTermDebt: '-',
              otherLiabilities: '8,986',
              deferredLongTermLiabilityCharges: '245',
              minorityInterest: '-',
              negativeGoodwill: '-',
              totalLiabilities: '15,134',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '117',
              retainedEarnings: '12,019',
              treasuryStock: '(3,888)',
              capitalSurplus: '12,595',
              otherStockholderEquity: '-',
              totalStockholderEquity: '-',
              netTangibleAssets: '20,552'
            }
          ]

        case 'VLKAY':
          return [
            {
              periodEndDate: 'Sep 30, 2015',
              cashAndCashEquivalents: '25,653,000',
              shortTermInvestments: '16,350,000',
              netReceivables: '81,297,000',
              inventory: '38,287,000',
              otherCurrentAssets: '-',
              totalCurrentAssets: '161,586,000',
              longTermInvestments: '29,761,000',
              propertyPlantAndEquipment: '52,716,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '103,411,000',
              deferredLongTermAssetCharges: '-',
              totalAssets: '414,589,000',
              accountsPayable: '22,224,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '136,574,000',
              totalCurrentLiabilities: '158,798,000',
              longTermDebt: '-',
              otherLiabilities: '120,212,000',
              deferredLongTermLiabilityCharges: '31,084,000',
              minorityInterest: '231,000',
              negativeGoodwill: '-',
              totalLiabilities: '310,325,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '-',
              retainedEarnings: '-',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '104,264,000',
              totalStockholderEquity: '-',
              netTangibleAssets: '104,264,000'
            },
            {
              periodEndDate: 'Jun 30, 2015',
              cashAndCashEquivalents: '19,608,000',
              shortTermInvestments: '16,819,000',
              netReceivables: '153,140,000',
              inventory: '39,289,000',
              otherCurrentAssets: '-',
              totalCurrentAssets: '228,856,000',
              longTermInvestments: '33,486,000',
              propertyPlantAndEquipment: '52,882,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '34,254,000',
              deferredLongTermAssetCharges: '-',
              totalAssets: '416,735,000',
              accountsPayable: '22,853,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '133,162,000',
              totalCurrentLiabilities: '156,016,000',
              longTermDebt: '-',
              otherLiabilities: '153,574,000',
              deferredLongTermLiabilityCharges: '-',
              minorityInterest: '231,000',
              negativeGoodwill: '-',
              totalLiabilities: '309,821,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '-',
              retainedEarnings: '-',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '106,914,000',
              totalStockholderEquity: '-',
              netTangibleAssets: '106,914,000'
            },
            {
              periodEndDate: 'Mar 31, 2015',
              cashAndCashEquivalents: '19,491,000',
              shortTermInvestments: '15,921,000',
              netReceivables: '80,412,000',
              inventory: '37,596,000',
              otherCurrentAssets: '-',
              totalCurrentAssets: '153,420,000',
              longTermInvestments: '37,106,000',
              propertyPlantAndEquipment: '50,681,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '97,690,000',
              deferredLongTermAssetCharges: '-',
              totalAssets: '403,644,000',
              accountsPayable: '22,449,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '129,952,000',
              totalCurrentLiabilities: '152,401,000',
              longTermDebt: '-',
              otherLiabilities: '155,368,000',
              deferredLongTermLiabilityCharges: '-',
              minorityInterest: '215,000',
              negativeGoodwill: '-',
              totalLiabilities: '307,984,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '-',
              retainedEarnings: '-',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '95,659,000',
              totalStockholderEquity: '-',
              netTangibleAssets: '95,660,000'
            },
            {
              periodEndDate: 'Dec 31, 2014',
              cashAndCashEquivalents: '23,140,000',
              shortTermInvestments: '13,142,000',
              netReceivables: '74,975,000',
              inventory: '38,076,000',
              otherCurrentAssets: '9,309,000',
              totalCurrentAssets: '158,642,000',
              longTermInvestments: '16,992,000',
              propertyPlantAndEquipment: '55,867,000',
              goodwill: '-',
              intangibleAssets: '-',
              accumulatedAmortization: '-',
              otherAssets: '113,844,000',
              deferredLongTermAssetCharges: '7,113,000',
              totalAssets: '424,982,000',
              accountsPayable: '23,942,000',
              shortTermAndCurrentLongTermDebt: '-',
              otherCurrentLiabilities: '134,218,000',
              totalCurrentLiabilities: '158,160,000',
              longTermDebt: '-',
              otherLiabilities: '115,842,000',
              deferredLongTermLiabilityCharges: '41,844,000',
              minorityInterest: '240,000',
              negativeGoodwill: '-',
              totalLiabilities: '316,085,000',
              miscellaneousStocksOptionsWarrants: '-',
              redeemablePreferredStock: '-',
              preferredStock: '-',
              commonStock: '1,474,000',
              retainedEarnings: '86,152,000',
              treasuryStock: '-',
              capitalSurplus: '-',
              otherStockholderEquity: '21,268,000',
              totalStockholderEquity: '-',
              netTangibleAssets: '108,897,000'
            }
          ]
      }
      break
  }
}
