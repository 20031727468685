import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { withRouter } from '../../components/WithRouter'
import { logEvent } from '../../analytics'
import { LINKS, HERO_ACTION, SPLASH_PAGE_ACTION } from '../../constants/GoogleAnalytics'
import HeadTitle from '../../components/HeadTitle'
import Loading from '../../components/Loading'
import SymbolLink from '../../components/SymbolLink'
import { Button } from '@otcmarketsgroup/otcm-ui'
import {
  getRiskScoring
} from '../../api/otc/market-activity/compliance-analytics'
import { template, fetch } from '../../api/helper'
import { withContext } from '../../AuthContext'
import styles from './PreLoginPage.module.scss'

const riskCategories = [
    'Caveat Emptor', 'Marketplace', 'Security Status', 'Shell', 'Penny Stock', 'Bankruptcy', 'Delinquency', 'Reverse Splits', 'Name Changes', 'Market Cap', 'Price', 'Price Change', 'Volume Change', 'Trade Count Change', 'Shares Out Change', 'Authorized Shares Change', 'Promotion', 'Shell Risk', 'Hot Sector', 'Control Dispute', 'Prohibited Service Provider', 'Foreign Issuer - No Foreign Listing', 'Market Cap Change'
]

const ScoreRow = ({ item }) => {
  const sign = parseFloat(item.currentRiskScore) > 0 ? '+' : ''
  return (
    <div className={styles.scoreRowContainer}>
      <SymbolLink data={item} large />
      <div className={styles.score}>{item.currentRiskScore ? `${sign}${parseInt(item.currentRiskScore)}` : '-'}</div>
    </div>
  )
}

ScoreRow.propTypes = {
  item: PropTypes.object
}

const PreLoginPage = ({ context }) => {
  const [riskScoreChange, setRiskScoreChange] = useState(template('records'))
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    if (context.isAuth === true) goToDashboard()
    else {
      const params = {
        page: 1,
        pageSize: 5,
        ignoreAuth: true
      }

      setIsReady(true)

      getRiskScoring(params)
        .then(data => {
          const updateData = { ...riskScoreChange }
          updateData.data = data
          updateData.loaded = true
          setRiskScoreChange(updateData)
        })
        .catch(err => {
          const updateData = { ...riskScoreChange }
          console.log('err', err.message)
          updateData.error = err.message || 'Failed'
          setRiskScoreChange(updateData)
        })
    }
  }, [context.isAuth])

  const gaEvent = (action, label) => {
    logEvent(LINKS, action, label)
  }

  const goToDashboard = () => {
    window.location.replace('/dashboard')
  }

  const hero1Style = {
    backgroundImage: 'url(\'/images/hero1.jpg\')',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }

  const hero2Style = {
    backgroundImage: 'url(\'/images/hero2.jpg\')',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }

  return (
      <>
      {isReady && <div className={styles.container}>
        <HeadTitle title='OTC Markets Compliance UI' />
        <div className={styles.section} style={hero1Style}>
          <div className={styles.hero}>
            <div>
              <h1>Critical Data Points for Assessing Risk</h1>
            </div>
            <div>
              <Link to='/contact' onClick={() => gaEvent(HERO_ACTION, '/contact')}>
                <Button id={'hero1-cta'} className={styles.ctaButton} title='Find out more' type='button' />
              </Link>
              <Link to='/login' className={styles.textCta} onClick={() => gaEvent(HERO_ACTION, '/login')}>
                <span>or Login, if already a member</span>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.section2}>
          <h3 className={styles.desc}>The OTC Compliance Analytics product provides broker-dealers and investment managers with a comprehensive, quantitative tool for evaluating and automating risk processes for OTCQX, OTCQB <br />and Pink Securities.</h3>
        </div>
        <div className={styles.leftCard}>
          <div className={styles.card}>
            <h3>Comprehensive, detailed data</h3>
            <p>Provides 110 data points essential for compliance for OTCQX, OTCQB, Pink and Grey Market securities.</p>
          </div>
          <div />
        </div>
        <div className={styles.rightCard}>
          <div />
          <div className={styles.card}>
            <h3>Advanced Analytics</h3>
            <p>Leverages a multidimensional scoring system to provide a risk score for all OTC equity securities.</p>
          </div>
        </div>
        <div className={styles.sectionRiskScore}>
          <div>
            <h3 className={styles.desc}>Snapshot of the Top 5 Risk Score Value Changes</h3>
          </div>
          <div>
            <h2 className={styles.title}>Risk Score Change</h2>
            <div className={styles.riskScoreRow}>
              <Loading
                type='table'
                data={riskScoreChange.data.records}
                error={riskScoreChange.error}
                loaded={riskScoreChange.loaded}>
                {riskScoreChange.data.records.map((item, i) => <ScoreRow key={i} item={item} />)}
              </Loading>
            </div>
          </div>
        </div>
        <div className={styles.section} style={hero2Style}>
          <div className={classnames(styles.hero, styles.hero2)}>
            <h1>All brought together into a seamless application</h1>
          </div>
        </div>
        <div className={styles.sectionRiskCategory}>
          <h1>
            Quantitative Values in 23 Risk Categories
          </h1>
          <h3 className={styles.desc}>
            The Compliance Analytics product offers quantitative values in 23 risk categories as well as an aggregate risk value for all OTC equity securities.
          </h3>
          <div className={styles.categoryList}>
            {riskCategories.map(type => <div key={type} className={styles.categoryLabel}>{type}</div>)}
          </div>
        </div>
        <div className={styles.sectionInterested}>
          <h1>Interested in our product?</h1>
          <Link to='/contact' onClick={() => gaEvent(SPLASH_PAGE_ACTION, '/contact')}>
            <Button id={'hero2-cta'} className={styles.ctaButton} title='Find out more' type='button' />
          </Link>
        </div>
        </div>
        }
      </>
  )
}

PreLoginPage.propTypes = {
  context: PropTypes.shape({
    isAuth: PropTypes.bool,
    submitLogin: PropTypes.func,
    error: PropTypes.string,
    changeState: PropTypes.func
  }),
  navigate: PropTypes.func
}

export default withContext(withRouter(PreLoginPage))
