import React from 'react'
import styles from './MAComplianceStatsPage.module.scss'
import moment from 'moment-timezone'
import { template, fetch, expand, sort } from '../../api/helper'
import {
  getCaveatEmptor,
  getCompanyNameChange,
  getPromotions,
  getGracePeriod,
  getReverseSplits,
  getShellRisk,
  getShellStatus,
  getSuspensionsRevocations,
  downloadStats
} from '../../api/otc/market-activity/compliance-statistics'
import columns from './columns'

import HeadTitle from '../../components/HeadTitle'
import Flexgrid from '../../components/Flexgrid'
import Outline from '../../components/Outline'
import Table from '../../components/Table'
import Sticky from '../../components/Sticky'
import JumpLinkNav from '../../components/JumpLinkNav'
import More from '../../components/More'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'

// Mapping functions to table names
const tableFunctions = {
  promotionsCaveatEmptor: getCaveatEmptor,
  promotionsNameChanges: getCompanyNameChange,
  promotions: getPromotions,
  graceperiod: getGracePeriod,
  reverseSplits: getReverseSplits,
  shellRisk: getShellRisk,
  promotionsShell: getShellStatus,
  promotionsSuspensionRevocation: getSuspensionsRevocations
}

class MAComplianceStatsPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      promotionsCaveatEmptor: template('records'),
      promotionsNameChanges: template('records'),
      promotions: template('records'),
      graceperiod: template('records'),
      reverseSplits: template('records'),
      shellRisk: template('records'),
      promotionsShell: template('records'),
      promotionsSuspensionRevocation: template('records'),
      filters: {
        promotionsCaveatEmptor: {
          fromDate: null,
          endDate: null
        },
        promotionsNameChanges: {
          fromDate: null,
          endDate: null
        },
        promotions: {
          fromDate: null,
          endDate: null
        },
        graceperiod: {
          fromDate: null,
          endDate: null
        },
        reverseSplits: {
          fromDate: null,
          endDate: null
        },
        shellRisk: {
          fromDate: null,
          endDate: null
        },
        promotionsShell: {
          fromDate: null,
          endDate: null
        },
        promotionsSuspensionRevocation: {
          fromDate: null,
          endDate: null
        }
      }
    }

    this.dateSelect = this.dateSelect.bind(this)
  }

  /**
   * Load Initial Data
   */
  componentDidMount () {
    this.loadData()
  }

  scrollToAnchor = (anchor) => () => {
    const el = document.getElementById(anchor)
    if (el) el.scrollIntoView(true)
  }

  /**
   * Load all the necessary promo data.
   */
  loadData = () => {
    columns.map(table => {
      return fetch(this, tableFunctions[table.key], table.key, {
        page: 1,
        pageSize: 10,
        fromDate: this.state.filters[table.key].fromDate,
        endDate: this.state.filters[table.key].endDate
      })
    })
  }

  loadSection = (name) => {
    fetch(this, tableFunctions[name], name, {
      page: 1,
      pageSize: 10,
      fromDate: this.state.filters[name].fromDate,
      endDate: this.state.filters[name].endDate
    })
  }

  dateSelect = (type, field) => {
    return (date) => {
      const filters = this.state.filters

      if (type && field) {
        filters[type][field] = date && moment(date).format('MM/DD/YYYY')
      }

      this.setState({ filters }, this.loadSection(type))
    }
  }

  handleDownload = (pathname, key) => {
    downloadStats(pathname, this.state.filters[key])
  }

  render () {
    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['header', 'links', 'info', 'tables']
        }
      ],
      md: [
        {
          type: 'flex',
          column: true,
          subtree: ['header', 'links', 'info', 'tables', 'suspensions']
        }
      ],
      lg: [
        {
          type: 'flex',
          wrap: true,
          subtree: [
            {
              type: 'box',
              column: true,
              width: 1,
              wrap: true,
              subtree: [{
                type: 'flex',
                column: false,
                width: 1,
                subtree: [{
                  type: 'side',
                  column: true,
                  subtree: ['header']
                }
                ]
              }]
            },
            {
              type: 'flex',
              column: false,
              width: 1,
              subtree: [{
                type: 'side',
                column: true,
                subtree: ['info', 'links']
              },
              {
                type: 'main',
                column: true,
                subtree: ['tables']
              }]
            }
          ]
        }
      ]
    }

    const tables = columns

    return <div className={styles.MAComplianceStatsPage}>
      <HeadTitle title='Get A Quick Overview Of Key Compliance Statistics' />
      <PageTitle>Compliance Statistics</PageTitle>
      <Flexgrid layouts={layouts}>
        <div key='info' className={styles.info}>
          <p>
            OTC Markets&apos; compliance statistics provide a quick overview
            of key, current compliance data points.
          </p>
        </div>
        <Sticky key='links'>
          <JumpLinkNav links={columns} />
        </Sticky>
        <div key='tables' className={styles.tables}>
          {tables.map(table => {
            const tableData = this.state[table.key]
            return (
              <div key={`table-${table.key}`} id={`${table.hash}`}>
                <div key={table.key} className={styles.tradeDataTable}>
                  <Outline mode='heading'
                    download={() => this.handleDownload(table.hash, table.key)}
                    filters={[{
                      type: 'date',
                      placeholderText: 'From',
                      maxDate: moment(this.state.filters[table.key].endDate) || moment(),
                      onChange: this.dateSelect(table.key, 'fromDate')
                    }, {
                      type: 'date',
                      placeholderText: 'To',
                      minDate: moment(this.state.filters[table.key].fromDate),
                      maxDate: moment(),
                      onChange: this.dateSelect(table.key, 'endDate')
                    }]}>
                    {table.title}
                  </Outline>
                  <Loading
                    type='table'
                    emptyMessage='No data available'
                    loaded={tableData.loaded}
                    reloading={tableData.reloading}
                    error={tableData.error}
                    data={tableData.data}>
                    <Table
                      columns={table.columns}
                      data={tableData.data.records}
                      sortColumn={tableData.sortOn}
                      sortDirection={tableData.sortDir}
                      onSorting={sort(this, tableFunctions[table.key], table.key)}
                      showHeader
                    />
                    <TableFooter>
                      <More
                        onClick={expand(this, tableFunctions[table.key], table.key)}
                        disabled={tableData.expandEmpty} />
                      {tableData.data.records && tableData.data.totalRecords &&
                        <DisplayResults show={tableData.data.records.length} total={tableData.data.totalRecords} text={'Results'} />
                      }
                    </TableFooter>
                    {table.key === 'promotions' && <abbr>
                      This table displays the 10 most recent securities undergoing promotional activity.
                    </abbr>}
                  </Loading>
                </div>
              </div>
            )
          })}
        </div>

      </Flexgrid>
    </div>
  }
}

export default MAComplianceStatsPage
