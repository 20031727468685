import React from 'react'
import styles from '../LogIn/LogIn.module.scss'

const PasswordRequirements = props =>
  <div className={styles.greeting}>
    <p className={styles.formDesc}>Password requires:</p>
    <ul className={styles.formList}>
      <li>
        Minimum 14 characters
      </li>
      <li>
        Require numeric character
      </li>
      <li>
        Require special character
      </li>
      <li>
        Require uppercase letters
      </li>
      <li>
        Require lowercase letters
      </li>
    </ul>
  </div>

export default PasswordRequirements
