export function request(params) {
  return {
    promotions: [
      {
        latestPromoDate: '2018-01-11',
        symbol: 'BVTK',
        tierId: '20',
        tierCode: 'PC',
        promoStartDate: '2018-01-11',
        promoDuration: '1',
        startDatePrice: '0.00220',
        currentClosingPrice: '0.00220',
        volume: '87666582',
        thirtyDayAvg: '93410851',
        volumeChangePct: '-6.15',
        isCaveatEmptor: true
      },
      {
        latestPromoDate: '2018-01-11',
        symbol: 'VOIP',
        tierId: '22',
        tierCode: 'PN',
        promoStartDate: '2018-01-11',
        promoDuration: '1',
        startDatePrice: '0.00020',
        currentClosingPrice: '0.00020',
        volume: '0',
        thirtyDayAvg: '0'
      },
      {
        latestPromoDate: '2018-01-11',
        symbol: 'AWAW',
        tierId: '21',
        tierCode: 'PL',
        promoStartDate: '2017-12-27',
        promoDuration: '16',
        startDatePrice: '0.02720',
        currentClosingPrice: '0.02720',
        volume: '6766',
        thirtyDayAvg: '17350',
        volumeChangePct: '-61.00'
      },
      {
        latestPromoDate: '2018-01-11',
        symbol: 'AWSL',
        tierId: '22',
        tierCode: 'PN',
        promoStartDate: '2018-01-11',
        promoDuration: '1',
        startDatePrice: '0.01300',
        currentClosingPrice: '0.01300',
        volume: '12629',
        thirtyDayAvg: '18705',
        volumeChangePct: '-32.48'
      },
      {
        latestPromoDate: '2018-01-11',
        symbol: 'ASAE',
        tierId: '10',
        tierCode: 'QB',
        promoStartDate: '2018-01-11',
        promoDuration: '1',
        startDatePrice: '0.04170',
        currentClosingPrice: '0.04170',
        volume: '203',
        thirtyDayAvg: '3732',
        volumeChangePct: '-94.56'
      },
      {
        latestPromoDate: '2018-01-11',
        symbol: 'MATN',
        tierId: '2',
        tierCode: 'QX',
        promoStartDate: '2018-01-11',
        promoDuration: '1',
        startDatePrice: '0.21966',
        currentClosingPrice: '0.21966',
        volume: '217080',
        thirtyDayAvg: '56823',
        volumeChangePct: '282.03'
      },
      {
        latestPromoDate: '2018-01-11',
        symbol: 'MAKSY',
        tierId: '5',
        tierCode: 'QX',
        promoStartDate: '2018-01-11',
        promoDuration: '1',
        startDatePrice: '8.47000',
        currentClosingPrice: '8.47000',
        volume: '47393',
        thirtyDayAvg: '94933',
        volumeChangePct: '-50.08'
      },
      {
        latestPromoDate: '2018-01-11',
        symbol: 'QNTO',
        tierId: '1',
        tierCode: 'QX',
        promoStartDate: '2018-01-11',
        promoDuration: '1',
        startDatePrice: '13.30000',
        currentClosingPrice: '13.30000',
        volume: '0',
        thirtyDayAvg: '1602',
        volumeChangePct: '-100.00'
      },
      {
        latestPromoDate: '2018-01-11',
        symbol: 'GLBXF',
        tierId: '6',
        tierCode: 'QX',
        promoStartDate: '2018-01-11',
        promoDuration: '1',
        startDatePrice: '0.32300',
        currentClosingPrice: '0.32300',
        volume: '0',
        thirtyDayAvg: '3536',
        volumeChangePct: '-100.00'
      },
      {
        latestPromoDate: '2018-01-11',
        symbol: 'CSVI',
        tierId: '1',
        tierCode: 'QX',
        promoStartDate: '2018-01-11',
        promoDuration: '1',
        startDatePrice: '48.00000',
        currentClosingPrice: '48.00000',
        volume: '4285',
        thirtyDayAvg: '2016',
        volumeChangePct: '112.55'
      }
    ],
    graceperiod: [
      {
        startDate: 1683604800000,
        endDate: 1684814400000,
        symbol: 'DATI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.045,
        marketCap: 947064.3242,
        caveatEmptor: false
      },
      {
        startDate: 1683518400000,
        endDate: 1684728000000,
        symbol: 'AXAS',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.52,
        marketCap: 49343700.7,
        caveatEmptor: false
      },
      {
        startDate: 1683518400000,
        endDate: 1684728000000,
        symbol: 'ENDPQ',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.064,
        marketCap: 15054055.168,
        caveatEmptor: false
      },
      {
        startDate: 1683259200000,
        endDate: 1684468800000,
        symbol: 'OZMLF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 19,
        marketCap: 6317889215,
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'PTNRY',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 4.22,
        marketCap: 830225554.4,
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'AWSL',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0615,
        marketCap: 20124611.3453,
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'ARMM',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.21,
        marketCap: 4419986.34,
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'RWRDP',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.1,
        marketCap: 11434830.8,
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'MSTH',
        isCaveatEmptor: false,
        tierId: 2,
        tierCode: 'QX',
        tierName: 'OTCQX U.S.',
        currentClosingPrice: 0.15,
        marketCap: 17434840.95,
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'RENI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'GSAC',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0003,
        marketCap: 310975.9566,
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'REOS',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.011,
        marketCap: 826015.6427,
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'CSOL',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.02,
        marketCap: 7504691.04,
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'PTEL',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0003,
        marketCap: 1053156.5031,
        caveatEmptor: false
      },
      {
        startDate: 1683172800000,
        endDate: 1684382400000,
        symbol: 'UCASU',
        isCaveatEmptor: false,
        tierId: 10,
        tierCode: 'QB',
        tierName: 'OTCQB',
        currentClosingPrice: 1.19,
        marketCap: 6527179.75,
        caveatEmptor: false
      },
      {
        startDate: 1683086400000,
        endDate: 1684296000000,
        symbol: 'MLYF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0739,
        marketCap: 30224943.268,
        caveatEmptor: false
      },
      {
        startDate: 1683086400000,
        endDate: 1684296000000,
        symbol: 'ADAD',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.75,
        marketCap: 29263849.5,
        caveatEmptor: false
      },
      {
        startDate: 1683086400000,
        endDate: 1684296000000,
        symbol: 'PPJE',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0002,
        marketCap: 1060967.2116,
        caveatEmptor: false
      },
      {
        startDate: 1683086400000,
        endDate: 1684296000000,
        symbol: 'ASPU',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.07435,
        marketCap: 1871020.6305,
        caveatEmptor: false
      },
      {
        startDate: 1683086400000,
        endDate: 1684296000000,
        symbol: 'SUWN',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.00606,
        marketCap: 1209774.7862,
        caveatEmptor: false
      },
      {
        startDate: 1683086400000,
        endDate: 1684296000000,
        symbol: 'VRUS',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0001,
        marketCap: 202451.7388,
        caveatEmptor: false
      },
      {
        startDate: 1683086400000,
        endDate: 1684296000000,
        symbol: 'HBISD',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0603,
        marketCap: 60133.6323,
        caveatEmptor: false
      },
      {
        startDate: 1683086400000,
        endDate: 1684296000000,
        symbol: 'UNIR',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.21,
        marketCap: 746644.71,
        caveatEmptor: false
      },
      {
        startDate: 1683000000000,
        endDate: 1684209600000,
        symbol: 'RBMTF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.07,
        marketCap: 8022350,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'WMXCF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0609,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'JFWV',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 71,
        marketCap: 19577966,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'RBRI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 1,
        marketCap: 233208449,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'MDCN',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0001,
        marketCap: 1947248.8143,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'NXGM',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0192,
        marketCap: 629144.6208,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'ORBT',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 6.64,
        marketCap: 23420601.36,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'BRAV',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0014,
        marketCap: 2624393.3338,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'DTRK',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.32,
        marketCap: 1059920.96,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CFTN',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.085,
        marketCap: 5759224.695,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'FCGY',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.085,
        marketCap: 2027566.71,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'BINP',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.03999,
        marketCap: 2159244.4539,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'PPHI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 7,
        marketCap: 25308500,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'IENT',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.025,
        marketCap: 3334349.655,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'EGBB',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.01528,
        marketCap: 10054009.9138,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CGRA',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.017,
        marketCap: 8576989.047,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CDJM',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.063,
        marketCap: 3210834.123,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CAMG',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.051,
        marketCap: 1290045,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CNGI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.1114,
        marketCap: 3184653.7384,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CFIC',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 8.1,
        marketCap: 17755191.9,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CSTI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 5.12,
        marketCap: 8443724.8,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'AVOT',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.02,
        marketCap: 1036654.02,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'LGBS',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0001,
        marketCap: 613651,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'SIPC',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0019,
        marketCap: 652839.267,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'TVOG',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0041,
        marketCap: 1144794.45,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'WWII',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.01195,
        marketCap: 1230347.9925,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'OXIHF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0013,
        marketCap: 991351.4793,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'SEGI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0019,
        marketCap: 9126000,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'SSFT',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.01,
        marketCap: 8919130.03,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'VSST',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.008,
        marketCap: 1614301.082,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'EXLA',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0037,
        marketCap: 2730600.2812,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'GWTR',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.01274,
        marketCap: 333456.1867,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'QWTR',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0388,
        marketCap: 5117837.5252,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'UAMM',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0025,
        marketCap: 814124.8675,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CCWF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0032,
        marketCap: 4928768.433,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'SPBV',
        isCaveatEmptor: true,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0005,
        marketCap: 337062.0035,
        caveatEmptor: true
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'AGYTF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.175,
        marketCap: 111858250,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'INSO',
        isCaveatEmptor: true,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.009,
        marketCap: 984535.155,
        caveatEmptor: true
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'DSTZF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 8,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'POTTF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0057,
        marketCap: 434479.023,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'AXTG',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.00255,
        marketCap: 1302379.4834,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'AATC',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 4.02,
        marketCap: 21660768,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'WRHLF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0002,
        marketCap: 239760.9262,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'XFLS',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0011,
        marketCap: 538994.233,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'ACMB',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.006,
        marketCap: 350979.264,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'SNHO',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.065,
        marketCap: 3844836.19,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'VICA',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.001,
        marketCap: 152876.9583,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'PURA',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0012,
        marketCap: 2062469.3175,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'AZFL',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0001,
        marketCap: 1761687.2506,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'MEDH',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.001,
        marketCap: 690499.016,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'NAFS',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.00095,
        marketCap: 347994.5736,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'EQLB',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0006,
        marketCap: 2133374.4063,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'RNWF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0002,
        marketCap: 525012.2628,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'ALYI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0007,
        marketCap: 2140217.6264,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'VAYK',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0005,
        marketCap: 431441.898,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CSLI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0015,
        marketCap: 724458.6272,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'AUSAF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.019,
        marketCap: 6647741.565,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'LNLHF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.015,
        marketCap: 3478272.765,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'SGBG',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.85,
        marketCap: 23862220,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'EXCH',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 43.3,
        marketCap: 26313886.3,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'IONKF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0015,
        marketCap: 525053.2365,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CPWY',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.002,
        marketCap: 374318.324,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'TRBK',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 16.965,
        marketCap: 47299336.11,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'MACE',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.11,
        marketCap: 7159733.02,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'SPRN',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.00612,
        marketCap: 763137.1342,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'NIDB',
        isCaveatEmptor: false,
        tierId: 10,
        tierCode: 'QB',
        tierName: 'OTCQB',
        currentClosingPrice: 40.35,
        marketCap: 49079117.25,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'CHOOF',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.001,
        marketCap: 373578.5136,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'MNBO',
        isCaveatEmptor: false,
        tierId: 10,
        tierCode: 'QB',
        tierName: 'OTCQB',
        currentClosingPrice: 23,
        marketCap: 10529584,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'TINO',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0017,
        marketCap: 1149170.556,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'DIGI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0033,
        marketCap: 852166.1511,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'TGRO',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0001,
        marketCap: 161439.4691,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'VRTB',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 2013,
        marketCap: 2516250,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'VDKB',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 1.386,
        marketCap: 18071872.434,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'INFT',
        isCaveatEmptor: false,
        tierId: 10,
        tierCode: 'QB',
        tierName: 'OTCQB',
        currentClosingPrice: 9.4,
        marketCap: 31261730.4,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'SOHI',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 1.45,
        marketCap: 92959386.9,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'TMEB',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.0125,
        marketCap: 5203316.342,
        caveatEmptor: false
      },
      {
        startDate: 1682913600000,
        endDate: 1684123200000,
        symbol: 'SPSO',
        isCaveatEmptor: false,
        tierId: 21,
        tierCode: 'PL',
        tierName: 'Pink Limited',
        currentClosingPrice: 0.007,
        marketCap: 3060130.4907,
        caveatEmptor: false
      }
    ],
    promotionsCaveatEmptor: [
      {
        changeDate: '2017-08-18',
        symbol: 'CSKSCSZURJ',
        tierId: '10',
        tierCode: 'QB',
        oldValue: 'Y',
        newValue: 'N'
      },
      {
        changeDate: '2017-08-18',
        symbol: 'JBJZGPVHDR',
        tierId: '10',
        tierCode: 'QB',
        oldValue: 'Y',
        newValue: 'N'
      },
      {
        changeDate: '2017-08-18',
        symbol: 'JRHXFKYXSX',
        tierId: '10',
        tierCode: 'QB',
        oldValue: 'Y',
        newValue: 'N'
      },
      {
        changeDate: '2017-08-18',
        symbol: 'LYIIEANMXY',
        tierId: '10',
        tierCode: 'QB',
        oldValue: 'Y',
        newValue: 'N'
      },
      {
        changeDate: '2017-08-18',
        symbol: 'REHIEEHRBU',
        tierId: '10',
        tierCode: 'QB',
        oldValue: 'Y',
        newValue: 'N'
      },
      {
        changeDate: '2017-09-29',
        symbol: 'MAKE',
        tierId: '22',
        tierCode: 'PN',
        oldValue: 'N',
        newValue: 'Y',
        closingPrice: '5000.00000',
        marketCap: '34730000.000000'
      },
      {
        changeDate: '2017-12-19',
        symbol: 'VINO',
        tierId: '10',
        tierCode: 'QB',
        oldValue: 'N',
        newValue: 'Y',
        closingPrice: '1.06000',
        marketCap: '45548625.060000'
      },
      {
        changeDate: '2017-12-21',
        symbol: 'HARL',
        tierId: '10',
        tierCode: 'QB',
        oldValue: 'N',
        newValue: 'Y',
        closingPrice: '23.50000',
        marketCap: '84444655.750000'
      },
      {
        changeDate: '2018-01-12',
        symbol: 'ADDC',
        tierId: '22',
        tierCode: 'PN',
        oldValue: 'N',
        newValue: 'Y',
        closingPrice: '14.61000'
      },
      {
        changeDate: '2018-01-12',
        symbol: 'ADDC',
        tierId: '22',
        tierCode: 'PN',
        oldValue: 'Y',
        newValue: 'N',
        closingPrice: '14.61000'
      }
    ],
    promotionsNameChanges: [
      {
        effectiveDate: '2017-06-29',
        oldSymbol: 'MNBP',
        oldTierId: '1',
        oldTierCode: 'QX',
        oldCompanyName: 'Mars National Bancorp, Inc.',
        oldSic: '6021 - National commercial banks',
        newSymbol: 'MNBP',
        newTierId: '1',
        newTierCode: 'QX',
        newCompanyName: 'Mars Bancorp, Inc. ',
        newSic: '6021 - National commercial banks'
      },
      {
        effectiveDate: '2017-06-29',
        oldSymbol: 'PAOG',
        oldTierId: '20',
        oldTierCode: 'PC',
        oldCompanyName: 'New Wave Holdings, Inc.',
        oldSic: '6719 - Holding companies, misc',
        newSymbol: 'PAOG',
        newTierId: '20',
        newTierCode: 'PC',
        newCompanyName: 'PAO Group, Inc. ',
        newSic: '6719 - Holding companies, misc'
      },
      {
        effectiveDate: '2017-06-30',
        oldSymbol: 'CYSM',
        oldTierId: '1',
        oldTierCode: 'QX',
        oldCompanyName: 'Community Bank of Santa Maria (CA)',
        oldSic: '6022 - State commercial banks',
        newSymbol: 'CYSM',
        newTierId: '1',
        newTierCode: 'QX',
        newCompanyName: 'Community Bancorp of Santa Maria',
        newSic: '6022 - State commercial banks'
      },
      {
        effectiveDate: '2017-06-30',
        oldSymbol: 'MPME',
        oldTierId: '10',
        oldTierCode: 'QB',
        oldCompanyName: 'Accurexa Inc.',
        oldSic: '3845 - Electromedical equipment',
        newSymbol: 'MPME',
        newTierId: '10',
        newTierCode: 'QB',
        newCompanyName: 'Medisun Precision Medicine Ltd. ',
        newSic: '3845 - Electromedical equipment'
      },
      {
        effectiveDate: '2017-07-03',
        oldSymbol: 'OSOK',
        oldTierId: '20',
        oldTierCode: 'PC',
        oldCompanyName: 'Original Source Entertainment Inc',
        oldSic: '6794 - Patent owners and lessors',
        newSymbol: 'OSOK',
        newTierId: '20',
        newTierCode: 'PC',
        newCompanyName: 'NeuroOne Medical Technologies Corporation',
        newSic: '6794 - Patent owners and lessors'
      },
      {
        effectiveDate: '2017-07-03',
        oldSymbol: 'SOHI',
        oldTierId: '20',
        oldTierCode: 'PC',
        oldCompanyName: 'Merchants Bancorp',
        oldSic: '6029 - Commercial banks, misc',
        newSymbol: 'SOHI',
        newTierId: '20',
        newTierCode: 'PC',
        newCompanyName: 'Sortis Holdings, Inc.',
        newSic: '6029 - Commercial banks, misc'
      },
      {
        effectiveDate: '2017-07-05',
        oldSymbol: 'GCTAY',
        oldTierId: '20',
        oldTierCode: 'PC',
        oldCompanyName: 'Gamesa Corporacion Tecnologica S.A.',
        newSymbol: 'GCTAY',
        newTierId: '20',
        newTierCode: 'PC',
        newCompanyName: 'Siemens Gamesa Renewable Energy'
      },
      {
        effectiveDate: '2017-07-06',
        oldSymbol: 'USTDF',
        oldTierId: '20',
        oldTierCode: 'PC',
        oldCompanyName: 'Palisades Ventures Inc.',
        oldSic: '1094 - Uranium-radium-vanadium ores',
        newSymbol: 'USTDD',
        newTierId: '20',
        newTierCode: 'PC',
        newCompanyName: 'Fremont Gold Ltd.. ',
        newSic: '1094 - Uranium-radium-vanadium ores'
      },
      {
        effectiveDate: '2018-01-12',
        oldSymbol: 'ASAM',
        oldTierId: '21',
        oldTierCode: 'PL',
        oldCompanyName: 'AssuranceAmerica Corp.',
        oldSic: '6331 - Fire, marine, and casualty insurance',
        newSymbol: 'ASAM',
        newTierId: '22',
        newTierCode: 'PN',
        newCompanyName: 'Assurance Perfect',
        newSic: '6331 - Fire, marine, and casualty insurance'
      },
      {
        effectiveDate: '2018-01-16',
        oldSymbol: 'AELTF',
        oldTierId: '20',
        oldTierCode: 'PC',
        oldCompanyName: 'Adacel Technologies Ltd.',
        newSymbol: 'AELTF',
        newTierId: '20',
        newTierCode: 'PC',
        newCompanyName: 'New Adacel Technologis Ltd.'
      }
    ],
    reverseSplits: [
      {
        splitEffectiveDate: '2017-01-13',
        oldTierCode: '',
        newSymbol: 'ABCE',
        newTierId: '20',
        newTierCode: 'PC',
        splitRatio: '1:10',
        currentClosingPrice: '0.00700',
        marketCap: '179494.191500',
        isCaveatEmptor: true
      },
      {
        splitEffectiveDate: '2017-01-13',
        oldTierCode: '',
        newSymbol: 'EPGC',
        newTierId: '22',
        newTierCode: 'PN',
        splitRatio: '1:40',
        currentClosingPrice: '0.21000',
        marketCap: '14628236.070000',
        isCaveatEmptor: true
      },
      {
        splitEffectiveDate: '2017-01-17',
        oldTierCode: '',
        newSymbol: 'WSFL',
        newTierId: '20',
        newTierCode: 'PC',
        splitRatio: '1:10',
        currentClosingPrice: '0.59000',
        marketCap: '11527731.520000',
        isCaveatEmptor: true
      },
      {
        splitEffectiveDate: '2017-01-18',
        oldTierCode: '',
        newSymbol: 'NLPXF',
        newTierId: '20',
        newTierCode: 'PC',
        splitRatio: '1:5',
        currentClosingPrice: '0.03500'
      },
      {
        splitEffectiveDate: '2017-01-18',
        oldTierCode: '',
        newSymbol: 'PEFF',
        newTierId: '22',
        newTierCode: 'PN',
        splitRatio: '1:15',
        currentClosingPrice: '0.12000'
      },
      {
        splitEffectiveDate: '2017-01-19',
        oldTierCode: '',
        newSymbol: 'AMSA',
        newTierId: '20',
        newTierCode: 'PC',
        splitRatio: '1:5',
        currentClosingPrice: '0.00600',
        marketCap: '291434.262000'
      },
      {
        splitEffectiveDate: '2017-05-15',
        oldTierCode: '',
        newSymbol: 'NOBGY',
        newTierId: '20',
        newTierCode: 'PC',
        splitRatio: '1:5',
        currentClosingPrice: '4.30000'
      },
      {
        splitEffectiveDate: '2017-05-15',
        oldTierCode: '',
        newSymbol: 'TFGL',
        newTierId: '22',
        newTierCode: 'PN',
        splitRatio: '1:50',
        currentClosingPrice: '0.91000'
      },
      {
        splitEffectiveDate: '2017-05-19',
        oldTierCode: '',
        newSymbol: 'ECEZ',
        newTierId: '20',
        newTierCode: 'PC',
        splitRatio: '1:100',
        currentClosingPrice: '1.00000',
        marketCap: '48093974.000000'
      },
      {
        splitEffectiveDate: '2017-05-22',
        oldTierCode: '',
        newSymbol: 'DIRV',
        newTierId: '20',
        newTierCode: 'PC',
        splitRatio: '1:200',
        currentClosingPrice: '0.03600'
      }
    ],
    promotionsShell: [
      {
        shellChangeDate: '2017-07-31',
        symbol: 'DSOL',
        tierId: '22',
        tierCode: 'PN',
        oldValue: 'N',
        newValue: 'Y',
        currentClosingPrice: '0.01400',
        marketCap: '573084.400000'
      },
      {
        shellChangeDate: '2017-07-31',
        symbol: 'DSOL',
        tierId: '22',
        tierCode: 'PN',
        oldValue: 'Y',
        newValue: 'N',
        currentClosingPrice: '0.01400',
        marketCap: '573084.400000'
      },
      {
        shellChangeDate: '2017-07-31',
        symbol: 'LBAO',
        tierId: '10',
        tierCode: 'QB',
        oldValue: 'Y',
        newValue: 'N'
      },
      {
        shellChangeDate: '2017-08-01',
        symbol: 'LBAO',
        tierId: '10',
        tierCode: 'QB',
        oldValue: 'N',
        newValue: 'Y'
      },
      {
        shellChangeDate: '2017-08-02',
        symbol: 'GFGSF',
        tierId: '10',
        tierCode: 'QB',
        oldValue: 'N',
        newValue: 'Y',
        currentClosingPrice: '0.51100',
        marketCap: '25722552.436000'
      },
      {
        shellChangeDate: '2017-08-17',
        symbol: 'MMNHTR',
        tierId: '1',
        tierCode: 'QX',
        oldValue: 'N',
        newValue: 'Y'
      },
      {
        shellChangeDate: '2017-08-17',
        symbol: 'MMNHTR',
        tierId: '1',
        tierCode: 'QX',
        oldValue: 'Y',
        newValue: 'N'
      },
      {
        shellChangeDate: '2018-01-12',
        symbol: 'ASWAY',
        tierId: '20',
        tierCode: 'PC',
        oldValue: 'N',
        newValue: 'Y',
        currentClosingPrice: '3.74250',
        marketCap: '261958757.550000'
      },
      {
        shellChangeDate: '2018-01-12',
        symbol: 'ASWAY',
        tierId: '20',
        tierCode: 'PC',
        oldValue: 'Y',
        newValue: 'N',
        currentClosingPrice: '3.74250',
        marketCap: '261958757.550000'
      },
      {
        shellChangeDate: '2018-01-12',
        symbol: 'ATIN',
        tierId: '22',
        tierCode: 'PN',
        oldValue: 'N',
        newValue: 'Y',
        currentClosingPrice: '0.19450',
        marketCap: '19289632.027000'
      }
    ],
    promotionsSuspensionRevocation: [
      {
        effectiveDate: '2017-07-06',
        symbol: 'AIOM',
        tierId: '30',
        tierCode: 'GM',
        currentStatus: 'Active',
        closingPrice: '0.72000',
        marketCap: '244800000.000000'
      },
      {
        effectiveDate: '2017-07-06',
        symbol: 'FGLD',
        tierId: '30',
        tierCode: 'GM',
        currentStatus: 'Active',
        closingPrice: '0.00010',
        marketCap: '54701.638600'
      },
      {
        effectiveDate: '2017-07-06',
        symbol: 'NSUB',
        tierId: '30',
        tierCode: 'GM',
        currentStatus: 'Active'
      },
      {
        effectiveDate: '2018-01-11',
        symbol: 'AEB',
        tierId: '0',
        tierCode: 'NT',
        currentStatus: 'Active',
        closingPrice: '25.00000'
      },
      {
        effectiveDate: '2018-01-12',
        symbol: 'ADDC',
        tierId: '22',
        tierCode: 'PN',
        currentStatus: 'Active',
        closingPrice: '14.61000'
      },
      {
        effectiveDate: '2018-01-12',
        symbol: 'AEB',
        tierId: '0',
        tierCode: 'NT',
        currentStatus: 'Active',
        closingPrice: '25.00000'
      },
      {
        effectiveDate: '2018-01-12',
        symbol: 'YRAIF',
        tierId: '20',
        tierCode: 'PC',
        currentStatus: 'Suspended',
        closingPrice: '37.00000',
        marketCap: '10304500000.000000'
      }
    ]
  }
}
