import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getList (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'directory/qualified-fexchange/list',
    url: '/qualified-foreign-exchanges',
    params
  })
  // normalize non-standard response from OTC API
  if (response.data && response.data.qualifiedForeignExchanges) {
    response.data.records = response.data.qualifiedForeignExchanges
  }

  // if tier is 'No Tier', set to null
  response.data && response.data.records && response.data.records.forEach(item => {
    if (item && item.tier === 'No Tier') item.tier = null
  })

  return response.data
}

export async function downloadList () {
  const url = '/otcapi/download/internal/qualified-foreign-exchanges/download'
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
    .catch(e => console.log('no session', e))
  window.open(`${REACT_APP_API_BASE_URI}${url}?file=qualified-foreign-exchanges&token=${jwtToken}`)
}
