import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '../../components/WithRouter'
import { template, fetch, expand, sort } from '../../api/helper'
import {
  getFilters,
  getData
} from '../../api/otc/market-activity/corporate-actions'

import columns from './columns'
import HeadTitle from '../../components/HeadTitle'
import styles from './MACorpActionsPage.module.scss'
import Flexgrid from '../../components/Flexgrid'
import Outline from '../../components/Outline'
import Input from '../../components/Input'
import Table from '../../components/Table'
import More from '../../components/More'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import Select from '../../components/Select'
import PageTitle from '../../components/PageTitle'
import Sticky from '../../components/Sticky'

const searchValues = [{
  title: 'Venue Changes',
  value: 'venue-changes',
  key: 'venue-changes'
}, {
  title: 'Symbol Changes',
  value: 'symbol-changes',
  key: 'symbol-changes'
}, {
  title: 'Company Name Changes',
  value: 'name-changes',
  key: 'name-changes'
}, {
  title: 'Symbol And Name Changes',
  value: 'symbol-name-changes',
  key: 'symbol-name-changes'
}, {
  title: 'Splits',
  value: 'splits',
  key: 'splits'
}, {
  title: 'Deletion',
  value: 'deletions',
  key: 'deletions'
}, {
  title: 'Suspensions / Halts',
  value: 'halts',
  key: 'halts'
}, {
  title: 'Proprietary Quote Eligible',
  value: 'proprietary-quote-eligible',
  key: 'proprietary-quote-eligible'
}, {
  title: 'Ex-Dividends',
  value: 'ex-dividends',
  key: 'ex-dividends'
}, {
  title: 'Scheduled Dividends',
  value: 'sch-dividends',
  key: 'sch-dividends'
}, {
  title: 'Tier Changes',
  value: 'tier-changes',
  key: 'tier-changes'
}, {
  title: 'Caveat Emptor',
  value: 'ce-changes',
  key: 'ce-changes'
}]

const searchBy = [{
  title: 'Symbol',
  value: 'symbol',
  key: 'symbol'
}, {
  title: 'Name',
  value: 'name',
  key: 'name'
}]

class MACorpActionsPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      filters: template({
        allowedVenues: { records: [] },
        otcVenues: { records: [] },
        securityTypes: { records: [] },
        tiers: { records: [] }
      }),
      table: template('records'),
      searchType: 'symbol-changes',
      byField: 'symbol',
      forValue: null,
      tierCode: null,
      securityType: null
    }
    this.initialState = Object.assign({}, this.state)
  }

  /**
   * Load Initial Data
   */
  componentDidMount () {
    this.loadFilters()
    this.loadTable()
  }

  /**
   * When the component updates, check if the market changed, and requery the data if so.
   * @param {Object} prevProps - The previous props to compare to.
   */
  componentDidUpdate (prevProps) {
    const { match: { params: { market: prevMarket } } } = prevProps
    const { match: { params: { market } } } = this.props
    if (market !== prevMarket) {
      this.loadFilters()
      this.loadTable()
    }
  }

  loadFilters = () => {
    fetch(this, getFilters, 'filters', {}, data => {
      if (!data) data = {}
      if (data === '') data = {}

      for (const key in data) {
        for (const item of data[key].records) {
          item.key = item.id
          item.value = item.id
          item.title = item.caption

          delete item.id
          delete item.caption
        }
      }

      return data
    })
  }

  loadTable = () => {
    fetch(this, getData, 'table', {
      route: this.state.searchType,
      page: 1,
      pageSize: 25,
      symbol: this.state.byField === 'symbol' ? this.state.forValue : undefined,
      companyName: this.state.byField === 'name' ? this.state.forValue : undefined,
      tierCode: this.state.tierCode,
      securityTypeId: this.state.securityType,
      retainPageSize: true
    }, data => {
      if (!data) data = {}
      if (data === '') data = {}
      if (!data.records) data.records = []

      for (const item of data.records) {
        // caveat emptor
        if (item.isCaveatEmptor === true) {
          item.caveatEmptorStatus = 'Added'
        } else if (item.isCaveatEmptor === false) {
          item.caveatEmptorStatus = 'Removed'
        }
        // deletion
        if (item.isDeletion === true) {
          item.deletionStatus = 'Deleted'
        } else if (item.isDeletion === false) {
          item.deletionStatus = 'Reactivated'
        }
      }
      return data
    })
  }

  getTableTitle = (value) => {
    const item = searchValues.find(item => item.key === this.state.searchType)
    return item && item.title
  }

  onSelectSearchType = (searchType) => {
    this.setState({ searchType, table: template('records') }, this.loadTable)
  }

  onSelectByField = (byField) => {
    this.setState({ byField }, this.loadTable)
  }

  onSelectTier = (tierCode) => {
    this.setState({ tierCode }, this.loadTable)
  }

  onSelectSecurityType = (securityType) => {
    this.setState({ securityType }, this.loadTable)
  }

  onReturnForValue = (forValue) => {
    if (forValue === this.state.forValue) return
    this.setState({ forValue }, this.loadTable)
  }

  dateSelect = (type, field) => {
    return (date) => {
      // var filters = this.state.filters

      // if (type && field) {
      //   filters[type][field] = date && moment(date).format('MM/DD/YYYY')
      // }

      // this.setState({ filters }, this.loadContent)
    }
  }

  render () {
    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['filter', 'table']
        }
      ],
      md: [
        {
          type: 'flex',
          column: true,
          subtree: ['filter', 'table', 'footer-note']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['filter']
            },
            {
              type: 'main',
              subtree: ['table', 'footer-note']
            }
          ]
        }
      ]
    }
    const filterLayouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['searchType', 'for', 'by', 'securityType', 'tiers']
        }
      ],
      md: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'box',
              width: 1 / 2,
              className: styles.filterLeft,
              subtree: ['searchType', 'by']
            },
            {
              type: 'box',
              width: 1 / 2,
              className: styles.filterRight,
              subtree: ['for', 'securityType', 'tiers']
            }
          ]
        }
      ]
    }

    return (
      <div className={styles.MACurrentMarketPage}>
        <HeadTitle title='How Corporate Actions Impact Shareholders' />
        <PageTitle>Corporate Actions</PageTitle>
        <Flexgrid layouts={layouts}>
          <Sticky key='filter' className={styles.sideColumn}>
            <Flexgrid layouts={filterLayouts} className={styles.filter}>
              <Select title='Search'
                design='standard'
                options={searchValues}
                defaultValue={this.state.searchType}
                onSelect={this.onSelectSearchType}
                required
                key='searchType' />
              <Input title='For'
                design='standard'
                onReturn={this.onReturnForValue}
                onBlur={this.onReturnForValue}
                key='for' />
              <Select title='By'
                design='standard'
                options={searchBy}
                defaultValue={'symbol'}
                onSelect={this.onSelectByField}
                required
                key='by' />
              <Select title='Security Type'
                design='standard'
                options={this.state.filters.data.securityTypes.records}
                onSelect={this.onSelectSecurityType}
                key='securityType' />
              <Select title='Market'
                design='standard'
                options={this.state.filters.data.tiers.records}
                onSelect={this.onSelectTier}
                key='tiers' />
            </Flexgrid>
          </Sticky>
          <div key='table'>
            <Outline mode='heading'>
              {this.getTableTitle()}
            </Outline>
            <Loading
              type='table'
              loaded={this.state.table.loaded}
              reloading={this.state.table.reloading}
              error={this.state.table.error}
              data={this.state.table.data.records}>
              <Table
                columns={columns[this.state.searchType]}
                data={this.state.table.data.records}
                sortColumn={this.state.table.sortOn}
                sortDirection={this.state.table.sortDir}
                onSorting={sort(this, getData, 'table')}
                showHeader
              />
              <TableFooter>
                <More onClick={expand(this, getData, 'table')}
                  disabled={this.state.table.expandEmpty} />
                {this.state.table.data.records && this.state.table.data.totalRecords &&
                  <DisplayResults show={this.state.table.data.records.length} total={this.state.table.data.totalRecords} text={this.getTableTitle()} />
                }
              </TableFooter>
            </Loading>
          </div>
        </Flexgrid>
      </div>
    )
  }
}

MACorpActionsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      market: PropTypes.string,
      table: PropTypes.string
    })
  })
}

export default withRouter(MACorpActionsPage)
