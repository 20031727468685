import React from 'react'
import PropTypes from 'prop-types'
import { Amplify } from 'aws-amplify'
import { withRouter } from './components/WithRouter'
import { parseJwt } from './utils/jwt'
import { checkSession } from './utils/session'
import getConfig from './config'
import {
  signIn,
  confirmSignIn,
  setDeviceId,
  getCurrentDevice,
  deleteDevice,
  changePassword,
  completeNewPassword,
  forgotPassword,
  signout,
  getCurrentSession,
  getCurrentUser,
  forgotPasswordSubmit
} from './api/auth/auth'
const { REACT_APP_API_BASE_URI } = getConfig()
const COOKIE_EXP = 0.416667
const AuthContext = React.createContext()

class AuthProviderComponent extends React.Component {
  state = {
    isAuth: null,
    user: null,
    username: null,
    password: null,
    authState: null,
    error: null,
    codeDeliveryMedium: null,
    codeDeliveryDestination: null,
    successBanner: false,
    disableButton: false
  }

  componentDidMount () {
    window.fetch(`${REACT_APP_API_BASE_URI}/aws`, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then((resp) => resp.json())
      .then(data => {
        Amplify.configure({
          Auth: {
            // REQUIRED - Amazon Cognito Region
            region: data.awsRegion,

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: data.awsUserPoolId,

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: data.awsClientId,

            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,

            // OPTIONAL - Configuration for cookie storage
            // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
            cookieStorage: {
              // REQUIRED - Cookie domain (only required if cookieStorage is provided)
              domain: window.location.hostname,
              // OPTIONAL - Cookie expiration in days
              expires: COOKIE_EXP,
              // OPTIONAL - Cookie secure flag
              // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
              secure: false
            }
            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            // authenticationFlowType: 'USER_PASSWORD_AUTH'
          }
        })

        getCurrentSession()
          .then(() => {
            getCurrentUser()
              .then(user => {
                getCurrentDevice({ username: user.attributes.email })
                  .then(response => {
                    if (response === 'AUTHORIZE_LOGIN') {
                      this.resetLogin()
                    } else if (response === 'NO_UUID_FOUND') {
                      this.resetLogin()
                    } else {
                      this.setState({ user, email: user.attributes.email, username: user.username, error: null, isAuth: true })
                    }
                  })
                  .catch(() => {
                    this.resetLogin()
                  })
              })
              .catch(() => this.setState({ isAuth: false, authState: 'LOG_IN' }))
          })
          .catch(() => this.setState({ isAuth: false, authState: 'LOG_IN' }))
      })
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.location.pathname !== '' && this.props.location.pathname !== '/dashboard') {
        checkSession()
          .then(res => {
            if (res === 'invalid') {
              this.resetLogin()
            }
          })
          .catch(() => this.resetLogin())
      }
    }
  }

  changeState = (state) => {
    this.setState({
      error: null,
      authState: state
    })
  }

  submitLogin = (username, pwd, event) => {
    event.preventDefault()
    this.setState({ error: null, disableButton: true, isAuth: false, user: username, username, password: pwd }, () => {
      getCurrentDevice({ username })
        .then(response => {
          if (response === 'AUTHORIZE_LOGIN') {
            this.setState({ authState: response })
          } else {
            this.login()
          }
        })
        .catch(() => { // no device uuid set for this user.
          this.login()
        })
    })
  }

  login = () => {
    signIn(this.state.user, this.state.password)
      .then(user => {
        if (user.challengeName) {
          this.setState({ error: null, disableButton: false, user, authState: user.challengeName })
        } else {
          const userEmail = parseJwt(user.signInUserSession.idToken.jwtToken).email || null
          setDeviceId(userEmail).then(() => this.setState({ error: null, disableButton: false, email: userEmail, user, isAuth: true }))
        }
      }).catch(() => {
        this.setState({
          disableButton: false,
          error: 'Invalid credentials, please try again.'
        })
      })
  }

  submitConfirmLogin = (code, event) => {
    const challengeName = (this.state.user && this.state.user.challengeName) || 'SMS_MFA'
    event.preventDefault()
    this.setState({ disableButton: true })
    confirmSignIn(this.state.user, code, challengeName)
      .then(user => {
        const userEmail = parseJwt(user.signInUserSession.idToken.jwtToken).email || null
        setDeviceId(userEmail).then(() => this.setState({ error: null, disableButton: false, email: userEmail, user, password: null, isAuth: true }))
      })
      .catch(err => this.setState({ disableButton: false, error: err.message }))
  }

  resendCode = () => {
    this.setState({ disableButton: true })
    signIn(this.state.username, this.state.password)
      .then(user => this.setState({ disableButton: false, error: null, user, successBanner: true }))
      .catch(err => this.setState({ disableButton: false, error: err.message }))
  }

  closeBanner = () => {
    this.setState({ successBanner: false })
  }

  submitCompleteNewPwd = (pwd, event) => {
    event.preventDefault()
    this.setState({ disableButton: true })
    completeNewPassword(this.state.user, pwd)
      .then(user => {
        if (user.challengeName && user.challengeName === 'SMS_MFA') {
          this.setState({ disableButton: false, error: null, user, authState: user.challengeName })
        } else {
          this.setState({ disableButton: false, error: null, user, isAuth: true })
        }
      })
      .catch(err => this.setState({ disableButton: false, error: err.message }))
  }

  submitForgotPwd = (user, event) => {
    event.preventDefault()
    this.setState({ disableButton: true })
    forgotPassword(user)
      .then(data => {
        this.setState({
          disableButton: false,
          error: null,
          user,
          codeDeliveryMedium: data.CodeDeliveryDetails.DeliveryMedium,
          codeDeliveryDestination: data.CodeDeliveryDetails.Destination,
          authState: 'RESET_PASSWORD'
        })
      })
      .catch(err => this.setState({ disableButton: false, error: err.message }))
  }

  submitResetPassword = (resetCode, password, event) => {
    event.preventDefault()
    this.setState({ disableButton: true })
    forgotPasswordSubmit(this.state.user, resetCode, password)
      .then(data => {
        this.setState({
          disableButton: false,
          error: null,
          authState: 'LOG_IN'
        })
      })
      .catch(err => this.setState({ disableButton: false, error: err.message }))
  }

  submitChangePassword = (oldPwd, newPwd, event) => {
    event.preventDefault()
    this.setState({ disableButton: true })
    if (oldPwd === newPwd) return this.setState({ disableButton: false, error: 'Old and New Passwords must be different' })
    changePassword(this.state.user, oldPwd, newPwd)
      .then(() => this.setState({ disableButton: false, error: null, authState: 'UPDATE_PASSWORD_SUCCESS' }))
      .catch(err => this.setState({ disableButton: false, error: err.message }))
  }

  logout = () => {
    signout()
      .then(() => {
        deleteDevice({ username: this.state.email })
        this.resetLogin()
      })
      .catch(() => this.resetLogin())
  }

  resetLogin = () => {
    this.setState({ error: null, isAuth: false, user: null, authState: 'LOG_IN' })
  }

  render () {
    const user = this.state.user
    const userGroups = user && user.signInUserSession && user.signInUserSession.accessToken && user.signInUserSession.accessToken.payload && user.signInUserSession.accessToken.payload['cognito:groups']

    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          authState: this.state.authState,
          changeState: this.changeState,
          submitLogin: this.submitLogin,
          submitConfirmLogin: this.submitConfirmLogin,
          resendCode: this.resendCode,
          successBanner: this.state.successBanner,
          closeBanner: this.closeBanner,
          login: this.login,
          logout: this.logout,
          resetLogin: this.resetLogin,
          submitCompleteNewPwd: this.submitCompleteNewPwd,
          submitForgotPwd: this.submitForgotPwd,
          submitChangePassword: this.submitChangePassword,
          submitResetPassword: this.submitResetPassword,
          codeDeliveryMedium: this.state.codeDeliveryMedium,
          codeDeliveryDestination: this.state.codeDeliveryDestination,
          user: { ...this.state.user, userGroups },
          error: this.state.error,
          disableButton: this.state.disableButton
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

AuthProviderComponent.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
}

export const withContext = (Component) => {
  return (props) =>
    <AuthConsumer>
      {(context) => {
        return <Component {...props} context={context} />
      }}
    </AuthConsumer>
}

const AuthConsumer = AuthContext.Consumer
const AuthProvider = withRouter(AuthProviderComponent)
export { AuthProvider, AuthConsumer }
