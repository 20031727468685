import api from '../../api'

export async function getFullCompanyProfile (symbol) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    mockData: 'company/profile/full-company-profile',
    url: `/company/profile/full/${symbol}`,
    params: {
      symbol
    }
  })
  return response.data
}

export async function getMiniCompanyProfile (symbol) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    mockData: 'company/profile/mini-company-profile',
    url: `/company/profile/min/${symbol}`,
    params: {
      symbol
    }
  })
  return response.data
}

export async function getFixedIncome (symbol) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: '/fixed-income',
    params: {
      symbol
    }
  })

  return response.data.response || response
}

export async function getCompanyBadges (symbol) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    mockData: 'company/profile/company-badges',
    url: `/internal/company/profile/${symbol}/badges`,
    params: {
      symbol
    }
  })
  return response.data
}
