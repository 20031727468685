import React from 'react'
import PropTypes from 'prop-types'
import styles from './StockQuote.module.scss'
import inputStyles from '../Input/styles.module.scss'
import classNames from 'classnames'
import { FaSearch } from 'react-icons/fa'
import { Flex, Box } from 'grid-styled'
import enhanceWithClickOutside from 'react-click-outside'
import { securitySearch } from '../../api/otc/search'
import { search } from '../../utils/auto-suggest'

class StockQuote extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      hidden: true,
      value: null,
      inputVal: '',
      loaded: false,
      focused: false,
      error: null,
      searchObject: [],
      results: [],
      selectionIndex: -1
    }
  }

  onFocus = (e) => {
    this.setState({ focused: true })
    this.onChange(e)
  }

  onBlur = () => {
    this.setState(state => ({
      focused: false
    }))
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.value.length > 0) {
      this.selectSymbol()
    } else if (e.key === 'ArrowUp') {
      const newIndex = this.state.selectionIndex >= 0
        ? this.state.selectionIndex - 1
        : this.state.results.length - 1
      this.setState({ selectionIndex: newIndex })
    } else if (e.key === 'ArrowDown') {
      const newIndex = this.state.selectionIndex < this.state.results.length - 1
        ? this.state.selectionIndex + 1
        : -1
      this.setState({ selectionIndex: newIndex })
    }
  }

  resultHover = (index, item) => {
    return () => {
      this.setState(state => ({
        selectionIndex: index
      }))
    }
  }

  selectSymbol = () => {
    // don't continue if no results (symbol is not valid)
    if (!this.state.results || !this.state.results.length) return
    // if selectionIndex isn't specified, choose first symbol in results
    const symbolIndex = this.state.selectionIndex > -1 ? this.state.selectionIndex : 0
    const symbolItem = this.state.results[symbolIndex]
    // don't continue if symbol isn't found in results set (edge case)
    if (!symbolItem) return
    const symbol = symbolItem.symbol
    // set input value to selected symbol and hide dropdown
    this.setState({
      value: this.props.keepSelection ? symbol : '',
      inputVal: this.props.keepSelection ? symbol : '',
      hidden: true,
      selectionIndex: -1
    }, () => {
      if (this.props.onSelect) this.props.onSelect(symbol, symbolItem)
    })
  }

  onChange = (e) => {
    let value = e.target.value
    // display an error if there's an empty value for a required field.
    if (value) {
      // trim the value if it's starting with a space
      if (value.substring(0, 1) === ' ') {
        value = value.trim()
      }
    }
    if (this.props.onChange) this.props.onChange(value)
    if (value === '' || !value) {
      this.setState(state => ({
        hidden: true,
        value: null,
        inputVal: null,
        selectionIndex: -1
      }))
      return
    }

    this.setState({ inputVal: value })

    securitySearch(value, this.props.hideFixedIncome)
      .then(data => {
        this.setState({
          hidden: false,
          value,
          loaded: true,
          error: null,
          selectionIndex: -1,
          results: search(data.docs)
        })
      })
      .catch(e => {
        console.log('search error', e)
        this.setState({
          hidden: false,
          value,
          loaded: true,
          selectionIndex: -1,
          error: 'Search error',
          results: []
        })
      })
  }

  handleClickOutside = () => {
    if (this.state.hidden === false) {
      this.setState({ hidden: true })
    }
  }

  handleImgError = (index) => {
    const a = this.state.results.slice()
    a[index].text = a[index].img
    this.setState({ results: a })
  }

  render () {
    const classes = classNames(inputStyles.input, inputStyles[this.props.design], {
      [inputStyles.focused]: this.state.focused
    }, this.props.className)
    return (
      <div className={styles.wrapper}>
        <Flex align='center' className={classes}>
          <input
            placeholder={this.props.placeholder || 'Symbol'}
            value={this.state.inputVal || ''}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.handleKeyDown} />
          <FaSearch />
        </Flex>

        <Flex wrap w={1} className={classNames({
          [styles.searchResults]: true,
          [styles.searchResultsHidden]: this.state.hidden
        })}>
          {this.state.results.map((result, i) => <Box
            key={result.symbol}
            w={1}
            onMouseOver={this.resultHover(i, result)}
            onClick={this.selectSymbol}
            className={classNames({
              [styles.searchResultItem]: true,
              [styles.searchResultItemHidden]: result.symbol.toLowerCase().startsWith('test') || result.companyname.toLowerCase() === 'test co',
              [styles.activeItem]: this.state.selectionIndex === i
            })}>
            <div className={styles.symbolTierContainer}>
              {(result.img && !result.text && !result.isFixedIncome) && <img onError={() => this.handleImgError(i)} src={`/icons/tier/${result.img}.png`} />}
              {(result.text && !result.isFixedIncome) && <span>{result.text}</span>}
            </div>
            <div className={styles.name}>
              {result.symbol} - {result.companyname}
            </div>
          </Box>)}
          {!this.state.results.length &&
            <Box w={1} className={styles.searchInfo} onClick={this.onClickOutside}>
              {!this.state.error && this.state.loaded &&
                <i>No results match your search query</i>}
              {!this.state.error && !this.state.loaded &&
                <i>Loading...</i>}
              {this.state.error && <i>{this.state.error}</i>}
            </Box>
          }
        </Flex>
        {process.env.NODE_ENV === 'test' && <div
          onClickOutside={this.onClickOutside} />}
      </div>
    )
  }
}

StockQuote.propTypes = {
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  keepSelection: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  hideFixedIncome: PropTypes.bool,
  /** Design Style */
  design: PropTypes.oneOf(['filter', 'standard', 'outline'])
}
StockQuote.defaultProps = {
  hideFixedIncome: false,
  keepSelection: true,
  design: 'outline',
  includeIndices: false,
  maxResults: 10
}

export default enhanceWithClickOutside(StockQuote)
