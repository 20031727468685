import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input, TextLink } from '@otcmarketsgroup/otcm-ui'
import { PasswordRequirements } from '../PasswordRequirements'
import { withRouter } from '../WithRouter'
import { withContext } from '../../AuthContext'
import styles from '../../components/LogIn/LogIn.module.scss'

class ChangePassword extends React.PureComponent {
  constructor (props, context) {
    super(props, context)

    this.state = {
      oldPassword: '',
      newPassword: '',
      error: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleCodeChange = this.handleCodeChange.bind(this)
  }

  componentWillUnmount () {
    this.props.context.changeState(null)
  }

  handleChange (field) {
    return (value) => {
      this.setState({ [field]: value })
    }
  }

  handleCodeChange (value) {
    this.setState({ resetCode: value })
  }

  render () {
    const { context, navigate } = this.props

    return <div className={styles.container} style={{ marginTop: '50px' }}>
      <div className={styles.form}>
        {context.authState === 'UPDATE_PASSWORD_SUCCESS' ? <div>
          <div className={styles.greeting}>
            <h3>Success!</h3>
            <p>Your password has been successfully changed.</p>
          </div>
          <Button id={'successPwdChage'} className={styles.submit} title='Back to Dashboard' fullWidth onClick={() => navigate('/dashboard')} />
        </div>
          : <form onSubmit={(event) => context.submitChangePassword(this.state.oldPassword, this.state.newPassword, event)}>
            <div className={styles.greeting}>
              <h3 className={styles.formTitle}>Change Password</h3>
            </div>
            {/* <CodeInput type='number' fields={6} onChange={this.handleCodeChange} /> */}
            <Input id={'oldPassword'}
              className={styles.input}
              title={'Old Password'}
              required
              design='standard'
              onChange={this.handleChange('oldPassword')}
              // error={this.state.errors[field.name]}
              type={'password'}
            />
            <Input id={'newPassword'}
              className={styles.input}
              title={'New Password'}
              required
              design='standard'
              onChange={this.handleChange('newPassword')}
              // error={this.state.errors[field.name]}
              type={'password'}
            />
            {context.error && <i className={styles.error}>{context.error}</i>}
            <PasswordRequirements />
            <Button id={'forgotPwdSubmitLogIn'} nactive={context.disableButton} className={styles.submit} title='Submit' fullWidth type='submit' />
            <TextLink onClick={() => navigate(-1)}>Cancel</TextLink>
          </form>
        }
      </div>
    </div>
  }
}

ChangePassword.propTypes = {
  context: PropTypes.shape({
    authState: PropTypes.string,
    submitChangePassword: PropTypes.func,
    error: PropTypes.string,
    changeState: PropTypes.func
  }),
  navigate: PropTypes.func.isRequired
}

export default withRouter(withContext(ChangePassword))
