import React, { PureComponent } from 'react'
import styles from './DStateSecCommissionsPage.module.scss'
import { getCommissions, downloadList } from '../../api/otc/directory/state-sec-commissions'
import { template, fetch } from '../../api/helper'

import Loading from '../../components/Loading'
import Flexgrid from '../../components/Flexgrid'
import HeadTitle from '../../components/HeadTitle'
import Table from '../../components/Table'
import Outline from '../../components/Outline'
import PageTitle from '../../components/PageTitle'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Sticky from '../../components/Sticky'
import OtherDirectories from '../../components/OtherDirectories'
import DirSearch from '../../components/DirSearch'

const columns = [
  {
    name: 'jurisdiction'
  },
  {
    name: 'commissioner',
    header: 'Commissioner/Contact'
  },
  {
    name: 'phone',
    noWrap: true
  },
  {
    name: 'website',
    noWrap: true,
    linkTo: item => item.website
  }
]

export default class DStateSecCommissionsPage extends PureComponent {
  state = {
    loaded: false,
    list: template('records'),
    selectedLoadedPage: 1,
    searchQuery: null
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = () => {
    fetch(this, getCommissions, 'list', {
      page: 1,
      pageSize: 25,
      search: this.state.searchQuery
    }).then(data => this.setState({ fullList: data.data.records }))
  }

  onSearch = searchQuery => {
    const filtered = this.state.fullList.filter(item => item.jurisdiction.toLowerCase().includes(searchQuery.toLowerCase()))
    this.setState(state => ({
      list: {
        ...state.list,
        data: {
          ...state.list.data,
          records: filtered
        }
      }
    }))
  }

  get table () {
    const { list, fullList } = this.state
    return (
      <div>
        <Outline mode='heading'
          download={() => downloadList(fullList)}>
          State Securities Commissions
        </Outline>
        <Loading
          type='table'
          data={list.data}
          error={list.error}
          emptyMessage='No commissions found'
          reloading={list.reloading}
          loaded={list.loaded}>
          <Table
            columns={columns}
            loaded={list.loaded}
            data={list.data.records}
            showHeader
          />
          <TableFooter>
            {list.data.records && list.data.totalRecords &&
              <DisplayResults show={list.data.records.length} total={list.data.totalRecords} text={'Commissions'} />
            }
          </TableFooter>
        </Loading>
      </div>
    )
  }

  render () {
    const layouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['search', 'description', 'table', 'other-directories']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['sticky-column']
            },
            {
              type: 'main',
              mt: '1.8rem',
              subtree: ['table']
            }
          ]
        }
      ]
    }

    const dirSearch = <DirSearch key='search' placeholder='Search Jurisdiction' onSearch={this.onSearch} value={this.state.searchQuery} />

    const otherDirectories = <div key='other-directories'>
      <OtherDirectories />
    </div>

    return (
      <div className={styles.component}>
        <HeadTitle title='Directory - State Securities Commissions' />
        <PageTitle>State Securities Commissions</PageTitle>
        <Flexgrid layouts={layouts}>
          {dirSearch}
          <div key='table' className={styles.table}>
            {this.table}
          </div>
          {otherDirectories}
          <Sticky key='sticky-column' className={styles.sideColumn}>
            {dirSearch}
            {otherDirectories}
          </Sticky>
        </Flexgrid>
      </div>
    )
  }
}
