import api from '../../api'

export async function getFixedIncome211Data (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    url: '/fi/211',
    params
  })
  return response.data
}

export async function getFixedIncome211ExemptionReasons () {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    ignoreAuth: true,
    params: {},
    url: '/fi/211/compliance/reasons'
  })
  return response.data
}
