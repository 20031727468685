import moment from 'moment'
const movementFilters = [
  {
    title: 'All',
    value: 'ALL'
  },
  {
    title: '<.01',
    value: 0.01
  },
  {
    title: '>.50',
    value: 0.5
  },
  {
    title: '>1.00',
    value: '1.0'
  },
  {
    title: '>5.00',
    value: '5.0'
  }
]

export default [
  {
    key: 'riskScoring',
    hash: 'risk-scoring-change',
    title: 'Risk Scoring Change',
    filterDefault: 1,
    filters: [
      {
        title: 'Day',
        value: 'day'
      },
      {
        title: 'Week',
        value: 'week'
      },
      {
        title: 'Month',
        value: 'month'
      },
      {
        title: 'Quarter',
        value: 'quarter'
      }
    ],
    columns: [
      {
        name: 'symbol',
        header: 'Symbol',
        type: 'symbol',
        sortingEnabled: true
      },
      {
        name: 'prevRiskScore',
        header: (data) => `${moment(data.riskScoreDate).format('MM/DD/YYYY')} Risk Score`,
        sortingEnabled: true
      },
      {
        name: 'currentRiskScore',
        header: 'Current Risk Score',
        sortingEnabled: true
      },
      {
        name: 'riskScoreChange',
        header: 'Change',
        type: 'sign',
        sortingEnabled: true
      },
      {
        name: 'closingPrice',
        header: 'Closing Price',
        type: 'price',
        sortingEnabled: true
      },
      {
        name: 'marketCap',
        header: 'Market Cap (M)',
        type: 'millions',
        sortingEnabled: true
      }
    ]
  },
  {
    key: 'priceMovement',
    hash: 'price-movement',
    title: 'Price Movement',
    filterDefault: 1,
    filters: movementFilters,
    columns: [
      {
        name: 'symbol',
        header: 'Symbol',
        type: 'symbol',
        sortingEnabled: true
      },
      {
        name: 'closingPrice',
        header: 'price',
        type: 'float',
        sortingEnabled: true
      },
      {
        name: 'thirtyDayMean',
        header: '30 Day Mean',
        type: 'float',
        sortingEnabled: true
      },
      {
        name: 'priceChange',
        header: 'Change',
        type: 'sign',
        sortingEnabled: true
      },
      {
        name: 'pricePctChange',
        header: '% Change',
        type: 'sign',
        sortingEnabled: true
      },
      {
        name: 'marketCap',
        header: 'Market Cap (M)',
        type: 'millions',
        sortingEnabled: true
      }
    ]
  },
  {
    key: 'volumeMovement',
    hash: 'volume-movement',
    title: 'Volume Movement',
    filterDefault: 1,
    filters: movementFilters,
    columns: [
      {
        name: 'symbol',
        header: 'Symbol',
        type: 'symbol',
        sortingEnabled: true
      },
      {
        name: 'closingPrice',
        header: 'Closing Price',
        type: 'price',
        sortingEnabled: true
      },
      {
        name: 'volume',
        header: 'Volume',
        type: 'int',
        sortingEnabled: true
      },
      {
        name: 'thirtyDayAdv',
        header: '30 Day Adv',
        type: 'int',
        sortingEnabled: true
      },
      {
        name: 'volumeChange',
        header: 'Change',
        type: 'int',
        sortingEnabled: true
      },
      {
        name: 'volumePctChange',
        header: '% Change',
        type: 'sign',
        sortingEnabled: true
      },
      {
        name: 'marketCap',
        header: 'Market Cap (M)',
        type: 'millions',
        sortingEnabled: true
      }
    ]
  }
]
