import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player/lazy';
import styles from './VideoPlayer.module.scss'
class VideoPlayer extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      ready: false
    }
  }
  render () {
    return (
      <div className={styles.videoContainer}>
        <ReactPlayer
          className={styles.video}
          url={this.props.url}
          width='100%'
          height='100%'
          playing
          controls
        />
      </div>
    )
  }
}

VideoPlayer.propTypes = {
  url: PropTypes.string,
  autoPlay: PropTypes.bool
}

export default VideoPlayer
