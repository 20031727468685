import { Auth } from 'aws-amplify'
import api from '../api'
import { getCookie, setCookie } from '../../utils/cookie'

const COMPUI_COOKIE = 'complianceui.device'
const COOKIE_EXP = 0.416667

export async function signIn (user, pwd) {
  const response = await Auth.signIn(user, pwd)
  return response
}

export async function confirmSignIn (user, code, type) {
  const response = await Auth.confirmSignIn(user, code, type)
  return response
}

export async function setDeviceId (username) {
  const uuid = new Date().getTime()
  const params = {
    username,
    uuid
  }
  await setCookie(COMPUI_COOKIE, uuid, COOKIE_EXP)
  const response = await api.request({
    method: 'post',
    url: '/auth-uuid',
    otcAPI: true,
    nodeAPI: true,
    params
  })
  return response.data
}

export async function getCurrentDevice (params) {
  const response = await api.request({
    method: 'get',
    url: '/auth-uuid',
    otcAPI: true,
    ignoreAuth: true,
    nodeAPI: true,
    params: {
      ...params,
      cookie: getCookie(COMPUI_COOKIE)
    }
  })
  return response.data
}

export async function deleteDevice (params) {
  document.cookie = `${COMPUI_COOKIE}= ; expires = ${new Date()}`
  const response = await api.request({
    method: 'delete',
    url: '/auth-uuid',
    otcAPI: true,
    nodeAPI: true,
    params: {
      ...params,
      cookie: getCookie(COMPUI_COOKIE)
    }
  })
  return response.data
}

export async function getCurrentSession () {
  const response = await Auth.currentSession()
  return response
}

export async function getCurrentUser () {
  const response = await Auth.currentAuthenticatedUser()
  return response
}
export async function completeNewPassword (user, pwd) {
  const response = await Auth.completeNewPassword(user, pwd)
  return response
}

export async function forgotPassword (user) {
  const response = await Auth.forgotPassword(user)
  return response
}

export async function forgotPasswordSubmit (user, code, pwd) {
  const response = Auth.forgotPasswordSubmit(user, code, pwd)
  return response
}

export async function changePassword (user, oldPwd, newPwd) {
  const response = Auth.changePassword(user, oldPwd, newPwd)
  return response
}

export async function signout () {
  const response = Auth.signOut()
  return response
}
