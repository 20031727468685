import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'grid-styled'
import { withTheme } from 'styled-components'
import classNames from 'classnames'
import styles from './Flexgrid.module.scss'

const makeGrid = (elements, gridChildren) => {
  return elements
    .map(el => {
      // shorthand: default to box with id if element is string
      if (typeof el === 'string') {
        return { type: 'box', id: el }
      }
      return el
    })
    .map(({ type, id, subtree, ...props }, i) => {
      // component type map
      const compForType = {
        flex: Flex,
        box: Box,
        main: Flex,
        side: Flex
      }
      // compute children (child by key or subtree)
      let children = null
      if (id) {
        if (gridChildren) {
          children = [gridChildren.find(child => child && child.key === id)]
        }
      } else if (subtree) {
        children = makeGrid(subtree, gridChildren)
      } else {
        children = [gridChildren]
      }

      // make sure we're not throwing an error if children is set to null
      if (!children) children = []

      // add type classes
      const className = classNames(props.className, styles[type])

      // create and return element
      return React.createElement(compForType[type], { key: i, ...props, className }, ...children)
    })
}

export const Flexgrid = ({ layouts, theme: { screen }, className, children, id }) => {
  const finalClassNames = classNames({
    flexgrid: true,
    [className]: !!className
  })
  let layout = layouts[screen.type]
  // if no screen layout found, use default if it exists
  if (!layout) layout = layouts.default
  // if no default layout found, render children as-is
  if (!layout) return children
  const gridContent = makeGrid(layout, children)
  return React.createElement('div', { className: finalClassNames, id }, gridContent)
}

Flexgrid.propTypes = {
  children: PropTypes.node,
  layouts: PropTypes.object.isRequired,
  theme: PropTypes.shape({
    screen: PropTypes.shape({
      type: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  className: PropTypes.string,
  id: PropTypes.string
}

Flexgrid.defaultProps = {
  theme: {
    screen: {
      type: 'lg',
      index: 0
    }
  }
}

export default withTheme(Flexgrid)
