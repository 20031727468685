import React from 'react'
import PropTypes from 'prop-types'
import styles from './RiskScore.module.scss'
import { Link } from 'react-router-dom'
import Outline from '../Outline'
import { Button } from '@otcmarketsgroup/otcm-ui'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

const risks = [
  {
    title: 'Caveat Emptor',
    key: 'caveatEmptorScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'Promotion',
    key: 'promotionScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Hot Sector',
    key: 'hotSectorScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Control Dispute',
    key: 'controlDisputeScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'Prohibited Service Provider',
    key: 'prohibitedServiceProviderScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'Exchange Deficiency',
    key: 'exchangeDeficiencyScore',
    isOTC: false,
    isListed: true
  },
  {
    title: 'Tier Change',
    key: 'otcTierScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'OTC Graduate',
    key: 'otcGraduateScore',
    isOTC: false,
    isListed: true
  },
  {
    title: 'Security Status',
    key: 'securityStatusScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'Penny Stock Status',
    key: 'pennyStockStatusScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Shell Status',
    key: 'shellStatusScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Shell Risk Status',
    key: 'shellRiskStatusScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'Delinquency',
    key: 'delinquencyStatusScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'Bankruptcy',
    key: 'bankruptcyScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'No Foreign Listing',
    key: 'foreignIssuerNoForeignListingScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'Price',
    key: 'priceScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Price Change',
    key: 'priceChangeScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Market Cap',
    key: 'marketCapScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Market Cap Change',
    key: 'marketCapChangeScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'Volume Change',
    key: 'volumeChangeScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Trade Count Change',
    key: 'tradeCountChangeScore',
    isOTC: true,
    isListed: false
  },
  {
    title: 'Company Name Change',
    key: 'companyNameChangeScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Reverse Splits',
    key: 'reverseSplitScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Shares Outstanding Changes',
    key: 'sharesOutstandingChangeScore',
    isOTC: true,
    isListed: true
  },
  {
    title: 'Authorized Shares Changes',
    key: 'authorizedSharesChangeScore',
    isOTC: true,
    isListed: true
  }
]

class RiskScore extends React.PureComponent {
  scrollToAnchor = (anchor) => () => {
    const el = document.getElementById(anchor)
    if (el) {
      const elOffSet = el.offsetTop
      window.scrollTo({ top: elOffSet - 100, behavior: 'smooth' })
    }
  }

  render () {
    const { company, accent, tab, stats, isEquity } = this.props
    return <div className={styles.container}>
      <div>
        <Outline mode='heading' accent={accent}>Risk Score Stats</Outline>
        {risks.map(({ title, key, isOTC, isListed }) => {
          const hash = title.replace(/\s+/g, '-').toLowerCase()
          const showStat = (isEquity && isOTC) || (!isEquity && isListed)
          if (showStat) {
            return <div className={styles.row} key={title}>
              {tab === 'overview' && <div className={styles.link} onClick={this.scrollToAnchor(hash)}>{title}</div>}
              {tab !== 'overview' && <Link to={`/stock/${company}/overview/#${hash}`}>{title}</Link>}
              <span ml='auto'>{stats[key] === undefined ? '0' : stats[key]}</span>
            </div>
          }
        }
        )}

        <Link to={`/stock/${company}/history-risk-score`}>
          <Button title='View Historical Risk Score' className={styles.viewLink} color={accent} fullWidth buttonType={'secondary'} />
        </Link>
      </div>
    </div>
  }
}

RiskScore.propTypes = {
  accent: PropTypes.string,
  company: PropTypes.string.isRequired,
  tab: PropTypes.string,
  stats: PropTypes.object,
  isEquity: PropTypes.bool
}

export default RiskScore
