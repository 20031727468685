import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'grid-styled'

import {
  FaFacebook,
  FaLinkedin
} from 'react-icons/fa'
import FaTwitter from '../../FaTwitter'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

const CompanyQuotePersonProfile = ({ name, desc, picUrl, socialButtons, facebook, twitter, linkedin }) =>
  <div className={styles.item}>
    <Flex w={1} align='center'>
      <Box>
        <p className={styles.content}>{desc}</p>
        {socialButtons && <Box
          flex='1 1 auto'
          justify='flex-end'
          className={styles.socialButtonsContainer}>
          {facebook && <Link to={facebook} className={styles.socialButton}>
            <FaFacebook />
          </Link>}
          {twitter && <Link to={twitter} className={styles.socialButton}>
            <FaTwitter />
          </Link>}
          {linkedin && <Link to={linkedin} className={styles.socialButton}>
            <FaLinkedin />
          </Link>}
        </Box>}
      </Box>
    </Flex>
  </div>

CompanyQuotePersonProfile.propTypes = {
  name: PropTypes.string,
  desc: PropTypes.string,
  picUrl: PropTypes.string,
  socialButtons: PropTypes.bool,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string
}

export default CompanyQuotePersonProfile
