export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return {
        totalRecords: 6879,
        pages: 688,
        currentPage: 2,
        pageSize: 10,
        sortOn: 'receivedDate',
        sortDir: 'DESC',
        records: [
          {
            exchangeId: 3,
            id: 12442130,
            dcn: '0001209191-17-066885',
            formTypeId: 74,
            formType: '4',
            receivedDate: 1513905442000,
            filedDate: 1513832400000,
            periodEndDate: 1513746000000,
            sizeOfFiling: 17797,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: false,
            beenViewed: true,
            paperOnly: false,
            valid: false,
            companyId: 976409,
            companyName: 'ALPHABET INC.',
            companyCik: '0001652044',
            symbol: 'GOOGL',
            tierCode: 'NT'
          },
          {
            exchangeId: 3,
            id: 12441788,
            dcn: '0001193125-17-376656',
            secFileNo: '001-37580',
            formTypeId: 135,
            formType: '8-K',
            receivedDate: 1513894518000,
            filedDate: 1513832400000,
            periodEndDate: 1513573200000,
            sizeOfFiling: 19648,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: true,
            beenViewed: true,
            paperOnly: false,
            valid: false,
            companyId: 976409,
            companyName: 'ALPHABET INC.',
            companyCik: '0001652044',
            symbol: 'GOOGL',
            tierCode: 'NT'
          },
          {
            exchangeId: 3,
            id: 12418135,
            dcn: '0001209191-17-064189',
            formTypeId: 74,
            formType: '4',
            receivedDate: 1512595954000,
            filedDate: 1512536400000,
            periodEndDate: 1512536400000,
            sizeOfFiling: 17799,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: false,
            beenViewed: true,
            paperOnly: false,
            valid: false,
            companyId: 976409,
            companyName: 'ALPHABET INC.',
            companyCik: '0001652044',
            symbol: 'GOOGL',
            tierCode: 'NT'
          },
          {
            exchangeId: 3,
            id: 12412820,
            dcn: '0001209191-17-063435',
            formTypeId: 74,
            formType: '4',
            receivedDate: 1512412466000,
            filedDate: 1512363600000,
            periodEndDate: 1512363600000,
            sizeOfFiling: 6648,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: false,
            beenViewed: true,
            paperOnly: false,
            valid: false,
            companyId: 976409,
            companyName: 'ALPHABET INC.',
            companyCik: '0001652044',
            symbol: 'GOOGL',
            tierCode: 'NT'
          },
          {
            exchangeId: 3,
            id: 12411566,
            dcn: '0001209191-17-063297',
            formTypeId: 74,
            formType: '4',
            receivedDate: 1512170295000,
            filedDate: 1512104400000,
            periodEndDate: 1512018000000,
            sizeOfFiling: 28640,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: false,
            beenViewed: true,
            paperOnly: false,
            valid: false,
            companyId: 976409,
            companyName: 'ALPHABET INC.',
            companyCik: '0001652044',
            symbol: 'GOOGL',
            tierCode: 'NT'
          },
          {
            exchangeId: 3,
            id: 12411554,
            dcn: '0001209191-17-063294',
            formTypeId: 74,
            formType: '4',
            receivedDate: 1512169889000,
            filedDate: 1512104400000,
            periodEndDate: 1512104400000,
            sizeOfFiling: 8783,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: false,
            beenViewed: true,
            paperOnly: false,
            valid: false,
            companyId: 976409,
            companyName: 'ALPHABET INC.',
            companyCik: '0001652044',
            symbol: 'GOOGL',
            tierCode: 'NT'
          },
          {
            exchangeId: 3,
            id: 12405316,
            dcn: '0001209191-17-062808',
            formTypeId: 74,
            formType: '4',
            receivedDate: 1511986314000,
            filedDate: 1511931600000,
            periodEndDate: 1511758800000,
            sizeOfFiling: 22955,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: false,
            beenViewed: true,
            paperOnly: false,
            valid: false,
            companyId: 976409,
            companyName: 'ALPHABET INC.',
            companyCik: '0001652044',
            symbol: 'GOOGL',
            tierCode: 'NT'
          },
          {
            exchangeId: 3,
            id: 12403782,
            dcn: '0001209191-17-062764',
            formTypeId: 74,
            formType: '4',
            receivedDate: 1511919337000,
            filedDate: 1511845200000,
            periodEndDate: 1511845200000,
            sizeOfFiling: 24514,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: false,
            beenViewed: true,
            paperOnly: false,
            valid: false,
            companyId: 976409,
            companyName: 'ALPHABET INC.',
            companyCik: '0001652044',
            symbol: 'GOOGL',
            tierCode: 'NT'
          },
          {
            exchangeId: 3,
            id: 12403748,
            dcn: '0001209191-17-062758',
            formTypeId: 74,
            formType: '4',
            receivedDate: 1511915103000,
            filedDate: 1511845200000,
            periodEndDate: 1511845200000,
            sizeOfFiling: 23313,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: false,
            beenViewed: true,
            paperOnly: false,
            valid: false,
            companyId: 976409,
            companyName: 'ALPHABET INC.',
            companyCik: '0001652044',
            symbol: 'GOOGL',
            tierCode: 'NT'
          },
          {
            exchangeId: 3,
            id: 12403730,
            dcn: '0001209191-17-062749',
            formTypeId: 74,
            formType: '4',
            receivedDate: 1511913455000,
            filedDate: 1511845200000,
            periodEndDate: 1511758800000,
            sizeOfFiling: 9481,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: false,
            beenViewed: true,
            paperOnly: false,
            valid: false,
            companyId: 976409,
            companyName: 'ALPHABET INC.',
            companyCik: '0001652044',
            symbol: 'GOOGL',
            tierCode: 'NT'
          }
        ],
        singular: 'Sec Filing',
        plural: 'Sec Filings'
      }

    case 'OTCM':
      return {
        totalRecords: 0,
        pages: 0,
        currentPage: 2,
        pageSize: 10,
        sortOn: 'receivedDate',
        sortDir: 'DESC',
        records: [],
        singular: 'Sec Filing',
        plural: 'Sec Filings'
      }

    case 'VLKAY':
      return {
        totalRecords: 52,
        pages: 6,
        currentPage: 2,
        pageSize: 10,
        sortOn: 'receivedDate',
        sortDir: 'DESC',
        records: [
          {
            exchangeId: 8,
            id: 3556146,
            dcn: '9999999997-05-013391',
            secFileNo: '082-02188',
            formTypeId: 144,
            formType: 'ARS',
            receivedDate: 1111593316000,
            filedDate: 1111467600000,
            periodEndDate: 1104469200000,
            sizeOfFiling: 1190,
            hasPdf: false,
            hasExcel: false,
            filedAsHtml: false,
            beenViewed: false,
            paperOnly: true,
            valid: true,
            companyId: 107264,
            companyName: 'VOLKSWAGEN A G                                          /FI',
            companyCik: '0000839772',
            symbol: 'VLKAY',
            tierCode: 'PC'
          },
          {
            exchangeId: 8,
            id: 3326196,
            dcn: '9999999997-04-045731',
            secFileNo: '082-02188',
            formTypeId: 667,
            formType: 'SUPPL',
            receivedDate: 1102440777000,
            filedDate: 1100581200000,
            sizeOfFiling: 1178,
            hasPdf: false,
            hasExcel: false,
            filedAsHtml: false,
            beenViewed: false,
            paperOnly: true,
            valid: true,
            companyId: 107264,
            companyName: 'VOLKSWAGEN A G                                          /FI',
            companyCik: '0000839772',
            symbol: 'VLKAY',
            tierCode: 'PC'
          },
          {
            exchangeId: 8,
            id: 3105686,
            dcn: '9999999997-04-032433',
            secFileNo: '082-02188',
            formTypeId: 667,
            formType: 'SUPPL',
            receivedDate: 1091725225000,
            filedDate: 1091678400000,
            sizeOfFiling: 1117,
            hasPdf: false,
            hasExcel: false,
            filedAsHtml: false,
            beenViewed: false,
            paperOnly: true,
            valid: true,
            companyId: 107264,
            companyName: 'VOLKSWAGEN A G                                          /FI',
            companyCik: '0000839772',
            symbol: 'VLKAY',
            tierCode: 'PC'
          },
          {
            exchangeId: 8,
            id: 2965122,
            dcn: '9999999997-04-022367',
            secFileNo: '082-02188',
            formTypeId: 667,
            formType: 'SUPPL',
            receivedDate: 1084551621000,
            filedDate: 1084420800000,
            sizeOfFiling: 1117,
            hasPdf: false,
            hasExcel: false,
            filedAsHtml: false,
            beenViewed: false,
            paperOnly: true,
            valid: true,
            companyId: 107264,
            companyName: 'VOLKSWAGEN A G                                          /FI',
            companyCik: '0000839772',
            symbol: 'VLKAY',
            tierCode: 'PC'
          },
          {
            exchangeId: 8,
            id: 2840168,
            dcn: '9999999997-04-010735',
            secFileNo: '082-02188',
            formTypeId: 144,
            formType: 'ARS',
            receivedDate: 1079633529000,
            filedDate: 1079586000000,
            periodEndDate: 1072846800000,
            sizeOfFiling: 1129,
            hasPdf: false,
            hasExcel: false,
            filedAsHtml: false,
            beenViewed: false,
            paperOnly: true,
            valid: true,
            companyId: 107264,
            companyName: 'VOLKSWAGEN A G                                          /FI',
            companyCik: '0000839772',
            symbol: 'VLKAY',
            tierCode: 'PC'
          },
          {
            exchangeId: 8,
            id: 2664219,
            dcn: '0000950117-03-005475',
            secFileNo: '333-111607',
            formTypeId: 186,
            formType: 'F-6EF',
            receivedDate: 1072795326000,
            filedDate: 1072760400000,
            sizeOfFiling: 21349,
            hasPdf: false,
            hasExcel: true,
            filedAsHtml: false,
            beenViewed: true,
            paperOnly: false,
            valid: true,
            companyId: 107264,
            companyName: 'VOLKSWAGEN A G                                          /FI',
            companyCik: '0000839772',
            symbol: 'VLKAY',
            tierCode: 'PC'
          },
          {
            exchangeId: 8,
            id: 2582681,
            dcn: '9999999997-03-041233',
            secFileNo: '082-02188',
            formTypeId: 667,
            formType: 'SUPPL',
            receivedDate: 1068658220000,
            filedDate: 1068613200000,
            sizeOfFiling: 983,
            hasPdf: false,
            hasExcel: false,
            filedAsHtml: false,
            beenViewed: false,
            paperOnly: true,
            valid: true,
            companyId: 107264,
            companyName: 'VOLKSWAGEN A G                                          /FI',
            companyCik: '0000839772',
            symbol: 'VLKAY',
            tierCode: 'PC'
          },
          {
            exchangeId: 8,
            id: 2433895,
            dcn: '9999999997-03-032194',
            secFileNo: '082-02188',
            formTypeId: 667,
            formType: 'SUPPL',
            receivedDate: 1060698450000,
            filedDate: 1060660800000,
            sizeOfFiling: 983,
            hasPdf: false,
            hasExcel: false,
            filedAsHtml: false,
            beenViewed: false,
            paperOnly: true,
            valid: true,
            companyId: 107264,
            companyName: 'VOLKSWAGEN A G                                          /FI',
            companyCik: '0000839772',
            symbol: 'VLKAY',
            tierCode: 'PC'
          },
          {
            exchangeId: 8,
            id: 2320077,
            dcn: '9999999997-03-022693',
            secFileNo: '082-02188',
            formTypeId: 667,
            formType: 'SUPPL',
            receivedDate: 1054044925000,
            filedDate: 1053576000000,
            sizeOfFiling: 983,
            hasPdf: false,
            hasExcel: false,
            filedAsHtml: false,
            beenViewed: false,
            paperOnly: true,
            valid: true,
            companyId: 107264,
            companyName: 'VOLKSWAGEN A G                                          /FI',
            companyCik: '0000839772',
            symbol: 'VLKAY',
            tierCode: 'PC'
          },
          {
            exchangeId: 8,
            id: 2238917,
            dcn: '9999999997-03-014266',
            secFileNo: '082-02188',
            formTypeId: 144,
            formType: 'ARS',
            receivedDate: 1049314668000,
            filedDate: 1048654800000,
            periodEndDate: 1041310800000,
            sizeOfFiling: 995,
            hasPdf: false,
            hasExcel: false,
            filedAsHtml: false,
            beenViewed: false,
            paperOnly: true,
            valid: true,
            companyId: 107264,
            companyName: 'VOLKSWAGEN A G                                          /FI',
            companyCik: '0000839772',
            symbol: 'VLKAY',
            tierCode: 'PC'
          }
        ],
        singular: 'Sec Filing',
        plural: 'Sec Filings'
      }
  }
}
