export const footerMenu = [
  {
    key: 'contact',
    link: 'Contact',
    to: '/contact'
  },
  {
    key: 'careers',
    link: 'Careers',
    to: '/careers'
  },
  {
    key: 'market-hours',
    link: 'Market Hours',
    to: '/market-hours'
  },
  {
    key: 'glossary',
    link: 'Glossary',
    to: '/glossary'
  }
]
