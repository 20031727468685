import React, { PureComponent } from 'react'
import styles from './DQualifiedFExchangePage.module.scss'
import { getList, downloadList } from '../../api/otc/directory/qualified-fexchange'
import { template, fetch, expand } from '../../api/helper'

import Loading from '../../components/Loading'
import Flexgrid from '../../components/Flexgrid'
import HeadTitle from '../../components/HeadTitle'
import Table from '../../components/Table'
import Outline from '../../components/Outline'
import More from '../../components/More'
import PageTitle from '../../components/PageTitle'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Sticky from '../../components/Sticky'
import OtherDirectories from '../../components/OtherDirectories'
import DirSearch from '../../components/DirSearch'

const columns = [
  {
    name: 'country',
    header: 'Country'
  },
  {
    name: 'name',
    header: 'Stock exchange'
  },
  {
    name: 'tier',
    header: 'Exchange Tier',
    emptyValue: '—'
  },
  {
    name: '',
    header: '',
    component: ({ data }) => { // eslint-disable-line react/prop-types
      if (data.forQx === false && data.forQb === true) return <span className={styles.tierIcon}><img src='/icons/tier/qb.png' /> only</span>
      return null
    }
  }
]

export default class DQualifiedFExchangePage extends PureComponent {
  state = {
    loaded: false,
    list: template('records'),
    selectedLoadedPage: 1,
    searchQuery: null
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = () => {
    fetch(this, getList, 'list', {
      page: 1,
      pageSize: 25,
      search: this.state.searchQuery
    })
  }

  onSearch = searchQuery => {
    this.setState({ searchQuery }, this.loadData)
  }

  get table () {
    const { list } = this.state
    return (
      <div>
        <Outline mode='heading'
          download={() => downloadList()}>
          Qualified Foreign Exchanges
        </Outline>
        <Loading
          type='table'
          data={list.data}
          error={list.error}
          emptyMessage='No exchanges found'
          reloading={list.reloading}
          loaded={list.loaded}>
          <Table
            columns={columns}
            loaded={list.loaded}
            data={list.data.records}
            showHeader
          />
          <TableFooter>
            <More
              onClick={expand(this, getList, 'list')}
              disabled={list.expandEmpty} />
            {list.data.records && list.data.totalRecords &&
              <DisplayResults show={list.data.records.length} total={list.data.totalRecords} text={'Qualified Foreign Exchanges'} />
            }
          </TableFooter>
        </Loading>
      </div>
    )
  }

  render () {
    const layouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['search', 'description', 'table', 'other-directories']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['sticky-column']
            },
            {
              type: 'main',
              mt: '1.8rem',
              subtree: ['table']
            }
          ]
        }
      ]
    }

    const dirSearch = <DirSearch key='search' onSearch={this.onSearch} value={this.state.searchQuery} />

    const description = <div className={styles.description} key='description'>
      Companies listed on a Qualified Foreign Exchange can leverage OTCQX or OTCQB to access an efficient and cost-effective secondary market in the U.S.
    </div>

    const otherDirectories = <div key='other-directories'>
      <OtherDirectories />
    </div>

    return (
      <div className={styles.component}>
        <HeadTitle title='Directory - Qualified Foreign Exchange' />
        <PageTitle>Qualified Foreign Exchange</PageTitle>
        <Flexgrid layouts={layouts}>
          {dirSearch}
          {description}
          <div key='table' className={styles.table}>
            {this.table}
          </div>
          {otherDirectories}
          <Sticky key='sticky-column' className={styles.sideColumn}>
            {dirSearch}
            {description}
            {otherDirectories}
          </Sticky>
        </Flexgrid>
      </div>
    )
  }
}
