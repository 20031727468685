import React from 'react'
import PropTypes from 'prop-types'
import equal from 'fast-deep-equal'
import { LEARN_BLUE_SKY_DATA } from '../../constants/Routes'
import { jurisdictionOptions } from './jurisdictionOptions'
import { template, fetch } from '../../api/helper'
import { getUserManual } from '../../api/otc/company/blue-sky'
import ExternalLink from '../../components/ExternalLink'
import HeadTitle from '../../components/HeadTitle'
import Breadcrumbs from '../../components/Breadcrumbs'
import Select from '../../components/Select'
import Flexgrid from '../../components/Flexgrid'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { Flex } from 'grid-styled'
import { withRouter } from '../../components/WithRouter'
import classNames from 'classnames'
import styles from './BlueSkyRulesPage.module.scss'

const noDataMessage = jurisdiction => <div>
  <p>
    <em>
    We are currently in the process of migrating Blue Sky reference information for {jurisdiction}.  We anticipate the migration being complete by the end of June.
    </em>
  </p>
  <p>
    <em>
    If you have a specific question regarding Blue Sky, please email us at <ExternalLink href='mailto:marketdata@otcmarkets.com'>marketdata@otcmarkets.com</ExternalLink> or call 212.220.2166.
    </em>
  </p>
</div>

const getText = node => {
  let text = ''
  if (node.ranges) {
    node.ranges.forEach(range => {
      text += range.text
    })
  }
  if (node.nodes) {
    node.nodes.forEach(child => {
      text += getText(child)
    })
  }
  return text
}

class BlueSkyRulesPage extends React.PureComponent {
  state = {
    loaded: false,
    list: template(),
    selectedLoadedPage: 1,
    searchQuery: null
  }

  componentDidMount () {
    const hash = window.location.hash
    if (hash && hash.length > 1) {
      const el = document.getElementById(hash.substr(1))
      if (el) {
        setTimeout(() => { el.scrollIntoView(true) }, 10)
      }
    }

    this.loadData()
  }

  componentDidUpdate (prevProps) {
    const { match: { params: prevParams } } = prevProps
    const { match: { params } } = this.props
    if (!equal(params, prevParams)) {
      this.loadData()
    }
  }

  loadData = () => {
    fetch(this, getUserManual, 'list', {
      state: this.props.match.params.jurisdiction
    }).then(data => this.setState({ fullList: data.data.records }))
      .catch(() => this.setState({ fullList: null, error: true }))
  }

  onSelect = option => {
    // this.setState({ searchQuery })
    let location = '/learn/blue-sky-rules'
    if (option) location += '/' + option
    this.props.navigate(location)
  }

  serialize (item, index) {
    switch (item.type) {
      case 'html': return <span key={index} dangerouslySetInnerHTML={{ __html: item.text }} />
      case 'Regular': return <span key={index} className={styles[item.color]} dangerouslySetInnerHTML={{ __html: item.text }} />
      case 'Bold': return <strong key={index} className={styles[item.color]}>{item.text}</strong>
      case 'Italic': return <em key={index} className={styles[item.color]}>{item.text}</em>
      case 'Inline': return item.nodes.map((node, i) => this.serialize(node, i))
      case 'OrderedList': return <ol key={index} type={item.listType} start={item.start} className={classNames({
        [styles.noPadding]: item.noPadding
      })}>
        {item.nodes.map((node, i) => {
          if (node.type === 'OrderedList') return this.serialize(node, i)
          return <li key={i}>{this.serialize(node, i)}</li>
        })}
      </ol>
      case 'Break': return <br key={index} />
    }
  }

  renderContent (content) {
    return content.map((c, i) => <div key={i}>{this.serialize(c, i)}</div>)
  }

  render () {
    const { list } = this.state
    const selectedJurisdictionObj = jurisdictionOptions.find(item => item.value === this.props.match.params.jurisdiction)

    const layouts = (sidebarLocation) => ({
      sidebar: {
        default: [
          {
            type: 'box',
            id: 'sectionTitle'
          },
          {
            type: 'box',
            className: styles.mainContent,
            id: sidebarLocation === 'before' ? 'sidebarContent' : 'mainContent'
          },
          {
            type: 'box',
            id: sidebarLocation === 'before' ? 'mainContent' : 'sidebarContent'
          }
        ],
        lg: [
          {
            type: 'flex',
            subtree: [
              {
                type: 'side',
                className: styles.sidebarContent,
                subtree: ['sectionTitle', 'sidebarContent']
              },
              {
                type: 'main',
                id: 'mainContent'
              }
            ]
          }
        ]
      },
      fullwidth: {
        default: [
          {
            type: 'flex',
            column: true,
            id: 'mainContent'
          }
        ]
      }
    })

    return (
      <div className={styles.component}>
        <Breadcrumbs items={[
          { title: 'Learn', slug: '/learn' },
          { title: 'Blue Sky Rules', slug: LEARN_BLUE_SKY_DATA },
          selectedJurisdictionObj.title]
        } />
        <HeadTitle title='Blue Sky Rules' />
        <PageTitle>Blue Sky Rules</PageTitle>
        <div>
          <Flex className={classNames(styles.topBar, styles.hasSidebar)}>
            <Flex className={styles.selectContainer}>
              {<div className={styles.selectBox}>
                <Select
                  required
                  design='standard'
                  options={jurisdictionOptions}
                  defaultValue={this.props.match.params.jurisdiction}
                  onSelect={this.onSelect} />
              </div>}
            </Flex>
            {list.loaded && <Flex>
              <div className={styles.jumpLinks}>
                {list.data.map((section, i) => {
                  if (section.hideJumpLink) return
                  return <React.Fragment key={i}>
                    {section.linkNewLine && <div key={`new-line${i}`} className={styles.linkNewLine} />}
                    <a key={`jump-link${i}`} href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`} className={classNames(styles.linkItem, {
                      [styles.boldLink]: section.linkBold
                    })}>{section.jumpLinkTitle || section.title}</a>
                  </React.Fragment>
                })}
              </div>
            </Flex>}
          </Flex>
          <div key='table' className={styles.table}>
            <Loading
              data={list.data}
              error={list.error && noDataMessage(selectedJurisdictionObj.title)}
              emptyMessage='No commissions found'
              reloading={list.reloading}
              loaded={list.loaded}>
              {list && list.data && list.data.map((section, i) =>
                <Flexgrid wrap layouts={layouts(section.border, section.sidebarLocation)[section.layout || 'sidebar']} className={classNames(styles.section)} key={i} id={section.anchor}>
                  <div key='sectionTitle' id={section.title.toLowerCase().replace(/\s+/g, '-')} className={classNames(styles.sectionTitle)}>
                    {section.title && <div>{section.title}</div>}
                  </div>
                  <div key='mainContent' className={styles.mainContent}>{this.renderContent(section.nodes)}</div>
                </Flexgrid>
              )}
            </Loading>
          </div>
        </div>
      </div>
    )
  }
}

BlueSkyRulesPage.propTypes = {
  match: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}

export default withRouter(BlueSkyRulesPage)
