import api from '../../api'

export async function getDates () {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'rarray',
    mockData: 'market-activity/reg-sho/dates',
    url: '/internal/market-data/reg-sho/dates',
    params: {
    }
  })
  return response.data
}

export async function getList (params) {
  if (!params.symbol) {
    const response = await api.request({
      method: 'get',
      otcAPI: true,
      responseFormat: 'records',
      url: '/internal/market-data/reg-sho',
      params
    })
    return response.data
  } else {
    const response = await api.request({
      method: 'get',
      otcAPI: true,
      responseFormat: 'records',
      url: `/internal/market-data/reg-sho/${params.symbol}`,
      params
    })
    return response.data
  }
}
