export function request (params) {
  return [
    {
      name: 'KCG Americas LLC',
      mpid: 'NITE',
      dollarVolume: 44098015400.81557,
      shareVolume: 479598506,
      trades: 1269
    },
    {
      name: 'Canaccord Genuity Inc',
      mpid: 'CSTI',
      dollarVolume: 758295442499.0038,
      shareVolume: 153696322,
      trades: 1172
    },
    {
      name: 'Citadel Securities ',
      mpid: 'CDEL',
      dollarVolume: 950237787.3175086,
      shareVolume: 27590321,
      trades: 916
    },
    {
      name: 'Stockcross Financial Services, Inc.',
      mpid: 'STXG',
      dollarVolume: 134114135.58400004,
      shareVolume: 5848185,
      trades: 163
    },
    {
      name: 'Tradelink Securities, LLC',
      mpid: 'TRLN',
      dollarVolume: 3012.2470000000003,
      shareVolume: 39105,
      trades: 8
    }
  ]
}
