import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '../../components/WithRouter'
import { getDNSTierNews } from '../../api/otc/company/news'
import {
  getSnapshot,
  getMostActive,
  getAdvancers,
  getDecliners,
  getMostActiveHome
} from '../../api/otc/market-activity/current-market'
import { getListHome } from '../../api/otc/market-activity/broker-dealer'
import { getPageBySlug } from '../../api/content/pages'
import { getNewlyPromoted, getCurrentRiskStats } from '../../api/otc/compliance/compliance'
import { getRiskScoring } from '../../api/otc/market-activity/compliance-analytics'
import { getDNSNewsUrl } from '../../utils/news-urls'

import { template, fetch, sort } from '../../api/helper'
import styles from './DashboardPage.module.scss'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'

import HeadTitle from '../../components/HeadTitle'
import Flexgrid from '../../components/Flexgrid'
import Outline from '../../components/Outline'
import More from '../../components/More'
import Table from '../../components/Table'
import Loading from '../../components/Loading'
import TableTabs from '../../components/TableTabs'
import KeySecurityOverview from '../../components/KeySecurityOverview'

// Mapping functions to table names
const tableFunctions = {
  active: getMostActive,
  advancers: getAdvancers,
  decliners: getDecliners
}

/** Headers for Table */
const columns = {
  riskScore: [
    {
      name: 'symbol',
      header: 'Symbol',
      type: 'symbol'
    },
    {
      name: 'currentRiskScore',
      header: 'Score'
    },
    {
      name: 'riskScoreChange',
      header: 'Change',
      type: 'sign'
    }
  ],
  newlyPromo: [
    {
      name: 'promoDate',
      header: 'Date',
      type: 'date'
    },
    {
      name: 'symbol',
      header: 'Symbol',
      type: 'symbol'
    },
    {
      name: 'score',
      header: 'Score',
      type: 'int'
    }
  ],
  mostActive: [
    {
      name: 'symbol',
      header: 'Symbol',
      headerShort: 'Sym',
      type: 'symbol'
    },
    {
      name: 'price',
      header: 'Price',
      type: 'price'
    },
    {
      name: 'pctChange',
      header: '% Change',
      type: 'sign'
    },
    {
      key: 'dollarVolume',
      name: 'dollarVolume',
      header: '$ Vol',
      type: 'int',
      hideOnMobile: false
    },
    {
      key: 'volume',
      name: 'shareVolume',
      header: 'Share Vol',
      type: 'int',
      hideOnMobile: true
    },
    {
      key: 'tradeCount',
      name: 'tradeCount',
      header: 'Trades',
      type: 'int',
      hideOnMobile: true
    }
  ],
  news: [
    {
      name: 'symbol',
      header: 'Symbol',
      type: 'symbol'
    },
    {
      name: 'title',
      header: 'Title',
      bold: true,
      linkTo: (item) => getDNSNewsUrl(item.symbol, item)
    }
  ]
}

const allNewsPath = '/market-activity/news'
const viewMostActivePath = '/market-activity/current-market'
const viewKeySecurities = '/market-activity/key-securities'
const viewCompAnalytics = '/market-activity/compliance-analytics'

const riskScoreChangeFilters = [
  {
    title: 'Day',
    value: 'day'
  },
  {
    title: 'Week',
    value: 'week'
  },
  {
    title: 'Month',
    value: 'month'
  }
]

const CarouselArrow = ({ type, onClick }) =>
  <div onClick={onClick}>
    <button className={type === 'previous'
      ? styles.carouselPrevArrow
      : styles.carouselNextArrow}>
      {type === 'previous' ? <FaCaretLeft /> : <FaCaretRight />}
    </button>
  </div>

CarouselArrow.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func
}

class DashboardPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      news: template('records'),
      mostActive: template('records'),
      brokerDealers: template('records'),
      marketSnapshot: template('records'),
      riskScoreChange: template('records'),
      newlyPromoted: template('records'),
      riskStats: template('records'),
      featuredNews: null,
      mainNews: null,
      selectedMarketActivityTab: 'mostActive',
      selectedOverviewTab: 'day',
      gaTrack: {
        quarter: false,
        half: false,
        threeQuarter: false,
        full: false
      },
      active: template('records'),
      advancers: template('records'),
      decliners: template('records'),
      selectedTab: {
        active: 'dollarVolume',
        advancers: '1',
        decliners: '1'
      },
      filters: [{
        type: 'select',
        required: true,
        options: riskScoreChangeFilters,
        defaultValue: riskScoreChangeFilters[1].value,
        onSelect: this.onRiskScoreChangeSelect
      }]
    }
  }

  /**
   * Get table data
   */
  componentDidMount () {
    getPageBySlug('fragments/homepage-news').then(fragment => {
      if (fragment && fragment.sections && fragment.sections.length > 0) {
        const featuredNews = fragment.sections.slice(0, 4)
        const mainNews = fragment.sections.length > 4 ? fragment.sections.slice(4) : null
        this.setState({ featuredNews, mainNews })
      }
    })

    this.loadData()
    // Get market data
    fetch(this, getDNSTierNews, 'news', {
      page: 1,
      pageSize: 5,
      tierGroups: 'ALL'
    })
    fetch(this, getRiskScoring, 'riskScoreChange')
    fetch(this, getNewlyPromoted, 'newlyPromoted')
    fetch(this, getCurrentRiskStats, 'riskStats')
    fetch(this, getMostActiveHome, 'mostActive')
    fetch(this, getSnapshot, 'marketSnapshot')
    fetch(this, getListHome, 'brokerDealers')
  }

  /**
   * Loads the necessary data for Most Active snapshot and table(s).
   * @param {string} priceMin - Filter the query based on minimum price. (Options are 1, 0.05, and 0.01)
   * @param {string} forceTable - Specific table to query the data from.
   */
  loadData = (priceMin, forceTable) => {
    const { match: { params: { market, table, tab } } } = this.props
    let tempTable = table
    if (forceTable) tempTable = forceTable

    const loadTable = (tableName) => {
      this.setState({ [tableName]: template('records') }, () => {
        fetch(this, tableFunctions[tableName], tableName, {
          tierGroup: market,
          page: 1,
          pageSize: table ? 25 : 10,
          priceMin: tempTable === 'active' ? undefined : (priceMin || tab),
          sortOn: tempTable === 'active' ? (this.state.selectedTab[tableName] || tab) : undefined
        })
      })
    }

    // We want to load basic data for all the tables
    // if a table is not selected
    if (!tempTable) {
      for (const key in tableFunctions) {
        loadTable(key)
      }
    } else {
      // A table was selected, so load the specific table data.
      loadTable(tempTable)
    }
  }

  /**
   * When the user selects an item in the top risk score dropdown.
   * Updates the positionDate in local state and calls the API again to get the new data.
   * @param {string} key - The epoch time to query on.
   * @param {string} item - The human readable date.
   */
  onRiskScoreChangeSelect = (key) => {
    fetch(this, getRiskScoring, 'riskScoreChange', {
      filter: key
    })
  }

  /**
   * Generate Tabs for the tables. Initial view.
   * @param {string} table - The table the user has selected.
   */
  getTabsArrayFor (table) {
    let tabs = table === 'active'
      ? [{
          key: 'dollarVolume',
          title: '$ Volume'
        }, {
          key: 'volume',
          title: 'Share Volume'
        }, {
          key: 'tradeCount',
          title: 'Trades'
        }]
      : [{
          key: '1',
          title: 'Over $1'
        }, {
          key: '0.05',
          title: 'Over $0.05'
        }, {
          key: '0',
          title: 'All'
        }]

    tabs = tabs.map((tab) => {
      tab.func = () => {
        this.setState({
          ...this.state,
          selectedTab: {
            ...this.state.selectedTab,
            [table]: tab.key
          }
        }, () => {
          this.loadData(table === 'active' ? null : tab.key, table)
        })
      }
      return tab
    })

    return tabs
  }

  /**
   * Update table columns array to render properly on mobile
   * @param {array} table - array to search
   */

  checkTableColumn = (table) => {
    let tableCols = table.map(x => ({ ...x }))
    tableCols = tableCols.map((col) => {
      col.hideOnMobile = typeof col.hideOnMobile !== 'undefined' && col.key !== this.state.selectedTab.active
      return col
    })

    return tableCols
  }

  videoModalDisplayChange = (open) => {
    if (open) return
    this.setState({ selectedVideo: null })
  }

  render () {
    const { active } = this.state

    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['market-snapshot', 'main-news',
            'market-activity', 'news', 'research', 'advert1',
            'newly-trading', 'subscription']
        }
      ],
      md: [
        {
          type: 'flex',
          column: true,
          subtree: ['market-snapshot', 'main-news', 'market-activity',
            {
              type: 'flex',
              subtree: [
                {
                  type: 'box',
                  width: 1 / 2,
                  className: styles.columnLeft,
                  subtree: ['news', 'newly-trading', 'subscription']
                },
                {
                  type: 'box',
                  width: 1 / 2,
                  className: styles.columnRight,
                  subtree: ['research', 'advert1']
                }
              ]
            }
          ]
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'flex',
              column: true,
              width: 2 / 3,
              className: styles.main,
              subtree: [
                {
                  type: 'flex',
                  subtree: [
                    {
                      type: 'box',
                      width: 1 / 2,
                      className: styles.columnLeft,
                      subtree: ['top-risk-score']
                    },
                    {
                      type: 'box',
                      width: 1 / 2,
                      className: styles.columnRight,
                      subtree: ['top-promoted']
                    }
                  ]
                },
                'market-activity',
                'news'
              ]
            },
            {
              type: 'flex',
              column: true,
              width: 1 / 3,
              className: styles.side,
              subtree: ['key-securities']
            }
          ]
        }
      ]
    }

    return (
      <div className={styles.DashboardPage}>
        <HeadTitle title='OTC Markets Compliance UI' />
        <Flexgrid layouts={layouts}>
          <div key='top-risk-score' className={styles.table}>
            <Outline mode='heading' hideFilterToggle filters={this.state.filters}>Risk Scoring Change</Outline>
            <Loading
              height='12em'
              type='table'
              loaded={this.state.riskScoreChange.loaded}
              data={this.state.riskScoreChange.data}
              error={this.state.riskScoreChange.error}>
              <Table
                columns={columns.riskScore}
                data={this.state.riskScoreChange.data}
                showHeader
              />
              <More to={`${viewCompAnalytics}/#risk-scoring-change`} />
            </Loading>
          </div>
          <div key='top-promoted' className={styles.table}>
            <Outline mode='heading'>Newly Promoted Securities</Outline>
            <Loading
              height='12em'
              type='table'
              loaded={this.state.newlyPromoted.loaded}
              data={this.state.newlyPromoted.data}
              error={this.state.newlyPromoted.error}>
              <Table
                columns={columns.newlyPromo}
                data={this.state.newlyPromoted.data}
                showHeader
              />
              <More to={`${viewKeySecurities}/#active-promotions`} />
            </Loading>
          </div>
          <div key='market-activity' className={styles.table}>
            <Outline mode='heading'>Most Active</Outline>
            <TableTabs
              tabs={this.getTabsArrayFor('active')}
              selectedTab={this.state.selectedTab.active} />
            <Loading
              type='table'
              loaded={active.loaded}
              error={active.error}
              data={active.data.records}>
              <Table
                columns={columns.mostActive}
                data={active.data.records}
                sortColumn={active.sortOn}
                sortDirection={active.sortDir}
                onSorting={sort(this, tableFunctions.active, 'active')}
                showHeader
              />
              <More to={viewMostActivePath} />
            </Loading>
          </div>
          <div key='news' className={styles.table}>
            <Outline mode='heading'>News</Outline>
            <Table
              className={styles.newsTable}
              columns={columns.news}
              data={this.state.news.data}
              largeFont
            />
            <More to={allNewsPath} />
          </div>
          <div key='key-securities'>
            <KeySecurityOverview
              stats={this.state.riskStats.data}
              securities={[
                {
                  title: 'Caveat Emptor',
                  key: 'totalCaveatEmptor',
                  hash: 'caveat-emptor'
                },
                {
                  title: 'Promotions',
                  key: 'totalPromotions',
                  hash: 'active-promotions'
                },
                {
                  title: 'Hot Sectors',
                  key: 'totalHotSectors',
                  hash: 'hot-sectors'
                },
                {
                  title: 'Shell Risk',
                  key: 'totalShellRisk',
                  hash: 'shell-risk'
                },
                {
                  title: 'Shell Status',
                  key: 'totalShellStatus',
                  hash: 'shell'
                },
                {
                  title: 'Bankruptcy',
                  key: 'totalBankruptcy',
                  hash: 'bankruptcy'
                }
              ]}
            />
          </div>
        </Flexgrid>
      </div>
    )
  }
}

DashboardPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      market: PropTypes.string,
      table: PropTypes.number,
      tab: PropTypes.string
    })
  })
}

export default withRouter(DashboardPage)
