import React from 'react'
import styles from './DirSearch.module.scss'
import PropTypes from 'prop-types'
import { FaRegWindowClose, FaSearch } from 'react-icons/fa'

class DirSearch extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = { value: props.value }
  }

  submit = (e) => {
    e.preventDefault()
    if (this.props.onSearch) this.props.onSearch(this.state.value)
  }

  onChange = (e) => {
    let val = e.target.value

    if (val) {
      // trim the value if it's starting with a space
      if (val.substring(0, 1) === ' ') {
        val = val.trim()
      }
    }

    this.setState({ value: val }, () => this.props.onSearch(val))
  }

  checkValue = (val) => {
    if (val === '') this.submit()
  }

  clearField = () => {
    const val = ''
    this.setState({ value: val }, () => this.props.onSearch(val))
  }

  render () {
    return <form className={styles.component} onSubmit={this.submit}>
      <input onChange={this.onChange} placeholder={this.props.placeholder || 'Search'} value={this.state.value || ''} />
      {this.state.value
        ? <FaRegWindowClose className={styles.button} onClick={this.clearField} />
        : <FaSearch />}
    </form>
  }
}

DirSearch.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func
}

export default DirSearch
