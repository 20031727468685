import React from 'react'
import PropTypes from 'prop-types'
import styles from './ContentAccordion.module.scss'
import Accordion from '../Accordion'
import CustomContentItem from '../AccordionItems/CustomContentItem'

const ContentAccordion = ({ children }) => {
  const list = []
  let item = {}
  children && children.forEach((component, index) => {
    if (!component) return
    // handle item content
    if ((index + 1) % 2 === 0) {
      // add content to accordion item
      item.data = { content: component }
      // always show chevron
      item.hasContent = true
      // add complete accordion item to list
      list.push(item)
      // reset accordion item
      item = {}
    // add title to accordion item
    } else item.title = component
  })
  if (list.length === 0) {
    return <div className={styles.noResults}>
      No matches found.
    </div>
  }
  return <Accordion chevron
    titleComponent={({ children }) => <div>{children}</div>}
    component={CustomContentItem}
    list={list}
    className={styles.accordion}
    fullWidth
    columnSplit={1} />
}

ContentAccordion.propTypes = {
  children: PropTypes.node
}

export default ContentAccordion
