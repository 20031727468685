export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return {
        totalRecords: 0,
        pages: 0,
        currentPage: 1,
        pageSize: 20,
        records: []
      }

    case 'OTCM':
      return {
        totalRecords: 3,
        pages: 1,
        currentPage: 1,
        pageSize: 20,
        records: [
          {
            cmdId: 2155,
            compId: 674812,
            title: 'stock screenr',
            description: '',
            type: 'video',
            url: 'https://www.youtube.com/watch?v=cfY7L0xo3Qg',
            publishedDate: 1513555200000,
            publishedDateFormatted: 'Dec 18, 2017',
            expirationDate: 1576627200000,
            tierId: 0
          },
          {
            cmdId: 2154,
            compId: 674812,
            title: 'laura anthony ',
            description: '',
            type: 'video',
            url: 'https://www.youtube.com/watch?v=zOvQN1cMhjM',
            publishedDate: 1513555200000,
            publishedDateFormatted: 'Dec 18, 2017',
            expirationDate: 1576627200000,
            tierId: 0
          },
          {
            cmdId: 1361,
            compId: 674812,
            title: 'SNNLive Update with OTC Markets Group',
            description: 'SNNLive caught up with Bea Ordonez, CFO of OTC Markets Group, Inc. (OTCQX: OTCM)',
            type: 'video',
            url: 'https://www.youtube.com/watch?v=Gzgi-6tJRIs',
            publishedDate: 1460419200000,
            publishedDateFormatted: 'Apr 12, 2016',
            expirationDate: 1523491200000,
            tierId: 0
          }
        ]
      }

    case 'VLKAY':
      return {
        totalRecords: 0,
        pages: 0,
        currentPage: 1,
        pageSize: 20,
        records: []
      }
  }
}
