import api from '../../api'

export async function getFilters (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    url: '/corp-actions/filters',
    params
  })
  return response.data
}

/**
 * Covers:
 * GET /internal/corp-actions/ce-changes
 * GET /internal/corp-actions/deletions
 * GET /internal/corp-actions/ex-dividends
 * GET /internal/corp-actions/halts
 * GET /internal/corp-actions/name-changes
 * GET /corp-actions/proprietary-quote-eligible
 * GET /internal/corp-actions/sch-dividends
 * GET /internal/corp-actions/splits
 * GET /internal/corp-actions/symbol-changes
 * GET /internal/corp-actions/symbol-name-changes
 * GET /internal/corp-actions/tier-changes
 * GET /internal/corp-actions/venue-changes
 */
export async function getData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    url: `/internal/corp-actions/${params.route}`,
    params
  })
  return response.data
}

export async function getCompanyData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    url: '/company/corp-actions',
    params
  })
  // If symbol is missing, add it
  response.data && response.data.records && response.data.records.forEach(item => {
    if (item && !item.symbol) item.symbol = params.symbol
  })

  return response.data
}
