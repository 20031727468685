import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { withRouter } from '../../components/WithRouter'
import styles from './HistoryRiskScore.module.scss'
import { getStatsHistory, downloadStatsHistory } from '../../api/otc/compliance/stats'
import { getMiniCompanyProfile } from '../../api/otc/company/profile'
import { template, fetch, expand, sort } from '../../api/helper'

import HeadTitle from '../../components/HeadTitle'
import Table from '../../components/Table'
import More from '../../components/More'
import Outline from '../../components/Outline'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import { withContext } from '../../AuthContext'
import { OTC_ANALYTICS, SMALL_CAP_LISTED } from '../../constants/groups'

const columns = [
  {
    name: 'scoreDate',
    header: 'Date',
    sortingEnabled: true,
    type: 'date'
  },
  {
    name: 'totalScore',
    header: 'Total',
    align: 'right',
    sortingEnabled: true
  },
  {
    name: 'publicInterestScore',
    header: 'Public Interest Concern',
    align: 'right',
    sortingEnabled: true
  },
  {
    name: 'issuerStatusScore',
    header: 'Security/Issuer Status',
    align: 'right',
    sortingEnabled: true
  },
  {
    name: 'tradingActivityScore',
    header: 'Trading Activity',
    align: 'right',
    sortingEnabled: true
  },
  {
    name: 'corpActionsScore',
    header: 'corp actions',
    align: 'right',
    sortingEnabled: true
  },
  {
    name: 'issuanceScore',
    header: 'issuance/dilution',
    align: 'right',
    sortingEnabled: true
  }
]

const initialState = {
  company: template('records'),
  historyStats: template('records'),
  selectedLoadedPage: 1,
  searchQuery: null,
  filters: {
    history: {
      fromDate: null,
      endDate: null
    }
  }
}

class HistoryRiskScore extends PureComponent {
  state = initialState

  componentDidMount () {
    this.loadData()

    // If anchor is supplied in hash, scroll into view
    const hash = window.location.hash
    if (hash && hash.length > 1) {
      const el = document.getElementById(hash.substr(1))
      if (el) {
        setTimeout(() => { el.scrollIntoView(true) }, 100)
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.company.loaded !== this.state.company.loaded && this.state.company.loaded) {
      const user = this.props.context.user
      const isOtcAnalyticsEntitled = user && user.userGroups && user.userGroups.find(group => (group === OTC_ANALYTICS))
      const isSmallCapEntitled = user && user.userGroups && user.userGroups.find(group => (group === SMALL_CAP_LISTED))
      const isEquity = this.state.company.data.tierGroup
      const isListed = !this.state.company.data.tierGroup
      const isRiskScoreEligible = (isEquity && isOtcAnalyticsEntitled) || (isListed && isSmallCapEntitled)

      this.setState({
        isRiskScoreEligible
      })

      if (isRiskScoreEligible) {
        this.loadStats()
      }
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const currentSymbol = this.props.match.params.company
    const nextSymbol = nextProps.match.params.company
    if (nextSymbol !== currentSymbol) {
      // reset state before loading
      this.setState({ ...initialState }, () => {
        this.loadData(nextSymbol)
      })
    }
  }

  loadData = () => {
    fetch(this, getMiniCompanyProfile, 'company', this.props.match.params.company)
  }

  loadStats = () => {
    fetch(this, getStatsHistory, 'historyStats',
      {
        symbol: this.props.match.params.company,
        fromDate: this.state.filters.history.fromDate,
        endDate: this.state.filters.history.endDate,
        page: 1
      }
    )
  }

  onSearch = searchQuery => {
    this.setState({ searchQuery }, this.loadData)
  }

  dateSelect = (type, field) => {
    return (date) => {
      const filters = this.state.filters

      if (type && field) {
        filters[type][field] = date && moment(date).format('MM/DD/YYYY')
      }

      this.setState({ filters }, this.loadStats())
    }
  }

  render () {
    const { filters, historyStats } = this.state
    const isListed = !this.state.company.data.tierGroup
    const listedMinDate = '09/01/2020'

    return (
      <div className={styles.component}>
        <HeadTitle title='Historical Risk Score' />
        <h2>Historical Risk Score</h2>
        <Outline className={classNames(styles.menu, {
          [styles.green]: this.state.company.data.tierGroup === 'QX',
          [styles.orange]: this.state.company.data.tierGroup === 'DQ',
          [styles.pink]: this.state.company.data.tierGroup === 'PS',
          [styles.gray]: this.state.company.data.tierGroup === 'OO'
        })} mode='text' download={() => downloadStatsHistory(this.state.company.options.params, this.state.filters.history)} filters={[{
          type: 'date',
          placeholderText: 'From',
          maxDate: moment(filters.history.endDate) || moment(),
          minDate: isListed && listedMinDate,
          onChange: this.dateSelect('history', 'fromDate')
        }, {
          type: 'date',
          placeholderText: 'To',
          minDate: filters.history.fromDate ? moment(filters.history.fromDate) : isListed && listedMinDate,
          maxDate: moment(),
          onChange: this.dateSelect('history', 'endDate')
        }]}>{this.state.company.data.name}</Outline>
        {this.state.isRiskScoreEligible &&
        <div className={styles.table}>
          <Loading
            type='table'
            data={historyStats.data}
            error={historyStats.error}
            reloading={historyStats.reloading}
            loaded={historyStats.loaded}>
            <Table
              columns={columns}
              loaded={historyStats.loaded}
              data={historyStats.data}
              sortColumn={historyStats.sortOn}
              sortDirection={historyStats.sortDir}
              onSorting={sort(this, getStatsHistory, 'historyStats')}
              showHeader
            />
            <TableFooter>
              <More
                onClick={expand(this, getStatsHistory, 'historyStats')}
                disabled={historyStats.expandEmpty} />
              {historyStats.data.records && historyStats.data.totalRecords &&
                <DisplayResults show={historyStats.data.records.length} total={historyStats.data.totalRecords} text={'Risk Scores'} />
              }
            </TableFooter>
          </Loading>
        </div>}
      </div>
    )
  }
}
HistoryRiskScore.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      company: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  context: PropTypes.object,
  info: PropTypes.object
}

export default withRouter(withContext(HistoryRiskScore))
