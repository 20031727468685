import api from '../../api'
import getConfig from '../../../config'
const { QARAVAN_API_URI } = getConfig()

export async function getCorpStructure (bankId) {
  const response = await api.request({
    method: 'get',
    url: `${QARAVAN_API_URI}/structure/${bankId}/chart`
  })
  return response.data
}

export async function getQuarterlyCallReports (params) {
  const response = await api.request({
    method: 'get',
    responseFormat: 'records',
    url: `${QARAVAN_API_URI}/call-report/external/reported-periods?rssdId=${params.bankId}`
  })
  return response.data
}

export async function getFRY9Reports (params) {
  const response = await api.request({
    method: 'get',
    responseFormat: 'records',
    url: `${QARAVAN_API_URI}/fr-y9/external/reports?rssdId=${params.traderRssdId}`
  })
  return response.data
}

export async function getFRY6Reports (params) {
  const response = await api.request({
    method: 'get',
    responseFormat: 'records',
    url: `${QARAVAN_API_URI}/fr-y6/external/reports?rssdId=${params.traderRssdId}`
  })
  return response.data
}

export async function getFDICReports (params) {
  const pageSize = params.pageSize || 10
  const page = params.page || 1
  const offset = (page - 1) * pageSize
  const response = await api.request({
    method: 'get',
    responseFormat: 'records',
    url: `${QARAVAN_API_URI}/efr/external/list?rssdId=${params.traderRssdId}&offset=${offset}&limit=${pageSize}`
  })
  return response.data
}