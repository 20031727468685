import api from '../../api'

export async function getMedia (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    responseFormat: 'records',
    mockData: 'company/media/media',
    url: '/media/list',
    params
  })
  return response.data
}

export async function getMediaDetails (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    responseFormat: 'object',
    url: '/media',
    params
  })
  return response.data
}
