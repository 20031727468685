import React from 'react'
import PropTypes from 'prop-types'
import styles from './TableFilter.module.scss'
import { FaFilter } from 'react-icons/fa'
import classNames from 'classnames'
import Select from '../Select'
import { DateSelector } from '../DateSelector'

class TableFilter extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      showFilters: true
    }
  }

  render () {
    const style = {
      gridTemplateColumns: `repeat(${this.props.filters.length}, 1fr)`,
      gridGap: '2em'
    }

    return (
      <div className={styles.TableFilter}>
        {!this.props.hideFilterToggle && <div className={styles.filterToggle} onClick={() => { this.setState({ ...this.state, showFilters: !this.state.showFilters }) }}>
          <FaFilter /> Filter
        </div>}
        <div key='filters' className={classNames({ [styles.tableFilters]: true, [styles.showFilters]: this.state.showFilters })} style={style}>
          {this.props.filters &&
            this.props.filters.length &&
            this.props.filters.map((filter, i) => {
              const { type, className, ...props } = filter
              const classes = classNames(styles.filterItem, className)
              switch (type) {
                case 'select':
                  return <Select key={filter.title || i} className={classes} design='filter' {...props} />
                case 'date':
                  return <DateSelector key={filter.title || i} className={classes} design='standard' {...props} isClearable />
              }
            })}
        </div>
      </div>
    )
  }
}

TableFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['select', 'date']),
    title: PropTypes.string,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.oneOfType([
      /* string */
      PropTypes.string,
      /* complex object with title/value etc. */
      PropTypes.shape({
        /** Title/key of the item */
        title: PropTypes.string.isRequired,
        /** Item value */
        value: PropTypes.string.isRequired,
        /** Nested items (creates a submenu) */
        nested: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        }))
      })
    ])),
    className: PropTypes.string
  })),
  hideFilterToggle: PropTypes.bool
}

export default TableFilter
