import React, { PureComponent } from 'react'
import styles from './DServiceProviderPage.module.scss'
import { template, fetch, expand, sort } from '../../api/helper'
import * as Routes from '../../constants/Routes'
import { format } from '../../utils/locale'
import { getServiceProvider, getTypes, downloadServiceProvider } from '../../api/otc/directory/service-provider'
import { withRouter } from '../../components/WithRouter'
import FilterPick from '../../components/FilterPick'
import HeadTitle from '../../components/HeadTitle'
import Flexgrid from '../../components/Flexgrid'
import Table from '../../components/Table'
import Outline from '../../components/Outline'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import More from '../../components/More'
import Sticky from '../../components/Sticky'
import OtherDirectories from '../../components/OtherDirectories'
import DirSearch from '../../components/DirSearch'

const getServiceProviderLink = data => `${Routes.DIR_SERVICE_PROVIDER}/${data.id}?t=${data.typeId}`

const columns = [
  {
    name: 'name',
    header: 'Service Provider',
    linkTo: (item) => getServiceProviderLink(item)
  },
  {
    name: 'phone',
    header: 'Phone number',
    hideOnTablet: true,
    hideOnMobile: true
  },
  {
    name: 'website',
    header: 'Website',
    type: 'url',
    hideOnTablet: true,
    hideOnMobile: true
  },
  {
    name: 'city',
    header: 'Location'
  },
  {
    name: 'country',
    header: 'Country'
  },
  {
    name: 'activeCompanies',
    header: 'No. Active Companies',
    hideOnMobile: true
  }
]

const serviceProviderTypeolumn =
  {
    name: 'typeDesc',
    header: 'Type'
  }

const getOptionsFromTypes = types => types.map(type => ({
  id: type.id,
  label: `${type.caption} (${format(type.count, '0', 'int')})`,
  caption: type.caption
}))

export class DServiceProviderPage extends PureComponent {
  state = {
    selectedType: null,
    searchQuery: null,
    list: template('records'),
    types: {
      loaded: false,
      data: [],
      error: null
    }
  }

  loadTypes = () => {
    getTypes().then(data => this.handleLoadTypes(data))
  }

  handleLoadTypes = data => {
    this.setState({
      types: {
        loaded: true,
        data,
        error: null
      }
    }, () => {
      if (!data.length) return
      const firstType = getOptionsFromTypes(data)[0]
      this.handleSelectType(firstType)
    })
  }

  loadData = () => {
    fetch(this, getServiceProvider, 'list', {
      page: 1,
      pageSize: 25,
      typeId: this.state.selectedType && this.state.selectedType.id,
      search: this.state.searchQuery
    })
  }

  handleSelectType = selectedType => {
    this.setState({ selectedType }, this.loadData)
  }

  onSearch = searchQuery => {
    this.setState({ searchQuery }, this.loadData)
  }

  componentDidMount () {
    this.loadTypes()
  }

  render () {
    const { selectedType, list, types } = this.state
    // Add Type column to Table when all Service Provider is selected
    const tableColumns = selectedType && selectedType.id === '0' ? [columns[0], serviceProviderTypeolumn, ...columns.slice(1)] : columns

    const layouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['search', 'type-select', 'table', 'other-directories']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['sticky-column']
            },
            {
              type: 'main',
              mt: '1.8rem',
              subtree: ['table']
            }
          ]
        }
      ]
    }

    const otherDirectories = <div key='other-directories' className={styles.otherDirectories}>
      <OtherDirectories />
    </div>

    const dirSearch = <DirSearch key='search' onSearch={this.onSearch} value={this.state.searchQuery} />

    const typeSelect = <div key='type-select' className={styles.typeSelect}>
      <FilterPick
        isLoading={!types.loaded}
        onChange={this.handleSelectType}
        value={selectedType}
        header='Service Provider Type'
        options={getOptionsFromTypes(types.data)} />
    </div>

    return (
      <div className={styles.component}>
        <HeadTitle title='Directory - Service Provider Directory' />
        <PageTitle>Service Provider Directory</PageTitle>
        <Flexgrid layouts={layouts}>
          {dirSearch}
          {typeSelect}
          <div key='table' className={styles.table}>
            <Outline mode='heading'
              content={<div className={styles.headingCount}>{list.data.totalRecords} </div>}
              download={() => downloadServiceProvider()}>
              {selectedType ? selectedType.caption : <span>&nbsp;</span>}
            </Outline>
            <Loading type='table'
              data={list.data}
              error={list.error}
              reloading={list.reloading}
              loaded={list.loaded}>
              <Table
                columns={tableColumns}
                loaded={list.loaded}
                data={list.data.records}
                sortColumn={list.sortOn}
                sortDirection={list.sortDir}
                onSorting={sort(this, getServiceProvider, 'list')}
                showHeader
              />
              <More
                onClick={expand(this, getServiceProvider, 'list')}
                disabled={list.expandEmpty} />
            </Loading>
          </div>
          <Sticky key='sticky-column' className={styles.sideColumn}>
            {dirSearch}
            {typeSelect}
            {otherDirectories}
          </Sticky>
          {otherDirectories}
        </Flexgrid>
      </div>
    )
  }
}

export default withRouter(DServiceProviderPage)
