import React from 'react'
import PropTypes from 'prop-types'
import styles from './Header.module.scss'
import classNames from 'classnames'
import { withContext } from '../../AuthContext'
import { withRouter } from '../WithRouter'
import getConfig from '../../config'
import { Link } from 'react-router-dom'
import { Flex, Box } from 'grid-styled'
import { LINKS, HEADER_NAV_ACTION } from '../../constants/GoogleAnalytics'
import { logEvent } from '../../analytics'
import { Button } from '@otcmarketsgroup/otcm-ui'
import { headerLinks } from '../../menus'
import { search } from '../../utils/auto-suggest'
import Hide from '../Hide'
import ExternalLink from '../ExternalLink'
import MenuLink from '../MenuLink'
import { getAppVersion } from '../../api/content/pages'

const { REACT_ENV } = getConfig()

class Header extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      menuOpen: false,
      searchOpen: false,
      searchQuery: null,
      symbolsLoaded: false,
      results: [],
      appVersion: undefined
    }
  }

  componentDidMount () {
    if (REACT_ENV && REACT_ENV !== 'prod') {
      getAppVersion().then(versionObj => {
        this.setState({ appVersion: versionObj.version })
      })
    }
  }

  gotoLogin = () => {
    const path = '/login'
    this.props.navigate(path)
    logEvent(LINKS, HEADER_NAV_ACTION, path)
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  openSearch = () => {
    this.setState({ searchOpen: true, searchQuery: '', results: [] })
    if (this.searchInput) this.searchInput.focus()
  }

  closeSearch = () => {
    this.setState({ searchOpen: false, results: [] })
  }

  clearSearch = () => {
    this.setState({ searchOpen: false, searchQuery: '', results: [] })
  }

  changeSearchQuery = (e) => {
    let value = e.target.value
    this.setState({ searchQuery: value })

    // display an error if there's an empty value for a required field.
    if (value) {
      // trim the value if it's starting with a space
      if (value.substring(0, 1) === ' ') {
        value = value.trim()
      }
    }

    if (value === '' || !value) {
      this.setState(state => ({
        hidden: true,
        value: null,
        results: []
      }))
      return
    }
    this.setState(state => ({
      hidden: false,
      value,
      results: search(state.searchObject, value, true, 10)
    }))
  }

  search = (e) => {
    let value = this.state.searchQuery

    e.preventDefault()

    if (!value) return
    value = value.trim()

    if (value !== '') {
      this.props.navigate(`/search?q=${value}`)
    }
    this.setState({ results: [] })
  }

  render () {
    const { context } = this.props
    const logoUrl = REACT_ENV === 'prod' ? 'https://s3.amazonaws.com/assets.otcmarkets.com/compliance/canari-logo.png' : 'https://s3.amazonaws.com/assets.compliance.stage.otc/images/canari-logo.png'

    return (
      <div className={styles.headerLineContainer} id='site_header'>
        <div className={styles.header}>
          <Flex column align='stretch' className={styles.container}>
            <Flex align={context.isAuth ? 'flex-end' : 'center'} className={classNames(styles.headerTop, { [styles.searchOpen]: this.state.searchOpen })}>
              <Flex w={[1 / 3]}>
                <Box className={styles.logo}>
                  <Link to='/'>
                    <img src={logoUrl} alt='logo' />
                  </Link>
                  {(this.state.appVersion) && <span className={styles.env}>{` [ ${REACT_ENV} - ${this.state.appVersion} ] `}</span>}
                </Box>
              </Flex>
              {
                context.isAuth
                  ? <Flex w={[2 / 3]} justify='flex-end'>
                  <Hide>
                    <div className={styles.topRightLinks}>
                      {headerLinks.map(({ link, to, href, sublinks }, i) =>
                        <Box key={i} className={styles.headerItem}>
                          <MenuLink text={link} to={to} href={href} sublinks={sublinks} />
                        </Box>
                      )}
                    </div>
                  </Hide>
                </Flex>
                  : <Flex w={[2 / 3]} justify='flex-end' align='center' className={classNames(styles.topRightLinks, styles.loggedoutMenu)}>
                  <Box>
                    <ExternalLink to='/contact' className={styles.textLink} ga={{ category: LINKS, action: HEADER_NAV_ACTION }}>
                      Need an account?
                    </ExternalLink>
                  </Box>
                  <Box>
                    <Button to='/login' onClick={this.gotoLogin} title='Log in' className={styles.loginCta} />
                  </Box>
                </Flex>
              }
            </Flex>
          </Flex>
        </div>
        {/* <div className={styles.otcLine} /> */}
      </div>
    )
  }
}

Header.propTypes = {
  navigate: PropTypes.func,
  context: PropTypes.object
}

export default withRouter(withContext(Header))
