import React, { Component } from 'react'
import styles from './BackToTop.module.scss'
import { FaAngleUp } from 'react-icons/fa'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default class BackToTop extends Component {
  state = {
    windowHeight: 0,
    isVisible: false,
    isLocked: false
  }

  ticking = false

  lastScrollPosition = null

  handleScroll = () => {
    this.requestTick()
  }

  requestTick = () => {
    if (!this.ticking) {
      window.requestAnimationFrame(this.updateScroll)
    }
    this.ticking = true
  }

  updateScroll = () => {
    this.ticking = false
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const distanceTillBottom = -((scrollTop + this.state.windowHeight) - document.body.scrollHeight)
    const isLocked = this.component && this.footer && distanceTillBottom <= (this.footer.clientHeight - this.component.clientHeight)
    const hasScrolledDown = scrollTop > this.lastScrollPosition
    this.setState({ isLocked, isVisible: hasScrolledDown })
    this.lastScrollPosition = scrollTop
  }

  componentDidMount () {
    this.footer = document.getElementById(this.props.footerId)
    this.updateWindowHeight()
    window.addEventListener('resize', this.updateWindowHeight)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowHeight)
    window.removeEventListener('scroll', this.handleScroll)
    clearTimeout(this.resizeTimer)
  }

  updateWindowHeight = () => {
    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.setState(state => ({
        windowHeight: window.innerHeight
      }))
    }, 100)
  }

  scrollToTop = () => {
    document.body.scrollTop = 0 // For Chrome, Safari and Opera
    document.documentElement.scrollTop = 0 // For IE and Firefox
  }

  render () {
    if (document.body.scrollHeight < this.state.windowHeight * 1.5) {
      return null
    }

    const { isVisible, isLocked } = this.state

    return (
      <div
        ref={el => { this.component = el }}
        title='Scroll to top of page'
        className={classNames(styles.component, {
          [styles.isVisible]: isVisible,
          [styles.isLocked]: isLocked
        })}
        onTouchStart={this.scrollToTop}
        onClick={this.scrollToTop}>
        <FaAngleUp className={styles.icon} />
      </div>
    )
  }
}

BackToTop.propTypes = {
  footerId: PropTypes.string
}
