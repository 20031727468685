export function request (params, pagination) {
  return `
    <ul type=disc style="MARGIN-TOP: 0in">\n
        <li class=MsoNormalCxSpFirst style="COLOR: black; MARGIN: auto auto 0pt; LINE-HEIGHT: normal; mso-add-space: auto; mso-list: l0 level1 lfo1">
            <span  style="font-family: Times New Roman;">
                <strong style="mso-bidi-font-weight: normal">
                    <span lang=EN-GB style="FONT-SIZE: 12pt; COLOR: windowtext; mso-bidi-font-size: 11.0pt">No unlawful change to the stated fuel consumption and</span>
                </strong>
                <strong style="mso-bidi-font-weight: normal">
                    <span lang=EN-GB style="FONT-SIZE: 12pt; mso-bidi-font-size: 11.0pt"> CO
                        <SUB>2</SUB> figures found to date
                    </span>
                </strong>
                <strong style="mso-bidi-font-weight: normal">
                    <span lang=EN-GB style='FONT-SIZE: 12pt; mso-fareast-font-family: "VW Headline OT-Book"; mso-bidi-font-family: Arial'>
                        <?xml:namespace prefix = "o" ns = "urn:schemas-microsoft-com:office:office" />
                        <o:p></o:p>
                    </span>
                </strong>
            </span>
        </li>\n
        <li class=MsoNormalCxSpMiddle style="COLOR: black; MARGIN: auto auto 0pt; LINE-HEIGHT: normal; mso-add-space: auto; mso-list: l0 level1 lfo1">
            <span  style="font-family: Times New Roman;">
                <strong style="mso-bidi-font-weight: normal">
                    <span lang=EN-GB style="FONT-SIZE: 12pt; mso-bidi-font-size: 11.0pt">Only a small number of the model variants of new cars will have the catalogue figure slightly adjusted </span>
                </strong>
                <strong style="mso-bidi-font-weight: normal">
                    <span lang=EN-GB style='FONT-SIZE: 12pt; mso-fareast-font-family: "VW Headline OT-Book"; mso-bidi-font-family: Arial'>
                        <o:p></o:p>
                    </span>
                </strong>
            </span>
        </li>\n
    </ul>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <span lang=EN-GB style='FONT-FAMILY: "Calibri", sans-serif; COLOR: black; mso-fareast-font-family: "VW Headline OT-Book"'>
            <o:p>
                <font size=3>&nbsp;</font>
            </o:p>
        </span>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <strong style="mso-bidi-font-weight: normal">
            <span lang=EN-GB style="COLOR: black">
                <font size=3>Wolfsburg, 9 December 2015  Just a month after questions relating to the CO</font>
                <SUB>
                    <font size=2>2</font>
                </SUB>
                <font size=3> figures measured on some of the Group's models arose, Volkswagen has largely concluded the clarification of the matter. Following extensive internal investigations and measurement checks, it is now clear that almost all of these model variants do correspond to the CO2 figures originally determined. This means that these vehicles can be marketed and sold without any limitations. The suspicion that the fuel consumption figures of current production vehicles had been unlawfully changed was not confirmed. During internal remeasurements slight deviations were found on just nine model variants of the Volkswagen brand.
                    <o:p></o:p>
                </font>
            </span>
        </strong>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <strong style="mso-bidi-font-weight: normal">
            <span lang=EN-GB style="COLOR: black">
                <o:p>
                    <font size=3>&nbsp;</font>
                </o:p>
            </span>
        </strong>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <font size=3>
            <span lang=EN-GB style="COLOR: black">These model variants will be remeasured by a neutral technical service under the supervision of the appropriate authority by Christmas. In cases where the correctness of original figures is confirmed, there will be no consequences. These cars can be offered for sale by dealers without any reservations. In the case of any deviations, the figures will be adjusted in the future in the course of the normal processes as required. </span>
            <span lang=EN-GB style="COLOR: black; mso-bidi-font-family: Arial">
                <o:p></o:p>
            </span>
        </font>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <strong style="mso-bidi-font-weight: normal">
            <span lang=EN-GB style="COLOR: black; mso-bidi-font-family: Arial">
                <o:p>
                    <font size=3>&nbsp;</font>
                </o:p>
            </span>
        </strong>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <span lang=EN-GB style="COLOR: black">
            <font size=3>Volkswagen presented these results to the investigation commission of the Federal Government and the Federal Motor Transport Authority (KBA). The figure of approximately 800,000 vehicles under suspicion originally published by the Volkswagen Group has not been confirmed. The deviations found in the figures for only nine model variants amount to a few grams of CO</font>
            <SUB>
                <font size=2>2</font>
            </SUB>
            <font size=3> on average, corresponding to increased cycle consumption in the NEDC of approximately 0.1 to 0.2 litres per 100 kilometres. With an annual production of approximately 36,000 vehicles, these model variants correspond to around only 0.5 per cent of the volume of the Volkswagen brand. The list of the nine model variants can be found at </font>
            <A href="http://www.volkswagen-media-services.com/">
                <font size=3>www.volkswagen-media-services.com</font>
            </A>
            <o:p></o:p>
        </span>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <span lang=EN-GB style="COLOR: black; mso-bidi-font-family: Arial">
            <o:p>
                <font size=3>&nbsp;</font>
            </o:p>
        </span>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <font size=3>
            <span lang=EN-GB style="COLOR: black">The Group's subsidiaries Audi, SKODA and SEAT have also agreed a similar procedure with the approval authorities responsible for the vehicles initially considered.</span>
            <span lang=EN-GB style="COLOR: black; mso-bidi-font-family: Arial">
                <o:p></o:p>
            </span>
        </font>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <span lang=EN-GB style="mso-bidi-font-family: Arial">
            <o:p>
                <font size=3>&nbsp;</font>
            </o:p>
        </span>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <font size=3>
            <span lang=EN-GB>Customers' real-world consumption figures do not change and neither are any technical vehicle modifications necessary. Against this background, the negative impact on earnings of 2 billion that was originally expected has not been confirmed. </span>
            <span lang=EN-GB style="mso-bidi-font-family: Arial">
                <o:p></o:p>
            </span>
        </font>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <span lang=EN-GB style="mso-bidi-font-family: Arial">
            <o:p>
                <font size=3>&nbsp;</font>
            </o:p>
        </span>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 13.5pt">
        <span style="mso-ansi-language: EN-US">
            <font size=3>Whether we will have a minor economic impact, depends on the results of the remeasurement exercise.
                <o:p></o:p>
            </font>
        </span>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <span style='mso-fareast-font-family: "VW Headline OT-Book"; mso-bidi-font-family: Arial; mso-ansi-language: EN-US'>
            <o:p>
                <font size=3>&nbsp;</font>
            </o:p>
        </span>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <span lang=EN-GB style='mso-fareast-font-family: "VW Headline OT-Book"; mso-bidi-font-family: Arial'>
            <o:p>
                <font size=3>&nbsp;</font>
            </o:p>
        </span>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 0pt; LINE-HEIGHT: normal">
        <span lang=EN-GB style='mso-fareast-font-family: "VW Headline OT-Book"; mso-bidi-font-family: Arial'>
            <o:p>
                <font size=3>&nbsp;</font>
            </o:p>
        </span>
    </P>\n
    <P class=MsoNormal style="MARGIN: 0in 0in 13.5pt">
        <strong style="mso-bidi-font-weight: normal">
            <em style="mso-bidi-font-style: normal">
                <span lang=EN-GB>
                    <font size=3>Please note:</font>
                </span>
            </em>
        </strong>
        <em style="mso-bidi-font-style: normal">
            <span lang=EN-GB>
                <font size=3> This text and the overview of vehicles to be adjusted in future with new CO</font>
                <SUB>
                    <font size=2>2</font>
                </SUB>
                <font size=3> figures, model year 2016, can be found at </font>
                <A href="http://www.volkswagen-media-services.com/">
                    <span style="COLOR: black">
                        <font size=3>www.volkswagen-media-services.com</font>
                    </span>
                </A>
                <font size=3>. The information contained in this press release does not apply to products and services from the Volkswagen Group of America or Volkswagen Canada.</font>
                <o:p></o:p>
            </span>
        </em>
    </P>`
}
