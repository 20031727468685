import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

class Input extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      value: null,
      focused: false
    }
  }

  onFocus = () => {
    this.setState(state => ({
      focused: true
    }))
  }

  onBlur = (e) => {
    this.setState(state => ({
      focused: false
    }))
    if (this.props.onBlur) {
      this.props.onBlur(this.state.value, e)
    }
  }

  onChange = (e) => {
    let value = e.target.value

    // display an error if there's an empty value for a required field.
    if (value) value = value.trim()
    if ((value === '' || !value) && this.props.required) {
      this.setState({ error: 'Required field.' })
    } else {
      this.setState({ error:  null })
    }

    // set state with the trimmed value
    this.setState(state => ({
      value
    }))

    // call onChange function if it exists
    if (this.props.onChange) {
      this.props.onChange(value, e)
    }
  }

  onReturn = (e) => {
    // capture return key event
    if (e.key === 'Enter') {
      // call onReturn function if it exists
      if (this.props.onReturn) {
        this.props.onReturn(this.state.value)
      }
    }
  }

  render () {
    const inputClasses = classNames(styles.input, styles[this.props.design], {
      [styles.focused]: this.state.focused,
      [styles.inactive]: this.props.inactive,
      [styles.error]: this.props.error || this.state.error
    }, this.props.className)

    return <div className={inputClasses}>
      {this.props.design === 'default' && <label className={this.state.focused || this.state.value ? styles.focused : null}>{this.props.title} {this.props.required ? '*' : ''}</label>}
      <input
        placeholder={this.props.design !== 'default' ? this.props.title : undefined}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onKeyDown={this.onReturn}
        disabled={this.props.inactive}
        type={this.props.type}
      />
      {
        (this.props.error || this.state.error) &&
          <span className={styles.errorMessage}>
            {this.props.error || this.state.error}
          </span>
      }
    </div>
  }
}

Input.propTypes = {
  /** Input title */
  title: PropTypes.string.isRequired,
  /** Class name for hte input */
  className: PropTypes.string,
  /** Required field? */
  required: PropTypes.bool,
  /** Component state: enabled/disabled */
  inactive: PropTypes.bool,
  /** Error message (if available) */
  error: PropTypes.string,
  /** Design Style */
  design: PropTypes.oneOf(['filter', 'standard', 'default']),
  /** OnChange event */
  onChange: PropTypes.func,
  /** Enter key press event */
  onReturn: PropTypes.func,
  /** Blur event */
  onBlur: PropTypes.func,
  /** html input type */
  type: PropTypes.string
}
Input.defaultProps = {
  design: 'default'
}

export default Input
