import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FaCalendarAlt, FaCaretDown } from 'react-icons/fa'
import DatePicker from 'react-datepicker';
import styles from './DateSelector.module.scss'
import './styles/datepicker-cssmodules.scss';

const ExampleCustomInput = ({ value, onClick, placeholder, color }) => (
  <button className={classNames(styles.box, styles[color])} onClick={onClick}>
    <FaCalendarAlt className={styles.calIcon} />
    <span>{value || placeholder}</span>
    <FaCaretDown className={styles.caretIcon} />
  </button>
);

class DateSelector extends Component {
  constructor (props, context) {
    super(props, context);

    this.handleFocus = this.handleFocus.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      date: null,
      focused: this.props.autoFocus
    }
  }

  componentWillUpdate (nextProps) {
    if (nextProps.selected !== this.props.selected) {
      if (nextProps.selected === null) {
        this.setState({
          date: null,
        })
      }
    }

    if (nextProps.autoFocus !== this.props.autoFocus) {
      this.setState({
        focused: nextProps.autoFocus,
      })
    }

    if (nextProps.reset != this.props.reset) {
      nextProps.reset && this.setState({ date: null });
    }
  }

  handleFocus (e) {
    this.setState({
      focused: true,
    })
  }

  handleOnBlur (e) {
    this.setState({
      focused: false,
    })

    if (this.props.handleBlur) this.props.handleBlur()
  }
  
  handleChange (date) {
    this.setState({
      date: date,
    });
    
    (this.props.onChange) && this.props.onChange(date, this.props.name && this.props.name);
  }

  render () {
    const props = this.props;
    const { date, focused } = this.state;
    return <div className={classNames(styles.dateContainer, props.color)}>
      {props.design === 'default' && (props.selected || props.value || date || focused) && <label className={classNames(styles.label, {
        [styles.active]: focused,
        [styles.small]: props.size === 'small'
      })}>{props.label} {this.props.required && !this.props.hideRequired ? '*' : ''}</label>}
      
      <DatePicker
        {...props}
        className={classNames({
          [styles.small]: props.size === 'small'
        })}
        customInput={props.design === 'box' && <ExampleCustomInput placeholder={props.label} color={props.color} />}
        autoFocus={this.state.focused}
        selected={props.selected || this.state.date}
        onChange={this.handleChange}
        placeholderText={focused ? props.placeholderText || 'MM/DD/YYYY' : `${props.label || props.title || props.placeholderText}${this.props.required && !this.props.hideRequired ? '*': ''}`}
        onFocus={this.handleFocus}
        onBlur={this.handleOnBlur}
      />
      {
        (this.props.error || this.state.error || this.props.description) &&
          <span className={styles.inputNote}>
            {this.props.description && <span id='desc' className={styles.descMessage}>
              {this.props.description}
            </span>}
            {(this.props.error || this.state.error) && <span id='inputError' className={styles.errorMessage}>
              {this.props.error || this.state.error}
            </span>}
          </span>
      }
    </div>
      
  }
}

DateSelector.propTypes = {
  allowSameDay: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  calendarClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  clearButtonTitle: PropTypes.string,
  color: PropTypes.string,
  customInput: PropTypes.element,
  customInputRef:	PropTypes.string,
  dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  dateFormatCalendar: PropTypes.string,
  dayClassName: PropTypes.func,
  description: PropTypes.string,
  design: PropTypes.oneOfType(['default', 'box']),
  disabled: PropTypes.bool,
  disabledKeyboardNavigation: PropTypes.bool,
  dropdownMode: PropTypes.oneOf(['scroll', 'select']),
  /** endDate expects moment js object */
  endDate: PropTypes.object,
  error: PropTypes.string,
  excludeDates: PropTypes.array,
  excludeTimes: PropTypes.array,
  filterDate: PropTypes.func,
  fixedHeight: PropTypes.bool,
  forceShowMonthNavigation:	PropTypes.bool,		
  formatWeekNumber:	PropTypes.func,		
  highlightDates:	PropTypes.array,		
  id:	PropTypes.string,		
  includeDates:	PropTypes.array,		
  includeTimes:	PropTypes.array,		
  injectTimes:	PropTypes.array,		
  inline:	PropTypes.bool,		
  isClearable:	PropTypes.bool,	
  label: PropTypes.string,	
  locale:	PropTypes.string,	
  /** maxDate expects moment js object */	
  maxDate:	PropTypes.object,
  /** maxTime expects moment js object */
  maxTime:	PropTypes.object,
  /** minDate expects moment js object */
  minDate:	PropTypes.object,
  /** minTime expects moment js object */
  minTime:	PropTypes.object,
  monthsShown:	PropTypes.number,
  name:	PropTypes.string,		
  onBlur:	PropTypes.func,
  onChange: PropTypes.func,	
  onChangeRaw:	PropTypes.func,		
  onClickOutside:	PropTypes.func,	
  onFocus:	PropTypes.func,
  onKeyDown:	PropTypes.func,
  onMonthChange:	PropTypes.func,
  onYearChange:	PropTypes.func,
  onSelect:	PropTypes.func,
  onWeekSelect:	PropTypes.func,
  /** minTime expects moment js object */
  openToDate:	PropTypes.object,		
  peekNextMonth:	PropTypes.bool,		
  placeholderText:	PropTypes.string,		
  popperClassName:	PropTypes.string,		
  popperContainer:	PropTypes.func,
  popperModifiers:	PropTypes.object,		
  popperPlacement:	PropTypes.oneOf([
    'auto',
    'auto-left',
    'auto-right',
    'bottom',
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
    'right',
    'right-end',
    'right-start',
    'top',
    'top-end',
    'top-start'
  ]),		
  readOnly:	PropTypes.bool,
  reset: PropTypes.bool,
  required:	PropTypes.bool,		
  scrollableYearDropdown:	PropTypes.bool,
  /** selected expects moment js object */	
  selected:	PropTypes.object,		
  selectsEnd:	PropTypes.bool,		
  selectsStart:	PropTypes.bool,		
  shouldCloseOnSelect:	PropTypes.bool,	
  showMonthDropdown:	PropTypes.bool,		
  showTimeSelect:	PropTypes.bool,	
  showWeekNumbers:	PropTypes.bool,		
  showYearDropdown:	PropTypes.bool,
  /** startDate expects moment js object */
  startDate:	PropTypes.object,		
  startOpen:	PropTypes.bool,		
  tabIndex:	PropTypes.number,		
  timeFormat:	PropTypes.string,		
  timeIntervals:	PropTypes.number,	
  title:	PropTypes.string,		
  todayButton:	PropTypes.node,		
  useWeekdaysShort:	PropTypes.bool,		
  utcOffset:	PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
  value:	PropTypes.string,		
  weekLabel:	PropTypes.string,		
  withPortal:	PropTypes.bool,
  yearDropdownItemNumber:	PropTypes.number,
  /* Size of input font */
  size: PropTypes.oneOf(['small', 'default'])
};

DateSelector.defaultProps = {
  allowSameDay:	false,
  color: 'green',
  customInputRef: 'ref',
  dateFormat: 'L',
  dateFormatCalendar: 'MMM YYYY',
  design: 'default',
  disabled: false,
  disabledKeyboardNavigation: false,
  dropdownMode: 'scroll',
  monthsShown: 1,
  shouldCloseOnSelect: true,
  showTimeSelect: false,
  timeIntervals: 30,
  withPortal: false,
  size: 'default'
}

export default DateSelector;
