import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './JumpLinkNav.module.scss'
import { withRouter } from '../WithRouter'

class JumpLinkNav extends Component {
  scrollToAnchor = (anchor) => () => {
    const el = document.getElementById(anchor)
    if (el) {
      const elOffSet = el.offsetTop
      window.scrollTo({ top: elOffSet - 100, behavior: 'smooth' })
    }
  }

  render () {
    const { links } = this.props

    return (
      <aside className={styles.component}>
        <div className={styles.links}>
          {links.map((link, index) => (
            <a
              className={styles.link}
              key={index}
              onClick={this.scrollToAnchor(link.hash)}>
              {link.label || link.title}
            </a>
          ))}
        </div>
      </aside>
    )
  }
}

JumpLinkNav.propTypes = {
  /** Links array */
  links: PropTypes.array
}

export default withRouter(JumpLinkNav)
