import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  FaMapMarkerAlt,
  FaChevronDown,
  FaChevronUp
} from 'react-icons/fa'
import Hide from '../Hide'
import styles from './BankAccordian.module.scss'

class BankDetail extends React.PureComponent {
  constructor (props, context) {
    super(props, context)

    this.state = {
      elementNameId: `name-${this.props.id}`,
      elementTypeId: `type-${this.props.id}`,
      isNameOpen: false,
      isTypeOpen: false,
      isLocationOpen: false,
      isPercentageOpen: false
    }
  }

  handleNameEnter = () => {
    const e = document.getElementById(this.state.elementNameId)

    if (e.clientWidth < e.scrollWidth) {
      this.setState({ isNameOpen: true })
    }
  }

  handleNameLeave = () => {
    this.setState({ isNameOpen: false })
  }

  handleTypeEnter = () => {
    const e = document.getElementById(this.state.elementTypeId)

    if (e.clientWidth < e.scrollWidth) {
      this.setState({ isTypeOpen: true })
    }
  }

  handleTypeLeave = () => {
    this.setState({ isTypeOpen: false })
  }

  handleLocationEnter = () => {
    this.setState({ isLocationOpen: true })
  }

  handleLocationLeave = () => {
    this.setState({ isLocationOpen: false })
  }

  handlePercentageEnter = () => {
    this.setState({ isPercentageOpen: true })
  }

  handlePercentageLeave = () => {
    this.setState({ isPercentageOpen: false })
  }

  render () {
    const { name, location, type, percentage, hasChildren, level, handleClick, isParent, isOpen } = this.props
    const chevronSpace = 44
    const leftSpace = ((!isParent || hasChildren) && chevronSpace) + level * 15
    let marginStyle = {}
    const tooltipStyle = { left: (hasChildren ? chevronSpace : 0) + leftSpace }

    if (level > 0) marginStyle = { marginLeft: leftSpace }

    return <div className={classNames(styles.BankDetail, {
      [styles.hasChildren]: hasChildren,
      [styles.parent]: isParent
    })} onClick={hasChildren && handleClick}>
      {hasChildren && <span className={styles.accordian}>
        {isOpen ? <FaChevronDown /> : <FaChevronUp />}
      </span>}
      <div className={styles.companyInfo}>
        <div id={this.state.elementNameId} onMouseEnter={this.handleNameEnter} onMouseLeave={this.handleNameLeave} className={classNames(styles.name, { [styles.parent]: hasChildren })} style={marginStyle}>
          {name}
        </div>
        <div className={classNames(styles.tooltip, { [styles.show]: this.state.isNameOpen })} style={tooltipStyle}>
          <div className={styles.triangle} />
          {name}
        </div>
        <Hide lg>
          <div onMouseEnter={this.handleLocationEnter} onMouseLeave={this.handleLocationLeave} className={styles.location}>
            <FaMapMarkerAlt className={styles.marker} />
            <div className={classNames(styles.tooltip, styles.tooltipRight, { [styles.show]: this.state.isLocationOpen })}>
              <div className={styles.triangle} />
              {location}
            </div>
          </div>
        </Hide>
        <Hide sm md>
          <div className={styles.location}>
            <FaMapMarkerAlt className={styles.marker} />
            <span className={styles.text}>
              {location}
            </span>
          </div>
        </Hide>
      </div>
      <div className={classNames(styles.companyType, {
        [styles.noPercent]: !percentage
      })}>
        <b id={this.state.elementTypeId} className={styles.type} onMouseEnter={this.handleTypeEnter} onMouseLeave={this.handleTypeLeave} style={marginStyle}>{type}</b>
        <div className={classNames(styles.tooltip, { [styles.show]: this.state.isTypeOpen })}>
          <div className={styles.triangle} />
          {type}
        </div>
        {percentage && <div className={styles.percentage} onMouseEnter={this.handlePercentageEnter} onMouseLeave={this.handlePercentageLeave}>
          <div className={styles.percentageBar} style={{ width: `${percentage}%` }} />
          <div className={styles.text}>{percentage}%</div>
          <div className={classNames(styles.tooltip, styles.tooltipRight, { [styles.show]: this.state.isPercentageOpen })}>
            <div className={styles.triangle} />
            % of equity voting control
          </div>
        </div>}
      </div>
    </div>
  }
}

BankDetail.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  location: PropTypes.string,
  type: PropTypes.string,
  percentage: PropTypes.string,
  hasChildren: PropTypes.bool,
  level: PropTypes.number,
  handleClick: PropTypes.func,
  isParent: PropTypes.bool,
  isOpen: PropTypes.bool
}

export default BankDetail
