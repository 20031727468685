import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import ClickOutside from 'react-click-outside'
import { FaEllipsisH } from 'react-icons/fa'
import styles from './ContentMenu.module.scss'

class ContentMenu extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      showOverflowMenu: false,
      menuItems: props.tabs || [],
      hasOverflow: false
    }
  }

  menuElements = []

  componentDidMount () {
    this.doResize()
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount () {
    clearTimeout(this.resizeTimer)
    window.removeEventListener('resize', this.onResize)
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    clearTimeout(this.resizeTimer)
    this.menuElements = []
    this.setState({
      showOverflowMenu: false,
      menuItems: newProps.tabs || [],
      hasOverflow: false
    }, this.doResize)
  }

  onResize = () => {
    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(this.doResize, 100)
  }

  doResize = () => {
    if (!this.container) return
    let hasOverflow
    const newTabs = [...this.state.menuItems]
    const container = ReactDOM.findDOMNode(this.container)
    const containerRect = container.getBoundingClientRect()
    this.menuElements.forEach((child, i) => {
      const menuItem = ReactDOM.findDOMNode(child)
      if (!menuItem) return
      const menuItemRect = menuItem.getBoundingClientRect()
      const isVisible = menuItemRect.right <= (containerRect.right - 40)
      newTabs[i].visible = true
      if (!isVisible) {
        newTabs[i].visible = false
        hasOverflow = true
      }
    })
    this.setState({ menuItems: newTabs, hasOverflow })
  }

  toggleOverflowMenu = () => {
    this.setState(state => ({ showOverflowMenu: !state.showOverflowMenu }))
  }

  hideMenu = () => {
    this.setState({ showOverflowMenu: false })
  }

  render () {
    return <ClickOutside onClickOutside={this.hideMenu} className={styles.tabBarContainer}>
      <div className={styles.tabBar} ref={el => { this.container = el }}>
        {this.state.menuItems.map((page, i) => {
          const pageTitle = page.tabTitle || page.title
          if (page.visible === false) return <div ref={el => { this.menuElements[i] = el }} key={page.slug} className={styles.hidden}>{pageTitle}</div>
          if (page.active) return <div ref={el => { this.menuElements[i] = el }} key={page.slug} className={styles.activeTab}>{pageTitle}</div>
          return <Link ref={el => { this.menuElements[i] = el }} key={page.slug} to={page.slug}>{pageTitle}</Link>
        })}
      </div>
      {this.state.hasOverflow && <div className={styles.overflowMenuButton} onClick={this.toggleOverflowMenu}>
        <FaEllipsisH />
      </div>}
      {this.state.showOverflowMenu && <div className={classNames(styles.overflowMenu, styles[this.props.accent])}>
        {this.state.menuItems.map((page, i) => {
          if (page.visible === true) return null
          const pageTitle = page.tabTitle || page.title
          if (page.active) return <div key={page.slug} className={styles.activeTab}>{pageTitle}</div>
          return <Link key={page.slug} to={page.slug}>{pageTitle}</Link>
        })}
      </div>}
    </ClickOutside>
  }
}

ContentMenu.propTypes = {
  tabs: PropTypes.array,
  accent: PropTypes.string
}

export default ContentMenu
