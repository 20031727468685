import React, { PureComponent } from 'react'
import styles from './MAComplianceAnalytics.module.scss'
import {
  getRiskScoring,
  getPriceMovement,
  getVolumeMovment,
  downloadAnalytics
} from '../../api/otc/market-activity/compliance-analytics'
import { template, fetch, expand, sort } from '../../api/helper'

import HeadTitle from '../../components/HeadTitle'
import Flexgrid from '../../components/Flexgrid'
import Table from '../../components/Table'
import More from '../../components/More'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import Sticky from '../../components/Sticky'
import JumpLinkNav from '../../components/JumpLinkNav'
import Outline from '../../components/Outline'
import columns from './columns'

// Mapping functions to table names
const tableFunctions = {
  riskScoring: getRiskScoring,
  priceMovement: getPriceMovement,
  volumeMovement: getVolumeMovment
}

const defaultFilter = (type) => {
  const table = columns.find(col => col.key === type)
  return table.filters[table.filterDefault].value
}

export default class MAComplianceAnalytics extends PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      loaded: false,
      riskScoring: template('records'),
      priceMovement: template('records'),
      volumeMovement: template('records'),
      selectedLoadedPage: 1,
      searchQuery: null,
      filters: {
        riskScoring: { filter: defaultFilter('riskScoring') },
        priceMovement: { range: defaultFilter('priceMovement') },
        volumeMovement: { range: defaultFilter('volumeMovement') }
      }
    }
  }

  componentDidMount () {
    this.loadData()

    // If anchor is supplied in hash, scroll into view
    const hash = window.location.hash
    if (hash && hash.length > 1) {
      const el = document.getElementById(hash.substr(1))
      if (el) {
        setTimeout(() => { el.scrollIntoView(true) }, 100)
      }
    }
  }

  loadData = () => {
    columns.map(table => {
      fetch(this, tableFunctions[table.key], table.key, {
        page: 1,
        pageSize: 10
      })
    })
  }

  /**
   * When the user selects an item in the top risk score dropdown.
   * Updates the positionDate in local state and calls the API again to get the new data.
   * @param {string} key - The epoch time to query on.
   * @param {string} item - The human readable date.
   */
  onSelect = (tableName) => {
    return (val) => {
      const filters = this.state.filters
      const filter = tableName === 'riskScoring' ? { filter: val } : { range: val }

      if (tableName) {
        filters[tableName] = filter
      }

      this.setState({ filters }, () => fetch(this, tableFunctions[tableName], tableName, {
        ...filter,
        page: 1,
        pageSize: 10
      }))
    }
  }

  handleDownload = (tableName, key) => {
    downloadAnalytics(tableName, this.state.filters[key])
  }

  render () {
    const layouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['search', 'desc', 'tables']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['search', 'desc', 'jump-links']
            },
            {
              type: 'main',
              subtree: ['tables']
            }
          ]
        }
      ]
    }

    const tables = columns

    return (
      <div className={styles.component}>
        <HeadTitle title='Market Activity' />
        <PageTitle>Compliance Analytics</PageTitle>
        <Flexgrid layouts={layouts}>
          <div key='tables' className={styles.tables}>
            {tables.map(table => {
              const tableData = this.state[table.key]
              return (<div key={`table-${table.key}`} id={`${table.hash}`}>
                <div key={table.key} className={styles.tradeDataTable}>
                  <Outline mode='heading'
                    download={() => this.handleDownload(table.hash, table.key)}
                    hideFilterToggle
                    filters={table.filters && [{
                      type: 'select',
                      required: true,
                      options: table.filters,
                      defaultValue: table.filters[table.filterDefault || 0].value,
                      onSelect: this.onSelect(table.key)
                    }]}>
                    {table.title}
                  </Outline>
                  <Loading
                    type='table'
                    data={tableData.data.records}
                    error={tableData.error}
                    reloading={tableData.reloading}
                    loaded={tableData.loaded}>
                    <Table
                      columns={table.columns}
                      data={tableData.data.records}
                      sortColumn={tableData.sortOn}
                      sortDirection={tableData.sortDir}
                      onSorting={sort(this, tableFunctions[table.key], table.key)}
                      showHeader
                    />
                    <TableFooter>
                      <More
                        onClick={expand(this, tableFunctions[table.key], table.key)}
                        disabled={tableData.expandEmpty} />
                      {tableData.data.records && tableData.data.totalRecords &&
                        <DisplayResults show={tableData.data.records.length} total={tableData.data.totalRecords} text={'Results'} />
                      }
                    </TableFooter>
                  </Loading>
                </div>
              </div>)
            })}
          </div>
          <div key='desc' className={styles.desc}>
            Compliance Analytics provides insight on the changes to risk scores, price and volume across the marketplace.  Data may be filtered by time frame (risk scoring) or by price (price and volume movement).  All data sets may be downloaded by selecting the downward arrow in the top right corner of the data table.
          </div>
          <Sticky key='jump-links'>
            <JumpLinkNav links={columns} />
          </Sticky>
        </Flexgrid>
      </div>
    )
  }
}
