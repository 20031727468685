import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ExternalLink from '../ExternalLink'
import {
  FaRegComment,
  FaExclamationCircle,
  FaRegClock
} from 'react-icons/fa'
import styles from './AlertBanner.module.scss'
const ALERT_STORAGE = 'complianceui-alert'

class AlertBanner extends Component {
  state = {
    alerts: this.props.alerts,
    currentAlert: 0
  }

  close = () => {
    const { alerts, currentAlert } = this.state

    const array = [...alerts]
    array.splice(this.state.currentAlert, 1)

    // store in localstorage to no show again
    if (alerts[currentAlert].alert === 'important') {
      window.sessionStorage.setItem(`${ALERT_STORAGE}${alerts[currentAlert].key}`, true)
    } else {
      window.localStorage.setItem(`${ALERT_STORAGE}${alerts[currentAlert].key}`, true)
    }

    this.setState({
      alerts: array,
      currentAlert: currentAlert !== 0 ? currentAlert - 1 : 0
    })
  }

  prev = () => {
    this.setState({ currentAlert: this.state.currentAlert - 1 })
  }

  next = () => {
    this.setState({ currentAlert: this.state.currentAlert + 1 })
  }

  render () {
    const { alerts, currentAlert } = this.state

    return (
      (alerts && alerts.length > 0)
        ? <div className={classnames(styles.component, {
          [styles.reminder]: alerts[currentAlert].alert === 'reminder',
          [styles.note]: alerts[currentAlert].alert === 'note',
          [styles.important]: alerts[currentAlert].alert === 'important'
        })}>
        <div className={styles.contentContainer}>
          <div>
            <span className={styles.icon}>
              {alerts[currentAlert].alert === 'reminder' && <FaRegClock size='25px' />}
              {alerts[currentAlert].alert === 'note' && <FaRegComment size='25px' />}
              {alerts[currentAlert].alert === 'important' && <FaExclamationCircle size='25px' />}
            </span>
            <span>
              {alerts[currentAlert].link ? <ExternalLink className={styles.link} href={alerts[currentAlert].link}>{alerts[currentAlert].description}</ExternalLink> : alerts[currentAlert].description}
            </span>
          </div>
          <div className={styles.cta} onClick={this.close}>OK</div>
        </div>
        {alerts.length > 1 && <div className={styles.pagination}>
          {currentAlert > 0 && <span onClick={this.prev}>Previous</span>}
          {currentAlert !== alerts.length - 1 && <span onClick={this.next}>Next</span>}
        </div>}
      </div>
        : null
    )
  }
}

AlertBanner.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape({
    alert: PropTypes.oneOf(['reminder', 'note', 'important']),
    description: PropTypes.string,
    link: PropTypes.string
  }))
}

AlertBanner.defaultProps = {
  alert: 'note'
}

export default AlertBanner
