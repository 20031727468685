import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'grid-styled'
import {
  FaFacebook,
  FaLinkedin,
  FaShieldAlt
} from 'react-icons/fa'
import FaTwitter from '../../FaTwitter'
import styles from './styles.module.scss'

import Hide from '../../Hide'
import ExternalLink from '../../ExternalLink'

const CompanyQuoteInfoTitle = (props) =>
  <Flex w={[1, 1 / 2, 1 / 2]} className={styles.item} wrap>
    <Box className={styles.firstColumn}>
      {!props.link && props.value}
      {props.link && <ExternalLink className={styles.link} to={props.value}>{props.linkTitle}</ExternalLink>}
      {props.shield && <FaShieldAlt />}
    </Box>
    {props.rightValue && <Box
      flex='1 1 auto'
      justify='flex-end'
      className={styles.rightValue}>
      {props.rightValue}
    </Box>}
    {props.socialButtons &&
      <Hide sm>
        <Box
          flex='1 1 auto'
          justify='flex-end'
          className={styles.lastColumn}>
          {props.facebook && <ExternalLink to={props.facebook} className={styles.socialButton}>
            <FaFacebook />
          </ExternalLink>}
          {props.twitter && <ExternalLink to={props.twitter} className={styles.socialButton}>
            <FaTwitter />
          </ExternalLink>}
          {props.linkedin && <ExternalLink to={props.linkedin} className={styles.socialButton}>
            <FaLinkedin />
          </ExternalLink>}
        </Box>
      </Hide>
    }
  </Flex>

CompanyQuoteInfoTitle.propTypes = {
  value: PropTypes.string,
  shield: PropTypes.bool,
  link: PropTypes.bool,
  linkTitle: PropTypes.string,
  rightValue: PropTypes.string,
  socialButtons: PropTypes.bool,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string
}

export default CompanyQuoteInfoTitle
