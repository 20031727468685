import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getList (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'directory/company/list',
    url: '/company-directory',
    params
  })
  return response.data
}

export async function getCountries (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'array',
    url: '/company-directory/country',
    params
  })
  return response.data
}

export async function downloadCompanies () {
  let jwtToken

  const url = '/otcapi/download/internal/company-directory/download'

  await getToken()
    .then(res => {
      jwtToken = res
    })
    .catch(e => console.log('no session', e))

  window.open(`${REACT_APP_API_BASE_URI}${url}?file=company-directory&token=${jwtToken}`)
}
