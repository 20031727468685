import moment from 'moment'

const columns = [
  {
    name: 'symbol',
    header: 'Symbol',
    type: 'symbol',
    sortingEnabled: true
  },
  {
    name: 'riskScore',
    header: (data) => (data && data.riskScoreDate) ? `${moment(data.riskScoreDate).format('MM/DD/YYYY')} Risk Score` : 'Risk Score',
    type: 'int',
    sortingEnabled: true
  },
  {
    name: 'change',
    header: '*Change',
    type: 'int',
    sortingEnabled: true
  },
  {
    name: 'closingPrice',
    header: 'Closing Price',
    type: 'price',
    sortingEnabled: true
  },
  {
    name: 'volume',
    header: 'Vol',
    type: 'int',
    sortingEnabled: true
  },
  {
    name: 'thirtyDayAdv',
    header: '30 ADV',
    type: 'int',
    sortingEnabled: true
  },
  {
    name: 'marketCap',
    header: 'Market Cap (M)',
    type: 'millions',
    sortingEnabled: true
  }
]

const mostRecentDateColumn = {
  name: 'latestPromoDate',
  header: 'Most Recent Promo Date',
  type: 'date',
  sortingEnabled: true
}

const sectorColumn = {
  name: 'sector',
  header: 'Sector',
  type: 'string',
  sortingEnabled: true
}

const promoStartDate = {
  name: 'promoStartDate',
  header: 'Start Date',
  type: 'date',
  sortingEnabled: true
}
const activePromoSecColumns = [
  mostRecentDateColumn,
  ...columns
]
activePromoSecColumns.splice(3, 0, promoStartDate)

const hotSecColumns = [
  {
    name: 'hotSectorDate',
    header: 'Date',
    type: 'date',
    sortingEnabled: true
  },
  ...columns
]
hotSecColumns.splice(3, 0, sectorColumn)

export const tables = [{
  key: 'topRisk',
  hash: 'top-risk-scores',
  title: 'Top Risk Scores',
  columns: [
    ...columns
  ]
},
{
  key: 'caveatEmptor',
  hash: 'caveat-emptor',
  title: 'Caveat Emptor',
  columns: [
    {
      name: 'caveatEmptorDate',
      header: 'Date',
      type: 'date',
      sortingEnabled: true
    },
    ...columns
  ]
},
{
  key: 'activePromotions',
  hash: 'active-promotions',
  title: 'Active Promotions',
  columns: [...activePromoSecColumns]
},
{
  key: 'hotSectors',
  hash: 'hot-sectors',
  title: 'Hot Sectors',
  columns: [
    ...hotSecColumns
  ]
},
{
  key: 'shellRisk',
  hash: 'shell-risk',
  title: 'Shell Risk',
  columns: [
    ...columns
  ]
},
{
  key: 'shell',
  hash: 'shell',
  title: 'Shell',
  columns: [
    ...columns
  ]
},
{
  key: 'bankruptcy',
  hash: 'bankruptcy',
  title: 'Bankruptcy',
  columns: [
    {
      name: 'bankruptcyDate',
      header: 'Date',
      type: 'date',
      sortingEnabled: true
    },
    ...columns
  ]
}
]
