export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return {
        totalRecords: 0,
        pages: 0,
        currentPage: 2,
        pageSize: 10,
        sortOn: 'releaseDate',
        sortDir: 'DESC',
        singular: 'News & Financial Reports',
        plural: 'News & Financial Report'
      }

    case 'OTCM':
      return {
        totalRecords: 0,
        pages: 0,
        currentPage: 2,
        pageSize: 10,
        sortOn: 'releaseDate',
        sortDir: 'DESC',
        singular: 'News & Financial Reports',
        plural: 'News & Financial Report'
      }

    case 'VLKAY':
      // Taken from FB
      return {
        records: [
          {
            typeName: 'Automatic Sell',
            relationshipName: 'Officer',
            ownershipTypeName: 'indirect',
            id: 3305892,
            currencyId: 11,
            filerId: 882215,
            filerName: 'ZUCKERBERG MARK',
            formTypeId: 4,
            issueId: 1159589,
            issueName: 'FACEBOOK INC',
            typeId: 9,
            relationshipId: 19,
            transDate: 1504843200000,
            priceFrom: 171.1816,
            priceTo: 173.0338,
            numberOfShares: 123503,
            amountOwned: 1915103,
            ownershipTypeId: 1,
            dcn: '0001127602-17-027276',
            filingId: 12276534
          },
          {
            typeName: 'Acquisition (Non Open Market)',
            relationshipName: 'Officer',
            ownershipTypeName: 'indirect',
            id: 3305891,
            currencyId: 11,
            filerId: 882215,
            filerName: 'ZUCKERBERG MARK',
            formTypeId: 4,
            issueId: 1159589,
            issueName: 'FACEBOOK INC',
            typeId: 3,
            relationshipId: 19,
            transDate: 1504843200000,
            priceFrom: 0,
            numberOfShares: 94446,
            amountOwned: 58121,
            ownershipTypeId: 1,
            dcn: '0001127602-17-027276',
            filingId: 12276534
          },
          {
            typeName: 'Automatic Sell',
            relationshipName: 'Officer',
            ownershipTypeName: 'indirect',
            id: 3305890,
            currencyId: 11,
            filerId: 882215,
            filerName: 'ZUCKERBERG MARK',
            formTypeId: 4,
            issueId: 1159589,
            issueName: 'FACEBOOK INC',
            typeId: 9,
            relationshipId: 19,
            transDate: 1504756800000,
            priceFrom: 170.739,
            priceTo: 172.8005,
            numberOfShares: 123644,
            amountOwned: 785997,
            ownershipTypeId: 1,
            dcn: '0001127602-17-027276',
            filingId: 12276534
          },
          {
            typeName: 'Acquisition (Non Open Market)',
            relationshipName: 'Officer',
            ownershipTypeName: 'indirect',
            id: 3305889,
            currencyId: 11,
            filerId: 882215,
            filerName: 'ZUCKERBERG MARK',
            formTypeId: 4,
            issueId: 1159589,
            issueName: 'FACEBOOK INC',
            typeId: 3,
            relationshipId: 19,
            transDate: 1504756800000,
            priceFrom: 0,
            numberOfShares: 94549,
            amountOwned: 58182,
            ownershipTypeId: 1,
            dcn: '0001127602-17-027276',
            filingId: 12276534
          },
          {
            typeName: 'Automatic Sell',
            relationshipName: 'Officer',
            ownershipTypeName: 'direct',
            id: 3305888,
            currencyId: 11,
            filerId: 934386,
            filerName: 'COX CHRISTOPHER K',
            formTypeId: 4,
            issueId: 1159589,
            issueName: 'FACEBOOK INC',
            typeId: 9,
            relationshipId: 19,
            transDate: 1504756800000,
            priceFrom: 170.9977,
            priceTo: 171.8505,
            numberOfShares: 16000,
            amountOwned: 192202,
            ownershipTypeId: 0,
            dcn: '0001127602-17-027275',
            filingId: 12276533
          },
          {
            typeName: 'Automatic Sell',
            relationshipName: 'Officer',
            ownershipTypeName: 'direct',
            id: 3304696,
            currencyId: 11,
            filerId: 910219,
            filerName: 'STRETCH COLIN',
            formTypeId: 4,
            issueId: 1159589,
            issueName: 'FACEBOOK INC',
            typeId: 9,
            relationshipId: 19,
            transDate: 1504584000000,
            priceFrom: 171.5907,
            numberOfShares: 750,
            amountOwned: 94841,
            ownershipTypeId: 0,
            dcn: '0001127602-17-027151',
            filingId: 12272167
          },
          {
            typeName: 'Automatic Sell',
            relationshipName: 'Director',
            ownershipTypeName: 'indirect',
            id: 3301889,
            currencyId: 11,
            filerId: 946428,
            filerName: 'KOUM JAN',
            formTypeId: 4,
            issueId: 1159589,
            issueName: 'FACEBOOK INC',
            typeId: 9,
            relationshipId: 12,
            transDate: 1504152000000,
            priceFrom: 170.7542,
            priceTo: 172.0857,
            numberOfShares: 577125,
            amountOwned: 17675252,
            ownershipTypeId: 1,
            dcn: '0001127602-17-026849',
            filingId: 12265453
          },
          {
            typeName: 'Automatic Sell',
            relationshipName: 'Director',
            ownershipTypeName: 'indirect',
            id: 3301888,
            currencyId: 11,
            filerId: 946428,
            filerName: 'KOUM JAN',
            formTypeId: 4,
            issueId: 1159589,
            issueName: 'FACEBOOK INC',
            typeId: 9,
            relationshipId: 12,
            transDate: 1504065600000,
            priceFrom: 170.0204,
            numberOfShares: 63629,
            amountOwned: 7078958,
            ownershipTypeId: 1,
            dcn: '0001127602-17-026849',
            filingId: 12265453
          },
          {
            typeName: 'Automatic Sell',
            relationshipName: 'Officer',
            ownershipTypeName: 'direct',
            id: 3300180,
            currencyId: 11,
            filerId: 910219,
            filerName: 'STRETCH COLIN',
            formTypeId: 4,
            issueId: 1159589,
            issueName: 'FACEBOOK INC',
            typeId: 9,
            relationshipId: 19,
            transDate: 1503979200000,
            priceFrom: 165.4041,
            priceTo: 167.5425,
            numberOfShares: 750,
            amountOwned: 95591,
            ownershipTypeId: 0,
            dcn: '0001127602-17-026682',
            filingId: 12258150
          },
          {
            typeName: 'Automatic Sell',
            relationshipName: 'Officer',
            ownershipTypeName: 'indirect',
            id: 3300179,
            currencyId: 11,
            filerId: 882215,
            filerName: 'ZUCKERBERG MARK',
            formTypeId: 4,
            issueId: 1159589,
            issueName: 'FACEBOOK INC',
            typeId: 9,
            relationshipId: 19,
            transDate: 1503892800000,
            priceFrom: 166.9905,
            priceTo: 167.5324,
            numberOfShares: 127183,
            amountOwned: 1973255,
            ownershipTypeId: 1,
            dcn: '0001127602-17-026681',
            filingId: 12258149
          }
        ],
        sortOn: '1',
        sortDir: 'DESC',
        key: 'FB',
        plural: 'Insider Transactions',
        singular: 'Insider Transaction',
        totalRecords: 799,
        pages: 80,
        currentPage: 1,
        pageSize: 10
      }
  }
}
