/**
 * Assign tier/venue image values etc.
 */
export const highlighter = (item) => {
  // assign all valid image values
  if (item.venue === 'NO_VENUE' || item.tierid === 'NO_VENUE') {
    item.img = null
  } else {
    if (item.tierid === 0) {
      item.img = item.venue && item.venue.trim()
    } else if (item.tierid === -1) {
      item.img = 'caveat-emptor'
    } else {
      item.img = item.tierid
    }
  }

  switch (item.img) {
    case 1:
    case 2:
    case 5:
    case 6:
      item.img = 'qx'
      break

    case 10:
      item.img = 'qb'
      break

    case 20:
      item.img = 'ps'
      break

    case 21:
      item.img = 'yield'
      break

    case 22:
      item.img = 'stop'
      break

    case 30:
      item.img = 'caution'
      break

    case 40:
      item.img = 'expert-tier'
      break

    default:
      break
  }

  return item
}

/**
 * Company symbol search
 * @param  {Array} list              the entire company list (list.gz)
 * @param  {String} keyword          search keyword
 * @param  {Boolean} includeIndices  whether to include indices in results
 * @return {Array}                   search results
 */
export const search = (list) => {
  let results = list

  if (!list) return results
  if (!list.length) return results

  // assign image values etc.
  results = results.map(highlighter)
  return results
}
