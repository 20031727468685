import React, { useState, useEffect } from 'react'
import { Route, Navigate, Routes, matchPath } from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from './Layout.module.scss'
import classnames from 'classnames'
import { Flex, Box } from 'grid-styled'
import { ThemeProvider } from 'styled-components'
// import { matchPath } from 'react-router'
import { withRouter } from '../WithRouter'
import { withTracker } from '../../analytics'
import Header from '../Header'
import Footer from '../Footer'
import Breadcrumbs from '../Breadcrumbs'
import StockQuote from '../StockQuote'
import AlertBanner from '../AlertBanner'
import StockScreener from '../StockScreener'
import { AuthConsumer } from '../../AuthContext'
import { getPageBySlug } from '../../api/content/pages'
import routes from '../../routes'

const screens = ['sm', 'md', 'lg']
const breakpoints = [48, 64] // in rem
const rem = 16 // 1 rem = 16px

const defaultScreen = {
  type: 'sm',
  index: 0
}

const ProtectedRoute = ({ isAuth, children }) => {
  if (!isAuth) {
    return <Navigate to="/" replace />
  }
  return children
}

ProtectedRoute.propTypes = {
  isAuth: PropTypes.bool,
  children: PropTypes.node
}

const Layout = ({ location, navigate, children }) => {
  const [alerts, setAlerts] = useState(null)
  const [screen, setScreen] = useState(defaultScreen)
  const pathname = location.pathname
  const hidePathName = pathname === '/change-password' || pathname === '/contact'

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)

    getPageBySlug('fragments/compliance-alert-banner').then(fragment => {
      if (fragment && fragment.sections) {
        const ALERT_STORAGE = 'complianceui-alert'
        let alerts = fragment.sections.filter(alert => !window.localStorage.getItem(`${ALERT_STORAGE}${alert.key}`))
        alerts = alerts.filter(alert => !window.sessionStorage.getItem(`${ALERT_STORAGE}${alert.key}`))
        setAlerts(alerts)
      }
    })

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const onResize = () => {
    // determine screen type and inject in theme
    const width = window.innerWidth / rem // width in rem
    const screenIndex = [...breakpoints, Infinity].findIndex(bp => width < bp)

    // only update if screen index has changed
    if (screen.index === screenIndex) return
    setScreen({
      type: screens[screenIndex],
      index: screenIndex
    })
  }

  const onSymbolSelect = (symbol, item) => {
    if (!symbol) return
    const secType = item.isFixedIncome ? 'fixed-income' : 'stock'
    const symbolType = symbol.indexOf('.') === 0 ? 'index' : secType
    const match = matchPath({
      path: `/${symbolType}/:company/:tab`,
      exact: true,
      strict: false
    }, location.pathname)
    const tab = match && match.params && match.params.tab ? `/${match.params.tab}` : ''
    navigate(`/${symbolType}/${symbol}${tab}`)
  }

  return (
    <ThemeProvider
      theme={{
        breakpoints,
        screen
      }}
    >
      <AuthConsumer>
        {({ isAuth }) => (
          typeof isAuth === 'boolean'
            ? <Flex column align='center' className={styles.app}>
            {pathname !== '/login' && <Header />}
            {(isAuth && alerts) && <AlertBanner alerts={alerts} />}
            <Box flex='1 1 auto' w={1} className={classnames({
              [styles.wrapper]: isAuth,
              [styles.preloginWrapper]: !isAuth
            })}>
              {(isAuth && !hidePathName) && <Flex wrap align='center' className={styles.subHeader}>
                <Box w={[1, 1 / 2, 1 / 4]} className={styles.stockQuote}>
                  <StockQuote
                    placeholder='Symbol, Company Name or CUSIP'
                    onSelect={onSymbolSelect}
                    keepSelection={false}
                    includeIndices />
                </Box>
                <Box w={[1, 1 / 2, 1 / 4]} className={styles.stockScreener}>
                  <StockScreener />
                </Box>
              </Flex>}
              <Breadcrumbs />
              <Box w={1} className={styles.content}>
                {children}
                <Routes>
                  {routes.filter(route => !route.noroute).map(({ ...config }, i) => {
                    const Component = config.component

                    if (config.redirect) {
                      return <Route
                        key={i}
                        path={config.path}
                        element={<Navigate to={config.redirect} replace />} />
                      // Add analytics tracking
                    } else if (config.element && config.log !== false) config.element = withTracker(config.element)

                    return config.public
                      ? <Route key={i} {...config} element={<Component />} />
                      : <Route key={i} {...config} element={<ProtectedRoute isAuth={isAuth}><Component /></ProtectedRoute>} />
                  })}
                </Routes>
              </Box>
            </Box>
            {pathname !== '/login' && <Footer isAuth={isAuth} />}
          </Flex>
            : null
        )}
      </AuthConsumer>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  isAuth: PropTypes.bool,
  navigate: PropTypes.func,
  children: PropTypes.node.isRequired,
  location: PropTypes.object
}

export default withRouter(Layout)
