import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './Select.module.scss'
import inputStyles from '../Input/styles.module.scss'
import { FaChevronDown } from 'react-icons/fa'
import { Flex } from 'grid-styled'
import SelectMenu from './SelectMenu'

class Select extends React.PureComponent {
  constructor (props, context) {
    super(props, context)

    // Setting Default Values
    let title = null
    let value = null
    if (this.props.defaultValue) {
      const option = this.props.options.find(o => o.value === this.props.defaultValue)
      if (option) {
        title = option.title
        value = option.value
      }
    }

    this.handleClick = this.handleClick.bind(this)
    this.state = {
      focused: false,
      active: value,
      selection: value,
      selectionTitles: title,
      selectionIndices: [],
      menuStates: []
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = (e) => {
    if (!this.node.contains(e.target)) {
      if (!this.state.focused) return
      this.setState({ focused: false })
    }
  }

  onClick = (e) => {
    if (this.props.inactive || !this.inputRef) return
    if (!this.state.focused) {
      if (document.activeElement === ReactDOM.findDOMNode(this.inputRef)) {
        this.setState({ focused: true, active: this.state.selection }, this.scrollToActive)
      } else {
        this.inputRef.focus()
      }
    } else {
      setTimeout(() => { if (this.inputRef) this.inputRef.blur() }, 100)
    }
    if (e && e.preventDefault) e.preventDefault()
  }

  onFocus = () => {
    if (this.props.inactive) return
    this.setState({ focused: true, active: this.state.selection }, this.scrollToActive)
  }

  onBlur = () => {
    // if (!this.state.focused) return
    // this.setState({ focused: false })
  }

  toggleSubmenu = (value) => {
    return () => {
      this.setState(state => ({
        menuStates: {
          [value]: !state.menuStates[value]
        }
      }))
    }
  }

  // Scrolls active item into view if necessary
  scrollToActive = () => {
    if (this.state.active === undefined || !this.containerRef) return
    const container = ReactDOM.findDOMNode(this.containerRef)
    if (!container || container.clientHeight > container.scrollHeight) return
    const options = this.getOptions()
    const index = options.findIndex(option => option.value === this.state.active)
    const item = container.querySelector(`[name='item_${index}']`)
    if (!item) return
    const parentRect = container.getBoundingClientRect()
    const childRect = item.getBoundingClientRect()
    if ((childRect.top < parentRect.top) || (childRect.bottom > parentRect.top + container.clientHeight)) {
      container.scrollTop = item.offsetTop
    }
  }

  handleKeyDown = (e) => {
    const options = this.getOptions()
    if (!options || options.length === 0) return
    const currentIndex = this.state.active !== undefined ? options.findIndex(option => option.value === this.state.active) : null
    if (e.key === 'Enter' && this.state.active !== undefined) {
      this.onSelect(options[currentIndex])()
      if (e && e.preventDefault) e.preventDefault()
    } else if (e.key === 'ArrowUp') {
      if (this.state.active !== undefined) {
        const newIndex = currentIndex === 0 ? options.length - 1 : currentIndex - 1
        this.setState({ active: options[newIndex].value }, this.scrollToActive)
      } else {
        this.setState({ active: options[options.length - 1].value }, this.scrollToActive)
      }
      if (e && e.preventDefault) e.preventDefault()
    } else if (e.key === 'ArrowDown') {
      if (this.state.active !== undefined) {
        const newIndex = currentIndex === options.length - 1 ? 0 : currentIndex + 1
        this.setState({ active: options[newIndex].value }, this.scrollToActive)
      } else {
        this.setState({ active: options[0].value }, this.scrollToActive)
      }
      if (e && e.preventDefault) e.preventDefault()
    }
  }

  onHover = (option) => {
    return () => {
      this.setState({ active: option.value })
    }
  }

  onSelect = (option) => {
    return (e) => {
      if (e && e.preventDefault) e.preventDefault()
      let selection = this.state.selection
      let selectionTitles = this.state.selectionTitles
      if (selection === option.value) return
      switch (this.props.type) {
        case 'checkboxes':
          const { selectionIndices } = this.state

          if (!selection) selection = []
          if (!selectionTitles) selectionTitles = []

          if (selectionIndices[option.value]) {
            selectionIndices[option.value] = false

            // remove item
            selection.splice(selection.indexOf(option.value), 1)
            selectionTitles.splice(selection.indexOf(option.title), 1)
          } else {
            selectionIndices[option.value] = true

            // add item
            selection.push(option.value)
            selectionTitles.push(option.title)
          }

          if (selection && selection.length === 0) {
            selection = null
            selectionTitles = null
          }

          this.setState(state => ({
            selection,
            selectionTitles
          }))
          break

        default:
          selection = option.value
          selectionTitles = option.title
          this.setState(state => ({
            selection: option.value,
            selectionTitles: option.value === null ? null : option.title
          }))

          if (this.props.onSelect) {
            this.props.onSelect(selection, selectionTitles)
          }

          // basic dropdowns should be closed on selection
          this.setState(state => ({
            focused: false
          }))
          break
      }

      // validations
      if (!selection && selection !== 0 && this.props.required) {
        this.state.error = this.props.title + ' is Required.'
      } else {
        this.state.error = null
      }

      this.forceUpdate()
    }
  }

  getOptions = () => {
    let options = (this.props.options && this.props.options.slice(0)) || []
    // if options are strings, use as both title and value
    options = options.map(option => {
      if (typeof option === 'string') {
        return {
          title: option,
          value: option
        }
      }
      return option
    })

    // add "clear" option if not required and existing value is set
    if ((this.state && this.state.selection !== null) && !this.props.required && this.props.type !== 'checkboxes') {
      options.unshift({ clear: true, value: null, title: 'Clear' })
    }

    return options
  }

  render () {
    const selectClasses = classNames(inputStyles.input, styles.select, inputStyles[this.props.design], {
      [inputStyles.focused]: this.state.focused,
      [inputStyles.inactive]: this.props.inactive,
      [inputStyles.error]: this.props.error || this.state.error,
      [styles.centerAligned]: this.props.centerAligned
    }, this.props.className)

    const labelClasses = classNames({
      [inputStyles.focused]: this.state.focused || this.state.selection
    })

    const options = this.getOptions()

    return <div ref={node => (this.node = node)} onMouseDown={this.onClick}
      className={selectClasses}>
      <button className={styles.hiddenInput} onFocus={this.onFocus} onBlur={this.onBlur} onKeyDown={this.handleKeyDown} ref={ref => { this.inputRef = ref }} />
      {(this.state.selection === null || this.props.design === 'default') && <label className={labelClasses}>
        {this.props.title} {this.props.required ? '*' : ''}
      </label>}

      <div className={inputStyles.value}>
        {
          this.props.type === 'checkboxes' && this.state.selectionTitles
            ? this.state.selectionTitles.join(', ')
            : this.state.selectionTitles
        }
        {this.props.centerAligned && <span className={styles.centerCaret}>
          <FaChevronDown style={{ fontSize: '.7em' }} />
        </span>}
      </div>

      {!this.props.centerAligned && <div className={styles.caret} >
        <FaChevronDown style={{ fontSize: '.7em' }} />
      </div>}

      <div ref={ref => { this.containerRef = ref }} onMouseDown={e => { e.stopPropagation() }} className={classNames({
        [styles.dropdown]: true,
        [styles.open]: this.state.focused && !this.props.inactive
      })}>
        <Flex column className={styles.container}>
          <SelectMenu
            options={options}
            type={this.props.type}
            active={this.state.active}
            selection={this.state.selection}
            selectionIndices={this.state.selectionIndices}
            menuStates={this.state.menuStates}
            toggleSubmenu={this.toggleSubmenu}
            onSelect={this.onSelect}
            onHover={this.onHover} />
        </Flex>
      </div>
      {
        (this.props.error || this.state.error) &&
          <span className={inputStyles.errorMessage}>
            {this.state.error || this.props.error}
          </span>
      }
    </div>
  }
}

Select.propTypes = {
  /** Select component title */
  title: PropTypes.string,
  /** Selection type: 'single','checkboxes' */
  type: PropTypes.string,
  /** Selection data */
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    /* string */
    PropTypes.string,
    /* number */
    PropTypes.number,
    /* complex object with title/value etc. */
    PropTypes.shape({
      /** Title/key of the item */
      title: PropTypes.string.isRequired,
      /** Item value */
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Nested items (creates a submenu) */
      nested: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      }))
    })
  ])),
  /** The Default value that's selected on render */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Required field? */
  required: PropTypes.bool,
  /** Component state, enabled/disabled */
  inactive: PropTypes.bool,
  /** Error message string */
  error: PropTypes.string,
  /** Function to listen for the change if we'd like to do something extra */
  onSelect: PropTypes.func,
  /** Custom class name for overriding styling */
  className: PropTypes.string,
  /** Center aligned text? */
  centerAligned: PropTypes.bool,
  /** Design Style */
  design: PropTypes.oneOf(['filter', 'standard', 'light', 'default'])
}
Select.defaultProps = {
  design: 'default'
}

export default Select
