export function request () {
  return {
    totalRecords: 33,
    pages: 4,
    currentPage: 1,
    pageSize: 10,
    sortOn: 'symbol',
    sortDir: 'DESC',
    records: [
      {
        positionDate: 1507608000000,
        securityId: 220949,
        securityName: 'Vodacom Group Ltd. Sponsored ADR',
        symbol: 'VDMCY',
        venue: 'Other OTC',
        regShoFlag: false,
        isRule4320: true,
        tier: '20',
        tierName: 'OTC Pink Current',
        tierGroupId: 'PS',
        rule4320: true
      },
      {
        positionDate: 1507608000000,
        securityId: 132715,
        securityName: 'Turk Telekomunikasyon Unsponsored American Depository Receipt (Turkey)',
        symbol: 'TRKNY',
        venue: 'Other OTC',
        regShoFlag: false,
        isRule4320: true,
        tier: '20',
        tierName: 'OTC Pink Current',
        tierGroupId: 'PS',
        rule4320: true
      },
      {
        positionDate: 1507608000000,
        securityId: 269296,
        securityName: 'TGR Finl Inc Common Stock',
        symbol: 'TGRF',
        venue: 'Other OTC',
        regShoFlag: false,
        isRule4320: true,
        tier: '2',
        tierName: 'OTCQX U.S.',
        tierGroupId: 'QX',
        rule4320: true
      },
      {
        positionDate: 1507608000000,
        securityId: 125329,
        securityName: 'Tencent Holdings Limited Unsponsored ADR Representing B Shares (Cayman Islands)',
        symbol: 'TCEHY',
        venue: 'Other OTC',
        regShoFlag: false,
        isRule4320: true,
        tier: '20',
        tierName: 'OTC Pink Current',
        tierGroupId: 'PS',
        rule4320: true
      },
      {
        positionDate: 1507608000000,
        securityId: 253849,
        securityName: 'SHIMANO INC  American Depositary Receipts - Unsponsored (Japan)',
        symbol: 'SMNNY',
        venue: 'Other OTC',
        regShoFlag: false,
        isRule4320: true,
        tier: '20',
        tierName: 'OTC Pink Current',
        tierGroupId: 'PS',
        rule4320: true
      },
      {
        positionDate: 1507608000000,
        securityId: 130776,
        securityName: 'Smiths Group Plc Sponsored ADR (UK)',
        symbol: 'SMGZY',
        venue: 'Other OTC',
        regShoFlag: false,
        isRule4320: true,
        tier: '20',
        tierName: 'OTC Pink Current',
        tierGroupId: 'PS',
        rule4320: true
      },
      {
        positionDate: 1507608000000,
        securityId: 115600,
        securityName: 'Pharma-Bio Serv, Inc. Common Stock',
        symbol: 'PBSV',
        venue: 'Other OTC',
        regShoFlag: true,
        isRule4320: false,
        tier: '10',
        tierName: 'OTCQB',
        tierGroupId: 'DQ',
        rule4320: false
      },
      {
        positionDate: 1507608000000,
        securityId: 64688,
        securityName: 'Oil Search Ltd Sponsored ADR',
        symbol: 'OISHY',
        venue: 'Other OTC',
        regShoFlag: false,
        isRule4320: true,
        tier: '20',
        tierName: 'OTC Pink Current',
        tierGroupId: 'PS',
        rule4320: true
      },
      {
        positionDate: 1507608000000,
        securityId: 75877,
        securityName: 'PJSC Gazprom American Depositary Receipts - Sponsored',
        symbol: 'OGZPY',
        venue: 'Other OTC',
        regShoFlag: false,
        isRule4320: true,
        tier: '20',
        tierName: 'OTC Pink Current',
        tierGroupId: 'PS',
        rule4320: true
      },
      {
        positionDate: 1507608000000,
        securityId: 251098,
        securityName: 'Massroots, Inc. Common Stock',
        symbol: 'MSRT',
        venue: 'Other OTC',
        regShoFlag: false,
        isRule4320: true,
        tier: '10',
        tierName: 'OTCQB',
        tierGroupId: 'DQ',
        rule4320: true
      }
    ],
    singular: 'Threshold Security',
    plural: 'Threshold Securities'
  }
}
