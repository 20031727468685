import React from 'react'
import ExternalLink from '../ExternalLink'
import { FaEnvelope, FaPhone } from 'react-icons/fa'
import styles from './FixedIncome211NotEntitled.module.scss'

const FixedIncome211NotEntitled = () => {
  return <div className={styles.container}>
    <h4>
    OTC Markets Fixed Income 15c2-11 Service provides 211 compliance data for more than 130,000 OTC corporate and equity linked fixed income securities.
    </h4>
    <div className={styles.spacer} />
    <div>
      For more information and/or a free trial, please contact Market Data at
    </div>
    <div className={styles.link}>
      <ExternalLink target='_self' href='mailto:marketdata@otcmarkets.com'><FaEnvelope /> marketdata@otcmarkets.com</ExternalLink>
    </div>
    <div className={styles.phone}>
      <FaPhone /> 212.220.2166
    </div>
  </div>
}

export default FixedIncome211NotEntitled
