import React from 'react'
import PropTypes from 'prop-types'
import styles from './Tooltip.module.scss'
import classNames from 'classnames'

class Tooltip extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      show: false,
      active: false,
      top: 0,
      left: 0,
      triangleLeft: 0,
      placement: this.props.placement
    }

    this.delayShow = null
    this.delayHide = null
    this.tipOffset = 12
    this.verticalOffset = 20
  }

  componentDidMount () {
    this.mount = true
  }

  componentWillUnmount () {
    this.mount = false
    window.removeEventListener('scroll', this.hideTooltip)
    clearTimeout(this.delayShow)
  }

  // display tooltip
  showTooltip = (e) => {
    if ((this.props.text && this.props.text !== '') || this.props.content) {
      this.setState({
        active: true
      }, () => {
        window.addEventListener('scroll', this.hideTooltip)
        this.updatePosition()
        this.updateTooltip(e)
      })
    }
  }

  // update tooltip state
  updateTooltip = (e) => {
    if (this.state.active) {
      const updateState = () => {
        this.setState({
          show: true
        }, () => {
          this.updatePosition()
        })
      }

      clearTimeout(this.delayShow)

      if (this.props.delay && !this.props.testing) {
        this.delayShow = setTimeout(updateState, this.props.delay)
      } else {
        updateState()
      }
    }
  }

  // hide tooltip component
  hideTooltip = (e) => {
    if (!this.mount) return

    this.delayHide = setTimeout(() => {
      this.setState({
        show: false,
        active: false
      }, () => {
        window.removeEventListener('scroll', this.hideTooltip)
      })
    }, this.props.keepTooltip ? this.props.delayHide : 0)

    clearTimeout(this.delayShow)
  }

  // keep the tooltip if the user is interacting with the tooltip popup.
  keepTooltip = (e) => {
    if (!this.mount) return
    if (this.delayHide && this.props.keepTooltip) {
      clearTimeout(this.delayHide)
    }
  }

  // set tooltip position relative to the target
  updatePosition = (e) => {
    window.requestAnimationFrame(() => {
      if (this.element) {
        const tipWidth = this.element.clientWidth
        const tipHeight = this.element.clientHeight
        const targetRect = this.targetElement ? this.targetElement.getBoundingClientRect() : {}
        const targetWidth = targetRect.width
        const targetHeight = targetRect.height
        const tipOffset = this.tipOffset
        const verticalOffset = this.verticalOffset // vertical offset for left/right aligned items
        let triangleLeft = tipOffset

        // get parent width/height
        const parent = this.targetElement

        const parentTop = (parent && !this.props.testing) ? parent.getBoundingClientRect().top : 0
        const parentLeft = (parent && !this.props.testing) ? parent.getBoundingClientRect().left : 0

        const placements = {
          top: {
            left: 0,
            top: -(tipOffset + tipHeight + tipOffset)
          },
          bottom: {
            left: 0,
            top: (targetHeight + tipOffset)
          },
          left: {
            left: -(tipWidth + tipOffset),
            top: -verticalOffset
          },
          right: {
            left: targetWidth + tipOffset,
            top: -verticalOffset
          }
        }

        let { placement } = this.props

        let offsetLeft = placements[placement].left
        let offsetTop = placements[placement].top

        // change tip placement if it goes beyond window boundaries
        if (parentLeft + offsetLeft < 0 && placement === 'left') {
          placement = 'right'
        } else if (parentLeft + offsetLeft + tipWidth > window.innerWidth && placement === 'right') {
          placement = 'left'
        } else if (parentTop + offsetTop < 0 && placement === 'top') {
          placement = 'bottom'
        } else if (parentTop + offsetTop + tipHeight > window.innerHeight && placement === 'bottom') {
          placement = 'top'
        }

        offsetLeft = placements[placement].left
        offsetTop = placements[placement].top

        // adjust both tip and 'triangle' placement if the tip popup extends beyond
        // window width
        if (offsetLeft + tipWidth + parentLeft > window.innerWidth) {
          const previousOffset = offsetLeft
          offsetLeft = window.innerWidth - tipWidth - tipOffset - parentLeft
          triangleLeft = previousOffset - offsetLeft + tipOffset
        }

        this.setState({
          left: offsetLeft,
          top: offsetTop,
          triangleLeft,
          placement
        })
      }
    })
  }

  setElement = () => {
    return (element) => {
      if (element) {
        this.element = element
      }
    }
  }

  setTargetElement = () => {
    return (element) => {
      if (element) {
        this.targetElement = element
      }
    }
  }

  render () {
    const verticleTriangle = this.props.placement === 'left' || this.props.placement === 'right'
    const content = this.props.text || this.props.content

    return <span className={styles.tooltipContainer} onMouseLeave={this.hideTooltip}>
      <div
        onMouseEnter={this.keepTooltip}
        className={classNames({
          [styles.tooltip]: true,
          [styles.tooltipOpen]: this.state.show,
          [styles.top]: this.state.placement === 'top',
          [styles.bottom]: this.state.placement === 'bottom',
          [styles.left]: this.state.placement === 'left',
          [styles.right]: this.state.placement === 'right'
        })}
        ref={this.setElement()}
        style={{ left: this.state.left + 'px', top: this.state.top + 'px', width: this.props.width }}>
        <div
          className={styles.triangle}
          style={{
            left: verticleTriangle ? null : (this.state.triangleLeft + 'px')
          }} />
        {this.props.title && <div className={styles.title}>{this.props.title}</div>}
        {this.props.title && content && <div className={styles.divider} />}
        {content && <div className={styles.content}>{content}</div>}
      </div>
      <span
        onMouseEnter={this.showTooltip}
        ref={this.setTargetElement()}>
        {this.props.children}
      </span>
      {this.props.testing && <div
        onSetElement={this.setElement}
        onSetTargetElement={this.setTargetElement} />}
    </span>
  }
}

Tooltip.propTypes = {
  children: PropTypes.node,
  /** Tooltip delay in ms */
  delay: PropTypes.number,
  /** Tooltip closing delay in ms */
  delayHide: PropTypes.number,
  /** Keep tooltips open for the duration specified in 'delayHide' */
  keepTooltip: PropTypes.bool,
  /** Tooltip content (text) */
  text: PropTypes.string,
  /** Tooltip content */
  content: PropTypes.node,
  /** Tooltip title */
  title: PropTypes.string,
  /** Tooltip placement: 'left', 'right', 'top' or 'bottom' */
  placement: PropTypes.string,
  /** Tooltip width */
  width: PropTypes.string,
  /** [Reserved] test mode flag to turn off delays etc. */
  testing: PropTypes.bool
}

Tooltip.defaultProps = {
  delay: 150,
  delayHide: 150,
  keepTooltip: false,
  placement: 'bottom',
  testing: false
}

export default Tooltip
