import PropTypes from 'prop-types'
import { format } from '../../utils/locale'

const Locale = (props) => format(props.children, props.placeholder || 'N/A', props.type, props.shorten)

Locale.propTypes = {
  shorten: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
  placeholder: PropTypes.string
}

export default Locale
