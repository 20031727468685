import React from 'react'
import { getShortSaleDates, getShortSaleDataByTier } from '../../api/otc/stock/short-sales'
import { template, fetch, expand, sort } from '../../api/helper'
import styles from './MAShortSalePage.module.scss'

import HeadTitle from '../../components/HeadTitle'
import Flexgrid from '../../components/Flexgrid'
import Outline from '../../components/Outline'
import Select from '../../components/Select'
import StockQuote from '../../components/StockQuote'
import Table from '../../components/Table'
import More from '../../components/More'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import ExternalLink from '../../components/ExternalLink'
import Sticky from '../../components/Sticky'

/** Headers for Table */
const columns = [
  {
    name: 'positionDate',
    header: 'Date',
    type: 'date',
    sortingEnabled: false,
    hideOnMobile: true
  },
  {
    name: 'symbol',
    header: 'Symbol',
    headerShort: 'Sym',
    type: 'symbol',
    sortingEnabled: true
  },
  {
    name: 'securityName',
    header: 'Security Name'
  },
  {
    name: 'shortInterest',
    header: 'Short Interest',
    type: 'int',
    sortingEnabled: true,
    sortingKey: 'volume'
  },
  {
    name: 'pctChgVolume',
    header: '% Change',
    type: 'sign',
    sortingEnabled: true,
    hideOnMobile: true
  },
  {
    name: 'avgDailyVolume',
    header: 'Avg Daily Vol',
    type: 'int',
    sortingEnabled: true,
    hideOnMobile: true
  },
  {
    name: 'daysToCover',
    header: 'Days To Cover',
    sortingEnabled: true,
    align: 'right',
    hideOnMobile: true,
    hideOnTablet: true
  }
]

class MAShortSalePage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      /** Store the symbol to query on */
      symbol: '',
      /** Store the position date which is meant to be the ID (epoch time) */
      positionDate: '',
      table: template('records')
    }
  }

  /**
   * Get the dates to filter on.
   * Set the first date as the default filter date.
   * Get the data for that date.
   */
  componentDidMount () {
    getShortSaleDates()
      .then(dates => {
        this.setState({
          ...this.state,
          dates,
          positionDate: dates[0].id
        }, this.loadList)
      })
  }

  /**
   * When the user clicks on a symbol they searched for.
   * @param {string} key - The symbol key. ex: GOOG
   * @param {string} item - The company name for the symbol. ex: Alphabet Inc.
   */
  onClickSymbol = (key, item) => {
    if (key === '' && this.state.symbol === '') return
    this.setState({
      ...this.state,
      symbol: key
    }, this.loadList)
  }

  /**
   * If the text is empty in the symbol input, clear the filters and requery.
   * @param {string} text - The text in the input.
   */
  onChangeSymbol = (text) => {
    if (!text) {
      this.onClickSymbol('', '')
    }
  }

  /**
   * When the user selects an item in the date dropdown.
   * Updates the positionDate in local state and calls the API again to get the new data.
   */
  onSelectDate = (key, item) => {
    this.setState({
      positionDate: key || this.state.dates[0].id
    }, () => {
      this.loadList()
    })
  }

  /**
   * Load the data from a specific date at a specific page.
   */
  loadList = () => {
    const { positionDate, symbol } = this.state
    fetch(this, getShortSaleDataByTier, 'table', {
      date: positionDate,
      symbol,
      page: 1,
      pageSize: 25
    })
  }

  render () {
    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['filter', 'info', 'table']
        }
      ],
      md: [
        {
          type: 'flex',
          column: true,
          subtree: ['filter', 'info', 'table']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['filter', 'info']
            },
            {
              type: 'main',
              subtree: ['table']
            }
          ]
        }
      ]
    }

    const filterLayouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['symbol', 'dates']
        }
      ],
      md: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'box',
              width: 1 / 2,
              className: styles.filterLeft,
              id: 'symbol'
            },
            {
              type: 'box',
              width: 1 / 2,
              className: styles.filterRight,
              id: 'dates'
            }
          ]
        }
      ]
    }

    return (
      <div className={styles.MACurrentMarketPage}>
        <HeadTitle title='Short Interest Data | Customer Support' />
        <PageTitle>Short Interest Data</PageTitle>
        <Flexgrid layouts={layouts}>
          <Sticky key='filter' className={styles.sideColumn}>
            <Flexgrid layouts={filterLayouts} className={styles.filter}>
              <StockQuote
                design='standard'
                onSelect={this.onClickSymbol}
                onChange={this.onChangeSymbol}
                hideFixedIncome
                key='symbol' />
              <Select title='Dates'
                design='standard'
                options={this.state.dates
                  ? this.state.dates.map(d => {
                    d.value = d.id
                    d.title = d.caption
                    return d
                  })
                  : []}
                onSelect={this.onSelectDate}
                key='dates' />
            </Flexgrid>
            <div key='info' className={styles.info}>
              <p>
              Short position data for OTC equity securities must be reported by FINRA Member firms
              (<ExternalLink href='https://www.finra.org/rules-guidance/rulebooks/finra-rules/4560'>FINRA Rule 4560</ExternalLink>)
              twice monthly. The table below groups securities by the settlement date of short interest reporting.
              Historical details at the symbol level may be found by selecting the symbol link. For more
              information on short interest reporting, please go <ExternalLink href='http://www.finra.org/industry/short-interest-reporting'>here</ExternalLink>.
              </p>
            </div>
          </Sticky>
          <div key='table'>
            <Outline mode='heading'>Short Interest By Symbol</Outline>
            <Loading
              type='table'
              loaded={this.state.table.loaded}
              reloading={this.state.table.reloading}
              error={this.state.table.error}
              data={this.state.table.data.records}>
              <Table
                columns={columns}
                data={this.state.table.data.records}
                sortColumn={this.state.table.sortOn}
                sortDirection={this.state.table.sortDir}
                onSorting={sort(this, getShortSaleDataByTier, 'table')}
                showHeader
              />
              <TableFooter>
                <More onClick={expand(this, getShortSaleDataByTier, 'table')}
                  disabled={this.state.table.expandEmpty} />
                {this.state.table.data.records && this.state.table.data.totalRecords &&
                  <DisplayResults show={this.state.table.data.records.length} total={this.state.table.data.totalRecords} text={'Securities'} />
                }
              </TableFooter>
            </Loading>
          </div>
        </Flexgrid>
      </div>
    )
  }
}

export default MAShortSalePage
