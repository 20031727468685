import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import { getVideoUrl } from '../../utils/news-urls'

const Video = ({ video }) => {
  let thumbnail = video.thumbnailUrl

  if (!thumbnail && video.url) {
    // try to fetch thumbnail from Youtube
    if (video.url.indexOf('youtube.com') !== -1 ||
    video.url.indexOf('youtu.be') !== -1) {
      const exp = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i
      const extractedValues = video.url.match(exp)
      const videoId = extractedValues ? extractedValues[1] : ''
      thumbnail = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    }
  }

  // deafult thumbnail
  if (!thumbnail) thumbnail = '/placeholders/video-thumbnail.png'

  return <div
    className={styles.video} >
    <div className={styles.content}>
      <Link to={getVideoUrl(video.symbol, video)}>
        <img src={thumbnail} />
      </Link>
    </div>
    {video.title && <div className={styles.title}>{video.title}</div>}
  </div>
}

Video.propTypes = {
  video: PropTypes.object
}

export default Video
