import api from '../api'

export async function securitySearch (input, hideFixedIncome) {
  let query

  if (input.includes(' ')) {
    query = `companyname:*${input.replace(/ /g, String.raw`\ `)}*`
  } else {
    query = `symbol:${input} OR symbol:${input}* OR companyname:*${input}* OR cusip:${input}*`
  }

  if (hideFixedIncome) {
    query = query + ' AND isFixedIncome:false'
  }

  const response = await api.request({
    method: 'get',
    url: '/solr/web_canari/select',
    otcAPI: true,
    internalAPI: true,
    params: {
      q: query,
      rows: 10
    }
  })

  return response.data.response || response
}
