import React from 'react'
import styles from './MenuLink.module.scss'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import ExternalLink from '../ExternalLink'
import Icon from 'react-icons-kit'
import { FaChevronDown } from 'react-icons/fa'
import { userCircleO } from 'react-icons-kit/fa/userCircleO'
import { withContext } from '../../AuthContext'

class MenuLink extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      open: false
    }
  }

  collapse = () => {
    this.setState({ open: false })
  }

  open = () => {
    this.setState({ open: true })
  }

  render () {
    return <div className={classnames(styles.component, { [styles.icon]: this.props.text === 'Profile' })} onMouseEnter={this.open} onMouseLeave={this.collapse}>
      {this.props.text === 'Profile'
        ? <Icon className={styles.profileIcon} icon={userCircleO} />
        : <Link to={this.props.to}><div className={styles.header} onClick={this.collapse}>
        <span>{this.props.text}</span>
        {this.props.sublinks && <FaChevronDown className={classnames(styles.chevron, {
          [styles.chevronUp]: this.state.open
        })} />}
      </div></Link>}
      {(this.props.sublinks && this.state.open) && <div className={styles.dropdown} onClick={this.collapse}>
        {this.props.sublinks.map(({ link, href, to }, i) =>
          href
            ? <ExternalLink key={i} href={href} className={styles.link}>{link}</ExternalLink>
            : (to === 'logout') ? <div key={i} onClick={this.props.context.logout} className={styles.link}>{link}</div> : <Link key={i} to={to} className={styles.link}>{link}</Link>
        )}
      </div>}
    </div>
  }
}

MenuLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  sublinks: PropTypes.array,
  context: PropTypes.object
}

export default withContext(MenuLink)
