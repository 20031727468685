export default {
  incomeStatement: [{
    name: 'periodEndDate',
    title: null,
    gray: true,
    bold: true,
    hideOnMobile: true,
    hideOnTablet: true
  }, {
    title: 'Revenues',
    bold: true,
    separator: true
  }, {
    name: 'totalRevenue',
    title: 'Total Revenue'
  }, {
    name: 'costOfRevenue',
    title: 'Cost of Revenue'
  }, {
    name: 'grossProfit',
    title: 'Gross Profit',
    totals: true,
    bottomSpacing: true
  }, {
    title: 'Operating Expenses',
    bold: true,
    separator: true
  }, {
    name: 'researchAndDevelopment',
    title: 'Research and Development',
    highlightRow: true
  }, {
    name: 'salesGeneralAndAdmin',
    title: 'Sales, General and Admin'
  }, {
    name: 'nonRecurringItems',
    title: 'Non-Recurring Items'
  }, {
    name: 'otherExpenses',
    title: 'Other'
  }, {
    name: 'totalExpenses',
    title: 'Total Expenses',
    totals: true
  }, {
    name: 'operatingIncome',
    title: 'Operating Income',
    totals: true,
    bottomSpacing: true
  }, {
    title: 'Income from Continuing Operations',
    bold: true,
    separator: true
  }, {
    name: 'additionalIncomeExpenseItems',
    title: 'Additional Income / Expense Items'
  }, {
    name: 'earningsBeforeInterestTaxes',
    title: 'Earnings Before Interest and Taxes'
  }, {
    name: 'interestExpense',
    title: 'Interest Expense'
  }, {
    name: 'earningsBeforeTax',
    title: 'Earnings Before Tax'
  }, {
    name: 'incomeTax',
    title: 'Income Tax'
  }, {
    name: 'minorityInterestEarnings',
    title: 'Minority Interest'
  }, {
    name: 'equityEarnings',
    title: 'Equity Earnings'
  }, {
    name: 'netIncomeOperations',
    title: 'Net Income Cont. Operations',
    totals: true,
    bottomSpacing: true
  }, {
    title: 'Non-Recurring Events',
    bold: true,
    separator: true
  }, {
    name: 'discontinuedOperations',
    title: 'Discontinued Operations'
  }, {
    name: 'extraordinaryOperations',
    title: 'Extraordinary Operations'
  }, {
    name: 'effectOfAccountingChanges',
    title: 'Effect of Accounting Changes',
    bottomSpacing: true
  }, {
    name: 'netIncome',
    title: 'Net Income',
    totals: true,
    topBorder: true,
    bottomSpacing: true
  }, {
    title: 'Key Financial Ratios',
    bold: true,
    separator: true
  }, {
    name: 'earningsperShare',
    title: 'Earnings Per Share'
  }, {
    name: 'priceEarnings',
    title: 'Price/Earnings'
  }, {
    name: 'grossMargin',
    title: 'Gross Margin'
  }, {
    name: 'operatingMargin',
    title: 'Operating Margin'
  }],
  balanceSheet: [{
    name: 'periodEndDate',
    title: null,
    gray: true,
    bold: true,
    hideOnMobile: true,
    hideOnTablet: true
  }, {
    title: 'Assets',
    bold: true,
    separator: true
  }, {
    title: 'Current Assets',
    bold: true,
    subSeparator: true,
    topBorder: true
  }, {
    name: 'cashAndCashEquivalents',
    title: 'Cash and Cash Equivalents',
    subRow: true
  }, {
    name: 'shortTermInvestments',
    title: 'Short Term Investments',
    subRow: true
  }, {
    name: 'netReceivables',
    title: 'Net Receivables',
    highlightRow: true,
    subRow: true
  }, {
    name: 'inventory',
    title: 'Inventory',
    subRow: true
  }, {
    name: 'otherCurrentAssets',
    title: 'Other Current Assets',
    subRow: true
  }, {
    name: 'totalCurrentAssets',
    title: 'Total Current Assets',
    bold: true
  }, {
    title: 'Long Term Assets',
    bold: true,
    subSeparator: true
  }, {
    name: 'longTermInvestments',
    title: 'Long Term Investments',
    subRow: true
  }, {
    name: 'propertyPlantAndEquipment',
    title: 'Property Plant and Equipment',
    subRow: true
  }, {
    name: 'goodwill',
    title: 'Goodwill',
    subRow: true
  }, {
    name: 'intangibleAssets',
    title: 'Intangible Assets',
    subRow: true
  }, {
    name: 'otherAssets',
    title: 'Other Assets',
    subRow: true
  }, {
    name: 'deferredLongTermAssetsCharges',
    title: 'Deferred Long Term Asset Charges',
    subRow: true
  }, {
    name: 'totalAssets',
    title: 'Total Assets',
    totals: true,
    bottomSpacing: true
  }, {
    name: '',
    title: 'Liabilities',
    bold: true,
    separator: true
  }, {
    title: 'Current Liabilities',
    bold: true,
    subSeparator: true,
    topBorder: true
  }, {
    name: 'accountPayable',
    title: 'Accounts Payable',
    subRow: true
  }, {
    name: 'shortTermAndCurrentLongTermDebt',
    title: 'Short Term and Current Long Term Debt',
    subRow: true
  }, {
    name: 'otherLiabilities',
    title: 'Other Liabilities',
    subRow: true
  }, {
    name: 'totalCurrentLiabilities',
    title: 'Total Current Liabilities',
    bold: true
  }, {
    title: 'Long Term Liabilities',
    bold: true,
    subSeparator: true
  }, {
    name: 'longTermDebt',
    title: 'Long Term Debt',
    subRow: true
  }, {
    name: 'otherLiabilitiesWithContingencies',
    title: 'Other Liabilities',
    subRow: true
  }, {
    name: 'deferredLongTermLiabilityCharges',
    title: 'Deferred Long Term Liability Charges',
    subRow: true
  }, {
    name: 'minorityInterest',
    title: 'Minority Interest',
    subRow: true
  }, {
    name: 'totalLiabilities',
    title: 'Total Liabilities',
    totals: true,
    bottomSpacing: true
  }, {
    title: 'Stockholders\' Equity',
    separator: true
  }, {
    name: 'preferredStock',
    title: 'Preferred Stock'
  }, {
    name: 'commonStock',
    title: 'Common Stock'
  }, {
    name: 'retainedEarnings',
    title: 'Retained Earnings'
  }, {
    name: 'treasuryStock',
    title: 'Treasury Stock'
  }, {
    name: 'capitalSurplus',
    title: 'Capital Surplus'
  }, {
    name: 'otherStockholderEquity',
    title: 'Other Stockholder Equity'
  }, {
    name: 'totalStockholderEquity',
    title: 'Total Stockholder Equity',
    totals: true
  }, {
    name: 'totalLiablitiesAndStockholderEquity',
    title: 'Total Liabilities and Stockholders\' Equity',
    totals: true,
    bottomSpacing: true
  }, {
    name: 'netTangibleAssets',
    title: 'Net Tangible Assets',
    totals: true,
    topBorder: true
  }],
  cashFlow: [{
    name: 'periodEndDate',
    title: null,
    gray: true,
    bold: true,
    hideOnMobile: true,
    hideOnTablet: true
  }, {
    title: 'Operating Activities',
    bold: true,
    separator: true
  }, {
    name: 'netIncome',
    title: 'Net Income',
    totals: true
  }, {
    name: 'depreciation',
    title: 'Depreciation'
  }, {
    name: 'adjustmentsToNetIncome',
    title: 'Adjustments to Net Income'
  }, {
    name: 'changesInLiabilities',
    title: 'Changes in Liabilities'
  }, {
    name: 'changesInAccountReceivables',
    title: 'Changes in Accounts Receivables'
  }, {
    name: 'changesInInventories',
    title: 'Changes in Inventories'
  }, {
    name: 'changesInOtherOperatingActivities',
    title: 'Changes in Other Operating Activities'
  }, {
    name: 'totalCashFlowFromOperatingActivities',
    title: 'Total Cash Flow From Operating Activities',
    totals: true,
    bottomSpacing: true
  }, {
    title: 'Investing Activities',
    bold: true,
    separator: true
  }, {
    name: 'capitalExpenditures',
    title: 'Capital Expenditures'
  }, {
    name: 'investments',
    title: 'Investments'
  }, {
    name: 'otherCashFlowFromInvestingActivities',
    title: 'Other Cash Flows From Investing Activities'
  }, {
    name: 'totalCashFlowFromInvestingActivities',
    title: 'Total Cash Flow From Investing Activities',
    totals: true,
    bottomSpacing: true
  }, {
    title: 'Financing Activities',
    bold: true,
    separator: true
  }, {
    name: 'dividendsPaid',
    title: 'Dividends Paid'
  }, {
    name: 'salePurchaseOfStock',
    title: 'Sale/Purchase of Stock'
  }, {
    name: 'netBarrowings',
    title: 'Net Borrowings'
  }, {
    name: 'otherCashFlowsFromFinancingActivities',
    title: 'Other Cash Flows From Financing Activities'
  }, {
    name: 'totalCashFlowsFromFinancingActivities',
    title: 'Total Cash Flow From Financing Activities',
    totals: true
  }, {
    name: 'effectOfExchangeRateChanges',
    title: 'Effect of Exchange Rate Changes',
    bottomSpacing: true
  }, {
    name: 'changeInCashAndEquities',
    title: 'Change in Cash and Cash Equivalents',
    totals: true,
    topBorder: true
  }]
}
