import React, { PureComponent } from 'react'
import styles from './DBrokerDealerPage.module.scss'
import { getList, downloadBrokerDealers } from '../../api/otc/directory/broker-dealer'
import { template, fetch, expand, sort } from '../../api/helper'
import * as Routes from '../../constants/Routes'

import HeadTitle from '../../components/HeadTitle'
import Flexgrid from '../../components/Flexgrid'
import Table from '../../components/Table'
import Outline from '../../components/Outline'
import More from '../../components/More'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import OtherDirectories from '../../components/OtherDirectories'
import Sticky from '../../components/Sticky'
import DirSearch from '../../components/DirSearch'

const getLocation = data => {
  const primaryAddress = data.addresses[0]

  if (!primaryAddress) {
    return '-'
  }

  return `${primaryAddress.city}, ${primaryAddress.state}`
}

const getBrokerDealerDetailLink = data => {
  return `${Routes.DIR_BROKER_DEALER}/${data.mpid}`
}

const columns = [
  {
    name: 'name',
    header: 'Market Participant Name',
    type: 'string',
    sortingEnabled: true,
    linkTo: (item) => getBrokerDealerDetailLink(item)
  },
  {
    name: 'mpid',
    header: 'MPID',
    sortingEnabled: true,
    linkTo: (item) => getBrokerDealerDetailLink(item)
  },
  {
    name: 'addr1',
    header: 'Location',
    component: ({ data }) => { // eslint-disable-line react/prop-types
      const location = getLocation(data)

      return (
        <div>
          {location}
        </div>
      )
    }
  },
  {
    name: 'quotedSecurities',
    header: 'Quoted Securites',
    type: 'int'
  }
]

export default class DBrokerDealerPage extends PureComponent {
  state = {
    loaded: false,
    list: template('records'),
    selectedLoadedPage: 1,
    searchQuery: null
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = () => {
    fetch(this, getList, 'list', {
      page: 1,
      pageSize: 20,
      search: this.state.searchQuery
    })
  }

  onSearch = searchQuery => {
    this.setState({ searchQuery }, this.loadData)
  }

  render () {
    const { list } = this.state

    const layouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['search', 'table', 'other-directories']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['sticky-column']
            },
            {
              type: 'main',
              mt: '1.8rem',
              subtree: ['table']
            }
          ]
        }
      ]
    }

    const dirSearch = <DirSearch key='search' onSearch={this.onSearch} placeholder='Name / MPID' value={this.state.searchQuery} />
    const otherDirectories = <OtherDirectories key='other-directories' />

    return (
      <div className={styles.component}>
        <HeadTitle title='Directory - Broker Dealer Directory' />
        <PageTitle>Broker-Dealer Directory</PageTitle>
        <Flexgrid layouts={layouts}>
          <div key='table' className={styles.table}>
            <Outline mode='heading'
              download={() => downloadBrokerDealers()}>
              Broker Dealers
            </Outline>
            <Loading
              type='table'
              data={list.data}
              error={list.error}
              emptyMessage='No broker dealers found'
              reloading={list.reloading}
              loaded={list.loaded}>
              <Table
                columns={columns}
                loaded={list.loaded}
                data={list.data.records}
                sortColumn={list.sortOn}
                sortDirection={list.sortDir}
                onSorting={sort(this, getList, 'list')}
                showHeader
              />
              <TableFooter>
                <More
                  onClick={expand(this, getList, 'list')}
                  disabled={list.expandEmpty} />
                {list.data.records && list.data.totalRecords &&
                  <DisplayResults show={list.data.records.length} total={list.data.totalRecords} text={'Broker-Dealers'} />
                }
              </TableFooter>
            </Loading>
          </div>
          <Sticky key='sticky-column' className={styles.sideColumn}>
            {dirSearch}
            {otherDirectories}
          </Sticky>

          {dirSearch}
          {otherDirectories}
        </Flexgrid>
      </div>
    )
  }
}
