import api from '../api'

export async function getPage (pageId) {
  const response = await api.request({
    method: 'get',
    responseFormat: 'data',
    url: `/pages/${pageId}`
  })
  return response.data
}

export async function getPageBySlug (path, preview = false) {
  const url = preview ? '/pages/path/preview' : '/pages/path'
  const response = await api.request({
    method: 'get',
    url,
    ignoreAuth: true,
    responseFormat: 'data',
    params: {
      path
    },
    privateAPI: preview
  })
  return response.data
}

export async function getHierarchy (path, preview = false) {
  const response = await api.request({
    method: 'get',
    url: '/pages/hierarchy',
    responseFormat: 'data'
  })
  return response.data
}

export async function search (query) {
  const response = await api.request({
    method: 'get',
    responseFormat: 'data',
    url: `/search?query=${query.keywords}&limit=${query.limit}&offset=${query.offset}`
  })
  return response.data
}

export async function getAppVersion () {
  const response = await api.request({
    method: 'get',
    nodeAPI: true,
    otcAPI: true,
    ignoreAuth: true,
    responseFormat: 'data',
    url: '/internal/health'
  })
  return response.data
}
