export function request (params) {
  return {
    canDistribute: true,
    companyId: 0,
    companyName: 'Mock Data',
    contents: 'Mock Data',
    correctedNewsId: 0,
    createdDate: '2018-01-10T13:42:07.611Z',
    deleteDate: '2018-01-10T13:42:07.611Z',
    display: true,
    displayDateTime: 'Mock Data',
    docList: [
      {
        description: 'Mock Data',
        id: 0,
        name: 'Mock Data',
        obfuscatedId: 0
      }
    ],
    documentList: [
      {
        attachmentUid: 'Mock Data',
        contents: [
          'Mock Data'
        ],
        description: 'Mock Data',
        edgarFilingInfo: {
          ccc: 'Mock Data',
          cik: 'Mock Data',
          edfId: 0,
          fileConversion: 'Mock Data',
          filingInstruction: 'Mock Data'
        },
        edgarFilingInfoId: 0,
        extension: 'Mock Data',
        filename: 'Mock Data',
        id: 0,
        newsId: 0,
        newsUid: 'Mock Data',
        url: 'Mock Data'
      }
    ],
    id: 0,
    immediate: true,
    isCaveatEmptor: true,
    isImmediate: true,
    isItemFromExternalProvider: true,
    lastModifiedDate: '2018-01-10T13:42:07.611Z',
    location: 'Mock Data',
    name: 'Mock Data',
    newsTypeDescript: 'Mock Data',
    primaryExchange: 'Mock Data',
    primarySymbol: 'Mock Data',
    releaseDate: '2018-01-10T13:42:07.611Z',
    releaseDateFormatted: 'Mock Data',
    releaseDateTimeFormatted: 'Mock Data',
    sourceId: 'Mock Data',
    statusDescript: 'Mock Data',
    statusId: 'Mock Data',
    symbol: 'Mock Data',
    tierCode: 'Mock Data',
    tierGroup: 'Mock Data',
    title: 'Mock Data',
    typeId: 'Mock Data',
    userId: 0,
    userName: 'Mock Data',
    userTitle: 'Mock Data',
    wasDistributed: true
  }
}
