import React from 'react'
import PropTypes from 'prop-types'
import styles from './Loading.module.scss'
import classNames from 'classnames'

const Loading = ({
  children, type, loaded, reloading, error, data,
  emptyMessage, height
}) => {
  let isDataEmpty = true
  const inlineStyles = {}

  if (Array.isArray(data)) {
    isDataEmpty = data.length <= 0
  } else if (typeof data === 'object' && Object.keys(data).length === 0) {
    isDataEmpty = true
  } else if (data && data.records && Array.isArray(data.records)) {
    isDataEmpty = data.records.length <= 0
  } else {
    isDataEmpty = !!data
  }

  if (height) {
    inlineStyles.minHeight = height
  }

  return <div className={classNames(styles.wrapper, { [styles.reloading]: reloading })}>
    {(loaded && isDataEmpty && !error) && <p className={styles.info}>
      {emptyMessage || 'Not available'}
    </p>}
    {error && <p className={styles.error}>
      {error || 'Data temporarily unavailable'}
    </p>}
    {((loaded || reloading) && !isDataEmpty) && children}
    {(!loaded && !reloading && !error) && <div
      style={inlineStyles}
      className={classNames({
        [styles.loading]: true,
        [styles.table]: type === 'table'
      })}>
      <div className={styles.spinner}>
        <div className={styles.inner} />
      </div>
    </div>}
  </div>
}

Loading.propTypes = {
  children: PropTypes.node,
  height: PropTypes.string,
  type: PropTypes.string,
  loaded: PropTypes.bool,
  reloading: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object
  ]),
  emptyMessage: PropTypes.string
}

export default Loading
