export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return {
        id: 655940,
        name: 'Alphabet Inc.',
        reportingStandardMin: 'SEC Reporting - Delinquent',
        isProfileVerified: false,
        tierGroup: null,
        hasLogo: false,
        companyLogoUrl: null,
        edgarFilingStatus: 'SEC Reporting',
        edgarFilingStatusId: 'F',
        isInternationalReporting: false,
        isAlternativeReporting: false,
        isOtherReporting: false,
        isCaveatEmptor: false,
        isBankrupt: false,
        venue: 'Nasdaq',
        tierStartDate: null,
        otcAward: {
          symbol: 'GOOG',
          best50: false,
          tenYear: false
        },
        indexStatuses: []
      }

    case 'OTCM':
      return {
        id: 674812,
        name: 'OTC Markets Group Inc.',
        reportingStandardMin: 'SEC Reporting - Current',
        isProfileVerified: false,
        tierGroup: 'QX',
        hasLogo: true,
        companyLogoUrl: '/company/logo/OTCM',
        edgarFilingStatus: 'SEC Reporting',
        edgarFilingStatusId: 'F',
        isInternationalReporting: false,
        isAlternativeReporting: false,
        isOtherReporting: false,
        isCaveatEmptor: false,
        isBankrupt: false,
        venue: 'OTC Link',
        tierStartDate: 1268283600000,
        otcAward: {
          symbol: 'OTCM',
          best50: false,
          tenYear: false
        },
        indexStatuses: [
          {
            indexSymbol: '.OTCQXUS',
            indexName: 'OTCQX U.S.'
          },
          {
            indexSymbol: '.OTCQX',
            indexName: 'OTCQX Composite'
          },
          {
            indexSymbol: '.OTCQXDIV',
            indexName: 'OTCQX Dividend'
          }
        ]
      }

    case 'VLKAY':
      return {
        id: 1471,
        name: 'Volkswagen AG',
        reportingStandardMin: 'International Reporting',
        isProfileVerified: false,
        tierGroup: 'PS',
        hasLogo: true,
        companyLogoUrl: '/company/logo/VLKAY',
        edgarFilingStatus: 'International Reporting',
        edgarFilingStatusId: 'G',
        isInternationalReporting: true,
        isAlternativeReporting: false,
        isOtherReporting: false,
        isCaveatEmptor: false,
        isBankrupt: false,
        venue: 'OTC Link',
        tierStartDate: 1449723600000,
        otcAward: {
          symbol: 'VLKAY',
          best50: false,
          tenYear: false
        },
        indexStatuses: [
          {
            indexSymbol: '.OTCDR',
            indexName: 'OTCM ADR INDEX'
          }
        ]
      }
  }
}
