import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Sticky.module.scss'

class Sticky extends Component {
  constructor (props) {
    super(props)
    this.state = {}

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount () {
    const el = document.getElementById('sticky')
    this.setState({ top: el.offsetTop, height: el.offsetHeight })
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll () {
    const el = document.getElementById('sticky')
    this.setState({ scroll: window.scrollY + 10, top: (el.offsetTop !== this.state.top && el.offsetTop !== 10) ? el.offsetTop : this.state.top })
  }

  render () {
    const { children, className } = this.props
    return (
      <div id='sticky' className={classNames(styles.component, className, { [styles.sticky]: this.state.scroll > this.state.top })}>
        {children && children}
      </div>
    )
  }
}

Sticky.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Sticky
