import api from '../../api'

export async function getHolidayDates(params) {
    const response = await api.request({
        method: 'get',
        otcAPI: true,
        internalAPI: true,
        ignoreAuth: true,
        params,
        url: '/holiday-api'
    })
    return response.data
}
