import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import styles from './DServiceProviderDetailPage.module.scss'
import * as Routes from '../../constants/Routes'
import { getDetails } from '../../api/otc/directory/service-provider'
import { template, fetch, expand, sort } from '../../api/helper'
import { Link } from 'react-router-dom'
import { FaBan } from 'react-icons/fa'
import { withRouter } from '../../components/WithRouter'
import NotFound from '../NotFound'
import ExternalLink from '../../components/ExternalLink'
import HeadTitle from '../../components/HeadTitle'
import Flexgrid from '../../components/Flexgrid'
import Outline from '../../components/Outline'
import PageTitle from '../../components/PageTitle'
import BackLink from '../../components/BackLink'
import Loading from '../../components/Loading'
import Table from '../../components/Table'
import More from '../../components/More'
import { Breadcrumbs } from '../../components/Breadcrumbs'

const columns = [
  {
    name: 'companyName',
    header: 'Name'
  },
  {
    name: 'companyCity',
    header: 'City',
    hideOnMobile: true
  },
  {
    name: 'companyCountry',
    header: 'Country'
  },
  {
    name: 'symbol',
    header: 'Symbol',
    type: 'symbol'
  },
  {
    name: 'tierName',
    header: 'tier',
    hideOnMobile: true
  }
]

class DServiceProviderDetailPage extends PureComponent {
  state = {
    details: template('records')
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = () => {
    const id = this.props.match.params.id
    if (!id) return this.setState({ notFound: true })
    const queryParams = queryString.parse(this.props.location.search)

    fetch(this, getDetails, 'details', {
      id,
      typeId: queryParams.t,
      page: 1,
      pageSize: 10
    })
  }

  get details () {
    const { details } = this.state

    if (!details.loaded && !details.reloading) {
      return <Loading loaded={details.loaded} />
    }
    const { serviceProviderInfo } = details.data

    return (
      <div className={styles.detailsInner}>
        <div className={styles.companyInfo}>
          <div className={styles.companyName}>
            {serviceProviderInfo.name}
          </div>
          <div className={styles.companyType}>
            {serviceProviderInfo.typeDesc}
          </div>
          {serviceProviderInfo.questionable === 'P' && <div className={styles.prohibited}>
            <Link to={Routes.DIR_PROH_SERVICE_PROVIDERS}>
              <FaBan /> Prohibited Service Provider
            </Link>
          </div>}
          <div className={styles.separator} />
          {serviceProviderInfo.addr1 && <div>{serviceProviderInfo.addr1}</div>}
          {serviceProviderInfo.addr2 && <div>{serviceProviderInfo.addr2}</div>}
          {serviceProviderInfo.addr3 && <div>{serviceProviderInfo.addr3}</div>}
          <div>
            {serviceProviderInfo.city}{serviceProviderInfo.state ? ', ' + serviceProviderInfo.state : ''} {serviceProviderInfo.zip}
          </div>
          {serviceProviderInfo.country !== 'USA' && <div>{serviceProviderInfo.country}</div>}
          {serviceProviderInfo.phone && <div>{serviceProviderInfo.phone}</div>}
          {serviceProviderInfo.website && <div>
            <ExternalLink href={serviceProviderInfo.website} external />
          </div>}
        </div>
      </div>
    )
  }

  render () {
    const { details } = this.state

    if ((details.loaded && !details.data.serviceProviderInfo) || details.error) return <NotFound />

    const serviceProviderInfo = details.data.serviceProviderInfo

    const layouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['back', 'details', 'content']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['back', 'details']
            },
            {
              type: 'main',
              mt: '1.8rem',
              subtree: ['content']
            }
          ]
        }
      ]
    }

    return (
      <div className={styles.component}>
        <Breadcrumbs items={[
          { title: 'Directories', slug: '/directories' },
          {
            title: 'Service Provider Directory',
            slug: Routes.DIR_SERVICE_PROVIDER
          }, (serviceProviderInfo && serviceProviderInfo.name) || '']
        } />
        <HeadTitle title='Directory - Service Provider Directory' />
        <PageTitle>Service Provider Directory</PageTitle>
        <Loading loaded={details.loaded || details.reloading} />
        {(details.loaded || details.reloading) && <Flexgrid layouts={layouts}>

          <BackLink to={Routes.DIR_SERVICE_PROVIDER} key='back' />
          <div key='details' className={styles.details}>
            {this.details}
          </div>
          <div key='content'>
            {serviceProviderInfo.description && <div className={styles.description}>
              {serviceProviderInfo.description}
            </div>}
            <Outline mode='heading' className={styles.tableHeading}>
              Associated Companies
            </Outline>
            <Loading type='table'
              data={details.data}
              reloading={details.reloading}
              loaded={details.loaded}>
              <Table
                columns={columns}
                loaded={details.loaded}
                data={details.data.records}
                sortColumn={details.sortOn}
                sortDirection={details.sortDir}
                onSorting={sort(this, getDetails, 'details')}
                showHeader />
              <More
                onClick={expand(this, getDetails, 'details')}
                disabled={details.expandEmpty} />
            </Loading>
          </div>
        </Flexgrid>}
      </div>
    )
  }
}

DServiceProviderDetailPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

export default withRouter(DServiceProviderDetailPage)
