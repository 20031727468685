export function request (params, pagination) {
  switch (params.duration) {
    case 'annual':
      switch (params.symbol) {
        case 'GOOG':
          return []

        case 'OTCM':
          return [
            {
              periodEndDate: 'Dec 31, 2016',
              totalRevenue: '48,565',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '21,205',
              nonRecurring: '-',
              otherOperatingExpenses: '1,606',
              operatingIncome: '16,927',
              totalOtherIncomeAndExpensesNet: '8',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '16,935',
              incomeTaxExpense: '6,407',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '10,528',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '10,528',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '10,528'
            },
            {
              periodEndDate: 'Dec 31, 2015',
              totalRevenue: '47,530',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '20,817',
              nonRecurring: '-',
              otherOperatingExpenses: '1,692',
              operatingIncome: '16,866',
              totalOtherIncomeAndExpensesNet: '27',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '16,893',
              incomeTaxExpense: '6,635',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '10,258',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '10,258',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '10,258'
            },
            {
              periodEndDate: 'Dec 31, 2014',
              totalRevenue: '39,827',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '17,773',
              nonRecurring: '-',
              otherOperatingExpenses: '1,543',
              operatingIncome: '12,902',
              totalOtherIncomeAndExpensesNet: '9',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '12,911',
              incomeTaxExpense: '5,021',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '7,890',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '7,890',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '7,890'
            },
            {
              periodEndDate: 'Dec 31, 2013',
              totalRevenue: '33,640',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '16,577',
              nonRecurring: '-',
              otherOperatingExpenses: '1,642',
              operatingIncome: '8,298',
              totalOtherIncomeAndExpensesNet: '(2)',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '8,296',
              incomeTaxExpense: '2,665',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '5,631',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '5,631',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '5,631'
            }
          ]

        case 'VLKAY':
          return [
            {
              periodEndDate: 'Dec 31, 2016',
              totalRevenue: '48,565',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '21,205',
              nonRecurring: '-',
              otherOperatingExpenses: '1,606',
              operatingIncome: '16,927',
              totalOtherIncomeAndExpensesNet: '8',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '16,935',
              incomeTaxExpense: '6,407',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '10,528',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '10,528',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '10,528'
            },
            {
              periodEndDate: 'Dec 31, 2015',
              totalRevenue: '47,530',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '20,817',
              nonRecurring: '-',
              otherOperatingExpenses: '1,692',
              operatingIncome: '16,866',
              totalOtherIncomeAndExpensesNet: '27',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '16,893',
              incomeTaxExpense: '6,635',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '10,258',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '10,258',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '10,258'
            },
            {
              periodEndDate: 'Dec 31, 2014',
              totalRevenue: '39,827',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '17,773',
              nonRecurring: '-',
              otherOperatingExpenses: '1,543',
              operatingIncome: '12,902',
              totalOtherIncomeAndExpensesNet: '9',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '12,911',
              incomeTaxExpense: '5,021',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '7,890',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '7,890',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '7,890'
            },
            {
              periodEndDate: 'Dec 31, 2013',
              totalRevenue: '33,640',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '16,577',
              nonRecurring: '-',
              otherOperatingExpenses: '1,642',
              operatingIncome: '8,298',
              totalOtherIncomeAndExpensesNet: '(2)',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '8,296',
              incomeTaxExpense: '2,665',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '5,631',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '5,631',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '5,631'
            }
          ]
      }
      break

    case 'semi-annual':
      switch (params.symbol) {
        case 'GOOG':
          return []

        case 'OTCM':
          return [
            {
              periodEndDate: 'Jun 30, 2017',
              totalRevenue: '50,247',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '21,774',
              nonRecurring: '-',
              otherOperatingExpenses: '1,573',
              operatingIncome: '18,074',
              totalOtherIncomeAndExpensesNet: '48',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '18,122',
              incomeTaxExpense: '6,268',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '11,854',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '11,854',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '11,854'
            },
            {
              periodEndDate: 'Jun 30, 2016',
              totalRevenue: '48,989',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '21,121',
              nonRecurring: '-',
              otherOperatingExpenses: '1,693',
              operatingIncome: '17,538',
              totalOtherIncomeAndExpensesNet: '-',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '17,538',
              incomeTaxExpense: '6,697',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '10,841',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '10,841',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '10,841'
            },
            {
              periodEndDate: 'Jun 30, 2015',
              totalRevenue: '43,556',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '19,442',
              nonRecurring: '-',
              otherOperatingExpenses: '1,626',
              operatingIncome: '14,680',
              totalOtherIncomeAndExpensesNet: '25',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '14,705',
              incomeTaxExpense: '5,852',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '8,853',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '8,853',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '8,853'
            },
            {
              periodEndDate: 'Jun 30, 2014',
              totalRevenue: '36,030',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '17,066',
              nonRecurring: '-',
              otherOperatingExpenses: '1,512',
              operatingIncome: '10,159',
              totalOtherIncomeAndExpensesNet: '10',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '10,171',
              incomeTaxExpense: '3,889',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '6,282',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '6,282',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '6,282'
            }
          ]

        case 'VLKAY':
          return [
            {
              periodEndDate: 'Jun 30, 2015',
              totalRevenue: '290,843,000',
              costOfRevenue: '236,777,000',
              grossProfit: '54,066,000',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '9,639,000',
              nonRecurring: '-',
              otherOperatingExpenses: '-',
              operatingIncome: '18,251,000',
              totalOtherIncomeAndExpensesNet: '(3,746,000)',
              earningsBeforeInterestAndTaxes: '18,251,000',
              interestExpense: '-',
              incomeBeforeTax: '14,505,000',
              incomeTaxExpense: '5,018,000',
              minorityInterestExpense: '5,594,000',
              equityEarnings: '5,594,000',
              netIncomeFromContinuingOperations: '15,081,000',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '15,081,000',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '15,081,000'
            },
            {
              periodEndDate: 'Jun 30, 2014',
              totalRevenue: '271,635,000',
              costOfRevenue: '222,112,000',
              grossProfit: '49,523,000',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '9,712,000',
              nonRecurring: '-',
              otherOperatingExpenses: '-',
              operatingIncome: '16,640,000',
              totalOtherIncomeAndExpensesNet: '(3,281,000)',
              earningsBeforeInterestAndTaxes: '16,640,000',
              interestExpense: '-',
              incomeBeforeTax: '13,359,000',
              incomeTaxExpense: '4,848,000',
              minorityInterestExpense: '5,360,000',
              equityEarnings: '5,360,000',
              netIncomeFromContinuingOperations: '13,872,000',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '13,872,000',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '13,872,000'
            },
            {
              periodEndDate: 'Jun 30, 2013',
              totalRevenue: '258,388,000',
              costOfRevenue: '211,763,000',
              grossProfit: '46,626,000',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '8,728,000',
              nonRecurring: '-',
              otherOperatingExpenses: '-',
              operatingIncome: '14,157,000',
              totalOtherIncomeAndExpensesNet: '(3,006,000)',
              earningsBeforeInterestAndTaxes: '14,157,000',
              interestExpense: '-',
              incomeBeforeTax: '11,151,000',
              incomeTaxExpense: '5,525,000',
              minorityInterestExpense: '17,945,000',
              equityEarnings: '17,875,000',
              netIncomeFromContinuingOperations: '23,571,000',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '23,571,000',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '23,571,000'
            },
            {
              periodEndDate: 'Jun 30, 2012',
              totalRevenue: '256,550,000',
              costOfRevenue: '210,169,000',
              grossProfit: '46,381,000',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '7,645,000',
              nonRecurring: '-',
              otherOperatingExpenses: '-',
              operatingIncome: '17,003,000',
              totalOtherIncomeAndExpensesNet: '9,096,000',
              earningsBeforeInterestAndTaxes: '17,003,000',
              interestExpense: '-',
              incomeBeforeTax: '26,099,000',
              incomeTaxExpense: '3,816,000',
              minorityInterestExpense: '3,725,000',
              equityEarnings: '4,036,000',
              netIncomeFromContinuingOperations: '26,008,000',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '26,008,000',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '26,008,000'
            }
          ]
      }
      break

    case 'quarterly':
      switch (params.symbol) {
        case 'GOOG':
          return []

        case 'OTCM':
          return [
            {
              periodEndDate: 'Jun 30, 2017',
              totalRevenue: '13,143',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '5,644',
              nonRecurring: '-',
              otherOperatingExpenses: '399',
              operatingIncome: '4,824',
              totalOtherIncomeAndExpensesNet: '22',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '4,846',
              incomeTaxExpense: '1,741',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '3,105',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '3,105',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '3,105'
            },
            {
              periodEndDate: 'Mar 31, 2017',
              totalRevenue: '12,752',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '5,970',
              nonRecurring: '-',
              otherOperatingExpenses: '395',
              operatingIncome: '4,238',
              totalOtherIncomeAndExpensesNet: '14',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '4,252',
              incomeTaxExpense: '1,202',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '3,050',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '3,050',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '3,050'
            },
            {
              periodEndDate: 'Dec 31, 2016',
              totalRevenue: '12,295',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '5,147',
              nonRecurring: '-',
              otherOperatingExpenses: '391',
              operatingIncome: '4,612',
              totalOtherIncomeAndExpensesNet: '2',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '4,614',
              incomeTaxExpense: '1,922',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '2,692',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '2,692',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '2,692'
            },
            {
              periodEndDate: 'Sep 30, 2016',
              totalRevenue: '12,056',
              costOfRevenue: '-',
              grossProfit: '-',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '5,011',
              nonRecurring: '-',
              otherOperatingExpenses: '387',
              operatingIncome: '4,399',
              totalOtherIncomeAndExpensesNet: '11',
              earningsBeforeInterestAndTaxes: '-',
              interestExpense: '-',
              incomeBeforeTax: '4,410',
              incomeTaxExpense: '1,404',
              minorityInterestExpense: '-',
              equityEarnings: '-',
              netIncomeFromContinuingOperations: '3,006',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '3,006',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '3,006'
            }
          ]

        case 'VLKAY':
          return [
            {
              periodEndDate: 'Sep 30, 2015',
              totalRevenue: '57,473,000',
              costOfRevenue: '52,153,000',
              grossProfit: '5,320,000',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '1,994,000',
              nonRecurring: '-',
              otherOperatingExpenses: '-',
              operatingIncome: '(3,883,000)',
              totalOtherIncomeAndExpensesNet: '78,000',
              earningsBeforeInterestAndTaxes: '(3,883,000)',
              interestExpense: '-',
              incomeBeforeTax: '(3,805,000)',
              incomeTaxExpense: '(947,000)',
              minorityInterestExpense: '990,000',
              equityEarnings: '990,000',
              netIncomeFromContinuingOperations: '(1,869,000)',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '(1,869,000)',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '(1,869,000)'
            },
            {
              periodEndDate: 'Jun 30, 2015',
              totalRevenue: '62,441,000',
              costOfRevenue: '49,753,000',
              grossProfit: '12,689,000',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '2,018,000',
              nonRecurring: '-',
              otherOperatingExpenses: '-',
              operatingIncome: '3,890,000',
              totalOtherIncomeAndExpensesNet: '(993,000)',
              earningsBeforeInterestAndTaxes: '3,890,000',
              interestExpense: '-',
              incomeBeforeTax: '2,897,000',
              incomeTaxExpense: '1,074,000',
              minorityInterestExpense: '1,220,000',
              equityEarnings: '1,220,000',
              netIncomeFromContinuingOperations: '3,043,000',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '3,043,000',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '3,043,000'
            },
            {
              periodEndDate: 'Mar 31, 2015',
              totalRevenue: '56,638,000',
              costOfRevenue: '45,565,000',
              grossProfit: '11,073,000',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '1,949,000',
              nonRecurring: '-',
              otherOperatingExpenses: '-',
              operatingIncome: '3,575,000',
              totalOtherIncomeAndExpensesNet: '(543,000)',
              earningsBeforeInterestAndTaxes: '3,575,000',
              interestExpense: '-',
              incomeBeforeTax: '3,032,000',
              incomeTaxExpense: '1,113,000',
              minorityInterestExpense: '1,231,000',
              equityEarnings: '1,231,000',
              netIncomeFromContinuingOperations: '3,150,000',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '3,150,000',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '3,150,000'
            },
            {
              periodEndDate: 'Dec 31, 2014',
              totalRevenue: '69,151,000',
              costOfRevenue: '57,336,000',
              grossProfit: '11,815,000',
              researchAndDevelopment: '-',
              sellingGeneralAndAd: '2,222,000',
              nonRecurring: '-',
              otherOperatingExpenses: '-',
              operatingIncome: '4,146,000',
              totalOtherIncomeAndExpensesNet: '(1,148,000)',
              earningsBeforeInterestAndTaxes: '4,146,000',
              interestExpense: '-',
              incomeBeforeTax: '2,998,000',
              incomeTaxExpense: '1,165,000',
              minorityInterestExpense: '1,176,000',
              equityEarnings: '1,176,000',
              netIncomeFromContinuingOperations: '3,009,000',
              discontinuedOperations: '-',
              extraordinaryItems: '-',
              effectOfAccountingChanges: '-',
              otherItems: '-',
              netIncome: '3,009,000',
              preferredStockAndOtherAdjustments: '-',
              netIncomeApplicableToCommonShares: '3,009,000'
            }
          ]
      }
      break
  }
}
