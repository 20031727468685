import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getSnapshot (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    mockData: 'market-activity/broker-dealer/snapshot',
    url: '/market-activity/broker-dealer/executed-volume/snapshot',
    params
  })
  return response.data
}

export async function getExecVolume (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'market-activity/broker-dealer/list',
    url: '/market-activity/broker-dealer/executed-volume',
    params
  })
  return response.data
}

export async function getExecLinkVolume (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'market-activity/broker-dealer/list',
    url: '/market-activity/broker-dealer/executed-link-volume',
    params
  })
  return response.data
}

export async function getTotalLinkVolume (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'market-activity/broker-dealer/list',
    url: '/market-activity/broker-dealer/total-link-volume',
    params
  })
  return response.data
}

export async function getResponseQuality (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'market-activity/broker-dealer/list',
    url: '/market-activity/broker-dealer/response-quality',
    params
  })
  return response.data
}

export async function getListHome (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'array',
    mockData: 'market-activity/broker-dealer/list-home',
    url: '/market-data/broker-dealer/home',
    params
  })
  return response.data
}

const getBrokerDealerPath = path => {
  switch (path) {
    case 'execVol':
      return 'executed-volume'
    case 'execLinkOnlyVol':
      return 'executed-link-volume'
    case 'totalLinkVol':
      return 'total-link-volume'
    case 'resStat':
      return 'response-quality'
  }
}

export async function downloadBrokerDealer (type) {
  const path = getBrokerDealerPath(type)
  const url = `/otcapi/download/internal/market-activity/broker-dealer/${path}/download`
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
  window.open(`${REACT_APP_API_BASE_URI}${url}?file='broker-dealer&token=${jwtToken}`)
}
