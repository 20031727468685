import React from 'react'
import styles from './OtherDirectories.module.scss'
import { Link } from 'react-router-dom'
import * as Routes from '../../constants/Routes'
import { FaAngleRight } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { withRouter } from '../WithRouter'

const links = [{
  label: 'OTCQX & OTCQB Sponsors',
  to: Routes.DIR_SPONSORS
}, {
  label: 'Qualified Foreign Exchange',
  to: Routes.DIR_QUALIFIED_FEXCHANGE
}, {
  label: 'Broker-Dealer Directory',
  to: Routes.DIR_BROKER_DEALER
}, {
  label: 'Service Provider Directory',
  to: Routes.DIR_SERVICE_PROVIDER
}, {
  label: 'Prohibited Service Providers',
  to: Routes.DIR_PROH_SERVICE_PROVIDERS
}, {
  label: 'Company Directory',
  to: Routes.DIR_COMPANY
}, {
  label: 'State Securities Commissions',
  to: Routes.DIR_STATE_SEC_COMMISSIONS
}]

const OtherDirectories = ({ location }) => (
  <div
    className={styles.component}>
    <div className={styles.title}>
      Other Directories
    </div>
    <div className={styles.links}>
      {links.map((link, index) => (
        (!location || (location.pathname + '/').indexOf(link.to + '/') === -1) && <Link
          className={styles.link}
          key={index}
          to={link.to || '#'}>
          <div className={styles.linkLabel}>
            {link.label}
          </div>
          <FaAngleRight className={styles.linkIcon} />
        </Link>
      ))}
    </div>
  </div>
)

OtherDirectories.propTypes = {
  location: PropTypes.object
}

export default withRouter(OtherDirectories)
