import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ContactModal.module.scss'
import { Flex, Box } from 'grid-styled'
import { contact, hasIq } from '../../api/contact'
import Input from '../Input'
import Modal from '../Modal'
import ExternalLink from '../ExternalLink'

const basicFields = [
  { title: 'First Name', name: 'firstName', required: true, type: 'input' },
  { title: 'Last Name', name: 'lastName', required: true, type: 'input' },
  { title: 'Company Name', name: 'company', type: 'input', width: 1 },
  { title: 'Email', name: 'email', required: true, type: 'input', inputType: 'email' },
  { title: 'Phone Number', name: 'phone', required: true, type: 'input', inputType: 'tel' },
  { title: 'Comments', name: 'comments', type: 'input', width: 1 }
]
const forms = {
  otcqxus: {
    title: 'Learn More About OTCQX',
    fields: basicFields
  },
  otcqxbanks: {
    title: 'Learn More About OTCQX',
    fields: basicFields
  },
  otcqxintl: {
    title: 'Learn More About OTCQX',
    fields: basicFields
  },
  otcqb: {
    title: 'Learn More About OTCQB',
    fields: basicFields
  },
  quotes: {
    title: 'Provide Level 1 Quotes to Your Investors',
    fields: [
      { title: 'First Name', name: 'firstName', required: true, type: 'input' },
      { title: 'Last Name', name: 'lastName', required: true, type: 'input' },
      { title: 'Title', name: 'title', type: 'input' },
      { title: 'Trading Symbol', name: 'symbol', required: true, type: 'input' },
      { title: 'Email', name: 'email', required: true, type: 'input', inputType: 'email' },
      { title: 'Website', name: 'website', required: true, type: 'input' }
    ]
  },
  profile: {
    title: 'Update Your Company Profile',
    submitText: 'Next',
    fields: [
      { title: 'Company Trading Symbol', name: 'symbol', required: true, type: 'input' },
      { title: 'Company Affiliation', name: 'affiliation', required: true, type: 'input' },
      { title: 'First Name', name: 'firstName', required: true, type: 'input' },
      { title: 'Last Name', name: 'lastName', required: true, type: 'input' },
      { title: 'Email', name: 'email', required: true, type: 'input', inputType: 'email' },
      { title: 'Phone Number', name: 'phone', required: true, type: 'input', inputType: 'tel' }
    ]
  }
}

class ContactModal extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      form: {},
      errors: {},
      displaySuccess: false,
      displayError: false,
      modalOpen: false,
      // For profile update form
      page2: false,
      hasIq: false,
      sending: false
    }
  }

  toggleModal = () => {
    const { location: { pathname } } = this.props
    this.setState(state => ({
      modalOpen: !state.modalOpen,
      form: {},
      errors: {},
      displaySuccess: false,
      displayError: false,
      page2: false,
      hasIq: false,
      sending: false
    }))
  }

  modalDisplayChange = (modalOpen) => {
    this.setState({ modalOpen })
  }

  onValidate = (field) => {
    let value = this.state.form[field.name]
    if (typeof value === 'string') value = value.trim()

    // display required field errors
    if (field.required && (!value || value === '')) {
      return 'Required field.'
    }

    if (field.inputType === 'email') {
      // validate email address
      const atIndex = value.indexOf('@')
      if (atIndex <= 0 || atIndex >= value.length - 1) {
        return 'Please enter a valid email address.'
      }
    }
  }

  onFieldChange = (field) => {
    return (value) => {
      const obj = Object.assign({}, this.state.form)
      obj[field] = value
      this.setState({ form: obj })
    }
  }

  handleSubmit = (event) => {
    const { location: { pathname } } = this.props
    const form = forms[this.props.formType]
    let formValid = true

    event.preventDefault()

    for (const field of form.fields) {
      const validationResult = this.onValidate(field)
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          [field.name]: validationResult
        }
      }))

      if (validationResult) {
        formValid = false
      }
    }

    if (formValid && this.props.formType === 'profile' && !this.state.page2) {
      this.setState({ sending: true })
      return hasIq(this.state.form.symbol)
        .then((data) => {
          this.setState({
            page2: true,
            hasIq: (data === true),
            sending: false
          })
        })
        .catch(() => {
          this.setState({
            page2: true,
            hasIq: false,
            sending: false
          })
        })
    }

    if (formValid) {
      this.setState({ sending: true })
      contact(this.props.formType, this.state.form)
        .then(() => {
          this.setState({
            displaySuccess: true,
            displayError: false,
            sending: false
          })
        })
        .catch(() => {
          this.setState({
            displayError: true,
            displaySuccess: false,
            page2: false,
            sending: false
          })
        })
    }

    this.forceUpdate()
  }

  render () {
    const { accentColor } = this.props
    const form = forms[this.props.formType]
    return <div className={styles.contactModal}>
      <button onClick={this.toggleModal} type='button' className={styles[accentColor]}>{this.props.children}</button>
      {form && <Modal closeButton isOpen={this.state.modalOpen} onDisplayChange={this.modalDisplayChange} width={640} borderAccent={accentColor}>
        <div className={classNames(styles.title, styles[accentColor])}>{form.title}</div>
        {!this.state.displaySuccess && !this.state.displayError && !this.state.page2 && <form>
          <Flex wrap justify='space-between' className={styles.form}>
            {form.fields.map((field, i) => <Box key={i}  w={[1, field.width || 1 / 2]} className={styles.inputWrapper}>
              <Input title={field.title}
                required={field.required}
                onChange={this.onFieldChange(field.name)}
                key={field.title}
                error={this.state.errors[field.name]}
                type={field.inputType}
              />
            </Box>)}
          </Flex>
          <div className={styles.buttonWrapper}>
            <button className={classNames(styles.submit, styles[accentColor])} onClick={this.handleSubmit} disabled={this.state.sending}>{form.submitText || 'Submit'}</button>
          </div>
        </form>}
        {!this.state.displaySuccess && this.state.page2 && <form>
          {this.state.hasIq && <div>
            Our records indicate that your organization has an OTCIQ account.
            In order to preserve the highest level of security for our information updates,
            all OTCIQ accounts must make updates via our secure website: <ExternalLink href='https://www.otciq.com'>OTCIQ.com</ExternalLink>
          </div>}
          {!this.state.hasIq && <div>
            Please provide a description of the company information that should be updated.
            An analyst will reach out to you to confirm the changes.
            <textarea className={styles.textarea} onChange={e => { this.onFieldChange('message')(e.target.value) }} />
            <div className={styles.buttonWrapper}>
              <button className={styles.submit} onClick={this.handleSubmit} disabled={this.state.sending}>Submit</button>
            </div>
          </div>}
        </form>}
        {this.state.displaySuccess && <div className={styles.message}>
          <h3>{form.successMessage || 'Thank you for your inquiry!'}</h3>
          <p>A member of our Corporate Services team will contact you in 1-2 business days.</p>
        </div>}
        {this.state.displayError && <div className={styles.message}>
          <h3>{'Your message was not sent.'}</h3>
          <p>{`We're unable to receive your message at this time. Please try again.`}</p>
        </div>}
      </Modal>}
    </div>
  }
}

ContactModal.propTypes = {
  formType: PropTypes.oneOf(Object.keys(forms)),
  accentColor: PropTypes.string,
  children: PropTypes.node,
  location: PropTypes.object
}

export default ContactModal
