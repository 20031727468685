
export function template (defaultValue, sortOn, sortDir) {
  const obj = {
    reloading: false,
    loaded: false,
    complete: false,
    data: {},
    options: {
      params: {}
    },
    expandEmpty: false,
    error: null,
    sortOn,
    sortDir
  }

  // set custom default values if provided
  if (typeof defaultValue !== 'string') {
    obj.data = defaultValue
    return obj
  }

  switch (defaultValue) {
    case 'object':
      obj.data = {}
      return obj

    case 'array':
      obj.data = []
      return obj

    case 'records':
      obj.data = {
        records: []
      }
      return obj

    case 'text':
      obj.data = ''
      return obj
  }
}

export function fetch (component, func, objectName, params = {}, formatting, isExpand) {
  const _concat = (data, currentData) => {
    let returnData = currentData
    if (Array.isArray(currentData)) {
      returnData = currentData.concat(data)
    } else {
      if (currentData.records) {
        returnData.records = currentData.records.concat(data.records)
      }
    }
    return returnData
  }

  const _getDataLength = (data) => {
    let returnLength = 0

    if (!data) return 0

    if (Array.isArray(data)) {
      returnLength = data.length
    } else {
      if (data.records) {
        returnLength = data.records.length
      }
    }

    return returnLength
  }

  const _isLastPage = (data, pageSize, page) => {
    if (!data) return true

    // making sure if it's the last page in cases where
    // the last page has the same number of results we requested.
    if (page && (data.pages || data.totalPages)) {
      if (page >= (data.pages || data.totalPages)) {
        return true
      }
    }

    // decide by the number of results
    return _getDataLength(data) < pageSize
  }

  const currentPage = (component.state[objectName].options.currentPage || 0) + 1

  return new Promise((resolve, reject) => {
    // reset `loaded` and error states and set sorting flags early
    component.setState({
      [objectName]: {
        ...component.state[objectName],
        reloading: isExpand,
        loaded: false,
        complete: false,
        error: null,
        sortDir: params.sortDir || component.state[objectName].sortDir,
        sortOn: params.sortOn || component.state[objectName].sortOn
      }
    }, () => {
      let requestPageSize = params.pageSize

      if (component.state[objectName].options &&
          component.state[objectName].options.currentPageSize) {
        requestPageSize = component.state[objectName].options.currentPageSize
      }

      // fetch records based on the initial page size parameter
      if (params.retainPageSize && requestPageSize && !isExpand) {
        params.pageSize = requestPageSize
      }

      func(params)
        .then(data => {
          if (formatting) {
            data = formatting(data)
          }

          component.setState(state => ({
            [objectName]: {
              ...component.state[objectName],
              reloading: false,
              loaded: true,
              complete: true,
              error: null,
              options: {
                ...component.state[objectName].options,
                currentPage: isExpand ? currentPage : 1,
                params,
                formatting,
                currentPageSize: requestPageSize
              },
              expandEmpty: _isLastPage(data, params.pageSize || 1, params.page),
              data: isExpand ? _concat(data, component.state[objectName].data) : data
            }
          }), () => {
            resolve(component.state[objectName])
          })
        })
        .catch((err) => {
          console.error(err)
          component.setState(state => ({
            [objectName]: {
              ...state[objectName],
              reloading: false,
              loaded: false,
              complete: true,
              error: 'Data temporarily unavailable.'
            }
          }), () => {
            reject(err)
          })
        })
    })
  })
}

export function empty (component, objectName, callback) {
  component.setState({
    [objectName]: {
      ...component.state[objectName],
      reloading: false,
      loaded: false,
      complete: false,
      error: null
    }
  }, callback)
}

export const expand = (component, func, objectName) => () => {
  component.state[objectName].options.params.page =
    component.state[objectName].options.currentPage + 1

  return fetch(component, func, objectName,
    component.state[objectName].options.params,
    component.state[objectName].options.formatting, true)
}

export const sort = (component, func, objectName) => (sortOn, sortDir) => {
  const obj = Object.assign({}, component.state[objectName])
  obj.options.params.sortOn = sortOn
  obj.options.params.sortDir = sortDir
  obj.options.params.page = 1
  component.setState({ [objectName]: obj })
  return fetch(component, func, objectName, obj.options.params, obj.options.formatting)
}

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const getNextBusinessDay = (fromDate, holidayDates, number) => {
  let maxIterations = (holidayDates && holidayDates.length) || 0
  let outputBusinessDay = null
  while (number > 0 && maxIterations > 0) {
    const dateToCheck = fromDate.add(1, 'day')
    const dateToCheckStr = dateToCheck.format('YYYY-MM-DD')
    const holiday = holidayDates.find(holidayObj => {
      return holidayObj.date === dateToCheckStr
    })
    if (!holiday) {
      number--
    }
    if (number === 0) {
      outputBusinessDay = dateToCheck
    }
    maxIterations--
  }
  return outputBusinessDay
}

export const getPrevBusinessDay = (fromDate, holidayDates, number) => {
  let maxIterations = (holidayDates && holidayDates.length) || 0
  let outputBusinessDay = null
  while (number > 0 && maxIterations > 0) {
    const dateToCheck = fromDate.subtract(1, 'days')
    const dateToCheckStr = dateToCheck.format('YYYY-MM-DD')
    const holiday = holidayDates.find(holidayObj => {
      return holidayObj.date === dateToCheckStr
    })
    if (!holiday) {
      number--
    }
    if (number === 0) {
      outputBusinessDay = dateToCheck
    }
    maxIterations--
  }
  return outputBusinessDay
}
