import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { FaCaretUp, FaCaretDown } from 'react-icons/fa'
import { format } from '../../utils/locale'

const TickerPrice = (props) => {
  const data = props.data
  const price = format(data.lastPrice, '', 'price')
  const isUp = data.tradeDirection === 'Up'
  const isDown = data.tradeDirection === 'Down'
  return <div className={styles.container}>
    { isUp && <FaCaretUp color='limegreen' className={styles.tick} /> }
    { isDown && <FaCaretDown color='red' className={styles.tick} /> }
    <div className={styles.price}>{price}</div>
  </div>
}

TickerPrice.propTypes = {
  data: PropTypes.any.isRequired
}

export default TickerPrice
