export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return {
        isOtc: false,
        symbol: 'GOOG',
        lastSale: 1106.26,
        change: -0.6799,
        percentChange: -0.061421,
        tickCode: '2',
        tickName: 'Down',
        volume: 902138,
        volumeFormatted: '902,138',
        quoteTime: 76500000,
        quoteDateTime: 1515532500000,
        dailyHigh: 1110.5699,
        dailyLow: 1101.23,
        openingPrice: 1109.4,
        annualHigh: 1111.27,
        annualLow: 790.52,
        dividend: 0,
        eps: 21.35,
        previousClose: 1106.9399,
        peRatio: 51.8155,
        yield: 0,
        exchangeCode: 'O',
        exchangeName: 'NASDAQ',
        delay: 15,
        isADR: false,
        realtime: false,
        pinkLinkRealtime: false,
        thirtyDaysAvgVol: 1377191.6,
        showRealtimeAd: false,
        companyName: 'ALPHABET INC. (C)',
        adr: false
      }

    case 'OTCM':
      return {
        isOtc: true,
        symbol: 'OTCM',
        securityId: 128054,
        lastSale: 29.68,
        change: 0.48,
        percentChange: 1.64,
        tickCode: '2',
        tickName: 'Down',
        volume: 1322,
        volumeFormatted: '1,322',
        lastTradeTime: 1515531099028,
        quoteTime: 1515531099028,
        quoteDateTime: 1515531060000,
        insideTime: 1515535202678,
        bidPrice: 29,
        bidSize: 138,
        askPrice: 29.88,
        askSize: 175,
        dailyHigh: 29.88,
        dailyLow: 28.525,
        openingPrice: 28.525,
        annualHigh: 32.25,
        annualLow: 19,
        dividend: 0.56,
        eps: 0.94,
        previousClose: 29.2,
        peRatio: 31.5745,
        yield: 1.8868,
        betaCoefficient: 0.48,
        exchangeCode: '5',
        exchangeName: 'NASDAQ OTC',
        delay: 15,
        isADR: false,
        realtime: false,
        pinkLinkRealtime: false,
        thirtyDaysAvgVol: 3745.733333,
        showRealtimeAd: false,
        marketCap: 347227887,
        sharesOutstanding: 11891366,
        adr: false
      }

    case 'VLKAY':
    default:
      return {
        isOtc: true,
        symbol: 'VLKAY',
        securityId: 35275,
        lastSale: 43.29,
        change: 0.07,
        percentChange: 0.16,
        tickCode: '1',
        tickName: 'Up',
        volume: 132430,
        volumeFormatted: '132,430',
        lastTradeTime: 1515531593065,
        quoteTime: 1515531593065,
        quoteDateTime: 1515531540000,
        insideTime: 1515535202613,
        bidPrice: 43.2,
        bidSize: 1,
        askPrice: 43.29,
        askSize: 1,
        dailyHigh: 43.47,
        dailyLow: 43.04,
        openingPrice: 43.37,
        annualHigh: 43.22,
        annualLow: 28.9,
        dividend: 0.3229,
        eps: 5.796,
        previousClose: 43.22,
        peRatio: 7.4689,
        yield: 0.7459,
        betaCoefficient: 1.08,
        exchangeCode: '5',
        exchangeName: 'NASDAQ OTC',
        delay: 15,
        isADR: true,
        realtime: false,
        pinkLinkRealtime: false,
        thirtyDaysAvgVol: 152382.3,
        showRealtimeAd: true,
        marketCap: 63771110000,
        sharesOutstanding: 295100000,
        adr: true
      }
  }
}
