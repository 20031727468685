import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FaAngleRight } from 'react-icons/fa'
import styles from './styles.module.scss'

const HelpfulLinks = (props) => (<div>
  <div className={styles['help-links']}>
    <div className={styles['help-title']}>
      Try these helpful links
    </div>
    <div className={styles.links}>
      {props.links.map((link, index) => (
        (!props.location || (props.location.pathname + '/').indexOf(link.to + '/') === -1) && <Link
          className={styles.link}
          key={index}
          to={link.to || '#'}>
          <div className={styles.linkLabel}>
            {link.label}
          </div>
          <FaAngleRight className={styles.linkIcon} />
        </Link>
      ))}
    </div>
  </div>
</div>)

HelpfulLinks.propTypes = {
  location: PropTypes.object,
  links: PropTypes.arrayOf(PropTypes.object)
}

export default HelpfulLinks
