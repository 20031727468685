import api from '../../api'

export async function getNewlyTrading () {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    mockData: 'stock/stock/newly-trading',
    url: '/stock/newly-trading'
  })
  return response.data
}

export async function getSuggestedSecurities (symbol) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    url: `/stock/suggested-stocks/${symbol}`
  })
  return response.data
}

export async function getLevel2Montage (symbol) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    mockData: 'stock/stock/level2montage',
    url: `/internal/stock/level2/${symbol}`,
    params: {
      symbol
    }
  })
  return response.data
}
