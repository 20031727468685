import React from 'react'
import PropTypes from 'prop-types'
import { PasswordRequirements } from '../PasswordRequirements'
import { withContext } from '../../AuthContext'
import { Button, Input } from '@otcmarketsgroup/otcm-ui'
import styles from '../LogIn/LogIn.module.scss'

class RequireNewPassword extends React.PureComponent {
  constructor (props, context) {
    super(props, context)

    this.state = {
      tempPassword: '',
      newPassword: '',
      error: false
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (field) {
    return (value) => {
      this.setState({ [field]: value })
    }
  }

  render () {
    const { context } = this.props

    return <div className={styles.container}>
      <div className={styles.form}>
        <form onSubmit={(event) => context.submitCompleteNewPwd(this.state.newPassword, event)}>
          <div className={styles.greeting}>
            <h3 className={styles.formTitle}>Change Password</h3>
            <p className={styles.formDesc}>This is your first time logging in. You are required to change your password</p>
          </div>
          <Input id={'newPassword'}
            className={styles.input}
            title={'New Password'}
            required
            design='standard'
            onChange={this.handleChange('newPassword')}
            // error={this.state.errors[field.name]}
            type={'password'}
          />
          {context.error && <i className={styles.error}>{context.error}</i>}
          <PasswordRequirements />
          <Button id={'updatePwdSubmitLogIn'} inactive={context.disableButton} className={styles.submit} title='Submit' fullWidth type='submit' />
        </form>
      </div>
    </div>
  }
}

RequireNewPassword.propTypes = {
  context: PropTypes.shape({
    submitCompleteNewPwd: PropTypes.func,
    error: PropTypes.string,
    changeState: PropTypes.func,
    disableButton: PropTypes.func
  })
}

export default withContext(RequireNewPassword)
