import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Flex, Box } from 'grid-styled'
import { LabelTip } from '../LabelTip'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import ExternalLink from '../ExternalLink'
import styles from './styles.module.scss'

class Accordion extends React.PureComponent {
  constructor (props, context) {
    const itemStates = []
    super(props, context)

    props.list.forEach((item) => {
      itemStates.push(item.open)
    })

    this.state = {
      itemStates
    }
  }

  onClick = (index) => {
    return () => {
      const itemStates = this.state.itemStates

      itemStates[index] = !itemStates[index]

      if (this.props.onClick) {
        this.props.onClick(this.props.list[index])
      }

      this.setState(state => ({
        itemStates
      }))

      this.forceUpdate()
    }
  }

  render () {
    const ComponentToRender = this.props.component
    const TitleComponentToRender = this.props.titleComponent
    const accentColor = this.props.accent

    return <div
      className={classNames(this.props.className, {
        [styles.accordion]: true
      })} >
      {this.props.list.map((item, index) => item.visible === false
        ? null
        : <div
        className={classNames({
          [styles.item]: true,
          [styles.fullWidth]: this.props.fullWidth,
          [styles.compact]: this.props.compact
        })}
        key={`accordion-item-${index}`}
      >
        <div
          className={classNames(styles.title, { [styles.hasContent]: item.hasContent !== false })}>
          <Flex w={1} wrap>
            <Box
              w={(TitleComponentToRender || this.props.columnView)
                ? [1, this.props.columnSplit || 1 / 2, this.props.columnSplit || 1 / 2]
                : null}
              pr={this.props.columnView ? '2em' : 0}>
              {item.to && <ExternalLink href={item.to} className={classNames(styles.link, styles[accentColor])}>
                <b>{item.title}</b>
              </ExternalLink>}
              {!item.to && <b onClick={item.hasContent !== false ? this.onClick(index) : undefined}>
                {item.tooltip && <LabelTip
                  title={item.title}
                  tooltipTitle={item.tooltipTitle}
                  tipText={item.tooltip}
                  width='350px'
                  icon='/icons/sidebar/search-dollar-solid.svg'
                /> }
                {!item.tooltip && item.title}
              </b>}
              {item.subtitle &&
                <abbr className={classNames({
                  [styles.subtitle]: true,
                  [styles.columnView]: this.props.columnView
                })}>{item.subtitle}</abbr>
              }
              {item.hasContent !== false && this.props.chevron &&
                <span className={styles.chevron} onClick={this.onClick(index)}>
                  {this.state.itemStates[index] ? <FaChevronUp /> : <FaChevronDown />}
                </span>
              }
            </Box>
            {TitleComponentToRender &&
              <TitleComponentToRender {...item.data} />
            }
          </Flex>
        </div>
        {ComponentToRender && item.hasContent &&
          <div className={classNames({
            [styles.content]: true,
            [styles.visible]: this.state.itemStates[index]
          })}>
            <ComponentToRender {...item.data} />
          </div>
        }
      </div>)}
    </div>
  }
}

Accordion.propTypes = {
  /** Accordion items */
  list: PropTypes.array.isRequired,
  /** Custom accordion item component */
  component: PropTypes.func,
  /** Custom accordion item title component */
  titleComponent: PropTypes.func,
  /** Switch to column mode */
  columnView: PropTypes.bool,
  /** Column split value. Example: { 1 / 4 } for 4 column layout */
  columnSplit: PropTypes.number,
  /** Compact mode */
  compact: PropTypes.bool,
  /** Make accordion items take the full-width of the parent component */
  fullWidth: PropTypes.bool,
  /** Display expand/collapse chevron */
  chevron: PropTypes.bool,
  /** Item click event **/
  onClick: PropTypes.func,
  /** Custom accordion class name */
  className: PropTypes.string,
  /** Custom color class name to be used on link hovers */
  accent: PropTypes.string
}

export default Accordion
