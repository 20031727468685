import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

class HeaderAccordion extends Component {
  static propTypes = {
    children: PropTypes.node,
    atomic: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {}
  }

  renderChildren () {
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        atomic: this.props.atomic
      })
    })
  }

  render () {
    return <div className={styles.accordion}>{this.renderChildren()}</div>
  }
}

export default HeaderAccordion
