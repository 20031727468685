import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import BankDetail from './BankDetail'
import styles from './BankAccordian.module.scss'

class BankAccordian extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    let toggleOpen = false

    if (this.props.bank.isParent && this.props.bank.children && this.props.bank.children.length > 10) {
      toggleOpen = this.props.level < 2
    } else if (this.props.level < 3 && (this.props.bank.isParent || this.props.bank.parentChildren < 10)) {
      toggleOpen = this.props.level < 3
    }

    this.state = {
      toggleOpen
    }
  }

  handleClick = () => {
    this.setState(state => ({
      toggleOpen: !state.toggleOpen
    }))
  }

  render () {
    const { bank, level } = this.props

    return <div className={styles.BankAccordian}>
      <BankDetail
        id={bank.rssdId}
        name={bank.legalName}
        location={`${bank.city && bank.city}${bank.city && bank.state ? ', ' : ''}${bank.state ? bank.state : ''}`}
        type={bank.charterName}
        percentage={typeof bank.pctEquity === 'number' && bank.pctEquity.toString()}
        hasChildren={bank.children && bank.children.length > 0}
        isParent={bank.isParent}
        handleClick={this.handleClick}
        level={level}
        isOpen={this.state.toggleOpen}>
          {(bank.children && bank.children.length > 0) && bank.children}
        </BankDetail>
      {bank.children && <div className={classNames(styles.inside, {
        [styles.show]: this.state.toggleOpen
      })}>
        {bank.children.map((c, i) => <BankAccordian key={i} bank={c} level={level + 1} />)
        }
      </div>}
    </div>
  }
}

BankAccordian.propTypes = {
  bank: PropTypes.object,
  level: PropTypes.number
}

export default BankAccordian
