import api from '../../api'

export async function getOTCInsiderDisclosureSummary (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    url: `/insider-disclosure/otc/${params.symbol}/summary`
  })
  return response.data
}

export async function getOTCInsiderDisclosure (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'company/disclosure/otc-insider-disclosure',
    url: `/insider-disclosure/otc/${params.symbol}`,
    params
  })
  return response.data
}

export async function getExternalInsiderDisclosure (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'company/disclosure/external-insider-disclosure',
    url: `/insider-disclosure/external/${params.symbol}`,
    params
  })
  return response.data
}
