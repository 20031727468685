import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

import { FaRegWindowClose } from 'react-icons/fa'

class Modal extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      isOpen: this.props.isOpen
    }
  }

  componentDidMount () {
    this.switchModal(this.state.isOpen)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.switchModal(nextProps.isOpen)
    }
  }

  /**
   * display/hide modal
   * @param  {Boolean} isOpen display modal?
   */
  switchModal = (isOpen) => {
    this.setState({
      isOpen,
      overflow: document.body.style.overflow,
      position: document.body.style.position
    }, () => {
      // this is to prevent page background from scrolling,
      // we're always keeping the original overflow value in the component state
      if (isOpen) {
        document.body.style.overflow = 'hidden'
        // Workaround for an iOS 11 display bug
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
        if (iOS) document.body.style.position = 'fixed'
      } else {
        document.body.style.overflow = this.state.overflow
        document.body.style.position = this.state.position
      }

      if (this.overlay) {
        this.overlay.scrollTop = 0
      }

      if (this.props.onDisplayChange) {
        this.props.onDisplayChange(isOpen)
      }
    })
  }

  onCloseButton = (e) => {
    this.setState({
      isOpen: false
    })

    document.body.style.overflow = this.state.overflow
    document.body.style.position = this.state.position
    if (this.props.onDisplayChange) {
      this.props.onDisplayChange(false)
    }
  }

  hideModal = (e) => {
    e.preventDefault()
    if (e.target === e.currentTarget) {
      this.setState({
        isOpen: false
      })

      document.body.style.overflow = this.state.overflow
      document.body.style.position = this.state.position
      if (this.props.onDisplayChange) {
        this.props.onDisplayChange(false)
      }
    }
  }

  displayModal = (e) => {
    e.preventDefault()
    if (e.target === e.currentTarget) {
      this.switchModal(true)
    }
  }

  render () {
    return <span
      className={classNames(styles.link, this.props.linkClassName)}
      onClick={this.displayModal}>
      <div
        className={classNames(styles.modalOverlay, {
          [styles.show]: this.state.isOpen
        })}
        ref={(overlay) => { this.overlay = overlay }}
        onClick={this.hideModal}>
        <div
          onClick={e => { e.stopPropagation() }}
          className={classNames(styles.modal, this.props.borderAccent && styles[this.props.borderAccent])}
          style={{
            width: this.props.width ? this.props.width + 'px' : '50em'
          }}>
          <div
            onClick={this.onCloseButton}
            className={classNames(styles.closeButton, {
              [styles.show]: this.props.closeButton
            })}>
            <FaRegWindowClose />
          </div>
          {this.props.children}
        </div>
      </div>
      {this.props.linkTitle}
    </span>
  }
}

Modal.propTypes = {
  /** width in pixels */
  width: PropTypes.number,
  /** display close button? */
  closeButton: PropTypes.bool,
  /** accent color: 'green', 'orange' etc. */
  borderAccent: PropTypes.string,
  /** display modal window? */
  isOpen: PropTypes.bool,
  /**
   * onDisplayChange callback, use this to sync modal display state with the
   * parent component when using 'isOpen' property.
   */
  onDisplayChange: PropTypes.func,
  /** open modal window using a simple link */
  linkTitle: PropTypes.node,
  /** link class name */
  linkClassName: PropTypes.string,
  /** modal window content */
  children: PropTypes.node.isRequired
}

export default Modal
