import React from 'react'
import styles from './Expandable.module.scss'
import PropTypes from 'prop-types'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

class Expandable extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      open: false
    }
  }

  toggle = () => {
    this.setState({ open: !this.state.open })
  }

  render () {
    return <div className={styles.component}>
      <div className={styles.header} onClick={this.toggle}>
        {this.props.text}
        <div className={styles.chevron}>
          {this.state.open ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      <div className={styles.body} style={{ display: this.state.open ? 'block' : 'none' }}>
        {this.props.children}
      </div>
    </div>
  }
}

Expandable.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node
}

export default Expandable
