import { companyQuoteTabs } from './menus'
import CompanyQuotePage from './pages/CompanyQuotePage'
import HistoryRiskScore from './pages/HistoryRiskScore'
import ContactPage from './pages/ContactPage'
import DashboardPage from './pages/DashboardPage'
import AuthPage from './pages/AuthPage'
import ContentPage from './pages/ContentPage'
import PreLoginPage from './pages/PreLoginPage'
import ChangePasswordPage from './pages/ChangePasswordPage'
import MACurrentMarketPage from './pages/MACurrentMarketPage'
import MAKeySecurities from './pages/MAKeySecurities'
import MAComplianceAnalytics from './pages/MAComplianceAnalytics'
import { MAClosingSummaryPage } from './pages/MAClosingSummaryPage'
import MABrokerDealerPage from './pages/MABrokerDealerPage'
import MACorpActionsPage from './pages/MACorpActionsPage'
import MAComplianceStatsPage from './pages/MAComplianceStatsPage'
import MAShortSalePage from './pages/MAShortSalePage'
import MARegSHOPage from './pages/MARegSHOPage'
import MAIndexPage from './pages/MAIndexPage'
import MAIndicesPage from './pages/MAIndicesPage'
import MANewsPage from './pages/MANewsPage'
import DBrokerDealerPage from './pages/DBrokerDealerPage'
import DBrokerDealerDetailPage from './pages/DBrokerDealerDetailPage'
import DCompanyDirectoryPage from './pages/DCompanyDirectoryPage'
import DProhServiceProvidersPage from './pages/DProhServiceProvidersPage'
import DQualifiedFExchangePage from './pages/DQualifiedFExchangePage'
import DStateSecCommissionsPage from './pages/DStateSecCommissionsPage'
import DServiceProviderPage from './pages/DServiceProviderPage'
import DServiceProviderDetailPage from './pages/DServiceProviderDetailPage'
import DSponsorsPage from './pages/DSponsorsPage'
import DSponsorPage from './pages/DSponsorPage'
import BlueSkyRulesPage from './pages/BlueSkyRulesPage'

import * as Routes from './constants/Routes'

const routes = [
  {
    path: '/',
    exact: true,
    component: PreLoginPage,
    public: true
  },
  {
    path: '/login',
    exact: true,
    component: AuthPage,
    public: true
  },
  {
    path: '/dashboard',
    exact: true,
    component: DashboardPage
  },
  {
    path: '/change-password',
    exact: true,
    component: ChangePasswordPage
  },
  {
    path: '/home',
    exact: true,
    redirect: '/dashboard'
  },
  {
    path: '/contact',
    exact: true,
    public: true,
    component: ContactPage
  },
  {
    path: Routes.FIXED_INCOME,
    exact: true,
    breadcrumb: 'Fixed Income',
    redirect: '/'
  },
  {
    path: `${Routes.FIXED_INCOME}/:company`,
    exact: true,
    component: CompanyQuotePage,
    breadcrumb: ({ match }) => {
      if (match.params.tab) return companyQuoteTabs.find(cqt => cqt.tab === match.params.tab).name
      return [`${match.params.company && match.params.company.toUpperCase()}`]
    },
    log: false
  },
  {
    path: `${Routes.FIXED_INCOME}/:company/:tab/:page?`,
    exact: true,
    component: CompanyQuotePage,
    breadcrumb: ({ match }) => {
      if (match.params.tab) return companyQuoteTabs.find(cqt => cqt.tab === match.paramstab).name
      return [`${match.params.company && match.params.company.toUpperCase()}`]
    },
    activeLink: 'market-activity',
    log: false
  },
  {
    path: '/stock',
    exact: true,
    breadcrumb: 'Market Activity',
    redirect: '/market-activity/current-market'
  },
  {
    path: '/stock/:company',
    exact: true,
    component: CompanyQuotePage,
    breadcrumb: ({ match: { params: { company, tab, page } } }) => {
      if (page) return '-'
      if (tab) return companyQuoteTabs.find(cqt => cqt.tab === tab).name
      return `${company && company.toUpperCase()}`
    },
    log: false
  },
  {
    path: '/stock/:company/:tab/:page?',
    exact: true,
    component: CompanyQuotePage,
    breadcrumb: ({ match: { params: { company, tab, page } } }) => {
      if (page) return '-'
      if (tab) return companyQuoteTabs.find(cqt => cqt.tab === tab).name
      return `${company && company.toUpperCase()}`
    },
    activeLink: 'market-activity',
    log: false
  },
  {
    path: '/stock/:company/history-risk-score',
    exact: true,
    component: HistoryRiskScore,
    breadcrumb: 'History Risk Score'
  },
  {
    path: '/market-activity',
    exact: true,
    breadcrumb: 'Market Activity',
    redirect: '/market-activity/current-market'
  },
  {
    path: '/market-activity/key-securities',
    exact: true,
    breadcrumb: 'Key Securities',
    component: MAKeySecurities
  },
  {
    path: '/market-activity/compliance-analytics',
    exact: true,
    breadcrumb: 'Compliance Analytics',
    component: MAComplianceAnalytics
  },
  {
    path: '/market-activity/current-market/:market?/:table?/:tab?',
    exact: true,
    component: MACurrentMarketPage
  },
  {
    path: '/market-activity/closing-summary/:market?/:table?/:tab?',
    exact: true,
    component: MAClosingSummaryPage
  },
  {
    path: '/market-activity/broker-dealer-data',
    exact: true,
    component: MABrokerDealerPage,
    breadcrumb: 'Broker Dealer Data'
  },
  {
    path: '/market-activity/broker-dealer-data/:market',
    exact: true,
    component: MABrokerDealerPage,
    breadcrumb: ({ market }) => {
      switch (market) {
        case 'QX':
          return 'OTCQX'
        case 'DQ':
          return 'OTCQB'
        case 'PS':
          return 'Pink'
        case 'OO':
          return 'Grey'
        default:
          return 'All Markets'
      }
    }
  },
  {
    path: '/market-activity/corporate-actions',
    exact: true,
    component: MACorpActionsPage,
    breadcrumb: 'Corporate Actions'
  },
  {
    path: '/market-activity/short-interest-data',
    exact: true,
    component: MAShortSalePage,
    breadcrumb: 'Short Interest Data'
  },
  {
    path: '/market-activity/reg-sho-data',
    exact: true,
    component: MARegSHOPage,
    breadcrumb: 'Reg SHO Data'
  },
  {
    path: '/market-activity/indices',
    exact: true,
    component: MAIndicesPage,
    breadcrumb: 'OTCM Indices'
  },
  {
    path: '/index/:symbol',
    exact: true,
    component: MAIndexPage,
    breadcrumb: false,
    activeLink: ['market-activity', 'indices']
  },
  {
    path: '/market-activity/news',
    exact: true,
    component: MANewsPage,
    breadcrumb: 'News'
  },
  {
    path: '/market-activity/compliance-statistics',
    exact: true,
    component: MAComplianceStatsPage,
    breadcrumb: 'Compliance Statistics'
  },
  {
    path: '/otc-link',
    exact: true,
    noroute: true,
    breadcrumb: 'OTC Link ATS',
    activeLink: 'otc-link'
  },
  {
    path: '/corporate-services',
    exact: true,
    noroute: true,
    breadcrumb: 'Corporate Services',
    activeLink: 'corporate-services'
  },
  {
    path: '/market-data',
    exact: true,
    noroute: true,
    breadcrumb: 'Market Data',
    activeLink: 'market-data'
  },
  {
    path: '/about',
    exact: true,
    breadcrumb: 'About',
    activeLink: 'about',
    redirect: '/about/our-company'
  },
  {
    path: '/learn',
    exact: true,
    breadcrumb: 'Learn',
    activeLink: 'learn',
    redirect: '/learn/15c2-11-resource-center'
  },
  {
    path: '/directories',
    exact: true,
    breadcrumb: 'Directories',
    activeLink: 'directories',
    redirect: '/directories/prohibited-service-providers'
  },
  {
    path: Routes.DIR_BROKER_DEALER,
    exact: true,
    component: DBrokerDealerPage,
    breadcrumb: 'Broker Dealer Directory'
  },
  {
    path: `${Routes.DIR_BROKER_DEALER}/:mpid`,
    exact: true,
    component: DBrokerDealerDetailPage
  },
  {
    path: Routes.DIR_COMPANY,
    exact: true,
    component: DCompanyDirectoryPage,
    breadcrumb: 'Company Directory'
  },
  {
    path: Routes.OLD_DIR_SPONSORS,
    exact: true,
    redirect: Routes.DIR_SPONSORS
  },
  {
    path: '/directories/otcqx-otcqb-sponsors',
    exact: true,
    redirect: Routes.DIR_SPONSORS
  },
  {
    path: Routes.DIR_SPONSORS,
    exact: true,
    component: DSponsorsPage,
    breadcrumb: 'Sponsors'
  },
  {
    path: `${Routes.DIR_SPONSORS}/:id`,
    exact: true,
    component: DSponsorPage,
    breadcrumb: 'Sponsor'
  },
  {
    path: Routes.DIR_QUALIFIED_FEXCHANGE,
    exact: true,
    component: DQualifiedFExchangePage,
    breadcrumb: 'Qualified Foreign Exchange'
  },
  {
    path: Routes.DIR_STATE_SEC_COMMISSIONS,
    exact: true,
    component: DStateSecCommissionsPage,
    breadcrumb: 'State Securities Commissions'
  },
  {
    path: Routes.DIR_PROH_SERVICE_PROVIDERS,
    exact: true,
    component: DProhServiceProvidersPage,
    breadcrumb: 'Prohibited Service Providers'
  },
  {
    path: Routes.DIR_SERVICE_PROVIDER,
    exact: true,
    component: DServiceProviderPage,
    breadcrumb: 'Service Provider Directory'
  },
  {
    path: `${Routes.DIR_SERVICE_PROVIDER}/:id`,
    exact: true,
    component: DServiceProviderDetailPage
  },
  {
    path: Routes.LEARN_BLUE_SKY_DATA,
    exact: true,
    redirect: `${Routes.LEARN_BLUE_SKY_DATA}/alaska`
  },
  {
    path: `${Routes.LEARN_BLUE_SKY_DATA}/:jurisdiction`,
    exact: true,
    component: BlueSkyRulesPage
  },
  {
    path: '/*',
    exact: true,
    component: ContentPage,
    breadcrumb: false,
    activeLink: 'content',
    log: false
  }
]

export default routes
