import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from './DProhServiceProvidersPage.module.scss'
import { getProhAttorneys, getProhOthers, downloadProhAttorneys, downloadProhOthers } from '../../api/otc/directory/service-provider'
import { template, fetch, expand } from '../../api/helper'

import Flexgrid from '../../components/Flexgrid'
import Table from '../../components/Table'
import Loading from '../../components/Loading'
import DirSearch from '../../components/DirSearch'
import PageTitle from '../../components/PageTitle'
import HeadTitle from '../../components/HeadTitle'
import Sticky from '../../components/Sticky'
import More from '../../components/More'
import Outline from '../../components/Outline'
import ExternalLink from '../../components/ExternalLink'
import OtherDirectories from '../../components/OtherDirectories'

const Locations = ({ value }) => value && value.length ? value.map((item, i) => <div key={i}>{item}</div>) : null

Locations.propTypes = {
  value: PropTypes.array
}

const LegalOrders = ({ value }) => {
  if (!value || !value.length) return null
  return value.map((item, i) => {
    if (!item) return null
    let urlLocation = item.toLowerCase().indexOf('http://')
    if (urlLocation === -1) urlLocation = item.toLowerCase().indexOf('https://')
    if (urlLocation === -1) return <div key={i}>{item}</div>
    const text = item.substring(0, urlLocation).trim()
    const url = item.substring(urlLocation).trim()
    return <div key={i}><ExternalLink href={url}>{text}</ExternalLink></div>
  })
}

LegalOrders.propTypes = {
  value: PropTypes.array
}

const prohAttorneysColumns = [
  {
    name: 'fullName',
    header: 'Name'
  },
  {
    name: 'firm',
    header: 'Firm'
  },
  {
    name: 'legalOrders',
    header: 'Legal order',
    component: LegalOrders,
    hideOnTablet: true,
    hideOnMobile: true
  },
  {
    name: 'locations',
    header: 'Location',
    component: Locations,
    hideOnMobile: true
  },
  {
    name: 'prohibitDate',
    header: 'Prohibited date'
  }
]

const prohOthersColumns = [
  {
    name: 'firm',
    header: 'Prohibited Individual or Entity'
  },
  {
    name: 'legalOrders',
    header: 'Legal order',
    component: LegalOrders,
    hideOnTablet: true,
    hideOnMobile: true
  },
  {
    name: 'locations',
    header: 'Location',
    component: Locations,
    hideOnMobile: true
  },
  {
    name: 'prohibitDate',
    header: 'Prohibited date'
  }
]

export default class DProhServiceProvidersPage extends PureComponent {
  state = {
    prohAttorneys: template('records'),
    prohOthers: template('records'),
    searchQuery: null
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = () => {
    fetch(this, getProhAttorneys, 'prohAttorneys', {
      page: 1,
      pageSize: 1000,
      search: this.state.searchQuery
    })

    fetch(this, getProhOthers, 'prohOthers', {
      page: 1,
      pageSize: 1000,
      search: this.state.searchQuery
    })
  }

  onSearch = searchQuery => {
    this.setState({ searchQuery }, this.loadData)
  }

  render () {
    const { prohAttorneys, prohOthers } = this.state

    const layouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['search', 'proh-attorneys', 'other-service-providers', 'other-directories']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['sticky-column']
            },
            {
              type: 'main',
              mt: '1.8rem',
              subtree: ['proh-attorneys', 'other-service-providers']
            }
          ]
        }
      ]
    }

    const dirSearch = <DirSearch key='search' onSearch={this.onSearch} value={this.state.searchQuery} />

    const otherDirectories = <div key='other-directories' className={styles.otherDirectories}>
      <OtherDirectories />
    </div>

    return (
      <div className={styles.component}>
        <HeadTitle title='Directory - Prohibited Service Providers' />
        <PageTitle>Prohibited Service Providers</PageTitle>
        <Flexgrid layouts={layouts}>
          {dirSearch}
          <div key='proh-attorneys' className={styles.table}>
            <Outline mode='heading'
              content={<div className={styles.headingCount}>{prohAttorneys.data.totalRecords}</div>}
              download={() => downloadProhAttorneys()}>
              Prohibited Attorneys
            </Outline>
            <Loading
              type='table'
              emptyMessage={'No service providers found.'}
              error={prohAttorneys.error}
              data={prohAttorneys.data.records}
              reloading={prohAttorneys.reloading}
              loaded={prohAttorneys.loaded}>
              <Table
                columns={prohAttorneysColumns}
                loaded={prohAttorneys.loaded}
                data={prohAttorneys.data.records}
                showHeader
              />
              <More
                onClick={expand(this, getProhAttorneys, 'prohAttorneys')}
                disabled={prohAttorneys.expandEmpty} />
            </Loading>
          </div>
          <div key='other-service-providers' className={styles.table}>
            <Outline mode='heading'
              content={<div className={styles.headingCount}>{prohOthers.data.totalRecords}</div>}
              download={() => downloadProhOthers()}>
                Prohibited Accountants / Auditors & Other Service Providers
            </Outline>
            <Loading
              type='table'
              emptyMessage={'No service providers found.'}
              error={prohOthers.error}
              data={prohOthers.data.records}
              reloading={prohOthers.reloading}
              loaded={prohOthers.loaded}>
              <Table
                columns={prohOthersColumns}
                loaded={prohOthers.loaded}
                data={prohOthers.data.records}
                showHeader
              />
              <More
                onClick={expand(this, getProhOthers, 'prohOthers')}
                disabled={prohOthers.expandEmpty} />
            </Loading>
          </div>
          <Sticky key='sticky-column' className={styles.sideColumn}>
            {dirSearch}
            {otherDirectories}
          </Sticky>
          {otherDirectories}
        </Flexgrid>
      </div>
    )
  }
}

DProhServiceProvidersPage.propTypes = {
  value: PropTypes.array,
  children: PropTypes.node.isRequired,
  location: PropTypes.object
}
