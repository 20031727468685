export function request (params) {
  let data = [
    {
      calcTime: '2017-03-18T21:33:50Z',
      dollarVolume: 17442538,
      isCaveatEmptor: false,
      item: 'tempus',
      itemDesc: 'habitasse platea dictumst morbi',
      numOfQuotes: 2.7,
      numOfTrades: 1.499,
      price: 11.35,
      pricePct: 0.875,
      symbol: 'CVRR',
      tierId: 3,
      tierName: 'OO',
      volume: 96592
    },
    {
      calcTime: '2016-10-12T06:37:19Z',
      dollarVolume: 20264856,
      isCaveatEmptor: false,
      item: 'velit',
      itemDesc: 'porttitor lacus at turpis',
      numOfQuotes: 1.884,
      numOfTrades: 2.741,
      price: 33.34,
      pricePct: 0.827,
      symbol: 'COH',
      tierId: 3,
      tierName: 'OO',
      volume: 96529
    },
    {
      calcTime: '2017-03-11T02:13:57Z',
      dollarVolume: 18558474,
      isCaveatEmptor: false,
      item: 'magna',
      itemDesc: 'quam nec',
      numOfQuotes: 2.178,
      numOfTrades: 3.034,
      price: 60.4,
      pricePct: 0.003,
      symbol: 'MINI',
      tierId: 1,
      tierName: 'DQ',
      volume: 97569
    },
    {
      calcTime: '2016-11-06T13:13:50Z',
      dollarVolume: 18692331,
      isCaveatEmptor: true,
      item: 'molestie',
      itemDesc: 'integer',
      numOfQuotes: 0.54,
      numOfTrades: 3.404,
      price: 50.93,
      pricePct: 0.888,
      symbol: 'ECYT',
      tierId: 1,
      tierName: 'DQ',
      volume: 97055
    },
    {
      calcTime: '2017-01-01T08:27:49Z',
      dollarVolume: 15692635,
      isCaveatEmptor: false,
      item: 'posuere',
      itemDesc: 'est',
      numOfQuotes: 4.014,
      numOfTrades: 3.082,
      price: 45.41,
      pricePct: 0.399,
      symbol: 'LNC',
      tierId: 1,
      tierName: 'DQ',
      volume: 97260
    },
    {
      calcTime: '2017-06-30T05:00:58Z',
      dollarVolume: 1193361,
      isCaveatEmptor: true,
      item: 'suspendisse',
      itemDesc: 'tristique in tempus',
      numOfQuotes: 1.524,
      numOfTrades: 4.427,
      price: 31.21,
      pricePct: 0.295,
      symbol: 'SBS',
      tierId: 0,
      tierName: 'QX',
      volume: 99214
    },
    {
      calcTime: '2017-03-21T15:53:16Z',
      dollarVolume: 6673874,
      isCaveatEmptor: true,
      item: 'dui',
      itemDesc: 'mi integer ac',
      numOfQuotes: 1.145,
      numOfTrades: 4.338,
      price: 4.26,
      pricePct: 0.24,
      symbol: 'GSUM',
      tierId: 0,
      tierName: 'QX',
      volume: 98168
    },
    {
      calcTime: '2017-04-21T20:24:56Z',
      dollarVolume: 16422245,
      isCaveatEmptor: false,
      item: 'sem',
      itemDesc: 'lacus morbi sem mauris',
      numOfQuotes: 3.853,
      numOfTrades: 0.477,
      price: 36.01,
      pricePct: 0.304,
      symbol: 'NYV',
      tierId: 2,
      tierName: 'PS',
      volume: 95807
    },
    {
      calcTime: '2017-03-02T11:59:17Z',
      dollarVolume: 11228494,
      isCaveatEmptor: false,
      item: 'integer',
      itemDesc: 'dui vel',
      numOfQuotes: 3.241,
      numOfTrades: 3.543,
      price: 1.3,
      pricePct: 0,
      symbol: 'PSA^X',
      tierId: 1,
      tierName: 'DQ',
      volume: 99138
    },
    {
      calcTime: '2016-09-24T06:17:01Z',
      dollarVolume: 12771550,
      isCaveatEmptor: true,
      item: 'nam',
      itemDesc: 'ac',
      numOfQuotes: 2.566,
      numOfTrades: 0.679,
      price: 21.99,
      pricePct: 0.322,
      symbol: 'AHPA',
      tierId: 2,
      tierName: 'PS',
      volume: 97031
    },
    {
      calcTime: '2016-10-17T13:45:04Z',
      dollarVolume: 1322122,
      isCaveatEmptor: false,
      item: 'nulla',
      itemDesc: 'feugiat et eros vestibulum',
      numOfQuotes: 1.211,
      numOfTrades: 0.409,
      price: 11.4,
      pricePct: 0.973,
      symbol: 'MTSC',
      tierId: 2,
      tierName: 'PS',
      volume: 96222
    },
    {
      calcTime: '2017-09-10T04:03:30Z',
      dollarVolume: 23189124,
      isCaveatEmptor: false,
      item: 'quis',
      itemDesc: 'justo',
      numOfQuotes: 0.415,
      numOfTrades: 3.17,
      price: 62.42,
      pricePct: 0.216,
      symbol: 'HQL',
      tierId: 1,
      tierName: 'DQ',
      volume: 96965
    },
    {
      calcTime: '2016-11-01T12:35:45Z',
      dollarVolume: 12622173,
      isCaveatEmptor: false,
      item: 'lacinia',
      itemDesc: 'sapien non',
      numOfQuotes: 4.649,
      numOfTrades: 0.663,
      price: 35.52,
      pricePct: 0.714,
      symbol: 'TRVG',
      tierId: 3,
      tierName: 'OO',
      volume: 97883
    },
    {
      calcTime: '2017-08-29T01:08:01Z',
      dollarVolume: 21845650,
      isCaveatEmptor: true,
      item: 'ligula',
      itemDesc: 'porttitor lorem',
      numOfQuotes: 0.98,
      numOfTrades: 1.517,
      price: 0.01,
      pricePct: 0.62,
      symbol: 'CXW',
      tierId: 0,
      tierName: 'QX',
      volume: 95922
    },
    {
      calcTime: '2017-05-17T20:46:54Z',
      dollarVolume: 10029358,
      isCaveatEmptor: true,
      item: 'amet',
      itemDesc: 'integer',
      numOfQuotes: 0.024,
      numOfTrades: 0.442,
      price: 30.76,
      pricePct: 0.204,
      symbol: 'NLS',
      tierId: 1,
      tierName: 'DQ',
      volume: 99199
    },
    {
      calcTime: '2016-11-15T13:29:43Z',
      dollarVolume: 3551256,
      isCaveatEmptor: false,
      item: 'adipiscing',
      itemDesc: 'cursus vestibulum proin',
      numOfQuotes: 2.328,
      numOfTrades: 2.648,
      price: 55.67,
      pricePct: 0.026,
      symbol: 'HAWK',
      tierId: 1,
      tierName: 'DQ',
      volume: 95129
    },
    {
      calcTime: '2016-10-08T12:07:31Z',
      dollarVolume: 491332,
      isCaveatEmptor: true,
      item: 'feugiat',
      itemDesc: 'gravida nisi',
      numOfQuotes: 1.316,
      numOfTrades: 4.713,
      price: 51.85,
      pricePct: 0.559,
      symbol: 'ETW',
      tierId: 1,
      tierName: 'DQ',
      volume: 95187
    },
    {
      calcTime: '2017-01-02T06:58:07Z',
      dollarVolume: 14857989,
      isCaveatEmptor: false,
      item: 'orci',
      itemDesc: 'aliquet massa id',
      numOfQuotes: 0.823,
      numOfTrades: 3.746,
      price: 63.63,
      pricePct: 0.404,
      symbol: 'GCVRZ',
      tierId: 1,
      tierName: 'DQ',
      volume: 97921
    },
    {
      calcTime: '2017-08-12T18:15:49Z',
      dollarVolume: 15213330,
      isCaveatEmptor: true,
      item: 'sapien',
      itemDesc: 'rutrum nulla nunc',
      numOfQuotes: 2.769,
      numOfTrades: 0.123,
      price: 31.55,
      pricePct: 0.593,
      symbol: 'SHPG',
      tierId: 2,
      tierName: 'PS',
      volume: 95025
    },
    {
      calcTime: '2017-08-29T17:16:58Z',
      dollarVolume: 6538533,
      isCaveatEmptor: true,
      item: 'at',
      itemDesc: 'pulvinar nulla',
      numOfQuotes: 1.166,
      numOfTrades: 3.99,
      price: 23.71,
      pricePct: 0.87,
      symbol: 'ABG',
      tierId: 0,
      tierName: 'QX',
      volume: 98623
    },
    {
      calcTime: '2017-07-14T15:34:00Z',
      dollarVolume: 12847552,
      isCaveatEmptor: true,
      item: 'amet',
      itemDesc: 'pretium quis lectus',
      numOfQuotes: 1.941,
      numOfTrades: 4.493,
      price: 17.52,
      pricePct: 0.054,
      symbol: 'MNR',
      tierId: 2,
      tierName: 'PS',
      volume: 99634
    },
    {
      calcTime: '2017-04-24T02:54:02Z',
      dollarVolume: 1115683,
      isCaveatEmptor: false,
      item: 'ut',
      itemDesc: 'quisque id justo sit',
      numOfQuotes: 4.351,
      numOfTrades: 3.317,
      price: 14.85,
      pricePct: 0.766,
      symbol: 'EPC',
      tierId: 3,
      tierName: 'OO',
      volume: 97914
    },
    {
      calcTime: '2017-02-22T13:49:44Z',
      dollarVolume: 15944472,
      isCaveatEmptor: true,
      item: 'mi',
      itemDesc: 'nulla justo aliquam',
      numOfQuotes: 1.949,
      numOfTrades: 1.125,
      price: 68.87,
      pricePct: 0.476,
      symbol: 'ANTX',
      tierId: 1,
      tierName: 'DQ',
      volume: 97047
    },
    {
      calcTime: '2017-07-30T06:02:01Z',
      dollarVolume: 16166802,
      isCaveatEmptor: true,
      item: 'sagittis',
      itemDesc: 'orci nullam molestie nibh',
      numOfQuotes: 3.542,
      numOfTrades: 4.079,
      price: 14.88,
      pricePct: 0.315,
      symbol: 'SNAP',
      tierId: 0,
      tierName: 'QX',
      volume: 99082
    },
    {
      calcTime: '2016-11-16T01:31:05Z',
      dollarVolume: 8911466,
      isCaveatEmptor: true,
      item: 'congue',
      itemDesc: 'ut',
      numOfQuotes: 4.679,
      numOfTrades: 3.184,
      price: 15.97,
      pricePct: 0.122,
      symbol: 'HQY',
      tierId: 2,
      tierName: 'PS',
      volume: 96825
    },
    {
      calcTime: '2016-11-19T19:07:30Z',
      dollarVolume: 17094271,
      isCaveatEmptor: false,
      item: 'potenti',
      itemDesc: 'sapien',
      numOfQuotes: 1.004,
      numOfTrades: 4.007,
      price: 14.02,
      pricePct: 0.348,
      symbol: 'PEGI',
      tierId: 0,
      tierName: 'QX',
      volume: 97119
    },
    {
      calcTime: '2017-08-26T06:44:19Z',
      dollarVolume: 9820181,
      isCaveatEmptor: false,
      item: 'vulputate',
      itemDesc: 'duis ac nibh',
      numOfQuotes: 2.638,
      numOfTrades: 2.647,
      price: 40.8,
      pricePct: 0.458,
      symbol: 'PACD',
      tierId: 2,
      tierName: 'PS',
      volume: 96387
    },
    {
      calcTime: '2017-05-20T04:00:12Z',
      dollarVolume: 24093218,
      isCaveatEmptor: true,
      item: 'nec',
      itemDesc: 'nulla tellus in sagittis',
      numOfQuotes: 1.185,
      numOfTrades: 3.472,
      price: 31.65,
      pricePct: 0.442,
      symbol: 'HCI',
      tierId: 0,
      tierName: 'QX',
      volume: 98213
    },
    {
      calcTime: '2017-07-08T20:56:23Z',
      dollarVolume: 296288,
      isCaveatEmptor: true,
      item: 'in',
      itemDesc: 'curae duis faucibus',
      numOfQuotes: 2.835,
      numOfTrades: 2.376,
      price: 25.72,
      pricePct: 0.851,
      symbol: 'CHSCL',
      tierId: 0,
      tierName: 'QX',
      volume: 97534
    },
    {
      calcTime: '2017-06-04T10:10:02Z',
      dollarVolume: 984051,
      isCaveatEmptor: true,
      item: 'nisl',
      itemDesc: 'suspendisse ornare consequat',
      numOfQuotes: 4.558,
      numOfTrades: 3.948,
      price: 56.73,
      pricePct: 0.773,
      symbol: 'BSET',
      tierId: 2,
      tierName: 'PS',
      volume: 96294
    },
    {
      calcTime: '2017-04-21T16:35:54Z',
      dollarVolume: 512690,
      isCaveatEmptor: true,
      item: 'ridiculus',
      itemDesc: 'vestibulum rutrum rutrum neque',
      numOfQuotes: 1.872,
      numOfTrades: 3.279,
      price: 47.62,
      pricePct: 0.312,
      symbol: 'IKNX',
      tierId: 0,
      tierName: 'QX',
      volume: 96064
    },
    {
      calcTime: '2017-04-12T01:32:30Z',
      dollarVolume: 21672721,
      isCaveatEmptor: true,
      item: 'libero',
      itemDesc: 'ullamcorper augue a',
      numOfQuotes: 4.569,
      numOfTrades: 4.511,
      price: 34.2,
      pricePct: 0.662,
      symbol: 'ATOM',
      tierId: 1,
      tierName: 'DQ',
      volume: 97091
    },
    {
      calcTime: '2016-10-31T11:39:53Z',
      dollarVolume: 16993833,
      isCaveatEmptor: false,
      item: 'habitasse',
      itemDesc: 'integer pede justo',
      numOfQuotes: 0.397,
      numOfTrades: 3.648,
      price: 52.7,
      pricePct: 0.423,
      symbol: 'VOYA',
      tierId: 3,
      tierName: 'OO',
      volume: 95516
    },
    {
      calcTime: '2017-02-22T01:37:19Z',
      dollarVolume: 13210900,
      isCaveatEmptor: false,
      item: 'eu',
      itemDesc: 'scelerisque',
      numOfQuotes: 1.658,
      numOfTrades: 0.175,
      price: 1.5,
      pricePct: 0.582,
      symbol: 'SHOS',
      tierId: 0,
      tierName: 'QX',
      volume: 96315
    },
    {
      calcTime: '2017-05-15T21:04:01Z',
      dollarVolume: 15088287,
      isCaveatEmptor: false,
      item: 'blandit',
      itemDesc: 'non mauris morbi',
      numOfQuotes: 1.128,
      numOfTrades: 4.896,
      price: 30.38,
      pricePct: 0.332,
      symbol: 'EHIC',
      tierId: 0,
      tierName: 'QX',
      volume: 95287
    },
    {
      calcTime: '2016-11-06T03:02:59Z',
      dollarVolume: 3125022,
      isCaveatEmptor: false,
      item: 'vel',
      itemDesc: 'rutrum at',
      numOfQuotes: 3.314,
      numOfTrades: 0.727,
      price: 50.59,
      pricePct: 0.141,
      symbol: 'PFO',
      tierId: 0,
      tierName: 'QX',
      volume: 97643
    },
    {
      calcTime: '2016-10-20T16:48:24Z',
      dollarVolume: 12131142,
      isCaveatEmptor: false,
      item: 'morbi',
      itemDesc: 'vestibulum',
      numOfQuotes: 3.787,
      numOfTrades: 4.502,
      price: 52.56,
      pricePct: 0.361,
      symbol: 'PSCH',
      tierId: 1,
      tierName: 'DQ',
      volume: 98087
    },
    {
      calcTime: '2017-04-02T11:50:30Z',
      dollarVolume: 18694779,
      isCaveatEmptor: true,
      item: 'in',
      itemDesc: 'porttitor pede justo eu',
      numOfQuotes: 0.987,
      numOfTrades: 3.737,
      price: 28.3,
      pricePct: 0.391,
      symbol: 'BGG',
      tierId: 3,
      tierName: 'OO',
      volume: 98290
    },
    {
      calcTime: '2016-10-20T09:28:46Z',
      dollarVolume: 21703429,
      isCaveatEmptor: false,
      item: 'nulla',
      itemDesc: 'sodales sed',
      numOfQuotes: 1.319,
      numOfTrades: 4.471,
      price: 0.10,
      pricePct: 0.512,
      symbol: 'ALKS',
      tierId: 1,
      tierName: 'DQ',
      volume: 99296
    },
    {
      calcTime: '2016-09-28T23:31:32Z',
      dollarVolume: 14247022,
      isCaveatEmptor: false,
      item: 'quis',
      itemDesc: 'justo pellentesque',
      numOfQuotes: 1.01,
      numOfTrades: 0.716,
      price: 33.62,
      pricePct: 0.676,
      symbol: 'FTSM',
      tierId: 3,
      tierName: 'OO',
      volume: 98426
    },
    {
      calcTime: '2016-09-16T13:26:59Z',
      dollarVolume: 17936722,
      isCaveatEmptor: false,
      item: 'habitasse',
      itemDesc: 'orci nullam molestie',
      numOfQuotes: 0.173,
      numOfTrades: 3.755,
      price: 55.19,
      pricePct: 0.357,
      symbol: 'SMED',
      tierId: 2,
      tierName: 'PS',
      volume: 97324
    },
    {
      calcTime: '2016-12-03T15:44:14Z',
      dollarVolume: 6789891,
      isCaveatEmptor: true,
      item: 'habitasse',
      itemDesc: 'sapien cursus',
      numOfQuotes: 0.63,
      numOfTrades: 4.969,
      price: 12.35,
      pricePct: 0.435,
      symbol: 'LMFA',
      tierId: 0,
      tierName: 'QX',
      volume: 99331
    },
    {
      calcTime: '2016-09-12T19:34:04Z',
      dollarVolume: 1736739,
      isCaveatEmptor: true,
      item: 'mollis',
      itemDesc: 'proin at turpis',
      numOfQuotes: 3.608,
      numOfTrades: 3.769,
      price: 33.58,
      pricePct: 0.216,
      symbol: 'CVRR',
      tierId: 3,
      tierName: 'OO',
      volume: 96514
    },
    {
      calcTime: '2017-08-05T08:34:39Z',
      dollarVolume: 15316529,
      isCaveatEmptor: true,
      item: 'eu',
      itemDesc: 'lectus suspendisse',
      numOfQuotes: 2.47,
      numOfTrades: 2.207,
      price: 25.88,
      pricePct: 0.663,
      symbol: 'ENIA',
      tierId: 0,
      tierName: 'QX',
      volume: 95089
    },
    {
      calcTime: '2017-01-19T07:24:43Z',
      dollarVolume: 11978875,
      isCaveatEmptor: true,
      item: 'ante',
      itemDesc: 'nulla',
      numOfQuotes: 0.489,
      numOfTrades: 1.66,
      price: 33.24,
      pricePct: 0.873,
      symbol: 'SBS',
      tierId: 0,
      tierName: 'QX',
      volume: 95877
    },
    {
      calcTime: '2017-01-03T18:14:46Z',
      dollarVolume: 23769791,
      isCaveatEmptor: true,
      item: 'eget',
      itemDesc: 'parturient montes',
      numOfQuotes: 0.667,
      numOfTrades: 4.5,
      price: 47.52,
      pricePct: 0.661,
      symbol: 'MB',
      tierId: 1,
      tierName: 'DQ',
      volume: 98486
    },
    {
      calcTime: '2016-10-10T09:23:18Z',
      dollarVolume: 20492842,
      isCaveatEmptor: false,
      item: 'morbi',
      itemDesc: 'vivamus',
      numOfQuotes: 1.272,
      numOfTrades: 3.848,
      price: 59.31,
      pricePct: 0.848,
      symbol: 'GOOG',
      tierId: 2,
      tierName: 'PS',
      volume: 95694
    },
    {
      calcTime: '2017-06-19T13:07:23Z',
      dollarVolume: 22823816,
      isCaveatEmptor: true,
      item: 'erat',
      itemDesc: 'rhoncus aliquam',
      numOfQuotes: 2.651,
      numOfTrades: 3.424,
      price: 23.66,
      pricePct: 0.407,
      symbol: 'JMT',
      tierId: 2,
      tierName: 'PS',
      volume: 97022
    },
    {
      calcTime: '2017-05-28T00:01:07Z',
      dollarVolume: 3223551,
      isCaveatEmptor: true,
      item: 'duis',
      itemDesc: 'quis',
      numOfQuotes: 2.3,
      numOfTrades: 2.623,
      price: 67.36,
      pricePct: 0.59,
      symbol: 'KIN',
      tierId: 0,
      tierName: 'QX',
      volume: 95053
    },
    {
      calcTime: '2017-07-01T19:36:46Z',
      dollarVolume: 6158740,
      isCaveatEmptor: true,
      item: 'nascetur',
      itemDesc: 'lectus in',
      numOfQuotes: 0.469,
      numOfTrades: 2.628,
      price: 56.35,
      pricePct: 0.353,
      symbol: 'AGNCP',
      tierId: 3,
      tierName: 'OO',
      volume: 99139
    }
  ]

  // Filter Tier Group
  if (params.tierGroup && params.tierGroup.toUpperCase() !== 'ALL') {
    data = data.filter(d => d.tierName === params.tierGroup)
  }

  if (params.minPrice) {
    data = data.filter(d => d.price > parseFloat(params.minPrice))
  }

  const length = data.length

  // Handle Page
  let page = 1
  if (params.page) page = params.page
  // Page Size
  if (params.pageSize) {
    data = data.slice(page === 1 ? 0 : (page - 1) * params.pageSize,
      page === 1 ? params.pageSize : ((page - 1) * params.pageSize) + params.pageSize)
  }
  return {
    totalRecords: length,
    pages: Math.ceil(length / params.pageSize),
    pageSize: params.pageSize,
    records: data,
    sortOn: params.sortOn,
    isRealTime: false,
    bySymbol: true
  }
}
