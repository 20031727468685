import api from '../../api'

export async function getIncomeStatementData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    responseFormat: 'array',
    mockData: 'company/financials/income-statement-data',
    url: `/financials/income-statement/${params.symbol}`,
    params
  })
  return response.data
}

export async function getBalanceSheetData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    responseFormat: 'array',
    mockData: 'company/financials/balance-sheet-data',
    url: `/financials/balance-sheet/${params.symbol}`,
    params
  })
  return response.data
}

export async function getCashFlowData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    responseFormat: 'array',
    mockData: 'company/financials/cash-flow-data',
    url: `/financials/cash-flow/${params.symbol}`,
    params
  })
  return response.data
}

export async function getFinancialReports (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'company/financials/financial-reports',
    url: `/company/${params.symbol}/financial-report`,
    params
  })
  return response.data
}

export async function getFinancialReportsByTier (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    mockData: 'company/financials/financial-reports-by-tier',
    url: '/company/financial-report',
    params
  })
  return response.data
}
