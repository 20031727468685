import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { logEvent } from '../../analytics'

const ExternalLink = ({ to, href, target, external, children, ga, ...props }) => {
  let url = to || href || '#'
  const onClick = () => {
    if (ga) {
      logEvent(ga.category, ga.action, url)
    }
  }
  const isExternal = url && (url.indexOf('http://') === 0 || url.indexOf('https://') === 0 || url.indexOf('mailto:') === 0)
  // external
  if ((isExternal || external) && url !== '#') {
    // add http if missing
    if (url.indexOf('http') !== 0 && url.indexOf('mailto:') !== 0) url = 'http://' + url
    // if no children specified, use formatted URL
    if (!children) {
      children = url
        .replace(/^(http:\/\/)/, '')
        .replace(/^(https:\/\/)/, '')
        .replace(/\/$/, '')
    }

    return <a onClick={onClick} {...props} target={target || '_blank'} rel='noopener' href={url}>{children}</a>
  }
  // internal fallback
  return <Link to={url} onClick={onClick} {...props} target={target === '_self' ? null : target}>{children}</Link>
}

ExternalLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node,
  ga: PropTypes.object,
  /* forces external in all cases */
  external: PropTypes.bool
}

export default ExternalLink
