import React from 'react'
import PropTypes from 'prop-types'
import styles from './MABrokerDealerPage.module.scss'
import { withRouter } from '../../components/WithRouter'
import { getSnapshot, getExecVolume, getExecLinkVolume, getTotalLinkVolume, getResponseQuality, downloadBrokerDealer } from '../../api/otc/market-activity/broker-dealer'
import { template, fetch, expand, sort } from '../../api/helper'
import * as Routes from '../../constants/Routes'
import { sort as sortArray } from 'fast-sort'
import MarketActivityOverview from '../../components/MarketActivityOverview'
import Flexgrid from '../../components/Flexgrid'
import Table from '../../components/Table'
import More from '../../components/More'
import Outline from '../../components/Outline'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import HeadTitle from '../../components/HeadTitle'
import Sticky from '../../components/Sticky'
import { formatProper } from '../../utils/locale'

/** Headers for the Table */
const bdColumns = [
  {
    name: 'brokerDealer',
    header: 'Broker Dealer',
    linkTo: (item) => `${Routes.DIR_BROKER_DEALER}/${item.mpid}`,
    sortingEnabled: true,
    sortingKey: 'name',
    component: ({ data }) => data.external ? `${data.brokerDealer}*` : data.brokerDealer
  },
  {
    name: 'mpid',
    header: 'MPID',
    linkTo: (item) => `${Routes.DIR_BROKER_DEALER}/${item.mpid}`,
    sortingEnabled: true,
    hideOnMobile: true
  }
]

const volumeColumns = [
  ...bdColumns,
  {
    name: 'dollarVolume',
    header: '$ Vol',
    type: 'int',
    sortingKey: 'dollarVolume',
    sortingEnabled: true
  },
  {
    name: 'volume',
    header: 'Share Vol',
    type: 'int',
    headerShort: 'Share Vol',
    sortingKey: 'executedVolume',
    sortingEnabled: true
  },
  {
    name: 'numOfTrades',
    header: 'Trades',
    type: 'int',
    sortingEnabled: true,
    sortingKey: 'trades',
    hideOnMobile: true
  }
]

const resStatColumns = [
  ...bdColumns,
  {
    name: 'l1AvgR',
    header: 'L1 AVG R',
    type: 'seconds',
    sortingKey: 'l1AvgR',
    sortingEnabled: true
  },
  {
    name: 'expiredL1Pct',
    header: '*Expired L1 %',
    headerShort: '*Exp L1%',
    type: 'tenths',
    sortingEnabled: true
  },
  {
    name: 'saturationPct',
    header: '**Saturation %',
    headerShort: '**Sat L1%',
    type: 'tenths',
    sortingEnabled: true
  },
  {
    name: 'qscore',
    header: 'Q Score',
    type: 'float',
    sortingEnabled: true
  }
]

const columns = {
  execVol: volumeColumns,
  execLinkOnlyVol: volumeColumns,
  totalLinkVol: volumeColumns,
  resStat: resStatColumns
}

const api = {
  execVol: getExecVolume,
  execLinkOnlyVol: getExecLinkVolume,
  totalLinkVol: getTotalLinkVolume,
  resStat: getResponseQuality
}

const tableOptions = [
  { title: 'Executed Volume', value: 'execVol' },
  { title: 'Executed Link Volume', value: 'execLinkOnlyVol' },
  { title: 'Total Link Volume', value: 'totalLinkVol' },
  { title: 'Response Statistics', value: 'resStat' }
]

class MABrokerDealerPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      loaded: false,
      snapshot: template('object'),
      list: template('records', 'dollarVolume', 'desc'),
      selectedLoadedPage: 1,
      tableSelection: 'execVol'
    }
    this.initialState = Object.assign({}, this.state)
  }

  /**
   * Load Initial Data
   */
  componentDidMount () {
    this.loadSnapshot()
    this.loadData()
  }

  /**
   * When the component updates, check if the market changed, and requery the data if so.
   * @param {Object} prevProps - The previous props to compare to.
   */
  componentDidUpdate (prevProps) {
    const { match: { params: { market: prevMarket } } } = prevProps
    const { match: { params: { market } } } = this.props
    if (market !== prevMarket) {
      this.loadSnapshot()
      this.loadData()
    }
  }

  /**
   * Mapping snapshot data to have better headers displayed.
   * @param {Array[Object]} data - Snapshot data
   */
  getInfoFromData = (data) => {
    const info = []
    if (!data) return info
    Object.keys(data).forEach((key) => {
      const item = { key, value: data[key], title: '' }

      if (Array.isArray(item.value) || !item.value) {
        item.value = 0
      }

      switch (key) {
        case 'dollarVolume':
          item.title = '$ Volume'
          break
        case 'shareVolume':
          item.title = 'Share Volume'
          break
        case 'trades':
        case 'advancers':
        case 'decliners':
          item.title = item.key.charAt(0).toUpperCase() + item.key.slice(1)
          break
      }
      info.push(item)
    })
    return info
  }

  /**
   * Loads snapshot data
   */
  loadSnapshot = () => {
    const { match: { params: { market } } } = this.props

    // get snapshot
    fetch(this, getSnapshot, 'snapshot', {
      tierGroup: market
    }, data => {
      if (!data) data = {}
      data.info = this.getInfoFromData(data)
      return data
    })
  }

  /**
   * Loads the necessary data for the snapshot and table.
   */
  loadData = () => {
    const { match: { params: { market } } } = this.props
    // get list
    fetch(this, api[this.state.tableSelection], 'list', {
      tierGroup: market,
      page: 1,
      pageSize: this.state.tableSelection === 'resStat' ? 1000 : 20,
      sortDir: this.state.tableSelection === 'resStat' ? 'asc' : 'desc',
      sortOn: this.state.tableSelection === 'resStat' ? 'l1AvgR' : 'dollarVolume'
    })
  }

  /**
   * Navigate to requery and change the market selected.
   * @param {string} market - The market to change to.
   */
  changeMarket = (market) => {
    let location = '/market-activity/broker-dealer-data'
    if (market) {
      location += '/' + market
    }
    this.props.navigate(location)
  }

  onSelect = () => {
    return (tableName) => {
      this.setState({
        tableSelection: tableName
      }, () => {
        this.loadData()
      })
    }
  }

  handleSort = (col, dir) => {
    this.setState(prevState => ({
      list: {
        ...prevState.list,
        sortOn: col,
        sortDir: dir
      }
    }))
  }

  render () {
    const { match: { params: { market } } } = this.props
    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['overview', 'table']
        }
      ],
      md: [
        {
          type: 'flex',
          column: true,
          subtree: ['overview', 'table']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['overview']
            },
            {
              type: 'main',
              subtree: ['table']
            }
          ]
        }
      ]
    }

    const overviewLayouts = {
      sm: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'flex',
              column: true,
              width: 1 / 2,
              className: styles.overviewLeft,
              subtree: ['dollarVolume', 'trades']
            },
            {
              type: 'flex',
              column: true,
              width: 1 / 2,
              className: styles.overviewRight,
              subtree: ['shareVolume']
            }
          ]
        }
      ],
      md: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'flex',
              column: true,
              width: 1 / 2,
              className: styles.overviewLeft,
              subtree: ['dollarVolume', 'trades']
            },
            {
              type: 'flex',
              column: true,
              width: 1 / 2,
              className: styles.overviewRight,
              subtree: ['shareVolume']
            }
          ]
        }
      ],
      lg: [
        {
          type: 'flex',
          column: true,
          subtree: ['dollarVolume', 'shareVolume', 'trades']
        }
      ]
    }

    const externalTrade = this.state.list.data.records.find(r => r.external)
    const notTradedFooter = (this.state.tableSelection === 'execVol' || this.state.tableSelection === 'execLinkOnlyVol') && externalTrade
    const cols = columns[this.state.tableSelection]
    const isResStat = this.state.tableSelection === 'resStat'

    let data = this.state.list.data && this.state.list.data.records && this.state.list.data.records.map(item => {
      const dataItem = item
      Object.keys(item).forEach((key) => {
        const value = item[key]
        const col = cols.find(c => c.name === key)
        if (!col) return item
        if (isResStat) item[key] = formatProper(value, '', col.type)
      })

      return dataItem
    })

    if (isResStat) {
      const l1AvgRASC = this.state.list.sortOn === 'l1AvgR' && this.state.list.sortDir.toLowerCase() === 'asc'
      const l1AvgRDESC = this.state.list.sortOn === 'l1AvgR' && this.state.list.sortDir.toLowerCase() === 'desc'
      const expiredL1PctASC = this.state.list.sortOn === 'expiredL1Pct' && this.state.list.sortDir.toLowerCase() === 'asc'
      const expiredL1PctDESC = this.state.list.sortOn === 'expiredL1Pct' && this.state.list.sortDir.toLowerCase() === 'desc'
      const saturationPctASC = this.state.list.sortOn === 'saturationPct' && this.state.list.sortDir.toLowerCase() === 'asc'
      const saturationPctDESC = this.state.list.sortOn === 'saturationPct' && this.state.list.sortDir.toLowerCase() === 'desc'
      const qscoreASC = this.state.list.sortOn === 'qscore' && this.state.list.sortDir.toLowerCase() === 'asc'
      const qscoreDESC = this.state.list.sortOn === 'qscore' && this.state.list.sortDir.toLowerCase() === 'desc'
      const mpidASC = this.state.list.sortOn === 'mpid' && this.state.list.sortDir.toLowerCase() === 'asc'
      const mpidDESC = this.state.list.sortOn === 'mpid' && this.state.list.sortDir.toLowerCase() === 'desc'
      const nameASC = this.state.list.sortOn === 'name' && this.state.list.sortDir.toLowerCase() === 'asc'
      const nameDESC = this.state.list.sortOn === 'name' && this.state.list.sortDir.toLowerCase() === 'desc'

      if (l1AvgRASC) {
        data = sortArray(data).by([
          { asc: d => d.l1AvgR },
          { asc: d => d.expiredL1Pct },
          { asc: d => d.saturationPct },
          { desc: d => d.qscore },
          { asc: d => d.mpid }
        ])
      } else if (l1AvgRDESC) {
        data = sortArray(data).by([
          { desc: d => d.l1AvgR },
          { desc: d => d.expiredL1Pct },
          { desc: d => d.saturationPct },
          { asc: d => d.qscore },
          { desc: d => d.mpid }
        ])
      } else if (expiredL1PctASC) {
        data = sortArray(data).by([
          { asc: d => d.expiredL1Pct },
          { asc: d => d.l1AvgR },
          { asc: d => d.saturationPct },
          { desc: d => d.qscore },
          { asc: d => d.mpid }
        ])
      } else if (expiredL1PctDESC) {
        data = sortArray(data).by([
          { desc: d => d.expiredL1Pct },
          { desc: d => d.l1AvgR },
          { desc: d => d.saturationPct },
          { asc: d => d.qscore },
          { desc: d => d.mpid }
        ])
      } else if (saturationPctASC) {
        data = sortArray(data).by([
          { asc: d => d.saturationPct },
          { asc: d => d.l1AvgR },
          { asc: d => d.expiredL1Pct },
          { desc: d => d.qscore },
          { asc: d => d.mpid }
        ])
      } else if (saturationPctDESC) {
        data = sortArray(data).by([
          { desc: d => d.saturationPct },
          { desc: d => d.l1AvgR },
          { desc: d => d.expiredL1Pct },
          { asc: d => d.qscore },
          { desc: d => d.mpid }
        ])
      } else if (qscoreASC) {
        data = sortArray(data).by([
          { asc: d => d.qscore },
          { desc: d => d.l1AvgR },
          { desc: d => d.expiredL1Pct },
          { desc: d => d.saturationPct },
          { desc: d => d.mpid }
        ])
      } else if (qscoreDESC) {
        data = sortArray(data).by([
          { desc: d => d.qscore },
          { asc: d => d.l1AvgR },
          { asc: d => d.expiredL1Pct },
          { asc: d => d.saturationPct },
          { asc: d => d.mpid }
        ])
      } else if (mpidASC) {
        data = sortArray(data).by([
          { asc: d => d.mpid }
        ])
      } else if (mpidDESC) {
        data = sortArray(data).by([
          { desc: d => d.mpid }
        ])
      } else if (nameASC) {
        data = sortArray(data).by([
          { asc: d => d.brokerDealer }
        ])
      } else if (nameDESC) {
        data = sortArray(data).by([
          { desc: d => d.brokerDealer }
        ])
      }
    }

    return (
      <div className={styles.MACurrentMarketPage}>
        <HeadTitle title='Explore Broker Dealer Trade and Volume Data' />
        <PageTitle>Broker Dealer Data</PageTitle>
        <Flexgrid layouts={layouts}>
          <Loading
            key='overview'
            height='14em'
            emptyMessage='No snapshot data available'
            loaded={this.state.snapshot.loaded}
            error={this.state.snapshot.error}
            data={this.state.snapshot.data.info}>
            <Sticky className={styles.sideColumn}>
              {this.state.snapshot.loaded && <MarketActivityOverview
                selectedMarket={market}
                changeMarket={this.changeMarket}
                info={this.state.snapshot.data.info}
                date={this.state.snapshot.data.lastUpdated}
                layouts={overviewLayouts} />}
            </Sticky>
          </Loading>
          <div key='table' className={styles.tradeDataTable}>
            <Outline mode='heading' hideFilterToggle filters={[{
              type: 'select',
              required: true,
              options: tableOptions,
              defaultValue: tableOptions[0].value,
              onSelect: this.onSelect()
            }]} download={() => downloadBrokerDealer(this.state.tableSelection)}>
              Broker Dealer Data
            </Outline>
            <Loading
              type='table'
              loaded={this.state.list.loaded}
              reloading={this.state.list.reloading}
              error={this.state.list.error}
              data={data}>
              <Table
                columns={cols}
                data={data}
                sortColumn={this.state.list.sortOn}
                sortDirection={this.state.list.sortDir}
                onSorting={isResStat ? this.handleSort : sort(this, api[this.state.tableSelection], 'list')}
                showHeader
                overline
              />
              <TableFooter>
                <More onClick={expand(this, api[this.state.tableSelection], 'list')}
                  disabled={this.state.list.expandEmpty} />
                {this.state.list.data.records && this.state.list.data.totalRecords &&
                  <DisplayResults show={this.state.list.data.records.length} total={this.state.list.data.totalRecords} text={'Results'} />
                }
              </TableFooter>
              {notTradedFooter && <abbr className={styles.abbr}>
                  * Includes volume not traded on OTC Link ATS
              </abbr>}
              {this.state.tableSelection === 'resStat' && <abbr className={styles.abbr}>
                * Percent of expired L1 trade messages, per total L1 trade messages <br />
                ** Percent of Saturation events, per total liability trade messages
              </abbr>}
            </Loading>
          </div>
        </Flexgrid>
      </div>
    )
  }
}

MABrokerDealerPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      market: PropTypes.string,
      table: PropTypes.string
    })
  }),
  navigate: PropTypes.func.isRequired
}

export default withRouter(MABrokerDealerPage)
