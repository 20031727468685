import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styles from './styles.module.scss'

import Outline from '../Outline'

class List extends React.PureComponent {
  constructor (props, context) {
    super(props, context)

    this.state = {
      open: !this.props.collapsed
    }
  }

  onClick = () => {
    this.setState(state => ({
      open: !state.open
    }))
  }

  render () {
    const { accent } = this.props

    return <div className={classNames({
      [styles.list]: true
    })} >
      {this.props.title && <Outline mode='heading' accent={accent}>
        {this.props.title}
        <span className={styles.chevron} onClick={this.onClick}>
          {this.state.open && <FaChevronDown />}
          {!this.state.open && <FaChevronUp />}
        </span>
      </Outline>}
      <div className={classNames({
        [styles.container]: true,
        [styles.display]: this.state.open
      })}>
        {this.props.list.map((item, index) => <div
          className={classNames({
            [styles.item]: true,
            [styles.borders]: this.props.borders
          })}
          key={`list-item-${index}`}>
          {item.title && <div
            className={styles.title}>
            {item.title}
          </div>}
          {item.value}
        </div>)}
      </div>
    </div>
  }
}

List.propTypes = {
  /** Component title */
  title: PropTypes.string,
  /** Enable borders? */
  borders: PropTypes.bool,
  /** Initially collapsed list */
  collapsed: PropTypes.bool,
  /** List content */
  list: PropTypes.array.isRequired,
  /** Accent color */
  accent: PropTypes.string
}

export default List
