import api from '../../api'

export async function getDividends (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    url: '/company/dividends',
    params
  })
  return response.data
}

export async function getSplits (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    url: '/company/splits',
    params
  })
  return response.data
}

export async function getShareStructureHistory (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: `/compliance/share-structure/${params.symbol && params.symbol}`,
    params
  })
  return response.data
}
