import React from 'react'
import PropTypes from 'prop-types'
import styles from './Subscribe.module.scss'
import classNames from 'classnames'
import Input from '../Input'

import { subscribe } from '../../api/otc/newsletter'

class Subscribe extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      email: '',
      success: null,
      error: null
    }
  }

  subscribe = () => {
    this.setState({ error: null })
    subscribe(this.state.email).then(() => {
      this.setState({ success: true })
    }).catch(error => {
      this.setState({ error })
    })
  }

  changeEmail = (email) => {
    this.setState({ email })
  }

  render () {
    return <div key='subscription' className={classNames({
      [styles.Subscribe]: true,
      [styles.nomargin]: this.props.nomargin
    })}>
      <div className={styles.title}>Subscribe to Our Newsletter</div>
      <div className={styles.description}>Stay up to date on the latest company news, industry trends and regulatory changes that affect our markets and learn about members of our community.</div>
      {this.state.success !== true && <div className={styles.emailInputContainer}>
        <Input
          title='Enter your email'
          className={styles.emailInput}
          design='standard'
          type='email'
          onChange={this.changeEmail}
          onReturn={this.subscribe}
        />
        <button className={styles.signupButton} onClick={this.subscribe}>Sign Up</button>
      </div>}
      {this.state.error && <div className={styles.error}>{this.state.error.message}</div>}
      {this.state.success === true && <div className={styles.success}>Thank you for subscribing!</div>}
    </div>
  }
}

Subscribe.propTypes = {
  nomargin: PropTypes.bool
}

export default Subscribe
