import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const SymbolLink = (props) => {
  const symbol = props.symbol || props.data.symbol || props.data.ticker
  const disableLink = props.disableLink
  let tier = props.tier || (props.data && (props.data.tierId || props.data.tierCode || props.data.tierGroup))
  if (!isNaN(parseInt(tier))) tier = parseInt(tier)
  if (!props.disableCaveatEmptor && props.data && props.data.isCaveatEmptor === true) tier = 'CE'

  let tierIcon = null

  switch (tier) {
    case -1:
    case 'CE':
      tierIcon = 'caveat-emptor'
      break

    case 1:
    case 2:
    case 5:
    case 6:
    case 'OTCQX':
    case 'QX':
    case 'QXUSP':
    case 'QXUS':
    case 'QXIP':
    case 'QXI':
      tierIcon = 'qx' // QX
      break

    case 10:
    case 'OTCQB':
    case 'QB':
    case 'DQ':
      tierIcon = 'qb' // QB
      break

    case 20:
    case 'PS':
    case 'PC':
      tierIcon = 'ps' // Pink
      break

    case 21:
    case 'PL':
      tierIcon = 'yield' // Pink Limited Info
      break

    case 22:
    case 'PN':
      tierIcon = 'stop' // Pink No Info
      break

    case 30:
    case 'OO':
    case 'GM':
      tierIcon = 'caution' // Grey Market
      break

    case 40:
    case 'EM':
      tierIcon = 'expert-tier' // Expert Tier
      break

    default:
      break
  }

  return <span className={classNames(styles.container, tierIcon && styles.hasIcon, {
    [styles.large]: props.large
  })}>
    {tierIcon && <div className={styles.tierIcon}>
      <img src={`/icons/tier/${tierIcon}.png`} />
    </div>}
    {disableLink
      ? symbol
      : <Link
      to={`/stock/${symbol}`}>
      {symbol}
    </Link>}
  </span>
}

SymbolLink.propTypes = {
  data: PropTypes.object,
  tier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  symbol: PropTypes.string,
  disableCaveatEmptor: PropTypes.bool,
  disableLink: PropTypes.bool,
  large: PropTypes.bool
}

export default SymbolLink
