import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getCaveatEmptor (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/statistics/caveat-emptor',
    params
  })
  return response.data
}

export async function getCompanyNameChange (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/statistics/company-name-changes',
    params
  })
  return response.data
}

export async function getPromotions (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/statistics/promotions',
    params
  })
  return response.data
}

export async function getGracePeriod (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/statistics/grace-period',
    params
  })
  return response.data
}

export async function getReverseSplits (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/statistics/reverse-splits',
    params
  })
  return response.data
}

export async function getShellRisk (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/statistics/shell-risk-changes',
    params
  })
  return response.data
}

export async function getShellStatus (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/statistics/shell-status-changes',
    params
  })
  return response.data
}

export async function getSuspensionsRevocations (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/statistics/suspensions-revocations',
    params
  })
  return response.data
}

export async function downloadStats (pathname, params) {
  const url = `/internalapi/download/compliance/statistics/${pathname}/download`
  const queryString = Object.keys(params)
    .filter(key => params[key])
    .map(key => params[key] && `${key}=${params[key]}`).join('&')
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
  window.open(`${REACT_APP_API_BASE_URI}${url}?${queryString}&file=${pathname}&token=${jwtToken}`)
}
