import React from 'react'
import ChangePassword from '../../components/ChangePassword'

class ChangePasswordPage extends React.PureComponent {
  render () {
    return <ChangePassword />
  }
}

export default ChangePasswordPage
