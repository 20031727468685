import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from './DBrokerDealerDetailPage.module.scss'
import Flexgrid from '../../components/Flexgrid'
import { Flex, Box } from 'grid-styled'
import { getDetails, getFilters } from '../../api/otc/directory/broker-dealer'
import { template, fetch, expand, sort } from '../../api/helper'
import * as Routes from '../../constants/Routes'
import { withRouter } from '../../components/WithRouter'
import Loading from '../../components/Loading'
import NotFound from '../NotFound'
import HeadTitle from '../../components/HeadTitle'
import Outline from '../../components/Outline'
import BackLink from '../../components/BackLink'
import Table from '../../components/Table'
import More from '../../components/More'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Select from '../../components/Select'
import PageTitle from '../../components/PageTitle'
import { Breadcrumbs } from '../../components/Breadcrumbs'

const columns = [
  {
    name: 'symbol',
    header: 'Symbol',
    type: 'symbol'
  },
  {
    name: 'company',
    header: 'Company'
  },
  {
    name: 'tier',
    header: 'Tier',
    hideOnMobile: true
  },
  {
    name: 'securityType',
    header: 'Security Type',
    hideOnMobile: true,
    hideOnTablet: true
  },
  {
    name: 'country',
    header: 'Country'
  }
]

const getAddress = address => {
  if (!address) return null
  return (
    <div>
      {address.addr1 && <div>{address.addr1}</div>}
      {address.addr2 && <div>{address.addr2}</div>}
      {address.addr3 && <div>{address.addr3}</div>}
      <div>{address.city}, {address.state} {address.zip}</div>
    </div>
  )
}

class DBrokerDealerDetailPage extends PureComponent {
  state = {
    details: template('records'),
    filters: {},
    filterTier: null,
    filterSecurityType: null,
    filterCountry: null
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = () => {
    const mpid = this.props.match.params.mpid

    getFilters().then((filters) => {
      this.setState({ filters })
    })

    fetch(this, getDetails, 'details', {
      mpid,
      page: 1,
      pageSize: 10,
      tier: this.state.filterTier || 'ALL',
      securityType: this.state.filterSecurityType || 'ALL',
      country: this.state.filterCountry || 'ALL'
    })
  }

  setFilter = (filterName) => {
    return (val) => {
      this.setState({ [filterName]: val }, () => {
        this.loadData()
      })
    }
  }

  get details () {
    const { details } = this.state

    const dealerInfo = details.data.dealerInfo
    const addresses = dealerInfo.addresses

    return (
      <div>
        <div className={styles.companyName}>
          {dealerInfo.name}
        </div>
        <div className={styles.addressWrapper}>
          {addresses && addresses.map((address, i) =>
            <div key={i} className={styles.address}>
              {getAddress(address)}
            </div>
          )}
        </div>
      </div>
    )
  }

  render () {
    const { details } = this.state

    if (details.error || (details.loaded && (!details.data || !details.data.dealerInfo))) return <NotFound />
    if (!details.data || !details.data.dealerInfo) return <Loading />

    const layouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['back', 'details', 'content']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['back', 'details']
            },
            {
              type: 'main',
              mt: '1.8rem',
              subtree: ['content']
            }
          ]
        }
      ]
    }

    return (
      <div className={styles.component}>
        <Breadcrumbs items={[
          { title: 'Directories', slug: '/directories' },
          {
            title: 'Broker Dealer Directory',
            slug: Routes.DIR_BROKER_DEALER
          }, (details.data.dealerInfo && details.data.dealerInfo.name) || '']
        } />
        <HeadTitle title='Directory - Broker Dealer Directory' />
        <PageTitle>Broker-Dealer Directory</PageTitle>
        <Flexgrid layouts={layouts}>
          <BackLink to={Routes.DIR_BROKER_DEALER} key='back' />
          <div key='details' className={styles.details}>
            {this.details}
          </div>
          <div key='content'>
            <div className={styles.filtersWrapper}>
              <Flex wrap className={styles.filters}>
                <Box w={[1, 1 / 3]}>
                  <Select
                    title='Tier'
                    options={this.state.filters.tiers}
                    onSelect={this.setFilter('filterTier')}
                    design='standard' />
                </Box>
                <Box w={[1, 1 / 3]}>
                  <Select
                    title='Type'
                    options={this.state.filters.securityTypes}
                    onSelect={this.setFilter('filterSecurityType')}
                    design='standard' />
                </Box>
                <Box w={[1, 1 / 3]}>
                  <Select
                    title='Country'
                    options={this.state.filters.countries}
                    onSelect={this.setFilter('filterCountry')}
                    design='standard' />
                </Box>
              </Flex>
            </div>
            <Outline mode='heading'>Current Security List</Outline>
            <Loading type='table'
              data={details.data}
              error={details.error}
              reloading={details.reloading}
              loaded={details.loaded}>
              <Table
                columns={columns}
                loaded={details.loaded}
                data={details.data.records}
                sortColumn={details.sortOn}
                sortDirection={details.sortDir}
                onSorting={sort(this, getDetails, 'details')}
                showHeader />
              <TableFooter>
                <More
                  onClick={expand(this, getDetails, 'details')}
                  disabled={details.expandEmpty} />
                {details.data.records && details.data.totalRecords &&
                  <DisplayResults show={details.data.records.length} total={details.data.totalRecords} text={'Securities'} />
                }
              </TableFooter>
            </Loading>
          </div>
        </Flexgrid>
      </div>
    )
  }
}

DBrokerDealerDetailPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      mpid: PropTypes.string
    })
  })
}

export default withRouter(DBrokerDealerDetailPage)
