import React from 'react'

// Based on internal Slate HTML serializer:
// https://github.com/ianstormtaylor/slate/blob/master/packages/slate-html-serializer/src/index.js

export class RichContentRenderer {
  constructor (options = {}) {
    this.rules = options.rules
  }

  render = (content) => {
    return content.document.nodes.map(this.serializeNode)
  }

  serializeNode = (node) => {
    if (node.kind === 'text') {
      return node.ranges && node.ranges.map(this.serializeLeaf)
    }
    const children = node.nodes && node.nodes.map(this.serializeNode)
    return this.serializeObject(node, children)
  }

  serializeLeaf = (leaf) => {
    const text = this.serializeString(leaf.text)
    return leaf.marks && leaf.marks.reduce((children, mark) => {
      return this.serializeObject(mark, children)
    }, text)
  }

  serializeObject = (object, children) => {
    for (const rule of this.rules) {
      if (!rule.serialize) continue
      const el = rule.serialize(object, children)
      if (el) return addKey(el)
    }
    return null
  }

  serializeString = (string) => {
    if (!string) return ''
    return string.split('\n').reduce((array, text, i) => {
      if (i !== 0) array.push(<br />)
      array.push(text)
      return array
    }, [])
  }
}

let key = 0
const addKey = (element) => {
  return React.cloneElement(element, { key: key++ })
}

export default RichContentRenderer
