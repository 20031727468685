import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'grid-styled'
import { stateText, countryText, yearText } from '../../../utils/text'
import styles from './styles.module.scss'

const CompanyQuoteIncorporationInfo = ({ list }) => {
  const array = [...list]
  array.shift()
  return <div className={styles.item}>
    <Flex w={1} justify='flex-end' align='center'>
      <Box w={1 / 2}>
        {array && array.map((item, index) => <p key={index} className={styles.field}>
          {stateText(item) + countryText(item) + yearText(item)}
        </p>)}
      </Box>
    </Flex>
  </div>
}

CompanyQuoteIncorporationInfo.propTypes = {
  list: PropTypes.array
}

export default CompanyQuoteIncorporationInfo
