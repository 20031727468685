export function request () {
  return {
    totalRecords: 6992,
    pages: 700,
    currentPage: 1,
    pageSize: 10,
    sortOn: 'symbol',
    sortDir: 'ASC',
    records: [
      {
        positionDate: 1504137600000,
        securityId: 194057,
        securityName: 'Alternative Investment Trust U',
        symbol: 'AAAIF',
        venue: 'Other OTC',
        shortInterest: 4050,
        prevVolume: 4050,
        diffVolume: 0,
        pctChgVolume: 0,
        avgDailyVolume: 0,
        daysToCover: 999.99,
        isSplit: false,
        isNewIssue: false
      },
      {
        positionDate: 1504137600000,
        securityId: 94615,
        securityName: 'Aareal Bank AG AKT',
        symbol: 'AAALF',
        venue: 'Other OTC',
        shortInterest: 5935,
        prevVolume: 2537,
        diffVolume: 3398,
        pctChgVolume: 133.94,
        avgDailyVolume: 0,
        daysToCover: 999.99,
        isSplit: false,
        isNewIssue: false
      },
      {
        positionDate: 1504137600000,
        securityId: 189854,
        securityName: 'Aareal Bank AG Unsponsored Ame',
        symbol: 'AAALY',
        venue: 'Other OTC',
        shortInterest: 9,
        prevVolume: 9,
        diffVolume: 0,
        pctChgVolume: 0,
        avgDailyVolume: 0,
        daysToCover: 999.99,
        isSplit: false,
        isNewIssue: false
      },
      {
        positionDate: 1504137600000,
        securityId: 90426,
        securityName: 'Aberdeen International Inc Ord',
        symbol: 'AABVF',
        venue: 'Other OTC',
        shortInterest: 500,
        prevVolume: 500,
        diffVolume: 0,
        pctChgVolume: 0,
        avgDailyVolume: 30970,
        daysToCover: 1,
        isSplit: false,
        isNewIssue: false
      },
      {
        positionDate: 1504137600000,
        securityId: 204112,
        securityName: 'AAC Technologies Holdings Inc',
        symbol: 'AACAF',
        venue: 'Other OTC',
        shortInterest: 2400568,
        prevVolume: 3320797,
        diffVolume: -920229,
        pctChgVolume: -27.71,
        avgDailyVolume: 83,
        daysToCover: 999.99,
        isSplit: false,
        isNewIssue: false
      },
      {
        positionDate: 1504137600000,
        securityId: 125182,
        securityName: 'AAC Technologies Holdings Inc',
        symbol: 'AACAY',
        venue: 'Other OTC',
        shortInterest: 630,
        prevVolume: 2336,
        diffVolume: -1706,
        pctChgVolume: -73.03,
        avgDailyVolume: 23294,
        daysToCover: 1,
        isSplit: false,
        isNewIssue: false
      },
      {
        positionDate: 1504137600000,
        securityId: 126201,
        securityName: 'Asia Cement (China) Holdings C',
        symbol: 'AACEY',
        venue: 'Other OTC',
        shortInterest: 300,
        prevVolume: 300,
        diffVolume: 0,
        pctChgVolume: 0,
        avgDailyVolume: 0,
        daysToCover: 999.99,
        isSplit: false,
        isNewIssue: false
      },
      {
        positionDate: 1504137600000,
        securityId: 224681,
        securityName: 'AURORA SOLAR TECHNOLOGIES INC',
        symbol: 'AACTF',
        venue: 'Other OTC',
        shortInterest: 250,
        prevVolume: 2750,
        diffVolume: -2500,
        pctChgVolume: -90.91,
        avgDailyVolume: 23762,
        daysToCover: 1,
        isSplit: false,
        isNewIssue: false
      },
      {
        positionDate: 1504137600000,
        securityId: 231159,
        securityName: 'All American Energy Holding, I',
        symbol: 'AAEH',
        venue: 'Other OTC',
        shortInterest: 2447,
        prevVolume: 0,
        diffVolume: 2447,
        pctChgVolume: 100,
        avgDailyVolume: 1453794,
        daysToCover: 1,
        isSplit: false,
        isNewIssue: false
      },
      {
        positionDate: 1504137600000,
        securityId: 132381,
        securityName: 'AIA Group, Ltd. Sponsored Amer',
        symbol: 'AAGIY',
        venue: 'Other OTC',
        shortInterest: 57769,
        prevVolume: 65857,
        diffVolume: -8088,
        pctChgVolume: -12.28,
        avgDailyVolume: 225095,
        daysToCover: 1,
        isSplit: false,
        isNewIssue: false
      }
    ],
    singular: 'Short Position',
    plural: 'Short Positions'
  }
}
