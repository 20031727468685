import React from 'react'
import PropTypes from 'prop-types'
import GA from 'react-ga4'
import getConfig from './config'
const { REACT_GA_ID } = getConfig()

export const initAnalytics = () => {
  if (REACT_GA_ID) GA.initialize(REACT_GA_ID)
}

export const withTracker = (Component) => {
  const trackPage = (path) => {
    if (!path || !REACT_GA_ID) return
    if (path === '/') pageview(path)
  }

  const Tracker = (props) => {
    const path = props.location && props.location.pathname
    trackPage(path)
    return <Component {...props} />
  }

  Tracker.propTypes = {
    location: PropTypes.object.isRequired
  }

  return Tracker
}

let lastHit = 0
const pageview = (path, title) => {
  if (!REACT_GA_ID) return
  const msSinceLastHit = new Date().getTime() - lastHit
  if (msSinceLastHit > 1000 && path && path.length > 0) {
    GA.send({ hitType: 'pageview', page: path, title: title })
    lastHit = new Date().getTime()
  }
}

export const logPageview = (title) => {
  if (!REACT_GA_ID) return
  const path = window.location.pathname
  pageview(path, title)
}

export const logEvent = (category, action, label, value) => {
  GA.event({
    category,
    action,
    label,
    value
  })
}

export default withTracker
