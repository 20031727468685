export function request (params, pagination) {
  // the results are all the same except for the first item in each list since
  // the API endpoint returns mocked data
  switch (params.symbol) {
    case 'GOOG':
      return {
        symbol: 'ADDYY',
        montagemontageBuyList: [
          {
            transTime: 1509127268389,
            mmId: 'JANE',
            price: 96.06,
            size: 1100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFCM',
            price: 96.06,
            size: 300
          },
          {
            transTime: 1509127268389,
            mmId: 'NITE',
            price: 96.05,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CDEL',
            price: 96.04,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'MCAP',
            price: 95.99,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CSTI',
            price: 95.95,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'ETRF',
            price: 95.85,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'PUMA',
            price: 95.8,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'INTL',
            price: 95.47,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CANT',
            price: 94,
            size: 385
          },
          {
            transTime: 1509127268389,
            mmId: 'VNDM',
            price: 93.2,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BARD',
            price: 93.1,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'MAXM',
            price: 86.15,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFIN',
            price: 83.84,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'WABR',
            price: 75,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BGCE',
            price: 54.93,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VERT',
            price: 15,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'STFL',
            price: 0,
            size: 0
          }
        ],
        montageSellList: [
          {
            transTime: 1509127268389,
            mmId: 'JANE',
            price: 96.15,
            size: 1100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFCM',
            price: 96.15,
            size: 1300
          },
          {
            transTime: 1509127268389,
            mmId: 'MCAP',
            price: 96.24,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'NITE',
            price: 96.24,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CSTI',
            price: 96.3,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'ETRF',
            price: 96.45,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'INTL',
            price: 96.48,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CANT',
            price: 96.7,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VNDM',
            price: 97.65,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CDEL',
            price: 98.3,
            size: 154
          },
          {
            transTime: 1509127268389,
            mmId: 'PUMA',
            price: 99,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BARD',
            price: 99.2,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFIN',
            price: 104.65,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'MAXM',
            price: 108.14,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BGCE',
            price: 168.51,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VERT',
            price: 200,
            size: 1
          },
          {
            transTime: 1509127268389,
            mmId: 'WABR',
            price: 200,
            size: 1
          },
          {
            transTime: 1509127268389,
            mmId: 'STFL',
            price: 0,
            size: 0
          }
        ]
      }

    case 'OTCM':
      return {
        symbol: 'ADDYY',
        montageBuyList: [
          {
            transTime: 1509127268389,
            mmId: 'JANE',
            price: 96.06,
            size: 2100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFCM',
            price: 96.06,
            size: 300
          },
          {
            transTime: 1509127268389,
            mmId: 'NITE',
            price: 96.05,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CDEL',
            price: 96.04,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'MCAP',
            price: 95.99,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CSTI',
            price: 95.95,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'ETRF',
            price: 95.85,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'PUMA',
            price: 95.8,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'INTL',
            price: 95.47,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CANT',
            price: 94,
            size: 385
          },
          {
            transTime: 1509127268389,
            mmId: 'VNDM',
            price: 93.2,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BARD',
            price: 93.1,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'MAXM',
            price: 86.15,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFIN',
            price: 83.84,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'WABR',
            price: 75,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BGCE',
            price: 54.93,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VERT',
            price: 15,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'STFL',
            price: 0,
            size: 0
          }
        ],
        montageSellList: [
          {
            transTime: 1509127268389,
            mmId: 'JANE',
            price: 96.15,
            size: 2100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFCM',
            price: 96.15,
            size: 1300
          },
          {
            transTime: 1509127268389,
            mmId: 'MCAP',
            price: 96.24,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'NITE',
            price: 96.24,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CSTI',
            price: 96.3,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'ETRF',
            price: 96.45,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'INTL',
            price: 96.48,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CANT',
            price: 96.7,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VNDM',
            price: 97.65,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CDEL',
            price: 98.3,
            size: 154
          },
          {
            transTime: 1509127268389,
            mmId: 'PUMA',
            price: 99,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BARD',
            price: 99.2,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFIN',
            price: 104.65,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'MAXM',
            price: 108.14,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BGCE',
            price: 168.51,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VERT',
            price: 200,
            size: 1
          },
          {
            transTime: 1509127268389,
            mmId: 'WABR',
            price: 200,
            size: 1
          },
          {
            transTime: 1509127268389,
            mmId: 'STFL',
            price: 0,
            size: 0
          }
        ]
      }

    case 'VLKAY':
      return {
        symbol: 'ADDYY',
        montageBuyList: [
          {
            transTime: 1509127268389,
            mmId: 'JANE',
            price: 96.06,
            size: 3100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFCM',
            price: 96.06,
            size: 300
          },
          {
            transTime: 1509127268389,
            mmId: 'NITE',
            price: 96.05,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CDEL',
            price: 96.04,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'MCAP',
            price: 95.99,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CSTI',
            price: 95.95,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'ETRF',
            price: 95.85,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'PUMA',
            price: 95.8,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'INTL',
            price: 95.47,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CANT',
            price: 94,
            size: 385
          },
          {
            transTime: 1509127268389,
            mmId: 'VNDM',
            price: 93.2,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BARD',
            price: 93.1,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'MAXM',
            price: 86.15,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFIN',
            price: 83.84,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'WABR',
            price: 75,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BGCE',
            price: 54.93,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VERT',
            price: 15,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'STFL',
            price: 0,
            size: 0
          }
        ],
        montageSellList: [
          {
            transTime: 1509127268389,
            mmId: 'JANE',
            price: 96.15,
            size: 3100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFCM',
            price: 96.15,
            size: 1300
          },
          {
            transTime: 1509127268389,
            mmId: 'MCAP',
            price: 96.24,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'NITE',
            price: 96.24,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CSTI',
            price: 96.3,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'ETRF',
            price: 96.45,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'INTL',
            price: 96.48,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CANT',
            price: 96.7,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VNDM',
            price: 97.65,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'CDEL',
            price: 98.3,
            size: 154
          },
          {
            transTime: 1509127268389,
            mmId: 'PUMA',
            price: 99,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BARD',
            price: 99.2,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VFIN',
            price: 104.65,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'MAXM',
            price: 108.14,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'BGCE',
            price: 168.51,
            size: 100
          },
          {
            transTime: 1509127268389,
            mmId: 'VERT',
            price: 200,
            size: 1
          },
          {
            transTime: 1509127268389,
            mmId: 'WABR',
            price: 200,
            size: 1
          },
          {
            transTime: 1509127268389,
            mmId: 'STFL',
            price: 0,
            size: 0
          }
        ]
      }
  }
}
