import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getList (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    url: '/directory/broker-dealer',
    params
  })
  return response.data
}

export async function downloadBrokerDealers () {
  let jwtToken
  const url = '/otcapi/download/internal/directory/broker-dealer/download'

  await getToken()
    .then(res => {
      jwtToken = res
    })
    .catch(e => console.log('no session', e))

  window.open(`${REACT_APP_API_BASE_URI}${url}?file=broker-dealer&token=${jwtToken}`)
}

export async function getDetails (params) {
  if (params.mpid) params.mpid = params.mpid.toUpperCase()
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    url: `/directory/broker-dealer/details/${params.mpid}`,
    params
  })
  return response.data
}

export async function getFilters () {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    url: '/directory/broker-dealer/filters'
  })
  return response.data
}
