import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const CustomContentItem = ({ content }) =>
  <div className={styles.item}>
    {content}
  </div>

CustomContentItem.propTypes = {
  content: PropTypes.node
}

export default CustomContentItem
