import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './index.module.scss'
import Outline from '../Outline'

class AccordionItem extends Component {
  static propTypes = {
    title: PropTypes.string,
    accent: PropTypes.string,
    children: PropTypes.node,
    atomic: PropTypes.bool,
    loaded: PropTypes.bool
  }

  static defaultProps = {
    title: 'TITLE'
  }

  constructor (props) {
    super(props)
    this.state = {
      isOpen: true,
      panelHeight: null
    }
    this.mounted = true
    this.getHeight = this.getHeight.bind(this)
  }

  handleDocumentClick = event => {
    if (
      this.mounted &&
      !ReactDOM.findDOMNode(this).contains(event.target) &&
      this.state.isOpen
    ) {
      this.setState({ isOpen: false })
    }
  }

  componentDidMount () {
    if (this.props.atomic) {
      document.addEventListener('click', this.handleDocumentClick, false)
      document.addEventListener('touchend', this.handleDocumentClick, false)
    }

    if (this.props.loaded) {
      this.getHeight()
    }

    window.addEventListener('resize', this.getHeight, false)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.loaded !== this.props.loaded && this.props.loaded === true) {
      this.getHeight()
    }
  }

  componentWillUnmount () {
    this.mounted = false
    document.removeEventListener('click', this.handleDocumentClick)
    document.removeEventListener('touchend', this.handleDocumentClick)
    window.removeEventListener('resize', this.getHeight)
  }

  getHeight () {
    const elementHeight = document.getElementById(this.props.title.replace(/\s+/g, '-').toLowerCase()).clientHeight
    this.setState({ panelHeight: elementHeight })
  }

  onClick = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    const accordionItemClassNames = classNames([
      styles.accordionItem,
      {
        [styles.active]: this.state.isOpen
      }
    ])

    const panelStyle = {
      maxHeight: this.state.isOpen ? this.state.panelHeight : 0
    }
    return (
      <div className={accordionItemClassNames}>
        <button className={styles.title} onClick={this.onClick}>
          <Outline mode='accordion' isOpen={this.state.isOpen} accent={this.props.accent}>{this.props.title}</Outline>
        </button>
        <div id={this.props.title.replace(/\s+/g, '-').toLowerCase()} className={styles.panel} style={panelStyle}>{this.props.children}</div>
      </div>
    )
  }
}

export default AccordionItem
