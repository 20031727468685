import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getBlueSkySummary (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: '/bluesky/daily/stats',
    params
  })
  return response.data
}

export async function getBlueSkyData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: '/bluesky/daily/results',
    params
  })
  return response.data
}

export async function getHistoricalBlueSkySummary (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: '/bluesky/historical/stats',
    params
  })
  return response.data
}

export async function getHistoricalBlueSkyData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: '/bluesky/historical/results',
    params
  })
  return response.data
}

export async function downloadBlueSkyData (params) {
  let jwtToken
  const url = `/bluesky/download/${params.date || ''}`
  let downloadPath = '/bluesky/daily/results'

  if (params.date) {
    downloadPath = '/bluesky/historical/results'
  }
  await getToken()
    .then(res => {
      jwtToken = res
    })
    .catch(e => console.log('no session', e))

  window.open(`${REACT_APP_API_BASE_URI}${url}?download=${downloadPath}&page=1&pageSize=60&symbol=${params.symbol}&file=Blue Sky${params.date ? ` Historical-${params.date}` : ''}&token=${jwtToken}`)
}

export async function getUserManual (params) {
  const response = await api.request({
    method: 'get',
    url: `/blue-sky-user-manual/${params.state}`
  })

  return response.data
}
