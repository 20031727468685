import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withRouter } from '../WithRouter'
import styles from './BackLink.module.scss'
import { FaLongArrowAltLeft } from 'react-icons/fa'

const BackLink = ({ navigate, children, ignoreHistory, to }) => {
  const goBack = () => {
    navigate(-1)
  }

  return (!ignoreHistory && navigate)
    ? <div
      onClick={goBack}
      className={styles.link}>
      <FaLongArrowAltLeft />
      {children || 'Back'}
    </div>
    : <Link
        to={to}
        className={styles.link}>
        <FaLongArrowAltLeft />
        {children || 'Back'}
    </Link>
}

BackLink.propTypes = {
  to: PropTypes.string,
  ignoreHistory: PropTypes.bool,
  navigate: PropTypes.func,
  children: PropTypes.node
}

export default withRouter(BackLink)
