export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return {
        totalRecords: 0,
        pages: 0,
        currentPage: 2,
        pageSize: 10,
        singular: 'Short Position',
        plural: 'Short Positions'
      }

    case 'OTCM':
      return {
        totalRecords: 144,
        pages: 15,
        currentPage: 2,
        pageSize: 10,
        sortOn: 'positionDate',
        sortDir: 'DESC',
        records: [
          {
            positionDate: 1496203200000,
            securityId: 128054,
            securityName: 'OTC Markets Group Inc. Common',
            symbol: 'OTCM',
            venue: 'Other OTC',
            tierCode: 'QX',
            shortInterest: 17,
            prevVolume: 62,
            diffVolume: -45,
            pctChgVolume: -72.58,
            avgDailyVolume: 6815,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1494820800000,
            securityId: 128054,
            securityName: 'OTC Markets Group Inc. Common',
            symbol: 'OTCM',
            venue: 'Other OTC',
            tierCode: 'QX',
            shortInterest: 62,
            prevVolume: 3071,
            diffVolume: -3009,
            pctChgVolume: -97.98,
            avgDailyVolume: 4755,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1493352000000,
            securityId: 128054,
            securityName: 'OTC Markets Group Inc. Common',
            symbol: 'OTCM',
            venue: 'Other OTC',
            tierCode: 'QX',
            shortInterest: 3071,
            prevVolume: 242,
            diffVolume: 2829,
            pctChgVolume: 1169.01,
            avgDailyVolume: 4978,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1492056000000,
            securityId: 128054,
            securityName: 'OTC Markets Group Inc. Common',
            symbol: 'OTCM',
            venue: 'Other OTC',
            tierCode: 'QX',
            shortInterest: 242,
            prevVolume: 3297,
            diffVolume: -3055,
            pctChgVolume: -92.66,
            avgDailyVolume: 5408,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1490932800000,
            securityId: 128054,
            securityName: 'OTC Markets Group Inc. Common',
            symbol: 'OTCM',
            venue: 'Other OTC',
            tierCode: 'QX',
            shortInterest: 3297,
            prevVolume: 0,
            diffVolume: 3297,
            pctChgVolume: 100,
            avgDailyVolume: 5060,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1488258000000,
            securityId: 128054,
            securityName: 'OTC Markets Group Inc. Common',
            symbol: 'OTCM',
            venue: 'Other OTC',
            tierCode: 'QX',
            shortInterest: 385,
            prevVolume: 0,
            diffVolume: 385,
            pctChgVolume: 100,
            avgDailyVolume: 3357,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1487134800000,
            securityId: 128054,
            securityName: 'OTC Markets Group Inc. Common',
            symbol: 'OTCM',
            venue: 'Other OTC',
            tierCode: 'QX',
            shortInterest: 0,
            prevVolume: 14471,
            diffVolume: -14471,
            pctChgVolume: -100,
            avgDailyVolume: 8924,
            daysToCover: 0,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1485838800000,
            securityId: 128054,
            securityName: 'OTC Markets Group Inc. Common',
            symbol: 'OTCM',
            venue: 'Other OTC',
            tierCode: 'QX',
            shortInterest: 14471,
            prevVolume: 316,
            diffVolume: 14155,
            pctChgVolume: 4479.43,
            avgDailyVolume: 11496,
            daysToCover: 1.26,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1484283600000,
            securityId: 128054,
            securityName: 'OTC Markets Group Inc. Common',
            symbol: 'OTCM',
            venue: 'Other OTC',
            tierCode: 'QX',
            shortInterest: 316,
            prevVolume: 62,
            diffVolume: 254,
            pctChgVolume: 409.68,
            avgDailyVolume: 1801,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1483074000000,
            securityId: 128054,
            securityName: 'OTC Markets Group Inc. Common',
            symbol: 'OTCM',
            venue: 'Other OTC',
            tierCode: 'QX',
            shortInterest: 62,
            prevVolume: 0,
            diffVolume: 62,
            pctChgVolume: 100,
            avgDailyVolume: 14571,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          }
        ],
        singular: 'Short Position',
        plural: 'Short Positions'
      }

    case 'VLKAY':
      return {
        totalRecords: 260,
        pages: 26,
        currentPage: 2,
        pageSize: 10,
        sortOn: 'positionDate',
        sortDir: 'DESC',
        records: [
          {
            positionDate: 1500004800000,
            securityId: 35275,
            securityName: 'Volkswagen A G Ord American De',
            symbol: 'VLKAY',
            venue: 'Other OTC',
            tierCode: 'PC',
            shortInterest: 25595,
            prevVolume: 21098,
            diffVolume: 4497,
            pctChgVolume: 21.31,
            avgDailyVolume: 319040,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1498795200000,
            securityId: 35275,
            securityName: 'Volkswagen A G Ord American De',
            symbol: 'VLKAY',
            venue: 'Other OTC',
            tierCode: 'PC',
            shortInterest: 21098,
            prevVolume: 56338,
            diffVolume: -35240,
            pctChgVolume: -62.55,
            avgDailyVolume: 391358,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1497499200000,
            securityId: 35275,
            securityName: 'Volkswagen A G Ord American De',
            symbol: 'VLKAY',
            venue: 'Other OTC',
            tierCode: 'PC',
            shortInterest: 56338,
            prevVolume: 195497,
            diffVolume: -139159,
            pctChgVolume: -71.18,
            avgDailyVolume: 257951,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1496203200000,
            securityId: 35275,
            securityName: 'Volkswagen A G Ord American De',
            symbol: 'VLKAY',
            venue: 'Other OTC',
            tierCode: 'PC',
            shortInterest: 195497,
            prevVolume: 63223,
            diffVolume: 132274,
            pctChgVolume: 209.22,
            avgDailyVolume: 330981,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1494820800000,
            securityId: 35275,
            securityName: 'Volkswagen A G Ord American De',
            symbol: 'VLKAY',
            venue: 'Other OTC',
            tierCode: 'PC',
            shortInterest: 63223,
            prevVolume: 45399,
            diffVolume: 17824,
            pctChgVolume: 39.26,
            avgDailyVolume: 305646,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1493352000000,
            securityId: 35275,
            securityName: 'Volkswagen A G Ord American De',
            symbol: 'VLKAY',
            venue: 'Other OTC',
            tierCode: 'PC',
            shortInterest: 45399,
            prevVolume: 265432,
            diffVolume: -220033,
            pctChgVolume: -82.9,
            avgDailyVolume: 616473,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1492056000000,
            securityId: 35275,
            securityName: 'Volkswagen A G Ord American De',
            symbol: 'VLKAY',
            venue: 'Other OTC',
            tierCode: 'PC',
            shortInterest: 265432,
            prevVolume: 112459,
            diffVolume: 152973,
            pctChgVolume: 136.03,
            avgDailyVolume: 675167,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1490932800000,
            securityId: 35275,
            securityName: 'Volkswagen A G Ord American De',
            symbol: 'VLKAY',
            venue: 'Other OTC',
            tierCode: 'PC',
            shortInterest: 112459,
            prevVolume: 56470,
            diffVolume: 55989,
            pctChgVolume: 99.15,
            avgDailyVolume: 315831,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1489550400000,
            securityId: 35275,
            securityName: 'Volkswagen A G Ord American De',
            symbol: 'VLKAY',
            venue: 'Other OTC',
            tierCode: 'PC',
            shortInterest: 56470,
            prevVolume: 91747,
            diffVolume: -35277,
            pctChgVolume: -38.45,
            avgDailyVolume: 236574,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          },
          {
            positionDate: 1488258000000,
            securityId: 35275,
            securityName: 'Volkswagen A G Ord American De',
            symbol: 'VLKAY',
            venue: 'Other OTC',
            tierCode: 'PC',
            shortInterest: 91747,
            prevVolume: 38857,
            diffVolume: 52890,
            pctChgVolume: 136.11,
            avgDailyVolume: 383715,
            daysToCover: 1,
            isSplit: false,
            isNewIssue: false,
            isCaveatEmptor: false
          }
        ],
        singular: 'Short Position',
        plural: 'Short Positions'
      }
  }
}
