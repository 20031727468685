const trim = text => {
  return text && text.toLowerCase().trim()
}

export const compareAddress = (addrObj1, addrObj2) => {
  if (trim(addrObj1.address1) !== trim(addrObj2.addr1)) return false
  if (trim(addrObj1.address2) !== trim(addrObj2.addr2)) return false
  if (trim(addrObj1.city) !== trim(addrObj2.city)) return false
  if (trim(addrObj1.state) !== trim(addrObj2.state)) return false
  if (trim(addrObj1.zip) !== trim(addrObj2.zip)) return false
  if (trim(addrObj1.country) !== trim(addrObj2.country)) return false
  return true
}
