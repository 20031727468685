import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './More.module.scss'
import { Link } from 'react-router-dom'

import { FaChevronRight, FaChevronDown } from 'react-icons/fa'

const More = ({ disabled, onClick, to }) => {
  const classes = classNames(styles.more, { [styles.disabled]: disabled })
  if (to) {
    return <Link to={disabled ? '#' : to} className={classes}>
      More <FaChevronRight className={styles.iconRight} />
    </Link>
  }
  return <div className={classes} onClick={disabled ? null : onClick}>
    More <FaChevronDown className={styles.iconDown} />
  </div>
}

More.propTypes = {
  /** The route to navigate to */
  to: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default More
