import React from 'react'
import PropTypes from 'prop-types'
import styles from './MarketActivityOverview.module.scss'
import Select from '../Select'
import Locale from '../Locale'
import Flexgrid from '../Flexgrid'
import { checkNegative } from '../../utils/locale'
import moment from 'moment-timezone'

/** The Market options to query on */
const markets = [
  {
    title: 'All Markets',
    value: 'ALL'
  },
  {
    title: 'OTCQX',
    value: 'QX'
  },
  {
    title: 'OTCQB',
    value: 'DQ'
  },
  {
    title: 'Pink',
    value: 'PS'
  },
  {
    title: 'Grey',
    value: 'OO'
  }
]

const MarketActivityOverview = props =>
  <div className={styles.MarketActivityOverview}>
    <Select
      required
      design='standard'
      className={styles.select}
      options={markets}
      defaultValue={(props.selectedMarket && props.selectedMarket.toUpperCase()) || 'ALL'}
      onSelect={props.changeMarket}
      key='market' />
    <Flexgrid className={styles.marketInfo} layouts={props.layouts}>
      {props.info &&
        props.info.length &&
        props.info.map((record, i) => (
          <div className={styles.marketItem} key={record.key}>
            <div>{record.title}</div>
            <p>{checkNegative(record.value) === 'N/A' ? 'N/A' : <Locale type='int'>{record.value}</Locale>}</p>
          </div>
        ))
      }
    </Flexgrid>
    {props.date && <abbr>{moment(props.date).tz('America/New_York').format(props.hideTime ? 'MM/DD/YYYY' : 'MM/DD/YYYY hh:mm a')}</abbr>}
  </div>

MarketActivityOverview.propTypes = {
  /** Select Market value */
  selectedMarket: PropTypes.string,
  /** Change Market function */
  changeMarket: PropTypes.func.isRequired,
  /** Selected market data */
  info: PropTypes.arrayOf(PropTypes.shape({
    /** Key of the item */
    key: PropTypes.string.isRequired,
    /** Title of the item */
    title: PropTypes.string.isRequired,
    /** Value of the item */
    value: PropTypes.number.isRequired
  })),
  /** Layout to pass to Flexgrid */
  layouts: PropTypes.object.isRequired,
  /** Date & time the market data was received */
  date: PropTypes.number,
  hideTime: PropTypes.bool
}

export default MarketActivityOverview
