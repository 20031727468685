import axios from 'axios'

// CampaignMonitor email subscription
export async function subscribe (email) {
  try {
    // If email is empty, throw invalid email error
    if (!email || email.trim() === '') throw Error('invalidEmail')
    // Attempt to subscribe
    const response = await axios({
      method: 'post',
      url: 'https://otcmarketsgroup1.createsend.com/t/i/s/dulidi',
      data: 'cm-dulidi-dulidi=' + encodeURIComponent(email),
      headers: { 'Content-type': 'application/x-www-form-urlencoded' }
    })
    // CampaignMonitor responded with invalid email message
    if (response.data && response.data.indexOf('Invalid email') > -1) {
      throw Error('invalidEmail')
    }
  } catch (e) {
    // An unhandled error ocurred
    if (e.response) {
      throw Error('Unable to subscribe. Please try again.')
    } else if (e.message === 'invalidEmail') {
      // Throw custom error for CampaignMonitor invalid email message
      throw Error('Please use a valid email address.')
    } else {
      // CampaignMonitor responded with a 302 empty response in case of success
      return true
    }
  }
}
