import api from '../../api'
import { getParameterByName } from '../../helper'

export async function getSECFilings (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    mockData: 'company/filings/sec-filings',
    url: `/company/sec-filings/${params.symbol}`,
    params
  })

  await setGuid(response)

  return response.data
}

export async function getSECFilingsByTier (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    mockData: 'company/filings/sec-filings-by-tier',
    url: '/company/sec-filings',
    params
  })

  await setGuid(response)

  return response.data
}

async function setGuid (response) {
  /*
    Current solve for sesson id expirations. Will be done properly via the backend -> OTCM-429
  */
  const currentTime = new Date().getTime()

  if (response && response.data && response.data.records && response.data.records.length > 1) {
    if ((window.sessionStorage && window.sessionStorage.guid && currentTime > JSON.parse(window.sessionStorage.guid).expire) || !window.sessionStorage.guid) {
      const setGuid = await api.request({
        method: 'get',
        otcAPI: true,
        mockData: 'company/sec-filings-url',
        url: `/company/sec-filings-url/${response.data.records[0].id}`,
        params: {
          format: 'html'
        }
      })

      const storageObj = {
        expire: new Date().getTime() + 5 * 60000,
        id: getParameterByName('SessionID', setGuid.data)
      }

      window.sessionStorage.setItem('guid', JSON.stringify(storageObj))
    }
  }
}
