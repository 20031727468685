export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return {
        id: 655940,
        name: 'Alphabet Inc.',
        city: 'Mountain View',
        state: 'CA',
        zip: '94043     ',
        countryId: 'USA',
        country: 'United States',
        address1: '1600 Amphitheatre Parkway',
        address2: '',
        address3: '',
        execAddr: {
          addr1: '300 Vesey Street',
          addr2: '12th Floor',
          city: 'New York',
          state: 'NY',
          zip: '10013',
          country: 'United States'
        },
        website: 'http://www.google.com',
        phone: '(650) 253-0000',
        fax: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        businessDesc: '',
        productServicesDescription: '',
        facilitiesDescription: '',
        stateOfIncorporation: 'DE ',
        stateOfIncorporationName: 'Delaware',
        countryOfIncorporation: 'USA',
        countryOfIncorporationName: 'United States',
        premierDirectorList: [],
        standardDirectorList: [],
        officers: [
          {
            name: 'Eric  Schmidt',
            title: 'CEO'
          },
          {
            name: 'George  Reyes',
            title: 'CFO'
          }
        ],
        fiscalYearEnd: '12/31',
        filingCycle: 'Q',
        edgarFilingStatus: 'SEC Reporting',
        edgarFilingStatusId: 'F',
        reportingStandard: 'U.S. Reporting: SEC Reporting',
        isDark: false,
        deregistered: false,
        is12g32b: false,
        cik: '0001288776',
        isAlternativeReporting: false,
        isBankThrift: false,
        isNonBankRegulated: false,
        isInternationalReporting: false,
        isOtherReporting: false,
        auditedStatusDisplay: 'Not Available',
        auditStatus: 'N',
        audited: false,
        email: '',
        primarySicCode: '7370 - Services-Computer Programming, Data Processing, Etc.',
        auditors: [],
        investorRelationFirms: [],
        legalCounsels: [],
        investmentBanks: [],
        notes: [
          'Formerly=Google, Inc. until 10-2015'
        ],
        securities: [
          {
            id: 234098,
            symbol: 'GOOG',
            cusip: '02079K107',
            compId: 655940,
            issueName: 'ALPHABET INC C',
            className: 'Class C Common Stock',
            primaryVenue: 'Nasdaq',
            categoryId: 0,
            categoryName: 'No Category',
            isBB: false,
            isPinkSheets: false,
            typeCode: 'CS',
            typeName: 'Common Stock',
            ratioAdr: 1,
            isAdr: false,
            isGdr: false,
            isTest: false,
            isOtcQX: false,
            isNoInfo: false,
            isSponsored: false,
            isPiggyBacked: true,
            isQuoteElig: true,
            isCaveatEmptor: false,
            isLevel2Entitled: false,
            hasLevel2: false,
            canAccessBB: false,
            noPar: false,
            outstandingShares: 341600659,
            outstandingSharesAsOfDate: 1430107200000,
            unlimitedAuthorizedShares: false,
            isExchangeQualified: false,
            sigFailDeliver: false,
            showTrustedLogoForAuthorizedShares: false,
            showTrustedLogoForOutstandingShares: false,
            showTrustedLogoForRestrictedShares: false,
            showTrustedLogoForUnrestrictedShares: false,
            showTrustedLogoForDTCShares: false
          }
        ],
        otherSecurities: [
          {
            symbol: 'GOOGL',
            name: 'ALPHABET INC A'
          }
        ],
        estimatedMarketCap: 332240800943.4,
        estimatedMarketCapAsOfDate: 1507608000000,
        blankCheck: false,
        blindPool: false,
        spac: false,
        hasLatestFiling: true,
        latestFilingDate: 1506744000000,
        latestFilingUrl: '/company/sec-filings/12342644/content/pdf',
        hasLogo: false,
        isCaveatEmptor: false,
        otcAward: {
          symbol: 'GOOG',
          best50: false,
          tenYear: false
        },
        indexStatuses: [],
        profileBadges: {
          isShell: false,
          hasControlDispute: false,
          isPennyStockExempt: false,
          isBankrupt: false,
          unableToContact: false,
          transferAgentVerified: false,
          isCaveatEmptor: false,
          isDark: false,
          isDelinquent: false,
          verifiedProfile: false
        },
        unableToContact: false,
        isShell: false,
        isBankrupt: false,
        isProfileVerified: false,
        venue: 'Nasdaq'
      }

    case 'OTCM':
      return {
        id: 674812,
        name: 'OTC Markets Group Inc.',
        city: 'New York',
        state: 'NY',
        zip: '10013',
        countryId: 'USA',
        country: 'United States',
        address1: '304 Hudson Street',
        address2: '3rd Floor',
        execAddr: {
          addr1: '300 Vesey Street',
          addr2: '12th Floor',
          city: 'New York',
          state: 'NY',
          zip: '10013',
          country: 'United States'
        },
        website: 'http://www.otcmarkets.com',
        phone: '212-896-4400',
        facebook: 'http://www.facebook.com/OTCMarkets',
        twitter: 'http://www.twitter.com/OTCMarkets',
        linkedin: 'http://www.linkedin.com/company/1325454?trk=pro_other_cmpy',
        businessDesc: 'OTC Markets Group Inc. (OTCQX: OTCM) operates the OTCQX Best Market, the OTCQB Venture Market, and the Pink Open Market for 10,000 U.S. and global securities. Through OTC Link ATS, we connect a diverse network of broker-dealers that provide liquidity and execution services. We enable investors to easily trade through the broker of their choice and empower companies to improve the quality of information available for investors. To learn more about how we create better informed and more efficient markets, visit www.otcmarkets.com. OTC Link ATS is operated by OTC Link LLC, member FINRA/SIPC and SEC regulated ATS.',
        productServicesDescription: 'Testing product services description text for OTC Markets Group profile page.',
        facilitiesDescription: 'Testing facilities description text for OTC Markets Group profile page.',
        stateOfIncorporation: 'DE ',
        stateOfIncorporationName: 'Delaware',
        countryOfIncorporation: 'USA',
        countryOfIncorporationName: 'United States',
        yearOfIncorporation: '2008',
        originalYearofInc: '2008',
        incorporationInformation: [
          {
            stateOfIncorporation: 'DE',
            countryOfIncorporation: 'USA',
            yearOfIncorporation: '2018'
          }, {
            stateOfIncorporation: 'CA',
            countryOfIncorporation: 'USA',
            yearOfIncorporation: '2012'
          },
          {
            stateOfIncorporation: 'NJ',
            countryOfIncorporation: 'USA',
            yearOfIncorporation: '2008'
          }
        ],
        premierDirectorList: [
          {
            name: 'Neal Wolkoff',
            title: 'Chairman',
            boards: 'Independent Director',
            biography: 'Neal Wolkoff the Chairman of OTC Markets Group\'s Board of Directors...'
          }
        ],
        standardDirectorList: [
          {
            name: 'Gary Baddeley',
            title: 'Director',
            boards: 'Independent Director, Audit Committee Member',
            biography: 'Gary Baddeley has served as CEO of TDC Entertainment, a diversified entertainment company, since 1997. TDC\'s core businesses are home entertainment and electronic publishing. Previously, Gary served for two years as Vice President and General Manager of a leading dance music label, Robbins Entertainment. From 1990 to 1996 Gary was an attorney at Phillips Nizer LLP in New York City, specializing in representation of clients in the music and television industries. Gary holds a JD from New York University School of Law and a B.Sc. from Kingston University.'
          },
          {
            name: 'R. Cromwell Coulson',
            title: 'Director'
          },
          {
            name: 'Louisa Serene Schneider',
            title: 'Director',
            boards: 'Independent Director, Audit Committee Member',
            biography: 'Louisa Serene Schneider serves as the Chief Administrative Officer...'
          },
          {
            name: 'Andrew  Wimpfheimer',
            title: 'Director',
            boards: 'Independent Director, Audit Committee Member'
          }
        ],
        officers: [
          {
            name: 'R. Cromwell Coulson',
            title: 'CEO, President',
            twitter: 'https://twitter.com/xxxxxxxxx',
            linkedin: 'https://www.linkedin.com/in/xxxxxxxxx',
            biography: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
          },
          {
            name: 'Michael  Modeski',
            title: 'President, OTC Link',
            linkedin: 'https://www.linkedin.com/in/xxxxxxxxx'
          },
          {
            name: 'Bea Ordonez',
            title: 'CFO',
            twitter: 'https://twitter.com/xxxxxxxxx',
            linkedin: 'https://www.linkedin.com/in/xxxxxxxxx'
          },
          {
            name: 'Matthew  Fuchs',
            title: 'EVP'
          },
          {
            name: 'Lisabeth Heese',
            title: 'EVP',
            twitter: 'https://twitter.com/xxxxxxxxx'
          },
          {
            name: 'Dan  Zinn',
            title: 'General Counsel'
          }
        ],
        companyInsiders: [
          {
            firstName: 'Frankie',
            lastName: 'Pugliese'
          },
          {
            firstName: 'Joseph',
            lastName: 'Engle',
            corpEntity: 'Posh Corp.'
          }
        ],
        fiscalYearEnd: '12/31',
        filingCycle: 'Q',
        edgarFilingStatus: 'SEC Reporting',
        edgarFilingStatusId: 'F',
        reportingStandard: 'U.S. Reporting: SEC Reporting',
        reportingStandardMin: 'SEC Reporting - Current',
        isDark: false,
        deregistered: false,
        is12g32b: false,
        isAlternativeReporting: false,
        isBankThrift: false,
        isNonBankRegulated: false,
        isInternationalReporting: false,
        isOtherReporting: false,
        auditedStatusDisplay: 'Audited',
        auditStatus: 'A',
        audited: true,
        email: 'info@otcmarkets.com',
        numberOfEmployees: 78,
        numberOfEmployeesAsOf: 1493524800000,
        roundLotShareholders: 287,
        roundLotShareholdersDate: 1483160400000,
        primarySicCode: '6289 - Security and commodity service',
        auditors: [
          {
            id: 2069,
            type: 'AD',
            typeId: 5,
            typeName: 'Accounting/Auditing Firm',
            name: 'KPMG Accountants N.V.',
            phone: '49 (89) 9282-1040',
            countryId: 'DEU',
            country: 'Germany',
            address1: 'Ganghoferstrasse 29',
            city: 'Munich',
            zip: '80339',
            roles: [
              'Accounting/Auditing Firm'
            ],
            isPublic: true,
            hasLogo: false,
            isGoodStanding: true,
            isProhibited: false,
            isQuestionable: false,
            isAttorney: false,
            isSponsor: false,
            public: true
          }
        ],
        investorRelationFirms: [],
        investmentBanks: [],
        corporateBrokers: [
          {
            id: 2461,
            type: 'CS',
            typeId: 7,
            typeName: 'Corporate Broker',
            name: 'Deutsche Bank Trust Company Americas',
            phone: '212-250-9100',
            website: 'http://www.db.com',
            countryId: 'USA',
            country: 'United States',
            address1: '60 Wall Street',
            address2: '16th Floor',
            city: 'New York',
            stateId: 'NY',
            zip: '10005',
            roles: [
              'Corporate Broker',
              'Transfer Agent',
              'Investment Bank',
              'OTCQX Sponsor'
            ],
            isPublic: true,
            hasLogo: false,
            isGoodStanding: true,
            isProhibited: false,
            isQuestionable: false,
            isAttorney: false,
            isSponsor: true,
            public: true
          }
        ],
        legalCounsels: [
          {
            id: 1973,
            type: 'LC',
            typeId: 3,
            typeName: 'Securities Counsel',
            name: 'The Nelson Law Firm, LLC',
            phone: '914-220-1900',
            website: 'http://www.thenelsonlawfirm.com',
            countryId: 'USA',
            country: 'United States',
            address1: 'One North Broadway',
            address2: 'Suite 712',
            city: 'White Plains',
            stateId: 'NY',
            zip: '10601',
            roles: [
              'Securities Counsel',
              'OTCQX Sponsor'
            ],
            isPublic: true,
            hasLogo: true,
            isGoodStanding: true,
            isProhibited: false,
            isQuestionable: false,
            isAttorney: true,
            isSponsor: true,
            public: true
          }
        ],
        notes: [
          'Sponsored for OTCQX by Merriman Capital, Inc. on 03/11/2010.',
          'Formerly=Pink OTC Markets Inc. until 1-2011'
        ],
        securities: [
          {
            id: 128054,
            tierId: 1,
            symbol: 'OTCM',
            cusip: '67106F108',
            compId: 674812,
            issueName: 'OTC MARKETS GROUP INC A',
            className: 'Class A Common Stock',
            primaryVenue: 'OTC Link',
            categoryId: 2,
            categoryName: 'Current Information',
            isBB: false,
            isPinkSheets: false,
            typeCode: 'CS',
            typeName: 'Common Stock',
            tierName: 'OTCQX U.S. Premier',
            tierGroupId: 'QX',
            tierCode: 'QX',
            tierStartDate: 1268283600000,
            tierDisplayName: 'OTCQX U.S. Premier',
            ratioAdr: 1,
            isAdr: false,
            isGdr: false,
            isTest: false,
            isOtcQX: true,
            isNoInfo: false,
            isSponsored: false,
            isPiggyBacked: true,
            isQuoteElig: true,
            isCaveatEmptor: false,
            isLevel2Entitled: true,
            hasLevel2: true,
            canAccessBB: false,
            noPar: false,
            parValue: 0.01,
            outstandingShares: 11860898,
            outstandingSharesAsOfDate: 1507608000000,
            authorizedShares: 14000000,
            unlimitedAuthorizedShares: false,
            authorizedSharesAsOfDate: 1507608000000,
            publicFloat: 6642015,
            publicFloatAsOfDate: 1483160400000,
            dtcShares: 9446879,
            dtcSharesAsOfDate: 1507608000000,
            foreignExchangeId: 10,
            foreignExchangeSymbol: '',
            foreignExchangeName: 'ASX - Australian Securities Exchange',
            foreignExchangeTier: 'No Tier',
            isExchangeQualified: true,
            shortInterest: 3603,
            shortInterestChange: 100,
            shortInterestDate: 1504152000000,
            restrictedShares: 1481611,
            restrictedSharesAsOfDate: 1507608000000,
            unrestrictedShares: 10379287,
            unrestrictedSharesAsOfDate: 1507608000000,
            sigFailDeliver: false,
            numOfRecordShareholders: 166,
            numOfRecordShareholdersDate: 1483160400000,
            transferAgents: [
              {
                id: 2378,
                type: 'CS',
                typeName: 'Transfer Agent',
                name: 'Continental Stock Transfer & Trust Company',
                phone: '212-509-4000',
                website: 'http://www.continentalstock.com',
                email: 'cstmail@continentalstock.com',
                countryId: 'USA',
                country: 'United States',
                address1: '1 State Street Plaza',
                address2: '30th Floor',
                city: 'New York',
                stateId: 'NY',
                zip: '10004',
                roles: [
                  'Transfer Agent'
                ],
                isPublic: true,
                hasLogo: true,
                isGoodStanding: true,
                isProhibited: false,
                isQuestionable: false,
                isAttorney: false,
                isSponsor: false,
                public: true
              }
            ],
            showTrustedLogoForAuthorizedShares: false,
            showTrustedLogoForOutstandingShares: false,
            showTrustedLogoForRestrictedShares: false,
            showTrustedLogoForUnrestrictedShares: false,
            showTrustedLogoForDTCShares: false
          }
        ],
        otherSecurities: [],
        estimatedMarketCap: 358199119.6,
        estimatedMarketCapAsOfDate: 1507608000000,
        blankCheck: false,
        blindPool: false,
        spac: false,
        hasLatestFiling: true,
        latestFilingType: 'Semi-Annual Report',
        latestFilingDate: 1498795200000,
        latestFilingUrl: '/company/financial-report/180613/content',
        tierGroup: 'QX',
        tierCode: 'QX',
        tierStartDate: 1268283600000,
        hasLogo: true,
        companyLogoUrl: '/company/logo/OTCM',
        isCaveatEmptor: false,
        otcAward: {
          symbol: 'OTCM',
          best50: false,
          tenYear: false
        },
        indexStatuses: [
          {
            indexSymbol: '.OTCQXUS',
            indexName: 'OTCQX U.S.'
          },
          {
            indexSymbol: '.OTCQX',
            indexName: 'OTCQX Composite'
          },
          {
            indexSymbol: '.OTCQXDIV',
            indexName: 'OTCQX Dividend'
          }
        ],
        profileBadges: {
          isShell: false,
          hasControlDispute: false,
          isPennyStockExempt: true,
          isBankrupt: false,
          unableToContact: false,
          transferAgentVerified: false,
          isCaveatEmptor: false,
          isDark: false,
          isDelinquent: false,
          verifiedProfile: false,
          verifiedDate: 1496289600000
        },
        unableToContact: false,
        isShell: false,
        isBankrupt: false,
        isProfileVerified: false,
        venue: 'OTC Link'
      }

    case 'VLKAY':
      return {
        id: 1471,
        name: 'Volkswagen AG',
        city: 'Wolfsburg',
        state: '',
        zip: '38440     ',
        countryId: 'DEU',
        country: 'Germany',
        address1: 'Berliner Ring 2',
        address2: '',
        address3: '',
        execAddr: {
          city: 'Wolfsburg',
          state: '',
          zip: '38440     ',
          country: 'Germany',
          addr1: 'Berliner Ring 2',
          addr2: ''
        },
        website: 'http://www.volkswagenag.com/ir',
        phone: '+49 (0) 5361-9-0',
        fax: '+49 (0)5361-9-28282',
        businessDesc: 'The Volkswagen Group with its headquarters in Wolfsburg is one of the worlds leading automobile manufacturers...',
        productServicesDescription: 'The Volkswagen Group product services desription',
        facilitiesDescription: 'The Volkswagen Group facilities description.',
        countryOfIncorporation: 'DEU',
        countryOfIncorporationName: 'Germany',
        yearOfIncorporation: '1961',
        premierDirectorList: [],
        standardDirectorList: [
          {
            name: 'Hussein Ali Al Abdalla',
            title: 'Director'
          },
          {
            name: 'Akbar Al Baker',
            title: 'Director'
          },
          {
            name: 'Annika  Falkengren',
            title: 'Director'
          },
          {
            name: 'Dr. Hans-Peter Fischer',
            title: 'Director'
          },
          {
            name: 'Uwe  Fritsch',
            title: 'Director'
          },
          {
            name: 'Babette  Froehlich',
            title: 'Director'
          },
          {
            name: 'Berthold  Huber',
            title: 'Director'
          },
          {
            name: 'Uwe Hueck',
            title: 'Director'
          },
          {
            name: 'Louise Kiesling',
            title: 'Director'
          },
          {
            name: 'Julia Kuhn-Piech',
            title: 'Director'
          },
          {
            name: 'Olaf  Lies',
            title: 'Director'
          },
          {
            name: 'Hartmut  Meine',
            title: 'Director'
          },
          {
            name: 'Peter  Mosch',
            title: 'Director'
          },
          {
            name: 'Bernd Osterloh',
            title: 'Director'
          },
          {
            name: 'Hans-Michel  Piech',
            title: 'Director'
          },
          {
            name: 'Wolfgang  Porsche',
            title: 'Director'
          },
          {
            name: 'Stephan  Weil',
            title: 'Director'
          },
          {
            name: 'Stephan  Wolf',
            title: 'Director'
          },
          {
            name: 'Thomas  Zwiebler',
            title: 'Director'
          }
        ],
        officers: [
          {
            name: 'Berthold Huber',
            title: 'Chairman of the Supervisory Board'
          },
          {
            name: 'Hans Dieter Poetsch',
            title: 'CFO'
          },
          {
            name: 'Michael  Ganninger',
            title: 'Counsel'
          }
        ],
        fiscalYearEnd: '12/31',
        filingCycle: 'Q',
        edgarFilingStatus: 'International Reporting',
        edgarFilingStatusId: 'G',
        reportingStandard: 'International Reporting: Frankfurt Stock Exchange',
        reportingStandardMin: 'International Reporting',
        isDark: false,
        deregistered: false,
        is12g32b: false,
        cik: '0000839772',
        isAlternativeReporting: false,
        isBankThrift: false,
        isNonBankRegulated: false,
        isInternationalReporting: true,
        isOtherReporting: false,
        auditedStatusDisplay: 'Audited',
        auditStatus: 'A',
        audited: true,
        email: 'investor.relations@volkswagen.de',
        numberOfEmployees: 592586,
        numberOfEmployeesAsOf: 1420002000000,
        primarySicCode: '3711 - Motor vehicles and car bodies',
        auditors: [
          {
            id: 2708,
            type: 'AD',
            typeId: 5,
            typeName: 'Accounting/Auditing Firm',
            name: 'PricewaterhouseCoopers LLP',
            countryId: 'DEU',
            country: 'Germany',
            address1: 'Friedrich-Ebert-Anlage 35 - 37',
            city: 'Frankfurt',
            zip: '60327',
            roles: [
              'Accounting/Auditing Firm'
            ],
            isPublic: true,
            hasLogo: false,
            isGoodStanding: true,
            isProhibited: false,
            isQuestionable: false,
            isAttorney: false,
            isSponsor: false,
            public: true
          }
        ],
        investorRelationFirms: [],
        legalCounsels: [],
        investmentBanks: [],
        securities: [
          {
            id: 35275,
            tierId: 20,
            symbol: 'VLKAY',
            cusip: '928662303',
            compId: 1471,
            issueName: 'VOLKSWAGEN AG S/ADR',
            className: 'Sponsored ADR (1 ADS : 0.2 Ordinary)',
            primaryVenue: 'OTC Link',
            categoryId: 2,
            categoryName: 'Current Information',
            isBB: false,
            isPinkSheets: false,
            typeCode: 'ADR',
            typeName: 'ADR',
            tierName: 'OTC Pink Current',
            tierGroupId: 'PS',
            tierCode: 'PC',
            tierStartDate: 1449723600000,
            tierDisplayName: 'Pink Current Information',
            ratioAdr: 1,
            ratioOrd: 0.2,
            isAdr: true,
            isGdr: false,
            isTest: false,
            isOtcQX: false,
            isNoInfo: false,
            isSponsored: true,
            isPiggyBacked: true,
            isQuoteElig: true,
            isCaveatEmptor: false,
            isLevel2Entitled: false,
            hasLevel2: false,
            canAccessBB: false,
            noPar: true,
            parValue: 0,
            outstandingShares: 295100000,
            outstandingSharesAsOfDate: 1443585600000,
            unlimitedAuthorizedShares: false,
            publicFloat: 29200000,
            publicFloatAsOfDate: 1388466000000,
            foreignExchangeId: 103,
            foreignExchangeSymbol: 'VOW',
            foreignExchangeName: 'Frankfurt Stock Exchange',
            foreignExchangeTier: 'Regulated Market - Prime',
            isExchangeQualified: true,
            shortInterest: 42536,
            shortInterestChange: 211.35,
            shortInterestDate: 1504152000000,
            sigFailDeliver: false,
            transferAgents: [
              {
                id: 2220,
                type: 'CS',
                typeName: 'Transfer Agent',
                name: 'JPMorgan Depositary Receipts',
                website: 'https://www.adr.com',
                email: 'adr@jpmorgan.com',
                countryId: 'USA',
                country: 'United States',
                address1: '1 Chase Manhattan Plaza',
                address2: 'Floor 58',
                city: 'New York',
                stateId: 'NY',
                zip: '10005',
                roles: [
                  'Transfer Agent'
                ],
                isPublic: true,
                hasLogo: false,
                isGoodStanding: true,
                isProhibited: false,
                isQuestionable: false,
                isAttorney: false,
                isSponsor: false,
                public: true
              }
            ],
            notes: [
              'Par Change=No Par from DEM50. Basis: shs increased by 10 for 1 split. Rec Date=7-2-98 Pay date=7-6-98'
            ],
            showTrustedLogoForAuthorizedShares: false,
            showTrustedLogoForOutstandingShares: false,
            showTrustedLogoForRestrictedShares: false,
            showTrustedLogoForUnrestrictedShares: false,
            showTrustedLogoForDTCShares: false
          }
        ],
        otherSecurities: [
          {
            symbol: 'VLKAF',
            name: 'VOLKSWAGEN AG ORD'
          },
          {
            symbol: 'VLKPF',
            name: 'VOLKSWAGEN AG PFD SHS'
          },
          {
            symbol: 'VLKPY',
            name: 'VOLKSWAGEN AG PFD S/ADR'
          }
        ],
        estimatedMarketCap: 51480195000,
        estimatedMarketCapAsOfDate: 1507608000000,
        blankCheck: false,
        blindPool: false,
        spac: false,
        hasLatestFiling: false,
        tierGroup: 'PS',
        tierCode: 'PC',
        tierStartDate: 1449723600000,
        hasLogo: true,
        companyLogoUrl: '/company/logo/VLKAY',
        isCaveatEmptor: false,
        otcAward: {
          symbol: 'VLKAY',
          best50: false,
          tenYear: false
        },
        indexStatuses: [
          {
            indexSymbol: '.OTCDR',
            indexName: 'OTCM ADR INDEX'
          }
        ],
        profileBadges: {
          isShell: false,
          hasControlDispute: false,
          isPennyStockExempt: true,
          isBankrupt: false,
          unableToContact: false,
          transferAgentVerified: false,
          isCaveatEmptor: false,
          isDark: false,
          isDelinquent: false,
          verifiedProfile: false,
          verifiedDate: 1441684800000
        },
        unableToContact: false,
        isShell: false,
        isBankrupt: false,
        isProfileVerified: false,
        venue: 'OTC Link'
      }
  }
}
