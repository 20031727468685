import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Select.module.scss'
import SelectMenuItem from './SelectMenuItem'
import { Box } from 'grid-styled'

const SelectMenu = (props) => {
  const { options, type, active, selection, selectionIndices, menuStates, toggleSubmenu, onSelect, onHover } = props
  const items = []
  options.map((option, i) => {
    items.push(<Box w={1}
      name={`item_${i}`}
      key={option.value}
      className={classNames(styles.menuItem, {
        [styles.active]: option.value === active,
        [styles.selected]: option.value === selection,
        [styles.clear]: option.clear
      })}
      onMouseOver={onHover(option)}
      onMouseDown={option.nested ? toggleSubmenu(option.value) : onSelect(option)}>
      {
        <SelectMenuItem
          option={option}
          type={type}
          selectionIndices={selectionIndices}
          menuState={menuStates[option.value]} />
      }
    </Box>)

    if (option.nested) {
      option.nested.map(nestedValue => {
        return items.push(<Box w={1}
          key={nestedValue.value}
          onMouseDown={onSelect(nestedValue)}
          className={classNames(styles.menuItem, styles.submenu, {
            [styles.submenuOpen]: menuStates[option.value],
            [styles.clear]: option.clear
          })}>
          {
            <SelectMenuItem
              option={nestedValue}
              type={type}
              selectionIndices={selectionIndices}
              menuState={null} />
          }
        </Box>)
      })
    }
  })
  return <div>{items}</div>
}

SelectMenu.propTypes = {
  options: PropTypes.array,
  type: PropTypes.string,
  active: PropTypes.any,
  selection: PropTypes.any,
  selectionIndices: PropTypes.array,
  menuStates: PropTypes.any,
  toggleSubmenu: PropTypes.func,
  onSelect: PropTypes.func,
  onHover: PropTypes.func
}

export default SelectMenu
