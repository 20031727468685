export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return {
        records: [],
        sortOn: '1',
        sortDir: 'DESC',
        key: '673740',
        plural: 'News',
        singular: 'News',
        totalRecords: 230,
        pages: 23,
        currentPage: 1,
        pageSize: 10
      }

    case 'OTCM':
      return {
        records: [
          {
            formattedPublishedDate: 'Sep 5, 2017',
            guid: '4104256',
            link: 'https://seekingalpha.com/article/4104256-power-compounding-even-commodity-producers?source=otcmarkets',
            headline: 'The Power Of Compounding... Even For Commodity Producers',
            pubDate: new Date(1504639852000),
            sourceName: 'Seeking Alpha'
          },
          {
            formattedPublishedDate: 'Sep 5, 2017',
            guid: '4104249',
            link: 'https://seekingalpha.com/article/4104249-simple-strategy-beat-market-long-term?source=otcmarkets',
            headline: 'A Simple Strategy To Beat The Market Long-Term',
            pubDate: new Date(1504638742000),
            sourceName: 'Seeking Alpha'
          },
          {
            formattedPublishedDate: 'Sep 5, 2017',
            guid: '4104245',
            link: 'https://seekingalpha.com/article/4104245-atlassian-deep-dive-well-deserved-premium-cash-flow-story-brewing?source=otcmarkets',
            headline: 'Atlassian Deep Dive: A Well-Deserved Premium, Cash Flow Story Brewing',
            pubDate: new Date(1504638215000),
            sourceName: 'Seeking Alpha'
          },
          {
            formattedPublishedDate: 'Sep 5, 2017',
            guid: 'mc3293644',
            link: 'https://seekingalpha.com/news/3293644-bloomberg-facebook-ponying-music-rights-videos?source=otcmarkets',
            headline: 'Bloomberg: Facebook ponying up for music rights for videos',
            pubDate: new Date(1504637400000),
            sourceName: 'Seeking Alpha'
          },
          {
            formattedPublishedDate: 'Sep 5, 2017',
            guid: 'mc3293639',
            link: 'https://seekingalpha.com/news/3293639-whatsapp-moving-forward-monetizable-business-features?source=otcmarkets',
            headline: 'WhatsApp moving forward with monetizable business features',
            pubDate: new Date(1504634778000),
            sourceName: 'Seeking Alpha'
          },
          {
            formattedPublishedDate: 'Sep 5, 2017',
            guid: '4104202',
            link: 'https://seekingalpha.com/article/4104202-institutional-ownership-snap?source=otcmarkets',
            headline: 'Institutional Ownership In Snap',
            pubDate: new Date(1504633185000),
            sourceName: 'Seeking Alpha'
          },
          {
            formattedPublishedDate: 'Sep 5, 2017',
            guid: 'mc3293593',
            link: 'https://seekingalpha.com/news/3293593-facebooks-failed-600m-cricket-bid-may-presage-nfl-streaming-dreams?source=otcmarkets',
            headline: 'Facebook\'s failed $600M cricket bid may presage NFL streaming dreams',
            pubDate: new Date(1504626510000),
            sourceName: 'Seeking Alpha'
          },
          {
            sourceName: 'PRWEB',
            formattedPublishedDate: 'Sep 5, 2017',
            id: 881083,
            companyId: 673740,
            headline: 'datadecisions Group Announces dataFacesTM',
            pubDate: new Date(1504625100000),
            sourceId: 84
          },
          {
            formattedPublishedDate: 'Sep 5, 2017',
            guid: 'mc3293506',
            link: 'https://seekingalpha.com/news/3293506-worldwide-ar-vr-market-25_5-percent-q2-samsung-leads?source=otcmarkets',
            headline: 'Worldwide AR/VR market up 25.5% in Q2, Samsung leads',
            pubDate: new Date(1504615540000),
            sourceName: 'Seeking Alpha'
          },
          {
            formattedPublishedDate: 'Sep 4, 2017',
            guid: '4104007',
            link: 'https://seekingalpha.com/article/4104007-nextdoor-needs-ramp-revenues-ipo-preparation?source=otcmarkets',
            headline: 'Nextdoor Needs To Ramp Revenues In IPO Preparation',
            pubDate: new Date(1504558177000),
            sourceName: 'Seeking Alpha'
          }
        ],
        sortOn: '1',
        sortDir: 'DESC',
        key: '673740',
        plural: 'News',
        singular: 'News',
        totalRecords: 230,
        pages: 23,
        currentPage: 1,
        pageSize: 10
      }

    case 'VLKAY':
      return {
        records: [],
        sortOn: '1',
        sortDir: 'DESC',
        key: '673740',
        plural: 'News',
        singular: 'News',
        totalRecords: 230,
        pages: 23,
        currentPage: 1,
        pageSize: 10
      }
  }
}
