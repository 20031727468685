
const getSlug = (title) => {
  if (!title) return ''
  return title.trim()
    .replace(/ {2}/g, ' ')
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
    .substring(0, 120)
    .trim()
}

const getFullUrl = () => {
  return window.location.href.split('/').slice(0, 3).join('/')
}

export const getDNSNewsUrl = (symbol, item, fullUrl) => {
  const slug = item.title ? getSlug(item.title) : 'story'
  const url = `/stock/${symbol}/news/${slug}?id=${item.id}`
  return fullUrl ? getFullUrl() + url : url
}

export const getExternalNewsUrl = (symbol, item, fullUrl) => {
  const slug = item.title ? getSlug(item.title) : 'story'
  const url = `/stock/${symbol}/news/${slug}?e&id=${item.id}`
  return fullUrl ? getFullUrl() + url : url
}

export const getVideoUrl = (symbol, item, fullUrl) => {
  const slug = item.title ? getSlug(item.title) : 'video'
  const url = `/stock/${symbol}/news/${slug}?v&id=${item.id}`
  return fullUrl ? getFullUrl() + url : url
}
