import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import moment from 'moment-timezone'
import { Flex, Box } from 'grid-styled'
import classNames from 'classnames'
import { template, fetch, expand, sort } from '../../api/helper'
import { withRouter } from '../WithRouter'
import { Link } from 'react-router-dom'
import { LabelTip } from '../LabelTip'
import { getCompanyData } from '../../api/otc/market-activity/corporate-actions'
import { getDividends, getSplits, getShareStructureHistory } from '../../api/otc/company/company'
import { logPageview } from '../../analytics'

import { FaShieldAlt } from 'react-icons/fa'
import styles from './CompanyQuoteSecurity.module.scss'

import Locale from '../Locale'
import Outline from '../Outline'
import More from '../More'
import TableFooter from '../TableFooter'
import DisplayResults from '../DisplayResults'
import List from '../List'
import TableTabs from '../TableTabs'
import Table from '../Table'
import Loading from '../Loading'
import Select from '../Select'
import * as Routes from '../../constants/Routes'
import { withContext } from '../../AuthContext'
import { OTC_ANALYTICS, SMALL_CAP_LISTED } from '../../constants/groups'

const shareStructure = ({ currentSecurity = {}, ...profile }) => [{
  title: 'Market Cap',
  tooltip: 'Market Cap calculated only for this class of securities.',
  value: profile.estimatedMarketCap,
  date: profile.estimatedMarketCapAsOfDate,
  type: 'int'
}, {
  title: 'Authorized Shares',
  value: currentSecurity.unlimitedAuthorizedShares ? 'Unlimited' : currentSecurity.authorizedShares,
  date: currentSecurity.authorizedSharesAsOfDate,
  type: currentSecurity.unlimitedAuthorizedShares ? 'text' : 'int',
  shield: currentSecurity.showTrustedLogoForAuthorizedShares
}, {
  title: 'Outstanding Shares',
  tooltip: (['ADR', 'GDR', 'NYRS'].includes(currentSecurity.typeCode.toUpperCase())) ? 'This data represents the number of shares outstanding in the underlying security listed on a non-US exchange.' : '',
  value: currentSecurity.outstandingShares,
  date: currentSecurity.outstandingSharesAsOfDate,
  shield: currentSecurity.showTrustedLogoForOutstandingShares,
  hideUnderline: true,
  type: 'int'
}, {
  title: 'Restricted',
  value: currentSecurity.restrictedShares,
  date: currentSecurity.restrictedSharesAsOfDate,
  shield: currentSecurity.showTrustedLogoForRestrictedShares,
  type: 'int',
  child: true,
  hideUnderline: true
}, {
  title: 'Unrestricted',
  value: currentSecurity.unrestrictedShares,
  date: currentSecurity.unrestrictedSharesAsOfDate,
  shield: currentSecurity.showTrustedLogoForUnrestrictedShares,
  type: 'int',
  child: true
}, {
  title: 'Held at DTC',
  value: currentSecurity.dtcShares,
  date: currentSecurity.dtcSharesAsOfDate,
  shield: currentSecurity.showTrustedLogoForDTCShares,
  type: 'int'
}, {
  title: 'Float',
  value: currentSecurity.publicFloat,
  date: currentSecurity.publicFloatAsOfDate,
  type: 'int'
}, {
  title: 'Par Value',
  value: currentSecurity.parValue,
  type: 'price',
  emptyText: currentSecurity.parValue === 0 && currentSecurity.noPar ? 'No Par Value' : null,
  trigger: 'noPar'
}]

const corpActSearchValues = [{
  title: 'Symbol Changes',
  value: 'Symbol Change'
}, {
  title: 'Company Name Changes',
  value: 'Company Name Change'
}, {
  title: 'Security Status Changes',
  value: 'Security Status Change'
}, {
  title: 'Market Changes',
  value: 'Market Change'
}, {
  title: 'Symbol & Name Changes',
  value: 'Symbol & Name Change'
}, {
  title: 'Proprietary Quote Eligible',
  value: 'Proprietary Quote Eligible'
}, {
  title: 'Caveat Emptor',
  value: 'CE Status Change'
}]

const shareStructureHistoryColumns = [
  {
    name: 'effectiveDate',
    header: 'Effective Date',
    type: 'date',
    sortingEnabled: true
  },
  {
    name: 'shareType',
    header: 'Share Structure Type',
    sortingEnabled: true
  },
  {
    name: 'oldValue',
    header: 'Old Value',
    type: 'int',
    sortingEnabled: true
  },
  {
    name: 'newValue',
    header: 'New Value',
    type: 'int',
    sortingEnabled: true
  },
  {
    name: 'changeAmount',
    header: 'Change Amount',
    type: 'int',
    sortingEnabled: true
  }
]

const corpActionsColumns = [{
  name: 'actionType',
  header: 'Action Type',
  sortingEnabled: true
}, {
  name: 'changeDate',
  header: 'Effective Date',
  type: 'date',
  sortingEnabled: true
}, {
  name: 'symbol',
  header: 'Symbol'
}, {
  name: 'comments',
  header: 'Description'
}]

const corpActionsEmptyMsg = {
  'Symbol Change': 'No Symbol Changes',
  'Company Name Change': 'No Company Name Changes',
  'Security Status Change': 'No Security Status Changes',
  'Market Change': 'No Market Changes',
  'Symbol & Name Change': 'No Symbol & Name Changes',
  'Proprietary Quote Eligible': 'Has not been Proprietary Quote Eligible',
  'CE Status Change': 'Has not been designated with Caveat Emptor'
}

const dividendsColumns = [{
  name: 'changeDate',
  header: 'Date',
  type: 'date',
  emptyValue: '—',
  sortingEnabled: true
}, {
  name: 'actionType',
  header: 'Type',
  emptyValue: '—'
}, {
  name: 'cashAmount',
  header: 'Cash Amount',
  headerShort: '$Amt',
  type: 'price',
  emptyValue: '—',
  sortingEnabled: true
}, {
  name: 'stockAmount',
  header: 'Share Amount *',
  headerShort: 'ShareAMT*',
  emptyValue: '—',
  sortingEnabled: true
}, {
  name: 'exerciseDate',
  header: 'Ex Date',
  type: 'date',
  emptyValue: '—',
  sortingEnabled: true
}, {
  name: 'recordDate',
  header: 'Record Date',
  headerShort: 'RECDATE',
  type: 'date',
  emptyValue: '—',
  sortingEnabled: true
}, {
  name: 'payDate',
  header: 'Pay Date',
  headerShort: 'PayDate',
  type: 'date',
  emptyValue: '—',
  sortingEnabled: true
}]

const splitsColumns = [{
  name: 'changeDate',
  header: 'Split Date',
  headerShort: 'SptDate',
  type: 'date',
  emptyValue: '—',
  sortingEnabled: true
}, {
  name: 'actionType',
  header: 'Split Type',
  headerShort: 'SptType',
  emptyValue: '—',
  sortingEnabled: true
}, {
  name: 'splitRatio',
  header: 'Split Ratio',
  headerShort: 'SptRat',
  emptyValue: '—',
  sortingEnabled: true
}, {
  name: 'recordDate',
  header: 'Rec Date',
  headerShort: 'RECDATE',
  type: 'date',
  emptyValue: '—',
  sortingEnabled: true
}, {
  name: 'payDate',
  header: 'Pay Date',
  headerShort: 'PayDate',
  type: 'date',
  emptyValue: '—',
  sortingEnabled: true
}]

const getDividendsSplits = {
  dividends: getDividends,
  splits: getSplits
}

class CompanyQuoteSecurity extends React.PureComponent {
  constructor (props, context) {
    super(props, context)

    const queryParams = queryString.parse(this.props.location.search)

    this.state = {
      activeSecurity: 0,
      corpActSearchType: corpActSearchValues.find(value => value.value === queryParams.type) ? queryParams.type : corpActSearchValues[0].value,
      corpActions: template('records'),
      shareStructureHistory: template('records'),
      dividends: template('records'),
      selectedTab: queryParams.tab === 'splits' ? queryParams.tab : 'dividends',
      filters: {
        shareStructureHistory: {
          fromDate: null,
          endDate: null
        }
      }
    }
  }

  componentDidMount () {
    this.loadCorpActions()
    this.loadShareStructureHistory()
    this.state.selectedTab === 'splits' ? this.loadSplits() : this.loadDividends()

    const title = `OTC Markets | ${this.props.company} | Security Details`
    logPageview(title)

    // If anchor is supplied in hash, scroll into view
    const hash = window.location.hash
    if (hash && hash.length > 1) {
      const el = document.getElementById(hash.substr(1))
      if (el) {
        const elOffSet = el.offsetTop
        setTimeout(() => { window.scrollBy({ top: elOffSet - 150 }) }, 100)
      }
    }
  }

  loadCorpActions = () => {
    if (this.props.info && this.props.info.isListed) return
    fetch(this, getCompanyData, 'corpActions', {
      type: this.state.corpActSearchType,
      page: 1,
      pageSize: 5,
      symbol: this.props.company,
      retainPageSize: true
    })
  }

  loadShareStructureHistory = () => {
    const user = this.props.context.user
    const isOtcAnalyticsEntitled = user && user.userGroups && user.userGroups.find(group => (group === OTC_ANALYTICS))
    const isSmallCapEntitled = user && user.userGroups && user.userGroups.find(group => (group === SMALL_CAP_LISTED))
    const isEquity = this.props.info.isEquity
    const isListed = this.props.info.isListed
    const isRiskScoreEligible = (isEquity && isOtcAnalyticsEntitled) || (isListed && isSmallCapEntitled)

    if (isRiskScoreEligible) {
      fetch(this, getShareStructureHistory, 'shareStructureHistory', {
        page: 1,
        pageSize: 5,
        fromDate: this.state.filters.shareStructureHistory.fromDate,
        endDate: this.state.filters.shareStructureHistory.endDate,
        symbol: this.props.company
      })
      this.setState({
        showShareStructure: true
      })
    }
  }

  loadDividends = () => {
    if (this.props.info && this.props.info.isListed) return
    fetch(this, getDividends, 'dividends', {
      page: 1,
      pageSize: 5,
      symbol: this.props.company,
      retainPageSize: true
    })
  }

  loadSplits = () => {
    if (this.props.info && this.props.info.isListed) return
    fetch(this, getSplits, 'dividends', {
      page: 1,
      pageSize: 5,
      symbol: this.props.company,
      retainPageSize: true
    })
  }

  dateSelect = (type, field) => {
    return (date) => {
      const filters = this.state.filters

      if (type && field) {
        filters[type][field] = date && moment(date).format('MM/DD/YYYY')
      }

      this.setState({ filters }, this.loadShareStructureHistory)
    }
  }

  onSelectcorpActSearchType = (corpActSearchType) => {
    this.setState({ corpActSearchType }, this.loadCorpActions)
  }

  onOtherSymbolSelect = (symbol) => {
    this.props.navigate(`/stock/${symbol}/security`)
  }

  onSelectTab = (tab, func) => {
    this.state.dividends.loaded = false
    this.setState({
      ...this.state,
      selectedTab: tab,
      dividends: this.state.dividends
    }, () => {
      func()
    })
  }

  render () {
    const { info, company, accent } = this.props
    const profile = info
    const security = profile.currentSecurity

    if (!security) return null

    return <div className={classNames(styles.CompanyQuoteSecurity, styles[accent])}>
      <Outline mode='heading' accent={accent} hideFilterToggle filters={profile.otherSecurities && profile.otherSecurities.length > 0
        ? [{
            type: 'select',
            title: 'Additional Securities',
            options: profile.otherSecurities && profile.otherSecurities.map(security => security.symbol),
            onSelect: this.onOtherSymbolSelect
          }]
        : null}>
        {security.symbol} Security Details
      </Outline>

      <h4 id='share-structure'>Share Structure</h4>
      {shareStructure(profile).map((item, index) => <Flex
        wrap
        w={1}
        key={`share-structure-row-${index}`}
        className={classNames({
          [styles.row]: true,
          [styles.child]: item.child,
          [styles.hideUnderline]: item.hideUnderline
        })}>
        <Box w={[1, 1 / 2, 1 / 2]}><b>{item.tooltip
          ? <LabelTip
          title={item.title}
          tipText={item.tooltip}
          width='350px'
          icon='/icons/sidebar/search-dollar-solid.svg'
        />
          : item.title}</b></Box>
        <Box flex='1'>
          {item.trigger && security[item.trigger] && <span>
            {item.emptyText}
          </span>}
          {(!item.trigger || !security[item.trigger]) && <Locale
            placeholder={item.emptyText || 'Not Available'}
            type={item.type}>
            {item.value}
          </Locale>}
          {item.shield && <FaShieldAlt className={styles.icon} />}
        </Box>
        <Box w={[1 / 2, 'auto', 'auto']}>
          {item.date && <Locale type='date'>{item.date}</Locale>}
        </Box>
      </Flex>)}

      <abbr>Market Cap calculated only for this class of securities</abbr>

      <Outline mode='invisible' spacing='large' />

      <Flex wrap w={1}>
        <Box w={[1, 1 / 2, 1 / 2]} pr={['0', '1em', '1em']}>
          <Flex>
            <Box w={1}><h4>Transfer Agent{security.transferAgents && security.transferAgents.length > 1 && 's'}</h4></Box>
          </Flex>
          <Flex className={styles.row}>
            <Box w={1}>
              {security.transferAgents && security.transferAgents.length
                ? security.transferAgents.map((agent, index) => <div key={`security-${company}-agent-${index}`}>
                  <Link to={`${Routes.DIR_SERVICE_PROVIDER}/${agent.id}?t=6`}>
                    {agent.name}
                  </Link>
                </div>)
                : 'Not Available'}
            </Box>
          </Flex>
        </Box>
        <Box w={[1, 1 / 2, 1 / 2]}>
          <Flex>
            <Box w={1}><h4>Shareholders</h4></Box>
          </Flex>
          <Flex className={styles.row}>
            <Box flex='1'>
              {profile.roundLotShareholders
                ? <span>
                <b><LabelTip
                  title={'Beneficial Shareholder'}
                  tipText={'A Beneficial Shareholder is any person who, directly or indirectly, has or shares the power to vote or dispose of a stock.'}
                  width='350px'
                  icon='/icons/sidebar/search-dollar-solid.svg'
                /></b>&nbsp;&nbsp;&nbsp;<Locale type='int'>{profile.roundLotShareholders}</Locale>
              </span>
                : 'Not Available'}
            </Box>
            <Box w={[1 / 2, 'auto', 'auto']}>
              {profile.roundLotShareholdersDate && <span>
                <Locale type='date'>
                  {profile.roundLotShareholdersDate}
                </Locale>
              </span>}
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Outline mode='invisible' spacing='large' />

      {this.state.showShareStructure && <div>
        <div className={styles.corpActionsHeader}>
          <Outline mode='heading4' filters={[{
            type: 'date',
            placeholderText: 'From',
            maxDate: moment(this.state.filters.shareStructureHistory.endDate) || moment(),
            onChange: this.dateSelect('shareStructureHistory', 'fromDate')
          }, {
            type: 'date',
            placeholderText: 'To',
            minDate: moment(this.state.filters.shareStructureHistory.fromDate),
            maxDate: moment(),
            onChange: this.dateSelect('shareStructureHistory', 'endDate')
          }]}>
            Share Structure History
          </Outline>
        </div>
        <Loading
          type='table'
          height='10em'
          loaded={this.state.shareStructureHistory.loaded}
          reloading={this.state.shareStructureHistory.reloading}
          error={this.state.shareStructureHistory.error}
          data={this.state.shareStructureHistory.data}
          emptyMessage={'No share structure history data'}>
          <Table
            columns={shareStructureHistoryColumns}
            data={this.state.shareStructureHistory.data}
            sortColumn={this.state.shareStructureHistory.sortOn}
            sortDirection={this.state.shareStructureHistory.sortDir}
            onSorting={sort(this, getShareStructureHistory, 'shareStructureHistory')}
            showHeader
          />
          <TableFooter>
            <More onClick={expand(this, getShareStructureHistory, 'shareStructureHistory')}
              disabled={this.state.shareStructureHistory.expandEmpty} />
            {this.state.shareStructureHistory.data.records && this.state.shareStructureHistory.data.totalRecords &&
              <DisplayResults show={this.state.shareStructureHistory.data.records.length} total={this.state.shareStructureHistory.data.totalRecords} text={'results'} />
            }
          </TableFooter>
        </Loading>
      </div>}

      {!info.isListed && <div>
        <Outline mode='invisible' spacing='large' />
        <div id='corporate-actions' className={styles.corpActionsHeader}>
          <Flex>
            <Box flex='1'><h4>Corporate Actions</h4></Box>
            <Box className={styles.tab}>
              <Select
                design='light'
                title={'Symbol Changes'}
                options={corpActSearchValues}
                defaultValue={this.state.corpActSearchType}
                onSelect={this.onSelectcorpActSearchType}
                required
              />
            </Box>
          </Flex>
        </div>
        <Loading
          type='table'
          height='10em'
          loaded={this.state.corpActions.loaded}
          reloading={this.state.corpActions.reloading}
          error={this.state.corpActions.error}
          data={this.state.corpActions.data.records}
          emptyMessage={corpActionsEmptyMsg[this.state.corpActSearchType]}>
          <Table
            columns={corpActionsColumns}
            data={this.state.corpActions.data.records}
            sortColumn={this.state.corpActions.sortOn}
            sortDirection={this.state.corpActions.sortDir}
            onSorting={sort(this, getCompanyData, 'corpActions')}
            showHeader
          />
          <TableFooter>
            <More onClick={expand(this, getCompanyData, 'corpActions')}
              disabled={this.state.corpActions.expandEmpty} />
            {this.state.corpActions.data.records && this.state.corpActions.data.totalRecords &&
              <DisplayResults show={this.state.corpActions.data.records.length} total={this.state.corpActions.data.totalRecords} text={'Actions'} />
            }
          </TableFooter>
        </Loading>
      </div>}

      {!info.isListed && <div id='div-splits' className={styles.dividendsSplits}>
        <div className={styles.corpActionsHeader}>
          <Flex>
            <Box flex='1'><h4>Dividends & Splits</h4></Box>
          </Flex>
        </div>
        <TableTabs
          accent={accent}
          tabs={[{
            key: 'dividends',
            title: 'Dividends',
            func: () => {
              this.onSelectTab('dividends', this.loadDividends)
            }
          },
          {
            key: 'splits',
            title: 'Splits',
            func: () => {
              this.onSelectTab('splits', this.loadSplits)
            }
          }]}
          selectedTab={this.state.selectedTab} />
        <Loading
          type='table'
          height='10em'
          loaded={this.state.dividends.loaded}
          reloading={this.state.dividends.reloading}
          error={this.state.dividends.error}
          data={this.state.dividends.data.records}
          emptyMessage={`No ${this.state.selectedTab}`}>
          <Table
            columns={(this.state.selectedTab === 'dividends') ? dividendsColumns : splitsColumns}
            expandedSection={item => item.comments}
            data={this.state.dividends.data.records}
            sortColumn={this.state.dividends.sortOn}
            sortDirection={this.state.dividends.sortDir}
            onSorting={sort(this, getDividendsSplits[this.state.selectedTab], 'dividends')}
            showHeader
          />
          <TableFooter>
            <More onClick={expand(this, getDividendsSplits[this.state.selectedTab], 'dividends')}
              disabled={this.state.dividends.expandEmpty} />
            {this.state.dividends.data.records && this.state.dividends.data.totalRecords &&
              <DisplayResults show={this.state.dividends.data.records.length} total={this.state.dividends.data.totalRecords} text={this.state.selectedTab} />
            }
          </TableFooter>
          <abbr>Dividends are not adjusted for stock splits. * Per share ratio for stock dividend.</abbr>
        </Loading>
      </div>}

      {(security.foreignExchangeId && security.foreignExchangeId !== 158) && <div>
        <Outline mode='invisible' spacing='large' />
        <Flex>
          <Box w={1}><h4>Foreign Stock Exchange Listing</h4></Box>
        </Flex>
        <Flex className={styles.row}>
          <Box w={1}>
            {security.foreignExchangeSymbol && <span>{security.foreignExchangeSymbol}  |&nbsp;</span>}
            {security.foreignExchangeName}
            {security.foreignExchangeTier && security.foreignExchangeTier !== 'No Tier' && <span>&nbsp;| {security.foreignExchangeTier}</span>}
            {security.isExchangeQualified && <span>&nbsp;| <Link to='/directories/qualified-foreign-exchange'>Qualified</Link></span>}
          </Box>
        </Flex>
      </div>}

      <Outline mode='invisible' spacing='large' />

      {info.isEquity && <div>
        <Flex>
          <Box w={1}><h4>Short Selling Data</h4></Box>
        </Flex>
        <Flex wrap w={1} className={styles.row}>
          <Box w={[1, 1 / 2, 1 / 2]}><b>Short Interest</b></Box>
          <Box w={[1, 1 / 2, 1 / 2]}>
            <Flex justify='space-between'>
              <Box>
                <Locale type='int' placeholder='Not Available'>
                  {security.shortInterest}
                </Locale>
              </Box>
              <Box>
                {security.shortInterestChange !== undefined && <span>&nbsp;({security.shortInterestChange}%)&nbsp;</span>}
              </Box>
              <Box>
                {security.shortInterestDate && <Locale type='date'>{security.shortInterestDate}</Locale>}
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex wrap w={1} className={styles.row}>
          <Box w={[1, 1 / 2, 1 / 2]}><b>Significant Failures to Deliver</b></Box>
          <Box w={[1, 1 / 2, 1 / 2]}>{security.sigFailDeliver ? 'Yes' : 'No'}</Box>
        </Flex>
      </div>}

      {security.notes && <div>
        <Outline mode='invisible' spacing='large' />
        <List
          borders
          title='Security Notes'
          accent={accent}
          list={security.notes.map(note => {
            return { value: note }
          })} />
      </div>}
      <Outline mode='invisible' spacing='large' />
    </div>
  }
}

CompanyQuoteSecurity.propTypes = {
  company: PropTypes.string.isRequired,
  accent: PropTypes.string,
  info: PropTypes.object,
  navigate: PropTypes.func,
  location: PropTypes.object,
  context: PropTypes.object
}

export default withRouter(withContext(CompanyQuoteSecurity))
