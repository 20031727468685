import api from '../api'

export async function contact (formType, params) {
  console.log('Ummmm')
  const response = await api.request({
    method: 'post',
    otcAPI: true,
    nodeAPI: true,
    ignoreAuth: true,
    url: '/contact',
    data: {
      formType,
      ...params
    }
  })
  return response.data
}

export async function hasIq (symbol) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'data',
    url: `/company/hasIqAccount/${symbol}`
  })
  return response.data
}
