import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import HeadTitle from '../../components/HeadTitle'
import { withContext } from '../../AuthContext'
import { withRouter } from '../../components/WithRouter'
import LogIn from '../../components/LogIn'
import ForgotPassword from '../../components/ForgotPassword'
import RequireNewPassword from '../../components/RequireNewPassword'
import ResetPassword from '../../components/ResetPassword'
import ConfirmLogIn from '../../components/ConfirmLogIn'
import AuthorizeLogin from '../../components/AuthorizeLogin'
import { TextLink } from '@otcmarketsgroup/otcm-ui'
import styles from './AuthPage.module.scss'

class AuthPage extends React.PureComponent {
  componentDidMount () {
    if (this.props.context.isAuth === true) this.props.navigate('/dashboard')
  }

  componentDidUpdate (prevProps) {
    if (prevProps.context.isAuth !== this.props.context.isAuth) {
      if (this.props.context.isAuth === true) this.props.navigate('/dashboard')
    }
  }

  render () {
    const { authState } = this.props.context

    return (
      <div className={styles.container}>
        <HeadTitle title='OTC Markets Compliance UI' />
        <Link to='/' className={styles.logo}>
          <img src='/logos/otc-black.svg' alt='logo' />
        </Link>
        {authState === 'LOG_IN' && <LogIn />}

        {authState === 'NEW_PASSWORD_REQUIRED' && <RequireNewPassword />}

        {authState === 'SMS_MFA' && <ConfirmLogIn />}

        {authState === 'FORGOT_PASSWORD' && <ForgotPassword />}

        {authState === 'RESET_PASSWORD' && <ResetPassword />}

        {authState === 'AUTHORIZE_LOGIN' && <AuthorizeLogin />}

        <footer className={styles.footer}>
          <TextLink text='Terms' href='https://www.otcmarkets.com/terms-of-service' target='blank' />
          <TextLink text='Privacy' href='https://www.otcmarkets.com/privacy-statement' target='blank' />
          <Link to='/contact'>
            <TextLink text='Contact' target='blank' />
          </Link>
        </footer>
      </div>
    )
  }
}

AuthPage.propTypes = {
  context: PropTypes.shape({
    authState: PropTypes.string,
    isAuth: PropTypes.bool
  }),
  navigate: PropTypes.func.isRequired
}

export default withContext(withRouter(AuthPage))
