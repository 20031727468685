import HeadTitle from './HeadTitle'
// Uncomment the below to migrate to stateful component
// import HeadTitleContainer from './HeadTitleContainer'

export { HeadTitle }
export default HeadTitle

// Uncomment the below to migrate to stateful component
// export { HeadTitleContainer }
// export default { HeadTitleContainer }
