import React from 'react'
import PropTypes from 'prop-types'
import styles from './NewsWidget.module.scss'
import { template, fetch, expand } from '../../api/helper'
import { getDNSNews } from '../../api/otc/company/news'
import { getMediaCoverage } from '../../api/otc/news/news'
import { getDNSNewsUrl } from '../../utils/news-urls'
import { format } from '../../utils/locale'

import Loading from '../Loading'
import More from '../More'
import Accordion from '../Accordion'

class NewsWidget extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      dnsNews: template('records'),
      error: null
    }
  }

  componentDidMount () {
    this.loadData()
  }

  getFetchFunc = () => {
    return this.props.feed === 'media' ? getMediaCoverage : getDNSNews
  }

  loadData = () => {
    fetch(this, this.getFetchFunc(), 'dnsNews', {
      symbol: 'OTCM',
      page: 1,
      pageSize: this.props.feed === 'ir' ? 200 : 5
    }, data => {
      if (!data) data = {}
      if (data === '') data = {}
      if (!data.records) data.records = []

      if (this.props.feed === 'ir') {
        data.records = data.records.filter(article => article.title && (
          article.title.indexOf('Financial Results') !== -1 ||
          article.title.indexOf('Earnings Release and Conference Call') !== -1 ||
          article.title.indexOf('OTC Markets Group Inc. to Present') !== -1
        )).slice(0, 5)
      }

      if (this.props.feed === 'media') {
        data.records = this.formatMediaCoverage(data.records)
      } else {
        data.records = this.formatDNSNews(data.records)
      }

      return data
    })
  }

  formatDNSNews = (news) => {
    if (!news) return []
    return news.map(newsItem => {
      return {
        title: newsItem.title,
        subtitle: format(newsItem.releaseDate, '', 'date'),
        id: newsItem.id,
        to: getDNSNewsUrl('OTCM', newsItem)
      }
    })
  }

  formatMediaCoverage = (news) => {
    if (!news) return []
    return news.map(newsItem => {
      return {
        title: newsItem.title,
        subtitle: format(newsItem.releaseDate, '', 'date'),
        to: newsItem.url
      }
    })
  }

  render () {
    return (
      <div className={styles.newsWidget}>
        <Loading
          data={this.state.dnsNews.data.records}
          error={this.state.dnsNews.error}
          loaded={this.state.dnsNews.loaded}
          reloading={this.state.dnsNews.reloading}>
          <Accordion
            closed
            columnSplit={3 / 4}
            list={this.state.dnsNews.data.records} />
          {(this.props.feed === 'pr' || this.props.feed === 'media') && <More onClick={expand(this, this.getFetchFunc(), 'dnsNews')}
            disabled={this.state.dnsNews.expandEmpty} />}
        </Loading>
      </div>
    )
  }
}

NewsWidget.propTypes = {
  feed: PropTypes.oneOf(['pr', 'ir', 'media'])
}

NewsWidget.defaultProps = {
  feed: 'pr'
}

export default NewsWidget
