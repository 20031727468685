import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'grid-styled'
import classNames from 'classnames'
import styles from './Level2.module.scss'
import More from '../More'
import Outline from '../Outline'
import Loading from '../Loading'
import Table from '../Table'

const askColumns = [{
  name: 'mmId',
  header: 'MPID',
  tooltip: ({ mmIdDisplay }) => ({ text: mmIdDisplay })
}, {
  name: 'priceDisplay',
  header: 'Ask Price',
  align: 'right'
}, {
  name: 'size',
  header: 'Size',
  type: 'int'
}, {
  name: 'transTimeDisplay',
  header: 'Time',
  align: 'right'
}]

const bidColumns = [{
  name: 'mmId',
  header: 'MPID',
  tooltip: ({ mmIdDisplay }) => ({ text: mmIdDisplay })
}, {
  name: 'priceDisplay',
  header: 'Bid Price',
  align: 'right'
}, {
  name: 'size',
  header: 'Size',
  type: 'int'
}, {
  name: 'transTimeDisplay',
  header: 'Time',
  align: 'right'
}]

class Level2 extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      selectedTable: 'BID'
    }
  }

  onTableSwitch = (mode) => {
    return () => {
      this.setState({ selectedTable: mode })
    }
  }

  render () {
    const { loaded, data, accent, symbol, max } = this.props
    return <div id='level-2' className={styles.montage}>
      <Outline mode='heading' accent={accent}>Real-Time Level 2 Quote</Outline>
      <Loading
        type='table'
        loaded={loaded}>
        <Flex w={1}>
          <Box w={5.75 / 12} className={classNames({
            [styles.tableSwitch]: true,
            [styles.active]: this.state.selectedTable === 'BID'
          })} onClick={this.onTableSwitch('BID')}>
            Bid
          </Box>
          <Box w={0.5 / 12} />
          <Box w={5.75 / 12} className={classNames({
            [styles.tableSwitch]: true,
            [styles.active]: this.state.selectedTable === 'ASK'
          })} onClick={this.onTableSwitch('ASK')}>
            Ask
          </Box>
        </Flex>
        <Flex wrap w={1}>
          <Box
            w={[1, 5.75 / 12, 5.75 / 12]}
            className={classNames({
              [styles.tableHidden]: this.state.selectedTable !== 'BID'
            })}>
            <Table
              columns={bidColumns}
              data={data.montageBuyList}
              showHeader
              max={max}
            />
          </Box>
          <Box w={[0, 0.5 / 12, 0.5 / 12]} />
          <Box
            w={[1, 5.75 / 12, 5.75 / 12]}
            className={classNames({
              [styles.tableHidden]: this.state.selectedTable !== 'ASK'
            })}>
            <Table
              columns={askColumns}
              data={data.montageSellList}
              showHeader
              max={max}
            />
          </Box>
          {max && (data.montageBuyList.length >= max) && <More to={`/stock/${symbol}/quote#level-2`} />}
        </Flex>
      </Loading>
    </div>
  }
}

Level2.propTypes = {
  symbol: PropTypes.string,
  loaded: PropTypes.bool,
  data: PropTypes.object,
  accent: PropTypes.string,
  max: PropTypes.number
}

export default Level2
