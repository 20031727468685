export function request (params) {
  let data = [
    {
      calcTime: '2017-01-22T07:37:20Z',
      dollarVolume: 1600187,
      isCaveatEmptor: false,
      item: 'neque',
      itemDesc: 'ante ipsum primis in',
      numOfQuotes: 3.935,
      numOfTrades: 2.232,
      price: 43.84,
      pricePct: 0.609,
      symbol: 'UMH',
      tierId: 3,
      tierName: 'OO',
      volume: 98792
    },
    {
      calcTime: '2017-09-03T05:23:48Z',
      dollarVolume: 7749451,
      isCaveatEmptor: true,
      item: 'in',
      itemDesc: 'est',
      numOfQuotes: 2.895,
      numOfTrades: 0.925,
      price: 50.99,
      pricePct: 0.865,
      symbol: 'ESS',
      tierId: 1,
      tierName: 'DQ',
      volume: 97568
    },
    {
      calcTime: '2017-01-02T15:32:52Z',
      dollarVolume: 17820817,
      isCaveatEmptor: true,
      item: 'elit',
      itemDesc: 'duis aliquam convallis',
      numOfQuotes: 2.789,
      numOfTrades: 4.912,
      price: 46.19,
      pricePct: 0.12,
      symbol: 'GDV^G',
      tierId: 3,
      tierName: 'OO',
      volume: 99462
    },
    {
      calcTime: '2016-12-29T23:16:11Z',
      dollarVolume: 19591649,
      isCaveatEmptor: true,
      item: 'nulla',
      itemDesc: 'ut erat curabitur gravida',
      numOfQuotes: 3.433,
      numOfTrades: 3.423,
      price: 33.48,
      pricePct: 0.85,
      symbol: 'NXR',
      tierId: 3,
      tierName: 'OO',
      volume: 96589
    },
    {
      calcTime: '2016-12-03T14:30:08Z',
      dollarVolume: 15458726,
      isCaveatEmptor: true,
      item: 'convallis',
      itemDesc: 'erat volutpat',
      numOfQuotes: 0.092,
      numOfTrades: 4.228,
      price: 30.98,
      pricePct: 0.078,
      symbol: 'OSK',
      tierId: 0,
      tierName: 'QX',
      volume: 95174
    },
    {
      calcTime: '2016-09-30T01:37:58Z',
      dollarVolume: 24372006,
      isCaveatEmptor: true,
      item: 'blandit',
      itemDesc: 'nibh in hac habitasse',
      numOfQuotes: 2.27,
      numOfTrades: 4.389,
      price: 34.62,
      pricePct: 0.743,
      symbol: 'LXU',
      tierId: 1,
      tierName: 'DQ',
      volume: 95880
    },
    {
      calcTime: '2017-07-22T15:50:33Z',
      dollarVolume: 1305949,
      isCaveatEmptor: false,
      item: 'erat',
      itemDesc: 'faucibus orci luctus',
      numOfQuotes: 3.067,
      numOfTrades: 2.155,
      price: 11.62,
      pricePct: 0.968,
      symbol: 'CFO',
      tierId: 2,
      tierName: 'PS',
      volume: 99199
    },
    {
      calcTime: '2017-01-31T18:39:03Z',
      dollarVolume: 23832067,
      isCaveatEmptor: true,
      item: 'elementum',
      itemDesc: 'nisl nunc rhoncus dui',
      numOfQuotes: 1.157,
      numOfTrades: 1.329,
      price: 59.05,
      pricePct: 0.243,
      symbol: 'OTTW',
      tierId: 3,
      tierName: 'OO',
      volume: 97767
    },
    {
      calcTime: '2016-11-03T23:54:50Z',
      dollarVolume: 24951051,
      isCaveatEmptor: false,
      item: 'consequat',
      itemDesc: 'sed justo pellentesque viverra',
      numOfQuotes: 3.649,
      numOfTrades: 4.293,
      price: 58.01,
      pricePct: 0.017,
      symbol: 'DSM',
      tierId: 1,
      tierName: 'DQ',
      volume: 98015
    },
    {
      calcTime: '2016-11-07T09:53:43Z',
      dollarVolume: 11292183,
      isCaveatEmptor: true,
      item: 'amet',
      itemDesc: 'ante ipsum',
      numOfQuotes: 3.448,
      numOfTrades: 1.015,
      price: 38.5,
      pricePct: 0.338,
      symbol: 'DSWL',
      tierId: 0,
      tierName: 'QX',
      volume: 98409
    },
    {
      calcTime: '2017-08-06T11:37:58Z',
      dollarVolume: 12617520,
      isCaveatEmptor: true,
      item: 'at',
      itemDesc: 'praesent',
      numOfQuotes: 2.421,
      numOfTrades: 4.896,
      price: 37.13,
      pricePct: 0.984,
      symbol: 'WPZ',
      tierId: 1,
      tierName: 'DQ',
      volume: 97146
    },
    {
      calcTime: '2016-11-22T07:34:42Z',
      dollarVolume: 20071851,
      isCaveatEmptor: false,
      item: 'platea',
      itemDesc: 'vivamus metus arcu adipiscing',
      numOfQuotes: 3.151,
      numOfTrades: 4.141,
      price: 38.48,
      pricePct: 0.427,
      symbol: 'PI',
      tierId: 3,
      tierName: 'OO',
      volume: 99345
    },
    {
      calcTime: '2016-09-12T23:13:21Z',
      dollarVolume: 14954151,
      isCaveatEmptor: false,
      item: 'non',
      itemDesc: 'quam pharetra magna ac',
      numOfQuotes: 0.288,
      numOfTrades: 0.735,
      price: 55.62,
      pricePct: 0.321,
      symbol: 'MSD',
      tierId: 1,
      tierName: 'DQ',
      volume: 95141
    },
    {
      calcTime: '2017-08-17T22:48:18Z',
      dollarVolume: 15408504,
      isCaveatEmptor: true,
      item: 'sapien',
      itemDesc: 'ut',
      numOfQuotes: 0.891,
      numOfTrades: 2.211,
      price: 54.6,
      pricePct: 0.857,
      symbol: 'EFC',
      tierId: 0,
      tierName: 'QX',
      volume: 99596
    },
    {
      calcTime: '2017-08-24T20:07:39Z',
      dollarVolume: 12913455,
      isCaveatEmptor: true,
      item: 'justo',
      itemDesc: 'consectetuer',
      numOfQuotes: 3.58,
      numOfTrades: 0.086,
      price: 66.89,
      pricePct: 0.464,
      symbol: 'DAL',
      tierId: 1,
      tierName: 'DQ',
      volume: 97157
    },
    {
      calcTime: '2017-03-21T05:31:46Z',
      dollarVolume: 18603753,
      isCaveatEmptor: true,
      item: 'sit',
      itemDesc: 'vestibulum ante',
      numOfQuotes: 4.736,
      numOfTrades: 2.82,
      price: 1.67,
      pricePct: 0.784,
      symbol: 'MOD',
      tierId: 1,
      tierName: 'DQ',
      volume: 98267
    },
    {
      calcTime: '2017-02-25T12:17:44Z',
      dollarVolume: 20732025,
      isCaveatEmptor: true,
      item: 'ultrices',
      itemDesc: 'justo etiam pretium iaculis',
      numOfQuotes: 2.881,
      numOfTrades: 4.683,
      price: 10.43,
      pricePct: 0.213,
      symbol: 'CEO',
      tierId: 2,
      tierName: 'PS',
      volume: 95753
    },
    {
      calcTime: '2016-11-06T12:54:23Z',
      dollarVolume: 18251992,
      isCaveatEmptor: true,
      item: 'et',
      itemDesc: 'integer a nibh in',
      numOfQuotes: 3.874,
      numOfTrades: 3.043,
      price: 33.91,
      pricePct: 0.234,
      symbol: 'CHSCO',
      tierId: 2,
      tierName: 'PS',
      volume: 98813
    },
    {
      calcTime: '2016-12-03T05:02:25Z',
      dollarVolume: 527836,
      isCaveatEmptor: false,
      item: 'sapien',
      itemDesc: 'ipsum dolor',
      numOfQuotes: 1.63,
      numOfTrades: 0.732,
      price: 9.8,
      pricePct: 0.232,
      symbol: 'CALD',
      tierId: 1,
      tierName: 'DQ',
      volume: 97282
    },
    {
      calcTime: '2017-03-22T18:53:11Z',
      dollarVolume: 13295206,
      isCaveatEmptor: false,
      item: 'maecenas',
      itemDesc: 'tincidunt',
      numOfQuotes: 1.466,
      numOfTrades: 4.672,
      price: 20.11,
      pricePct: 0.297,
      symbol: 'IVZ',
      tierId: 3,
      tierName: 'OO',
      volume: 96035
    },
    {
      calcTime: '2016-11-28T09:49:45Z',
      dollarVolume: 8459008,
      isCaveatEmptor: true,
      item: 'vel',
      itemDesc: 'donec semper sapien a',
      numOfQuotes: 0.64,
      numOfTrades: 1.704,
      price: 60.08,
      pricePct: 0.584,
      symbol: 'ENIA',
      tierId: 1,
      tierName: 'DQ',
      volume: 96753
    },
    {
      calcTime: '2016-11-02T16:19:43Z',
      dollarVolume: 11340455,
      isCaveatEmptor: false,
      item: 'ut',
      itemDesc: 'congue vivamus metus arcu',
      numOfQuotes: 1.763,
      numOfTrades: 1.631,
      price: 30.18,
      pricePct: 0.539,
      symbol: 'AMTD',
      tierId: 0,
      tierName: 'QX',
      volume: 95779
    },
    {
      calcTime: '2017-07-21T14:05:38Z',
      dollarVolume: 22473797,
      isCaveatEmptor: true,
      item: 'tincidunt',
      itemDesc: 'lobortis ligula',
      numOfQuotes: 4.661,
      numOfTrades: 3.386,
      price: 34.22,
      pricePct: 0.643,
      symbol: 'NYMT',
      tierId: 1,
      tierName: 'DQ',
      volume: 95143
    },
    {
      calcTime: '2016-10-07T16:46:46Z',
      dollarVolume: 16931481,
      isCaveatEmptor: false,
      item: 'odio',
      itemDesc: 'nulla ac enim',
      numOfQuotes: 1.424,
      numOfTrades: 1.079,
      price: 36.06,
      pricePct: 0.313,
      symbol: 'APH',
      tierId: 0,
      tierName: 'QX',
      volume: 98683
    },
    {
      calcTime: '2016-10-14T19:27:56Z',
      dollarVolume: 6507291,
      isCaveatEmptor: false,
      item: 'sed',
      itemDesc: 'et',
      numOfQuotes: 4.076,
      numOfTrades: 0.087,
      price: 7.24,
      pricePct: 0.035,
      symbol: 'CTSO',
      tierId: 3,
      tierName: 'OO',
      volume: 97471
    },
    {
      calcTime: '2017-09-03T10:57:39Z',
      dollarVolume: 9071725,
      isCaveatEmptor: true,
      item: 'convallis',
      itemDesc: 'posuere cubilia',
      numOfQuotes: 4.514,
      numOfTrades: 0.066,
      price: 38.38,
      pricePct: 0.988,
      symbol: 'CCLP',
      tierId: 3,
      tierName: 'OO',
      volume: 97444
    },
    {
      calcTime: '2017-07-20T08:57:12Z',
      dollarVolume: 5893568,
      isCaveatEmptor: true,
      item: 'nisl',
      itemDesc: 'tristique tortor',
      numOfQuotes: 4.356,
      numOfTrades: 1.501,
      price: 14.51,
      pricePct: 0.457,
      symbol: 'CNI',
      tierId: 1,
      tierName: 'DQ',
      volume: 96763
    },
    {
      calcTime: '2017-09-07T12:28:21Z',
      dollarVolume: 17782739,
      isCaveatEmptor: false,
      item: 'pellentesque',
      itemDesc: 'ipsum',
      numOfQuotes: 2.181,
      numOfTrades: 3.852,
      price: 37.36,
      pricePct: 0.218,
      symbol: 'STLR',
      tierId: 3,
      tierName: 'OO',
      volume: 96338
    },
    {
      calcTime: '2017-05-16T02:16:32Z',
      dollarVolume: 11422681,
      isCaveatEmptor: true,
      item: 'tincidunt',
      itemDesc: 'mollis molestie lorem quisque',
      numOfQuotes: 4.027,
      numOfTrades: 3.087,
      price: 26.1,
      pricePct: 0.042,
      symbol: 'WLDN',
      tierId: 1,
      tierName: 'DQ',
      volume: 97163
    },
    {
      calcTime: '2017-08-08T12:51:51Z',
      dollarVolume: 13710198,
      isCaveatEmptor: false,
      item: 'in',
      itemDesc: 'interdum in ante',
      numOfQuotes: 3.515,
      numOfTrades: 4.567,
      price: 8.92,
      pricePct: 0.164,
      symbol: 'NEFF',
      tierId: 2,
      tierName: 'PS',
      volume: 98275
    },
    {
      calcTime: '2016-10-09T15:39:08Z',
      dollarVolume: 23235446,
      isCaveatEmptor: false,
      item: 'etiam',
      itemDesc: 'tempus vivamus in',
      numOfQuotes: 2.195,
      numOfTrades: 4.958,
      price: 20.4,
      pricePct: 0.484,
      symbol: 'ASND',
      tierId: 1,
      tierName: 'DQ',
      volume: 96415
    },
    {
      calcTime: '2017-02-28T12:42:09Z',
      dollarVolume: 13175877,
      isCaveatEmptor: true,
      item: 'pretium',
      itemDesc: 'donec',
      numOfQuotes: 3.403,
      numOfTrades: 0.662,
      price: 18.27,
      pricePct: 0.476,
      symbol: 'SBAC',
      tierId: 2,
      tierName: 'PS',
      volume: 97796
    },
    {
      calcTime: '2016-09-25T19:49:29Z',
      dollarVolume: 21547404,
      isCaveatEmptor: true,
      item: 'potenti',
      itemDesc: 'curae',
      numOfQuotes: 3.531,
      numOfTrades: 1.466,
      price: 63.85,
      pricePct: 0.5,
      symbol: 'MTBC',
      tierId: 1,
      tierName: 'DQ',
      volume: 98659
    },
    {
      calcTime: '2017-09-08T06:00:06Z',
      dollarVolume: 24698587,
      isCaveatEmptor: true,
      item: 'neque',
      itemDesc: 'ut nulla sed',
      numOfQuotes: 4.872,
      numOfTrades: 3.999,
      price: 1.71,
      pricePct: 0.39,
      symbol: 'ANDAU',
      tierId: 0,
      tierName: 'QX',
      volume: 96188
    },
    {
      calcTime: '2017-07-19T08:03:13Z',
      dollarVolume: 20468318,
      isCaveatEmptor: false,
      item: 'scelerisque',
      itemDesc: 'aliquam quis',
      numOfQuotes: 2.557,
      numOfTrades: 2.789,
      price: 51.16,
      pricePct: 0.691,
      symbol: 'CDXC',
      tierId: 0,
      tierName: 'QX',
      volume: 95981
    },
    {
      calcTime: '2017-03-12T02:52:00Z',
      dollarVolume: 19774151,
      isCaveatEmptor: false,
      item: 'nullam',
      itemDesc: 'nunc commodo placerat',
      numOfQuotes: 4.346,
      numOfTrades: 3.236,
      price: 44.61,
      pricePct: 0.757,
      symbol: 'AGNC',
      tierId: 1,
      tierName: 'DQ',
      volume: 95374
    },
    {
      calcTime: '2017-04-15T08:37:47Z',
      dollarVolume: 917816,
      isCaveatEmptor: true,
      item: 'eros',
      itemDesc: 'et tempus semper',
      numOfQuotes: 4.135,
      numOfTrades: 2.015,
      price: 30.39,
      pricePct: 0.91,
      symbol: 'CMD',
      tierId: 3,
      tierName: 'OO',
      volume: 95090
    },
    {
      calcTime: '2016-09-20T20:36:22Z',
      dollarVolume: 15365266,
      isCaveatEmptor: true,
      item: 'mi',
      itemDesc: 'eget massa tempor',
      numOfQuotes: 4.785,
      numOfTrades: 4.809,
      price: 5.08,
      pricePct: 0.745,
      symbol: 'PUK^',
      tierId: 3,
      tierName: 'OO',
      volume: 98340
    },
    {
      calcTime: '2016-10-06T18:54:48Z',
      dollarVolume: 9809323,
      isCaveatEmptor: false,
      item: 'aliquam',
      itemDesc: 'rutrum neque',
      numOfQuotes: 1.356,
      numOfTrades: 1.752,
      price: 15.1,
      pricePct: 0.719,
      symbol: 'GAB^H',
      tierId: 3,
      tierName: 'OO',
      volume: 95776
    },
    {
      calcTime: '2017-06-04T15:50:33Z',
      dollarVolume: 21526363,
      isCaveatEmptor: false,
      item: 'eleifend',
      itemDesc: 'ac lobortis vel dapibus',
      numOfQuotes: 3.372,
      numOfTrades: 4.546,
      price: 27.15,
      pricePct: 0.475,
      symbol: 'SYPR',
      tierId: 1,
      tierName: 'DQ',
      volume: 98125
    },
    {
      calcTime: '2017-02-10T23:41:52Z',
      dollarVolume: 14924963,
      isCaveatEmptor: false,
      item: 'arcu',
      itemDesc: 'vivamus metus arcu adipiscing',
      numOfQuotes: 1.174,
      numOfTrades: 2.676,
      price: 34.47,
      pricePct: 0.777,
      symbol: 'LSCC',
      tierId: 3,
      tierName: 'OO',
      volume: 96342
    },
    {
      calcTime: '2016-12-17T06:52:01Z',
      dollarVolume: 2310505,
      isCaveatEmptor: true,
      item: 'sed',
      itemDesc: 'augue',
      numOfQuotes: 1.103,
      numOfTrades: 3.665,
      price: 35.92,
      pricePct: 0.227,
      symbol: 'SUNW',
      tierId: 0,
      tierName: 'QX',
      volume: 95075
    },
    {
      calcTime: '2017-02-06T19:35:07Z',
      dollarVolume: 16255208,
      isCaveatEmptor: false,
      item: 'volutpat',
      itemDesc: 'volutpat dui',
      numOfQuotes: 2.034,
      numOfTrades: 4.945,
      price: 45.53,
      pricePct: 0.287,
      symbol: 'KMF',
      tierId: 3,
      tierName: 'OO',
      volume: 96811
    },
    {
      calcTime: '2017-03-21T15:45:59Z',
      dollarVolume: 18204777,
      isCaveatEmptor: false,
      item: 'in',
      itemDesc: 'turpis enim blandit mi',
      numOfQuotes: 1.155,
      numOfTrades: 3.826,
      price: 62.22,
      pricePct: 0.129,
      symbol: 'MCFT',
      tierId: 2,
      tierName: 'PS',
      volume: 97935
    },
    {
      calcTime: '2017-05-16T17:43:03Z',
      dollarVolume: 8371392,
      isCaveatEmptor: true,
      item: 'cubilia',
      itemDesc: 'lectus aliquam',
      numOfQuotes: 0.666,
      numOfTrades: 3.822,
      price: 63.42,
      pricePct: 0.085,
      symbol: 'MGLN',
      tierId: 0,
      tierName: 'QX',
      volume: 95375
    },
    {
      calcTime: '2017-02-12T16:15:16Z',
      dollarVolume: 17595776,
      isCaveatEmptor: false,
      item: 'dapibus',
      itemDesc: 'mauris lacinia sapien',
      numOfQuotes: 4.275,
      numOfTrades: 2.471,
      price: 19.93,
      pricePct: 0.501,
      symbol: 'SAJA',
      tierId: 2,
      tierName: 'PS',
      volume: 96234
    },
    {
      calcTime: '2017-01-30T22:36:16Z',
      dollarVolume: 23643808,
      isCaveatEmptor: true,
      item: 'hac',
      itemDesc: 'sociis',
      numOfQuotes: 3.256,
      numOfTrades: 3.65,
      price: 17.9,
      pricePct: 0.547,
      symbol: 'GPRE',
      tierId: 1,
      tierName: 'DQ',
      volume: 95578
    },
    {
      calcTime: '2017-04-06T06:42:44Z',
      dollarVolume: 20116646,
      isCaveatEmptor: false,
      item: 'nisi',
      itemDesc: 'ut suscipit a feugiat',
      numOfQuotes: 0.903,
      numOfTrades: 0.992,
      price: 14.82,
      pricePct: 0.087,
      symbol: 'BKHU',
      tierId: 3,
      tierName: 'OO',
      volume: 99824
    },
    {
      calcTime: '2017-09-08T15:36:01Z',
      dollarVolume: 7008931,
      isCaveatEmptor: false,
      item: 'mauris',
      itemDesc: 'in congue etiam',
      numOfQuotes: 1.999,
      numOfTrades: 1.835,
      price: 23.29,
      pricePct: 0.341,
      symbol: 'GTHX',
      tierId: 1,
      tierName: 'DQ',
      volume: 98940
    },
    {
      calcTime: '2017-08-23T04:45:46Z',
      dollarVolume: 24486753,
      isCaveatEmptor: true,
      item: 'interdum',
      itemDesc: 'dui vel',
      numOfQuotes: 2.419,
      numOfTrades: 4.98,
      price: 29.46,
      pricePct: 0.344,
      symbol: 'LMHB',
      tierId: 3,
      tierName: 'OO',
      volume: 96478
    }
  ]

  // Filter Tier Group
  if (params.tierGroup && params.tierGroup.toUpperCase() !== 'ALL') {
    data = data.filter(d => d.tierName === params.tierGroup)
  }

  if (params.minPrice) {
    data = data.filter(d => d.price > parseFloat(params.minPrice))
  }

  const length = data.length

  // Handle Page
  let page = 1
  if (params.page) page = params.page
  // Page Size
  if (params.pageSize) {
    data = data.slice(page === 1 ? 0 : (page - 1) * params.pageSize,
      page === 1 ? params.pageSize : ((page - 1) * params.pageSize) + params.pageSize)
  }
  return {
    totalRecords: length,
    pages: Math.ceil(length / params.pageSize),
    pageSize: params.pageSize,
    records: data,
    sortOn: params.sortOn,
    isRealTime: false,
    bySymbol: true
  }
}
