import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './Footer.module.scss'
import { Flex, Box } from 'grid-styled'
import ExternalLink from '../ExternalLink'
import { withRouter } from '../WithRouter'
import { copyrightLinks } from '../../menus'

import BackToTop from '../BackToTop'

const Footer = ({ location, isAuth }) =>
  <div id='otc_footer' className={classnames(styles.wrapper, {
    [styles.preloginWrapper]: !isAuth
  })}>
    <BackToTop footerId='otc_footer' />
    <div className={styles.footer}>
      <Flex column align='center' className={styles.container}>
        <Box className={styles.copyright}>
          <a href='https://www.otcmarkets.com' target='_blank' rel='noopener noreferrer'>
            &copy; {new Date().getFullYear()} OTC Markets Group Inc.
          </a>
          {copyrightLinks.map(({ link, href, to }, i) =>
            <ExternalLink key={i} href={href} to={to} className={location && location.pathname && location.pathname === to ? styles.active : null}>
              {link}
            </ExternalLink>
          )}
        </Box>
      </Flex>
    </div>
  </div>

Footer.propTypes = {
  isAuth: PropTypes.bool,
  location: PropTypes.object.isRequired
}

export default withRouter(Footer)
