import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from '../../ExternalLink'
import styles from './styles.module.scss'

const TableItemDownloads = (props) => {
  return <span className={styles.row}>
    {props.data && props.data.paperOnly === true && <i>Paper only</i>}
    {props.data && props.data.paperOnly !== true && props.options.buttons.map(button => {
      const Icon = button.icon
      let strLinkTo = null

      if (button.linkTo) {
        strLinkTo = typeof button.linkTo === 'string'
          ? button.linkTo
          : button.linkTo(props.data)
      }

      if (button.show && button.show(props.data) === false) return null

      return <ExternalLink href={strLinkTo} key={button.name} external>
        <span className={styles.button}>
          {button.icon && <Icon />}
          {button.name}
        </span>
      </ExternalLink>
    })}
  </span>
}

TableItemDownloads.propTypes = {
  options: PropTypes.object,
  data: PropTypes.any
}

export default TableItemDownloads
