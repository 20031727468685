import CompanyQuoteOverview from '../components/CompanyQuoteOverview'
import CompanyQuoteQuote from '../components/CompanyQuoteQuote'
import CompanyQuoteProfile from '../components/CompanyQuoteProfile'
import CompanyQuoteSecurity from '../components/CompanyQuoteSecurity'
import CompanyQuoteNews from '../components/CompanyQuoteNews'
import CompanyQuoteFinancials from '../components/CompanyQuoteFinancials'
import CompanyQuoteDisclosure from '../components/CompanyQuoteDisclosure'
import CompanyQuoteResearch from '../components/CompanyQuoteResearch'
import CompanyQuoteBlueSky from '../components/CompanyQuoteBlueSky'
import CompanyQuote211 from '../components/CompanyQuote211/CompanyQuote211'

export const companyQuoteTabs = [
  {
    name: 'Overview',
    tab: 'overview',
    component: CompanyQuoteOverview,
    showStock: true,
    showFixedIncome: false
  },
  {
    name: 'Quote',
    tab: 'quote',
    component: CompanyQuoteQuote,
    showStock: true,
    showFixedIncome: false
  },
  {
    name: 'Company Profile',
    tab: 'profile',
    component: CompanyQuoteProfile,
    showStock: true,
    showFixedIncome: false
  },
  {
    name: 'Security Details',
    tab: 'security',
    component: CompanyQuoteSecurity,
    showStock: true,
    showFixedIncome: false
  },
  {
    name: 'News',
    tab: 'news',
    component: CompanyQuoteNews,
    showStock: true,
    showFixedIncome: false
  },
  {
    name: 'Financials',
    tab: 'financials',
    component: CompanyQuoteFinancials,
    showStock: true,
    showFixedIncome: false
  },
  {
    name: 'Disclosure',
    tab: 'disclosure',
    component: CompanyQuoteDisclosure,
    showStock: true,
    showFixedIncome: false
  },
  {
    name: 'Research',
    tab: 'research',
    component: CompanyQuoteResearch,
    showStock: true,
    showFixedIncome: false
  },
  {
    name: 'Blue Sky',
    tab: 'blue-sky',
    component: CompanyQuoteBlueSky,
    showStock: true,
    showFixedIncome: true
  },
  {
    name: '211',
    tab: 'fi-211',
    component: CompanyQuote211,
    flag: 'NEW!',
    showStock: false,
    showFixedIncome: true
  }
]
