import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { initAnalytics } from './analytics'
// import third-party CSS
import 'slick-carousel/slick/slick.css'
// import layout styles first to keep CSS order in production builds
import './components/Layout/Layout.module.scss'
import { AuthProvider } from './AuthContext'
import { CompanyQuote211ContextProvider } from './CompanyQuote211.context'
// import layout component and routes
import Layout from './components/Layout'
import ScrollToTop from './components/ScrollToTop'

// Initalize analytics
initAnalytics()
const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <CompanyQuote211ContextProvider>
          <Layout>
            {/* Ensures page scrolls to top on navigation changes */}
          </Layout>
        </CompanyQuote211ContextProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
