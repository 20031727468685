import React from 'react'
import PropTypes from 'prop-types'
import styles from './CompanyQuoteMenu.module.scss'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Flex, Box } from 'grid-styled'

class CompanyQuoteMenu extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      open: false
    }
  }

  onClick = () => {
    this.setState(state => ({
      open: !this.state.open
    }))
  }

  render () {
    const { items, activeTab, company, accent, isFixedIncome, isLoaded } = this.props
    return <div style={{ position: 'relative' }}>
      <Flex justify='flex-start' className={classNames(styles.companyQuoteMenu, styles.mobileMenuItem)}>
        <div className={styles.scrollIndicators}>
          <div className={styles.featherEdge} />
          <div className={styles.featherEdge} />
        </div>
        {isLoaded && items.map(({ name, tab, flag, showFixedIncome, showStock }) => {
          const fixedIncomeTabs = (isFixedIncome && showFixedIncome)
          const linkPath = isFixedIncome ? 'fixed-income' : 'stock'
          const showTab = isFixedIncome ? showFixedIncome : showStock
          return (showTab) && <Box flex='1 1 auto' key={tab} className={classNames(styles.tab, styles[accent], {
            [styles.active]: tab === activeTab
          })}>
            <Link to={`/${linkPath}/${company}/${tab}`}>{name}</Link>
            {flag && <span className={classNames(styles.newFlag, { [styles.fixedIncomeOnlyFlag]: fixedIncomeTabs })}>{flag}</span>}
          </Box>
        }
        )}
      </Flex>
    </div>
  }
}

CompanyQuoteMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    tab: PropTypes.string.isRequired
  })).isRequired,
  accent: PropTypes.string,
  activeTab: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  isFixedIncome: PropTypes.bool,
  isLoaded: PropTypes.bool
}

export default CompanyQuoteMenu
