export const dynamicSort = function (property, type, sortDir) {
  property = property.toString()

  return function (a, b) {
    const aProperty = a[property]
    const bProperty = b[property]

    if (type === 'number') {
      if (sortDir === 'DESC') return bProperty - aProperty
      else return aProperty - bProperty
    }
    if (type === 'date') {
      if (sortDir === 'DESC') return new Date(bProperty) - new Date(aProperty)
      else return new Date(aProperty) - new Date(bProperty)
    }
    if (type === 'string') {
      if (sortDir === 'DESC') {
        if (!a[property]) return -1
        if (!b[property]) return 0
        return b[property].localeCompare(a[property])
      } else {
        if (!a[property]) return 0
        if (!b[property]) return -1
        return a[property].localeCompare(b[property])
      }
    }
  }
}
