export function request (params) {
  let data = [
    {
      dollarVolume: 6291542,
      mpid: 'CEMP',
      name: 'Cempra, Inc.',
      shareVolume: 98675,
      trades: 7406,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 3146300,
      mpid: 'HYI',
      name: 'Western Asset High Yield Defined Opportunity Fund Inc.',
      shareVolume: 96372,
      trades: 11541,
      tierName: 'DQ',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 20290649,
      mpid: 'OII',
      name: 'Oceaneering International, Inc.',
      shareVolume: 99871,
      trades: 16671,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 1649129,
      mpid: 'CTB',
      name: 'Cooper Tire & Rubber Company',
      shareVolume: 97683,
      trades: 6887,
      tierName: 'PS',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 19006161,
      mpid: 'EDN',
      name: 'Empresa Distribuidora Y Comercializadora Norte S.A. (Edenor)',
      shareVolume: 98983,
      trades: 16016,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 7443553,
      mpid: 'CGBD',
      name: 'TCG BDC, Inc.',
      shareVolume: 97773,
      trades: 2896,
      tierName: 'DQ',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 19158267,
      mpid: 'COHU',
      name: 'Cohu, Inc.',
      shareVolume: 95191,
      trades: 17787,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 20520243,
      mpid: 'WSBC',
      name: 'WesBanco, Inc.',
      shareVolume: 98606,
      trades: 13932,
      tierName: 'PS',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 14435994,
      mpid: 'AMG',
      name: 'Affiliated Managers Group, Inc.',
      shareVolume: 96664,
      trades: 9965,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 19528546,
      mpid: 'DMO',
      name: 'Western Asset Mortgage Defined Opportunity Fund Inc',
      shareVolume: 97915,
      trades: 19219,
      tierName: 'PS',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 21377225,
      mpid: 'AI',
      name: 'Arlington Asset Investment Corp',
      shareVolume: 99929,
      trades: 12700,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 22072120,
      mpid: 'VONE',
      name: 'Vanguard Russell 1000 ETF',
      shareVolume: 97261,
      trades: 1257,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 663118,
      mpid: 'VBLT',
      name: 'Vascular Biogenics Ltd.',
      shareVolume: 95914,
      trades: 15143,
      tierName: 'PS',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 11434233,
      mpid: 'AGM',
      name: 'Federal Agricultural Mortgage Corporation',
      shareVolume: 97075,
      trades: 18651,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 3246556,
      mpid: 'ABR^A',
      name: 'Arbor Realty Trust',
      shareVolume: 95024,
      trades: 17244,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 14868149,
      mpid: 'ASPN',
      name: 'Aspen Aerogels, Inc.',
      shareVolume: 95983,
      trades: 835,
      tierName: 'PS',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 8100846,
      mpid: 'LHO',
      name: 'LaSalle Hotel Properties',
      shareVolume: 98332,
      trades: 6851,
      tierName: 'PS',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 22235270,
      mpid: 'TPIV',
      name: 'TapImmune Inc.',
      shareVolume: 99292,
      trades: 3057,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 15640237,
      mpid: 'SPRT',
      name: 'support.com, Inc.',
      shareVolume: 96066,
      trades: 5221,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 23296603,
      mpid: 'MATW',
      name: 'Matthews International Corporation',
      shareVolume: 98706,
      trades: 1472,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 1039540,
      mpid: 'WSFS',
      name: 'WSFS Financial Corporation',
      shareVolume: 97271,
      trades: 9755,
      tierName: 'DQ',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 20346257,
      mpid: 'ENLC',
      name: 'EnLink Midstream, LLC',
      shareVolume: 99210,
      trades: 7433,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 13000508,
      mpid: 'PESI',
      name: 'Perma-Fix Environmental Services, Inc.',
      shareVolume: 99152,
      trades: 13563,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 7622222,
      mpid: 'LPSN',
      name: 'LivePerson, Inc.',
      shareVolume: 97688,
      trades: 10963,
      tierName: 'PS',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 19759639,
      mpid: 'RILYL',
      name: 'B. Riley Financial, Inc.',
      shareVolume: 99519,
      trades: 10084,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 21794932,
      mpid: 'AMTD',
      name: 'TD Ameritrade Holding Corporation',
      shareVolume: 98217,
      trades: 15973,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 14613297,
      mpid: 'NTX',
      name: 'Nuveen Texas Quality Municipal Income Fund',
      shareVolume: 97246,
      trades: 16314,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 17399895,
      mpid: 'PTEN',
      name: 'Patterson-UTI Energy, Inc.',
      shareVolume: 97836,
      trades: 6093,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 5029479,
      mpid: 'DS',
      name: 'Drive Shack Inc.',
      shareVolume: 98970,
      trades: 12012,
      tierName: 'DQ',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 20085330,
      mpid: 'VER',
      name: 'VEREIT Inc.',
      shareVolume: 97924,
      trades: 19916,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 6840928,
      mpid: 'CEN',
      name: 'Center Coast MLP & Infrastructure Fund',
      shareVolume: 98136,
      trades: 3341,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 9388646,
      mpid: 'AGC',
      name: 'Advent Claymore Convertible Securities and Income Fund II',
      shareVolume: 97859,
      trades: 3421,
      tierName: 'DQ',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 2071736,
      mpid: 'FOGO',
      name: 'Fogo de Chao, Inc.',
      shareVolume: 99154,
      trades: 18068,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 2480662,
      mpid: 'RAI',
      name: 'Reynolds American Inc',
      shareVolume: 97916,
      trades: 3440,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 24031352,
      mpid: 'NHTC',
      name: 'Natural Health Trends Corp.',
      shareVolume: 97885,
      trades: 4973,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 22891061,
      mpid: 'MNK',
      name: 'Mallinckrodt plc',
      shareVolume: 96865,
      trades: 13704,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 14818661,
      mpid: 'ATNI',
      name: 'ATN International, Inc.',
      shareVolume: 99600,
      trades: 7588,
      tierName: 'PS',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 9741599,
      mpid: 'IONS',
      name: 'Ionis Pharmaceuticals, Inc.',
      shareVolume: 99557,
      trades: 17364,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 15580321,
      mpid: 'MYOS',
      name: 'MYOS RENS Technology Inc.',
      shareVolume: 97571,
      trades: 6530,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 17489579,
      mpid: 'NVMI',
      name: 'Nova Measuring Instruments Ltd.',
      shareVolume: 95704,
      trades: 7268,
      tierName: 'PS',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 7862583,
      mpid: 'GAINN',
      name: 'Gladstone Investment Corporation',
      shareVolume: 97666,
      trades: 14662,
      tierName: 'PS',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 17548456,
      mpid: 'IDCC',
      name: 'InterDigital, Inc.',
      shareVolume: 97176,
      trades: 8668,
      tierName: 'DQ',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 548008,
      mpid: 'EPR^E',
      name: 'EPR Properties',
      shareVolume: 95630,
      trades: 18772,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 16411737,
      mpid: 'CMO',
      name: 'Capstead Mortgage Corporation',
      shareVolume: 97632,
      trades: 13924,
      tierName: 'DQ',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 5044982,
      mpid: 'TSN',
      name: 'Tyson Foods, Inc.',
      shareVolume: 98528,
      trades: 3395,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 1294671,
      mpid: 'ESEA',
      name: 'Euroseas Ltd.',
      shareVolume: 99225,
      trades: 18362,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 10026890,
      mpid: 'IO',
      name: 'Ion Geophysical Corporation',
      shareVolume: 98610,
      trades: 15647,
      tierName: 'QX',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 22247883,
      mpid: 'IMI',
      name: 'Intermolecular, Inc.',
      shareVolume: 97028,
      trades: 19166,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 24316022,
      mpid: 'BTZ',
      name: 'BlackRock Credit Allocation Income Trust',
      shareVolume: 96806,
      trades: 5227,
      tierName: 'OO',
      location: 'St. Louis, MO'
    },
    {
      dollarVolume: 20473737,
      mpid: 'STI.WS.A',
      name: 'SunTrust Banks, Inc.',
      shareVolume: 97630,
      trades: 7989,
      tierName: 'PS',
      location: 'St. Louis, MO'
    }
  ]
  // Filter Tier Group
  if (params.tierGroup && params.tierGroup.toUpperCase() !== 'ALL') {
    data = data.filter(d => d.tierName === params.tierGroup)
  }

  const length = data.length

  // Handle Page
  let page = 1
  if (params.page) page = params.page
  // Page Size
  if (params.pageSize) {
    data = data.slice(page === 1 ? 0 : (page - 1) * params.pageSize, page === 1 ? params.pageSize : ((page - 1) * params.pageSize) + params.pageSize)
  }
  return {
    totalRecords: length,
    pages: Math.ceil(length / params.pageSize),
    pageSize: params.pageSize,
    records: data,
    sortOn: params.sortOn,
    isRealTime: false,
    bySymbol: true
  }
}
