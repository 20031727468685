import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '../Tooltip'
import './LabelTip.scss'

const LabelTip = ({ title, tipText, icon, width, tooltipTitle, ...props }) => {
  return <Tooltip
    text={tipText}
    width={width}
    title={tooltipTitle}
    placement='right'>
    <label>{title} <img src={icon} alt={title} /> </label>
  </Tooltip>
}

LabelTip.propTypes = {
  title: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tipText: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string
}

export default LabelTip
