import React from 'react'
import PropTypes from 'prop-types'
import styles from './Breadcrumbs.module.scss'
import { withRouter } from '../WithRouter'
import { Link } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { useCurrentPattern } from '../../utils/useCurrentPattern'
import routes from '../../routes'

export const Breadcrumbs = ({ location, items }) => {
  const currentPattern = useCurrentPattern()
  // If items explicitly specified, render
  if (items) {
    return <ul className={styles.breadcrumbs}>
      <li />
      { items.map((item, i) => {
        if (item.slug) return <li key={i}><Link to={item.slug}>{item.title}</Link></li>
        return <li key={i}>{item.title || item}</li>
      }) }
    </ul>
  }

  // Get dynamic routes
  const dynamicRoutesMap = routes && routes.filter(route => route.breadcrumb, {})
  const breadcrumbs = useBreadcrumbs(dynamicRoutesMap)

  if (!dynamicRoutesMap.find(route => route.path === currentPattern)) return null

  return <ul className={styles.breadcrumbs}>
    {breadcrumbs.map(({ match, breadcrumb, ...props }, i) => {
      return (
      <li key={i}>{(location.pathname !== match.pathname) ? <Link to={match.pathname}>{breadcrumb}</Link> : breadcrumb}</li>
      )
    })}
  </ul>
}
Breadcrumbs.propTypes = {
  location: PropTypes.object,
  items: PropTypes.array
}

export default withRouter(Breadcrumbs)
