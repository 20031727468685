export function request (params, pagination) {
  switch (params.duration) {
    case 'annual':
      switch (params.symbol) {
        case 'GOOG':
          return []

        case 'OTCM':
          return [
            {
              periodEndDate: 'Dec 31, 2016',
              netIncome: '10528000',
              depreciation: '1,606',
              adjustmentsToNetIncome: '783',
              changesInLiabilities: '5,476',
              changesInAccountsReceivables: '(189)',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '359',
              cashFlowsFromOperatingActivities: '15,740',
              capitalExpenditures: '(415)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(415)',
              dividendsPaid: '(13,059)',
              salePurchaseOfStock: '(1,561)',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '404',
              cashFlowsFromFinancingActivities: '(14,216)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '1,109'
            },
            {
              periodEndDate: 'Dec 31, 2015',
              netIncome: '10258000',
              depreciation: '1,692',
              adjustmentsToNetIncome: '675',
              changesInLiabilities: '10,228',
              changesInAccountsReceivables: '(507)',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '(757)',
              cashFlowsFromOperatingActivities: '17,107',
              capitalExpenditures: '(940)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(940)',
              dividendsPaid: '(12,094)',
              salePurchaseOfStock: '(736)',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '316',
              cashFlowsFromFinancingActivities: '(12,514)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '3,653'
            },
            {
              periodEndDate: 'Dec 31, 2014',
              netIncome: '7890000',
              depreciation: '1,543',
              adjustmentsToNetIncome: '585',
              changesInLiabilities: '8,046',
              changesInAccountsReceivables: '(884)',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '(2,252)',
              cashFlowsFromOperatingActivities: '12,473',
              capitalExpenditures: '(1,582)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(1,582)',
              dividendsPaid: '(9,109)',
              salePurchaseOfStock: '(765)',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '319',
              cashFlowsFromFinancingActivities: '(9,555)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '1,336'
            },
            {
              periodEndDate: 'Dec 31, 2013',
              netIncome: '5631000',
              depreciation: '1,642',
              adjustmentsToNetIncome: '568',
              changesInLiabilities: '(1,918)',
              changesInAccountsReceivables: '1,413',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '1,479',
              cashFlowsFromOperatingActivities: '8,328',
              capitalExpenditures: '(477)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '5',
              cashFlowsFromInvestingActivities: '(472)',
              dividendsPaid: '(2,611)',
              salePurchaseOfStock: '33',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '47',
              cashFlowsFromFinancingActivities: '(2,531)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '5,325'
            }
          ]

        case 'VLKAY':
          return [
            {
              periodEndDate: 'Dec 31, 2014',
              netIncome: '13393000000',
              depreciation: '20,318,000',
              adjustmentsToNetIncome: '(6,275,000)',
              changesInLiabilities: '6,445,000',
              changesInAccountsReceivables: '-',
              changesInInventories: '(2,679,000)',
              changesInOtherOperatingActivities: '(48,001,000)',
              cashFlowsFromOperatingActivities: '13,050,000',
              capitalExpenditures: '-',
              investments: '(2,805,000)',
              otherCashFlowsFromInvestingActivities: '(20,302,000)',
              cashFlowsFromInvestingActivities: '(23,108,000)',
              dividendsPaid: '(2,374,000)',
              salePurchaseOfStock: '(1,940,000)',
              netBorrowings: '4,671,000',
              otherCashFlowsFromFinancingActivities: '5,264,000',
              cashFlowsFromFinancingActivities: '5,621,000',
              effectOfExchangeRate: '356,000',
              changeInCashAndCashEquivalents: '(4,082,000)'
            },
            {
              periodEndDate: 'Dec 31, 2013',
              netIncome: '12599000000',
              depreciation: '20,187,000',
              adjustmentsToNetIncome: '(3,931,000)',
              changesInLiabilities: '6,672,000',
              changesInAccountsReceivables: '-',
              changesInInventories: '(1,407,000)',
              changesInOtherOperatingActivities: '(43,989,000)',
              cashFlowsFromOperatingActivities: '17,355,000',
              capitalExpenditures: '-',
              investments: '(1,214,000)',
              otherCashFlowsFromInvestingActivities: '(22,058,000)',
              cashFlowsFromInvestingActivities: '(23,272,000)',
              dividendsPaid: '(2,548,000)',
              salePurchaseOfStock: '4,226,000',
              netBorrowings: '10,340,000',
              otherCashFlowsFromFinancingActivities: '344,000',
              cashFlowsFromFinancingActivities: '12,363,000',
              effectOfExchangeRate: '(637,000)',
              changeInCashAndCashEquivalents: '5,809,000'
            },
            {
              periodEndDate: 'Dec 31, 2012',
              netIncome: '28847000000',
              depreciation: '17,290,000',
              adjustmentsToNetIncome: '(24,538,000)',
              changesInLiabilities: '309,000',
              changesInAccountsReceivables: '-',
              changesInInventories: '606,000',
              changesInOtherOperatingActivities: '(27,535,000)',
              cashFlowsFromOperatingActivities: '9,503,000',
              capitalExpenditures: '-',
              investments: '(2,227,000)',
              otherCashFlowsFromInvestingActivities: '(23,461,000)',
              cashFlowsFromInvestingActivities: '(25,688,000)',
              dividendsPaid: '(2,206,000)',
              salePurchaseOfStock: '(73,000)',
              netBorrowings: '12,001,000',
              otherCashFlowsFromFinancingActivities: '8,353,000',
              cashFlowsFromFinancingActivities: '18,077,000',
              effectOfExchangeRate: '(186,000)',
              changeInCashAndCashEquivalents: '1,706,000'
            },
            {
              periodEndDate: 'Jan 01, 2012',
              netIncome: '20003000000',
              depreciation: '13,414,000',
              adjustmentsToNetIncome: '(13,527,000)',
              changesInLiabilities: '9,135,000',
              changesInAccountsReceivables: '-',
              changesInInventories: '(5,496,000)',
              changesInOtherOperatingActivities: '(34,307,000)',
              cashFlowsFromOperatingActivities: '11,034,000',
              capitalExpenditures: '(10,498,000)',
              investments: '(1,629,000)',
              otherCashFlowsFromInvestingActivities: '(12,060,000)',
              cashFlowsFromInvestingActivities: '(24,187,000)',
              dividendsPaid: '(1,643,000)',
              salePurchaseOfStock: '(431,000)',
              netBorrowings: '6,661,000',
              otherCashFlowsFromFinancingActivities: '6,208,000',
              cashFlowsFromFinancingActivities: '10,794,000',
              effectOfExchangeRate: '106,000',
              changeInCashAndCashEquivalents: '(2,252,000)'
            }
          ]
      }
      break

    case 'semi-annual':
      switch (params.symbol) {
        case 'GOOG':
          return []

        case 'OTCM':
          return [
            {
              periodEndDate: 'Jun 30, 2017',
              netIncome: '11854000',
              depreciation: '1,573',
              adjustmentsToNetIncome: '763',
              changesInLiabilities: '3,992',
              changesInAccountsReceivables: '(113)',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '(569)',
              cashFlowsFromOperatingActivities: '15,845',
              capitalExpenditures: '(548)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(630)',
              dividendsPaid: '(13,102)',
              salePurchaseOfStock: '(2,710)',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '119',
              cashFlowsFromFinancingActivities: '(15,891)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '(676)'
            },
            {
              periodEndDate: 'Jun 30, 2016',
              netIncome: '10841000',
              depreciation: '1,693',
              adjustmentsToNetIncome: '783',
              changesInLiabilities: '(900)',
              changesInAccountsReceivables: '590',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '1,306',
              cashFlowsFromOperatingActivities: '13,816',
              capitalExpenditures: '(501)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(501)',
              dividendsPaid: '(13,006)',
              salePurchaseOfStock: '(1,355)',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '594',
              cashFlowsFromFinancingActivities: '(14,008)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '(693)'
            },
            {
              periodEndDate: 'Jun 30, 2015',
              netIncome: '8853000',
              depreciation: '1,626',
              adjustmentsToNetIncome: '562',
              changesInLiabilities: '15,692',
              changesInAccountsReceivables: '(657)',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '(1,241)',
              cashFlowsFromOperatingActivities: '17,939',
              capitalExpenditures: '(1,214)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(1,214)',
              dividendsPaid: '(10,028)',
              salePurchaseOfStock: '(907)',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '1,042',
              cashFlowsFromFinancingActivities: '(10,279)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '6,446'
            },
            {
              periodEndDate: 'Jun 30, 2014',
              netIncome: '6282000',
              depreciation: '1,512',
              adjustmentsToNetIncome: '482',
              changesInLiabilities: '3,436',
              changesInAccountsReceivables: '(204)',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '(1,798)',
              cashFlowsFromOperatingActivities: '8,993',
              capitalExpenditures: '(1,138)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(1,133)',
              dividendsPaid: '(2,631)',
              salePurchaseOfStock: '(84)',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '118',
              cashFlowsFromFinancingActivities: '(2,628)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '5,232'
            }
          ]

        case 'VLKAY':
          return [
            {
              periodEndDate: 'Jun 30, 2015',
              netIncome: '15081000000',
              depreciation: '24,530,000',
              adjustmentsToNetIncome: '(431,000)',
              changesInLiabilities: '-',
              changesInAccountsReceivables: '-',
              changesInInventories: '(3,179,000)',
              changesInOtherOperatingActivities: '(54,615,000)',
              cashFlowsFromOperatingActivities: '20,819,000',
              capitalExpenditures: '-',
              investments: '(6,883,000)',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(30,892,000)',
              dividendsPaid: '-',
              salePurchaseOfStock: '-',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '-',
              cashFlowsFromFinancingActivities: '7,309,000',
              effectOfExchangeRate: '982,000',
              changeInCashAndCashEquivalents: '(1,783,000)'
            },
            {
              periodEndDate: 'Jun 30, 2014',
              netIncome: '13872000000',
              depreciation: '21,243,000',
              adjustmentsToNetIncome: '(6,552,000)',
              changesInLiabilities: '-',
              changesInAccountsReceivables: '-',
              changesInInventories: '(2,537,000)',
              changesInOtherOperatingActivities: '(52,549,000)',
              cashFlowsFromOperatingActivities: '15,094,000',
              capitalExpenditures: '-',
              investments: '(2,829,000)',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(25,531,000)',
              dividendsPaid: '-',
              salePurchaseOfStock: '-',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '-',
              cashFlowsFromFinancingActivities: '7,135,000',
              effectOfExchangeRate: '(448,000)',
              changeInCashAndCashEquivalents: '(3,749,000)'
            },
            {
              periodEndDate: 'Jun 30, 2013',
              netIncome: '23571000000',
              depreciation: '18,740,000',
              adjustmentsToNetIncome: '(1,705,000)',
              changesInLiabilities: '-',
              changesInAccountsReceivables: '-',
              changesInInventories: '960,000',
              changesInOtherOperatingActivities: '(20,070,000)',
              cashFlowsFromOperatingActivities: '12,967,000',
              capitalExpenditures: '-',
              investments: '(1,221,000)',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(25,775,000)',
              dividendsPaid: '-',
              salePurchaseOfStock: '-',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '-',
              cashFlowsFromFinancingActivities: '15,152,000',
              effectOfExchangeRate: '(396,000)',
              changeInCashAndCashEquivalents: '1,949,000'
            },
            {
              periodEndDate: 'Jun 30, 2012',
              netIncome: '26008000000',
              depreciation: '16,811,000',
              adjustmentsToNetIncome: '(6,600,000)',
              changesInLiabilities: '-',
              changesInAccountsReceivables: '-',
              changesInInventories: '(6,176,000)',
              changesInOtherOperatingActivities: '(36,742,000)',
              cashFlowsFromOperatingActivities: '10,406,000',
              capitalExpenditures: '-',
              investments: '(2,154,000)',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(25,429,000)',
              dividendsPaid: '-',
              salePurchaseOfStock: '-',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '-',
              cashFlowsFromFinancingActivities: '14,755,000',
              effectOfExchangeRate: '487,000',
              changeInCashAndCashEquivalents: '219,000'
            }
          ]
      }
      break

    case 'quarterly':
      switch (params.symbol) {
        case 'GOOG':
          return []

        case 'OTCM':
          return [
            {
              periodEndDate: 'Jun 30, 2017',
              netIncome: '3105000',
              depreciation: '399',
              adjustmentsToNetIncome: '339',
              changesInLiabilities: '(6,762)',
              changesInAccountsReceivables: '658',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '1,416',
              cashFlowsFromOperatingActivities: '1,500',
              capitalExpenditures: '(244)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(344)',
              dividendsPaid: '(1,599)',
              salePurchaseOfStock: '(463)',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '0',
              cashFlowsFromFinancingActivities: '(2,062)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '(906)'
            },
            {
              periodEndDate: 'Mar 31, 2017',
              netIncome: '3050000',
              depreciation: '395',
              adjustmentsToNetIncome: '(60)',
              changesInLiabilities: '(2,514)',
              changesInAccountsReceivables: '403',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '(1,279)',
              cashFlowsFromOperatingActivities: '1,690',
              capitalExpenditures: '(62)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '18',
              cashFlowsFromInvestingActivities: '(44)',
              dividendsPaid: '(1,599)',
              salePurchaseOfStock: '(1,580)',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '0',
              cashFlowsFromFinancingActivities: '(3,179)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '(1,533)'
            },
            {
              periodEndDate: 'Dec 31, 2016',
              netIncome: '2692000',
              depreciation: '391',
              adjustmentsToNetIncome: '438',
              changesInLiabilities: '11,742',
              changesInAccountsReceivables: '(1,348)',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '(316)',
              cashFlowsFromOperatingActivities: '8,560',
              capitalExpenditures: '(238)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(238)',
              dividendsPaid: '(8,326)',
              salePurchaseOfStock: '98',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '24',
              cashFlowsFromFinancingActivities: '(8,402)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '(80)'
            },
            {
              periodEndDate: 'Sep 30, 2016',
              netIncome: '3006000',
              depreciation: '388',
              adjustmentsToNetIncome: '46',
              changesInLiabilities: '1,526',
              changesInAccountsReceivables: '174',
              changesInInventories: '-',
              changesInOtherOperatingActivities: '(390)',
              cashFlowsFromOperatingActivities: '4,095',
              capitalExpenditures: '(4)',
              investments: '-',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(4)',
              dividendsPaid: '(1,578)',
              salePurchaseOfStock: '(765)',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '95',
              cashFlowsFromFinancingActivities: '(2,248)',
              effectOfExchangeRate: '-',
              changeInCashAndCashEquivalents: '1,843'
            }
          ]

        case 'VLKAY':
          return [
            {
              periodEndDate: 'Sep 30, 2015',
              netIncome: '-1869000000',
              depreciation: '6,157,000',
              adjustmentsToNetIncome: '(2,141,000)',
              changesInLiabilities: '15,530,000',
              changesInAccountsReceivables: '-',
              changesInInventories: '74,000',
              changesInOtherOperatingActivities: '(6,572,000)',
              cashFlowsFromOperatingActivities: '5,716,000',
              capitalExpenditures: '-',
              investments: '295,000',
              otherCashFlowsFromInvestingActivities: '(670,000)',
              cashFlowsFromInvestingActivities: '(375,000)',
              dividendsPaid: '-',
              salePurchaseOfStock: '-',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '-',
              cashFlowsFromFinancingActivities: '1,169,000',
              effectOfExchangeRate: '(326,000)',
              changeInCashAndCashEquivalents: '6,184,000'
            },
            {
              periodEndDate: 'Jun 30, 2015',
              netIncome: '3043000000',
              depreciation: '5,083,000',
              adjustmentsToNetIncome: '3,058,000',
              changesInLiabilities: '(1,794,000)',
              changesInAccountsReceivables: '-',
              changesInInventories: '(636,000)',
              changesInOtherOperatingActivities: '(12,852,000)',
              cashFlowsFromOperatingActivities: '4,618,000',
              capitalExpenditures: '-',
              investments: '(258,000)',
              otherCashFlowsFromInvestingActivities: '(4,087,000)',
              cashFlowsFromInvestingActivities: '(4,345,000)',
              dividendsPaid: '-',
              salePurchaseOfStock: '-',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '-',
              cashFlowsFromFinancingActivities: '(536,000)',
              effectOfExchangeRate: '(207,000)',
              changeInCashAndCashEquivalents: '(470,000)'
            },
            {
              periodEndDate: 'Mar 31, 2015',
              netIncome: '3150000000',
              depreciation: '4,710,000',
              adjustmentsToNetIncome: '(1,209,000)',
              changesInLiabilities: '12,042,000',
              changesInAccountsReceivables: '-',
              changesInInventories: '(2,391,000)',
              changesInOtherOperatingActivities: '(17,397,000)',
              cashFlowsFromOperatingActivities: '3,889,000',
              capitalExpenditures: '-',
              investments: '(3,987,000)',
              otherCashFlowsFromInvestingActivities: '(3,571,000)',
              cashFlowsFromInvestingActivities: '(7,558,000)',
              dividendsPaid: '-',
              salePurchaseOfStock: '-',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '-',
              cashFlowsFromFinancingActivities: '1,590,000',
              effectOfExchangeRate: '677,000',
              changeInCashAndCashEquivalents: '(1,403,000)'
            },
            {
              periodEndDate: 'Dec 31, 2014',
              netIncome: '3009000000',
              depreciation: '5,859,000',
              adjustmentsToNetIncome: '(440,000)',
              changesInLiabilities: '-',
              changesInAccountsReceivables: '-',
              changesInInventories: '365,000',
              changesInOtherOperatingActivities: '(6,981,000)',
              cashFlowsFromOperatingActivities: '2,866,000',
              capitalExpenditures: '-',
              investments: '(1,830,000)',
              otherCashFlowsFromInvestingActivities: '-',
              cashFlowsFromInvestingActivities: '(10,212,000)',
              dividendsPaid: '-',
              salePurchaseOfStock: '-',
              netBorrowings: '-',
              otherCashFlowsFromFinancingActivities: '-',
              cashFlowsFromFinancingActivities: '4,414,000',
              effectOfExchangeRate: '(35,000)',
              changeInCashAndCashEquivalents: '(2,967,000)'
            }
          ]
      }
      break
  }
}
