import React from 'react'
import PropTypes from 'prop-types'
import styles from './RichContent.module.scss'
import tableStyles from '../Table/Table.module.scss'
import classNames from 'classnames'
import ContentLink from '../ContentLink'
import ContentImage from '../ContentImage'
import ContentAccordion from '../ContentAccordion'
import NewsWidget from '../NewsWidget'
import Subscribe from '../Subscribe'
import VideoPlayer from '../VideoPlayer'
import RenderChildren from '../../utils/render-children'
import RichContentRenderer from '../../utils/rich-content-renderer'
import { Flex, Box } from 'grid-styled'

const rules = (config) => [
  // Serialize paragraphs
  {
    serialize (object, children) {
      if (object.kind === 'block' && (object.type === 'paragraph' || object.type === 'line')) {
        const empty = children && children.length === 1 && children[0] && children[0].length === 1 && children[0][0] === ''
        if (empty) return <div className={styles.lineBreak} />
        return <p>{children}</p>
      }
    }
  },
  // Serialize blocks
  {
    serialize (object, children) {
      if (object.kind !== 'block' || object.hide) return
      const data = object.data || {}
      switch (object.type) {
        // Lists
        case 'ul_list': return <ul>{children}</ul>
        case 'ol_list': return <ol>{children}</ol>
        case 'list_item': return <li>{children}</li>
        // Separators
        case 'HorizontalLine': return <hr className={styles.hr} />
        // Table
        case 'table': return <div className={styles.tableContainer}>
          <table className={classNames(styles.table, tableStyles.table, tableStyles.ContentTable)}><tbody>{children}</tbody></table>
        </div>
        case 'table_row': return <tr>{children}</tr>
        case 'table_cell': return <td className={tableStyles.cell} style={{ textAlign: data.align || 'left' }}>{children}</td>
        // Accordion
        case 'accordion_table': return <ContentAccordion children={children} />
        case 'accordion_table_row': return <RenderChildren>{children}</RenderChildren>
        case 'accordion_table_cell': return <RenderChildren>{children}</RenderChildren>
        // Grid
        case 'deep_table': return <Flex mx={-2} wrap>{children}</Flex>
        case 'deep_table_row':
          const columns = object.nodes.length
          const newChildren = React.Children.map(children, c => <Box className={styles.gridCell} px={2} w={[1, 1 / columns]}>{c}</Box>)
          return <RenderChildren>{newChildren}</RenderChildren>
        case 'deep_table_cell': return <RenderChildren>{children}</RenderChildren>
        // Components
        case 'Video': return <VideoPlayer url={data.url} />
        case 'SubscribeToNewsletter': return <Subscribe />
        case 'NewsPR': return <NewsWidget feed='pr' />
        case 'NewsIR': return <NewsWidget feed='ir' />
        case 'NewsMedia': return <NewsWidget feed='media' />
        case 'Careers': return <iframe
          src='https://otcmarkets.theresumator.com/apply/jobs/'
          sandbox='allow-scripts allow-forms allow-same-origin'
          frameBorder='0' marginHeight='0' marginWidth='0' allowTransparency='true'
          height='500' width='100%' />
      }
    }
  },
  // Serialize marks
  {
    serialize (object, children) {
      if (object.kind !== 'mark') return
      switch (object.type) {
        // Text Styles
        case 'Bold': return <strong>{children}</strong>
        case 'Italic': return <em>{children}</em>
        case 'Underline': return <u>{children}</u>
        case 'Light': return <span className={styles.light}>{children}</span>
        case 'Caption': return <span className={styles.caption}>{children}</span>
        case 'Serif': return <span className={styles.serif}>{children}</span>
        // Text Colors
        case 'Green': return <span className={styles.colorGreen}>{children}</span>
        case 'Orange': return <span className={styles.colorOrange}>{children}</span>
        case 'Pink': return <span className={styles.colorPink}>{children}</span>
      }
    }
  },
  // Serialize inlines
  {
    serialize (object, children) {
      if (object.kind !== 'inline') return
      const data = object.data || {}
      switch (object.type) {
        case 'image': return <ContentImage data={data} />
        case 'link': return <ContentLink data={data} children={children} accentColor={config.accentColor} location={config.location} />
      }
    }
  }
]

const RichContent = props => {
  if (!props.content) return <div />
  const renderer = new RichContentRenderer({ rules: rules({ accentColor: props.accentColor, location: props.location }) })
  const rendered = renderer.render(props.content)
  return <div className={styles.richContent}>{rendered}</div>
}

RichContent.propTypes = {
  content: PropTypes.object,
  accentColor: PropTypes.string,
  location: PropTypes.object
}

export default RichContent
