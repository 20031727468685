import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'

const { REACT_APP_API_BASE_URI } = getConfig()

export async function getStatsHistory (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: `/compliance/risk-score-stats/${params.symbol && params.symbol}`,
    params
  })
  return response.data
}

export async function downloadStatsHistory (symbol, params) {
  let jwtToken
  const url = `/internalapi/download/compliance/risk-score-stats/${symbol}/download`
  const queryString = Object.keys(params)
    .filter(key => params[key])
    .map(key => params[key] && `${key}=${params[key]}`).join('&')

  await getToken()
    .then(res => {
      jwtToken = res
    })
    .catch(e => console.log('no session', e))
  window.open(`${REACT_APP_API_BASE_URI}${url}?${queryString}&file=${symbol}&token=${jwtToken}`)
}

export async function getLatest (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: `/compliance/risk-score-stats/${params.symbol && params.symbol}/latest`,
    params
  })
  return response.data
}
