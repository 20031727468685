import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Flex, Box } from 'grid-styled'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import styles from './CompanyQuoteFinancials.module.scss'
import { logPageview } from '../../analytics'
import {
  getIncomeStatementData,
  getBalanceSheetData,
  getCashFlowData
} from '../../api/otc/company/financials'

import Tooltip from '../Tooltip'
import Outline from '../Outline'
import Select from '../Select'
import Loading from '../Loading'
import Hide from '../Hide'
import columns from './columns'

const reportTypes = [{
  title: 'Income Statement',
  value: 'incomeStatement'
}, {
  title: 'Balance Sheet',
  value: 'balanceSheet'
}, {
  title: 'Cash Flow',
  value: 'cashFlow'
}]

const durationTypes = [{
  title: 'Annual', value: 'annual'
}, {
  title: 'Semi-Annual', value: 'semi-annual'
}, {
  title: 'Quarterly', value: 'quarterly'
}]

class CompanyQuoteFinancials extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      loaded: false,
      reportType: 'incomeStatement',
      selectedDuration: 'annual',
      selectedTimeIndex: 0,
      incomeStatement: {
        loaded: false,
        data: [],
        error: null
      },
      balanceSheet: {
        loaded: false,
        data: [],
        error: null
      },
      cashFlow: {
        loaded: false,
        data: [],
        error: null
      }
    }
  }

  onSelectReport = (reportType) => {
    const { company } = this.props

    this.loadReport(company, reportType, this.state.selectedDuration)

    return this.setState(state => ({
      reportType
    }))
  }

  onSelectReportTab = (reportType) => {
    return () => {
      this.onSelectReport(reportType)
    }
  }

  onDurationSelect = (duration) => {
    const { company } = this.props

    this.loadReport(company, this.state.reportType, duration)

    return this.setState(state => ({
      selectedDuration: duration,
      selectedTimeIndex: 0
    }))
  }

  onTimeSelect = (timeIndex) => {
    return () => {
      return this.setState(state => ({
        selectedTimeIndex: timeIndex
      }))
    }
  }

  formatMobileTime = (time) => {
    if (this.state.selectedDuration === 'annual') {
      return moment(time, 'MM/DD/YYYY').tz('America/New_York').format('YYYY')
    } else {
      return moment(time, 'MM/DD/YYYY').tz('America/New_York').format('MMM, YYYY')
    }
  }

  formatTabletTime = (time) => {
    // moment.js will stop supporting non-standard time formats in the future,
    // if the 'API time' follows the current time format by the time, it will
    // need 'MMM DD, YYYY' specified in the parsing function.
    return moment(time).tz('America/New_York').format('MM/DD/YYYY')
  }

  loadReport (company, dataKey, duration) {
    let func = null
    let pageSize = 4

    switch (dataKey) {
      case 'incomeStatement': func = getIncomeStatementData; break
      case 'balanceSheet': func = getBalanceSheetData; break
      case 'cashFlow': func = getCashFlowData; break
    }
    switch (duration) {
      case 'quarterly': pageSize = 5; break
    }

    this.setState(state => ({
      [dataKey]: {
        ...state[dataKey],
        loaded: false,
        error: null
      }
    }), () => {
      func({
        symbol: company,
        duration,
        pageSize
      }).then(data => {
        if (data === '') data = []
        if (!data) data = []

        this.setState(state => ({
          [dataKey]: {
            loaded: true,
            data,
            error: null
          }
        }))
      }).catch(() => {
        this.setState(state => ({
          [dataKey]: {
            loaded: false,
            error: 'Something went wrong, please try again.',
            data: state[dataKey].data
          }
        }))
      })
    })
  }

  componentDidMount () {
    const { company } = this.props

    this.loadReport(company, 'incomeStatement', this.state.selectedDuration)
    this.loadReport(company, 'balanceSheet', this.state.selectedDuration)
    this.loadReport(company, 'cashFlow', this.state.selectedDuration)

    const title = `OTC Markets | ${this.props.company} | Financials`
    logPageview(title)
  }

  render () {
    const { company, accent, info } = this.props
    const showToolTip = (title) => { return title !== null && title.length > 29 && title }
    const currentReport = this.state[this.state.reportType]

    return (
      <div className={styles.CompanyQuoteFinancials}>
        <Outline mode='heading' accent={accent}
          content={<abbr>{info && info.fiscalYearEnd && <span>Fiscal year ends {info.fiscalYearEnd}</span>} <Hide sm inline> | Values in 000s USD</Hide></abbr>}>
          Financials
        </Outline>

        <Hide sm>
          <Flex wrap>
            {reportTypes.map(type => <Box
              w={1 / 6}
              key={`financial-report-type-${type.value}`}
              onClick={this.onSelectReportTab(type.value)}
              className={classNames({
                [styles.tab]: true,
                [styles[accent]]: true,
                [styles.active]: this.state.reportType === type.value
              })}>
              {type.title}
            </Box>)}
            <Box flex='1' />
          </Flex>
          <Flex wrap className={styles.tabDivider}>
            {durationTypes.map(type => <Box
              w={1 / 8}
              key={`duration-report-type-${type.value}`}
              onClick={() => this.onDurationSelect(type.value)}
              className={classNames({
                [styles.tab]: true,
                [styles[accent]]: true,
                [styles.active]: this.state.selectedDuration === type.value
              })}>
              {type.title}
            </Box>)}
          </Flex>
        </Hide>
        <Hide md lg>
          <Flex wrap>
            <Box w={1} className={styles.mobileTab}>
              <Select
                design='filter'
                className={styles.mobileFilter}
                title={'Income Statement'}
                required
                centerAligned
                defaultValue={'incomeStatement'}
                onSelect={this.onSelectReport}
                options={reportTypes}
              />
            </Box>
            <Box w={1} className={styles.mobileTab}>
              <Select
                design='filter'
                className={styles.mobileFilter}
                required
                centerAligned
                title={'Annual'}
                defaultValue={'annual'}
                onSelect={this.onDurationSelect}
                options={[
                  { title: 'Annual', value: 'annual' },
                  { title: 'Semi-Annual', value: 'semi-annual' },
                  { title: 'Quarterly', value: 'quarterly' }
                ]}
              />
            </Box>
          </Flex>
        </Hide>

        {currentReport && currentReport.loaded && currentReport.data && currentReport.data.length === 0 &&
        <div key='customEmptyMsg'>
          Financial data is not available. Click <Link to={`/stock/${company}/disclosure`} >here</Link> to see if the company has made disclosure available through the OTC Disclosure & News Service.
        </div>
        }
        <Loading
          type='table'
          emptyMessage={' '}
          height={'40em'}
          loaded={this.state[this.state.reportType].loaded}
          error={this.state[this.state.reportType].error}
          data={this.state[this.state.reportType].data}>
          <Hide lg>
            <Flex justify={['center', 'space-between', 'center']}>
              {this.state[this.state.reportType].data.map((value, valueIndex) => <Box
                key={`mobile-time-select-${valueIndex}`}
                className={classNames(styles.mobileTimeSelect, {
                  [styles.selected]: this.state.selectedTimeIndex === valueIndex
                })}
                onClick={this.onTimeSelect(valueIndex)}>
                <Hide md>
                  {this.formatMobileTime(value.periodEndDate)}
                </Hide>
                <Hide sm>
                  {this.formatTabletTime(value.periodEndDate)}
                </Hide>
              </Box>)}
            </Flex>
          </Hide>
          <Flex
            column
            flex={'1 1 auto'}>
            {columns[this.state.reportType].map((item, index) => <Flex
              key={`financial-data-${index}`}
              className={classNames({
                [styles.row]: true,
                [styles.child]: item.child,
                [styles.bold]: item.bold,
                [styles.gray]: item.gray,
                [styles.topBorder]: item.topBorder,
                [styles.bottomSpacing]: item.bottomSpacing,
                [styles.section]: item.section,
                [styles.separator]: item.separator,
                [styles.subSeparator]: item.subSeparator,
                [styles.totals]: item.totals,
                [styles.hideOnMobile]: item.hideOnMobile,
                [styles.hideOnTablet]: item.hideOnTablet
              })}>
              <Box className={classNames({
                [styles.itemTitle]: true,
                [styles.separator]: item.separator,
                [styles.subSeparator]: item.subSeparator,
                [styles.subRow]: item.subRow,
                [styles.totals]: item.totals
              })} flex='2'>
                <Hide lg>
                  <Tooltip width='230px' placement='top' text={showToolTip(item.title)}>
                    <div className={styles.itemTitleContent}>{item.title}</div>
                  </Tooltip>
                </Hide>
                <Hide sm md>
                  <div className={styles.itemTitleContent}>{item.title}</div>
                </Hide>
              </Box>
              {!item.separator && !item.subSeparator && this.state[this.state.reportType].data.map((value, valueIndex) => <Box
                key={`financial-datax-${index}-${valueIndex}`}
                className={classNames({
                  [styles.value]: true,
                  [styles.selected]: valueIndex === 0
                })}
                flex={'1 0 0'}>
                <Hide sm md>
                  <span>
                    {this.state[this.state.reportType].data[valueIndex][item.name] || '-'}
                  </span>
                </Hide>
                <Hide lg>
                  <span>
                    {this.state[this.state.reportType].data[this.state.selectedTimeIndex][item.name] || '-'}
                  </span>
                </Hide>
              </Box>)}
            </Flex>)}
          </Flex>
          <abbr>
            <p>
              {`Financial data presented is provided by Edgar Online and may differ from
              information contained in the issuer's annual and periodic reports, which
              are available under the "Disclosure" tab.`}
            </p>
            <p>
              Neither the issuer nor OTC Markets Group is liable for any errors, omissions
              or other defects in the data presented below, or for any decisions taken in
              reliance of the data.
            </p>
            <p>
              For information not originally reported in U.S. Dollars, conversion
              is based on applicable exchange rate on the last day of the period reported.
            </p>
          </abbr>
          <abbr>
            <Flex flex='1'>
              <Box>
                <img src='/logos/eol.png' />
              </Box>
              <Box className={styles.footerNote}>
                &copy; {new Date().getFullYear()} EDGAR&reg;Online LLC, a subsidiary of OTC Markets Group. All rights reserved. EDGAR® and SEC® are trademarks of the U.S. Securities and Exchange Commission. OTC Market Groups Inc.'s products and services are not affiliated with or approved by the U.S. Securities and Exchange Commission.
              </Box>
            </Flex>
          </abbr>
        </Loading>
      </div>
    )
  }
}

CompanyQuoteFinancials.propTypes = {
  company: PropTypes.string.isRequired,
  info: PropTypes.object,
  accent: PropTypes.string
}

CompanyQuoteFinancials.defaultProps = {
  accent: 'default'
}

export default CompanyQuoteFinancials
