import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import styles from './CompanyQuoteResearch.module.scss'
import { getResearchData } from '../../api/otc/company/research'
import { logPageview } from '../../analytics'

import { template, fetch, expand } from '../../api/helper'
import api from '../../api/api'

import Outline from '../Outline'
import Table from '../Table'
import Loading from '../Loading'
import More from '../More'

const hostname = window.location.origin

const researchColumns = [{
  name: 'publishDate',
  header: 'Publication Date',
  noWrap: true,
  component: ({data}) => data.publishDate ? moment(data.publishDate).tz('America/New_York').format('MMM DD, YYYY') : '—'
}, {
  name: 'title',
  header: 'Title',
  target: 'blank',
  linkTo: (item) => {
    if (item.storageId) {
      return `${hostname}/otc-content/research-report/${item.storageId}/contents`
    } else {
      return item.reportUrl
    }
  }
}, {
  name: 'reportDate',
  noWrap: true,
  header: 'Report Date',
  component: ({data}) => data.reportDate ? moment.tz(data.reportDate, 'YYYY-MM-DD', 'America/New_York').format('MMM DD, YYYY') : '—'
}, {
  name: 'publisherName',
  header: 'Source',
  hideOnMobile: true
}]

class CompanyQuoteResearch extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      researchData: template('array')
    }
  }

  componentDidMount () {
    this.reload()

    const title = `OTC Markets | ${this.props.company} | Research`
    logPageview(title)
  }

  reload = () => {
    const companyId = this.props.info.id

    fetch(this, getResearchData, 'researchData', {
      companyId,
      page: 1,
      pageSize: 10
    }, data => {
      if (!data) data = []
      if (data === '') data = []
      return data
    })
  }

  render () {
    const { accent } = this.props
    return (
      <div className={styles.CompanyQuoteResearch}>
        <Outline mode='heading' accent={accent}>Research Reports</Outline>
        <Loading
          type='table'
          emptyMessage='No Research Reports are available for this company.'
          loaded={this.state.researchData.loaded}
          reloading={this.state.researchData.reloading}
          error={this.state.researchData.error}
          data={this.state.researchData.data}>
          <Table
            columns={researchColumns}
            data={this.state.researchData.data.records}
            showHeader
            accent={accent}
          />
          <More onClick={expand(this, getResearchData, 'researchData')}
            disabled={this.state.researchData.expandEmpty} />
        </Loading>
      </div>
    )
  }
}

CompanyQuoteResearch.propTypes = {
  company: PropTypes.string.isRequired,
  accent: PropTypes.string
}

export default CompanyQuoteResearch
