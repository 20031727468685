export default [{
  key: 'promotions',
  hash: 'promotions',
  title: 'Promotions',
  columns: [{
    name: 'latestPromoDate',
    header: 'Most Recent Promo Date',
    headerShort: 'RECDATE',
    type: 'date',
    emptyValue: '—'
  }, {
    name: 'symbol',
    header: 'Symbol',
    headerShort: 'Sym',
    type: 'symbol',
    emptyValue: '—'
  }, {
    name: 'promoStartDate',
    header: 'Flagged Date',
    headerShort: 'FLGDATE',
    type: 'date',
    emptyValue: '—'
  }, {
    name: 'promoDuration',
    header: 'Promo Duration (Days)',
    headerShort: 'DUR',
    type: 'int',
    emptyValue: '—'
  }, {
    name: 'startDatePrice',
    header: 'Start Date Price',
    headerShort: 'STRPRC',
    type: 'price',
    emptyValue: '—'
  }, {
    name: 'currentClosingPrice',
    header: 'Closing Price',
    headerShort: 'CLOPRC',
    type: 'price',
    emptyValue: '—'
  }, {
    name: 'volume',
    header: 'Vol',
    type: 'int',
    emptyValue: '—'
  }, {
    name: 'thirtyDayAvg',
    header: '30 ADV',
    headerShort: '30ADV',
    type: 'int',
    emptyValue: '—'
  }, {
    name: 'volumeChangePct',
    header: '% Vol Change',
    headerShort: '%CHNG',
    type: 'price',
    emptyValue: '—'
  }]
}, {
  key: 'graceperiod',
  hash: 'grace-period',
  title: 'Grace Period',
  columns: [{
    name: 'startDate',
    header: 'Grace Period Start Date',
    headerShort: 'STRDATE',
    type: 'date',
    emptyValue: '—'
  }, {
    name: 'symbol',
    header: 'Symbol',
    headerShort: 'SYM',
    type: 'symbol',
    emptyValue: '—'
  }, {
    name: 'endDate',
    header: 'Anticipated Grace Period End Date',
    headerShort: 'ENDDATE',
    type: 'date',
    emptyValue: '—'
  }, {
    name: 'currentClosingPrice',
    header: 'Closing Price',
    headerShort: 'CLOPRC',
    type: 'price',
    emptyValue: '—'
  }, {
    name: 'marketCap',
    header: 'Market Cap (M)',
    headerShort: 'MKTCAP(M)',
    type: 'millions',
    emptyValue: '—'
  }]
}, {
  key: 'promotionsShell',
  hash: 'shell-status-changes',
  title: 'Shell Status Changes',
  columns: [{
    name: 'shellChangeDate',
    header: 'Shell Status Change Date',
    headerShort: 'CHNGDATE',
    type: 'date',
    emptyValue: '—'
  }, {
    name: 'symbol',
    header: 'Symbol',
    headerShort: 'SYM',
    type: 'symbol',
    emptyValue: '—'
  }, {
    name: 'oldValue',
    header: 'Old Value',
    headerShort: 'OLDVAL',
    emptyValue: '—'
  }, {
    name: 'newValue',
    header: 'New Value',
    headerShort: 'NEWVAL',
    emptyValue: '—'
  }, {
    name: 'currentClosingPrice',
    header: 'Closing Price',
    headerShort: 'CLOPRC',
    type: 'price',
    emptyValue: '—'
  }, {
    name: 'marketCap',
    header: 'Market Cap (M)',
    headerShort: 'MKTCAP(M)',
    type: 'millions',
    emptyValue: '—'
  }]
}, {
  key: 'shellRisk',
  hash: 'shell-risk-changes',
  title: 'Shell Risk Changes',
  columns: [{
    name: 'shellRiskChangeDate',
    header: 'Shell Status Change Date',
    headerShort: 'CHNGDATE',
    type: 'date',
    emptyValue: '—'
  }, {
    name: 'symbol',
    header: 'Symbol',
    headerShort: 'SYM',
    type: 'symbol',
    emptyValue: '—'
  }, {
    name: 'oldValue',
    header: 'Old Value',
    headerShort: 'OLDVAL',
    emptyValue: '—'
  }, {
    name: 'newValue',
    header: 'New Value',
    headerShort: 'NEWVAL',
    emptyValue: '—'
  }, {
    name: 'currentClosingPrice',
    header: 'Closing Price',
    headerShort: 'CLOPRC',
    type: 'price',
    emptyValue: '—'
  }, {
    name: 'marketCap',
    header: 'Market Cap (M)',
    headerShort: 'MKTCAP(M)',
    type: 'millions',
    emptyValue: '—'
  }]
}, {
  key: 'reverseSplits',
  hash: 'reverse-splits',
  title: 'Reverse Splits',
  columns: [{
    name: 'splitEffectiveDate',
    header: 'Split Effective Date',
    headerShort: 'EFFDATE',
    type: 'date',
    emptyValue: '—'
  }, {
    name: 'oldSymbol',
    header: 'Old Symbol',
    headerShort: 'OLDSYM',
    emptyValue: '—'
  }, {
    name: 'newSymbol',
    header: 'New Symbol',
    headerShort: 'NEWSYM',
    type: 'symbol',
    emptyValue: '—',
    componentOptions: {
      symbol: 'newSymbol',
      tierId: 'newTierId',
      tierCode: 'newTierCode'
    }
  }, {
    name: 'splitRatio',
    header: 'Split Ratio',
    headerShort: 'RATIO',
    emptyValue: '—'
  }, {
    name: 'closePricePreSplit',
    header: 'Closing Price Pre-Split',
    headerShort: 'CLOPRC PS',
    type: 'price',
    emptyValue: '—'
  }, {
    name: 'currentClosingPrice',
    header: 'Closing Price',
    headerShort: 'CLOPRC',
    type: 'price',
    emptyValue: '—'
  }, {
    name: 'marketCapPreSplit',
    header: 'Market Cap (M) Pre-Split',
    headerShort: 'MKTCAP PS',
    type: 'millions',
    emptyValue: '—'
  }, {
    name: 'marketCap',
    header: 'Current Market Cap (M)',
    headerShort: 'CURMKT(M)',
    type: 'millions',
    emptyValue: '—'
  }]
}, {
  key: 'promotionsNameChanges',
  hash: 'company-name-changes',
  title: 'Company Name Changes',
  columns: [{
    name: 'effectiveDate',
    header: 'Effective Date',
    headerShort: 'EFFDATE',
    type: 'date',
    emptyValue: '—'
  }, {
    name: 'oldSymbol',
    header: 'Old Symbol',
    headerShort: 'OLDSYM',
    emptyValue: '—'
  }, {
    name: 'oldCompanyName',
    header: 'Old Company Name',
    headerShort: 'OLDNAME',
    emptyValue: '—'
  }, {
    name: 'newSymbol',
    header: 'New Symbol',
    headerShort: 'NEWSYM',
    type: 'symbol',
    emptyValue: '—',
    componentOptions: {
      symbol: 'newSymbol',
      tierId: 'newTierId',
      tierCode: 'newTierCode'
    }
  }, {
    name: 'newCompanyName',
    header: 'New Company Name',
    headerShort: 'NEWNAME',
    emptyValue: '—'
  }]
}, {
  key: 'promotionsCaveatEmptor',
  hash: 'caveat-emptor',
  title: 'Caveat Emptor',
  columns: [{
    name: 'changeDate',
    header: 'CE Change Date',
    headerShort: 'CHNGDATE',
    type: 'date',
    emptyValue: '—'
  }, {
    name: 'symbol',
    header: 'Symbol',
    headerShort: 'SYM',
    type: 'symbol',
    emptyValue: '—'
  }, {
    name: 'newValue',
    header: 'Change',
    headerShort: 'CHNG',
    component: ({ data }) => data.newValue === 'Y' ? 'Added' : 'Removed'
  }, {
    name: 'closingPrice',
    header: 'Closing Price',
    headerShort: 'CLOPRC',
    type: 'price',
    emptyValue: '—'
  }, {
    name: 'marketCap',
    header: 'Market Cap (M)',
    headerShort: 'MKTCAP(M)',
    type: 'millions',
    emptyValue: '—'
  }]
}, {
  key: 'promotionsSuspensionRevocation',
  hash: 'suspensions-revocations',
  title: 'Suspensions / Revocations',
  linkTitle: 'Suspensions',
  columns: [{
    name: 'effectiveDate',
    header: 'Activation Date',
    headerShort: 'ACTDATE',
    type: 'date',
    emptyValue: '—'
  }, {
    name: 'symbol',
    header: 'Symbol',
    headerShort: 'SYM',
    type: 'symbol',
    emptyValue: '—'
  }, {
    name: 'currentStatus',
    header: 'Current Status',
    headerShort: 'CURSTAT',
    emptyValue: '—'
  }, {
    name: 'closingPrice',
    header: 'Closing Price',
    headerShort: 'CLOPRC',
    type: 'price',
    emptyValue: '—'
  }, {
    name: 'marketCap',
    header: 'Market Cap (M)',
    headerShort: 'MKTCAP(M)',
    type: 'millions',
    emptyValue: '—'
  }]
}]
