import api from '../../api'

export async function getDNSTierNews (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    mockData: 'company/news/dns-tier-news',
    url: '/company/dns/tier/news',
    params
  })
  return response.data
}

export async function getDNSNews (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'company/news/dns-news',
    url: `/company/${params.symbol}/dns/news`,
    params
  })
  return response.data
}

export async function getDNSNewsStory (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    url: `/company/dns/news/${params.newsId}`
  })
  return response.data
}

export async function getEdgarNewsStory (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    url: `/company/external/news/${params.newsId}`
  })
  return response.data
}

export async function getDNSNewsContent (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'data',
    mockData: 'company/news/dns-news-content',
    url: `/company/dns/news/content/${params.newsId}`,
    params
  })
  return response.data
}

export async function getEdgarNews (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'company/news/edgar-news',
    url: `/company/${params.symbol}/external/news`,
    params
  })
  // If source is missing, set as 'Seeking Alpha'
  response.data && response.data.records && response.data.records.forEach(item => {
    if (item && !item.sourceName) item.sourceName = 'Seeking Alpha'
  })

  return response.data
}

export async function getEdgarNewsContent (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'data',
    mockData: 'company/news/edgar-news-content',
    url: `/company/external/news/content/${params.newsId}`,
    params
  })
  return response.data
}
