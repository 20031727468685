import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getTopRiskScore (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/key-securities/top-risk-scores',
    params
  })
  return response.data
}

export async function getCaveatEmptor (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/key-securities/caveat-emptor',
    params
  })
  return response.data
}

export async function getActivePromotions (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/key-securities/active-promotions',
    params
  })
  return response.data
}

export async function getHotSectors (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/key-securities/hot-sectors',
    params
  })
  return response.data
}

export async function getShellRisk (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/key-securities/shell-risk',
    params
  })
  return response.data
}

export async function getShell (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/key-securities/shell',
    params
  })
  return response.data
}

export async function getBankruptcy (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/key-securities/bankruptcy',
    params
  })
  return response.data
}

export async function downloadKeySecurities (pathname, params) {
  const url = `/internalapi/download/compliance/key-securities/${pathname}/download`
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
  window.open(`${REACT_APP_API_BASE_URI}${url}?file=${pathname}&token=${jwtToken}`)
}
