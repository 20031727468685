import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '../../components/WithRouter'
import styles from './DCompanyDirectoryPage.module.scss'
import { getList, getCountries, downloadCompanies } from '../../api/otc/directory/company'
import { template, fetch, expand } from '../../api/helper'

import Select from '../../components/Select'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import HeadTitle from '../../components/HeadTitle'
import Flexgrid from '../../components/Flexgrid'
import Table from '../../components/Table'
import Outline from '../../components/Outline'
import More from '../../components/More'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Sticky from '../../components/Sticky'
import OtherDirectories from '../../components/OtherDirectories'
import DirSearch from '../../components/DirSearch'

const columns = [
  {
    name: 'symbol',
    header: 'Symbol',
    type: 'symbol'
  },
  {
    name: 'companyName',
    header: 'Company name'
  },
  {
    name: 'marketCap',
    header: 'Market cap',
    type: 'int',
    hideOnMobile: true
  },
  {
    name: 'joined',
    header: 'Date joined',
    type: 'date'
  },
  {
    name: 'industry',
    header: 'Industry',
    hideOnMobile: true,
    hideOnTablet: true
  },
  {
    name: 'country',
    header: 'Country',
    hideOnMobile: true
  }
]

const getOptionsFromCountries = cts => cts.sort().map((c, index) => ({
  id: index,
  title: c,
  value: c
}))

export class DCompanyDirectoryPage extends PureComponent {
  state = {
    list: template('records'),
    countries: template('records'),
    selectedCountry: null,
    searchQuery: null
  }

  componentDidMount () {
    this.loadCountries()
    this.loadData()
  }

  loadData = () => {
    fetch(this, getList, 'list', {
      page: 1,
      pageSize: 25,
      country: this.state.selectedCountry,
      search: this.state.searchQuery
    })
  }

  loadCountries = () => {
    fetch(this, getCountries, 'countries', {
      page: 1,
      pageSize: 300
    })
  }

  handleSelectCountry = selectedCountry => {
    this.setState({ selectedCountry }, this.loadData)
  }

  onSearch = searchQuery => {
    this.setState({ searchQuery }, this.loadData)
  }

  render () {
    const { countries, list } = this.state
    const selectedCountry = this.props.match.params.country

    const layouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['search', 'country-select', 'table', 'other-directories']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['sticky-column']
            },
            {
              type: 'main',
              mt: '1.8rem',
              subtree: ['table']
            }
          ]
        }
      ]
    }

    const dirSearch = <DirSearch key='search' onSearch={this.onSearch} value={this.state.searchQuery} />

    const countrySelect = <div key='country-select' className={styles.filters}>
      <Select
        title='Country'
        design='standard'
        options={countries.loaded ? getOptionsFromCountries(countries.data) : null}
        defaultValue={selectedCountry}
        onSelect={this.handleSelectCountry} />
    </div>

    const otherDirectories = <div key='other-directories'>
      <OtherDirectories />
    </div>

    return (
      <div>
        <HeadTitle title='Directory - Company Directory' />
        <PageTitle>Company Directory</PageTitle>
        <Flexgrid layouts={layouts}>
          {dirSearch}
          {countrySelect}
          <div key='table' className={styles.table}>
            <Outline mode='heading'
              download={() => downloadCompanies()}>
              Companies
            </Outline>
            <Loading
              type='table'
              data={list.data}
              reloading={list.reloading}
              loaded={list.loaded}>
              <Table
                columns={columns}
                loaded={list.loaded}
                data={list.data.records}
                showHeader
              />
              <TableFooter>
                <More
                  onClick={expand(this, getList, 'list')}
                  disabled={list.expandEmpty} />
                {list.data.records && list.data.totalRecords &&
                  <DisplayResults show={list.data.records.length} total={list.data.totalRecords} text={'Companies'} />
                }
              </TableFooter>
            </Loading>
          </div>
          <Sticky key='sticky-column' className={styles.sideColumn}>
            {dirSearch}
            {countrySelect}
            {otherDirectories}
          </Sticky>
          {otherDirectories}
        </Flexgrid>
      </div>
    )
  }
}

DCompanyDirectoryPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      country: PropTypes.string
    })
  })
}

export default withRouter(DCompanyDirectoryPage)
