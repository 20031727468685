export function request (params) {
  return {
    totalRecords: 0,
    pages: 0,
    currentPage: 1,
    pageSize: 5,
    records: [],
    sortOn: 'dollarVolume',
    isRealTime: false,
    bySymbol: true
  }
}
