const testGA = 'G-Q5KBXK0GHV'
const prodGA = 'G-DD1H6PK61N'

const ENV_VARS = {
  REACT_APP_API_BASE_URI: process.env.REACT_APP_API_BASE_URI || '',
  REACT_APP_ADMIN_BASE_URI: process.env.REACT_APP_ADMIN_BASE_URI || '',
  REACT_APP_OTC_S3_BASE_URI: process.env.REACT_APP_OTC_S3_BASE_URI || '',
  REACT_APP_CMS_S3_BASE_URI: process.env.REACT_APP_CMS_S3_BASE_URI || ''
}

const getConfig = () => {
  if (window.location.host.match(/localhost/)) {
    return Object.assign({
      REACT_ENV: 'local',
      REACT_GA_ID: testGA,
      REACT_APP_STOCK_SCREENER_URL: 'http://localhost:3002/research/stock-screener',
      OTC_HOSTNAME_URL: 'https://otcm-web.test.otcmkt.com',
      QARAVAN_API_URI: 'https://api-stg.qaravan.com',
      REACT_APP_QARAVAN_CALL_REPORT_EXPORT_URI: 'https://api-stg.qaravan.com/call-report/external/export/excel',
      REACT_APP_QARAVAN_FRY9_REPORT_EXPORT_URI: 'https://api-stg.qaravan.com/fr-y9/external/export/excel'
    }, ENV_VARS)
  } else if (window.location.host.match(/dev/)) {
    return Object.assign({
      REACT_ENV: 'dev',
      REACT_GA_ID: testGA,
      REACT_APP_STOCK_SCREENER_URL: 'https://otcm-web-test.test.otc/research/stock-screener',
      OTC_HOSTNAME_URL: 'https://otcm-web.dev.otcmkt.com',
      QARAVAN_API_URI: 'https://api-stg.qaravan.com',
      REACT_APP_QARAVAN_CALL_REPORT_EXPORT_URI: 'https://api-stg.qaravan.com/call-report/external/export/excel',
      REACT_APP_QARAVAN_FRY9_REPORT_EXPORT_URI: 'https://api-stg.qaravan.com/fr-y9/external/export/excel'
    }, ENV_VARS)
  } else if (window.location.host.match(/test/)) {
    return Object.assign({
      REACT_ENV: 'test',
      REACT_GA_ID: testGA,
      REACT_APP_STOCK_SCREENER_URL: 'https://otcm-web-test.test.otc/research/stock-screener',
      OTC_HOSTNAME_URL: 'https://otcm-web.test.otcmkt.com',
      QARAVAN_API_URI: 'https://api-stg.qaravan.com',
      REACT_APP_QARAVAN_CALL_REPORT_EXPORT_URI: 'https://api-stg.qaravan.com/call-report/external/export/excel',
      REACT_APP_QARAVAN_FRY9_REPORT_EXPORT_URI: 'https://api-stg.qaravan.com/fr-y9/external/export/excel'
    }, ENV_VARS)
  } else if (window.location.host.match(/stage/) ||
  window.location.host.match(/.otc\.co/)) {
    return Object.assign({
      REACT_ENV: 'stage',
      REACT_GA_ID: prodGA,
      REACT_APP_STOCK_SCREENER_URL: 'https://www.otc.co/research/stock-screener',
      OTC_HOSTNAME_URL: 'https://otcm-web.stage.otcmkt.com',
      QARAVAN_API_URI: 'https://api.qaravan.com',
      REACT_APP_QARAVAN_CALL_REPORT_EXPORT_URI: 'https://api.qaravan.com/call-report/external/export/excel',
      REACT_APP_QARAVAN_FRY9_REPORT_EXPORT_URI: 'https://api.qaravan.com/fr-y9/external/export/excel'
    }, ENV_VARS)
  } else if (window.location.host.match(/.otcmarkets\.com/) ||
  window.location.host.match(/www\.otcmarkets\.com/) || window.location.host.match(/prod/)) {
    return Object.assign({
      REACT_ENV: 'prod',
      REACT_GA_ID: prodGA,
      REACT_APP_STOCK_SCREENER_URL: 'https://www.otcmarkets.com/research/stock-screener',
      OTC_HOSTNAME_URL: 'https://www.otcmarkets.com',
      QARAVAN_API_URI: 'https://api.qaravan.com',
      REACT_APP_QARAVAN_CALL_REPORT_EXPORT_URI: 'https://api.qaravan.com/call-report/external/export/excel',
      REACT_APP_QARAVAN_FRY9_REPORT_EXPORT_URI: 'https://api.qaravan.com/fr-y9/external/export/excel'
    }, ENV_VARS)
  }
}

export default getConfig
