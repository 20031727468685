export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return ''

    case 'OTCM':
      return {
        totalRecords: 1977,
        pages: 99,
        currentPage: 1,
        pageSize: 20,
        sortOn: 'releaseDate',
        sortDir: 'DESC',
        records: [
          {
            id: 172201,
            companyId: 674812,
            userId: 14198,
            title: 'Test New Website',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: true,
            createdDate: 1512674673476,
            lastModifiedDate: 1512674673476,
            releaseDate: 1512674673476,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Dec 07, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 172185,
            companyId: 674812,
            userId: 6229,
            title: 'OTC Markets Group Welcomes New OTCQB Companies  October 12',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: false,
            createdDate: 1507757386061,
            lastModifiedDate: 1507853953558,
            releaseDate: 1507806000000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Oct 12, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: false
          },
          {
            id: 171987,
            companyId: 674812,
            userId: 2316,
            title: 'OTC Markets Group Welcomes GrowGeneration to OTCQX',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1507638686000,
            lastModifiedDate: 1507638686000,
            releaseDate: 1507638686000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'PRNS',
            displayDateTime: 'Oct 10, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 171919,
            companyId: 674812,
            userId: 6229,
            title: 'OTC Markets Group Welcomes New OTCQB Companies  October 10',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: false,
            createdDate: 1507584505118,
            lastModifiedDate: 1507633565429,
            releaseDate: 1507633200000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Oct 10, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: false
          },
          {
            id: 171817,
            companyId: 674812,
            userId: 6229,
            title: 'OTC Markets Group Welcomes New OTCQB Companies  October 6',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: false,
            createdDate: 1507301196333,
            lastModifiedDate: 1507301196333,
            releaseDate: 1507287600000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Oct 06, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: false
          },
          {
            id: 171438,
            companyId: 674812,
            userId: 6229,
            title: 'OTC Markets Group Welcomes New OTCQB Companies  October 3',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: false,
            createdDate: 1506979649981,
            lastModifiedDate: 1507028764527,
            releaseDate: 1507028400000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Oct 03, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: false
          },
          {
            id: 171354,
            companyId: 674812,
            userId: 2316,
            title: 'OTC Markets Group and the Canadian Securities Exchange to Announce Strategic Alliance',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1506945745000,
            lastModifiedDate: 1506945745000,
            releaseDate: 1506945745000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'PRNS',
            displayDateTime: 'Oct 02, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 171331,
            companyId: 674812,
            userId: 2316,
            title: 'OTC Markets Group Welcomes Change Financial to OTCQX',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1506942139000,
            lastModifiedDate: 1506942139000,
            releaseDate: 1506942139000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'PRNS',
            displayDateTime: 'Oct 02, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 171357,
            companyId: 674812,
            userId: 6229,
            title: 'OTC Markets Group Welcomes New OTCQB Companies  October 2',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: false,
            createdDate: 1506945973927,
            lastModifiedDate: 1506945973927,
            releaseDate: 1506942000000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Oct 02, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: false
          },
          {
            id: 171188,
            companyId: 674812,
            userId: 6229,
            title: 'OTC Markets Group Welcomes New OTCQB Companies  September 29',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: false,
            createdDate: 1506650203031,
            lastModifiedDate: 1506683166123,
            releaseDate: 1506682800000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Sep 29, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: false
          },
          {
            id: 170857,
            companyId: 674812,
            userId: 6229,
            title: 'OTC Markets Group Welcomes New OTCQB Companies  September 27',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: false,
            createdDate: 1506459154584,
            lastModifiedDate: 1506510364965,
            releaseDate: 1506510000000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Sep 27, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: false
          },
          {
            id: 170697,
            companyId: 674812,
            userId: 6229,
            title: 'OTC Markets Group Welcomes New OTCQB Companies  September 26',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: false,
            createdDate: 1506374910868,
            lastModifiedDate: 1506423965563,
            releaseDate: 1506423600000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Sep 26, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: false
          },
          {
            id: 170626,
            companyId: 674812,
            userId: 2316,
            title: 'OTC Markets Group Announces Agenda for OTCQX Virtual Investor Conference on October 5',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1506342965000,
            lastModifiedDate: 1506342965000,
            releaseDate: 1506342965000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'PRNS',
            displayDateTime: 'Sep 25, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 170625,
            companyId: 674812,
            userId: 2316,
            title: 'OTC Markets Group Announces Agenda for OTCQX Virtual Investor Conference on October 5',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1506342959000,
            lastModifiedDate: 1506342959000,
            releaseDate: 1506342959000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'PRNS',
            displayDateTime: 'Sep 25, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 170578,
            companyId: 674812,
            userId: 2316,
            title: 'VIDEO: easyJet Head of Investor Relations, Stuart Morgan, Featured in OTCQX Video Series',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1506338174000,
            lastModifiedDate: 1506338174000,
            releaseDate: 1506338174000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'PRNS',
            displayDateTime: 'Sep 25, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 170555,
            companyId: 674812,
            userId: 6229,
            title: 'OTC Markets Group Welcomes New OTCQB Companies  September 25',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: false,
            createdDate: 1506116603641,
            lastModifiedDate: 1506337568550,
            releaseDate: 1506337200000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Sep 25, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: false
          },
          {
            id: 170385,
            companyId: 674812,
            userId: 2316,
            title: 'OTC Markets Group Welcomes Dragon Jade to OTCQX',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1505992544000,
            lastModifiedDate: 1505992544000,
            releaseDate: 1505992544000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'PRNS',
            displayDateTime: 'Sep 21, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 170244,
            companyId: 674812,
            userId: 2316,
            title: 'OTC Markets Group Welcomes Beleave to OTCQX',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1505906145000,
            lastModifiedDate: 1505906145000,
            releaseDate: 1505906145000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'PRNS',
            displayDateTime: 'Sep 20, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 170067,
            companyId: 674812,
            userId: 6229,
            title: 'OTC Markets Group Welcomes New OTCQB Companies  September 20',
            typeId: 'PR',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: false,
            createdDate: 1505770088995,
            lastModifiedDate: 1505905562701,
            releaseDate: 1505905200000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Sep 20, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: false
          },
          {
            id: 170103,
            companyId: 674812,
            userId: 2316,
            title: 'OTC Markets Group Inc. to Present at the Sidoti & Company Fall 2017 Convention',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1505819742000,
            lastModifiedDate: 1505819742000,
            releaseDate: 1505819742000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'OTCM',
            isCaveatEmptor: false,
            sourceId: 'PRNS',
            displayDateTime: 'Sep 19, 2017',
            display: true,
            tierCode: 'QX',
            isItemFromExternalProvider: false,
            immediate: true
          }
        ],
        singular: 'News Item',
        plural: 'News Items'
      }

    case 'VLKAY':
      return {
        totalRecords: 134,
        pages: 7,
        currentPage: 1,
        pageSize: 20,
        sortOn: 'releaseDate',
        sortDir: 'DESC',
        records: [
          {
            id: 121110,
            companyId: 1471,
            userId: 11462,
            title: 'CO2 issue largely concluded',
            typeId: 'PR',
            statusId: 'A',
            location: 'Wolfsburg, Germany',
            isImmediate: true,
            createdDate: 1449671936000,
            lastModifiedDate: 1449671936000,
            releaseDate: 1449671936000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Dec 09, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 120111,
            companyId: 1471,
            userId: 2316,
            title: 'Hagens Berman and Quinn Emanuel File New Lawsuit Against VW for Tainted 3.0-Liter Diesels',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1448407143000,
            lastModifiedDate: 1448407143000,
            releaseDate: 1448407143000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'BUSI',
            displayDateTime: 'Nov 24, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 119870,
            companyId: 1471,
            userId: 2316,
            title: 'VOLKSWAGEN 96 HOUR DEADLINE ALERT: Approximately 96 Hours Remain; Former Louisiana Attorney General and Kahn Swick & Foti, LLC Remind Investors of Deadline in Class Action Lawsuit Against Volkswagen AG – VLKAY, VLKPY & VLKAF',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1448075507000,
            lastModifiedDate: 1448075507000,
            releaseDate: 1448075507000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'GLOBE',
            displayDateTime: 'Nov 21, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 119869,
            companyId: 1471,
            userId: 2316,
            title: 'VOLKSWAGEN 96 HOUR DEADLINE ALERT: Approximately 96 Hours Remain; Former Louisiana Attorney General and Kahn Swick & Foti, LLC Remind Investors of Deadline in Class Action Lawsuit Against Volkswagen AG – VLKAY, VLKPY & VLKAF',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1448075505000,
            lastModifiedDate: 1448075505000,
            releaseDate: 1448075505000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'GLOBE',
            displayDateTime: 'Nov 21, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 119868,
            companyId: 1471,
            userId: 2316,
            title: 'VOLKSWAGEN 96 HOUR DEADLINE ALERT: Approximately 96 Hours Remain; Former Louisiana Attorney General and Kahn Swick & Foti, LLC Remind Investors of Deadline in Class Action Lawsuit Against Volkswagen AG – VLKAY, VLKPY & VLKAF',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1448075497000,
            lastModifiedDate: 1448075497000,
            releaseDate: 1448075497000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'GLOBE',
            displayDateTime: 'Nov 21, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 119861,
            companyId: 1471,
            userId: 2316,
            title: 'SHAREHOLDER ALERT: Pomerantz Law Firm Reminds Shareholders With Losses on Their Investments in Volkswagen AG and Volkswagen Group of America of Class Action Lawsuit and Upcoming Deadline - VLKAY',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1448060442000,
            lastModifiedDate: 1448060442000,
            releaseDate: 1448060442000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'MKTW',
            displayDateTime: 'Nov 20, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 119455,
            companyId: 1471,
            userId: 2316,
            title: 'Kendall Law Group Files Class Action Lawsuit against Volkswagen AG',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447783720000,
            lastModifiedDate: 1447783720000,
            releaseDate: 1447783720000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'BUSI',
            displayDateTime: 'Nov 17, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 119454,
            companyId: 1471,
            userId: 2316,
            title: 'Kendall Law Group Files Class Action Lawsuit against Volkswagen AG',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447783718000,
            lastModifiedDate: 1447783718000,
            releaseDate: 1447783718000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'BUSI',
            displayDateTime: 'Nov 17, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 119453,
            companyId: 1471,
            userId: 2316,
            title: 'Kendall Law Group Files Class Action Lawsuit against Volkswagen AG',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447783711000,
            lastModifiedDate: 1447783711000,
            releaseDate: 1447783711000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'BUSI',
            displayDateTime: 'Nov 17, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 119012,
            companyId: 1471,
            userId: 2316,
            title: 'SHAREHOLDER ALERT: Levi & Korsinsky, LLP Reminds Shareholders of Volkswagen AG of Pendency of Class Action Lawsuit and a Lead Plaintiff Deadline of November 24, 2015 – VLKAY, VLKPY, VLKAF',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447360129000,
            lastModifiedDate: 1447360129000,
            releaseDate: 1447360129000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'GLOBE',
            displayDateTime: 'Nov 12, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 119011,
            companyId: 1471,
            userId: 2316,
            title: 'SHAREHOLDER ALERT: Levi & Korsinsky, LLP Reminds Shareholders of Volkswagen AG of Pendency of Class Action Lawsuit and a Lead Plaintiff Deadline of November 24, 2015 – VLKAY, VLKPY, VLKAF',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447360127000,
            lastModifiedDate: 1447360127000,
            releaseDate: 1447360127000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'GLOBE',
            displayDateTime: 'Nov 12, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 119010,
            companyId: 1471,
            userId: 2316,
            title: 'SHAREHOLDER ALERT: Levi & Korsinsky, LLP Reminds Shareholders of Volkswagen AG of Pendency of Class Action Lawsuit and a Lead Plaintiff Deadline of November 24, 2015 – VLKAY, VLKPY, VLKAF',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447360120000,
            lastModifiedDate: 1447360120000,
            releaseDate: 1447360120000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'GLOBE',
            displayDateTime: 'Nov 12, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 118877,
            companyId: 1471,
            userId: 2316,
            title: 'SECURITIES ALERT: Rosen Law Firm Reminds Volkswagen AG Investors of Important November 24, 2015 Class Action Deadline– VLKAY, VLKPY, VLKAF',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447286022000,
            lastModifiedDate: 1447286022000,
            releaseDate: 1447286022000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'GLOBE',
            displayDateTime: 'Nov 11, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 118876,
            companyId: 1471,
            userId: 2316,
            title: 'SECURITIES ALERT: Rosen Law Firm Reminds Volkswagen AG Investors of Important November 24, 2015 Class Action Deadline– VLKAY, VLKPY, VLKAF',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447286020000,
            lastModifiedDate: 1447286020000,
            releaseDate: 1447286020000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'GLOBE',
            displayDateTime: 'Nov 11, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 118875,
            companyId: 1471,
            userId: 2316,
            title: 'SECURITIES ALERT: Rosen Law Firm Reminds Volkswagen AG Investors of Important November 24, 2015 Class Action Deadline– VLKAY, VLKPY, VLKAF',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447286013000,
            lastModifiedDate: 1447286013000,
            releaseDate: 1447286013000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'GLOBE',
            displayDateTime: 'Nov 11, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 118788,
            companyId: 1471,
            userId: 5928,
            title: 'Books will close today November 11th, 2015.',
            typeId: 'A',
            statusId: 'A',
            location: 'New York, NY',
            isImmediate: true,
            createdDate: 1447245304000,
            lastModifiedDate: 1447245304000,
            releaseDate: 1447245304000,
            canDistribute: false,
            wasDistributed: false,
            newsTypeDescript: 'Announcement',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'OTCM',
            displayDateTime: 'Nov 11, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 118531,
            companyId: 1471,
            userId: 2316,
            title: 'INVESTOR ALERT: Lundin Law PC Announces Ongoing Investigation of Volkswagen AG and Encourages Investors with Losses in Excess of $100,000 to Contact the Firm',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447063807000,
            lastModifiedDate: 1447063807000,
            releaseDate: 1447063807000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'BUSI',
            displayDateTime: 'Nov 09, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 118530,
            companyId: 1471,
            userId: 2316,
            title: 'INVESTOR ALERT: Lundin Law PC Announces Ongoing Investigation of Volkswagen AG and Encourages Investors with Losses in Excess of $100,000 to Contact the Firm',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447063805000,
            lastModifiedDate: 1447063805000,
            releaseDate: 1447063805000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'BUSI',
            displayDateTime: 'Nov 09, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 118529,
            companyId: 1471,
            userId: 2316,
            title: 'INVESTOR ALERT: Lundin Law PC Announces Ongoing Investigation of Volkswagen AG and Encourages Investors with Losses in Excess of $100,000 to Contact the Firm',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1447063799000,
            lastModifiedDate: 1447063799000,
            releaseDate: 1447063799000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'BUSI',
            displayDateTime: 'Nov 09, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          },
          {
            id: 118518,
            companyId: 1471,
            userId: 2316,
            title: 'VOLKSWAGEN SHAREHOLDER ALERT BY FORMER LOUISIANA ATTORNEY GENERAL: Kahn Swick & Foti, LLC Reminds Investors with Losses in Excess of $100,000 of Lead Plaintiff Deadline in Class Action Lawsuit Against Volkswagen AG',
            typeId: 'PR',
            statusId: 'A',
            isImmediate: true,
            createdDate: 1446865750000,
            lastModifiedDate: 1446865750000,
            releaseDate: 1446865750000,
            canDistribute: true,
            wasDistributed: false,
            newsTypeDescript: 'Press Release',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            isCaveatEmptor: false,
            sourceId: 'BUSI',
            displayDateTime: 'Nov 07, 2015',
            display: true,
            tierCode: 'PC',
            isItemFromExternalProvider: false,
            immediate: true
          }
        ],
        singular: 'News Item',
        plural: 'News Items'
      }
  }
}
