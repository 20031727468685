import api, { paginate } from '../../api'

export async function getSponsors (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    url: '/service-provider/sponsor',
    params: Object.assign({}, params, { pageSize: 999, page: 1 })
  })

  let records = (response.data && response.data.records) || []

  // Filter by typeId if specified
  if (params.typeId !== undefined && params.typeId !== -1) {
    records = records.filter(record => record.typeId === params.typeId)
  }

  // Sort alphabetically if sort isn't specified
  if (!params.sortOn) {
    records.sort((a, b) => a.name.localeCompare(b.name))
  }

  return paginate({ records }, params)
}

export async function getDetails (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    url: '/service-provider/detail',
    params
  })
  return response.data
}

export async function getAssociated (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    url: '/service-provider/sponsor/associated',
    params
  })
  return response.data
}

export async function getAllSponsoredCompanies (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    url: '/service-provider/sponsored',
    params
  })
  return response.data
}
