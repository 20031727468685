import api, { paginate, sort } from '../../api'

export async function getSnapshot () {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'array',
    mockData: 'indices/indices/snapshot',
    url: '/index/snapshot'
  })
  return response.data
}

export async function getQuote (symbol) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    url: `/index/quote/${symbol}`
  })
  return response.data
}

export async function getConstituents (params) {
  if (!params.symbol) return
  const files = {
    '.otcdr': 'otcm_adr_list.json',
    '.otcqx': 'otcqx_composite_list.json',
    '.otcqx30': 'otcqx_30_list.json',
    '.otcqxus': 'otcqx_us_list.json',
    '.otcqxint': 'otcqx_international_list.json',
    '.otcqxbk': 'otcqx_banks_list.json',
    '.otcqxbil': 'otcqx_billion_list.json',
    '.otcqxdiv': 'otcqx_dividend_list.json',
    '.otcqxcan': 'otcqx_canada_list.json',
    '.otcqb': 'otcqb_composite_list.json',
    '.otcqxmj': 'otcqx_cannabis_list.json'
  }
  const file = files[params.symbol.toLowerCase()]
  if (!file) return
  const response = await api.request({
    method: 'get',
    otcS3: true,
    responseFormat: 'data',
    url: `constituents/${file}`
  })

  response.data = sort(response.data, params)
  return paginate({ records: response.data }, params)
}

export function getConstituentsDownload (symbol) {
  if (!symbol) return
  const files = {
    '.otcdr': 'OTCM-ADR-Index-Constituent-List.xls',
    '.otcqx': 'OTCQX-COMPOSITE-Index-Constituent-List.xls',
    '.otcqx30': 'OTCQX-30-ADR-Index-Constituent-List.xls',
    '.otcqxus': 'OTCQX-US-Index-Constituent-List.xls',
    '.otcqxint': 'OTCQX-INTERNATIONAL-Index-Constituent-List.xls',
    '.otcqxbk': 'OTCQX-BANKS-Index-Constituent-List.xls',
    '.otcqxbil': 'OTCQX-BILLION-Index-Constituent-List.xls',
    '.otcqxdiv': 'OTCQX-DIVIDEND-Index-Constituent-List.xls',
    '.otcqxcan': 'OTCQX-canada-Index-Constituent-List.xls',
    '.otcqb': 'OTCQB-COMPOSITE-Index-Constituent-List.xls',
    '.otcqxmj': 'OTCQX-CANNABIS-Index-Constituent-List.xls'
  }
  const file = files[symbol.toLowerCase()]
  if (!file) return
  return api.link({ otcS3: true, url: `otcs3/constituents/${file}` })
}
