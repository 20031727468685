import React from 'react'
import PropTypes from 'prop-types'
import { Button, TextLink } from '@otcmarketsgroup/otcm-ui'
import { withContext } from '../../AuthContext'
import styles from '../LogIn/LogIn.module.scss'

class AuthorizeLogin extends React.PureComponent {
  render () {
    const { context } = this.props

    return <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.greeting}>
          <h3 className={styles.formTitle}>Active session</h3>
          <p className={styles.formDesc}>
            Your account is currently signed in elsewhere. By continuing you will be signed out from your other session.
          </p>
        </div>
        <Button className={styles.submit} title='Continue' fullWidth onClick={() => context.login()} />
        <TextLink onClick={() => context.resetLogin()}>Cancel</TextLink>
        {context.error && <i className={styles.error}>{context.error}</i>}
      </div>
    </div>
  }
}

AuthorizeLogin.propTypes = {
  context: PropTypes.shape({
    isAuth: PropTypes.bool,
    submitLogin: PropTypes.func,
    login: PropTypes.func,
    resetLogin: PropTypes.func,
    error: PropTypes.string,
    changeState: PropTypes.func
  })
}

export default withContext(AuthorizeLogin)
