import React from 'react'
import PropTypes from 'prop-types'
import styles from './CompanyQuoteOverview.module.scss'
import { logPageview } from '../../analytics'
import classNames from 'classnames'
import {
  getTotals,
  getPublicInterestTotals,
  getIssuerStatusTotals,
  getTradeActivityTotals,
  getCorpActionTotals,
  getIssuanceTotals
} from '../../api/otc/compliance/totals'

import { getEdgarNews } from '../../api/otc/company/news'
import { getExternalNewsUrl } from '../../utils/news-urls'
import { template, fetch } from '../../api/helper'
import { format } from '../../utils/locale'

import { FaCaretUp, FaCaretDown } from 'react-icons/fa'
import Row from '../Row'
import Loading from '../Loading'
import Hide from '../Hide'
import Chart from '../Chart'
import Outline from '../Outline'
import QuoteInside from '../QuoteInside'
import Accordion from '../Accordion'
import More from '../More'
import { HeaderAccordion, AccordionItem } from '../HeaderAccordion'
import { OTC_ANALYTICS, SMALL_CAP_LISTED } from '../../constants/groups'

const NOT_AVAILABLE = 'N/A'

class CompanyQuoteOverview extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
      this.state = {
      edgarNews: template('records'),
      totals: template('records'),
      publicInterestTotals: template('records'),
      issuerStatusTotals: template('records'),
      tradingActivityTotals: template('records'),
      corpActionsTotals: template('records'),
      issuanceTotals: template('records'),
      isRiskScoreEligible: false
    }
  }

  componentDidMount () {
    const user = this.props.user
    const isOtcAnalyticsEntitled = user && user.userGroups && user.userGroups.find(group => (group === OTC_ANALYTICS))
    const isSmallCapEntitled = user && user.userGroups && user.userGroups.find(group => (group === SMALL_CAP_LISTED))
    const { company, info } = this.props

    const title = `OTC Markets | ${this.props.company} | Overview`
    logPageview(title)

    const isRiskScoreEligible = (info.isEquity && isOtcAnalyticsEntitled) || (info.isListed && isSmallCapEntitled)
    this.setState({ isRiskScoreEligible })

    if (isRiskScoreEligible) {
      this.fetchData()
    }

    if (!info.isOTC) {
      fetch(this, getEdgarNews, 'edgarNews', {
        symbol: company,
        page: 1,
        pageSize: 5,
        sortOn: '1',
        sortDir: 'D'
      }, data => {
        if (!data) data = {}
        if (data === '') data = {}
        if (!data.records) data.records = []

        data.records = this.formatEdgarNews(data.records)
        return data
      })
    }
  }

  checkHash = () => {
    // If anchor is supplied in hash, scroll into view
    const hash = window.location.hash
    if (hash && hash.length > 1) {
      const el = document.getElementById(hash.substr(1))
      if (el) {
        const elOffSet = el.offsetTop
        window.scrollTo({ top: elOffSet - 100 })
      }
    }
  }

  fetchData = async () => {
    const { company } = this.props

    fetch(this, getTotals, 'totals', {
      symbol: company
    }).then(() => this.checkHash())
    fetch(this, getPublicInterestTotals, 'publicInterestTotals', {
      symbol: company
    }).then(() => this.checkHash())
    fetch(this, getIssuerStatusTotals, 'issuerStatusTotals', {
      symbol: company
    }).then(() => this.checkHash())
    fetch(this, getTradeActivityTotals, 'tradingActivityTotals', {
      symbol: company
    }).then(() => this.checkHash())
    fetch(this, getCorpActionTotals, 'corpActionsTotals', {
      symbol: company
    }).then(() => this.checkHash())
    fetch(this, getIssuanceTotals, 'issuanceTotals', {
      symbol: company
    }).then(() => this.checkHash())
  }

  formatEdgarNews = (news) => {
    if (!news) return []
    return news.map(newsItem => {
      const url = getExternalNewsUrl(this.props.company, newsItem)
      return {
        title: newsItem.headline,
        subtitle: format(newsItem.sourceName, '', 'text') +
          (newsItem.sourceName && newsItem.pubDate ? ' | ' : '') +
          format(newsItem.pubDate, '', 'date'),
        id: newsItem.id,
        to: newsItem.id ? url : newsItem.link
      }
    })
  }

  render () {
    const { accent, company, info, inside, showRiskScoring } = this.props

    const totals = this.state.totals.data
    const pubIntConcern = this.state.publicInterestTotals.data
    const issuerStatus = this.state.issuerStatusTotals.data
    const tradingActivity = this.state.tradingActivityTotals.data
    const coporateActions = this.state.corpActionsTotals.data
    const issuanceDilution = this.state.issuanceTotals.data
    const prohibServProvList = pubIntConcern && pubIntConcern.prohibitedServiceProviderDesc && pubIntConcern.prohibitedServiceProviderDesc.map(provider => `${provider.name} - ${provider.role}`)
    const prohibServProvDOM = <div>
      {prohibServProvList && prohibServProvList.map((item, i) => <div key={i}>{item}</div>)}
    </div>

    return <div className={classNames(styles.CompanyQuoteOverview, styles[accent])}>
      <Hide sm>
        <Outline mode='invisible' spacing='larger'>
          <Chart symbol={company} />
        </Outline>
      </Hide>

      <QuoteInside quote={inside} profile={info} />
      {this.state.isRiskScoreEligible && showRiskScoring && <div>
        <div className={styles.dataContainer}>
          <HeaderAccordion atomic={false}>
            <AccordionItem title='Totals' accent={accent} loaded={this.state.totals.loaded} id='totals'>
              <Loading
                height='12em'
                type='table'
                loaded={this.state.totals.loaded}
                error={this.state.totals.error}>
                <Row items={[{ title: 'Total Risk Score', value: totals.totalRiskScore, bold: true, accent }]} />

                <Row items={[{
                  title: 'Change',
                  value: <div style={{ display: 'flex' }}>
                    {totals.totalRiskScoreTickDir === '+' && <FaCaretUp style={{ color: '#ff0000' }} />}
                    {totals.totalRiskScoreTickDir === '-' && <FaCaretDown style={{ color: '#00A88F' }} />}
                    <span style={{ marginLeft: '.5em' }}>{totals.prevTotalRiskScoreChange}</span>
                  </div>,
                  bold: true
                }]} />

                <Row items={[{ title: 'Previous Week TRS', value: totals.prevWeekTotalRiskScore, bold: true }]} />

                <Row items={[{ title: 'Previous Month TRS', value: totals.prevMonthTotalRiskScore, bold: true }]} />

                <Row items={[{ title: '30 Day Score Average', value: format(totals.avgThirtyDayTotalRiskScore, NOT_AVAILABLE, 'float'), bold: true }]} />
              </Loading>
            </AccordionItem>
          </HeaderAccordion>
        </div>

        <div className={styles.dataContainer}>
          <HeaderAccordion atomic={false}>
            <AccordionItem title='Public Interest Concern' loaded={this.state.publicInterestTotals.loaded} accent={accent}>
              <Loading
                height='12em'
                type='table'
                loaded={this.state.publicInterestTotals.loaded}
                error={this.state.publicInterestTotals.error}>
                <Row items={[{ title: 'Total Score', value: pubIntConcern.publicInterestTotalScore, bold: true, accent }]} />

                {info.isEquity && <Row id='caveat-emptor' items={[{ title: 'Caveat Emptor Score', value: pubIntConcern.caveatEmptorScore, bold: true, padTop: true }]} />}

                {info.isEquity &&
                <Row items={[
                  { title: 'Caveat Emptor Status', value: format(pubIntConcern.caveatEmptorStatus && pubIntConcern.caveatEmptorStatus === 'Y', NOT_AVAILABLE, 'bool') },
                  ...(pubIntConcern.caveatEmptorStatus === 'Y' && pubIntConcern.caveatEmptorStartDate)
                    ? [{ title: 'Effective', value: format(pubIntConcern.caveatEmptorStartDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/security/?type=CE Status Change#corporate-actions` }]
                    : []
                ]} />}

                {info.isEquity &&
                <Row items={[
                  { title: 'Former Caveat Emptor', value: format(pubIntConcern.formerCaveatEmptorStatus && pubIntConcern.formerCaveatEmptorStatus === 'Y', NOT_AVAILABLE, 'bool') },
                  ...(pubIntConcern.formerCaveatEmptorStatus === 'Y' && pubIntConcern.formerCaveatEmptorStartDate && pubIntConcern.formerCaveatEmptorEndDate)
                    ? [{ title: 'Effective', value: `${format(pubIntConcern.formerCaveatEmptorStartDate, NOT_AVAILABLE, 'date')}-${format(pubIntConcern.formerCaveatEmptorEndDate, NOT_AVAILABLE, 'date')}`, link: `/stock/${company}/security/?type=CE Status Change#corporate-actions` }]
                    : []
                ]} />}

                <Row id='promotion' items={[{ title: 'Promotion Score', value: pubIntConcern.promotionScore, bold: true, padTop: true }]} />
                <Row items={[
                  { title: 'Active Promotion', value: format(pubIntConcern.promotionStatus && pubIntConcern.promotionStatus === 'Y', NOT_AVAILABLE, 'bool') },
                  ...pubIntConcern.promotionStatus === 'Y' ? [{ title: 'Effective', value: format(pubIntConcern.mostRecentPromotionDate, NOT_AVAILABLE, 'date') }] : []
                ]} />

                <Row items={[
                  { title: 'Former Promotion', value: format(pubIntConcern.formerPromotionStatus && pubIntConcern.formerPromotionStatus === 'Y', NOT_AVAILABLE, 'bool') },
                  ...(pubIntConcern.formerPromotionStatus === 'Y' && pubIntConcern.formerPromotionStartDate && pubIntConcern.formerPromotionEndDate)
                    ? [{ title: 'Effective', value: `${format(pubIntConcern.formerPromotionStartDate, NOT_AVAILABLE, 'date')}-${format(pubIntConcern.formerPromotionEndDate, NOT_AVAILABLE, 'date')}` }]
                    : []
                ]} />

                <Row id='hot-sector' items={[{ title: 'Hot Sector Score', value: pubIntConcern.hotSectorScore, bold: true, padTop: true }]} />

                <Row items={[
                  { title: 'Hot Sector', value: format(pubIntConcern.hotSectorStatus && pubIntConcern.hotSectorStatus === 'Y', 'N', 'bool') }
                ]} />

                {(pubIntConcern.hotSectorStatus === 'Y') && <Row items={[
                  { title: 'Hot Sector', value: format(pubIntConcern.hotSectorStatusDesc, NOT_AVAILABLE, 'text') },
                  { title: 'Effective', value: format(pubIntConcern.hotSectorStartDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/profile/#description` }
                ]} />}

                <Row items={[
                  { title: 'Former Hot Sector', value: format(pubIntConcern.formerHotSectorStatus && pubIntConcern.formerHotSectorStatus === 'Y', 'N', 'bool') }
                ]} />
                {(pubIntConcern.formerHotSectorStatus === 'Y') && <Row items={[
                  { title: 'Hot Sector', value: format(pubIntConcern.formerHotSectorStatusDesc, NOT_AVAILABLE, 'text') },
                  { title: 'Effective', value: `${format(pubIntConcern.formerHotSectorStartDate, NOT_AVAILABLE, 'date')}-${format(pubIntConcern.formerHotSectorEndDate, NOT_AVAILABLE, 'date')}`, link: `/stock/${company}/profile/#description` }
                ]} />}

                {info.isEquity && <>
                  <Row id='control-dispute' items={[{ title: 'Control Dispute Score', value: pubIntConcern.controlDisputeScore, bold: true, padTop: true }]} />
                  <Row items={[
                    { title: 'Current Control Dispute', value: format(pubIntConcern.controlDisputeStatus && pubIntConcern.controlDisputeStatus === 'Y', 'N', 'bool'), link: `/stock/${company}/profile/#description` },
                    ...pubIntConcern.controlDisputeStatus === 'Y' ? [{ title: 'Effective', value: format(pubIntConcern.controlDisputeStartDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/profile/#description` }] : []
                  ]} />
                </>}

                {info.isEquity && <>
                  <Row id='prohibited-service-provider' items={[{ title: 'Prohibited Service Provider Score ', value: pubIntConcern.prohibitedServiceProviderScore, bold: true, padTop: true }]} />
                  {(pubIntConcern.prohibitedServiceProviderStatus !== 'Y') && <Row items={[
                    { title: 'Prohibited Service Provider(s)', value: format(pubIntConcern.prohibitedServiceProviderStatus && pubIntConcern.prohibitedServiceProviderStatus === 'Y', 'N', 'bool'), link: `/stock/${company}/profile/#service-providers` }
                  ]} />}

                  {(pubIntConcern.prohibitedServiceProviderStatus === 'Y') && <Row items={[
                    { title: 'Prohibited Service Provider(s)', value: prohibServProvList && prohibServProvList.length > 0 ? prohibServProvDOM : 'Y', link: `/stock/${company}/profile/#service-providers` }
                  ]} />}
                </>}

                {!info.isOTC && <>
                  <Row id='exchange-deficiency' items={[{ title: 'Exchange Deficiency Score', value: format(pubIntConcern.exchangeDeficiencyScore, NOT_AVAILABLE, 'int'), bold: true, padTop: true }]} />
                </>}

                {!info.isOTC && <>
                  <Row items={[
                    { title: 'Current Exchange Deficiency', value: format(pubIntConcern.currentExchangeDeficiencyFlag === 'Y', 'N', 'bool') }
                  ]} />
                  {(pubIntConcern.currentExchangeDeficiencyFlag === 'Y') && <Row items={[
                    { title: pubIntConcern.currentDeficiencyTypes.includes(';') ? 'Deficiency Types' : 'Deficiency Type', value: format(pubIntConcern.currentDeficiencyTypes, NOT_AVAILABLE, 'textList'), link: `/stock/${company}/disclosure` },
                    { title: 'As of', value: format(pubIntConcern.currentDeficiencyTypesAsOf, NOT_AVAILABLE, 'dateList'), link: `/stock/${company}/disclosure` }
                  ]} />}
                </>}

                {!info.isOTC && <>
                  <Row items={[
                    { title: 'Former Exchange Deficiency', value: format(pubIntConcern.formerExchangeDeficiency === 'Y', 'N', 'bool') }
                  ]} />
                  {(pubIntConcern.formerExchangeDeficiency === 'Y') && <Row items={[
                    { title: pubIntConcern.formerDeficiencyTypes.includes(';') ? 'Deficiency Types' : 'Deficiency Type', value: format(pubIntConcern.formerDeficiencyTypes, NOT_AVAILABLE, 'textList'), link: `/stock/${company}/disclosure` },
                    { title: 'As of', value: format(pubIntConcern.formerDeficiencyTypesAsOf, NOT_AVAILABLE, 'dateList'), link: `/stock/${company}/disclosure` }
                  ]} />}
                </>}
              </Loading>
            </AccordionItem>
          </HeaderAccordion>
        </div>

        <div className={styles.dataContainer}>
          <HeaderAccordion atomic={false}>
            <AccordionItem title='Security / Issuer Status' loaded={this.state.issuerStatusTotals.loaded} accent={accent}>
              <Loading
                height='12em'
                type='table'
                loaded={this.state.issuerStatusTotals.loaded}
                error={this.state.issuerStatusTotals.error}>
                <Row items={[{ title: 'Total Score', value: issuerStatus.totalSecurityScore, bold: true, accent }]} />

                {info.isEquity && <Row id='tier-change' items={[{ title: 'OTC Tier Score', value: issuerStatus.otcTierScore, bold: true, padTop: true }]} />}

                {info.isListed && <>
                  <Row id='otc-graduate' items={[{ title: 'OTC Graduate Score', value: issuerStatus.otcGraduateScore, bold: true, padTop: true }]} />
                  <Row items={[
                    { title: 'Former OTC Tier', value: issuerStatus.otcGraduateFormerTier },
                    { title: 'OTC Graduate Date', value: format(issuerStatus.otcGraduateEffectiveDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/security/?type=Market Change#corporate-actions` }
                  ]} /></>}

                {info.isEquity && <>
                  <Row items={[
                    { title: 'Tier', value: issuerStatus.market, padTop: true },
                    { title: 'Effective', value: format(issuerStatus.otcTierEffectiveDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/security/?type=Market Change#corporate-actions` }
                  ]} /></>}

                {info.isEquity && <>
                  <Row id='security-status' items={[{ title: 'Security Status Score', value: issuerStatus.securityStatusScore, bold: true, padTop: true }]} />
                  <Row items={[
                    { title: 'Security Status', value: issuerStatus.securityStatus },
                    { title: 'Effective', value: format(issuerStatus.securityStatusEffectiveDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/security/?type=Security Status Change#corporate-actions` }
                  ]} /></>}

                {!info.isListed && <>
                  <Row items={[
                    { title: 'Former Security Status', value: format(issuerStatus.formerSecurityStatus && issuerStatus.formerSecurityStatus === 'Y', NOT_AVAILABLE, 'bool') },
                    ...issuerStatus.formerSecurityStatus === 'Y'
                      ? [{ title: 'Effective', value: `${format(issuerStatus.formerSecurityStatusStartDate, NOT_AVAILABLE, 'date')}-${format(issuerStatus.formerSecurityStatusEndDate, NOT_AVAILABLE, 'date')}`, link: `/stock/${company}/security/?type=Security Status Change#corporate-actions` }]
                      : []
                  ]} /></>}

                <Row id='penny-stock-status' items={[{ title: 'Penny Stock Status', value: issuerStatus.pennyStockStatusScore, bold: true, padTop: true }]} />
                <Row items={[
                  { title: 'Price Exemption', value: format(issuerStatus.priceExemption && issuerStatus.priceExemption === 'Y', NOT_AVAILABLE, 'bool') },
                  { title: 'Closing Bid Price', value: format(issuerStatus.closingInsideBid, NOT_AVAILABLE, 'price') },
                  { title: 'As of', value: format(issuerStatus.priceExemptDate, NOT_AVAILABLE, 'date') }
                ]} />
                <Row items={[
                  { title: 'NTA Exemption', value: format(issuerStatus.netTangibleAssetExemption && issuerStatus.netTangibleAssetExemption === 'Y', NOT_AVAILABLE, 'bool') },
                  { title: 'NTA', link: `/stock/${company}/financials`, value: format(issuerStatus.netTangibleAssets, NOT_AVAILABLE, 'int') },
                  { title: 'As of', link: `/stock/${company}/financials`, value: format(issuerStatus.avgRevenueAsofDate, NOT_AVAILABLE, 'date') }
                ]} />
                <Row items={[
                  { title: 'Avg. Revenue Exemption', value: format(issuerStatus.averageRevenueExemption && issuerStatus.averageRevenueExemption === 'Y', NOT_AVAILABLE, 'bool') },
                  { title: 'Avg. Revenue', link: `/stock/${company}/financials`, value: format(issuerStatus.averageRevenue, NOT_AVAILABLE, 'int') },
                  { title: 'As of', link: `/stock/${company}/financials`, value: format(issuerStatus.avgRevenueAsofDate, NOT_AVAILABLE, 'date') }
                ]} />

                <Row id='shell-status' items={[{ title: 'Shell Status Score', value: issuerStatus.shellStatusScore, bold: true, padTop: true }]} />
                <Row items={[
                  { title: 'Current Shell', value: format(issuerStatus.currentShellStatus && issuerStatus.currentShellStatus === 'Y', NOT_AVAILABLE, 'bool') },
                  ...issuerStatus.currentShellStatus === 'Y' ? [{ title: 'Effective', value: format(issuerStatus.currentShellDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/profile/#profile-data` }] : []
                ]} />

                {!info.isListed && <>
                  <Row items={[
                    { title: 'Former Shell', value: format(issuerStatus.formerShellStatus && issuerStatus.formerShellStatus === 'Y', NOT_AVAILABLE, 'bool') },
                    ...issuerStatus.formerShellStatus === 'Y'
                      ? [{ title: 'Effective', value: `${format(issuerStatus.formerShellStartDate, NOT_AVAILABLE, 'date')}-${format(issuerStatus.formerShellEndDate, NOT_AVAILABLE, 'date')}`, link: `/stock/${company}/profile/#profile-data` }]
                      : []
                  ]} /></>}

                {info.isEquity && <>
                  <Row id='shell-risk-status' items={[{ title: 'Shell Risk Status Score', value: issuerStatus.shellRiskStatusScore, bold: true, padTop: true }]} />
                  <Row items={[
                    { title: 'Current Shell Risk', value: format(issuerStatus.currentShellRiskStatus && issuerStatus.currentShellRiskStatus === 'Y', NOT_AVAILABLE, 'bool') },
                    ...issuerStatus.currentShellRiskStatus ? [{ title: 'Effective', link: `/stock/${company}/financials`, value: format(issuerStatus.currentShellRiskAsofDate, NOT_AVAILABLE, 'date') }] : []
                  ]} /></>}

                <Row items={[
                  { title: 'Operating Revenue', link: `/stock/${company}/financials`, value: format(issuerStatus.operatingRevenue, NOT_AVAILABLE, 'int') },
                  { title: 'As of', link: `/stock/${company}/financials`, value: format(issuerStatus.operatingRevenueAsofDate, NOT_AVAILABLE, 'date') }]} />

                <Row items={[
                  { title: 'Total Assets', link: `/stock/${company}/financials`, value: format(issuerStatus.totalAssets, NOT_AVAILABLE, 'int') },
                  { title: 'As of', link: `/stock/${company}/financials`, value: format(issuerStatus.totalAssetsAsofDate, NOT_AVAILABLE, 'date') }]} />

                <Row items={[
                  { title: 'Profit/Loss', link: `/stock/${company}/financials`, value: format(issuerStatus.gross_profit, NOT_AVAILABLE, 'int') },
                  { title: 'As of', link: `/stock/${company}/financials`, value: format(issuerStatus.grossProfitAsofDate, NOT_AVAILABLE, 'date') }]} />

                <Row items={[
                  { title: 'Former Shell Risk', value: format(issuerStatus.formerShellRiskStatus && issuerStatus.formerShellRiskStatus === 'Y', NOT_AVAILABLE, 'bool') },
                  ...issuerStatus.formerShellRiskStatus === 'Y'
                    ? [{ title: 'Effective', value: `${format(issuerStatus.formerShellRiskStartDate, NOT_AVAILABLE, 'date')}-${format(issuerStatus.formerShellRiskEndDate, NOT_AVAILABLE, 'date')}`, link: `/stock/${company}/financials` }]
                    : []
                ]} />

                {info.isEquity && <>
                  <Row id='delinquency' items={[{ title: 'Delinquency Score', value: issuerStatus.delinquencyStatusScore, bold: true, padTop: true }]} />
                  <Row items={[
                    { title: 'Delinquent', value: format(issuerStatus.delinquencyStatus && issuerStatus.delinquencyStatus === 'Y', NOT_AVAILABLE, 'bool') },
                    { title: 'Last Report', value: issuerStatus.lastReportType, link: `/stock/${company}/disclosure` },
                    { title: 'Effective', value: format(issuerStatus.lastReportDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/disclosure` } // add href link
                  ]} /></>}

                {info.isEquity && <>
                  <Row id='bankruptcy' items={[{ title: 'Bankruptcy Score', value: issuerStatus.bankruptcyScore, bold: true, padTop: true }]} />
                  <Row items={[
                    { title: 'Bankrupt', value: format(issuerStatus.bankruptcystatus && issuerStatus.bankruptcystatus === 'Y', NOT_AVAILABLE, 'bool') }, // IF N no not have the next object
                    ...issuerStatus.bankruptcystatus === 'Y'
                      ? [{ title: 'Effective', value: format(issuerStatus.bankruptAsofDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/profile` }]
                      : []
                  ]} />
                </>}

                {info.isEquity && <>
                  <Row id='no-foreign-listing' items={[{ title: 'Foreign Issuer – No Foreign Listing Score ', value: issuerStatus.foreignIssuerNoForeignListingScore, bold: true, padTop: true }]} />
                  <Row items={[
                    { title: 'Foreign Issuer – No Foreign Listing', value: format(issuerStatus.foreignIssuerNoForeignListingStatus && issuerStatus.foreignIssuerNoForeignListingStatus === 'Y', NOT_AVAILABLE, 'bool') },
                    ...issuerStatus.foreignIssuerNoForeignListingStatus === 'Y' ? [{ title: 'Country of Domicile', value: format(issuerStatus.countryOfDomicile, NOT_AVAILABLE, 'text') }] : []
                  ]} />
                </>}
              </Loading>
            </AccordionItem>
          </HeaderAccordion>
        </div>

        <div className={styles.dataContainer}>
          <HeaderAccordion atomic={false}>
            <AccordionItem title='Trading Activity' loaded={this.state.tradingActivityTotals.loaded} accent={accent}>
              <Loading
                height='12em'
                type='table'
                loaded={this.state.tradingActivityTotals.loaded}
                error={this.state.tradingActivityTotals.error}>
                <Row items={[{ title: 'Total Score', value: tradingActivity.tradeActivityTotalScore, bold: true, accent }]} />

                <Row id='price' items={[{ title: 'Price Score', value: tradingActivity.priceScore, bold: true, padTop: true }]} />
                <Row items={[
                  { title: 'Last Sale', value: format(tradingActivity.lastSalePrice, NOT_AVAILABLE, 'price'), link: `/stock/${company}/quote/#trade-data` },
                  { title: 'As of', value: format(tradingActivity.lastSalePriceAsOfDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/quote/#trade-data` }
                ]} />

                <Row id='price-change' items={[{ title: 'Price Change Score', value: tradingActivity.priceChangeScore, bold: true, padTop: true }]} />

                <Row items={[
                  { title: 'Nominal Change', value: format(tradingActivity.priceNominalChange, NOT_AVAILABLE, 'price'), link: `/stock/${company}/quote/#trade-data` },
                  { title: 'Percent Change', value: format(tradingActivity.pricePctChange, NOT_AVAILABLE, 'percentage'), link: `/stock/${company}/quote/#trade-data` },
                  { title: '30 Day Mov. Price', value: tradingActivity.thirtyDayMovePrice, link: `/stock/${company}/quote/#trade-data` }
                ]} />

                <Row id='market-cap' items={[{ title: 'Market Cap Score', value: tradingActivity.marketCapScore, bold: true, padTop: true }]} />
                <Row items={[
                  { title: 'Market Cap', value: format(tradingActivity.marketCap, NOT_AVAILABLE, 'int'), link: `/stock/${company}/quote/#trade-data` },
                  { title: 'As of', value: format(tradingActivity.marketCapAsOfDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/quote/#trade-data` }
                ]} />

                {info.isEquity && <>
                  <Row id='market-cap-change' items={[{ title: 'Market Cap Change Score', value: tradingActivity.marketCapChangeScore, bold: true, padTop: true }]} />
                  <Row items={[
                    { title: 'Market Cap', value: format(tradingActivity.prevYearMarketCap, NOT_AVAILABLE, 'int'), link: `/stock/${company}/security#share-structure` },
                    { title: 'As of', value: format(tradingActivity.prevYearMarketCapAsOfDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/security#share-structure` }
                  ]} />
                  <Row items={[
                    { title: 'Nominal Change', value: format(tradingActivity.marketCapNominalChange, NOT_AVAILABLE, 'int'), link: `/stock/${company}/security#share-structure` },
                    { title: 'Percent Change', value: format(tradingActivity.marketCapPctChange, NOT_AVAILABLE, 'percentage'), link: `/stock/${company}/security#share-structure` }
                  ]} />
                </>
                }

                <Row id='volume-change' items={[{ title: 'Volume Change Score', value: tradingActivity.volumeChangeScore, bold: true, padTop: true }]} />
                <Row items={[
                  { title: 'Volume', value: format(tradingActivity.volume, NOT_AVAILABLE, 'int'), link: `/stock/${company}/quote/#trade-data` },
                  { title: 'As of', value: format(tradingActivity.volumeAsOfDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/quote/#trade-data` }
                ]} />
                <Row items={[
                  { title: 'Nominal Change', value: format(tradingActivity.volumeNominalChange, NOT_AVAILABLE, 'int'), link: `/stock/${company}/quote/#trade-data` },
                  { title: 'Percent Change', value: format(tradingActivity.volumePctChange, NOT_AVAILABLE, 'percentage'), link: `/stock/${company}/quote/#trade-data` },
                  { title: '30 Day ADV', value: format(tradingActivity.thirtyDayVolumeAvg, NOT_AVAILABLE, 'int'), link: `/stock/${company}/quote/#trade-data` }
                ]} />

                {info.isEquity && <>
                  <Row id='trade-count-change' items={[{ title: 'Trade Count Change Score', value: tradingActivity.tradeCountScore, bold: true, padTop: true }]} />
                  <Row items={[
                    { title: 'Trade Count', value: tradingActivity.tradeCount },
                    { title: 'As of', value: format(tradingActivity.tradeCountAsOfDate, NOT_AVAILABLE, 'date') }
                  ]} />
                  <Row items={[
                    { title: 'Nominal Change', value: format(tradingActivity.tradeCountNominalChange, NOT_AVAILABLE, 'int'), link: `/stock/${company}/quote/#trade-data` },
                    { title: 'Percent Change', value: format(tradingActivity.tradeCountPctChange, NOT_AVAILABLE, 'percentage'), link: `/stock/${company}/quote/#trade-data` },
                    { title: '30 Day AD Trade Count', value: format(tradingActivity.thirtyDayADTradeCount, NOT_AVAILABLE, 'int'), link: `/stock/${company}/quote/#trade-data` }
                  ]} /></>}
              </Loading>
            </AccordionItem>
          </HeaderAccordion>
        </div>

        <div className={styles.dataContainer}>
          <HeaderAccordion atomic={false}>
            <AccordionItem title='Corporate Actions' loaded={this.state.corpActionsTotals.loaded} accent={accent}>
              <Loading
                height='12em'
                type='table'
                loaded={this.state.corpActionsTotals.loaded}
                error={this.state.corpActionsTotals.error}>
                <Row items={[{ title: 'Total Score', value: coporateActions.corpActionsTotalScore, bold: true, accent }]} />

                <Row id='company-name-change' items={[{ title: 'Company Name Changes Score', value: coporateActions.companyNameChangeScore, bold: true, padTop: true }]} />
                {coporateActions.nameChangeDetails && <Row items={[{ title: 'Effective Date', value: format(coporateActions.lastNameChangeDate, NOT_AVAILABLE, 'date') }]} />}
                {coporateActions.nameChangeDetails && <Row items={[
                  { title: 'Old Name', value: coporateActions.nameChangeDetails || NOT_AVAILABLE, link: `/stock/${company}/security/?type=Company Name Change#corporate-actions` },
                  { title: 'New Name', value: info.name || NOT_AVAILABLE, link: `/stock/${company}/security/?type=Company Name Change#corporate-actions` }
                ]} />}
                {/* TODO update name change details w Yao nameChangeDetails */}
                <Row id='reverse-splits' items={[{ title: 'Reverse Splits Score', value: coporateActions.reverseSplitScore, bold: true, padTop: true }]} />
                <Row items={[
                  { title: 'Effective Date', value: format(coporateActions.lastSplitDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/security/?tab=splits#div-splits` },
                  { title: 'Split Factor', value: format(coporateActions.lastSplitRatio, NOT_AVAILABLE, 'price'), link: `/stock/${company}/security/?tab=splits#div-splits` }
                ]} />
              </Loading>
            </AccordionItem>
          </HeaderAccordion>
        </div>

        <div className={styles.dataContainer}>
          <HeaderAccordion atomic={false}>
            <AccordionItem title='Issuance/Dilution' loaded={this.state.issuanceTotals.loaded} accent={accent}>
              <Loading
                height='12em'
                type='table'
                loaded={this.state.issuanceTotals.loaded}
                error={this.state.issuanceTotals.error}>
                <Row items={[{ title: 'Total Score', value: issuanceDilution.issuanceDilutionTotalScore, bold: true, accent }]} />

                <Row id='shares-outstanding-changes' items={[{ title: 'Shares Outstanding Changes Score', value: issuanceDilution.sharesOutstandingChangeScore, bold: true, padTop: true }]} />
                <Row items={[
                  { title: 'Shares Outstanding', value: format(issuanceDilution.sharesOutstanding, NOT_AVAILABLE, 'int'), link: `/stock/${company}/security#share-structure` },
                  { title: 'As of', value: format(issuanceDilution.sharesOutstandingAsOfDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/security#share-structure` }
                ]} />
                <Row items={[
                  { title: 'Nominal Change', value: format(issuanceDilution.sharesOutstandingNominalChange, NOT_AVAILABLE, 'int'), link: `/stock/${company}/security#share-structure` },
                  { title: 'Percent Change', value: format(issuanceDilution.sharesOutstandingPctChange, NOT_AVAILABLE, 'percentage'), link: `/stock/${company}/security#share-structure` }
                ]} />
                <Row items={[
                  { title: 'PQE', value: format(issuanceDilution.pqeSharesOutstanding, NOT_AVAILABLE, 'int'), link: `/stock/${company}/security#share-structure` },
                  { title: 'As of', value: format(issuanceDilution.pqeSharesOutstandingAsOfDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/security#share-structure` }
                ]} />

                <Row id='authorized-shares-changes' items={[{ title: 'Authorized Shares Changes Score', value: issuanceDilution.authorizedSharesChangeScore, bold: true, padTop: true }]} />
                <Row items={[
                  { title: 'Authorized Shares', value: format(issuanceDilution.authorizedShares, NOT_AVAILABLE, 'int'), link: `/stock/${company}/security#share-structure` },
                  { title: 'As of', value: format(issuanceDilution.authorizedSharesAsOfDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/security#share-structure` }
                ]} />
                <Row items={[
                  { title: 'Nominal Change', value: format(issuanceDilution.authorizedSharesNominalChange, NOT_AVAILABLE, 'int'), link: `/stock/${company}/security#share-structure` },
                  { title: 'Percent Change', value: format(issuanceDilution.authorizedSharesPctChange, NOT_AVAILABLE, 'percentage'), link: `/stock/${company}/security#share-structure` }
                ]} />
                <Row items={[
                  { title: 'PQE', value: format(issuanceDilution.pqeAuthorizedShares, NOT_AVAILABLE, 'int'), link: `/stock/${company}/security#share-structure` },
                  { title: 'As of', value: format(issuanceDilution.pqeAuthorizedSharesAsOfDate, NOT_AVAILABLE, 'date'), link: `/stock/${company}/security#share-structure` }
                ]} />
              </Loading>
            </AccordionItem>
          </HeaderAccordion>
        </div>
      </div>}

      {!info.isOTC && <div className={styles.newsSection}>
        <Outline mode='heading' accent={accent}>News</Outline>
        <Loading
          type='table'
          loaded={this.state.edgarNews.loaded}
          error={this.state.edgarNews.error}
          data={this.state.edgarNews.data.records}>
          <Accordion
            closed
            accent={accent}
            list={this.state.edgarNews.data.records.slice(0, 3)} />
          <More to={`/stock/${company}/news`} />
        </Loading>
      </div>}
    </div>
  }
}

CompanyQuoteOverview.propTypes = {
  company: PropTypes.string.isRequired,
  info: PropTypes.object,
  inside: PropTypes.object,
  accent: PropTypes.string,
  user: PropTypes.object,
  showRiskScoring: PropTypes.bool
}

export default CompanyQuoteOverview
