import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './DisplayResults.module.scss'

const DisplayResults = (props) => (
  <div className={classNames(styles.component, props.className)} >
    Displaying {props.show} of {props.total} {props.text || ''}
  </div>)

DisplayResults.propTypes = {
  className: PropTypes.string,
  show: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  text: PropTypes.string
}

export default DisplayResults
