import React, { PureComponent } from 'react'
import styles from './MAKeySecurities.module.scss'
import {
  getTopRiskScore,
  getCaveatEmptor,
  getActivePromotions,
  getHotSectors,
  getShellRisk,
  getShell,
  getBankruptcy,
  downloadKeySecurities
} from '../../api/otc/market-activity/key-securities'
import { template, fetch, expand, sort } from '../../api/helper'
import { tables } from './columns'
import HeadTitle from '../../components/HeadTitle'
import Flexgrid from '../../components/Flexgrid'
import Table from '../../components/Table'
import More from '../../components/More'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import Sticky from '../../components/Sticky'
import JumpLinkNav from '../../components/JumpLinkNav'
import Outline from '../../components/Outline'

// Mapping functions to table names
const tableFunctions = {
  topRisk: getTopRiskScore,
  caveatEmptor: getCaveatEmptor,
  activePromotions: getActivePromotions,
  hotSectors: getHotSectors,
  shellRisk: getShellRisk,
  shell: getShell,
  bankruptcy: getBankruptcy
}

export default class MAKeySecurities extends PureComponent {
  state = {
    loaded: false,
    list: template('records'),
    topRisk: template('records'),
    caveatEmptor: template('records'),
    activePromotions: template('records'),
    hotSectors: template('records'),
    shellRisk: template('records'),
    shell: template('records'),
    bankruptcy: template('records'),
    selectedLoadedPage: 1,
    searchQuery: null
  }

  componentDidMount () {
    this.loadData()

    // If anchor is supplied in hash, scroll into view
    const hash = window.location.hash
    if (hash && hash.length > 1) {
      const el = document.getElementById(hash.substr(1))
      if (el) {
        const elOffSet = el.offsetTop
        window.scrollTo({ top: elOffSet - 100 })
      }
    }
  }

  loadData = () => {
    tables.map(table => {
      fetch(this, tableFunctions[table.key], table.key, {
        page: 1,
        pageSize: 10
      })
    })
  }

  onSearch = searchQuery => {
    this.setState({ searchQuery }, this.loadData)
  }

  handleDownload = (tableName, key) => {
    downloadKeySecurities(tableName)
  }

  render () {
    const layouts = {
      default: [
        {
          type: 'flex',
          column: true,
          subtree: ['search', 'desc', 'tables', 'other-securities']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['search', 'desc', 'other-securities']
            },
            {
              type: 'main',
              subtree: ['tables']
            }
          ]
        }
      ]
    }

    return (
      <div className={styles.component}>
        <HeadTitle title='Market Activity' />
        <PageTitle>Key Securities</PageTitle>
        <Flexgrid layouts={layouts}>
          <div key='tables' className={styles.tables}>
            {tables.map(table => {
              const tableData = this.state[table.key]
              return (<div key={`table-${table.key}`} id={`${table.hash}`}>
                <div key={table.key} className={styles.table}>
                  <Outline mode='heading'
                    hideFilterToggle
                    download={() => this.handleDownload(table.hash, table.key)}>
                    {table.title}
                  </Outline>
                  <Loading
                    type='table'
                    emptyMessage='No data available'
                    loaded={tableData.loaded}
                    reloading={tableData.reloading}
                    error={tableData.error}
                    data={tableData.data}>
                    <Table
                      columns={table.columns}
                      data={tableData.data}
                      sortColumn={this.state[table.key].sortOn}
                      sortDirection={this.state[table.key].sortDir}
                      onSorting={sort(this, tableFunctions[table.key], table.key)}
                      showHeader
                    />
                    <TableFooter>
                      <More
                        onClick={expand(this, tableFunctions[table.key], table.key)}
                        disabled={tableData.expandEmpty} />
                      {tableData.data.records && tableData.data.totalRecords &&
                        <DisplayResults show={tableData.data.records.length} total={tableData.data.totalRecords} text={table.title} />
                      }
                    </TableFooter>
                    {table.key === 'topRisk' && <abbr className={styles.abbr}>
                      * Change value is calculated vs previous trading day’s risk score value
                    </abbr>}
                  </Loading>
                </div>
              </div>
              )
            })}
          </div>
          <div key='desc' className={styles.desc}>
            Key Security Lists provides comprehensive data on some of the most important security groups from a compliance perspective.  All data sets may be downloaded by selecting the downward arrow in the top right corner of the data table.
          </div>
          <Sticky key='other-securities'>
            <JumpLinkNav links={tables} />
          </Sticky>
        </Flexgrid>
      </div>
    )
  }
}
