import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input, TextLink } from '@otcmarketsgroup/otcm-ui'
import { withContext } from '../../AuthContext'
import styles from './LogIn.module.scss'

class LogIn extends React.PureComponent {
  constructor (props, context) {
    super(props, context)

    this.state = {
      username: '',
      password: '',
      error: false
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (field) {
    return (value) => {
      this.setState({ [field]: value })
    }
  }

  render () {
    const { context } = this.props

    return <div className={styles.container}>
      <div className={styles.form}>
        <form onSubmit={(event) => context.submitLogin(this.state.username, this.state.password, event)}>
          <div className={styles.greeting}>
            <h3 className={styles.formTitle}>Log in</h3>
          </div>
          <Input id={'userName'}
            className={styles.input}
            title={'Email'}
            required
            design='standard'
            onChange={this.handleChange('username')}
            // error={this.state.errors[field.name]}
            type={'input'}
          />

          <Input id={'password'}
            className={styles.input}
            title={'Password'}
            required
            hideRequired
            design='standard'
            onChange={this.handleChange('password')}
            // error={this.state.errors[field.name]}
            type={'password'}
          />
          {context.error && <i className={styles.error}>{context.error}</i>}
          <Button id={'submitLogIn'} inactive={context.disableButton} className={styles.submit} title='Log in' fullWidth type='submit' />
          <TextLink onClick={() => context.changeState('FORGOT_PASSWORD')}>Forgot password?</TextLink>
        </form>
      </div>
    </div>
  }
}

LogIn.propTypes = {
  context: PropTypes.shape({
    isAuth: PropTypes.bool,
    submitLogin: PropTypes.func,
    error: PropTypes.string,
    changeState: PropTypes.func,
    disableButton: PropTypes.bool
  })
}

export default withContext(LogIn)
