import React from 'react'
import Outline from '../Outline'
import Loading from '../Loading'
import Row from '../Row'
import USMap from '../USMap'
import BlueSkyNotEntitled from '../BlueSkyNotEntitled'
import Table from '../Table'
import { DateSelector } from '../DateSelector'
import PropTypes from 'prop-types'
import { withContext } from '../../AuthContext'
import { getBlueSkySummary, getBlueSkyData, getHistoricalBlueSkySummary, getHistoricalBlueSkyData, downloadBlueSkyData } from '../../api/otc/company/blue-sky'
import { template, fetch } from '../../api/helper'
import styles from './CompanyQuoteBlueSky.module.scss'
import { BLUE_SKY_EQUITY, BLUE_SKY_FIXED_INCOME } from '../../constants/groups'
import classNames from 'classnames'
import moment from 'moment'

const STATIC_BLOCK = () => {
  return <div className={styles.staticBlock} />
}

const isWeekday = date => {
  const day = new Date(date).getDay()
  return day !== 0 && day !== 6
}

const staticColumns = [
  {
    name: 'Jurisdiction',
    component: () => <STATIC_BLOCK />
  },
  {
    name: 'status',
    component: () => <STATIC_BLOCK />
  }
]

const complianceColumns = [
  {
    name: 'state',
    header: 'Jurisdiction'
  },
  {
    name: 'status',
    header: 'In Compliance'
  },
  {
    name: 'exemption',
    header: 'Exemption Category'
  }
]

const defaultComplianceFilter = {
  title: 'All',
  value: 'BOTH'
}

const complianceFilters = [
  defaultComplianceFilter,
  {
    title: 'In Compliance',
    value: 'IN_COMPLIANCE'
  },
  {
    title: 'Out of Compliance',
    value: 'OUT_OF_COMPLIANCE'
  }
]

class CompanyQuoteBlueSky extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      date: null,
      asOfDate: null,
      mostRecentDate: null,
      complianceFilter: null,
      complianceData: template('records'),
      blueSkySummary: template('records'),
      mapData: template('records'),
      isEntitled: null
    }
  }

  componentDidMount () {
    const user = this.props.context.user
    const isFixedIncome = this.props.info.isFixedIncome
    let isEntitled

    if (isFixedIncome) {
      isEntitled = user && user.userGroups && user.userGroups.find(group => group === BLUE_SKY_FIXED_INCOME)
    } else {
      isEntitled = user && user.userGroups && user.userGroups.find(group => group === BLUE_SKY_EQUITY)
    }

    if (isEntitled) {
      this.setState({ isEntitled: true })
      this.loadData()
    } else {
      this.setState({ isEntitled: false })
    }
  }

  // call all daily results API
  loadData = () => {
    this.loadBlueSkySummary()
    this.loadComplianceData()
    this.loadComplianceDataMap()
  }

  // daily blue sky summary API
  loadBlueSkySummary = () => {
    const { company, info } = this.props
    const isFixedIncome = info.isFixedIncome

    const params = {
      page: 1,
      symbol: company
    }

    if (isFixedIncome) {
      params.cusip = info.cusip
      delete params.symbol
    }

    fetch(this, getBlueSkySummary, 'blueSkySummary', params)
  }

  // daily compliance results API to populate Table Data
  loadComplianceData = () => {
    const _this = this
    const { company, info } = this.props
    const isFixedIncome = info.isFixedIncome

    const params = {
      page: 1,
      pageSize: 60,
      symbol: company,
      complianceStatus: this.state.complianceFilter
    }

    if (isFixedIncome) {
      params.cusip = info.cusip
      delete params.symbol
    }

    fetch(this, getBlueSkyData, 'complianceData', params)
      .then(d => _this.setState({
        mostRecentDate: moment(d.data.blueskyDate).format('YYYY-MM-DD'),
        asOfDate: moment(d.data.blueskyDate).format('MM/DD/YYYY'),
        date: moment(d.data.blueskyDate) || moment()
      }))
      .catch(e => console.log('e', e))
  }

  // daily compliance results API to populate Map
  loadComplianceDataMap = () => {
    const { company, info } = this.props
    const isFixedIncome = info.isFixedIncome

    const params = {
      page: 1,
      pageSize: 60,
      symbol: company
    }

    if (isFixedIncome) {
      params.cusip = info.cusip
      delete params.symbol
    }

    fetch(this, getBlueSkyData, 'mapData', params)
  }

  /// ///////////////////////// function for historical dates///////////////////////

  // call all historical results API
  loadHistoricalData = (date) => {
    this.loadHistoricalBlueSkySummary(date)
    this.loadHistoricalComplianceData(date)
    this.loadHistoricalComplianceDataMap(date)
  }

  // historical blue sky summary API
  loadHistoricalBlueSkySummary = (date) => {
    const { company, info } = this.props
    const isFixedIncome = info.isFixedIncome

    const params = {
      page: 1,
      symbol: company,
      date
    }

    if (isFixedIncome) {
      params.cusip = info.cusip
      delete params.symbol
    }
    fetch(this, getHistoricalBlueSkySummary, 'blueSkySummary', params)
  }

  // historical compliance results API to populate Table Data
  loadHistoricalComplianceData = (date) => {
    const { company, info } = this.props
    const isFixedIncome = info.isFixedIncome

    const params = {
      page: 1,
      pageSize: 60,
      symbol: company,
      date,
      complianceStatus: this.state.complianceFilter
    }

    if (isFixedIncome) {
      params.cusip = info.cusip
      delete params.symbol
    }

    fetch(this, getHistoricalBlueSkyData, 'complianceData', params)
  }

  // historical compliance results API to populate Map
  loadHistoricalComplianceDataMap = (date) => {
    const { company, info } = this.props
    const isFixedIncome = info.isFixedIncome

    const params = {
      page: 1,
      pageSize: 60,
      symbol: company,
      date
    }

    if (isFixedIncome) {
      params.cusip = info.cusip
      delete params.symbol
    }

    fetch(this, getHistoricalBlueSkyData, 'mapData', params)
  }

  handleSelect = option => {
    const _this = this
    const date = _this.state.date
    this.setState({ complianceFilter: option }, () => this.handleDateChange(date))
  }

  handleDateChange = date => {
    const _this = this

    const formattedDate = date.format('YYYY-MM-DD')
    const reloadPage = date !== _this.state.date
    this.setState({
      date,
      asOfDate: date.format('MM/DD/YYYY')
    })

    if (reloadPage) {
      if (formattedDate === _this.state.mostRecentDate) {
        this.loadData()
      } else {
        this.loadHistoricalData(formattedDate)
      }
    } else {
      if (formattedDate === _this.state.mostRecentDate) {
        this.loadComplianceData()
      } else {
        this.loadHistoricalComplianceData(formattedDate)
      }
    }
  }

  handleDownload = () => {
    const formattedDate = this.state.date.format('YYYY-MM-DD')
    const params = {
      page: 1,
      pageSize: 60,
      symbol: this.props.company
    }

    if (formattedDate === moment().format('YYYY-MM-DD')) {
      downloadBlueSkyData(params)
    } else {
      params.date = formattedDate
      downloadBlueSkyData(params)
    }
  }

  render () {
    const { accent } = this.props
    const { isEntitled, mapData, blueSkySummary, complianceData } = this.state
    const asOfDate = <span className={styles.headerDate}>As of {this.state.asOfDate}</span>
    const minBlueSkyDate = moment('07-01-2020', 'MM-DD-YYYY')
    const showValue = value => value !== null && value !== undefined && value !== '' ? value : 'N/A';
console.log('this.state.date', this.state.date)
    return (
      <div className={styles.container}>
        {!isEntitled && <div className={styles.modal}>
          <BlueSkyNotEntitled />
        </div>}
        <div className={classNames({
          [styles.static]: !isEntitled
        })}>
          <div className={styles.dateContainer}>
            <DateSelector
              design='box'
              selected={this.state.date}
              filterDate={isWeekday}
              disabled={!isEntitled}
              minDate={minBlueSkyDate}
              maxDate={moment()}
              color={accent}
              onChange={this.handleDateChange} />
          </div>

          <Outline mode='heading' accent={accent}>
            <div className={styles.header}>
              Blue Sky Compliance Map
              <span className={styles.date}>{asOfDate}</span>
            </div>
          </Outline>

          <Loading
            type='table'
            loaded={mapData.loaded || isEntitled === false}
            reloading={mapData.reloading}
            error={complianceData.error}
            data={mapData.data.results}>
            <div className={styles.map}>
              <USMap accent={!isEntitled ? 'static' : accent} data={mapData.data.results} isStatic={!isEntitled || !mapData.data.results || mapData.data.results.length === 0} />
            </div>
          </Loading>

          <Outline mode='invisible' spacing='large' />

          <Outline mode='heading' accent={accent}>
            <div className={styles.header}>
              Summary
              <span className={styles.date}>{asOfDate}</span>
            </div>
          </Outline>
          <Loading
            height='12em'
            type='table'
            loaded={blueSkySummary.loaded || isEntitled === false}
            error={blueSkySummary.error}>
            {!isEntitled && <div>
              <Row items={[{ title: 'Jurisdictions in Compliance', value: <STATIC_BLOCK /> }]} />
              <Row items={[{ title: 'Jurisdictions out of Compliance', value: <STATIC_BLOCK /> }]} />
            </div>}
            {isEntitled && <div>
              <Row items={[{ title: 'Jurisdictions in Compliance', value: showValue(blueSkySummary.data.inCompliance) }]} />
              <Row items={[{ title: 'Jurisdictions out of Compliance', value: showValue(blueSkySummary.data.outOfCompliance) }]} />
            </div>}
          </Loading>

          <Outline mode='invisible' spacing='large' />

          <Outline mode='heading'
            accent={accent}
            download={this.handleDownload}
            filters={[{
              type: 'select',
              required: true,
              options: complianceFilters,
              defaultValue: defaultComplianceFilter.value,
              onSelect: this.handleSelect
            }]}>Blue Sky Compliance {asOfDate}</Outline>
          <Loading
            type='table'
            loaded={complianceData.loaded || isEntitled === false}
            reloading={complianceData.reloading}
            error={complianceData.error}
            data={complianceData.data.results}>
            {!isEntitled && <Table
              columns={staticColumns}
              data={[1, 2, 3, 4, 5]}
              showHeader
            />}
            {isEntitled && <Table
              columns={complianceColumns}
              data={complianceData.data.results}
              showHeader
              wordWrap
              accent={accent}
            />}
          </Loading>
        </div>
      </div>
    )
  }
}

CompanyQuoteBlueSky.propTypes = {
  company: PropTypes.string.isRequired,
  accent: PropTypes.string,
  context: PropTypes.object,
  info: PropTypes.shape({
    isEquity: PropTypes.bool,
    isFixedIncome: PropTypes.bool,
    cusip: PropTypes.string
  })
}

export default withContext(CompanyQuoteBlueSky)
