import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getRiskScoring (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/analytics/risk-scoring-change',
    params
  })
  return response.data
}

export async function getPriceMovement (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/analytics/price-movement',
    params
  })
  return response.data
}

export async function getVolumeMovment (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    mockData: 'market-activity/compliance-statistics/promo-data',
    url: '/compliance/analytics/volume-movement',
    params
  })
  return response.data
}

export async function downloadAnalytics (pathname, params) {
  const url = `/internalapi/download/compliance/analytics/${pathname}/download`
  const queryString = Object.keys(params)
    .filter(key => params[key])
    .map(key => params[key] && `${key}=${params[key]}`).join('&')
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
  window.open(`${REACT_APP_API_BASE_URI}${url}?${queryString}&file=${pathname}&token=${jwtToken}`)
}
