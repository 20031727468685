import React from 'react'
import { Link } from 'react-router-dom'
import Outline from '../Outline'
import Loading from '../Loading'
import Row from '../Row'
import FixedIncome211NotEntitled from '../FixedIncome211NotEntitled'
import { DateSelector } from '../DateSelector'
import PropTypes from 'prop-types'
import { withContext } from '../../AuthContext'
import { withCompanyQuote211Context } from '../../CompanyQuote211.context'
import { getFixedIncome211Data } from '../../api/otc/company/fi-211'
import { securitySearch } from '../../api/otc/search'
import { template, fetch } from '../../api/helper'
import styles from './CompanyQuote211.module.scss'
import { FIXED_INCOME_211 } from '../../constants/groups'
import classNames from 'classnames'
import moment from 'moment'

const minFixedIncome211Date = moment('11-23-2022', 'MM-DD-YYYY')

const fixedIncome211ExemptionReasonsApiFieldMap = new Map([
  ['reasonCode1', 1], ['reasonCode2', 2], ['reasonCode3', 3], ['reasonCode4', 4], ['reasonCode5', 5],
  ['reasonCode6', 6], ['reasonCode7', 7], ['reasonCode8', 8], ['reasonCode9', 9], ['reasonCode10', 10],
  ['reasonCode11', 11], ['reasonCode12', 12], ['reasonCode13', 13], ['reasonCode14', 14], ['reasonCode15', 15]])

const equityVenueMap = new Map([
  ['OB', 'FINRA BB'],
  ['GM', 'Grey Market'],
  ['PS', 'OTC Link'],
  ['BP', 'OTC Link/FINRA BB'],
  ['AR', 'NYSE Arca'],
  ['AX', 'NYSE MKT'],
  ['NQ', 'Nasdaq'],
  ['NY', 'NYSE'],
  ['IX', 'IEX'],
  ['CB', 'CBOE'],
  ['BS', 'BATS'],
  ['MX', 'MMEX'],
  ['LS', 'LTSE']
])

const STATIC_BLOCK = () => {
  return <div className={styles.staticBlock} />
}

const isWeekday = date => {
  const day = new Date(date).getDay()
  return day !== 0 && day !== 6
}

const formatFi211Result = (result, exemptionReasonsMap) => {
  if (result) {
    result.exemption = ''
    result.exemptionReasonOne = result.reasonCode1 !== null
    result.exemptionReasonTwo = result.reasonCode2 !== null
    result.status211Bool = result.status211 === 'Y'
    result.guarantorStatusRC1Bool = result.guarantorStatusRC1 === 'Y'
    result.guarantorStatusRC2Bool = result.guarantorStatusRC2 === 'Y'
    const exemptionReasonArray = []
    let exemptionReasonValue
    if (result.status211Bool && exemptionReasonsMap) {
      for (const [key, value] of fixedIncome211ExemptionReasonsApiFieldMap.entries()) {
        if (result[key]) {
          exemptionReasonValue = exemptionReasonsMap.get(value)
          if (exemptionReasonValue) {
            exemptionReasonArray.push(exemptionReasonValue)
          }
        }
      }
      result.exemptionReason = exemptionReasonArray.join(', ')
    }
  }
  return result
}

class CompanyQuote211 extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      date: null,
      mostRecentDate: null,
      complianceData: template('records'),
      secData: template('records'),
      isEntitled: null,
      dataError: null
    }
  }

  componentDidMount () {
    const user = this.props.context.user
    const isFixedIncome = this.props.info.isFixedIncome
    const isEntitled = user && user.userGroups && user.userGroups.find(group => (group === FIXED_INCOME_211 && isFixedIncome))
    this.setState({ isEntitled })
    if (this.checkEntitlementAndFilter()) {
      this.loadData(this.props.companyQuote211Context.date)
    }
  }

  componentDidUpdate (prevProps) {
    const companyQuote211Context = this.props.companyQuote211Context
    const prevCompanyQuote211Context = prevProps.companyQuote211Context || {}

    if (companyQuote211Context.isFilterLoaded !== prevCompanyQuote211Context.isFilterLoaded && companyQuote211Context.isFilterLoaded) {
      if (this.checkEntitlementAndFilter()) {
        this.loadData(companyQuote211Context.date)
      }
    }
  }

  checkEntitlementAndFilter = () => {
    const user = this.props.context.user
    const isFixedIncome = this.props.info.isFixedIncome
    const isEntitled = user && user.userGroups && user.userGroups.find(group => (group === FIXED_INCOME_211 && isFixedIncome))
    const companyQuote211Context = this.props.companyQuote211Context
    return companyQuote211Context.isFilterLoaded && isEntitled
  }

  loadData = (date) => {
    if (date) {
      const { company, info, companyQuote211Context } = this.props
      const isFixedIncome = info.isFixedIncome

      const exemptionReasonsMap = companyQuote211Context && companyQuote211Context.exemptionReasonsMap

      this.setState({ dataError: null })

      const params = {
        symbol: company,
        date: date.format('YYYY-MM-DD')
      }

      if (isFixedIncome) {
        params.cusip = info.cusip
        delete params.symbol
      }
      this.setState({ date })
      /*
      fetch(this, getFixedIncome211Data, 'complianceData', params,
        data => {
          return formatFi211Result(data, exemptionReasonsMap)
        }) */

      fetch(this, getFixedIncome211Data, 'complianceData', params,
        data => {
          return formatFi211Result(data, exemptionReasonsMap)
        })
        .then(d => {
          if (d && d.data && d.data.exemptionReasonOne && d.data.guarantorSymbolRC1) {
            securitySearch(d.data.guarantorSymbolRC1, false)
              .then(data => {
                this.setState({
                  secData: {
                    data: data.docs && data.docs[0],
                    loaded: true
                  }
                })
              })
              .catch(e => {
                console.log('search error', e)
                this.setState({
                  secData: {
                    loaded: true
                  }
                })
              })
          }
        })
        .catch(e => {
          const errorMsg = e && e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Unexpected error. Try again.'
          this.setState({ dataError: errorMsg })
        })
    }
  }

  handleDateChange = date => {
    this.setState({ date })
    this.loadData(date)
  }

  renderExchangeListedSymbol = () => {
    const complianceData = this.state.complianceData
    const secData = this.state.secData
    const symbol = complianceData.data.guarantorSymbolRC1 || secData.data.symbol || ''
    const isFixedIncome = secData.data.isFixedIncome
    let venue = secData.data.venue
    venue = isFixedIncome ? venue : (equityVenueMap.get(venue) || venue)
    if (complianceData.loaded) {
      return <div key={'exchangeListedSymbolDiv'} className={classNames(styles.row)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <div>
          <Link className={styles.value} to={isFixedIncome ? `/fixed-income/${symbol}/fi-211` : `/stock/${symbol}/overview`}>{symbol}</Link> | {venue}
        </div>
      </div>
    } else {
      return 'N/A'
    }
  }

  render () {
    const { accent } = this.props
    const { isEntitled, complianceData, secData, date } = this.state
    const asOfDate = date ? <span className={styles.headerDate}>As of {moment(date).format('MM/DD/YYYY')}</span> : ''
    const exemptionReasonOne = complianceData && complianceData.loaded && complianceData.data.exemptionReasonOne
    const exemptionReasonTwo = complianceData && complianceData.loaded && complianceData.data.exemptionReasonTwo

    return (
      <div className={styles.container}>
        {!isEntitled && <div className={styles.modal}>
          <FixedIncome211NotEntitled />
        </div>}
        <div className={classNames({
          [styles.static]: !isEntitled
        })}>
          <div className={styles.dateContainer}>
            <DateSelector
              design='box'
              selected={this.state.date || moment()}
              filterDate={isWeekday}
              disabled={!isEntitled}
              minDate={minFixedIncome211Date}
              maxDate={moment()}
              color={accent}
              onChange={this.handleDateChange} />
          </div>

          <Outline mode='heading' accent={accent}>
            <div className={styles.header}>
              15c2-11 Compliance Status
              <span className={styles.date}>{asOfDate}</span>
            </div>
          </Outline>
          <Loading
            height='12em'
            type='table'
            loaded={complianceData.loaded || isEntitled === false}
            error={this.state.dataError}>
            {!isEntitled && <div>
              <Row items={[{ title: 'Phase 1 211 Compliance Status', value: <STATIC_BLOCK /> }]} />
              <Row items={[{ title: 'Exemption Reason', value: <STATIC_BLOCK /> }]} />
              <Row items={[{ title: 'Exchange Listed Under Guarantor Status', value: <STATIC_BLOCK /> }]} />
              <Row items={[{ title: 'Exchange Listed Guarantor', value: <STATIC_BLOCK /> }]} />
              <Row items={[{ title: 'Exchange Listed Symbol', value: <STATIC_BLOCK /> }]} />
            </div>}
            {isEntitled && <div>
              <Row items={[{ title: 'Phase 1 211 Compliance Status', value: (complianceData.data.status211Bool ? 'Yes' : 'No') }]} />
              <Row items={[{ title: 'Exemption Reasons', value: complianceData.data.exemptionReason || 'N/A' }]} />
              {exemptionReasonOne && secData.loaded && <>
                <Row items={[{ title: 'Exchange Listed Under Guarantor Status', value: (complianceData.data.guarantorStatusRC1Bool ? 'Yes' : 'No') }]} />
                  {complianceData.data.guarantorStatusRC1Bool &&
                  <Row items={[{ title: 'Exchange Listed Guarantor', value: complianceData.data.guarantorNameRC1 || 'N/A' }]} /> }
                <Row items={[{ title: 'Exchange Listed Symbol', value: this.renderExchangeListedSymbol() }]} />
                </> }
              {exemptionReasonTwo && <>
                <Row items={[{ title: 'Exchange Act Filer Guarantor Status', value: (complianceData.data.guarantorStatusRC2Bool ? 'Yes' : 'No') }]} />
                  {complianceData.data.guarantorStatusRC2Bool &&
                  <Row items={[{ title: 'Exchange Act Filer Guarantor', value: complianceData.data.guarantorNameRC2 || 'N/A' }]} /> }
                </> }
            </div>}
          </Loading>

        </div>
      </div>
    )
  }
}

CompanyQuote211.propTypes = {
  company: PropTypes.string.isRequired,
  accent: PropTypes.string,
  context: PropTypes.object,
  companyQuote211Context: PropTypes.object,
  info: PropTypes.shape({
    isEquity: PropTypes.bool,
    isFixedIncome: PropTypes.bool,
    cusip: PropTypes.string
  })
}

export default withContext(withCompanyQuote211Context(CompanyQuote211))
