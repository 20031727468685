import api, { paginate } from '../../api'

export async function getResearchData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    responseFormat: 'array',
    params: { compId: params.companyId },
    url: '/research/list'
  })
  return paginate({ records: response.data }, params)
}

