export function request (params) {
  return {
    tiers: {
      records: [
        {
          id: 'QX',
          caption: 'OTCQX'
        },
        {
          id: 'QB',
          caption: 'OTCQB'
        },
        {
          id: 'BB',
          caption: 'FINRA BB Only'
        },
        {
          id: 'PC',
          caption: 'OTC Pink Current'
        },
        {
          id: 'PL',
          caption: 'OTC Pink Limited'
        },
        {
          id: 'PN',
          caption: 'OTC Pink No Information'
        },
        {
          id: 'GM',
          caption: 'Grey Market'
        },
        {
          id: 'YL',
          caption: 'OTC Yellow'
        },
        {
          id: 'GB',
          caption: 'Grey Bonds'
        }
      ],
      totalRecords: 9
    },
    securityTypes: {
      records: [
        {
          id: 'AGEN',
          caption: 'Agency Debt Bond'
        },
        {
          id: 'ADR',
          caption: 'American Depository Receipts'
        },
        {
          id: 'CS',
          caption: 'Common Stock'
        },
        {
          id: 'CORP',
          caption: 'Corporate Bond'
        },
        {
          id: 'EQLK',
          caption: 'Equity Linked Note'
        },
        {
          id: 'ETF',
          caption: 'Exchange-Traded Fund'
        },
        {
          id: 'FUND',
          caption: 'Fund'
        },
        {
          id: 'GDR',
          caption: 'Global Depository Receipts'
        },
        {
          id: 'NYRS',
          caption: 'New York Registry Shs'
        },
        {
          id: 'OS',
          caption: 'Ordinary Shares'
        },
        {
          id: 'OTHER',
          caption: 'Other Security Type'
        },
        {
          id: 'PS',
          caption: 'Preferred Stock'
        },
        {
          id: 'RTS',
          caption: 'Rights'
        },
        {
          id: 'SP',
          caption: 'Structured Product'
        },
        {
          id: 'UTS',
          caption: 'Units'
        },
        {
          id: 'WTS',
          caption: 'Warrants'
        }
      ],
      totalRecords: 16
    }
  }
}
