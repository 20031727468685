import api from '../../api'

export async function getTotals (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: `/compliance/risk-score/totals/${params.symbol && params.symbol}`,
    params
  })
  return response.data
}

export async function getCorpActionTotals (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: `/compliance/risk-score/totals/${params.symbol && params.symbol}/corp-action`,
    params
  })
  return response.data
}

export async function getIssuanceTotals (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: `/compliance/risk-score/totals/${params.symbol && params.symbol}/issuance`,
    params
  })
  return response.data
}

export async function getIssuerStatusTotals (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: `/compliance/risk-score/totals/${params.symbol && params.symbol}/issuer-status`,
    params
  })
  return response.data
}

export async function getPublicInterestTotals (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: `/compliance/risk-score/totals/${params.symbol && params.symbol}/public-interest`,
    params
  })
  return response.data
}

export async function getTradeActivityTotals (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    internalAPI: true,
    url: `/compliance/risk-score/totals/${params.symbol && params.symbol}/trade-activity`,
    params
  })
  return response.data
}
