import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'grid-styled'
import ExternalLink from '../../ExternalLink'
import styles from './styles.module.scss'

const CompanyQuoteDisclosureWebsiteInfo = (props) => {
  const array = Array.isArray(props.disclosureUrls) ? [...props.disclosureUrls] : []
  array.shift()

  return <div>
    {array && array.length > 0 && <div className={styles.item}>
      <Flex w={1} justify='flex-end' align='center' >
        <Box w={[1, 1 / 2, 1 / 2]}>
          {array.map((item, index) => <p key={index} className={styles.field}>
            <ExternalLink to={item}>{item}</ExternalLink>
          </p>)}
        </Box>
      </Flex>
    </div>}
  </div>
}

CompanyQuoteDisclosureWebsiteInfo.propTypes = {
  disclosureUrls: PropTypes.array
}

export default CompanyQuoteDisclosureWebsiteInfo
