import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import styles from './KeySecurityOverview.module.scss'

class KeySecurityOverview extends PureComponent {
  render () {
    const { securities, stats } = this.props

    return (
      <div
        className={classNames(styles.container)}>
        {securities.map(security => (
          <Link
            to={`/market-activity/key-securities/#${security.hash}`}
            key={security.title}
            className={styles.security}>
            <img src={`/icons/key-securities/${security.title.replace(/\s+/g, '-').toLowerCase()}.svg`} />
            <div>
              <div className={styles.title}>{security.title}</div>
              <div><b>{stats[security.key]}</b></div>
            </div>
          </Link>
        ))}
      </div>
    )
  }
}

KeySecurityOverview.propTypes = {
  securities: PropTypes.array.isRequired,
  stats: PropTypes.object
}

export default KeySecurityOverview
