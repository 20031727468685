import api, { paginate } from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getTypes () {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'array',
    url: '/service-provider/types'
  })

  return response.data
}

export async function getServiceProvider (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    mockData: 'directory/service-provider/service-provider',
    url: '/service-provider',
    params
  })
  // normalize non-standard response from OTC API
  if (response.data && response.data.serviceProviderInfoList) {
    response.data.records = response.data.serviceProviderInfoList
  }
  return response.data
}

export async function downloadServiceProvider () {
  const url = '/otcapi/download/internal/service-provider/download'
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
  window.open(`${REACT_APP_API_BASE_URI}${url}?file=service-provider&token=${jwtToken}`)
}

export async function getDetails (params) {
  let url = '/service-provider/detail'
  if (params.typeId === undefined) url = `/service-provider/${params.id}/roles`
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    mockData: 'directory/service-provider/details',
    url,
    params
  })
  // normalize response format for service provider details
  if (response.data && response.data.serviceProviderSimple) {
    response.data.serviceProviderInfo = response.data.serviceProviderSimple
  }
  // normalize non-standard response from OTC API
  if (response.data && response.data.serviceProviderRelationList) {
    response.data.records = response.data.serviceProviderRelationList
  }
  return response.data
}

export async function getProhAttorneys (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'array',
    mockData: 'directory/service-provider/proh-attorneys',
    url: '/directory/prohibited-attorneys',
    params
  })

  // filter full dataset by search param, if specified
  let filtered
  if (response.data && params.search) {
    const search = params.search.toLowerCase()
    filtered = response.data.filter(item => {
      if (item.fullName && item.fullName.toLowerCase().indexOf(search) !== -1) return true
      else if (item.firm && item.firm.toLowerCase().indexOf(search) !== -1) return true
      return false
    })
  }

  return paginate({ records: filtered || response.data }, params)
}

export async function downloadProhAttorneys () {
  const url = '/otcapi/download/internal/directory/prohibited-attorneys/download'
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
  window.open(`${REACT_APP_API_BASE_URI}${url}?file=prohibited-attorneys&token=${jwtToken}`)
}

export async function getProhOthers (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'array',
    mockData: 'directory/service-provider/proh-other-service-providers',
    url: '/directory/prohibited-others',
    params
  })

  // filter full dataset by search param, if specified
  let filtered
  if (response.data && params.search) {
    const search = params.search.toLowerCase()
    filtered = response.data.filter(item => {
      return (item.firm && item.firm.toLowerCase().indexOf(search) !== -1)
    })
  }
  return paginate({ records: filtered || response.data }, params)
}

export async function downloadProhOthers () {
  const url = '/otcapi/download/internal/directory/prohibited-others/download'
  let jwtToken
  await getToken()
    .then(res => {
      jwtToken = res
    })
  window.open(`${REACT_APP_API_BASE_URI}${url}?file=prohibited-others&token=${jwtToken}`)
}
