import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import Hide from '../Hide'
import TableFilter from '../TableFilter'
import { FaChevronDown, FaDownload } from 'react-icons/fa'

const Outline = (props) => (<div>
  <div className={classNames(styles[props.accent || 'default'], props.className, {
    [styles.outline]: true,
    [styles.heading]: props.mode === 'heading' || props.mode === 'accordion',
    [styles.heading2]: props.mode === 'heading2',
    [styles.heading3]: props.mode === 'heading3',
    [styles.heading4]: props.mode === 'heading4',
    [styles.section1]: props.mode === 'section1',
    [styles.section2]: props.mode === 'section2',
    [styles.section3]: props.mode === 'section3',
    [styles.accordion]: props.mode === 'accordion',
    [styles.invisible]: props.mode === 'invisible',
    [styles.noSpacing]: props.spacing === 'none',
    [styles.largeSpacing]: props.spacing === 'large',
    [styles.largerSpacing]: props.spacing === 'larger',
    [styles.marginTop]: props.margin === 'top',
    [styles.marginBottom]: props.margin === 'bottom',
    [styles.noMargin]: props.margin === 'none',
    [styles.line]: props.line,
    [styles.underline]: props.underline
  })} >
    {(props.mode === 'heading' || props.mode === 'accordion') && props.children
      ? <div className={styles.text}>
      {props.children}
    </div>
      : props.children}
    {props.mode === 'accordion' && <FaChevronDown className={classNames(styles.toggleButton, { [styles.open]: props.isOpen })} />}
    {(props.filters || props.content || props.buttons || props.download) && <div className={styles.rightContent}>
      {/* for md/lg screen size, render TableFilter within */}
      {props.filters && <Hide sm><TableFilter filters={props.filters} /></Hide>}
      {props.content}
      {props.download && <div className={styles.download} onClick={props.download}>
        <FaDownload />
      </div>}
      {props.buttons && <div className={styles.buttons}>
        {props.buttons.map((button, i) => {
          return <div key={`button_${i}`}title={button.title} className={classNames(styles.button, {
            [styles.hideOnMobile]: button.hideOnMobile,
            [styles.hideOnTablet]: button.hideOnTablet
          })}>{button.icon}</div>
        })}
      </div>}
    </div>}
  </div>
  {/* for sm screen size, render TableFilter after */}
  {props.filters && <Hide md lg><TableFilter hideFilterToggle={props.hideFilterToggle} filters={props.filters} /></Hide>}
</div>)

Outline.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string.isRequired,
  line: PropTypes.bool,
  underline: PropTypes.bool,
  spacing: PropTypes.string,
  margin: PropTypes.string,
  accent: PropTypes.string,
  children: PropTypes.node,
  download: PropTypes.func,
  /* heading props  */
  hideFilterToggle: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.object),
  buttons: PropTypes.arrayOf(PropTypes.object),
  content: PropTypes.node,
  isOpen: PropTypes.bool
}

export default Outline
