import React from 'react'
import PropTypes from 'prop-types'
import styles from './ContentLink.module.scss'
import ExternalLink from '../ExternalLink'
import ContactModal from '../ContactModal'
import classNames from 'classnames'
import getConfig from '../../config'
import { FaChevronRight } from 'react-icons/fa'

const { REACT_APP_CMS_S3_BASE_URI } = getConfig()

const ContentLink = ({ data, accentColor, children, location }) => {
  let href = data.href
  // Remove S3 base URL
  if (REACT_APP_CMS_S3_BASE_URI && href && href.indexOf(REACT_APP_CMS_S3_BASE_URI) === 0) {
    href = href.substr(REACT_APP_CMS_S3_BASE_URI.length)
  }

  const target = data.target
  const style = data.linkStyle || 'default'
  const className = classNames(styles['link-' + style], styles['link-' + style + '-' + accentColor])

  // Special forms
  if (href.indexOf('form-') === 0) {
    const formType = href.substr(5)
    return <ContactModal formType={formType} accentColor={accentColor} location={location}>{children}</ContactModal>
  }

  // Ensures that block links never leave the arrow on a single line
  if (style === 'block') {
    const text = children.length === 1 && children[0].length === 1 && children[0][0].length === 1 &&
      typeof children[0][0][0] === 'string'
      ? children[0][0][0]
      : null
    if (!text) return <ExternalLink className={className} target={target} href={href}>{children}<FaChevronRight /></ExternalLink>
    const words = text.split(' ')
    const lastWord = words && words.length > 0 ? words.pop() : ''
    const firstWords = words && words.length > 0 ? words.join(' ') : ''
    return <ExternalLink className={className} target={target} href={href}>{firstWords}&nbsp;<span>{lastWord}<FaChevronRight /></span></ExternalLink>
  }
  // All other link types
  return <ExternalLink className={className} target={target} href={href}>{children}</ExternalLink>
}

ContentLink.propTypes = {
  data: PropTypes.object.isRequired,
  accentColor: PropTypes.string,
  children: PropTypes.node,
  location: PropTypes.object
}

export default ContentLink
