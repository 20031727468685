import React, { PureComponent } from 'react'
import styles from './DSponsorsPage.module.scss'
import { template, fetch } from '../../api/helper'
import { getSponsors } from '../../api/otc/directory/sponsor'

import DirSearch from '../../components/DirSearch'
import PageTitle from '../../components/PageTitle'
import HeadTitle from '../../components/HeadTitle'
import Select from '../../components/Select'
import Flexgrid from '../../components/Flexgrid'
import OtherDirectories from '../../components/OtherDirectories'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import More from '../../components/More'
import Outline from '../../components/Outline'
import Sticky from '../../components/Sticky'
import ListItem from './components/ListItem'
import { Flex } from 'grid-styled'
import ExternalLink from '../../components/ExternalLink'
import Button from '../../components/Button'

const types = [
  {
    title: 'All Sponsors',
    value: -1
  },
  {
    title: 'FINRA Members',
    value: 1
  },
  {
    title: 'Attorneys',
    value: 3
  },
  {
    title: 'Corporate Brokers',
    value: 7
  }
]

const sponsorTopHeaderText = 'All OTCQX and international OTCQB applicants must engage an approved Sponsor to review the company\'s qualifications and submit a Letter of Introduction to OTC Markets Group. Qualified Investment Banks, DR Banks, Attorneys or Corporate Brokers with capital markets experience may apply.'
const becomeSponsorLink = 'https://www.otcmarkets.com/corporate-services/sponsors/become-a-sponsor'

export default class DSponsorsPage extends PureComponent {
  state = {
    sponsors: template('records'),
    selectedType: types[0].value,
    selectedFilter: null,
    searchQuery: null
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = () => {
    fetch(this, getSponsors, 'sponsors', {
      page: 1,
      pageSize: 9999,
      search: this.state.searchQuery,
      sortOn: this.state.selectedFilter && this.state.selectedFilter.value,
      sortDir: this.state.selectedFilter && this.state.selectedFilter.direction,
      typeId: this.state.selectedType
    })
  }

  selectType = selectedType => {
    this.setState({ selectedType }, this.loadData)
  }

  onSearch = searchQuery => {
    this.setState({ searchQuery }, this.loadData)
  }

  get content () {
    const { selectedFilter, sponsors } = this.state

    return (
      <div className={styles.finra}>
        <Outline mode='heading'
          content={<div className={styles.outlineHeaderSecondary}>{selectedFilter && selectedFilter.label}</div>}>
          Sponsors
        </Outline>
        <Loading
          data={sponsors.data}
          error={sponsors.error}
          emptyMessage='No sponsors found'
          reloading={sponsors.reloading}
          loaded={sponsors.loaded}>
          <div className={styles.list}>
            {sponsors.data.records.map(item => (
              <ListItem
                key={item.id}
                item={item} />
            ))}
          </div>
          <TableFooter>
            {sponsors.data.records && sponsors.data.totalRecords &&
              <DisplayResults show={sponsors.data.records.length} total={sponsors.data.totalRecords} text={'Sponsors'} />
            }
          </TableFooter>
        </Loading>
      </div>
    )
  }

  render () {
    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['search', 'filter-select', 'tabs', 'other-directories']
        }
      ],
      md: [
        {
          type: 'flex',
          column: true,
          subtree: ['search', 'filter-select', 'tabs', 'other-directories']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['sticky-column']
            },
            {
              type: 'main',
              mt: '1.8rem',
              subtree: ['tabs', 'footer-note']
            }
          ]
        }
      ]
    }

    const dirSearch = <DirSearch key='search' onSearch={this.onSearch} value={this.state.searchQuery} />

    const filterSelect = <div key='filter-select' className={styles.filterSelect}>
      <Select
        required
        options={types}
        defaultValue={this.state.selectedType}
        onSelect={this.selectType}
        design='standard' />
    </div>

    const otherDirectories = <div key='other-directories'>
      <OtherDirectories />
    </div>

    return (
      <div className={styles.component}>
        <HeadTitle title='Sponsors' />
        <PageTitle>Sponsors</PageTitle>
        <div key='sponsors-title'>
          <Outline mode='heading' underline line>BECOME AN OTCQX AND OTCQB SPONSOR</Outline>
          <Flex wrap className={styles.sponsorTitleWrapper}>
            <Flex column w={[1, 4 / 5]} className={styles.text}>
              <span>{sponsorTopHeaderText}</span>
            </Flex>
            <Flex column w={[1, 1 / 5]}>
              <ExternalLink href={becomeSponsorLink} className={styles.buttonContainer}>
                <Button title='Apply' className={styles.applyButton} type='submit' />
              </ExternalLink>
            </Flex>
          </Flex>
          <Outline mode='heading' underline line>APPROVED OTCQX AND OTCQB SPONSORS</Outline>
        </div>
        <Flexgrid layouts={layouts}>
          {dirSearch}
          {filterSelect}
          <div key='tabs' className={styles.tabs}>
            <div className={styles.content}>
              {this.content}
            </div>
          </div>
          <Sticky key='sticky-column' className={styles.sideColumn}>
            {dirSearch}
            {filterSelect}
            {otherDirectories}
          </Sticky>
          {otherDirectories}
        </Flexgrid>
      </div>
    )
  }
}
