export function request () {
  return {
    totalRecords: 874,
    pages: 44,
    currentPage: 1,
    pageSize: 20,
    sortOn: 'receivedDate',
    sortDir: 'DESC',
    records: [
      {
        exchangeId: 2,
        id: 12471261,
        dcn: '0001193125-18-007878',
        secFileNo: '001-15270',
        formTypeId: 125,
        formType: '6-K',
        receivedDate: 1515591881000,
        filedDate: 1515560400000,
        periodEndDate: 1515560400000,
        sizeOfFiling: 909918,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 96325,
        companyName: 'NOMURA HOLDINGS INC',
        companyCik: '0001163653',
        symbol: 'NMR'
      },
      {
        exchangeId: 8,
        id: 12471255,
        dcn: '0001213900-18-000330',
        secFileNo: '000-54995',
        formTypeId: 14,
        formType: '10-Q/A',
        receivedDate: 1515591141000,
        filedDate: 1515560400000,
        periodEndDate: 1506744000000,
        sizeOfFiling: 4640351,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 910102,
        companyName: 'EVANS BREWING CO INC.',
        companyCik: '0001580490',
        symbol: 'ALES'
      },
      {
        exchangeId: 8,
        id: 12471254,
        dcn: '0001213900-18-000332',
        secFileNo: '000-53605',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515591114000,
        filedDate: 1515560400000,
        periodEndDate: 1515474000000,
        sizeOfFiling: 49241,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 791989,
        companyName: 'OPTIMIZERX CORP',
        companyCik: '0001448431',
        symbol: 'OPRX'
      },
      {
        exchangeId: 8,
        id: 12471235,
        dcn: '0001062993-18-000098',
        secFileNo: '000-55139',
        formTypeId: 125,
        formType: '6-K',
        receivedDate: 1515589600000,
        filedDate: 1515560400000,
        periodEndDate: 1515560400000,
        sizeOfFiling: 29303,
        hasPdf: false,
        hasExcel: false,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 686261,
        companyName: 'QUATERRA RESOURCES INC',
        companyCik: '0001339688',
        symbol: 'QTRRF'
      },
      {
        exchangeId: 2,
        id: 12471233,
        dcn: '0001530950-18-000016',
        secFileNo: '001-35305',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515589419000,
        filedDate: 1515560400000,
        periodEndDate: 1515560400000,
        sizeOfFiling: 1210225,
        hasPdf: false,
        hasExcel: false,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 864377,
        companyName: 'POST HOLDINGS, INC.',
        companyCik: '0001530950',
        symbol: 'POST'
      },
      {
        exchangeId: 8,
        id: 12471222,
        dcn: '0001654954-18-000255',
        secFileNo: '000-50492',
        formTypeId: 125,
        formType: '6-K',
        receivedDate: 1515587536000,
        filedDate: 1515560400000,
        periodEndDate: 1514955600000,
        sizeOfFiling: 152649,
        hasPdf: false,
        hasExcel: false,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 608526,
        companyName: 'LIVEREEL MEDIA CORP',
        companyCik: '0001168981',
        symbol: 'LVRLF'
      },
      {
        exchangeId: 2,
        id: 12471161,
        dcn: '0001193125-18-007780',
        secFileNo: '001-14948',
        formTypeId: 125,
        formType: '6-K',
        receivedDate: 1515582570000,
        filedDate: 1515560400000,
        periodEndDate: 1515560400000,
        sizeOfFiling: 24707,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 100216,
        companyName: 'TOYOTA MOTOR CORP/',
        companyCik: '0001094517',
        symbol: 'TM'
      },
      {
        exchangeId: 8,
        id: 12471156,
        dcn: '0001144204-18-001562',
        secFileNo: '333-177463',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515582567000,
        filedDate: 1515560400000,
        periodEndDate: 1515560400000,
        sizeOfFiling: 25997,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 708206,
        companyName: 'AUDIOEYE INC',
        companyCik: '0001362190',
        symbol: 'AEYE'
      },
      {
        exchangeId: 4,
        id: 12471130,
        dcn: '0001654954-18-000252',
        secFileNo: '333-221789',
        formTypeId: 302,
        formType: 'S-1/A',
        receivedDate: 1515582464000,
        filedDate: 1515560400000,
        sizeOfFiling: 6660394,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 1015313,
        companyName: 'NOVUME SOLUTIONS, INC.',
        companyCik: '0001697851',
        symbol: 'NVMM'
      },
      {
        exchangeId: 8,
        id: 12471129,
        dcn: '0001640334-18-000054',
        secFileNo: '000-52831',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515582461000,
        filedDate: 1515560400000,
        periodEndDate: 1515387600000,
        sizeOfFiling: 34672,
        hasPdf: false,
        hasExcel: false,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 756805,
        companyName: 'NATE\'S FOOD CO.',
        companyCik: '0001409446',
        symbol: 'NHMD'
      },
      {
        exchangeId: 8,
        id: 12471126,
        dcn: '0001493152-18-000371',
        secFileNo: '333-214461',
        formTypeId: 302,
        formType: 'S-1/A',
        receivedDate: 1515582423000,
        filedDate: 1515560400000,
        sizeOfFiling: 6796880,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 773467,
        companyName: 'BLINK CHARGING CO.',
        companyCik: '0001429764',
        symbol: 'CCGI'
      },
      {
        exchangeId: 8,
        id: 12471113,
        dcn: '0001213900-18-000324',
        secFileNo: '000-53376',
        formTypeId: 13,
        formType: '10-Q',
        receivedDate: 1515582420000,
        filedDate: 1515560400000,
        periodEndDate: 1509422400000,
        sizeOfFiling: 2345438,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 781590,
        companyName: 'ETERNITY HEALTHCARE INC.',
        companyCik: '0001437822',
        symbol: 'ETAH'
      },
      {
        exchangeId: 8,
        id: 12471076,
        dcn: '0001165527-18-000002',
        secFileNo: '000-55873',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515582313000,
        filedDate: 1515560400000,
        periodEndDate: 1512450000000,
        sizeOfFiling: 11858,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 724157,
        companyName: 'AMERICAN PARAMOUNT GOLD CORP.',
        companyCik: '0001373690',
        symbol: 'APGA'
      },
      {
        exchangeId: 8,
        id: 12471072,
        dcn: '0001161697-18-000044',
        secFileNo: '000-55072',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515582313000,
        filedDate: 1515560400000,
        periodEndDate: 1506744000000,
        sizeOfFiling: 51278,
        hasPdf: false,
        hasExcel: false,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 840575,
        companyName: 'ANGIOSOMA, INC.',
        companyCik: '0001502152',
        symbol: 'SOAN'
      },
      {
        exchangeId: 8,
        id: 12470811,
        dcn: '0001140361-18-001487',
        secFileNo: '001-10153',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515537091000,
        filedDate: 1515474000000,
        periodEndDate: 1514955600000,
        sizeOfFiling: 39555,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 3201,
        companyName: 'HOMEFED CORP',
        companyCik: '0000833795',
        symbol: 'HOFD'
      },
      {
        exchangeId: 8,
        id: 12470809,
        dcn: '0001445866-18-000042',
        secFileNo: '000-53571',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515537074000,
        filedDate: 1515474000000,
        periodEndDate: 1515474000000,
        sizeOfFiling: 6233322,
        hasPdf: false,
        hasExcel: false,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 706487,
        companyName: 'CANNABIS SATIVA, INC.',
        companyCik: '0001360442',
        symbol: 'CBDS'
      },
      {
        exchangeId: 8,
        id: 12470793,
        dcn: '0001493152-18-000361',
        secFileNo: '001-38255',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515536831000,
        filedDate: 1515474000000,
        periodEndDate: 1513314000000,
        sizeOfFiling: 6990015,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 9768,
        companyName: 'PHI GROUP INC',
        companyCik: '0000704172',
        symbol: 'PHIL'
      },
      {
        exchangeId: 8,
        id: 12470747,
        dcn: '0001206774-18-000048',
        secFileNo: '000-51907',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515536394000,
        filedDate: 1515474000000,
        periodEndDate: 1515474000000,
        sizeOfFiling: 20709,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 658619,
        companyName: 'INDEPENDENCE BANCSHARES, INC.',
        companyCik: '0001311828',
        symbol: 'IEBS'
      },
      {
        exchangeId: 8,
        id: 12470713,
        dcn: '0001185185-18-000033',
        secFileNo: '000-27873',
        formTypeId: 135,
        formType: '8-K',
        receivedDate: 1515536020000,
        filedDate: 1515474000000,
        periodEndDate: 1515387600000,
        sizeOfFiling: 18486,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 68588,
        companyName: 'AMERICA GREAT HEALTH',
        companyCik: '0001098009',
        symbol: 'AAGH'
      },
      {
        exchangeId: 8,
        id: 12470705,
        dcn: '0001096906-18-000020',
        secFileNo: '333-175483',
        formTypeId: 13,
        formType: '10-Q',
        receivedDate: 1515535890000,
        filedDate: 1515474000000,
        periodEndDate: 1512018000000,
        sizeOfFiling: 3819389,
        hasPdf: false,
        hasExcel: true,
        filedAsHtml: true,
        beenViewed: true,
        paperOnly: false,
        valid: false,
        companyId: 859636,
        companyName: 'CHINA XUEFENG ENVIRONMENTAL ENGINEERING INC.',
        companyCik: '0001518487',
        symbol: 'CXEE'
      }
    ],
    singular: 'Sec Filing',
    plural: 'Sec Filings'
  }
}
