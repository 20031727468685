import React from 'react'
import PropTypes from 'prop-types'
import styles from './Select.module.scss'
import { Flex } from 'grid-styled'
import {
  FaCheckSquare,
  FaRegSquare,
  FaChevronDown,
  FaChevronUp
} from 'react-icons/fa'

const SelectMenuItem = (props) => {
  if (props.option.nested) {
    // parent item
    return <Flex w={1} className={styles.parentItem} justify='space-between'>
      {props.option.title}
      {
        props.menuState
          ? <FaChevronDown className={styles.parentIconClosed} />
          : <FaChevronUp className={styles.parentIconOpen} />
      }
    </Flex>
  }

  if (props.type === 'checkboxes') {
    if (props.selectionIndices[props.option.value]) {
      return <span>
        <FaCheckSquare className={styles.checkboxChecked} />
        {props.option.title}
      </span>
    } else {
      return <span>
        <FaRegSquare className={styles.checkboxUnchecked} />
        {props.option.title}
      </span>
    }
  } else {
    return <span>{props.option.title}</span>
  }
}

SelectMenuItem.propTypes = {
  option: PropTypes.object,
  type: PropTypes.string,
  selectionIndices: PropTypes.array,
  menuState: PropTypes.any
}

export default SelectMenuItem
