import api from '../../api'

export async function getTradeData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    mockData: 'stock/trades/trade-data',
    url: `/internal/stock/trade/data/${params.symbol}`,
    params
  })
  return response.data
}

export async function getInsideData (symbol) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'object',
    mockData: 'stock/trades/inside-data',
    url: `/internal/stock/trade/inside/${symbol}`,
    params: {
      symbol
    }
  })
  return response.data
}
