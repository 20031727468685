import api from '../../api'

export async function getMediaCoverage (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    url: '/news/media-coverage',
    params
  })
  return response.data
}
