import React from 'react'
import PropTypes from 'prop-types'
import { withContext } from '../../AuthContext'
import CodeInput from '../CodeInput'
import { Button, TextLink } from '@otcmarketsgroup/otcm-ui'
import { FaRegWindowClose } from 'react-icons/fa'
import styles from '../LogIn/LogIn.module.scss'

class ConfirmLogIn extends React.PureComponent {
  constructor (props, context) {
    super(props, context)

    this.state = {
      code: '',
      error: false
    }
  }

  handleChange = (value) => {
    this.setState({ code: value })
  }

  render () {
    const { context } = this.props
    return <div className={styles.container}>
      {context.successBanner && <div className={styles.banner}>
        <span>Your authentication code has been sent.</span> <FaRegWindowClose className={styles.closeBtn} onClick={context.closeBanner} />
      </div>}
      <div className={styles.form}>
        <div className={styles.greeting}>
          <h3 className={styles.formTitle}>Confirm Login</h3>
          <p className={styles.formDesc}>
            We have sent you a message via SMS with your authentication code to {context.user.challengeParam.CODE_DELIVERY_DESTINATION}. Enter the code below to verify your identity.
          </p>
        </div>
        <form onSubmit={(event) => context.submitConfirmLogin(this.state.code, event)}>
          <CodeInput type='number' fields={6} onChange={this.handleChange} />
          {context.error && <i className={styles.error}>{context.error}</i>}
          <Button id={'submitLogIn'} inactive={context.disableButton} className={styles.submit} title='Verify' fullWidth type='submit' />
          <TextLink onClick={context.resendCode}>Resend Code</TextLink>
        </form>
      </div>
    </div>
  }
}

ConfirmLogIn.propTypes = {
  context: PropTypes.shape({
    submitConfirmLogin: PropTypes.func,
    resendCode: PropTypes.func,
    successBanner: PropTypes.bool,
    error: PropTypes.string,
    closeBanner: PropTypes.func,
    disableButton: PropTypes.func,
    user: PropTypes.object
  })
}

export default withContext(ConfirmLogIn)
