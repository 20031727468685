export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return {
        totalRecords: 0,
        pages: 0,
        currentPage: 0,
        pageSize: 20,
        sortOn: 'releaseDate',
        sortDir: 'DESC',
        singular: 'Financial Report',
        plural: 'Financial Reports',
        companyId: 655940,
        statusId: 'ALL'
      }

    case 'OTCM':
      return {
        totalRecords: 97,
        pages: 10,
        currentPage: 2,
        pageSize: 10,
        sortOn: 'releaseDate',
        sortDir: 'DESC',
        records: [
          {
            id: 167905,
            companyId: 674812,
            userId: 2150,
            title: 'Sidoti & Company, LLC Report - March 2, 2017',
            typeId: 'RR',
            statusId: 'A',
            periodDate: 1488430800000,
            isImmediate: true,
            createdDate: 1489692177000,
            lastModifiedDate: 1489692177000,
            releaseDate: 1489692177000,
            canDistribute: true,
            wasDistributed: false,
            companyName: 'OTC Markets Group Inc.',
            reportType: 'Research Report',
            name: 'Research Report - Sidoti & Company, LLC Report - March 2, 2017',
            statusDescript: 'Active',
            symbol: 'OTCM',
            primarySymbol: 'OTCM',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'QX'
          },
          {
            id: 167136,
            companyId: 674812,
            userId: 6733,
            title: 'Annual Report Fiscal Year 2016',
            typeId: 'AR',
            statusId: 'A',
            periodDate: 1483160400000,
            isImmediate: false,
            createdDate: 1488403574000,
            lastModifiedDate: 1488405967437,
            releaseDate: 1488405967437,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'OTC Markets Group Inc.',
            reportType: 'Annual Report',
            name: 'Annual Report - Annual Report Fiscal Year 2016',
            statusDescript: 'Active',
            symbol: 'OTCM',
            primarySymbol: 'OTCM',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'QX'
          },
          {
            id: 163444,
            companyId: 674812,
            userId: 10361,
            title: 'Edison Investment Research- Subscription base provides stability',
            typeId: 'RR',
            statusId: 'A',
            periodDate: 1479618000000,
            isImmediate: true,
            createdDate: 1480372367000,
            lastModifiedDate: 1480372367000,
            releaseDate: 1480372367000,
            canDistribute: true,
            wasDistributed: false,
            companyName: 'OTC Markets Group Inc.',
            reportType: 'Research Report',
            name: 'Research Report - Edison Investment Research- Subscription base provides stability',
            statusDescript: 'Active',
            symbol: 'OTCM',
            primarySymbol: 'OTCM',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'QX'
          },
          {
            id: 163154,
            companyId: 674812,
            userId: 2150,
            title: '2016 Annual Meeting Notice and Proxy',
            typeId: 'PXST',
            statusId: 'A',
            periodDate: 1481778000000,
            isImmediate: true,
            createdDate: 1479846832000,
            lastModifiedDate: 1479846832000,
            releaseDate: 1479846832000,
            canDistribute: true,
            wasDistributed: false,
            companyName: 'OTC Markets Group Inc.',
            reportType: 'Proxy Statement',
            name: 'Proxy Statement - 2016 Annual Meeting Notice and Proxy',
            statusDescript: 'Active',
            symbol: 'OTCM',
            primarySymbol: 'OTCM',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'QX'
          },
          {
            id: 163153,
            companyId: 674812,
            userId: 2150,
            title: 'Proxy Materials',
            typeId: 'PXM',
            statusId: 'A',
            periodDate: 1481778000000,
            isImmediate: true,
            createdDate: 1479846744000,
            lastModifiedDate: 1479846744000,
            releaseDate: 1479846744000,
            canDistribute: true,
            wasDistributed: false,
            companyName: 'OTC Markets Group Inc.',
            reportType: 'Proxy Materials',
            name: 'Proxy Materials - Proxy Materials',
            statusDescript: 'Active',
            symbol: 'OTCM',
            primarySymbol: 'OTCM',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'QX'
          },
          {
            id: 163034,
            companyId: 674812,
            userId: 10361,
            title: 'Subscription base provides stability',
            typeId: 'RR',
            statusId: 'I',
            periodDate: 1479618000000,
            isImmediate: true,
            createdDate: 1479756639000,
            lastModifiedDate: 1479756755636,
            releaseDate: 1479618000000,
            canDistribute: true,
            wasDistributed: false,
            companyName: 'OTC Markets Group Inc.',
            reportType: 'Research Report',
            name: 'Research Report - Subscription base provides stability',
            statusDescript: 'Inactive',
            symbol: 'OTCM',
            primarySymbol: 'OTCM',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'QX'
          },
          {
            id: 162002,
            companyId: 674812,
            userId: 11719,
            title: 'Third Quarter 2016',
            typeId: 'QR',
            statusId: 'A',
            periodDate: 1475208000000,
            isImmediate: false,
            createdDate: 1478118690000,
            lastModifiedDate: 1478120169735,
            releaseDate: 1478120169735,
            canDistribute: true,
            wasDistributed: false,
            companyName: 'OTC Markets Group Inc.',
            reportType: 'Quarterly Report',
            name: 'Quarterly Report - Third Quarter 2016',
            statusDescript: 'Active',
            symbol: 'OTCM',
            primarySymbol: 'OTCM',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'QX'
          },
          {
            id: 160224,
            companyId: 674812,
            userId: 11719,
            title: 'Edison Investment Research - OTCM 2016 Q2',
            typeId: 'RR',
            statusId: 'A',
            periodDate: 1467259200000,
            isImmediate: false,
            createdDate: 1473794583000,
            lastModifiedDate: 1473800770252,
            releaseDate: 1473800770252,
            canDistribute: true,
            wasDistributed: false,
            companyName: 'OTC Markets Group Inc.',
            reportType: 'Research Report',
            name: 'Research Report - Edison Investment Research - OTCM 2016 Q2',
            statusDescript: 'Active',
            symbol: 'OTCM',
            primarySymbol: 'OTCM',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'QX'
          },
          {
            id: 160223,
            companyId: 674812,
            userId: 11719,
            title: 'Edison Investment Research - OTCM 2016 Q1',
            typeId: 'RR',
            statusId: 'A',
            periodDate: 1459396800000,
            isImmediate: false,
            createdDate: 1473794476000,
            lastModifiedDate: 1473800770250,
            releaseDate: 1473800770250,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'OTC Markets Group Inc.',
            reportType: 'Research Report',
            name: 'Research Report - Edison Investment Research - OTCM 2016 Q1',
            statusDescript: 'Active',
            symbol: 'OTCM',
            primarySymbol: 'OTCM',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'QX'
          },
          {
            id: 160222,
            companyId: 674812,
            userId: 11719,
            title: 'Edison Investment Research - OTCM Fiscal Year 2015',
            typeId: 'RR',
            statusId: 'A',
            periodDate: 1451538000000,
            isImmediate: false,
            createdDate: 1473794416000,
            lastModifiedDate: 1473800770248,
            releaseDate: 1473800770248,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'OTC Markets Group Inc.',
            reportType: 'Research Report',
            name: 'Research Report - Edison Investment Research - OTCM Fiscal Year 2015',
            statusDescript: 'Active',
            symbol: 'OTCM',
            primarySymbol: 'OTCM',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'QX'
          }
        ],
        singular: 'Financial Report',
        plural: 'Financial Reports',
        companyId: 674812,
        statusId: 'ALL'
      }

    case 'VLKAY':
      return {
        totalRecords: 46,
        pages: 5,
        currentPage: 2,
        pageSize: 10,
        sortOn: 'releaseDate',
        sortDir: 'DESC',
        records: [
          {
            id: 108703,
            companyId: 1471,
            userId: 5850,
            title: 'Ad hoc Release: Interim Report January-June 2013',
            typeId: 'HCPR',
            statusId: 'A',
            periodDate: 1372564800000,
            isImmediate: true,
            createdDate: 1375256511839,
            lastModifiedDate: 1375256511839,
            releaseDate: 1375256511839,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'Volkswagen AG',
            reportType: 'Home Country News Release',
            name: 'Home Country News Release - Ad hoc Release: Interim Report January-June 2013',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            primarySymbol: 'VLKAY',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'PC'
          },
          {
            id: 103253,
            companyId: 1471,
            userId: 5850,
            title: 'Interim Report January-March 2013',
            typeId: 'IFR',
            statusId: 'A',
            periodDate: 1364702400000,
            isImmediate: true,
            createdDate: 1367259059829,
            lastModifiedDate: 1367259059829,
            releaseDate: 1367259059829,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'Volkswagen AG',
            reportType: 'Interim Financial Report',
            name: 'Interim Financial Report - Interim Report January-March 2013',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            primarySymbol: 'VLKAY',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'PC'
          },
          {
            id: 103068,
            companyId: 1471,
            userId: 5850,
            title: 'Ad hoc Release: Interim Report January-March 2013',
            typeId: 'HCPR',
            statusId: 'A',
            periodDate: 1364702400000,
            isImmediate: true,
            createdDate: 1366876563200,
            lastModifiedDate: 1366876563200,
            releaseDate: 1366876563200,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'Volkswagen AG',
            reportType: 'Home Country News Release',
            name: 'Home Country News Release - Ad hoc Release: Interim Report January-March 2013',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            primarySymbol: 'VLKAY',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'PC'
          },
          {
            id: 100972,
            companyId: 1471,
            userId: 5850,
            title: 'Volkswagen AG Annual Report 2012',
            typeId: 'AR',
            statusId: 'A',
            periodDate: 1356930000000,
            isImmediate: true,
            createdDate: 1363370389556,
            lastModifiedDate: 1363370389556,
            releaseDate: 1363370389556,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'Volkswagen AG',
            reportType: 'Annual Report',
            name: 'Annual Report - Volkswagen AG Annual Report 2012',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            primarySymbol: 'VLKAY',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'PC'
          },
          {
            id: 99912,
            companyId: 1471,
            userId: 5850,
            title: 'Home Country News Release - Volkswagen presents 2012 preliminary consolidated financial statements',
            typeId: 'HCPR',
            statusId: 'A',
            periodDate: 1356930000000,
            isImmediate: true,
            createdDate: 1361680981824,
            lastModifiedDate: 1361680981824,
            releaseDate: 1361680981824,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'Volkswagen AG',
            reportType: 'Home Country News Release',
            name: 'Home Country News Release - Home Country News Release - Volkswagen presents 2012 preliminary consolidated financial statements',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            primarySymbol: 'VLKAY',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'PC'
          },
          {
            id: 93000,
            companyId: 1471,
            userId: 5850,
            title: 'Interim Report January - September 2012',
            typeId: 'IFR',
            statusId: 'A',
            periodDate: 1348977600000,
            isImmediate: true,
            createdDate: 1351069844391,
            lastModifiedDate: 1351069844391,
            releaseDate: 1351069844391,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'Volkswagen AG',
            reportType: 'Interim Financial Report',
            name: 'Interim Financial Report - Interim Report January - September 2012',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            primarySymbol: 'VLKAY',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'PC'
          },
          {
            id: 92999,
            companyId: 1471,
            userId: 5850,
            title: 'Interim Report January-September 2012',
            typeId: 'HCPR',
            statusId: 'A',
            periodDate: 1348977600000,
            isImmediate: true,
            createdDate: 1351069541307,
            lastModifiedDate: 1351069541307,
            releaseDate: 1351069541307,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'Volkswagen AG',
            reportType: 'Home Country News Release',
            name: 'Home Country News Release - Interim Report January-September 2012',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            primarySymbol: 'VLKAY',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'PC'
          },
          {
            id: 86630,
            companyId: 1471,
            userId: 5850,
            title: 'Interim Report January-June 2012',
            typeId: 'HCPR',
            statusId: 'A',
            periodDate: 1343275200000,
            isImmediate: true,
            createdDate: 1343298950178,
            lastModifiedDate: 1343298950178,
            releaseDate: 1343298950178,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'Volkswagen AG',
            reportType: 'Home Country News Release',
            name: 'Home Country News Release - Interim Report January-June 2012',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            primarySymbol: 'VLKAY',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'PC'
          },
          {
            id: 86628,
            companyId: 1471,
            userId: 5850,
            title: 'Half-Yearly Financial Report January - June 2012',
            typeId: 'QR',
            statusId: 'A',
            periodDate: 1341028800000,
            isImmediate: true,
            createdDate: 1343298590614,
            lastModifiedDate: 1343298590614,
            releaseDate: 1343298590614,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'Volkswagen AG',
            reportType: 'Quarterly Report',
            name: 'Quarterly Report - Half-Yearly Financial Report January - June 2012',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            primarySymbol: 'VLKAY',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'PC'
          },
          {
            id: 85546,
            companyId: 1471,
            userId: 5850,
            title: 'Volkswagen and Porsche create integrated automotive group',
            typeId: 'HCPR',
            statusId: 'A',
            periodDate: 1341374400000,
            isImmediate: true,
            createdDate: 1341431273818,
            lastModifiedDate: 1341431273818,
            releaseDate: 1341431273818,
            canDistribute: false,
            wasDistributed: false,
            companyName: 'Volkswagen AG',
            reportType: 'Home Country News Release',
            name: 'Home Country News Release - Volkswagen and Porsche create integrated automotive group',
            statusDescript: 'Active',
            symbol: 'VLKAY',
            primarySymbol: 'VLKAY',
            isCaveatEmptor: false,
            edgarSECFiling: false,
            tierCode: 'PC'
          }
        ],
        singular: 'Financial Report',
        plural: 'Financial Reports',
        companyId: 1471,
        statusId: 'ALL'
      }
  }
}
