export function request (params, pagination) {
  switch (params.symbol) {
    case 'GOOG':
      return {
        currentPage: 2,
        pageSize: 10,
        records: [],
        singular: 'Trade',
        plural: 'Trades'
      }

    case 'OTCM':
      return {
        currentPage: 2,
        pageSize: 10,
        records: [
          {
            eventTimestamp: 1515426566203,
            tradeTypeId: 2,
            tradeDate: '2018-01-08',
            tradeTime: '10:49:24',
            lastPrice: 29.2,
            lastVolume: 113,
            tradeDirection: 'Down',
            pctChange: -0.6126617
          },
          {
            eventTimestamp: 1515425230206,
            tradeTypeId: 2,
            tradeDate: '2018-01-08',
            tradeTime: '10:27:09',
            lastPrice: 29.38,
            lastVolume: 100,
            tradeDirection: 'Down',
            pctChange: -0.1698947
          },
          {
            eventTimestamp: 1515423398102,
            tradeTypeId: 2,
            tradeDate: '2018-01-08',
            tradeTime: '09:56:36',
            lastPrice: 29.43,
            lastVolume: 100,
            tradeDirection: 'Down',
            pctChange: -0.1696065
          },
          {
            eventTimestamp: 1515423122607,
            tradeTypeId: 2,
            tradeDate: '2018-01-08',
            tradeTime: '09:52:01',
            lastPrice: 29.48,
            lastVolume: 100,
            tradeDirection: 'Down',
            pctChange: -0.1016605
          },
          {
            eventTimestamp: 1515422388505,
            tradeTypeId: 2,
            tradeDate: '2018-01-08',
            tradeTime: '09:39:47',
            lastPrice: 29.51,
            lastVolume: 100,
            tradeDirection: 'None - Last Down',
            pctChange: 0
          },
          {
            eventTimestamp: 1515421805393,
            tradeTypeId: 2,
            tradeDate: '2018-01-08',
            tradeTime: '09:30:03',
            lastPrice: 29.51,
            lastVolume: 400,
            tradeDirection: 'None - Last Down',
            pctChange: 0
          },
          {
            eventTimestamp: 1515182278081,
            tradeTypeId: 2,
            tradeDate: '2018-01-05',
            tradeTime: '14:57:56',
            lastPrice: 29.51,
            lastVolume: 250,
            tradeDirection: 'None - Last Down',
            pctChange: 0
          },
          {
            eventTimestamp: 1515180225983,
            tradeTypeId: 2,
            tradeDate: '2018-01-05',
            tradeTime: '14:23:45',
            lastPrice: 29.51,
            lastVolume: 140,
            tradeDirection: 'Down',
            pctChange: -1.2382865
          },
          {
            eventTimestamp: 1515163410582,
            tradeTypeId: 2,
            tradeDate: '2018-01-05',
            tradeTime: '09:43:29',
            lastPrice: 29.88,
            lastVolume: 100,
            tradeDirection: 'None - Last Up',
            pctChange: 0
          },
          {
            eventTimestamp: 1515163396593,
            tradeTypeId: 2,
            tradeDate: '2018-01-05',
            tradeTime: '09:43:16',
            lastPrice: 29.88,
            lastVolume: 100,
            tradeDirection: 'None - Last Up',
            pctChange: 0
          }
        ],
        singular: 'Trade',
        plural: 'Trades'
      }

    case 'VLKAY':
      return {
        currentPage: 2,
        pageSize: 10,
        records: [
          {
            eventTimestamp: 1515531430606,
            tradeTypeId: 2,
            tradeDate: '2018-01-09',
            tradeTime: '15:57:09',
            lastPrice: 43.22,
            lastVolume: 72,
            tradeDirection: 'Down',
            pctChange: -0.0231321
          },
          {
            eventTimestamp: 1515531419693,
            tradeTypeId: 2,
            tradeDate: '2018-01-09',
            tradeTime: '15:56:58',
            lastPrice: 43.23,
            lastVolume: 2000,
            tradeDirection: 'Up',
            pctChange: 0.0694444
          },
          {
            eventTimestamp: 1515531249099,
            tradeTypeId: 2,
            tradeDate: '2018-01-09',
            tradeTime: '15:54:08',
            lastPrice: 43.2,
            lastVolume: 13,
            tradeDirection: 'None - Last Down',
            pctChange: 0
          },
          {
            eventTimestamp: 1515531160894,
            tradeTypeId: 2,
            tradeDate: '2018-01-09',
            tradeTime: '15:52:39',
            lastPrice: 43.2,
            lastVolume: 30,
            tradeDirection: 'Down',
            pctChange: -0.0416493
          },
          {
            eventTimestamp: 1515531126993,
            tradeTypeId: 2,
            tradeDate: '2018-01-09',
            tradeTime: '15:52:05',
            lastPrice: 43.218,
            lastVolume: 250,
            tradeDirection: 'Down',
            pctChange: -0.0161943
          },
          {
            eventTimestamp: 1515531043395,
            tradeTypeId: 2,
            tradeDate: '2018-01-09',
            tradeTime: '15:50:41',
            lastPrice: 43.225,
            lastVolume: 300,
            tradeDirection: 'Up',
            pctChange: 0.0810373
          },
          {
            eventTimestamp: 1515530982593,
            tradeTypeId: 2,
            tradeDate: '2018-01-09',
            tradeTime: '15:49:41',
            lastPrice: 43.19,
            lastVolume: 253,
            tradeDirection: 'Down',
            pctChange: -0.0925283
          },
          {
            eventTimestamp: 1515530972809,
            tradeTypeId: 2,
            tradeDate: '2018-01-09',
            tradeTime: '15:49:31',
            lastPrice: 43.23,
            lastVolume: 100,
            tradeDirection: 'Up',
            pctChange: 0.092614
          },
          {
            eventTimestamp: 1515530892501,
            tradeTypeId: 2,
            tradeDate: '2018-01-09',
            tradeTime: '15:48:11',
            lastPrice: 43.19,
            lastVolume: 88,
            tradeDirection: 'Down',
            pctChange: -0.1848856
          },
          {
            eventTimestamp: 1515530888294,
            tradeTypeId: 2,
            tradeDate: '2018-01-09',
            tradeTime: '15:48:07',
            lastPrice: 43.27,
            lastVolume: 150,
            tradeDirection: 'Up',
            pctChange: 0.0462428
          }
        ],
        singular: 'Trade',
        plural: 'Trades'
      }
  }
}
