import React from 'react'
import styles from './MAIndicesPage.module.scss'

import Flexgrid from '../../components/Flexgrid'
import Chart from '../../components/Chart'
import Outline from '../../components/Outline'
import Table from '../../components/Table'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import HeadTitle from '../../components/HeadTitle'
import ExternalLink from '../../components/ExternalLink'
import Sticky from '../../components/Sticky'

import { getSnapshot } from '../../api/otc/indices/indices'
import getConfig from '../../config'

const { REACT_APP_API_BASE_URI } = getConfig()

const columns = [
  {
    name: 'description',
    header: 'Index',
    bold: true,
    linkTo: item => `/index/${item.symbol}`
  },
  {
    name: 'symbol',
    header: 'Symbol',
    linkTo: item => `/index/${item.symbol}`,
    hideOnMobile: true
  },
  {
    name: 'lastSale',
    header: 'Last Sale',
    type: 'float'
  },
  {
    name: 'change',
    header: 'Change',
    type: 'float',
    color: item => parseFloat(item.change) < 0 ? 'red' : 'limegreen'
  },
  {
    name: 'percentChange',
    header: '% Change',
    type: 'percentage',
    color: item => parseFloat(item.percentChange) < 0 ? 'red' : 'limegreen'
  }
]

const layouts = {
  sm: [
    {
      type: 'flex',
      column: true,
      subtree: ['chart', 'table', 'footer-note', 'links']
    }
  ],
  md: [
    {
      type: 'flex',
      column: true,
      subtree: ['chart', 'table', 'footer-note']
    }
  ],
  lg: [
    {
      type: 'flex',
      subtree: [
        {
          type: 'main',
          subtree: ['chart', 'table', 'footer-note']
        },
        {
          type: 'side',
          subtree: ['links']
        }
      ]
    }
  ]
}

class MAIndicesPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      snapshot: {
        data: [],
        loaded: false,
        error: null
      }
    }
  }

  componentDidMount () {
    getSnapshot().then(snapshot => {
      this.setState({
        snapshot: {
          data: snapshot,
          loaded: true
        }
      })
    }).catch(err => {
      console.error(err)
      this.setState({
        snapshot: {
          error: 'Failed to load market snapshot. Please try again.'
        }
      })
    })
  }

  render () {
    const qxLinkURL = `${REACT_APP_API_BASE_URI}/otc-content/files/OTCQX-Composite-Index-Rules-1515527185671.pdf`
    return (
      <div className={styles.MAIndicesPage}>
        <HeadTitle title='Get Information on OTC Markets Indices' />
        <PageTitle>OTC Markets Indices</PageTitle>
        <Flexgrid layouts={layouts}>
          <div key='chart'>
            <Chart symbol='.OTCQX' />
          </div>
          <div key='table' className={styles.snapshot}>
            <Outline mode='heading'>Market Snapshot</Outline>
            <Loading
              type='table'
              emptyMessage={'Market snapshot unavailable'}
              error={this.state.snapshot.error}
              data={this.state.snapshot.data}
              loaded={this.state.snapshot.loaded}>
              <Table
                columns={columns}
                data={this.state.snapshot.data}
                showHeader={false}
              />
            </Loading>
          </div>
          <div key='footer-note' className={styles.footerNote}>
            Intraday activity delayed 15 minutes.
          </div>
          <Sticky key='links' className={styles.sideColumn}>
            <div className={styles.links}>
              <Outline mode='heading'>
                Related Links
              </Outline>
              <div className={styles.linkList}>
                <p>
                  <ExternalLink href={qxLinkURL} target='_blank'>OTCQX Composite Rule Book</ExternalLink>
                </p>
              </div>
            </div>
          </Sticky>
          <div key='empty' />
        </Flexgrid>
      </div>
    )
  }
}

export default MAIndicesPage
