import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '../../components/WithRouter'
import styles from './DSponsorPage.module.scss'
import queryString from 'query-string'
import * as Routes from '../../constants/Routes'
import { template, fetch, expand } from '../../api/helper'
import { getDetails, getAssociated, getAllSponsoredCompanies } from '../../api/otc/directory/sponsor'

import HeadTitle from '../../components/HeadTitle'
import NotFound from '../NotFound'
import Flexgrid from '../../components/Flexgrid'
import Outline from '../../components/Outline'
import Table from '../../components/Table'
import More from '../../components/More'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import BackLink from '../../components/BackLink'
import ExternalLink from '../../components/ExternalLink'

const columns = [
  {
    name: 'name',
    header: 'Name'
  },
  {
    name: 'city',
    header: 'City',
    hideOnMobile: true
  },
  {
    name: 'country',
    header: 'Country'
  },
  {
    name: 'Symbol',
    header: 'symbol',
    type: 'symbol'
  },
  {
    name: 'tierName',
    header: 'tier',
    hideOnMobile: true
  }
]

export class DSponsorPage extends PureComponent {
  state = {
    notFound: false,
    info: null,
    associated: template('records'),
    sponsoredCompanies: template('records')
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = () => {
    const id = this.props.match.params.id

    if (!id) return this.setState({ notFound: true })
    const queryParams = queryString.parse(this.props.location.search)

    getDetails({ id, typeId: queryParams.t })
      .then((data) => {
        this.setState({
          notFound: data && !data.serviceProviderInfo,
          info: data && data.serviceProviderInfo
        })
      })
      .catch(() => {
        this.setState({ notFound: true })
      })

    fetch(this, getAssociated, 'associated', {
      serviceProviderId: id,
      page: 1,
      pageSize: 5
    })

    fetch(this, getAllSponsoredCompanies, 'sponsoredCompanies', {
      serviceProviderId: id,
      page: 1,
      pageSize: 5
    })
  }

  get content () {
    const { associated, sponsoredCompanies, info } = this.state

    return (
      <div>
        {info && info.description && <div className={styles.description}>{info.description}</div>}
        <div className={styles.table}>
          <Outline mode='heading'>
            Associated Companies
          </Outline>
          <Loading type='table'
            data={associated.data}
            reloading={associated.reloading}
            loaded={associated.loaded}>
            <Table
              columns={columns}
              data={associated.data.records}
              showHeader
            />
            <More
              onClick={expand(this, getAssociated, 'associated')}
              disabled={associated.expandEmpty} />
          </Loading>
        </div>
        <div className={styles.table}>
          <Outline mode='heading'>
            Companies Sponsored for OTCQX and OTCQB
          </Outline>
          <Loading type='table'
            data={sponsoredCompanies.data}
            reloading={sponsoredCompanies.reloading}
            loaded={sponsoredCompanies.loaded}>
            <Table
              columns={columns}
              data={sponsoredCompanies.data.records}
              showHeader
            />
            <More
              onClick={expand(this, getAllSponsoredCompanies, 'sponsoredCompanies')}
              disabled={sponsoredCompanies.expandEmpty} />
          </Loading>
        </div>
      </div>
    )
  }

  get details () {
    const { info } = this.state
    return (
      <div className={styles.detailsInner}>
        <Loading height='8em' loaded={!!info}>
          {info && <div className={styles.companyInfo}>
            <div className={styles.companyName}>
              {info.name}
            </div>
            <div className={styles.companyTypes}>
              {info.typeDesc}
            </div>
            {/* <div className={styles.separator} />
            <div>Sponsor Approval Date: 3/15/2007</div>
            <div>OTCQX Companies Sponsored: 8</div> */}
            <div className={styles.separator} />
            {info.addr1 && <div>{info.addr1}</div>}
            {info.addr2 && <div>{info.addr2}</div>}
            {info.addr3 && <div>{info.addr3}</div>}
            {info.city && <div>{info.city}, {info.state} {info.zip}</div>}
            {info.country && info.country !== 'USA' && <div>{info.country}</div>}
            {info.phone && <div>{info.phone}</div>}
            {info.website && <div><ExternalLink href={info.website} external /></div>}
            {/* <div className={styles.separator} />
            <div>Christopher Weekes</div>
            <div>
              <a href='mailto:christopher.weekes@cowen.com'>
                Christopher.Weekes@cowen.com
              </a>
            </div>
            <div>646-562-1133</div> */}
          </div>}
        </Loading>
      </div>
    )
  }

  render () {
    if (this.state.notFound) return <NotFound />

    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['back', 'details', 'content']
        }
      ],
      md: [
        {
          type: 'flex',
          column: true,
          subtree: ['back', 'details', 'content']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['back', 'details']
            },
            {
              type: 'main',
              mt: '1.8rem',
              subtree: ['content']
            }
          ]
        }
      ]
    }

    return (
      <div className={styles.component}>
        <HeadTitle title='Directory - OTCQX & OTCQB Sponsors' />
        <PageTitle>OTCQX & OTCQB Sponsors</PageTitle>
        <Flexgrid layouts={layouts}>
          <BackLink to={Routes.DIR_SPONSORS} key='back' ignoreHistory />
          <div key='details' className={styles.details}>
            {this.details}
          </div>
          <div key='content' className={styles.content}>
            {this.content}
          </div>
        </Flexgrid>
      </div>
    )
  }
}

DSponsorPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

export default withRouter(DSponsorPage)
