import React from 'react'
import PropTypes from 'prop-types'
import styles from './ListItem.module.scss'
import { Link } from 'react-router-dom'
import { Flex, Box } from 'grid-styled'
import moment from 'moment-timezone'
import Expandable from '../../../../components/Expandable'
import * as Routes from '../../../../constants/Routes'

const getApprovalData = item => {
  const mApprovalDate = moment(item.sponsorApprovalDate)
  return mApprovalDate.tz('America/New_York').format('MM/DD/YYYY')
}

const ListItem = ({ item }) => {
  const typeId = (item.typeDesc === 'Corporate Broker') ? item.typeId : 4
  const sponsorLink = `${Routes.DIR_SPONSORS}/${item.id}?t=${typeId}`

  return (
    <div className={styles.component}>
      <div className={styles.header}>
        <div className={styles.headerInfo}>
          <div className={styles.name}>
            <Link to={sponsorLink}>{item.name}</Link>
          </div>
          <div className={styles.types}>
            {item.typeDesc}
          </div>
        </div>
      </div>
      <div className={styles.infoPrimary}>
        <div>Sponsor Approval Date: {getApprovalData(item)}</div>
      </div>
      <Flex className={styles.info} wrap>
        {(item.addresses && item.addresses[0]) && (
          <Box w={[1, 1 / 3, 1 / 3]} className={styles.infoItem}>
            <div>{item.addresses[0].address1}</div>
            {item.addresses[0].address2 && <div>{item.addresses[0].address2}</div>}
            <div>{item.addresses[0].city}, {item.addresses[0].state} {item.addresses[0].postalCode}</div>
            {/* display country only if outside of US */}
            {item.addresses[0].country && item.addresses[0].country.name !== 'USA' && <div>{item.addresses[0].country.name}</div>}
          </Box>
        )}
        {(item.contacts && item.contacts.length === 1) && (
          <Box w={[1, 1 / 3, 1 / 3]} className={styles.infoItem}>
            <div>{item.contacts[0].firstName} {item.contacts[0].lastName}</div>
            {item.contacts[0].phone1 && <div>{item.contacts[0].phone1}</div>}
            {item.contacts[0].email && <div><a href={`mailto:${item.contacts[0].email}`}>{item.contacts[0].email}</a></div>}
          </Box>
        )}
        {(item.contacts && item.contacts.length > 1) && (
          <Box w={[1, 1 / 3, 1 / 3]} className={styles.infoItem}>
            {item.contacts.map((contact, i) =>
              <div key={i}>
                <Expandable text={`${contact.firstName} ${contact.lastName}`}>
                  {contact.phone1 && <div>{contact.phone1}</div>}
                  {contact.email && <div><a href={`mailto:${contact.email}`}>{contact.email}</a></div>}
                </Expandable>
              </div>
            )}
          </Box>
        )}
      </Flex>
    </div>
  )
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired
}

export default ListItem
