import getConfig from '../config'
const { REACT_APP_STOCK_SCREENER_URL } = getConfig()

export const headerLinks = [
  {
    link: 'Market Activity',
    to: '/market-activity/current-market',
    sublinks: [
      {
        link: 'Current Market',
        to: '/market-activity/current-market'
      },
      {
        link: 'Closing Summary',
        to: '/market-activity/closing-summary'
      },
      {
        link: 'Broker Dealer Data',
        to: '/market-activity/broker-dealer-data'
      },
      {
        link: 'Compliance Analytics',
        to: '/market-activity/compliance-analytics'
      },
      {
        link: 'Compliance Statistics',
        key: 'market-activity/compliance-statistics',
        to: '/market-activity/compliance-statistics'
      },
      {
        link: 'Key Securities',
        to: '/market-activity/key-securities'
      },
      {
        link: 'Corporate Actions',
        to: '/market-activity/corporate-actions'
      },
      {
        link: 'OTCM Indices',
        key: 'indices',
        to: '/market-activity/indices'
      },
      {
        link: 'News',
        to: '/market-activity/news'
      },
      {
        link: 'Short Interest Data',
        to: '/market-activity/short-interest-data'
      },
      {
        link: 'Reg SHO Data',
        to: '/market-activity/reg-sho-data'
      },
      {
        link: 'Stock Screener',
        href: REACT_APP_STOCK_SCREENER_URL
      }
    ]
  },
  {
    link: 'Directories',
    to: '/directories/prohibited-service-providers',
    sublinks: [
      { link: 'Prohibited Service Providers', to: '/directories/prohibited-service-providers' },
      { link: 'Service Provider Directory', to: '/directories/service-providers' },
      { link: 'Broker Dealer Directory', to: '/directories/broker-dealer-directory' },
      { link: 'Company Directory', to: '/directories/company-directory' },
      { link: 'Sponsors', to: '/directories/sponsors' },
      { link: 'Qualified Foreign Exchange', to: '/directories/qualified-foreign-exchange' },
      { link: 'State Securities Commissions', to: '/directories/state-securities-commissions' }
    ]
  },
  {
    link: 'Learn',
    to: '/learn/15c2-11-resource-center',
    sublinks: [
      { link: '15c2-11 Resource Center', to: '/learn/15c2-11-resource-center' },
      { link: 'FINRA / SEC Rules', to: '/learn/finra-sec-rules' },
      { link: 'Blue Sky Rules', to: '/learn/blue-sky-rules' },
      { link: 'Caveat Emptor Policy', to: '/learn/caveat-emptor' },
      { link: 'Policy on Stock Promotion', to: '/learn/policy-on-stock-promotion' },
      { link: 'Reporting Standards', to: '/learn/reporting-standards' },
      { link: 'FAQs', to: '/learn/faqs' }
    ]
  },
  {
    link: 'Contact',
    to: '/contact'
  },
  {
    link: 'Profile',
    to: '',
    sublinks: [
      { link: 'Change Password', to: '/change-password' },
      { link: 'Logout', to: 'logout' }
    ]
  }
  // {
  //   link: 'Files',
  //   to: '/files/compliance-analytics',
  //   sublinks: [
  //     { link: 'Compliance Analytics', to: '/files/compliance-analytics' },
  //     { link: 'Compliance Data', to: '/files/compliance-data' }
  //   ]
  // }
]
