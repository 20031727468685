import React from 'react'
import * as Routes from '../../constants/Routes'
import HeadTitle from '../../components/HeadTitle'
import HelpfulLinks from '../../components/HelpfulLinks'
import styles from './NotFound.module.scss'
import { Helmet } from 'react-helmet'

const links = [{
  label: 'Home',
  to: Routes.HOME
}, {
  label: 'FAQs',
  to: Routes.LEARN_FAQ
}, {
  label: 'Contact',
  to: Routes.CONTACT
}]

const NotFound = (props) =>
  <div className={styles.component}>
    <div>
      <HeadTitle title='Page Not Found' />
      <Helmet>
        <meta name='prerender-status-code' content='404' />
      </Helmet>
      <div className={styles.title}><span>4</span><span>0</span><span>4</span></div>
      <div className={styles.heading}>Page Not Found</div>
      <div className={styles.subheading}>{`Try again, and if it still doesn't work, let us know.`}</div>
      <HelpfulLinks links={links} />
    </div>
  </div>
export default NotFound
