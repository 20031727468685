import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'grid-styled'
import styles from './QuoteInside.module.scss'
import { LabelTip } from '../LabelTip'
import Locale from '../Locale'
import { FaShieldAlt } from 'react-icons/fa'

const QuoteInside = ({ quote = {}, profile = {} }) => {
  return <Flex wrap className={styles.quoteInfo}>
    <Box w={[1 / 2, 1 / 4, 1 / 4]} className={styles.quoteInfoItem}>
      <label>Open</label>
      <p><Locale type='price'>{quote.openingPrice}</Locale></p>
    </Box>
    <Box w={[1 / 2, 1 / 4, 1 / 4]} className={styles.quoteInfoItem}>
      <label>Daily Range</label>
      {quote.dailyLow && quote.dailyHigh
        ? <p>
        <Locale type='price'>{quote.dailyLow}</Locale>&nbsp;-&nbsp;
        <Locale type='price'>{quote.dailyHigh}</Locale>
      </p>
        : <p>N/A</p>}
    </Box>
    <Box w={[1 / 2, 1 / 4, 1 / 4]} className={styles.quoteInfoItem}>
      <label>Volume</label>
      <p><Locale type='int'>{quote.volume}</Locale></p>
    </Box>
    <Box w={[1 / 2, 1 / 4, 1 / 4]} className={styles.quoteInfoItem}>
      <LabelTip
        title='Market Cap'
        tipText='Market Cap calculated only for this class of securities.'
        width='350px'
        icon='/icons/sidebar/search-dollar-solid.svg'
      />
      <p><Locale type='int'>{profile.estimatedMarketCap}</Locale></p>
    </Box>
    <Box w={[1 / 2, 1 / 4, 1 / 4]} className={styles.quoteInfoItem}>
      <label>Close</label>
      <p><Locale type='price'>{quote.previousClose}</Locale></p>
    </Box>
    <Box w={[1 / 2, 1 / 4, 1 / 4]} className={styles.quoteInfoItem}>
      <label>52WK Range</label>
      {quote.annualLow && quote.annualHigh
        ? <p>
        <Locale type='price'>{quote.annualLow}</Locale>&nbsp;-&nbsp;
        <Locale type='price'>{quote.annualHigh}</Locale>
      </p>
        : <p>N/A</p>}
    </Box>
    <Box w={[1 / 2, 1 / 4, 1 / 4]} className={styles.quoteInfoItem}>
      <label>Average Vol (30D)</label>
      <p><Locale type='int'>{quote.thirtyDaysAvgVol}</Locale></p>
    </Box>
    <Box w={[1 / 2, 1 / 4, 1 / 4]} className={styles.quoteInfoItem}>
      <label>Shares Out {profile.currentSecurity && profile.currentSecurity.showTrustedLogoForOutstandingShares && <FaShieldAlt />}</label>
      <p><Locale type='int'>{profile.currentSecurity && profile.currentSecurity.outstandingShares}</Locale></p>
    </Box>
  </Flex>
}

QuoteInside.propTypes = {
  quote: PropTypes.object,
  profile: PropTypes.object
}

export default QuoteInside
