import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const HeadTitle = props =>
  <Helmet>
    <title>{props.title} | OTC Markets</title>
  </Helmet>

HeadTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default HeadTitle
