import React from 'react'
import { getDates, getList } from '../../api/otc/market-activity/reg-sho'
import { template, fetch, expand, sort } from '../../api/helper'

import HeadTitle from '../../components/HeadTitle'
import styles from './MARegSHOPage.module.scss'
import Flexgrid from '../../components/Flexgrid'
import Outline from '../../components/Outline'
import Table from '../../components/Table'
import More from '../../components/More'
import TableFooter from '../../components/TableFooter'
import DisplayResults from '../../components/DisplayResults'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import ExternalLink from '../../components/ExternalLink'

/** Headers for Table */
const columns = [
  {
    name: 'positionDate',
    header: 'Date',
    type: 'date',
    sortingEnabled: true,
    hideOnMobile: true
  },
  {
    name: 'symbol',
    header: 'Symbol',
    headerShort: 'Sym',
    type: 'symbol',
    sortingEnabled: true
  },
  {
    name: 'securityName',
    header: 'Security Name',
    sortingEnabled: true
  },
  {
    name: 'tierName',
    header: 'Market',
    sortingEnabled: true,
    hideOnMobile: true
  },
  {
    name: 'regShoFlag',
    header: 'Reg SHO Threshold Flag',
    type: 'bool'
  },
  {
    name: 'isRule4320',
    header: 'Rule 4320 Flag',
    type: 'bool'
  }
]

class MARegSHOPage extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      /** This is to show and hide the filters on mobile layout */
      showFilters: false,
      /** Store the position date which is meant to be the ID (epoch time) */
      positionDate: '',
      table: template('records', 'positionDate', 'desc'),
      /** These are the filters that will show up on the table */
      filters: [
        {
          type: 'select',
          title: 'Dates',
          options: []
        }
      ]
    }
  }

  /**
   * Get the dates to filter on.
   * Set the first date as the default filter date.
   * Get the data for that date.
   */
  componentDidMount () {
    getDates()
      .then(dates => {
        if (Array.isArray(dates) === false) {
          return null
        }

        this.setState({
          ...this.state,
          dates,
          positionDate: dates[0].id,
          filters: [
            {
              type: 'select', // This might eventually be a date picker, based on what they want.
              title: 'Dates',
              /**
               * On the Short Sale endpoint for dates, the structure comes
               * back as { id, caption }, but REG SHO returns { id, value }, not sure why.
               * I believe OTC want's to query on the epoch time of a date.
               */
              options: dates.map(d => {
                d.title = d.value
                d.value = d.id
                return d
              }),
              onSelect: this.onSelectData
            }
          ]
        }, () => {
          /** Get the data for the table */
          this.loadList()
        })
      })
  }

  /**
   * When the user selects an item in the date dropdown.
   * Updates the positionDate in local state and calls the API again to get the new data.
   * @param {string} key - The epoch time to query on.
   * @param {string} item - The human readable date.
   */
  onSelectData = (key, item) => {
    this.setState({
      ...this.state,
      positionDate: key || this.state.dates[0].value
    }, () => {
      this.loadList()
    })
  }

  /**
   * Load the data from a specific date at a specific page.
   */
  loadList = () => {
    const { positionDate } = this.state

    fetch(this, getList, 'table', {
      positionDate,
      page: 1,
      pageSize: 25
    })
  }

  render () {
    const layouts = {
      sm: [
        {
          type: 'flex',
          column: true,
          subtree: ['info', 'table']
        }
      ],
      md: [
        {
          type: 'flex',
          column: true,
          subtree: ['info', 'table', 'footer-note']
        }
      ],
      lg: [
        {
          type: 'flex',
          subtree: [
            {
              type: 'side',
              subtree: ['info']
            },
            {
              type: 'main',
              subtree: ['table', 'footer-note']
            }
          ]
        }
      ]
    }

    return (
      <div className={styles.MACurrentMarketPage}>
        <HeadTitle title='Regulation Sho Threshold Securities List' />
        <PageTitle>Reg SHO Data</PageTitle>
        <Flexgrid layouts={layouts}>
          <div key='info' className={styles.info}>
            <p>
              A security will be placed on the threshold list
              if it has a significant fail to deliver position for
              at least 5 business days. The threshold
              qualifications are different for SEC reporting
              issuers and non-SEC reporting issuers
              (see definitions). Reporting companies are
              governed by <ExternalLink href='https://www.sec.gov/rules/final/34-50103.htm'>Reg SHO</ExternalLink> and
              non-reporting companies are governed
              by <ExternalLink href='http://finra.complinet.com/en/display/display.html?rbid=2403&element_id=9398'>Rule 4320</ExternalLink>.
            </p>
          </div>
          <div key='table'>
            <Outline mode='heading' hideFilterToggle filters={this.state.filters}>Threshold Security List<sup>1</sup></Outline>
            <Loading
              type='table'
              loaded={this.state.table.loaded}
              reloading={this.state.table.reloading}
              error={this.state.table.error}
              data={this.state.table.data.records}>
              <Table
                columns={columns}
                data={this.state.table.data.records}
                sortColumn={this.state.table.sortOn}
                sortDirection={this.state.table.sortDir}
                onSorting={sort(this, getList, 'table')}
                showHeader
              />
              <TableFooter>
                <More onClick={expand(this, getList, 'table')}
                  disabled={this.state.table.expandEmpty} />
                {this.state.table.data.records && this.state.table.data.totalRecords &&
                  <DisplayResults show={this.state.table.data.records.length} total={this.state.table.data.totalRecords} text={'Securities'} />
                }
              </TableFooter>
            </Loading>
          </div>
          <div key='footer-note' className={styles.footerNote}>
            <sup>1</sup>
            A New Threshold Security List is published on each settlement day
          </div>
        </Flexgrid>
      </div>
    )
  }
}

export default MARegSHOPage
