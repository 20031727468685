import _ from 'lodash'

export function request (params) {
  let data = [
    {
      country: 'Argentina',
      name: 'Buenos Aires Stock Exchange',
      tier: 'Lorem Ipsum'
    },
    {
      country: 'Argentina',
      name: 'Buenos Aires Stock Exchange',
      tier: 'Lorem Ipsum'
    },
    {
      country: 'Argentina',
      name: 'Buenos Aires Stock Exchange',
      tier: 'Lorem Ipsum'
    },
    {
      country: 'Argentina',
      name: 'Buenos Aires Stock Exchange',
      tier: 'Lorem Ipsum'
    },
    {
      country: 'Argentina',
      name: 'Buenos Aires Stock Exchange',
      tier: 'Lorem Ipsum'
    }
  ]

  const length = data.length

  // Sort On
  if (params.sortOn) {
    data = _.orderBy(data, params.sortOn, params.sortDir || 'desc')
  }

  // Handle Page
  let page = 1
  if (params.page) page = params.page
  // Page Size
  if (params.pageSize) {
    data = data.slice(page === 1 ? 0 : (page - 1) * params.pageSize,
      page === 1 ? params.pageSize : ((page - 1) * params.pageSize) + params.pageSize)
  }

  return {
    totalRecords: length,
    pages: Math.ceil(length / params.pageSize),
    pageSize: params.pageSize,
    qualifiedForeignExchanges: data,
    sortOn: params.sortOn,
    isRealTime: false,
    bySymbol: true
  }
}
