import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import WebFont from 'webfontloader'

import 'normalize.css/normalize.css'

// load font
WebFont.load({
  google: {
    families: [
      'Nunito Sans:200,200i,400,400i,600,600i,700,700i,900,900i',
      'Source Serif Pro:400,700'
    ]
  }
})

// set version number in html element
const version = process.env.REACT_APP_VERSION;
const el = document.querySelector('html');
el.setAttribute('data-ui-version', version);

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

root.render(<App />)
