import React from 'react'
import PropTypes from 'prop-types'
import styles from './PageTitle.module.scss'
import classNames from 'classnames'

const PageTitle = ({ children, accent }) => {
  // if an unsupported color is specified, default to green
  const accentColor = !accent || ['green', 'pink', 'orange'].includes(accent) ? accent : 'green'
  // try to fit longer text on two lines
  const length = children && typeof children === 'string' && children.length
  const width = length && ((length / 2) * 0.65)
  return <div style={{ width: width && (width + 'em') }} className={classNames(styles.pageTitle, styles['color-' + accentColor])}>{children}</div>
}

PageTitle.propTypes = {
  accent: PropTypes.string,
  children: PropTypes.node
}

export default PageTitle
