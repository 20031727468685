import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import queryString from 'query-string'
import classNames from 'classnames'
import getConfig from '../../config';
import { withRouter } from '../WithRouter'
import { Flex, Box } from 'grid-styled'
import styles from './CompanyQuoteNews.module.scss'
import { Link } from 'react-router-dom'
import VideoPlayer from '../VideoPlayer'
import Video from '../Video'
import {
  FaAngleLeft,
  FaAngleRight,
  FaFacebook
} from 'react-icons/fa'
import FaTwitter from '../FaTwitter'
import ExternalLink from '../ExternalLink'
import { logPageview } from '../../analytics'

import {
  getDNSNews,
  getDNSNewsStory,
  getEdgarNews,
  getDNSNewsContent,
  getEdgarNewsContent
} from '../../api/otc/company/news'
import {
  getMedia,
  getMediaDetails
} from '../../api/otc/company/media'
import api from '../../api/api'
import { template, fetch, expand } from '../../api/helper'
import { format } from '../../utils/locale'
import {
  getDNSNewsUrl,
  getExternalNewsUrl,
  getVideoUrl
} from '../../utils/news-urls'

import Outline from '../Outline'
import Loading from '../Loading'
import More from '../More'
import TableFooter from '../TableFooter'
import DisplayResults from '../DisplayResults'
import Locale from '../Locale'
import Accordion from '../Accordion'

const { OTC_HOSTNAME_URL } = getConfig()
const hostname = OTC_HOSTNAME_URL
class CompanyQuoteNews extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      dnsNews: template('records'),
      dnsNewsContent: template('text'),
      edgarNews: template('records'),
      edgarNewsContent: template('text'),
      newsStory: template('object'),
      nextNewsStory: template('object'),
      videos: template('records'),
      presentations: template('records'),
      video: template('object'),
      currentContent: '',
      iFrameHeight: 100,
      filters: {
        dns: {
          fromDate: null,
          toDate: null
        },
        presentations: {
          fromDate: null,
          toDate: null
        },
        edgar: {
          fromDate: null,
          toDate: null
        }
      }
    }
  }

  componentDidMount () {
    this.loadContent()
  }

  componentDidUpdate (prevProps) {
    const contentId = this.getContentId(this.props)
    const prevContentId = this.getContentId(prevProps)

    if (contentId !== prevContentId) {
      this.loadContent()
    }
  }

  loadContent = () => {
    const { company } = this.props
    const contentId = this.getContentId(this.props)
    const companyId = this.props.info.id

    this.setState(state => ({
      newsStory: template('object'),
      dnsNewsContent: template('text'),
      edgarNewsContent: template('text'),
      video: template('object'),
      presentations: template('records'),
      currentContent: '',
      isEdgar: false,
      isVideo: false
    }))

    if (!contentId) {
      const title = `OTC Markets | ${this.props.company} News`
      logPageview(title)

      fetch(this, getDNSNews, 'dnsNews', {
        symbol: company,
        page: 1,
        pageSize: 10,
        sortOn: 'releaseDate',
        sortDir: 'DESC',
        fromDate: this.state.filters.dns.fromDate,
        toDate: this.state.filters.dns.toDate
      }, data => {
        data.records = this.formatDNSNews(data.records)
        return data
      })

      fetch(this, getMedia, 'videos', {
        compId: companyId,
        type: 'VIDEO',
        page: 1,
        pageSize: 4
      }, data => {
        data.records = data.results
        return data
      })

      fetch(this, getMedia, 'presentations', {
        compId: companyId,
        page: 1,
        pageSize: 10,
        sortOn: 'publishedTimestamp',
        sortDir: 'DESC',
        type: 'DOC',
        fromDate: this.state.filters.presentations.fromDate,
        toDate: this.state.filters.presentations.toDate
      }, data => {
        data.records = this.formatPresentations(data.results)
        return data
      })

      fetch(this, getEdgarNews, 'edgarNews', {
        symbol: company,
        page: 1,
        pageSize: 10,
        sortOn: 1,
        sortDir: 'D',
        fromDate: this.state.filters.edgar.fromDate,
        toDate: this.state.filters.edgar.toDate
      }, data => {
        data.records = this.formatEdgarNews(data.records)
        return data
      })
    // load video
    } else if (this.isVideo(this.props)) {
      this.loadVideo(contentId)
    // load Edgar story
    } else if (this.isEdgarNewsStory(this.props)) {
      this.expandEdgarStory({ id: contentId })
    // load DNS story
    } else {
      fetch(this, getDNSNewsStory, 'newsStory', {
        newsId: contentId
      }, data => {
        const title = `OTC Markets | ${this.props.company} News | ${data.title}`
        logPageview(title)

        return data
      })

      this.expandDNSStory({ id: contentId })
    }
  }

  formatPresentations = (presentations) => {
    if (!presentations) return []
    console.log('presentations, presentations', presentations);
    return presentations.map(presentationsItem => {
      const mediaUrl = presentationsItem.mediaUrl
      const parts = mediaUrl.split('/media')
      const toUrl = `${hostname}/media` + parts[1]
      return {
        title: `${presentationsItem.title}`,
        subtitle: format(presentationsItem.publishedTimestamp, 'N/A', 'date'),
        id: presentationsItem.id,
        to: toUrl
      }
    })
  }

  formatDNSNews = (news) => {
    if (!news) return []
    return news.map(newsItem => {
      return {
        title: `${newsItem.title}`,
        subtitle: format(newsItem.newsTypeDescript, 'N/A', 'text') +
          ' | ' + format(newsItem.releaseDate, 'N/A', 'date'),
        id: newsItem.id,
        to: getDNSNewsUrl(this.props.company, newsItem)
      }
    })
  }

  formatEdgarNews = (news) => {
    if (!news) return []
    return news.map(newsItem => {
      const url = getExternalNewsUrl(this.props.company, newsItem)
      return {
        title: newsItem.headline,
        subtitle: format(newsItem.sourceName, '', 'text') +
          (newsItem.sourceName && newsItem.pubDate ? ' | ' : '') +
          format(newsItem.pubDate, '', 'date'),
        id: newsItem.id,
        to: newsItem.id ? url : newsItem.link
      }
    })
  }

  loadVideo = (id) => {
    this.setState({ isVideo: true, isEdgar: false })
    fetch(this, getMediaDetails, 'video', { id }, data => {
      const title = `OTC Markets | ${this.props.company} | ${data.title}`
      logPageview(title)

      return data
    })
  }

  expandDNSStory = (story) => {
    this.setState({ isEdgar: false, isVideo: false })
    fetch(this, getDNSNewsContent, 'dnsNewsContent', {
      newsId: story.id
    }, data => {
      if (!data) data = ''

      const dnsNews = this.state.dnsNews.data

      dnsNews.records = dnsNews.records.map(record => {
        if (record.id === story.id) {
          record.data.content = data
        }
        return record
      })

      this.setState(state => ({
        currentContent: data
      }))

      return data
    })
  }

  expandEdgarStory = (story) => {
    this.setState({ isEdgar: true, isVideo: false })
    fetch(this, getEdgarNewsContent, 'edgarNewsContent', {
      newsId: story.id
    }, data => {
      if (!data) data = ''

      const edgarNews = this.state.edgarNews.data

      edgarNews.records = edgarNews.records.map(record => {
        if (record.id === story.id) {
          record.data.content = data
        }
        return record
      })

      this.setState(state => ({
        currentContent: data
      }))

      let articleTitle = data && data.match(/<title>(.*?)<\/title>/gi)
      articleTitle = articleTitle && articleTitle.length > 0 ? articleTitle[0].substring(7, articleTitle[0].length - 8) : 'Article'
      const title = `OTC Markets | ${this.props.company} News | ${articleTitle}`
      logPageview(title)

      return data
    })
  }

  filterSelect = (type, field) => {
    return (date) => {
      const filters = this.state.filters

      if (type && field) {
        filters[type][field] = date && moment(date).format('MM/DD/YYYY')
      }

      this.setState({ filters }, this.loadContent)
    }
  }

  getContentId = (props) => {
    const { location } = props
    const queryParams = queryString.parse(location.search)
    let contentId = null

    if (queryParams && queryParams.id) {
      contentId = queryParams.id
    }

    return contentId
  }

  isEdgarNewsStory = (props) => {
    const { location } = props
    const queryParams = queryString.parse(location.search)
    return queryParams && queryParams.e !== undefined
  }

  isVideo = (props) => {
    const { location } = props
    const queryParams = queryString.parse(location.search)
    return queryParams && queryParams.v !== undefined
  }
  
  render () {
    const { company, accent, info } = this.props
    const contentId = this.getContentId(this.props)

    // full page URLs for social sharing
    let currentLink = null
    if (this.state.isVideo) currentLink = getVideoUrl(company, this.state.video.data, true)
    else if (this.state.isEdgar) currentLink = getExternalNewsUrl(company, { id: contentId }, true)
    else if (contentId) currentLink = getDNSNewsUrl(company, this.state.newsStory.data, true)

    // safe document lists parameter for error reporting and document list display
    let newsStoryDocumentList = null
    if (this.state.newsStory.data && this.state.newsStory.data.documentList) {
      newsStoryDocumentList = this.state.newsStory.data.documentList
    }

    return <div className={styles.CompanyQuoteNews}>
      {!contentId && <div>
        <div>
          <Outline mode='heading' accent={accent} filters={[{
            type: 'date',
            placeholderText: 'From',
            maxDate: moment(this.state.filters.dns.toDate) || moment(),
            onChange: this.filterSelect('dns', 'fromDate')
          }, {
            type: 'date',
            placeholderText: 'To',
            minDate: moment(this.state.filters.dns.fromDate),
            maxDate: moment(),
            onChange: this.filterSelect('dns', 'toDate')
          }]}>
            OTC Disclosure & News Service
          </Outline>
          <Loading
            data={this.state.dnsNews.data.records}
            error={this.state.dnsNews.error}
            loaded={this.state.dnsNews.loaded}
            reloading={this.state.dnsNews.reloading}>
            <Accordion
              closed
              accent={accent}
              columnSplit={3 / 4}
              list={this.state.dnsNews.data.records} />
            <TableFooter>
              <More onClick={expand(this, getDNSNews, 'dnsNews')}
                disabled={this.state.dnsNews.expandEmpty} />
              {this.state.dnsNews.data.records && this.state.dnsNews.data.totalRecords &&
                <DisplayResults show={this.state.dnsNews.data.records.length} total={this.state.dnsNews.data.totalRecords} text={'Press Releases'} />
              }
            </TableFooter>
          </Loading>
        </div>

        <Outline mode='invisible' spacing='larger' />
        <div>
          <Outline mode='heading' accent={accent}>
            News
          </Outline>
          <Loading
            data={this.state.edgarNews.data.records}
            error={this.state.edgarNews.error}
            loaded={this.state.edgarNews.loaded}
            reloading={this.state.edgarNews.reloading}>
            <Accordion
              closed
              accent={accent}
              list={this.state.edgarNews.data.records} />
            <TableFooter>
              <More onClick={expand(this, getEdgarNews, 'edgarNews')}
                disabled={this.state.edgarNews.expandEmpty} />
              {this.state.edgarNews.data.records && this.state.edgarNews.data.totalRecords &&
                <DisplayResults show={this.state.edgarNews.data.records.length} total={this.state.edgarNews.data.totalRecords} text={'News Listings'} />
              }
            </TableFooter>
          </Loading>
        </div>

        <Outline mode='invisible' spacing='larger' />
        {info.isOTC && <div className={styles.videos}>
          <Outline mode='heading' accent={accent}>Videos</Outline>
          <Loading
            type='table'
            emptyMessage='This company has not added any videos'
            loaded={this.state.videos.loaded}
            reloading={this.state.videos.reloading}
            error={this.state.videos.error}
            data={this.state.videos.data.records}>
            <Flex wrap align='center' className={styles.videoContainer}>
              {this.state.videos.data.records.map((video, videoIndex) => <Box
                w={[1, 1 / 2, 1 / 2]}
                key={`company-video-${videoIndex}`}
                className={styles.video}>
                <Video video={{ ...video, symbol: this.props.company }} />
              </Box>)}
            </Flex>
            <TableFooter>
              <More
                onClick={expand(this, getMedia, 'videos')}
                disabled={this.state.videos.expandEmpty} />
              {this.state.videos.data.records && this.state.videos.data.totalResults &&
                <DisplayResults show={this.state.videos.data.records.length} total={this.state.videos.data.totalResults} text={'Videos'} />
              }
            </TableFooter>
          </Loading>
        </div>}

        {info.isOTC && <div>
          <Outline mode='heading' accent={accent}>
            Presentations
          </Outline>
          <Loading
            data={this.state.presentations.data.records}
            emptyMessage='This company has not added any presentations'
            error={this.state.presentations.error}
            loaded={this.state.presentations.loaded}
            reloading={this.state.presentations.reloading}>
            <Accordion
              closed
              accent={accent}
              list={this.state.presentations.data.records} />
            <TableFooter>
              <More onClick={expand(this, getMedia, 'presentations')}
                disabled={this.state.presentations.expandEmpty} />
              {this.state.presentations.data.records && this.state.presentations.data.totalResults &&
                <DisplayResults show={this.state.presentations.data.records.length} total={this.state.presentations.data.totalResults} text={'Presentations'} />
              }
            </TableFooter>
          </Loading>
        </div>}
      </div>}
      {/* Video player section */}
      {contentId && this.state.isVideo && <div>
        <Outline mode='heading' accent={accent}>Videos</Outline>
        <Loading
          height='20em'
          error={this.state.video.error}
          loaded={this.state.video.loaded}>
          <div className={styles.storyHeading}>
            <div>
              {this.state.video.data.title}
              <span className={styles.storyInfo}>
                <Locale type='date'>{this.state.video.data.publishedTimestamp}</Locale>
              </span>
            </div>

            <div className={styles.social}>
              <ExternalLink
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentLink)}`} className={styles[accent]}>
                <FaFacebook />
              </ExternalLink>
              <ExternalLink
                href={`https://twitter.com/share?url=${encodeURIComponent(currentLink)}&via=OTCMarkets`} className={styles[accent]}>
                <FaTwitter />
              </ExternalLink>
            </div>
          </div>
          {this.state.video.data.mediaUrl && <VideoPlayer url={this.state.video.data.mediaUrl} />}
          {this.state.video.data.description && <div className={styles.videoDescription}>
            {this.state.video.data.description}
          </div>}
        </Loading>
        <Flex className={styles.storyLinks}>
          <Box flex='1 1 auto'>
            <ExternalLink to={`/stock/${company}/news`} className={classNames(styles.linkTo, styles[accent])}>
              <FaAngleLeft />Back to News Headlines
            </ExternalLink>
          </Box>
        </Flex>
      </div>}
      {/* News story section */}
      {contentId && !this.state.isVideo && <div>
        <div>
          <Outline mode='heading' accent={accent}>{this.state.isEdgar ? 'News' : 'OTC Disclosure & News Service'}</Outline>
          <Loading
            height='20em'
            emptyMessage='Article not available'
            error={this.state.newsStory.error || (this.state.dnsNewsContent.error && !newsStoryDocumentList)}
            loaded={this.state.isEdgar ? this.state.edgarNewsContent.loaded : this.state.newsStory.loaded && this.state.dnsNewsContent.complete}>
            {!this.state.isEdgar && <div className={styles.storyHeading}>
              {this.state.newsStory.data.title}
              <span className={styles.storyInfo}>
                {this.state.newsStory.data.newsTypeDescript || 'N/A'} | <Locale type='date'>
                  {this.state.newsStory.data.releaseDate}
                </Locale>
              </span>
            </div>}
            {newsStoryDocumentList && this.state.newsStory.data.releaseDate && <div>
              <b>{moment(this.state.newsStory.data.releaseDate).tz('America/New_York').format('MMMM D, YYYY')}</b><br />
              OTC Disclosure & News Service
            </div>}
            {newsStoryDocumentList && this.state.newsStory.data.location && <div>
              <br />
              {this.state.newsStory.data.location} —
              <br /><br />
            </div>}
            {newsStoryDocumentList && <div>
              This release includes additional documents. Select the link(s) below to view.<br />
              {newsStoryDocumentList.map((item, i) => <div key={i}>
                <ExternalLink to={api.link({ otcAPI: true, url: `/backend-content${item.url}` })}>{item.description}</ExternalLink>
              </div>)}
            </div>}
            <div
              className={classNames(styles.largeContent, styles[accent])}
              dangerouslySetInnerHTML={{ __html: this.state.currentContent }} />
            <div className={styles.social}>
              <ExternalLink
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentLink)}`} className={styles[accent]}>
                <FaFacebook />
              </ExternalLink>
              <ExternalLink
                href={`https://twitter.com/share?url=${encodeURIComponent(currentLink)}&via=OTCMarkets`} className={styles[accent]}>
                <FaTwitter />
              </ExternalLink>
            </div>
          </Loading>
        </div>
        <Flex className={styles.storyLinks}>
          <Box flex='1 1 auto'>
            <Link to={`/stock/${company}/news`} className={classNames(styles.linkTo, styles[accent])}>
              <FaAngleLeft />Back to News Headlines
            </Link>
          </Box>
        </Flex>
        <Outline mode='invisible' spacing='larger'>
          <Flex wrap>
            <Box w={1}>
              <Outline mode='heading3' accent={accent}>
                Other Financial Information
              </Outline>
            </Box>
            <Box w={1}>
              <Link to={`/stock/${company}/news`} className={styles.linkTo}>
                <div className={styles.link}>
                  Recent News & Disclosure Filings
                  <FaAngleRight className={styles.linkAngle} />
                </div>
              </Link>
            </Box>
            <Box w={1}>
              <Link to={`/stock/${company}/disclosure`} className={styles.linkTo}>
                <div className={styles.link}>
                  Recent SEC Filings
                  <FaAngleRight className={styles.linkAngle} />
                </div>
              </Link>
            </Box>
          </Flex>
        </Outline>
      </div>}
    </div>
  }
}

CompanyQuoteNews.propTypes = {
  info: PropTypes.object,
  location: PropTypes.object,
  company: PropTypes.string.isRequired,
  accent: PropTypes.string
}

export default withRouter(CompanyQuoteNews)
