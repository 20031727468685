export function request (params) {
  return {
    totalRecords: 1398,
    pages: 70,
    currentPage: 1,
    pageSize: 20,
    sortOn: 'changeDate',
    sortDir: 'DESC',
    records: [
      {
        symbol: 'MSSNF',
        companyName: 'Mason Res Corp',
        securityTypeId: 'OS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 287817,
        changeDate: 1507608000000,
        issueName: 'MASON RES CORP ORD',
        companyId: 685289,
        className: 'Ordinary Shares',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507650515000,
        newSymbol: 'MSSNF',
        oldSymbol: 'MSSNF',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'TXMD',
        companyName: 'TherapeuticsMD, Inc.',
        securityTypeId: 'CS',
        tierCode: 'NT',
        isCaveatEmptor: false,
        securityId: 154967,
        changeDate: 1507521600000,
        issueName: 'THERAPEUTICSMD INC',
        companyId: 638410,
        className: 'Common Stock',
        tierName: 'No Tier',
        actionType: 'Venue Change',
        changeTime: 1507551738000,
        newSymbol: 'TXMD',
        oldSymbol: 'TXMD',
        oldVenueId: 'AX',
        oldVenueName: 'NYSE Amex',
        newVenueId: 'NQ',
        newVenueName: 'Nasdaq'
      },
      {
        symbol: 'STVA',
        companyName: 'Stevva Corp.',
        securityTypeId: 'CS',
        tierCode: 'GM',
        isCaveatEmptor: true,
        securityId: 213763,
        changeDate: 1507521600000,
        issueName: 'STEVVA CORP',
        companyId: 680549,
        className: 'Common Stock',
        tierName: 'Grey Market',
        actionType: 'Venue Change',
        changeTime: 1507582226000,
        newSymbol: 'STVA',
        oldSymbol: 'STVA',
        oldVenueId: 'PS',
        oldVenueName: 'OTC Link',
        newVenueId: 'GM',
        newVenueName: 'Grey Market'
      },
      {
        symbol: 'HMLP.PRA',
        companyName: 'Hoegh LNG Partners LP',
        securityTypeId: 'PS',
        tierCode: 'NT',
        isCaveatEmptor: false,
        securityId: 297151,
        changeDate: 1507521600000,
        issueName: 'HOEGH LNG PART 8.75 SER A',
        companyId: 683934,
        className: 'Preferred Stock',
        tierName: 'No Tier',
        actionType: 'Venue Change',
        changeTime: 1507549973000,
        newSymbol: 'HMLP.PRA',
        oldSymbol: 'HMLP.PRA',
        oldVenueId: 'PS',
        oldVenueName: 'OTC Link',
        newVenueId: 'NY',
        newVenueName: 'NYSE'
      },
      {
        symbol: 'PNNEF',
        companyName: 'PENNINE PETROLEUM CORP.',
        securityTypeId: 'OS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 120021,
        changeDate: 1507262400000,
        issueName: 'PENNINE PETROLEUM CORP',
        companyId: 669283,
        className: 'Ordinary Shares',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507288380000,
        newSymbol: 'PNNEF',
        oldSymbol: 'PNNEF',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'FDBC',
        companyName: 'Fidelity D&D Bancorp, Inc.',
        securityTypeId: 'CS',
        tierCode: 'NT',
        isCaveatEmptor: false,
        securityId: 84134,
        changeDate: 1507262400000,
        issueName: 'FIDELITY D&D BANCORP INC',
        companyId: 636647,
        className: 'Common Stock',
        tierName: 'No Tier',
        actionType: 'Venue Change',
        changeTime: 1507296147000,
        newSymbol: 'FDBC',
        oldSymbol: 'FDBC',
        oldVenueId: 'PS',
        oldVenueName: 'OTC Link',
        newVenueId: 'NQ',
        newVenueName: 'Nasdaq'
      },
      {
        symbol: 'DGLT',
        companyName: 'Digiliti Money Group, Inc.',
        securityTypeId: 'CS',
        tierCode: 'GM',
        isCaveatEmptor: false,
        securityId: 284509,
        changeDate: 1507262400000,
        issueName: 'DIGILITI MONEY GROUP INC',
        companyId: 682199,
        className: 'Common Stock',
        tierName: 'Grey Market',
        actionType: 'Venue Change',
        changeTime: 1507266013000,
        newSymbol: 'DGLT',
        oldSymbol: 'DGLT',
        oldVenueId: 'NQ',
        oldVenueName: 'Nasdaq',
        newVenueId: 'GM',
        newVenueName: 'Grey Market'
      },
      {
        symbol: 'BSTG',
        companyName: 'Biostage, Inc.',
        securityTypeId: 'CS',
        tierCode: 'QB',
        isCaveatEmptor: false,
        securityId: 225786,
        changeDate: 1507262400000,
        issueName: 'BIOSTAGE INC',
        companyId: 681285,
        className: 'Common Stock',
        tierName: 'OTCQB',
        actionType: 'Venue Change',
        changeTime: 1507266227000,
        newSymbol: 'BSTG',
        oldSymbol: 'BSTG',
        oldVenueId: 'NQ',
        oldVenueName: 'Nasdaq',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'ZNKKY',
        companyName: 'Zenkoku Hosho Co Ltd',
        securityTypeId: 'ADR',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 287210,
        changeDate: 1507176000000,
        issueName: 'ZENKOKU HOSHO UNSP/ADR',
        companyId: 683661,
        className: 'American Depository Receipts',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507203896000,
        newSymbol: 'ZNKKY',
        oldSymbol: 'ZNKKY',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'URZZF',
        companyName: 'Urz Energy Corp',
        securityTypeId: 'OS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 292979,
        changeDate: 1507176000000,
        issueName: 'URZ ENERGY CORP ORD',
        companyId: 685527,
        className: 'Ordinary Shares',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507224431000,
        newSymbol: 'URZZF',
        oldSymbol: 'URZZF',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'TDW.WSB',
        companyName: 'Tidewater Inc. New',
        securityTypeId: 'WTS',
        tierCode: 'NT',
        isCaveatEmptor: false,
        securityId: 293144,
        changeDate: 1507176000000,
        issueName: 'TIDEWATER INC WTS SER B',
        companyId: 685528,
        className: 'Warrants',
        tierName: 'No Tier',
        actionType: 'Venue Change',
        changeTime: 1507200803000,
        newSymbol: 'TDW.WSB',
        oldSymbol: 'TDW.WSB',
        oldVenueId: 'PS',
        oldVenueName: 'OTC Link',
        newVenueId: 'NY',
        newVenueName: 'NYSE'
      },
      {
        symbol: 'TDW.WSA',
        companyName: 'Tidewater Inc. New',
        securityTypeId: 'WTS',
        tierCode: 'NT',
        isCaveatEmptor: false,
        securityId: 293143,
        changeDate: 1507176000000,
        issueName: 'TIDEWATER INC WTS SER A',
        companyId: 685528,
        className: 'Warrants',
        tierName: 'No Tier',
        actionType: 'Venue Change',
        changeTime: 1507200692000,
        newSymbol: 'TDW.WSA',
        oldSymbol: 'TDW.WSA',
        oldVenueId: 'PS',
        oldVenueName: 'OTC Link',
        newVenueId: 'NY',
        newVenueName: 'NYSE'
      },
      {
        symbol: 'SSBBW',
        companyName: 'SilverBow Resources, Inc.',
        securityTypeId: 'WTS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 294995,
        changeDate: 1507176000000,
        issueName: 'SILVERBOW RES WTS 4/22/20',
        companyId: 684209,
        className: 'Warrants',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507235717000,
        newSymbol: 'SSBBW',
        oldSymbol: 'SSBBW',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'SPRYP',
        companyName: 'Spirit Realty Capital, Inc.',
        securityTypeId: 'PS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 296860,
        changeDate: 1507176000000,
        issueName: 'SPIRIT REALTY PFD SER A',
        companyId: 681001,
        className: 'Preferred Stock',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507235699000,
        newSymbol: 'SPRYP',
        oldSymbol: 'SPRYP',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'SLBBW',
        companyName: 'SilverBow Resources, Inc.',
        securityTypeId: 'WTS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 294989,
        changeDate: 1507176000000,
        issueName: 'SILVERBOW RES WTS 4/22/19',
        companyId: 684209,
        className: 'Warrants',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507235679000,
        newSymbol: 'SLBBW',
        oldSymbol: 'SLBBW',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'SDRWW',
        companyName: 'Sandridge Energy, Inc.',
        securityTypeId: 'WTS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 275333,
        changeDate: 1507176000000,
        issueName: 'SANDRIDGE ENERGY WTS A 22',
        companyId: 668604,
        className: 'Warrants',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507235662000,
        newSymbol: 'SDRWW',
        oldSymbol: 'SDRWW',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'SDRDW',
        companyName: 'Sandridge Energy, Inc.',
        securityTypeId: 'WTS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 275335,
        changeDate: 1507176000000,
        issueName: 'SANDRIDGE ENERGY WTS B 22',
        companyId: 668604,
        className: 'Warrants',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507235642000,
        newSymbol: 'SDRDW',
        oldSymbol: 'SDRDW',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'PRELF',
        companyName: 'HIVE Blockchain Technologies Ltd.',
        securityTypeId: 'OS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 131504,
        changeDate: 1507176000000,
        issueName: 'HIVE BLOCKCHAIN TECHS LTD',
        companyId: 630158,
        className: 'Ordinary Shares',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507204088000,
        newSymbol: 'PRELF',
        oldSymbol: 'PRELF',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'OGSRW',
        companyName: 'Overseas Shipholding Group, Inc.',
        securityTypeId: 'WTS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 241380,
        changeDate: 1507176000000,
        issueName: 'OVERSEAS SHIPHLDG 39A WTS',
        companyId: 682345,
        className: 'Warrants',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507235622000,
        newSymbol: 'OGSRW',
        oldSymbol: 'OGSRW',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      },
      {
        symbol: 'NSAFP',
        companyName: 'National Storage Affiliates Trust',
        securityTypeId: 'PS',
        tierCode: 'PC',
        isCaveatEmptor: false,
        securityId: 297353,
        changeDate: 1507176000000,
        issueName: 'NATIONAL STR PFD CUM A',
        companyId: 683958,
        className: 'Preferred Stock',
        tierName: 'OTC Pink Current',
        actionType: 'Venue Change',
        changeTime: 1507235565000,
        newSymbol: 'NSAFP',
        oldSymbol: 'NSAFP',
        oldVenueId: 'GM',
        oldVenueName: 'Grey Market',
        newVenueId: 'PS',
        newVenueName: 'OTC Link'
      }
    ],
    singular: 'Venue Change',
    plural: 'Venue Changes'
  }
}
