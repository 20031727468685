import React from 'react'
import PropTypes from 'prop-types'
import { withContext } from '../../AuthContext'
import { PasswordRequirements } from '../PasswordRequirements'
import { Button, Input, TextLink } from '@otcmarketsgroup/otcm-ui'
import styles from '../LogIn/LogIn.module.scss'

class ResetPassword extends React.PureComponent {
  constructor (props, context) {
    super(props, context)

    this.state = {
      resetCode: '',
      password: '',
      error: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleCodeChange = this.handleCodeChange.bind(this)
  }

  handleChange (field) {
    return (value) => {
      this.setState({ [field]: value })
    }
  }

  handleCodeChange (value) {
    this.setState({ resetCode: value })
  }

  render () {
    const { context } = this.props

    return <div className={styles.container}>
      <div className={styles.form}>
        <form onSubmit={(event) => context.submitResetPassword(this.state.resetCode, this.state.password, event)}>
          <div className={styles.greeting}>
            <h3 className={styles.formTitle}>Reset Password</h3>
            <p className={styles.formDesc}>
              We have sent a password reset code by {context.codeDeliveryMedium} to {context.codeDeliveryDestination}. Enter it below to reset your password.
            </p>
          </div>
          {/* <CodeInput type='number' fields={6} onChange={this.handleCodeChange} /> */}
          <Input id={'resetCode'}
            className={styles.input}
            title={'Reset Code'}
            required
            design='standard'
            onChange={this.handleChange('resetCode')}
            // error={this.state.errors[field.name]}
            type={'number'}
          />
          <Input id={'newPassword'}
            className={styles.input}
            title={'New Password'}
            required
            design='standard'
            onChange={this.handleChange('password')}
            // error={this.state.errors[field.name]}
            type={'password'}
          />
          {context.error && <i className={styles.error}>{context.error}</i>}
          <PasswordRequirements />
          <Button id={'forgotPwdSubmitLogIn'} inactive={context.disableButton} className={styles.submit} title='Submit' fullWidth type='submit' />
          <TextLink onClick={() => context.changeState('LOG_IN')}>Cancel</TextLink>
        </form>
      </div>
    </div>
  }
}

ResetPassword.propTypes = {
  context: PropTypes.shape({
    error: PropTypes.string,
    codeDeliveryMedium: PropTypes.string,
    codeDeliveryDestination: PropTypes.string,
    changeState: PropTypes.func,
    submitResetPassword: PropTypes.func,
    disableButton: PropTypes.bool
  })
}

export default withContext(ResetPassword)
