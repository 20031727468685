import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from './Row.module.scss'
import classNames from 'classnames'

const boxPadding = '.5em'
const paddingTop = '1em'

const Row = ({ id, items }) => {
  return <div id={id} style={{ display: 'grid', gridTemplateColumns: `repeat(${items.length}, 1fr)`, columnGap: '2em', paddingTop: `${items[0].padTop && paddingTop} ` }}>
    {items.map((item, i) => {
      const isLink = item.link || item.href

      return <div key={`${item.value}-${i}`} className={classNames(styles.row, {
        [styles.bold]: item.bold,
        [styles.boldBorder]: item.bold,
        [styles[item.accent]]: item.accent
      })} style={{ display: 'flex', justifyContent: 'space-between', padding: `${boxPadding} 0`, alignItems: 'flex-start' }}>
        <div className={styles.bold}>
          {item.title}
        </div>
        {isLink && (<>
          {item.link && <Link className={styles.value} to={item.link}>{item.value}</Link>}
          {item.href && <a className={styles.value} href={item.href} target={'_blank'}>{item.value}</a>}
        </>
        )}
        {!isLink && <div className={styles.value}>
          {item.value}
        </div>}
      </div>
    })}
  </div>
}

Row.propTypes = {
  id: PropTypes.string,
  items: PropTypes.array.isRequired
}

export default Row
