import api from '../../api'
import getConfig from '../../../config'
import { getToken } from '../../../utils/session'
const { REACT_APP_API_BASE_URI } = getConfig()

export async function getCommissions () {
  const response = await api.request({
    method: 'get',
    url: '/state-security-commissions'
  })
  return response.data
}

export async function downloadList (list) {
  let jwtToken
  const url = '/state-security-commissions/download'

  await getToken()
    .then(res => {
      jwtToken = res
    })
    .catch(e => console.log('no session', e))

  window.open(`${REACT_APP_API_BASE_URI}${url}?file=State Securities Commissions&token=${jwtToken}`)
}
