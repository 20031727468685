import api from '../../api'

export async function getShortSaleData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    mockData: 'stock/short-sales/short-sale-data',
    url: `/internal/stock/short-sale/${params.symbol}`,
    params
  })
  return response.data
}

export async function getShortSaleDates () {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'array',
    mockData: 'stock/short-sales/short-sale-dates',
    url: '/internal/stock/short-sale/dates',
    params: {
    }
  })
  return response.data
}

export async function getShortSaleDataByTier (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'records',
    url: '/internal/stock/short-sale',
    params
  })
  return response.data
}
