export const jurisdictionOptions = [
  {
    title: 'Alaska (AK)',
    value: 'alaska'
  },
  {
    title: 'Alabama (AL)',
    value: 'alabama'
  },
  {
    title: 'Arkansas (AR)',
    value: 'arkansas'
  },
  {
    title: 'Arizona (AZ)',
    value: 'arizona'
  },
  {
    title: 'California (CA)',
    value: 'california'
  },
  {
    title: 'Colorado (CO)',
    value: 'colorado'
  },
  {
    title: 'Connecticut (CT)',
    value: 'connecticut'
  },
  {
    title: 'District of Columbia (DC)',
    value: 'district_of_columbia'
  },
  {
    title: 'Delaware (DE)',
    value: 'delaware'
  },
  {
    title: 'Florida (FL)',
    value: 'florida'
  },
  {
    title: 'Georgia (GA)',
    value: 'georgia'
  },
  {
    title: 'Guam (GU)',
    value: 'guam'
  },
  {
    title: 'Hawaii (HI)',
    value: 'hawaii'
  },
  {
    title: 'Idaho (ID)',
    value: 'idaho'
  },
  {
    title: 'Illinois (IL)',
    value: 'illinois'
  },
  {
    title: 'Indiana (IN)',
    value: 'indiana'
  },
  {
    title: 'Iowa (IA)',
    value: 'iowa'
  },
  {
    title: 'Kansas (KS)',
    value: 'kansas'
  },
  {
    title: 'Kentucky (KY)',
    value: 'kentucky'
  },
  {
    title: 'Louisiana (LA)',
    value: 'louisiana'
  },
  {
    title: 'Maine (ME)',
    value: 'maine'
  },
  {
    title: 'Maryland (MD)',
    value: 'maryland'
  },
  {
    title: 'Massachussets (Ma)',
    value: 'massachussets'
  },
  {
    title: 'Michigan (MI)',
    value: 'michigan'
  },
  {
    title: 'Minnesota (MN)',
    value: 'minnesota'
  },
  {
    title: 'Mississippi (MS)',
    value: 'mississippi'
  },
  {
    title: 'Missouri (MO)',
    value: 'missouri'
  },
  {
    title: 'Montana (MT)',
    value: 'montana'
  },
  {
    title: 'Nebraska (NE)',
    value: 'nebraska'
  },
  {
    title: 'Nevada (NV)',
    value: 'nevada'
  },
  {
    title: 'New Hampshire (NH)',
    value: 'new_hampshire'
  },
  {
    title: 'New Jersey (NJ)',
    value: 'new_jersey'
  },
  {
    title: 'New Mexico (NM)',
    value: 'new_mexico'
  },
  {
    title: 'New York (NY)',
    value: 'new_york'
  },
  {
    title: 'North Carolina (NC)',
    value: 'north_carolina'
  },
  {
    title: 'North Dakota (ND)',
    value: 'north_dakota'
  },
  {
    title: 'Ohio (OH)',
    value: 'ohio'
  },
  {
    title: 'Oklahoma (OK)',
    value: 'oklahoma'
  },
  {
    title: 'Oregon (OR)',
    value: 'oregon'
  },
  {
    title: 'Pennsylvania (PA)',
    value: 'pennsylvania'
  },
  {
    title: 'Puerto Rico (PR)',
    value: 'puerto_rico'
  },
  {
    title: 'Rhode Island (RI)',
    value: 'rhode_island'
  },
  {
    title: 'South Carolina (SC)',
    value: 'south_carolina'
  },
  {
    title: 'South Dakota (SD)',
    value: 'south_dakota'
  },
  {
    title: 'Tennessee (TN)',
    value: 'tennessee'
  },
  {
    title: 'Texas (TX)',
    value: 'texas'
  },
  {
    title: 'Utah (UT)',
    value: 'utah'
  },
  {
    title: 'Vermont (VT)',
    value: 'vermont'
  },
  {
    title: 'Virginia (VA)',
    value: 'virginia'
  },
  {
    title: 'Virgin Islands (VI)',
    value: 'virginislands'
  },
  {
    title: 'Washington (WA)',
    value: 'washington'
  },
  {
    title: 'West Virginia (WV)',
    value: 'west_virginia'
  },
  {
    title: 'Wisconsin (WI)',
    value: 'wisconsin'
  },
  {
    title: 'Wyoming (WY)',
    value: 'wyoming'
  }
]
