import React from 'react'
import PropTypes from 'prop-types'
import CodeInput from '../CodeInput'
import { withContext } from '../../AuthContext'
import { Button, Input, TextLink } from '@otcmarketsgroup/otcm-ui'
import styles from '../LogIn/LogIn.module.scss'

class ForgotPassword extends React.PureComponent {
  constructor (props, context) {
    super(props, context)

    this.state = {
      username: '',
      password: '',
      error: false
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (field) {
    return (value) => {
      this.setState({ [field]: value })
    }
  }

  render () {
    const { context } = this.props

    return <div className={styles.container}>
      <div className={styles.form}>
        <form onSubmit={(event) => context.submitForgotPwd(this.state.username, event)}>
          <div className={styles.greeting}>
            <h3 className={styles.formTitle}>Forgot Password</h3>
            <p className={styles.formDesc}>
              Enter your email below and we will send a message to reset your password.
            </p>
          </div>
          <Input id={'userName'}
            className={styles.input}
            title={'Email'}
            required
            design='standard'
            onChange={this.handleChange('username')}
            // error={this.state.errors[field.name]}
            type={'input'}
          />
          {context.error && <i className={styles.error}>{context.error}</i>}
          <Button id={'forgotPwdSubmitLogIn'} nactive={context.disableButton} className={styles.submit} title='Submit' fullWidth type='submit' />
          <TextLink onClick={() => context.changeState('LOG_IN')}>Cancel</TextLink>
        </form>
      </div>
    </div>
  }
}

ForgotPassword.propTypes = {
  context: PropTypes.shape({
    submitForgotPwd: PropTypes.func,
    error: PropTypes.string,
    changeState: PropTypes.func,
    disableButton: PropTypes.func
  })
}

export default withContext(ForgotPassword)
