export function request () {
  return {
    totalRecords: 2,
    pages: 1,
    currentPage: 1,
    pageSize: 10,
    sortOn: 'releaseDate',
    sortDir: 'DESC',
    records: [
      {
        id: 180614,
        companyId: 658643,
        userId: 9407,
        title: 'Annual Report',
        typeId: 'AR',
        statusId: 'A',
        periodDate: 1498795200000,
        isImmediate: true,
        createdDate: 1515083872000,
        lastModifiedDate: 1515083872000,
        releaseDate: 1515083872000,
        canDistribute: true,
        wasDistributed: false,
        companyName: 'Canstar Resources Inc.',
        reportType: 'Annual Report',
        name: 'Annual Report - Annual Report',
        statusDescript: 'Active',
        symbol: 'CSRNF',
        primarySymbol: 'CSRNF',
        isCaveatEmptor: false,
        edgarSECFiling: false,
        tierCode: 'GM'
      },
      {
        id: 180613,
        companyId: 674812,
        userId: 6869,
        title: 'testing, must show!',
        typeId: 'SAR',
        statusId: 'A',
        periodDate: 1498795200000,
        isImmediate: true,
        createdDate: 1513717273000,
        lastModifiedDate: 1513717273000,
        releaseDate: 1513717273000,
        canDistribute: true,
        wasDistributed: false,
        companyName: 'OTC Markets Group Inc.',
        reportType: 'Semi-Annual Report',
        name: 'Semi-Annual Report - testing, must show!',
        statusDescript: 'Active',
        symbol: 'OTCM',
        primarySymbol: 'OTCM',
        isCaveatEmptor: false,
        edgarSECFiling: false,
        tierCode: 'QX'
      }
    ],
    singular: 'Financial Report',
    plural: 'Financial Reports',
    statusId: 'A',
    releasedAfterDate: 1512882000000
  }
}
