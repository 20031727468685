import axios from 'axios'
import { CrossStorageClient } from 'cross-storage'
import { checkSession, getToken } from '../utils/session'
import getConfig from '../config'
const { REACT_APP_ADMIN_BASE_URI, REACT_APP_OTC_S3_BASE_URI } = getConfig()

const isTestMode = process.env.NODE_ENV === 'test'
const isDebugMode = false

export function paginate (input, params) {
  if (!params) return input
  if (typeof params !== 'object') return input
  if (!params.pageSize) params.pageSize = 5

  let page = params.page
  if (page < 1) page = 1

  if (Array.isArray(input)) {
    return input.slice(page * params.pageSize, params.pageSize)
  } else {
    if (!input) return input
    if (!input.records) input.records = []

    input.totalRecords = input.records.length
    input.pages = Math.ceil(input.records.length / params.pageSize)
    input.currentPage = page
    input.pageSize = params.pageSize
    input.records = input.records.slice((page - 1) * params.pageSize, page * params.pageSize)
    return input
  }
}

export function sort (input, params) {
  const asc = params.sortDir === 'asc'

  if (!params.sortOn) return input

  return input.sort((a, b) => {
    const values = {
      a: a[params.sortOn],
      b: b[params.sortOn]
    }

    if (typeof (values.a) === 'string') values.a = values.a.toUpperCase()
    if (typeof (values.b) === 'string') values.b = values.b.toUpperCase()

    if (values.a < values.b) return asc ? -1 : 1
    if (values.a > values.b) return asc ? 1 : -1

    return 0
  })
}

/**
 * Prepare an empty data value based on the response format
 * if the API is returning invalid data.
 */
function prepareData (data, options) {
  if (!options) return data
  if (!options.responseFormat) return data
  if (options.responseFormat === 'data') return data

  switch (options.responseFormat) {
    case 'records':
      if (!data) data = {}
      if (typeof data === 'string') data = {}
      if (!data.records) data.records = []
      if (typeof data.totalRecords === 'undefined') data.totalRecords = 0
      break

    case 'array':
      if (!data) data = []
      if (typeof data === 'string') data = []
      break

    case 'object':
      if (!data) data = {}
      if (typeof data === 'string') data = {}
      break

    case 'text':
      if (!data) data = ''
      break
  }

  return data
}

function getOtcApiPath (options) {
  if (options.internalAPI) return '/internalapi'
  if (options.nodeAPI) return ''
  return '/otcapi'
}

export default {
  request: async (options) => {
    let jwtToken

    await getToken()
      .then(token => {
        jwtToken = token
      })
      .catch(e => console.log('no session', e))

    // Check for valid session
    if (options.ignoreAuth || (options.params && options.params.ignoreAuth)) {

    } else {
      checkSession()
        .then(res => {
          if (res === 'invalid') {
            window.location.reload()
          }
        })
        .catch(() => window.location.reload())
    }

    // set sort direction to uppercase (to keep component code consistency)
    // ignored 'A' / 'D' values since OTC will be fixing them
    if (options.params) {
      switch (options.params.sortDir) {
        case 'asc':
          options.params.sortDir = 'ASC'
          break
        case 'desc':
          options.params.sortDir = 'DESC'
          break
      }
    }

    if (isTestMode || (isDebugMode && options.otcAPI) || (options.isWorking === false && options.otcAPI)) {
      // use mock data
      let data = []

      if (options.mockData) {
        data = require(`./mock-data/${options.mockData}`)
          .request(options.params)
      }

      return {
        data: paginate(data, options.params)
      }
    } else {
      if (options.otcS3) {
        options.url = `/otcs3/${options.url}`
      } else if (options.url.indexOf('/') === 0) {
        options.url = `${options.otcAPI ? getOtcApiPath(options) : '/api/v1'}${options.url}`
      }
      if (options.privateAPI) {
        let token
        try {
          const storage = new CrossStorageClient(`${REACT_APP_ADMIN_BASE_URI}/storage`, { timeout: 10000 })
          await storage.onConnect()
          token = await storage.get('token')
        } catch (err) {
          throw Error('Error getting access token: ' + err)
        }
        options.headers = options.headers || {}
        options.headers['x-access-token'] = token
      }

      if (jwtToken) {
        options.headers = options.headers || {}
        options.headers.Authorization = `Bearer ${jwtToken}`
      }

      const response = await axios(options)

      response.data = prepareData(response.data, options)

      if (response === 'Unauthorized') {
        window.location.reload()
      }

      return response
    }
  },
  link: (options) => {
    if (options.otcS3) return `${REACT_APP_OTC_S3_BASE_URI}/${options.url}`
    if (options.otcCompanyFile) return `${window.location.origin}/company-otcapi${options.url}`
    const url = `${window.location.origin}${options.otcAPI ? '/otcapi' : '/api/v1'}${options.url}`
    return url
  },

  getGUID: (id) => {
    let idArray = ''
    try {
      idArray = id.split(/-/)
    } catch (err) {
      return ''
    }

    // const idArray = id.split(/-/)
    let guid = ''

    if (idArray.length === 3) {
      guid = idArray[1]
      return guid
    }

    idArray.forEach((item, index) => {
      if (index !== 0 && index !== idArray.length - 1) guid += item + '-'
    })

    guid = guid.substring(0, guid.length - 1)
    return guid
  }
}
